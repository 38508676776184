import { Athlete, Coach, Personnel } from "../store/themis_common_pb"
import { countryDateFormats, currencySymbols } from "./constants"
export const textGender = (genderId: number): string => {
  switch (genderId) {
    case 0: return "male"
    case 1: return "female"
    case 2: return "other"
    case 3: return "unknown"
  }
  return "unknown"
}

export const sortAthletes = (athletes: Athlete.AsObject[]): Athlete.AsObject[] => {
  if (!athletes) return []
  const sortAthletes = [...athletes]
  sortAthletes.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  return sortAthletes
}

export const sortCoaches = (coaches: Coach.AsObject[]): Coach.AsObject[] => {
  if (!coaches) return []
  const sortCoaches = [...coaches]
  sortCoaches.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  return sortCoaches
}

export const sortPersonnel = (personnel: Personnel.AsObject[]): Personnel.AsObject[] => {
  if (!personnel) return []
  const sortPersonnel = [...personnel]
  sortPersonnel.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  return sortPersonnel
}

export const addCommas = (nStr: string): string => {
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

export const getCountryDateFormat = (date: Date): string => {
  const language = navigator.language
  const format: string = (language in countryDateFormats) ? countryDateFormats[language] : "MM/dd/yyyy"
  return format
}

export const makeMoney = (amountInPennies: number, currency: string): string => {
  const symbol = currencySymbols.find(cs => cs.cc === currency)
  const value = addCommas((amountInPennies / 100).toFixed(2))
  return symbol?.symbol + value
}

export const locationApiCall = async (longitude: number, latitude: number): Promise<any> => {
  const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
  return response.json()
}

export const titleCase = (str: string): string => `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`