import { client } from "store/themisClient"
import themis_common from "store/themis_common_pb"
import { buildJudge } from "./producerBuilders"
import { AnyDispatchType, SET_JUDGES } from "lib/constants"
import { createAction } from "redux-actions"

const setJudges = createAction<themis_common.Judge.AsObject[]>(SET_JUDGES)

export const getJudge = async (judgeId: number, producerId: number, validateBrandId: number): Promise<themis_common.Judge.AsObject> => {
  const judgeRequest = new themis_common.Judge()

  judgeRequest.setProducerId(producerId)
  judgeRequest.setId(judgeId)

  if (validateBrandId && validateBrandId > 0) judgeRequest.setBrandId(validateBrandId)

  const res = await client.getJudge(judgeRequest, {})
  const judgeResponse = res.toObject()
  return judgeResponse.judge
}

export const addJudge = async (dispatch: AnyDispatchType, judgePerson: themis_common.Person.AsObject, producerId: number): Promise<themis_common.Judge.AsObject> => {
  const judgeRequest = buildJudge(undefined, producerId, judgePerson)

  const res = await client.addJudge(judgeRequest, {})
  const judgeResponse = res.toObject()
  const judges = judgeResponse.judgesList
  const judge = judgeResponse.judge
  dispatch(setJudges(judges))
  return judge
}

export const updateJudge = async (dispatch: AnyDispatchType, judgeId: number, judgePerson: themis_common.Person.AsObject, producerId: number, brandId: number): Promise<void> =>{

  const judgeRequest = buildJudge(judgeId, producerId, judgePerson, brandId)
  const res = await client.updateJudge(judgeRequest, {})
  const judgeResponse = res.toObject()
  const judges = judgeResponse.judgesList

  dispatch(setJudges(judges))
}

export const getRubricById = async (rubricId: number): Promise<themis_common.Rubric.AsObject> => {
  const rubricRequest = new themis_common.Rubric()
  rubricRequest.setId(rubricId);
  const res = await client.getRubricById(rubricRequest, {})
  const rubricResponse = res.toObject()
  return rubricResponse
}


