import React, {
  CSSProperties,
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { Badge, Box, Button, Container, Grid, Typography } from '@material-ui/core'
import { useStyles } from "../../lib/theme"
import { JudgeEventPermission, JudgeShift } from "../../store/user/userReducer"
import themis_common from 'store/themis_common_pb'
import { getEventDivisionsByShift, scoreEventDivisionShift } from "../../store/user/userActions"
import ScoreCardContainer from "./rubricScoring/ScoreCardContainer"
import themis_api from "../../store/themis_api_pb"
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@material-ui/icons'
import { useHistory } from "react-router"

interface JudgeEventProps {
  event: JudgeEventPermission,
  eventDetails: themis_common.Event.AsObject
  saveScores: Function
  judgeId: number
  currentTeam: themis_common.EventDivisionShiftTeam.AsObject | undefined
  getTeam: Function
  divisionScores: themis_api.TeamScores.AsObject
  setDivisionScores: Function
  isMasterJudge: boolean
  closeDivision: Function
  setCurrentShift: Function
  currentShift: number
}

const JudgeEvent: React.FC<JudgeEventProps> = ({
  event,
  eventDetails,
  saveScores,
  judgeId,
  currentTeam,
  getTeam,
  divisionScores,
  setDivisionScores,
  isMasterJudge,
  closeDivision,
  setCurrentShift,
  currentShift
}): ReactElement => {
  const classes = useStyles()

  const [offsetHeight, setOffsetHeight] = useState(300)
  const [time, setTime] = useState(false)
  const [currentDiv, setCurrentDiv] = useState(0)
  const [currentRubric, setCurrentRubric] = useState(0)
  const [divisionData, setDivisionData] = useState<themis_common.EventDivisionShift.AsObject[]>([])

  const [teamData, setTeamData] = useState<themis_common.EventDivisionShiftTeam.AsObject[]>([])
  const inputRef: MutableRefObject<any> = useRef()
  const history = useHistory()

  const setShift = useCallback((shiftId: number) => {
    const doSetDivisions = async () => {
      console.log(eventDetails)
      const divData = await getEventDivisionsByShift(shiftId, eventDetails.brand?.producer?.id || 0, eventDetails.brand?.id || 0, eventDetails.id)

      const sortedDivData = divData.map(div => {
        return { ...div, teamsList: div.teamsList.sort(teamSorter) }
      })
      setDivisionData(sortedDivData)
    }
    doSetDivisions()
    setCurrentShift(shiftId)
  }, [eventDetails.brandId, eventDetails.id, eventDetails.producerId])

  const teamSorter = (a: themis_common.EventDivisionShiftTeam.AsObject, b: themis_common.EventDivisionShiftTeam.AsObject) => {
    if (a.order < b.order) return -1
    return 1
  }

  const updateScores = async () => {
    console.log(currentTeam)
    const currentDivisionShiftId = currentTeam?.eventDivisionShift?.id
    if (currentDivisionShiftId && judgeId) {
      const scores = await scoreEventDivisionShift(currentDivisionShiftId, judgeId)
      setDivisionScores(scores)
    }
  }

  useEffect(() => {
    updateScores()
  }, [currentTeam])

  useEffect(() => {
    setTimeout(() => {
      setTime(current => !current)
    }, 2000)
  }, []) // Do not change this!

  // Get top offset
  useEffect(() => { // These timeouts adjust for the slow load of the header
    try {
      setOffsetHeight(Math.floor((inputRef.current?.getBoundingClientRect()).top))
    } catch (e) {
      // Do nothing
      console.error(e)
    }
  }, [time])

  const setDivision = useCallback((division: themis_common.EventDivisionShift.AsObject) => {
    setCurrentDiv(division.id)
    setTeamData(division.teamsList)
    getTeam(division.teamsList[0]?.id || 0)
    setCurrentRubric(division?.eventDivision?.rubricId || 0)
  }, [getTeam])

  const matchWindowHeight = useMemo<CSSProperties>(() => {
    return {
      height: `calc(100vh - ${offsetHeight}px - 24px)`, // 24px is the Material Tab padding
    }
  }, [offsetHeight])

  const matchScoreAreaHeight = useMemo<CSSProperties>(() => {
    return {
      height: `calc(100vh - ${offsetHeight}px - 60px)`, // 24px is the Material Tab padding
    }
  }, [offsetHeight])
  const handleShiftClick = (shift: JudgeShift) => {
    setShift(shift?.shiftId)
    setDivisionData([])
    setCurrentDiv(0)
    setTeamData([])
  }

  const shifts: ReactElement[] = useMemo(() => {
    if (!event?.shifts) return []
    const retVal: ReactElement[] = []
    for (const shiftId of Object.keys(event.shifts)) {
      const sId = Number(shiftId)
      const shift = event.shifts[sId]
      const bgColor = currentShift === sId ? "red" : "rgb(17, 17, 17)"
      retVal.push(
        <Grid container key={`shift-${shift?.shiftId}`}>
          <Grid item xs={isMasterJudge ? 10 : 12} style={{ cursor: "pointer", backgroundColor: bgColor }} onClick={() => {
            handleShiftClick(shift)
          }}>
            {`${shift?.day.substr(0, 3)} ${shift?.startTime} - ${shift?.floorName} ${shift?.panelName} (${shift?.divs} divs)`}
          </Grid>
          { isMasterJudge ? <Grid item xs={2}><Button variant="outlined" size="small" onClick={() => { history.push('/ShiftReport/' + shiftId) }}>Print</Button></Grid> : [] }

        </Grid>)
    }
    return retVal
  }, [event.shifts, setShift, currentShift])

  const divisions = useMemo(() => {
    return divisionData.map((division, i) => {
      const bgColor = currentDiv === division.id ? "red" : "rgb(17, 17, 17)"
      return (
        <Grid container key={`division-${division.id}`}>
          <Grid item xs={isMasterJudge ? 8 : 12} style={{ cursor: "pointer", backgroundColor: bgColor }} onClick={() => { setDivision(division) }}>
            {division.eventDivision?.division?.tier?.name} - {division.eventDivision?.name ? division.eventDivision?.name : division.eventDivision?.division?.name}
          </Grid>
          { isMasterJudge ?
            <Grid item xs={4}>
              <Button variant="outlined" size="small" onClick={() => { closeDivision(division.id, false); setDivisionData(current => { return [ ...current.slice(0, i), { ...current[i], approved: !division.approved }, ...current.slice(i+1)]}) }}>{ division.approved ? "Open" : "Close" }</Button>
              <Button variant="outlined" size="small" onClick={() => { history.push('/TeamReport/' + division.id) }}>Print</Button>
            </Grid>
            : [] }
        </Grid>)
    })
  }, [divisionData, currentDiv, setDivision, isMasterJudge])

  const teams = useMemo(() => {
    return teamData.map(team => {
      // const scores = currentTeamScores.find(scoreSet => scoreSet.teamId === team.id)
      const bgColor = currentTeam?.id === team.id ? "red" : "rgb(17, 17, 17)"
      const score = divisionScores.teamScoresList.find(score => score.teamId === team.id)
      return (
        <Grid container item xs={12} spacing={0} key={`team-${team.id}`} style={{ border: "1px solid white" }}>
          <Grid item xs={4} container spacing={0}>
            <Grid item xs={6}>{score?.score ?
              <Badge invisible={score?.rank > 3} badgeContent={score?.rank} color="secondary"><Box><Typography
                variant="h6"
                style={{ fontSize: 16 }}>{Math.round(score?.score * 10) / 10}</Typography></Box></Badge> : []} </Grid>
            <Grid item xs={6}><Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                                     badgeContent={score?.judgeCount} color="primary">{score?.mescore ?
              <CheckBoxOutlined/> : <CheckBoxOutlineBlank/>}</Badge></Grid>
          </Grid>
          <Grid item xs={8} style={{ cursor: "pointer", backgroundColor: bgColor }} onClick={() => {
            getTeam(team.id)
          }}>{team.eventTeam?.programName} - {team.eventTeam?.name}</Grid>
        </Grid>)
    })
  }, [teamData, currentTeam?.id, getTeam, divisionScores.teamScoresList])

  const scoreCardContainer = useMemo(() => {
    return currentRubric && currentTeam?.id ?
      <ScoreCardContainer rubricId={currentRubric} judgeId={judgeId} currentTeam={currentTeam}
                          saveScores={saveScores}/> : []
  }, [currentRubric, currentTeam?.id, saveScores])

  return <Container style={{ maxWidth: "none", fontSize: 12 }}>
    <Grid container spacing={1}>
      <Grid container item xs={12} alignItems="flex-start">
        <Typography variant="h1" className={classes.headerTypo}>Judging - {event.name}</Typography>
      </Grid>
      <Grid item container xs={5} md={4} lg={3} ref={inputRef} style={{ ...matchWindowHeight }}>
        <Grid item container xs={12} style={{ height: "31%", flexFlow: "column" }}>
          <Typography variant="h2">Divisions</Typography>
          <Grid container spacing={1} alignItems="flex-start"
                style={{ overflowY: "auto", border: "1px solid white", flexGrow: 1 }}>
            {divisions}
          </Grid>
        </Grid>
        <Grid item container xs={12} style={{ height: "50%", flexFlow: "column" }}>
          <Typography variant="h2">Teams</Typography>
          <Grid container spacing={1} alignItems="flex-start"
                style={{ overflowY: "auto", border: "1px solid white", flexGrow: 1 }}>
            {teams}
          </Grid>
        </Grid>
        <Grid item container xs={12} style={{ height: "17%", flexFlow: "column" }}>
          <Typography variant="h2">Shifts</Typography>
          <Grid container spacing={1} alignItems="flex-start"
                style={{ overflowY: "auto", border: "1px solid white", flexGrow: 1 }}>
            {shifts}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container sm={7} md={8} lg={9} spacing={1}>
        <Grid item container xs={12}>
          {currentRubric && currentTeam?.eventTeam?.name ? <Typography variant="h2" style={{
            textAlign: "center",
            width: "100%"
          }}>{currentTeam?.eventTeam?.programName} - {currentTeam?.eventTeam?.name}</Typography> : []}
        </Grid>
        <Grid item container xs={12} style={{ overflowY: "auto", ...matchScoreAreaHeight }}>
          {scoreCardContainer}
        </Grid>
      </Grid>
    </Grid>
  </Container>
}

export default JudgeEvent
