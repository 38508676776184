import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { deleteBrand } from "store/producer/brandActions"
import { IAppState } from "store/store"
import { selectCurrentBrands, selectCurrentProducer } from "store/producer/producerSelectors"
import { selectCurrentPermissionLists, selectPermissionCodes, selectSuperUser, selectUserProducerPermissions } from "store/user/userSelectors"
import { deleteProducer, doSetProducer, removeJudge } from "store/producer/producerActions"
import { refreshUser } from "store/user/userActions"
import themis_common from "store/themis_common_pb"
import Producer from './Producer'
import { useCallback } from 'react'


const ProducerContainer: React.FC = (): ReactElement => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const { producerId: inProducerId } = useParams<{producerId?: string | undefined}>()
  const producerId = Number(inProducerId)

  const [currentTab, setCurrentTab] = useState(5)
  const [isProducerOpen, setIsProducerOpen] = useState(false)
  const [brandToDelete, setBrandToDelete] = useState<themis_common.Brand.AsObject | null>(null)
  const [isBrandOpen, setIsBrandOpen] = useState(false)

  const brands = useSelector((state: IAppState) => selectCurrentBrands(state))
  const producer = useSelector((state: IAppState) => selectCurrentProducer(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const producerPermissions = useSelector((state: IAppState) => selectUserProducerPermissions(state))
  const permissionLists = useSelector((state: IAppState) => selectCurrentPermissionLists(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const hashValues = useMemo(() => ['brands', 'judges', 'users', 'links', 'documents', 'feed', 'reports'], [])

  useEffect(() => {
    // If producers stripe account isn't active, force redirect to stripe create page
    if (producer && producer.id > 0 && producerId === producer.id && !producer.stripeAccountActive) {
      history.replace(`/Producer/${producer.id}/CreateMerchantAccount`)
    }
  }, [producer, history, producerId])

  useEffect(() => {
    const hash = location.hash.toLowerCase().substr(1)
    if (hash === '') {
      history.replace('#' + hashValues[5])
    }
    const tab = hashValues.indexOf(location.hash.toLowerCase().substr(1))
    if (tab >= 0) setCurrentTab(tab)
  }, [location, hashValues, history])

  const handleChangeTab = (event: React.ChangeEvent<any>|null, newValue: number) => {
    if (hashValues[newValue]) history.push('#' + hashValues[newValue])
    setCurrentTab(newValue)
  }

  const handleDeleteProducer = async () => {
    await deleteProducer(dispatch, producer?.id || 0)
    setIsProducerOpen(false)
    await refreshUser(dispatch)
    await doSetProducer(dispatch, 0)
    history.push("/")
  }

  const setDeleteBrand = (brand: themis_common.Brand.AsObject) => {
    setBrandToDelete(brand)
    setIsBrandOpen(true)
  }

  const handleDeleteBrand = async () => {
    if (!brandToDelete?.id || !producer?.id) return
    await deleteBrand(dispatch, brandToDelete.id, producer.id)
    setIsBrandOpen(false)
  }

  const handleDeleteJudgeFromProducer = useCallback((judgeId: number) => {
    removeJudge(dispatch, producerId, judgeId)
  }, [dispatch, producerId])


  return ( permissionLists && producer && permissionCodes?.denyAccess.length ?
    <Producer
      superUser={superUser}
      producerPermissions={producerPermissions}
      permissionLists={permissionLists}
      producerId={producerId}
      producer={producer}
      brands={brands}
      currentTab={currentTab}
      handleChangeTab={handleChangeTab}
      handleDeleteProducer={handleDeleteProducer}
      handleDeleteBrand={handleDeleteBrand}
      setDeleteBrand={setDeleteBrand}
      brandToDelete={brandToDelete}
      isProducerOpen={isProducerOpen}
      isBrandOpen={isBrandOpen}
      setIsProducerOpen={setIsProducerOpen}
      setIsBrandOpen={setIsBrandOpen}
      permissionCodes={permissionCodes}
      handleDeleteJudgeFromProducer={handleDeleteJudgeFromProducer}
    /> : <></>
  )
}

export default ProducerContainer

