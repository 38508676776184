import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from "react-router-dom"
import JudgeEvent from "./JudgeEvent"
import { JudgeEventPermission, JudgeEventPermissions } from "../../store/user/userReducer"
import { useSelector } from "react-redux"
import { IAppState } from "../../store/store"
import { selectUserJudgeEvents, selectUserProducerPermissions } from "../../store/user/userSelectors"
import { getEvent } from "../../store/producer/eventActions"
import themis_common from 'store/themis_common_pb'
import { closeEventShiftDivision, getEventDivisionShiftTeam, saveRubricAreaScores } from "../../store/user/userActions"
import themis_api from "../../store/themis_api_pb"

const JudgeEventContainer: React.FC = (): ReactElement => {
  const { eventId: inEventId } = useParams<{ eventId: string }>()
  const eventId = Number(inEventId)
  const judgeEvents: JudgeEventPermissions = useSelector((state: IAppState) => selectUserJudgeEvents(state))
  const event: JudgeEventPermission = judgeEvents[eventId]
  const [eventDetails, setEventDetails] = useState<themis_common.Event.AsObject>()
  const [currentTeam, setCurrentTeam] = useState<themis_common.EventDivisionShiftTeam.AsObject>()
  const [divisionScores, setDivisionScores] = useState<themis_api.TeamScores.AsObject>({ teamScoresList: [] })
  const [currentShift, setCurrentShift] = useState<number>(0)

  const producerPermissions = useSelector((state: IAppState) => selectUserProducerPermissions(state))

  const closeDivision = (divisionId: number, status: boolean) => {
    closeEventShiftDivision(divisionId, event.judgeId, currentShift, status)
  }

  const isMasterJudge = useMemo<boolean>(() => {
    if (eventDetails?.brand?.producer?.id) {
      const permissions =  producerPermissions[eventDetails?.brand?.producer?.id]
      return permissions?.perms.includes("PS")
    }
    return false
  }, [producerPermissions, eventDetails])

  useEffect(() => {
    const doGetEvent = async () => {
      const eventD = await getEvent(event.id)
      setEventDetails(eventD)
    }
    if (event) doGetEvent()

  }, [event?.id])

  const saveScores = useCallback((rubricAreaScores) => {
    console.log('saveScores', rubricAreaScores)
    const doSaveScores = async () => {
      if (!eventDetails?.brand?.producer?.id || !eventDetails?.brand?.id || !currentTeam?.id || !currentTeam.eventDivisionShift?.id) return
      const currentScores = await saveRubricAreaScores(rubricAreaScores, currentTeam.id, currentTeam.eventDivisionShift.id, eventDetails.brand.producer.id, eventDetails.brand.id, eventId)
      setDivisionScores(currentScores)
    }
    doSaveScores()
  }, [eventDetails?.brand?.id, eventDetails?.brand?.producer?.id, currentTeam?.id, eventId])

  const getTeam = useCallback((edstId: number) => {
    const getTheTeam = async () => {
      if (eventDetails?.brand?.id && eventDetails?.brand?.producer?.id && eventId) {
        const team = await getEventDivisionShiftTeam(edstId, eventDetails.brand.producer.id, eventDetails.brand.id, eventId, event.judgeId)
        setCurrentTeam(team)
      }
    }
    getTheTeam()
  }, [eventDetails?.brand?.id, eventDetails?.brand?.producer?.id, eventId])

  return event && eventDetails && divisionScores ? <JudgeEvent event={event} eventDetails={eventDetails} saveScores={saveScores} judgeId={event.judgeId} currentTeam={currentTeam} getTeam={getTeam} divisionScores={divisionScores} setDivisionScores={setDivisionScores} isMasterJudge={isMasterJudge} closeDivision={closeDivision} currentShift={currentShift} setCurrentShift={setCurrentShift} /> : <></>
}

export default JudgeEventContainer
