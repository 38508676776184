/* eslint-disable */
// source: themis_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var themis_common_pb = require('./themis_common_pb.js');
goog.object.extend(proto, themis_common_pb);
goog.exportSymbol('proto.themis.AddAthleteListRequest', null, global);
goog.exportSymbol('proto.themis.AddEventDivisionPricingBulkRequest', null, global);
goog.exportSymbol('proto.themis.AddEventRegistrationCodeRequest', null, global);
goog.exportSymbol('proto.themis.AddEventRequest', null, global);
goog.exportSymbol('proto.themis.AddFinancialTransactionRequest', null, global);
goog.exportSymbol('proto.themis.AddFinancialTransactionResponse', null, global);
goog.exportSymbol('proto.themis.AddPaymentRequest', null, global);
goog.exportSymbol('proto.themis.AddPaymentResponse', null, global);
goog.exportSymbol('proto.themis.AddProducerLinkRequest', null, global);
goog.exportSymbol('proto.themis.AddStripeSourceRequest', null, global);
goog.exportSymbol('proto.themis.AllStarTypesResponse', null, global);
goog.exportSymbol('proto.themis.AthleteEventTeamRequest', null, global);
goog.exportSymbol('proto.themis.AthleteLocationRequest', null, global);
goog.exportSymbol('proto.themis.AthleteResponse', null, global);
goog.exportSymbol('proto.themis.AthleteTeamRequest', null, global);
goog.exportSymbol('proto.themis.AthletesTeamRequest', null, global);
goog.exportSymbol('proto.themis.AuthSharedRequest', null, global);
goog.exportSymbol('proto.themis.BasicReport', null, global);
goog.exportSymbol('proto.themis.BasicReportResponse', null, global);
goog.exportSymbol('proto.themis.BrandResponse', null, global);
goog.exportSymbol('proto.themis.BrandUser', null, global);
goog.exportSymbol('proto.themis.BugReport', null, global);
goog.exportSymbol('proto.themis.CategoriesResponse', null, global);
goog.exportSymbol('proto.themis.ChargePaymentSourceResponse', null, global);
goog.exportSymbol('proto.themis.CheckEmailRequest', null, global);
goog.exportSymbol('proto.themis.CheckEmailResponse', null, global);
goog.exportSymbol('proto.themis.CoachEventTeamRequest', null, global);
goog.exportSymbol('proto.themis.CoachLocationRequest', null, global);
goog.exportSymbol('proto.themis.CoachResponse', null, global);
goog.exportSymbol('proto.themis.CoachTeamRequest', null, global);
goog.exportSymbol('proto.themis.CodeRequest', null, global);
goog.exportSymbol('proto.themis.ConnectProducerLinkRequest', null, global);
goog.exportSymbol('proto.themis.ConnectProducerLinkResponse', null, global);
goog.exportSymbol('proto.themis.CountEventEmailsResponse', null, global);
goog.exportSymbol('proto.themis.CreateStripeProducerAccountResponse', null, global);
goog.exportSymbol('proto.themis.CreditRequest', null, global);
goog.exportSymbol('proto.themis.CreditResponse', null, global);
goog.exportSymbol('proto.themis.DeleteEventRegistrationCodeRequest', null, global);
goog.exportSymbol('proto.themis.DeleteEventRequest', null, global);
goog.exportSymbol('proto.themis.DeleteLocationRequest', null, global);
goog.exportSymbol('proto.themis.DivisionImportRequest', null, global);
goog.exportSymbol('proto.themis.DivisionTeamScores', null, global);
goog.exportSymbol('proto.themis.DivisionsResponse', null, global);
goog.exportSymbol('proto.themis.DocumentResponse', null, global);
goog.exportSymbol('proto.themis.DocumentSignatureRequest', null, global);
goog.exportSymbol('proto.themis.DocumentSignatureResponse', null, global);
goog.exportSymbol('proto.themis.ElectSigAgreement', null, global);
goog.exportSymbol('proto.themis.ElectSigAgreementResponse', null, global);
goog.exportSymbol('proto.themis.EmptyMessage', null, global);
goog.exportSymbol('proto.themis.EventDatesResponse', null, global);
goog.exportSymbol('proto.themis.EventDivisionResponse', null, global);
goog.exportSymbol('proto.themis.EventDivisionShiftTeamReturn', null, global);
goog.exportSymbol('proto.themis.EventDivisionShiftTeamsReturn', null, global);
goog.exportSymbol('proto.themis.EventDivisionShifts', null, global);
goog.exportSymbol('proto.themis.EventDivisionUsageResponse', null, global);
goog.exportSymbol('proto.themis.EventDocumentDetail', null, global);
goog.exportSymbol('proto.themis.EventDocumentResponse', null, global);
goog.exportSymbol('proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest', null, global);
goog.exportSymbol('proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest', null, global);
goog.exportSymbol('proto.themis.EventFloorsResponse', null, global);
goog.exportSymbol('proto.themis.EventGridRequest', null, global);
goog.exportSymbol('proto.themis.EventGridResponse', null, global);
goog.exportSymbol('proto.themis.EventRegistrationCodesResponse', null, global);
goog.exportSymbol('proto.themis.EventRegistrationPreRequest', null, global);
goog.exportSymbol('proto.themis.EventRegistrationPreResponse', null, global);
goog.exportSymbol('proto.themis.EventResponse', null, global);
goog.exportSymbol('proto.themis.EventTeamResponse', null, global);
goog.exportSymbol('proto.themis.EventTeamSignStatus', null, global);
goog.exportSymbol('proto.themis.EventUser', null, global);
goog.exportSymbol('proto.themis.FixScheduleRequest', null, global);
goog.exportSymbol('proto.themis.FixScheduleResponse', null, global);
goog.exportSymbol('proto.themis.GetAccountBalanceRequest', null, global);
goog.exportSymbol('proto.themis.GetAccountBalanceResponse', null, global);
goog.exportSymbol('proto.themis.GetCreditDepositByFinancialTransactionIDRequest', null, global);
goog.exportSymbol('proto.themis.GetCurrentDepositResponse', null, global);
goog.exportSymbol('proto.themis.GetEventDatesRequest', null, global);
goog.exportSymbol('proto.themis.GetEventTeamRegistrationTransactionsResponse', null, global);
goog.exportSymbol('proto.themis.GetPermissionsResponse', null, global);
goog.exportSymbol('proto.themis.GetRegistrationTransactionsByIdsRequest', null, global);
goog.exportSymbol('proto.themis.GetRegistrationTransactionsByIdsResponse', null, global);
goog.exportSymbol('proto.themis.GetSeasonsRequest', null, global);
goog.exportSymbol('proto.themis.GuardianAthleteRequest', null, global);
goog.exportSymbol('proto.themis.GuardianResponse', null, global);
goog.exportSymbol('proto.themis.JudgeBrandRequest', null, global);
goog.exportSymbol('proto.themis.JudgeProducerRequest', null, global);
goog.exportSymbol('proto.themis.JudgeResponse', null, global);
goog.exportSymbol('proto.themis.LoadMessage', null, global);
goog.exportSymbol('proto.themis.LocationResponse', null, global);
goog.exportSymbol('proto.themis.LocationUser', null, global);
goog.exportSymbol('proto.themis.Permissions', null, global);
goog.exportSymbol('proto.themis.PermissionsResponse', null, global);
goog.exportSymbol('proto.themis.PersonnelEventTeamRequest', null, global);
goog.exportSymbol('proto.themis.PersonnelLocationRequest', null, global);
goog.exportSymbol('proto.themis.PersonnelResponse', null, global);
goog.exportSymbol('proto.themis.PersonnelTeamRequest', null, global);
goog.exportSymbol('proto.themis.ProducerPermissions', null, global);
goog.exportSymbol('proto.themis.ProducerResponse', null, global);
goog.exportSymbol('proto.themis.ProducerUser', null, global);
goog.exportSymbol('proto.themis.ProgramPermissions', null, global);
goog.exportSymbol('proto.themis.ProgramResponse', null, global);
goog.exportSymbol('proto.themis.ProgramUser', null, global);
goog.exportSymbol('proto.themis.RefreshRequest', null, global);
goog.exportSymbol('proto.themis.RegisterRequest', null, global);
goog.exportSymbol('proto.themis.RegisterResponse', null, global);
goog.exportSymbol('proto.themis.RegistrationActivityLogsByEventRequest', null, global);
goog.exportSymbol('proto.themis.RegistrationActivityLogsByProducerRequest', null, global);
goog.exportSymbol('proto.themis.RegistrationActivityLogsResponse', null, global);
goog.exportSymbol('proto.themis.ReportRequest', null, global);
goog.exportSymbol('proto.themis.ResendEmailRequest', null, global);
goog.exportSymbol('proto.themis.ScrapeRequest', null, global);
goog.exportSymbol('proto.themis.ScrapeRequestType', null, global);
goog.exportSymbol('proto.themis.ScrapeResponse', null, global);
goog.exportSymbol('proto.themis.SeasonsResponse', null, global);
goog.exportSymbol('proto.themis.ShareAuthResponse', null, global);
goog.exportSymbol('proto.themis.ShiftScores', null, global);
goog.exportSymbol('proto.themis.SignatureQueryRequest', null, global);
goog.exportSymbol('proto.themis.SignatureQueryResponse', null, global);
goog.exportSymbol('proto.themis.SignatureRequest', null, global);
goog.exportSymbol('proto.themis.SortFloorsRequest', null, global);
goog.exportSymbol('proto.themis.StatusResponse', null, global);
goog.exportSymbol('proto.themis.StripePKResponse', null, global);
goog.exportSymbol('proto.themis.SubstituteAthleteEventTeamRequest', null, global);
goog.exportSymbol('proto.themis.TeamGenerationRequest', null, global);
goog.exportSymbol('proto.themis.TeamReport', null, global);
goog.exportSymbol('proto.themis.TeamResponse', null, global);
goog.exportSymbol('proto.themis.TeamScore', null, global);
goog.exportSymbol('proto.themis.TeamScores', null, global);
goog.exportSymbol('proto.themis.TeamScoringAuthorityResponse', null, global);
goog.exportSymbol('proto.themis.TeamUser', null, global);
goog.exportSymbol('proto.themis.TiersResponse', null, global);
goog.exportSymbol('proto.themis.UpdateEventRegistrationCodeRequest', null, global);
goog.exportSymbol('proto.themis.UpdateEventRequest', null, global);
goog.exportSymbol('proto.themis.UpdatePasswordRequest', null, global);
goog.exportSymbol('proto.themis.UpdatePasswordResponse', null, global);
goog.exportSymbol('proto.themis.UserAuthRequest', null, global);
goog.exportSymbol('proto.themis.UserAuthResponse', null, global);
goog.exportSymbol('proto.themis.UserPermissionsResponse', null, global);
goog.exportSymbol('proto.themis.UserRequest', null, global);
goog.exportSymbol('proto.themis.VerifyACHSourceRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.TeamReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.TeamReport.repeatedFields_, null);
};
goog.inherits(proto.themis.TeamReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.TeamReport.displayName = 'proto.themis.TeamReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.StatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.StatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.StatusResponse.displayName = 'proto.themis.StatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ShiftScores = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ShiftScores.repeatedFields_, null);
};
goog.inherits(proto.themis.ShiftScores, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ShiftScores.displayName = 'proto.themis.ShiftScores';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DivisionTeamScores = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.DivisionTeamScores, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DivisionTeamScores.displayName = 'proto.themis.DivisionTeamScores';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.TeamScores = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.TeamScores.repeatedFields_, null);
};
goog.inherits(proto.themis.TeamScores, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.TeamScores.displayName = 'proto.themis.TeamScores';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.TeamScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.TeamScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.TeamScore.displayName = 'proto.themis.TeamScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GetPermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.GetPermissionsResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.GetPermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GetPermissionsResponse.displayName = 'proto.themis.GetPermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProducerPermissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ProducerPermissions.repeatedFields_, null);
};
goog.inherits(proto.themis.ProducerPermissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProducerPermissions.displayName = 'proto.themis.ProducerPermissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProgramPermissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ProgramPermissions.repeatedFields_, null);
};
goog.inherits(proto.themis.ProgramPermissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProgramPermissions.displayName = 'proto.themis.ProgramPermissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Permissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.Permissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Permissions.displayName = 'proto.themis.Permissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EmptyMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EmptyMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EmptyMessage.displayName = 'proto.themis.EmptyMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.UserAuthRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.UserAuthRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.UserAuthRequest.displayName = 'proto.themis.UserAuthRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.UserAuthResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.UserAuthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.UserAuthResponse.displayName = 'proto.themis.UserAuthResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ShareAuthResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ShareAuthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ShareAuthResponse.displayName = 'proto.themis.ShareAuthResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AuthSharedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AuthSharedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AuthSharedRequest.displayName = 'proto.themis.AuthSharedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RefreshRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.RefreshRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RefreshRequest.displayName = 'proto.themis.RefreshRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RegisterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.RegisterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RegisterRequest.displayName = 'proto.themis.RegisterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.UserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.UserRequest.repeatedFields_, null);
};
goog.inherits(proto.themis.UserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.UserRequest.displayName = 'proto.themis.UserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RegisterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.RegisterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RegisterResponse.displayName = 'proto.themis.RegisterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CheckEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.CheckEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CheckEmailRequest.displayName = 'proto.themis.CheckEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CheckEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.CheckEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CheckEmailResponse.displayName = 'proto.themis.CheckEmailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AddProducerLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AddProducerLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AddProducerLinkRequest.displayName = 'proto.themis.AddProducerLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ConnectProducerLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ConnectProducerLinkRequest.repeatedFields_, null);
};
goog.inherits(proto.themis.ConnectProducerLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ConnectProducerLinkRequest.displayName = 'proto.themis.ConnectProducerLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventGridRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventGridRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventGridRequest.displayName = 'proto.themis.EventGridRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventGridResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventGridResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventGridResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventGridResponse.displayName = 'proto.themis.EventGridResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ConnectProducerLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ConnectProducerLinkResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.ConnectProducerLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ConnectProducerLinkResponse.displayName = 'proto.themis.ConnectProducerLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProgramResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ProgramResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.ProgramResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProgramResponse.displayName = 'proto.themis.ProgramResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProgramUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ProgramUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProgramUser.displayName = 'proto.themis.ProgramUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.LocationUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.LocationUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.LocationUser.displayName = 'proto.themis.LocationUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.TeamUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.TeamUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.TeamUser.displayName = 'proto.themis.TeamUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProducerUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ProducerUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProducerUser.displayName = 'proto.themis.ProducerUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.BrandUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.BrandUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.BrandUser.displayName = 'proto.themis.BrandUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventUser.displayName = 'proto.themis.EventUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.UserPermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.UserPermissionsResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.UserPermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.UserPermissionsResponse.displayName = 'proto.themis.UserPermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.PermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.PermissionsResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.PermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.PermissionsResponse.displayName = 'proto.themis.PermissionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.LocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.LocationResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.LocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.LocationResponse.displayName = 'proto.themis.LocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DeleteLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.DeleteLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DeleteLocationRequest.displayName = 'proto.themis.DeleteLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.TeamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.TeamResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.TeamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.TeamResponse.displayName = 'proto.themis.TeamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AthleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.AthleteResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.AthleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AthleteResponse.displayName = 'proto.themis.AthleteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AddAthleteListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.AddAthleteListRequest.repeatedFields_, null);
};
goog.inherits(proto.themis.AddAthleteListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AddAthleteListRequest.displayName = 'proto.themis.AddAthleteListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AthleteLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AthleteLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AthleteLocationRequest.displayName = 'proto.themis.AthleteLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AthleteTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AthleteTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AthleteTeamRequest.displayName = 'proto.themis.AthleteTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AthletesTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.AthletesTeamRequest.repeatedFields_, null);
};
goog.inherits(proto.themis.AthletesTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AthletesTeamRequest.displayName = 'proto.themis.AthletesTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GuardianAthleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.GuardianAthleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GuardianAthleteRequest.displayName = 'proto.themis.GuardianAthleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GuardianResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.GuardianResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.GuardianResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GuardianResponse.displayName = 'proto.themis.GuardianResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CoachLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.CoachLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CoachLocationRequest.displayName = 'proto.themis.CoachLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CoachTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.CoachTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CoachTeamRequest.displayName = 'proto.themis.CoachTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CoachResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.CoachResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.CoachResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CoachResponse.displayName = 'proto.themis.CoachResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.PersonnelLocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.PersonnelLocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.PersonnelLocationRequest.displayName = 'proto.themis.PersonnelLocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.PersonnelTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.PersonnelTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.PersonnelTeamRequest.displayName = 'proto.themis.PersonnelTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.PersonnelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.PersonnelResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.PersonnelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.PersonnelResponse.displayName = 'proto.themis.PersonnelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProducerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ProducerResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.ProducerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProducerResponse.displayName = 'proto.themis.ProducerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.BrandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.BrandResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.BrandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.BrandResponse.displayName = 'proto.themis.BrandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventResponse.displayName = 'proto.themis.EventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CountEventEmailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.CountEventEmailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CountEventEmailsResponse.displayName = 'proto.themis.CountEventEmailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AddEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AddEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AddEventRequest.displayName = 'proto.themis.AddEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.UpdateEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.UpdateEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.UpdateEventRequest.displayName = 'proto.themis.UpdateEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DeleteEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.DeleteEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DeleteEventRequest.displayName = 'proto.themis.DeleteEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.UpdateEventRegistrationCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.UpdateEventRegistrationCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.UpdateEventRegistrationCodeRequest.displayName = 'proto.themis.UpdateEventRegistrationCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DeleteEventRegistrationCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.DeleteEventRegistrationCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DeleteEventRegistrationCodeRequest.displayName = 'proto.themis.DeleteEventRegistrationCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AddEventRegistrationCodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AddEventRegistrationCodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AddEventRegistrationCodeRequest.displayName = 'proto.themis.AddEventRegistrationCodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventRegistrationCodesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventRegistrationCodesResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventRegistrationCodesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventRegistrationCodesResponse.displayName = 'proto.themis.EventRegistrationCodesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventRegistrationPreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventRegistrationPreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventRegistrationPreRequest.displayName = 'proto.themis.EventRegistrationPreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventRegistrationPreResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventRegistrationPreResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventRegistrationPreResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventRegistrationPreResponse.displayName = 'proto.themis.EventRegistrationPreResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ScrapeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ScrapeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ScrapeRequest.displayName = 'proto.themis.ScrapeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ScrapeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ScrapeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ScrapeResponse.displayName = 'proto.themis.ScrapeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.UpdatePasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.UpdatePasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.UpdatePasswordRequest.displayName = 'proto.themis.UpdatePasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.UpdatePasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.UpdatePasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.UpdatePasswordResponse.displayName = 'proto.themis.UpdatePasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AddPaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.AddPaymentRequest.repeatedFields_, null);
};
goog.inherits(proto.themis.AddPaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AddPaymentRequest.displayName = 'proto.themis.AddPaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AddPaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AddPaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AddPaymentResponse.displayName = 'proto.themis.AddPaymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GetCreditDepositByFinancialTransactionIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.GetCreditDepositByFinancialTransactionIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GetCreditDepositByFinancialTransactionIDRequest.displayName = 'proto.themis.GetCreditDepositByFinancialTransactionIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AddFinancialTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AddFinancialTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AddFinancialTransactionRequest.displayName = 'proto.themis.AddFinancialTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AddFinancialTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AddFinancialTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AddFinancialTransactionResponse.displayName = 'proto.themis.AddFinancialTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ChargePaymentSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ChargePaymentSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ChargePaymentSourceResponse.displayName = 'proto.themis.ChargePaymentSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.StripePKResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.StripePKResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.StripePKResponse.displayName = 'proto.themis.StripePKResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GetAccountBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.GetAccountBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GetAccountBalanceRequest.displayName = 'proto.themis.GetAccountBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GetAccountBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.GetAccountBalanceResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.GetAccountBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GetAccountBalanceResponse.displayName = 'proto.themis.GetAccountBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GetCurrentDepositResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.GetCurrentDepositResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GetCurrentDepositResponse.displayName = 'proto.themis.GetCurrentDepositResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GetEventDatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.GetEventDatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GetEventDatesRequest.displayName = 'proto.themis.GetEventDatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventDatesResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventDatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDatesResponse.displayName = 'proto.themis.EventDatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AllStarTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.AllStarTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.AllStarTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AllStarTypesResponse.displayName = 'proto.themis.AllStarTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.TiersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.TiersResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.TiersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.TiersResponse.displayName = 'proto.themis.TiersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CategoriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.CategoriesResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.CategoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CategoriesResponse.displayName = 'proto.themis.CategoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DivisionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.DivisionsResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.DivisionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DivisionsResponse.displayName = 'proto.themis.DivisionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventFloorsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventFloorsResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventFloorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventFloorsResponse.displayName = 'proto.themis.EventFloorsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.SortFloorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.SortFloorsRequest.repeatedFields_, null);
};
goog.inherits(proto.themis.SortFloorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.SortFloorsRequest.displayName = 'proto.themis.SortFloorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventTeamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventTeamResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventTeamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventTeamResponse.displayName = 'proto.themis.EventTeamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.GetEventTeamRegistrationTransactionsResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.GetEventTeamRegistrationTransactionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GetEventTeamRegistrationTransactionsResponse.displayName = 'proto.themis.GetEventTeamRegistrationTransactionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AthleteEventTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AthleteEventTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AthleteEventTeamRequest.displayName = 'proto.themis.AthleteEventTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.SubstituteAthleteEventTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.SubstituteAthleteEventTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.SubstituteAthleteEventTeamRequest.displayName = 'proto.themis.SubstituteAthleteEventTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventDivisionResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventDivisionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionResponse.displayName = 'proto.themis.EventDivisionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionUsageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventDivisionUsageResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventDivisionUsageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionUsageResponse.displayName = 'proto.themis.EventDivisionUsageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GetSeasonsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.GetSeasonsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GetSeasonsRequest.displayName = 'proto.themis.GetSeasonsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.SeasonsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.SeasonsResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.SeasonsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.SeasonsResponse.displayName = 'proto.themis.SeasonsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CoachEventTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.CoachEventTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CoachEventTeamRequest.displayName = 'proto.themis.CoachEventTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DivisionImportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.DivisionImportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DivisionImportRequest.displayName = 'proto.themis.DivisionImportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.PersonnelEventTeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.PersonnelEventTeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.PersonnelEventTeamRequest.displayName = 'proto.themis.PersonnelEventTeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.TeamScoringAuthorityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.TeamScoringAuthorityResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.TeamScoringAuthorityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.TeamScoringAuthorityResponse.displayName = 'proto.themis.TeamScoringAuthorityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.JudgeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.JudgeResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.JudgeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.JudgeResponse.displayName = 'proto.themis.JudgeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.JudgeBrandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.JudgeBrandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.JudgeBrandRequest.displayName = 'proto.themis.JudgeBrandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.JudgeProducerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.JudgeProducerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.JudgeProducerRequest.displayName = 'proto.themis.JudgeProducerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.BugReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.BugReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.BugReport.displayName = 'proto.themis.BugReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AddStripeSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AddStripeSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AddStripeSourceRequest.displayName = 'proto.themis.AddStripeSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.VerifyACHSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.VerifyACHSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.VerifyACHSourceRequest.displayName = 'proto.themis.VerifyACHSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ReportRequest.displayName = 'proto.themis.ReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.BasicReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.BasicReportResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.BasicReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.BasicReportResponse.displayName = 'proto.themis.BasicReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.BasicReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.BasicReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.BasicReport.displayName = 'proto.themis.BasicReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CreateStripeProducerAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.CreateStripeProducerAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CreateStripeProducerAccountResponse.displayName = 'proto.themis.CreateStripeProducerAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GetRegistrationTransactionsByIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.GetRegistrationTransactionsByIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.themis.GetRegistrationTransactionsByIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GetRegistrationTransactionsByIdsRequest.displayName = 'proto.themis.GetRegistrationTransactionsByIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GetRegistrationTransactionsByIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.GetRegistrationTransactionsByIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.GetRegistrationTransactionsByIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GetRegistrationTransactionsByIdsResponse.displayName = 'proto.themis.GetRegistrationTransactionsByIdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.displayName = 'proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.displayName = 'proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DocumentSignatureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.DocumentSignatureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DocumentSignatureRequest.displayName = 'proto.themis.DocumentSignatureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DocumentSignatureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.DocumentSignatureResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.DocumentSignatureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DocumentSignatureResponse.displayName = 'proto.themis.DocumentSignatureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.DocumentResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.DocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DocumentResponse.displayName = 'proto.themis.DocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDocumentDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventDocumentDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDocumentDetail.displayName = 'proto.themis.EventDocumentDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventDocumentResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDocumentResponse.displayName = 'proto.themis.EventDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CreditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.CreditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CreditRequest.displayName = 'proto.themis.CreditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CreditResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.CreditResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CreditResponse.displayName = 'proto.themis.CreditResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.SignatureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.SignatureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.SignatureRequest.displayName = 'proto.themis.SignatureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ElectSigAgreement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ElectSigAgreement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ElectSigAgreement.displayName = 'proto.themis.ElectSigAgreement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ElectSigAgreementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ElectSigAgreementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ElectSigAgreementResponse.displayName = 'proto.themis.ElectSigAgreementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.SignatureQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.SignatureQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.themis.SignatureQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.SignatureQueryRequest.displayName = 'proto.themis.SignatureQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.SignatureQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.SignatureQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.SignatureQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.SignatureQueryResponse.displayName = 'proto.themis.SignatureQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AddEventDivisionPricingBulkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.AddEventDivisionPricingBulkRequest.repeatedFields_, null);
};
goog.inherits(proto.themis.AddEventDivisionPricingBulkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AddEventDivisionPricingBulkRequest.displayName = 'proto.themis.AddEventDivisionPricingBulkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RegistrationActivityLogsByProducerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.RegistrationActivityLogsByProducerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RegistrationActivityLogsByProducerRequest.displayName = 'proto.themis.RegistrationActivityLogsByProducerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RegistrationActivityLogsByEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.RegistrationActivityLogsByEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RegistrationActivityLogsByEventRequest.displayName = 'proto.themis.RegistrationActivityLogsByEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RegistrationActivityLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.RegistrationActivityLogsResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.RegistrationActivityLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RegistrationActivityLogsResponse.displayName = 'proto.themis.RegistrationActivityLogsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventTeamSignStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventTeamSignStatus.repeatedFields_, null);
};
goog.inherits(proto.themis.EventTeamSignStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventTeamSignStatus.displayName = 'proto.themis.EventTeamSignStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ResendEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ResendEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ResendEmailRequest.displayName = 'proto.themis.ResendEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.CodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CodeRequest.displayName = 'proto.themis.CodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.TeamGenerationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.TeamGenerationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.TeamGenerationRequest.displayName = 'proto.themis.TeamGenerationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.FixScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.FixScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.FixScheduleRequest.displayName = 'proto.themis.FixScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.FixScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.FixScheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.FixScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.FixScheduleResponse.displayName = 'proto.themis.FixScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.LoadMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.LoadMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.LoadMessage.displayName = 'proto.themis.LoadMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionShifts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventDivisionShifts.repeatedFields_, null);
};
goog.inherits(proto.themis.EventDivisionShifts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionShifts.displayName = 'proto.themis.EventDivisionShifts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionShiftTeamReturn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventDivisionShiftTeamReturn.repeatedFields_, null);
};
goog.inherits(proto.themis.EventDivisionShiftTeamReturn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionShiftTeamReturn.displayName = 'proto.themis.EventDivisionShiftTeamReturn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionShiftTeamsReturn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventDivisionShiftTeamsReturn.repeatedFields_, null);
};
goog.inherits(proto.themis.EventDivisionShiftTeamsReturn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionShiftTeamsReturn.displayName = 'proto.themis.EventDivisionShiftTeamsReturn';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.TeamReport.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.TeamReport.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.TeamReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.TeamReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    themis_common_pb.EventDivisionShiftTeam.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.TeamReport}
 */
proto.themis.TeamReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.TeamReport;
  return proto.themis.TeamReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.TeamReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.TeamReport}
 */
proto.themis.TeamReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventDivisionShiftTeam;
      reader.readMessage(value,themis_common_pb.EventDivisionShiftTeam.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.TeamReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.TeamReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.TeamReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventDivisionShiftTeam.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventDivisionShiftTeam teams = 1;
 * @return {!Array<!proto.themis.EventDivisionShiftTeam>}
 */
proto.themis.TeamReport.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.themis.EventDivisionShiftTeam>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventDivisionShiftTeam, 1));
};


/**
 * @param {!Array<!proto.themis.EventDivisionShiftTeam>} value
 * @return {!proto.themis.TeamReport} returns this
*/
proto.themis.TeamReport.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivisionShiftTeam}
 */
proto.themis.TeamReport.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventDivisionShiftTeam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.TeamReport} returns this
 */
proto.themis.TeamReport.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.StatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.StatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.StatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.StatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.StatusResponse}
 */
proto.themis.StatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.StatusResponse;
  return proto.themis.StatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.StatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.StatusResponse}
 */
proto.themis.StatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.StatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.StatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.StatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.StatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.themis.StatusResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.StatusResponse} returns this
 */
proto.themis.StatusResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ShiftScores.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ShiftScores.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ShiftScores.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ShiftScores} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ShiftScores.toObject = function(includeInstance, msg) {
  var f, obj = {
    shift: (f = msg.getShift()) && themis_common_pb.EventFloorDateJudgingPanelShift.toObject(includeInstance, f),
    panel: (f = msg.getPanel()) && themis_common_pb.EventFloorDateJudgingPanel.toObject(includeInstance, f),
    divisionScoresList: jspb.Message.toObjectList(msg.getDivisionScoresList(),
    proto.themis.DivisionTeamScores.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ShiftScores}
 */
proto.themis.ShiftScores.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ShiftScores;
  return proto.themis.ShiftScores.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ShiftScores} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ShiftScores}
 */
proto.themis.ShiftScores.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventFloorDateJudgingPanelShift;
      reader.readMessage(value,themis_common_pb.EventFloorDateJudgingPanelShift.deserializeBinaryFromReader);
      msg.setShift(value);
      break;
    case 2:
      var value = new themis_common_pb.EventFloorDateJudgingPanel;
      reader.readMessage(value,themis_common_pb.EventFloorDateJudgingPanel.deserializeBinaryFromReader);
      msg.setPanel(value);
      break;
    case 3:
      var value = new proto.themis.DivisionTeamScores;
      reader.readMessage(value,proto.themis.DivisionTeamScores.deserializeBinaryFromReader);
      msg.addDivisionScores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ShiftScores.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ShiftScores.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ShiftScores} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ShiftScores.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShift();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.EventFloorDateJudgingPanelShift.serializeBinaryToWriter
    );
  }
  f = message.getPanel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.EventFloorDateJudgingPanel.serializeBinaryToWriter
    );
  }
  f = message.getDivisionScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.themis.DivisionTeamScores.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventFloorDateJudgingPanelShift shift = 1;
 * @return {?proto.themis.EventFloorDateJudgingPanelShift}
 */
proto.themis.ShiftScores.prototype.getShift = function() {
  return /** @type{?proto.themis.EventFloorDateJudgingPanelShift} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventFloorDateJudgingPanelShift, 1));
};


/**
 * @param {?proto.themis.EventFloorDateJudgingPanelShift|undefined} value
 * @return {!proto.themis.ShiftScores} returns this
*/
proto.themis.ShiftScores.prototype.setShift = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ShiftScores} returns this
 */
proto.themis.ShiftScores.prototype.clearShift = function() {
  return this.setShift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ShiftScores.prototype.hasShift = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EventFloorDateJudgingPanel panel = 2;
 * @return {?proto.themis.EventFloorDateJudgingPanel}
 */
proto.themis.ShiftScores.prototype.getPanel = function() {
  return /** @type{?proto.themis.EventFloorDateJudgingPanel} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventFloorDateJudgingPanel, 2));
};


/**
 * @param {?proto.themis.EventFloorDateJudgingPanel|undefined} value
 * @return {!proto.themis.ShiftScores} returns this
*/
proto.themis.ShiftScores.prototype.setPanel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ShiftScores} returns this
 */
proto.themis.ShiftScores.prototype.clearPanel = function() {
  return this.setPanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ShiftScores.prototype.hasPanel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated DivisionTeamScores division_scores = 3;
 * @return {!Array<!proto.themis.DivisionTeamScores>}
 */
proto.themis.ShiftScores.prototype.getDivisionScoresList = function() {
  return /** @type{!Array<!proto.themis.DivisionTeamScores>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.DivisionTeamScores, 3));
};


/**
 * @param {!Array<!proto.themis.DivisionTeamScores>} value
 * @return {!proto.themis.ShiftScores} returns this
*/
proto.themis.ShiftScores.prototype.setDivisionScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.DivisionTeamScores=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.DivisionTeamScores}
 */
proto.themis.ShiftScores.prototype.addDivisionScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.DivisionTeamScores, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ShiftScores} returns this
 */
proto.themis.ShiftScores.prototype.clearDivisionScoresList = function() {
  return this.setDivisionScoresList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DivisionTeamScores.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DivisionTeamScores.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DivisionTeamScores} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionTeamScores.toObject = function(includeInstance, msg) {
  var f, obj = {
    division: (f = msg.getDivision()) && themis_common_pb.EventDivision.toObject(includeInstance, f),
    teamScores: (f = msg.getTeamScores()) && proto.themis.TeamScores.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DivisionTeamScores}
 */
proto.themis.DivisionTeamScores.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DivisionTeamScores;
  return proto.themis.DivisionTeamScores.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DivisionTeamScores} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DivisionTeamScores}
 */
proto.themis.DivisionTeamScores.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventDivision;
      reader.readMessage(value,themis_common_pb.EventDivision.deserializeBinaryFromReader);
      msg.setDivision(value);
      break;
    case 2:
      var value = new proto.themis.TeamScores;
      reader.readMessage(value,proto.themis.TeamScores.deserializeBinaryFromReader);
      msg.setTeamScores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DivisionTeamScores.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DivisionTeamScores.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DivisionTeamScores} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionTeamScores.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDivision();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.EventDivision.serializeBinaryToWriter
    );
  }
  f = message.getTeamScores();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.TeamScores.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventDivision division = 1;
 * @return {?proto.themis.EventDivision}
 */
proto.themis.DivisionTeamScores.prototype.getDivision = function() {
  return /** @type{?proto.themis.EventDivision} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventDivision, 1));
};


/**
 * @param {?proto.themis.EventDivision|undefined} value
 * @return {!proto.themis.DivisionTeamScores} returns this
*/
proto.themis.DivisionTeamScores.prototype.setDivision = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DivisionTeamScores} returns this
 */
proto.themis.DivisionTeamScores.prototype.clearDivision = function() {
  return this.setDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DivisionTeamScores.prototype.hasDivision = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TeamScores team_scores = 2;
 * @return {?proto.themis.TeamScores}
 */
proto.themis.DivisionTeamScores.prototype.getTeamScores = function() {
  return /** @type{?proto.themis.TeamScores} */ (
    jspb.Message.getWrapperField(this, proto.themis.TeamScores, 2));
};


/**
 * @param {?proto.themis.TeamScores|undefined} value
 * @return {!proto.themis.DivisionTeamScores} returns this
*/
proto.themis.DivisionTeamScores.prototype.setTeamScores = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DivisionTeamScores} returns this
 */
proto.themis.DivisionTeamScores.prototype.clearTeamScores = function() {
  return this.setTeamScores(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DivisionTeamScores.prototype.hasTeamScores = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.TeamScores.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.TeamScores.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.TeamScores.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.TeamScores} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamScores.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamScoresList: jspb.Message.toObjectList(msg.getTeamScoresList(),
    proto.themis.TeamScore.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.TeamScores}
 */
proto.themis.TeamScores.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.TeamScores;
  return proto.themis.TeamScores.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.TeamScores} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.TeamScores}
 */
proto.themis.TeamScores.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.TeamScore;
      reader.readMessage(value,proto.themis.TeamScore.deserializeBinaryFromReader);
      msg.addTeamScores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.TeamScores.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.TeamScores.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.TeamScores} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamScores.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.themis.TeamScore.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TeamScore team_scores = 1;
 * @return {!Array<!proto.themis.TeamScore>}
 */
proto.themis.TeamScores.prototype.getTeamScoresList = function() {
  return /** @type{!Array<!proto.themis.TeamScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.TeamScore, 1));
};


/**
 * @param {!Array<!proto.themis.TeamScore>} value
 * @return {!proto.themis.TeamScores} returns this
*/
proto.themis.TeamScores.prototype.setTeamScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.TeamScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.TeamScore}
 */
proto.themis.TeamScores.prototype.addTeamScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.TeamScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.TeamScores} returns this
 */
proto.themis.TeamScores.prototype.clearTeamScoresList = function() {
  return this.setTeamScoresList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.TeamScore.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.TeamScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.TeamScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    judgeCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rank: jspb.Message.getFieldWithDefault(msg, 3, 0),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    mescore: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    program: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.TeamScore}
 */
proto.themis.TeamScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.TeamScore;
  return proto.themis.TeamScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.TeamScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.TeamScore}
 */
proto.themis.TeamScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setJudgeCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRank(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMescore(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgram(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.TeamScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.TeamScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.TeamScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getJudgeCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMescore();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProgram();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional uint32 team_id = 1;
 * @return {number}
 */
proto.themis.TeamScore.prototype.getTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.TeamScore} returns this
 */
proto.themis.TeamScore.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 judge_count = 2;
 * @return {number}
 */
proto.themis.TeamScore.prototype.getJudgeCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.TeamScore} returns this
 */
proto.themis.TeamScore.prototype.setJudgeCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 rank = 3;
 * @return {number}
 */
proto.themis.TeamScore.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.TeamScore} returns this
 */
proto.themis.TeamScore.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float score = 4;
 * @return {number}
 */
proto.themis.TeamScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.TeamScore} returns this
 */
proto.themis.TeamScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool meScore = 5;
 * @return {boolean}
 */
proto.themis.TeamScore.prototype.getMescore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.TeamScore} returns this
 */
proto.themis.TeamScore.prototype.setMescore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.themis.TeamScore.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.TeamScore} returns this
 */
proto.themis.TeamScore.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string program = 7;
 * @return {string}
 */
proto.themis.TeamScore.prototype.getProgram = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.TeamScore} returns this
 */
proto.themis.TeamScore.prototype.setProgram = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.GetPermissionsResponse.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GetPermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GetPermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GetPermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetPermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    producerPermissionsList: jspb.Message.toObjectList(msg.getProducerPermissionsList(),
    themis_common_pb.Permission.toObject, includeInstance),
    locationPermissionsList: jspb.Message.toObjectList(msg.getLocationPermissionsList(),
    themis_common_pb.Permission.toObject, includeInstance),
    teamPermissionsList: jspb.Message.toObjectList(msg.getTeamPermissionsList(),
    themis_common_pb.Permission.toObject, includeInstance),
    programPermissionsList: jspb.Message.toObjectList(msg.getProgramPermissionsList(),
    themis_common_pb.Permission.toObject, includeInstance),
    brandPermissionsList: jspb.Message.toObjectList(msg.getBrandPermissionsList(),
    themis_common_pb.Permission.toObject, includeInstance),
    eventPermissionsList: jspb.Message.toObjectList(msg.getEventPermissionsList(),
    themis_common_pb.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GetPermissionsResponse}
 */
proto.themis.GetPermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GetPermissionsResponse;
  return proto.themis.GetPermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GetPermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GetPermissionsResponse}
 */
proto.themis.GetPermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Permission;
      reader.readMessage(value,themis_common_pb.Permission.deserializeBinaryFromReader);
      msg.addProducerPermissions(value);
      break;
    case 2:
      var value = new themis_common_pb.Permission;
      reader.readMessage(value,themis_common_pb.Permission.deserializeBinaryFromReader);
      msg.addLocationPermissions(value);
      break;
    case 3:
      var value = new themis_common_pb.Permission;
      reader.readMessage(value,themis_common_pb.Permission.deserializeBinaryFromReader);
      msg.addTeamPermissions(value);
      break;
    case 4:
      var value = new themis_common_pb.Permission;
      reader.readMessage(value,themis_common_pb.Permission.deserializeBinaryFromReader);
      msg.addProgramPermissions(value);
      break;
    case 5:
      var value = new themis_common_pb.Permission;
      reader.readMessage(value,themis_common_pb.Permission.deserializeBinaryFromReader);
      msg.addBrandPermissions(value);
      break;
    case 6:
      var value = new themis_common_pb.Permission;
      reader.readMessage(value,themis_common_pb.Permission.deserializeBinaryFromReader);
      msg.addEventPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GetPermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GetPermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GetPermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetPermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProducerPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Permission.serializeBinaryToWriter
    );
  }
  f = message.getLocationPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      themis_common_pb.Permission.serializeBinaryToWriter
    );
  }
  f = message.getTeamPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      themis_common_pb.Permission.serializeBinaryToWriter
    );
  }
  f = message.getProgramPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      themis_common_pb.Permission.serializeBinaryToWriter
    );
  }
  f = message.getBrandPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      themis_common_pb.Permission.serializeBinaryToWriter
    );
  }
  f = message.getEventPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      themis_common_pb.Permission.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Permission producer_permissions = 1;
 * @return {!Array<!proto.themis.Permission>}
 */
proto.themis.GetPermissionsResponse.prototype.getProducerPermissionsList = function() {
  return /** @type{!Array<!proto.themis.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Permission, 1));
};


/**
 * @param {!Array<!proto.themis.Permission>} value
 * @return {!proto.themis.GetPermissionsResponse} returns this
*/
proto.themis.GetPermissionsResponse.prototype.setProducerPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Permission}
 */
proto.themis.GetPermissionsResponse.prototype.addProducerPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GetPermissionsResponse} returns this
 */
proto.themis.GetPermissionsResponse.prototype.clearProducerPermissionsList = function() {
  return this.setProducerPermissionsList([]);
};


/**
 * repeated Permission location_permissions = 2;
 * @return {!Array<!proto.themis.Permission>}
 */
proto.themis.GetPermissionsResponse.prototype.getLocationPermissionsList = function() {
  return /** @type{!Array<!proto.themis.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Permission, 2));
};


/**
 * @param {!Array<!proto.themis.Permission>} value
 * @return {!proto.themis.GetPermissionsResponse} returns this
*/
proto.themis.GetPermissionsResponse.prototype.setLocationPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Permission}
 */
proto.themis.GetPermissionsResponse.prototype.addLocationPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GetPermissionsResponse} returns this
 */
proto.themis.GetPermissionsResponse.prototype.clearLocationPermissionsList = function() {
  return this.setLocationPermissionsList([]);
};


/**
 * repeated Permission team_permissions = 3;
 * @return {!Array<!proto.themis.Permission>}
 */
proto.themis.GetPermissionsResponse.prototype.getTeamPermissionsList = function() {
  return /** @type{!Array<!proto.themis.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Permission, 3));
};


/**
 * @param {!Array<!proto.themis.Permission>} value
 * @return {!proto.themis.GetPermissionsResponse} returns this
*/
proto.themis.GetPermissionsResponse.prototype.setTeamPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Permission}
 */
proto.themis.GetPermissionsResponse.prototype.addTeamPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GetPermissionsResponse} returns this
 */
proto.themis.GetPermissionsResponse.prototype.clearTeamPermissionsList = function() {
  return this.setTeamPermissionsList([]);
};


/**
 * repeated Permission program_permissions = 4;
 * @return {!Array<!proto.themis.Permission>}
 */
proto.themis.GetPermissionsResponse.prototype.getProgramPermissionsList = function() {
  return /** @type{!Array<!proto.themis.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Permission, 4));
};


/**
 * @param {!Array<!proto.themis.Permission>} value
 * @return {!proto.themis.GetPermissionsResponse} returns this
*/
proto.themis.GetPermissionsResponse.prototype.setProgramPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Permission}
 */
proto.themis.GetPermissionsResponse.prototype.addProgramPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GetPermissionsResponse} returns this
 */
proto.themis.GetPermissionsResponse.prototype.clearProgramPermissionsList = function() {
  return this.setProgramPermissionsList([]);
};


/**
 * repeated Permission brand_permissions = 5;
 * @return {!Array<!proto.themis.Permission>}
 */
proto.themis.GetPermissionsResponse.prototype.getBrandPermissionsList = function() {
  return /** @type{!Array<!proto.themis.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Permission, 5));
};


/**
 * @param {!Array<!proto.themis.Permission>} value
 * @return {!proto.themis.GetPermissionsResponse} returns this
*/
proto.themis.GetPermissionsResponse.prototype.setBrandPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.themis.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Permission}
 */
proto.themis.GetPermissionsResponse.prototype.addBrandPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.themis.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GetPermissionsResponse} returns this
 */
proto.themis.GetPermissionsResponse.prototype.clearBrandPermissionsList = function() {
  return this.setBrandPermissionsList([]);
};


/**
 * repeated Permission event_permissions = 6;
 * @return {!Array<!proto.themis.Permission>}
 */
proto.themis.GetPermissionsResponse.prototype.getEventPermissionsList = function() {
  return /** @type{!Array<!proto.themis.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Permission, 6));
};


/**
 * @param {!Array<!proto.themis.Permission>} value
 * @return {!proto.themis.GetPermissionsResponse} returns this
*/
proto.themis.GetPermissionsResponse.prototype.setEventPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.themis.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Permission}
 */
proto.themis.GetPermissionsResponse.prototype.addEventPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.themis.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GetPermissionsResponse} returns this
 */
proto.themis.GetPermissionsResponse.prototype.clearEventPermissionsList = function() {
  return this.setEventPermissionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ProducerPermissions.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProducerPermissions.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProducerPermissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProducerPermissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerPermissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    permission: (f = msg.getPermission()) && proto.themis.Permissions.toObject(includeInstance, f),
    brandsList: jspb.Message.toObjectList(msg.getBrandsList(),
    proto.themis.Permissions.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProducerPermissions}
 */
proto.themis.ProducerPermissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProducerPermissions;
  return proto.themis.ProducerPermissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProducerPermissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProducerPermissions}
 */
proto.themis.ProducerPermissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.Permissions;
      reader.readMessage(value,proto.themis.Permissions.deserializeBinaryFromReader);
      msg.setPermission(value);
      break;
    case 2:
      var value = new proto.themis.Permissions;
      reader.readMessage(value,proto.themis.Permissions.deserializeBinaryFromReader);
      msg.addBrands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProducerPermissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProducerPermissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProducerPermissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerPermissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermission();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.themis.Permissions.serializeBinaryToWriter
    );
  }
  f = message.getBrandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.themis.Permissions.serializeBinaryToWriter
    );
  }
};


/**
 * optional Permissions permission = 1;
 * @return {?proto.themis.Permissions}
 */
proto.themis.ProducerPermissions.prototype.getPermission = function() {
  return /** @type{?proto.themis.Permissions} */ (
    jspb.Message.getWrapperField(this, proto.themis.Permissions, 1));
};


/**
 * @param {?proto.themis.Permissions|undefined} value
 * @return {!proto.themis.ProducerPermissions} returns this
*/
proto.themis.ProducerPermissions.prototype.setPermission = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ProducerPermissions} returns this
 */
proto.themis.ProducerPermissions.prototype.clearPermission = function() {
  return this.setPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ProducerPermissions.prototype.hasPermission = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Permissions brands = 2;
 * @return {!Array<!proto.themis.Permissions>}
 */
proto.themis.ProducerPermissions.prototype.getBrandsList = function() {
  return /** @type{!Array<!proto.themis.Permissions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Permissions, 2));
};


/**
 * @param {!Array<!proto.themis.Permissions>} value
 * @return {!proto.themis.ProducerPermissions} returns this
*/
proto.themis.ProducerPermissions.prototype.setBrandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.Permissions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Permissions}
 */
proto.themis.ProducerPermissions.prototype.addBrands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.Permissions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ProducerPermissions} returns this
 */
proto.themis.ProducerPermissions.prototype.clearBrandsList = function() {
  return this.setBrandsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ProgramPermissions.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProgramPermissions.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProgramPermissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProgramPermissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramPermissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    permission: (f = msg.getPermission()) && proto.themis.Permissions.toObject(includeInstance, f),
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.themis.Permissions.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProgramPermissions}
 */
proto.themis.ProgramPermissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProgramPermissions;
  return proto.themis.ProgramPermissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProgramPermissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProgramPermissions}
 */
proto.themis.ProgramPermissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.Permissions;
      reader.readMessage(value,proto.themis.Permissions.deserializeBinaryFromReader);
      msg.setPermission(value);
      break;
    case 2:
      var value = new proto.themis.Permissions;
      reader.readMessage(value,proto.themis.Permissions.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProgramPermissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProgramPermissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProgramPermissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramPermissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermission();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.themis.Permissions.serializeBinaryToWriter
    );
  }
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.themis.Permissions.serializeBinaryToWriter
    );
  }
};


/**
 * optional Permissions permission = 1;
 * @return {?proto.themis.Permissions}
 */
proto.themis.ProgramPermissions.prototype.getPermission = function() {
  return /** @type{?proto.themis.Permissions} */ (
    jspb.Message.getWrapperField(this, proto.themis.Permissions, 1));
};


/**
 * @param {?proto.themis.Permissions|undefined} value
 * @return {!proto.themis.ProgramPermissions} returns this
*/
proto.themis.ProgramPermissions.prototype.setPermission = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ProgramPermissions} returns this
 */
proto.themis.ProgramPermissions.prototype.clearPermission = function() {
  return this.setPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ProgramPermissions.prototype.hasPermission = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Permissions locations = 2;
 * @return {!Array<!proto.themis.Permissions>}
 */
proto.themis.ProgramPermissions.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.themis.Permissions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Permissions, 2));
};


/**
 * @param {!Array<!proto.themis.Permissions>} value
 * @return {!proto.themis.ProgramPermissions} returns this
*/
proto.themis.ProgramPermissions.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.Permissions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Permissions}
 */
proto.themis.ProgramPermissions.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.Permissions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ProgramPermissions} returns this
 */
proto.themis.ProgramPermissions.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Permissions.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Permissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Permissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Permissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    role: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Permissions}
 */
proto.themis.Permissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Permissions;
  return proto.themis.Permissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Permissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Permissions}
 */
proto.themis.Permissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Permissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Permissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Permissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Permissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Permissions.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Permissions} returns this
 */
proto.themis.Permissions.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Permissions.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Permissions} returns this
 */
proto.themis.Permissions.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo = 3;
 * @return {string}
 */
proto.themis.Permissions.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Permissions} returns this
 */
proto.themis.Permissions.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string role = 4;
 * @return {string}
 */
proto.themis.Permissions.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Permissions} returns this
 */
proto.themis.Permissions.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EmptyMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EmptyMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EmptyMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EmptyMessage.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EmptyMessage}
 */
proto.themis.EmptyMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EmptyMessage;
  return proto.themis.EmptyMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EmptyMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EmptyMessage}
 */
proto.themis.EmptyMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EmptyMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EmptyMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EmptyMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EmptyMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.UserAuthRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.UserAuthRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.UserAuthRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UserAuthRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.UserAuthRequest}
 */
proto.themis.UserAuthRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.UserAuthRequest;
  return proto.themis.UserAuthRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.UserAuthRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.UserAuthRequest}
 */
proto.themis.UserAuthRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.UserAuthRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.UserAuthRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.UserAuthRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UserAuthRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.themis.UserAuthRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UserAuthRequest} returns this
 */
proto.themis.UserAuthRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.themis.UserAuthRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UserAuthRequest} returns this
 */
proto.themis.UserAuthRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.UserAuthResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.UserAuthResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.UserAuthResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UserAuthResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    jwt: jspb.Message.getFieldWithDefault(msg, 1, ""),
    refreshToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.UserAuthResponse}
 */
proto.themis.UserAuthResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.UserAuthResponse;
  return proto.themis.UserAuthResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.UserAuthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.UserAuthResponse}
 */
proto.themis.UserAuthResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJwt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.UserAuthResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.UserAuthResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.UserAuthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UserAuthResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJwt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRefreshToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string jwt = 1;
 * @return {string}
 */
proto.themis.UserAuthResponse.prototype.getJwt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UserAuthResponse} returns this
 */
proto.themis.UserAuthResponse.prototype.setJwt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string refresh_token = 2;
 * @return {string}
 */
proto.themis.UserAuthResponse.prototype.getRefreshToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UserAuthResponse} returns this
 */
proto.themis.UserAuthResponse.prototype.setRefreshToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ShareAuthResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ShareAuthResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ShareAuthResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ShareAuthResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiresAt: (f = msg.getExpiresAt()) && themis_common_pb.ClockTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ShareAuthResponse}
 */
proto.themis.ShareAuthResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ShareAuthResponse;
  return proto.themis.ShareAuthResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ShareAuthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ShareAuthResponse}
 */
proto.themis.ShareAuthResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = new themis_common_pb.ClockTime;
      reader.readMessage(value,themis_common_pb.ClockTime.deserializeBinaryFromReader);
      msg.setExpiresAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ShareAuthResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ShareAuthResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ShareAuthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ShareAuthResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiresAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.ClockTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.themis.ShareAuthResponse.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ShareAuthResponse} returns this
 */
proto.themis.ShareAuthResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ClockTime expires_at = 2;
 * @return {?proto.themis.ClockTime}
 */
proto.themis.ShareAuthResponse.prototype.getExpiresAt = function() {
  return /** @type{?proto.themis.ClockTime} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.ClockTime, 2));
};


/**
 * @param {?proto.themis.ClockTime|undefined} value
 * @return {!proto.themis.ShareAuthResponse} returns this
*/
proto.themis.ShareAuthResponse.prototype.setExpiresAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ShareAuthResponse} returns this
 */
proto.themis.ShareAuthResponse.prototype.clearExpiresAt = function() {
  return this.setExpiresAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ShareAuthResponse.prototype.hasExpiresAt = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AuthSharedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AuthSharedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AuthSharedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AuthSharedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AuthSharedRequest}
 */
proto.themis.AuthSharedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AuthSharedRequest;
  return proto.themis.AuthSharedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AuthSharedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AuthSharedRequest}
 */
proto.themis.AuthSharedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AuthSharedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AuthSharedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AuthSharedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AuthSharedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.themis.AuthSharedRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AuthSharedRequest} returns this
 */
proto.themis.AuthSharedRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RefreshRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RefreshRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RefreshRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RefreshRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    refreshToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RefreshRequest}
 */
proto.themis.RefreshRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RefreshRequest;
  return proto.themis.RefreshRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RefreshRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RefreshRequest}
 */
proto.themis.RefreshRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RefreshRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RefreshRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RefreshRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RefreshRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefreshToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string refresh_token = 2;
 * @return {string}
 */
proto.themis.RefreshRequest.prototype.getRefreshToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RefreshRequest} returns this
 */
proto.themis.RefreshRequest.prototype.setRefreshToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RegisterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RegisterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RegisterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegisterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    birthDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    password: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    agreedToTerms: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RegisterRequest}
 */
proto.themis.RegisterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RegisterRequest;
  return proto.themis.RegisterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RegisterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RegisterRequest}
 */
proto.themis.RegisterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAgreedToTerms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RegisterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RegisterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RegisterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegisterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBirthDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAgreedToTerms();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.themis.RegisterRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegisterRequest} returns this
 */
proto.themis.RegisterRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.themis.RegisterRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegisterRequest} returns this
 */
proto.themis.RegisterRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.themis.RegisterRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegisterRequest} returns this
 */
proto.themis.RegisterRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string birth_date = 4;
 * @return {string}
 */
proto.themis.RegisterRequest.prototype.getBirthDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegisterRequest} returns this
 */
proto.themis.RegisterRequest.prototype.setBirthDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.themis.RegisterRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegisterRequest} returns this
 */
proto.themis.RegisterRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string password = 6;
 * @return {string}
 */
proto.themis.RegisterRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegisterRequest} returns this
 */
proto.themis.RegisterRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ip_address = 7;
 * @return {string}
 */
proto.themis.RegisterRequest.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegisterRequest} returns this
 */
proto.themis.RegisterRequest.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool agreed_to_terms = 8;
 * @return {boolean}
 */
proto.themis.RegisterRequest.prototype.getAgreedToTerms = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.RegisterRequest} returns this
 */
proto.themis.RegisterRequest.prototype.setAgreedToTerms = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.UserRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.UserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.UserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.UserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    themis_common_pb.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.UserRequest}
 */
proto.themis.UserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.UserRequest;
  return proto.themis.UserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.UserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.UserRequest}
 */
proto.themis.UserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = new themis_common_pb.Permission;
      reader.readMessage(value,themis_common_pb.Permission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.UserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.UserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.UserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      themis_common_pb.Permission.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.themis.UserRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UserRequest} returns this
 */
proto.themis.UserRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.themis.UserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UserRequest} returns this
 */
proto.themis.UserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Permission permissions = 4;
 * @return {!Array<!proto.themis.Permission>}
 */
proto.themis.UserRequest.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.themis.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Permission, 4));
};


/**
 * @param {!Array<!proto.themis.Permission>} value
 * @return {!proto.themis.UserRequest} returns this
*/
proto.themis.UserRequest.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Permission}
 */
proto.themis.UserRequest.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.UserRequest} returns this
 */
proto.themis.UserRequest.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RegisterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RegisterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RegisterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegisterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RegisterResponse}
 */
proto.themis.RegisterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RegisterResponse;
  return proto.themis.RegisterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RegisterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RegisterResponse}
 */
proto.themis.RegisterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RegisterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RegisterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RegisterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegisterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.themis.RegisterResponse.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegisterResponse} returns this
 */
proto.themis.RegisterResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CheckEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CheckEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CheckEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CheckEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CheckEmailRequest}
 */
proto.themis.CheckEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CheckEmailRequest;
  return proto.themis.CheckEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CheckEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CheckEmailRequest}
 */
proto.themis.CheckEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CheckEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CheckEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CheckEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CheckEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.themis.CheckEmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.CheckEmailRequest} returns this
 */
proto.themis.CheckEmailRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CheckEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CheckEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CheckEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CheckEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    okay: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CheckEmailResponse}
 */
proto.themis.CheckEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CheckEmailResponse;
  return proto.themis.CheckEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CheckEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CheckEmailResponse}
 */
proto.themis.CheckEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOkay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CheckEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CheckEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CheckEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CheckEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOkay();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool okay = 1;
 * @return {boolean}
 */
proto.themis.CheckEmailResponse.prototype.getOkay = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.CheckEmailResponse} returns this
 */
proto.themis.CheckEmailResponse.prototype.setOkay = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AddProducerLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AddProducerLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AddProducerLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddProducerLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    producer: (f = msg.getProducer()) && themis_common_pb.Producer.toObject(includeInstance, f),
    link: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AddProducerLinkRequest}
 */
proto.themis.AddProducerLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AddProducerLinkRequest;
  return proto.themis.AddProducerLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AddProducerLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AddProducerLinkRequest}
 */
proto.themis.AddProducerLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Producer;
      reader.readMessage(value,themis_common_pb.Producer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AddProducerLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AddProducerLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AddProducerLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddProducerLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Producer.serializeBinaryToWriter
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Producer producer = 1;
 * @return {?proto.themis.Producer}
 */
proto.themis.AddProducerLinkRequest.prototype.getProducer = function() {
  return /** @type{?proto.themis.Producer} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Producer, 1));
};


/**
 * @param {?proto.themis.Producer|undefined} value
 * @return {!proto.themis.AddProducerLinkRequest} returns this
*/
proto.themis.AddProducerLinkRequest.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.AddProducerLinkRequest} returns this
 */
proto.themis.AddProducerLinkRequest.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.AddProducerLinkRequest.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string link = 2;
 * @return {string}
 */
proto.themis.AddProducerLinkRequest.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddProducerLinkRequest} returns this
 */
proto.themis.AddProducerLinkRequest.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ConnectProducerLinkRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ConnectProducerLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ConnectProducerLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ConnectProducerLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ConnectProducerLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: jspb.Message.getFieldWithDefault(msg, 1, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    programIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    producerName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ConnectProducerLinkRequest}
 */
proto.themis.ConnectProducerLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ConnectProducerLinkRequest;
  return proto.themis.ConnectProducerLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ConnectProducerLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ConnectProducerLinkRequest}
 */
proto.themis.ConnectProducerLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProgramIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ConnectProducerLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ConnectProducerLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ConnectProducerLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ConnectProducerLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getProgramIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
  f = message.getProducerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string link = 1;
 * @return {string}
 */
proto.themis.ConnectProducerLinkRequest.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ConnectProducerLinkRequest} returns this
 */
proto.themis.ConnectProducerLinkRequest.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 program_id = 2;
 * @return {number}
 */
proto.themis.ConnectProducerLinkRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ConnectProducerLinkRequest} returns this
 */
proto.themis.ConnectProducerLinkRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated uint32 program_ids = 3;
 * @return {!Array<number>}
 */
proto.themis.ConnectProducerLinkRequest.prototype.getProgramIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.themis.ConnectProducerLinkRequest} returns this
 */
proto.themis.ConnectProducerLinkRequest.prototype.setProgramIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.themis.ConnectProducerLinkRequest} returns this
 */
proto.themis.ConnectProducerLinkRequest.prototype.addProgramIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ConnectProducerLinkRequest} returns this
 */
proto.themis.ConnectProducerLinkRequest.prototype.clearProgramIdsList = function() {
  return this.setProgramIdsList([]);
};


/**
 * optional string producer_name = 4;
 * @return {string}
 */
proto.themis.ConnectProducerLinkRequest.prototype.getProducerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ConnectProducerLinkRequest} returns this
 */
proto.themis.ConnectProducerLinkRequest.prototype.setProducerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventGridRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventGridRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventGridRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventGridRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    program: (f = msg.getProgram()) && themis_common_pb.Program.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && themis_common_pb.Location.toObject(includeInstance, f),
    team: (f = msg.getTeam()) && themis_common_pb.Team.toObject(includeInstance, f),
    fromDate: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventGridRequest}
 */
proto.themis.EventGridRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventGridRequest;
  return proto.themis.EventGridRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventGridRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventGridRequest}
 */
proto.themis.EventGridRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Program;
      reader.readMessage(value,themis_common_pb.Program.deserializeBinaryFromReader);
      msg.setProgram(value);
      break;
    case 2:
      var value = new themis_common_pb.Location;
      reader.readMessage(value,themis_common_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 3:
      var value = new themis_common_pb.Team;
      reader.readMessage(value,themis_common_pb.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventGridRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventGridRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventGridRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventGridRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgram();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Program.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      themis_common_pb.Team.serializeBinaryToWriter
    );
  }
  f = message.getFromDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Program program = 1;
 * @return {?proto.themis.Program}
 */
proto.themis.EventGridRequest.prototype.getProgram = function() {
  return /** @type{?proto.themis.Program} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Program, 1));
};


/**
 * @param {?proto.themis.Program|undefined} value
 * @return {!proto.themis.EventGridRequest} returns this
*/
proto.themis.EventGridRequest.prototype.setProgram = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventGridRequest} returns this
 */
proto.themis.EventGridRequest.prototype.clearProgram = function() {
  return this.setProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventGridRequest.prototype.hasProgram = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Location location = 2;
 * @return {?proto.themis.Location}
 */
proto.themis.EventGridRequest.prototype.getLocation = function() {
  return /** @type{?proto.themis.Location} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Location, 2));
};


/**
 * @param {?proto.themis.Location|undefined} value
 * @return {!proto.themis.EventGridRequest} returns this
*/
proto.themis.EventGridRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventGridRequest} returns this
 */
proto.themis.EventGridRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventGridRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Team team = 3;
 * @return {?proto.themis.Team}
 */
proto.themis.EventGridRequest.prototype.getTeam = function() {
  return /** @type{?proto.themis.Team} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Team, 3));
};


/**
 * @param {?proto.themis.Team|undefined} value
 * @return {!proto.themis.EventGridRequest} returns this
*/
proto.themis.EventGridRequest.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventGridRequest} returns this
 */
proto.themis.EventGridRequest.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventGridRequest.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string from_date = 4;
 * @return {string}
 */
proto.themis.EventGridRequest.prototype.getFromDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventGridRequest} returns this
 */
proto.themis.EventGridRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventGridResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventGridResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventGridResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventGridResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventGridResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventTeamsList: jspb.Message.toObjectList(msg.getEventTeamsList(),
    themis_common_pb.EventTeam.toObject, includeInstance),
    eventTeam: (f = msg.getEventTeam()) && themis_common_pb.EventTeam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventGridResponse}
 */
proto.themis.EventGridResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventGridResponse;
  return proto.themis.EventGridResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventGridResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventGridResponse}
 */
proto.themis.EventGridResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventTeam;
      reader.readMessage(value,themis_common_pb.EventTeam.deserializeBinaryFromReader);
      msg.addEventTeams(value);
      break;
    case 2:
      var value = new themis_common_pb.EventTeam;
      reader.readMessage(value,themis_common_pb.EventTeam.deserializeBinaryFromReader);
      msg.setEventTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventGridResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventGridResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventGridResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventGridResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventTeam.serializeBinaryToWriter
    );
  }
  f = message.getEventTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.EventTeam.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventTeam event_teams = 1;
 * @return {!Array<!proto.themis.EventTeam>}
 */
proto.themis.EventGridResponse.prototype.getEventTeamsList = function() {
  return /** @type{!Array<!proto.themis.EventTeam>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventTeam, 1));
};


/**
 * @param {!Array<!proto.themis.EventTeam>} value
 * @return {!proto.themis.EventGridResponse} returns this
*/
proto.themis.EventGridResponse.prototype.setEventTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventTeam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventTeam}
 */
proto.themis.EventGridResponse.prototype.addEventTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventTeam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventGridResponse} returns this
 */
proto.themis.EventGridResponse.prototype.clearEventTeamsList = function() {
  return this.setEventTeamsList([]);
};


/**
 * optional EventTeam event_team = 2;
 * @return {?proto.themis.EventTeam}
 */
proto.themis.EventGridResponse.prototype.getEventTeam = function() {
  return /** @type{?proto.themis.EventTeam} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventTeam, 2));
};


/**
 * @param {?proto.themis.EventTeam|undefined} value
 * @return {!proto.themis.EventGridResponse} returns this
*/
proto.themis.EventGridResponse.prototype.setEventTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventGridResponse} returns this
 */
proto.themis.EventGridResponse.prototype.clearEventTeam = function() {
  return this.setEventTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventGridResponse.prototype.hasEventTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ConnectProducerLinkResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ConnectProducerLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ConnectProducerLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ConnectProducerLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ConnectProducerLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    programsList: jspb.Message.toObjectList(msg.getProgramsList(),
    proto.themis.ProgramPermissions.toObject, includeInstance),
    producer: (f = msg.getProducer()) && themis_common_pb.Producer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ConnectProducerLinkResponse}
 */
proto.themis.ConnectProducerLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ConnectProducerLinkResponse;
  return proto.themis.ConnectProducerLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ConnectProducerLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ConnectProducerLinkResponse}
 */
proto.themis.ConnectProducerLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.ProgramPermissions;
      reader.readMessage(value,proto.themis.ProgramPermissions.deserializeBinaryFromReader);
      msg.addPrograms(value);
      break;
    case 2:
      var value = new themis_common_pb.Producer;
      reader.readMessage(value,themis_common_pb.Producer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ConnectProducerLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ConnectProducerLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ConnectProducerLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ConnectProducerLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.themis.ProgramPermissions.serializeBinaryToWriter
    );
  }
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Producer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProgramPermissions programs = 1;
 * @return {!Array<!proto.themis.ProgramPermissions>}
 */
proto.themis.ConnectProducerLinkResponse.prototype.getProgramsList = function() {
  return /** @type{!Array<!proto.themis.ProgramPermissions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.ProgramPermissions, 1));
};


/**
 * @param {!Array<!proto.themis.ProgramPermissions>} value
 * @return {!proto.themis.ConnectProducerLinkResponse} returns this
*/
proto.themis.ConnectProducerLinkResponse.prototype.setProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.ProgramPermissions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.ProgramPermissions}
 */
proto.themis.ConnectProducerLinkResponse.prototype.addPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.ProgramPermissions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ConnectProducerLinkResponse} returns this
 */
proto.themis.ConnectProducerLinkResponse.prototype.clearProgramsList = function() {
  return this.setProgramsList([]);
};


/**
 * optional Producer producer = 2;
 * @return {?proto.themis.Producer}
 */
proto.themis.ConnectProducerLinkResponse.prototype.getProducer = function() {
  return /** @type{?proto.themis.Producer} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Producer, 2));
};


/**
 * @param {?proto.themis.Producer|undefined} value
 * @return {!proto.themis.ConnectProducerLinkResponse} returns this
*/
proto.themis.ConnectProducerLinkResponse.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ConnectProducerLinkResponse} returns this
 */
proto.themis.ConnectProducerLinkResponse.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ConnectProducerLinkResponse.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ProgramResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProgramResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProgramResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProgramResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    programsList: jspb.Message.toObjectList(msg.getProgramsList(),
    themis_common_pb.Program.toObject, includeInstance),
    program: (f = msg.getProgram()) && themis_common_pb.Program.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProgramResponse}
 */
proto.themis.ProgramResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProgramResponse;
  return proto.themis.ProgramResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProgramResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProgramResponse}
 */
proto.themis.ProgramResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Program;
      reader.readMessage(value,themis_common_pb.Program.deserializeBinaryFromReader);
      msg.addPrograms(value);
      break;
    case 2:
      var value = new themis_common_pb.Program;
      reader.readMessage(value,themis_common_pb.Program.deserializeBinaryFromReader);
      msg.setProgram(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProgramResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProgramResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProgramResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Program.serializeBinaryToWriter
    );
  }
  f = message.getProgram();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Program.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Program programs = 1;
 * @return {!Array<!proto.themis.Program>}
 */
proto.themis.ProgramResponse.prototype.getProgramsList = function() {
  return /** @type{!Array<!proto.themis.Program>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Program, 1));
};


/**
 * @param {!Array<!proto.themis.Program>} value
 * @return {!proto.themis.ProgramResponse} returns this
*/
proto.themis.ProgramResponse.prototype.setProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Program=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Program}
 */
proto.themis.ProgramResponse.prototype.addPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Program, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ProgramResponse} returns this
 */
proto.themis.ProgramResponse.prototype.clearProgramsList = function() {
  return this.setProgramsList([]);
};


/**
 * optional Program program = 2;
 * @return {?proto.themis.Program}
 */
proto.themis.ProgramResponse.prototype.getProgram = function() {
  return /** @type{?proto.themis.Program} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Program, 2));
};


/**
 * @param {?proto.themis.Program|undefined} value
 * @return {!proto.themis.ProgramResponse} returns this
*/
proto.themis.ProgramResponse.prototype.setProgram = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ProgramResponse} returns this
 */
proto.themis.ProgramResponse.prototype.clearProgram = function() {
  return this.setProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ProgramResponse.prototype.hasProgram = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProgramUser.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProgramUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProgramUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && themis_common_pb.UserPermissions.toObject(includeInstance, f),
    program: (f = msg.getProgram()) && themis_common_pb.Program.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProgramUser}
 */
proto.themis.ProgramUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProgramUser;
  return proto.themis.ProgramUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProgramUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProgramUser}
 */
proto.themis.ProgramUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.UserPermissions;
      reader.readMessage(value,themis_common_pb.UserPermissions.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new themis_common_pb.Program;
      reader.readMessage(value,themis_common_pb.Program.deserializeBinaryFromReader);
      msg.setProgram(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProgramUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProgramUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProgramUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.UserPermissions.serializeBinaryToWriter
    );
  }
  f = message.getProgram();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Program.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserPermissions user = 1;
 * @return {?proto.themis.UserPermissions}
 */
proto.themis.ProgramUser.prototype.getUser = function() {
  return /** @type{?proto.themis.UserPermissions} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.UserPermissions, 1));
};


/**
 * @param {?proto.themis.UserPermissions|undefined} value
 * @return {!proto.themis.ProgramUser} returns this
*/
proto.themis.ProgramUser.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ProgramUser} returns this
 */
proto.themis.ProgramUser.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ProgramUser.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Program program = 2;
 * @return {?proto.themis.Program}
 */
proto.themis.ProgramUser.prototype.getProgram = function() {
  return /** @type{?proto.themis.Program} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Program, 2));
};


/**
 * @param {?proto.themis.Program|undefined} value
 * @return {!proto.themis.ProgramUser} returns this
*/
proto.themis.ProgramUser.prototype.setProgram = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ProgramUser} returns this
 */
proto.themis.ProgramUser.prototype.clearProgram = function() {
  return this.setProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ProgramUser.prototype.hasProgram = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.LocationUser.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.LocationUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.LocationUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.LocationUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && themis_common_pb.UserPermissions.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && themis_common_pb.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.LocationUser}
 */
proto.themis.LocationUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.LocationUser;
  return proto.themis.LocationUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.LocationUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.LocationUser}
 */
proto.themis.LocationUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.UserPermissions;
      reader.readMessage(value,themis_common_pb.UserPermissions.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new themis_common_pb.Location;
      reader.readMessage(value,themis_common_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.LocationUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.LocationUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.LocationUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.LocationUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.UserPermissions.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserPermissions user = 1;
 * @return {?proto.themis.UserPermissions}
 */
proto.themis.LocationUser.prototype.getUser = function() {
  return /** @type{?proto.themis.UserPermissions} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.UserPermissions, 1));
};


/**
 * @param {?proto.themis.UserPermissions|undefined} value
 * @return {!proto.themis.LocationUser} returns this
*/
proto.themis.LocationUser.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.LocationUser} returns this
 */
proto.themis.LocationUser.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.LocationUser.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Location location = 2;
 * @return {?proto.themis.Location}
 */
proto.themis.LocationUser.prototype.getLocation = function() {
  return /** @type{?proto.themis.Location} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Location, 2));
};


/**
 * @param {?proto.themis.Location|undefined} value
 * @return {!proto.themis.LocationUser} returns this
*/
proto.themis.LocationUser.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.LocationUser} returns this
 */
proto.themis.LocationUser.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.LocationUser.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.TeamUser.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.TeamUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.TeamUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && themis_common_pb.UserPermissions.toObject(includeInstance, f),
    team: (f = msg.getTeam()) && themis_common_pb.Team.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.TeamUser}
 */
proto.themis.TeamUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.TeamUser;
  return proto.themis.TeamUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.TeamUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.TeamUser}
 */
proto.themis.TeamUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.UserPermissions;
      reader.readMessage(value,themis_common_pb.UserPermissions.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new themis_common_pb.Team;
      reader.readMessage(value,themis_common_pb.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.TeamUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.TeamUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.TeamUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.UserPermissions.serializeBinaryToWriter
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Team.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserPermissions user = 1;
 * @return {?proto.themis.UserPermissions}
 */
proto.themis.TeamUser.prototype.getUser = function() {
  return /** @type{?proto.themis.UserPermissions} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.UserPermissions, 1));
};


/**
 * @param {?proto.themis.UserPermissions|undefined} value
 * @return {!proto.themis.TeamUser} returns this
*/
proto.themis.TeamUser.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.TeamUser} returns this
 */
proto.themis.TeamUser.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.TeamUser.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Team team = 2;
 * @return {?proto.themis.Team}
 */
proto.themis.TeamUser.prototype.getTeam = function() {
  return /** @type{?proto.themis.Team} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Team, 2));
};


/**
 * @param {?proto.themis.Team|undefined} value
 * @return {!proto.themis.TeamUser} returns this
*/
proto.themis.TeamUser.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.TeamUser} returns this
 */
proto.themis.TeamUser.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.TeamUser.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProducerUser.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProducerUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProducerUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && themis_common_pb.UserPermissions.toObject(includeInstance, f),
    producer: (f = msg.getProducer()) && themis_common_pb.Producer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProducerUser}
 */
proto.themis.ProducerUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProducerUser;
  return proto.themis.ProducerUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProducerUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProducerUser}
 */
proto.themis.ProducerUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.UserPermissions;
      reader.readMessage(value,themis_common_pb.UserPermissions.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new themis_common_pb.Producer;
      reader.readMessage(value,themis_common_pb.Producer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProducerUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProducerUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProducerUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.UserPermissions.serializeBinaryToWriter
    );
  }
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Producer.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserPermissions user = 1;
 * @return {?proto.themis.UserPermissions}
 */
proto.themis.ProducerUser.prototype.getUser = function() {
  return /** @type{?proto.themis.UserPermissions} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.UserPermissions, 1));
};


/**
 * @param {?proto.themis.UserPermissions|undefined} value
 * @return {!proto.themis.ProducerUser} returns this
*/
proto.themis.ProducerUser.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ProducerUser} returns this
 */
proto.themis.ProducerUser.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ProducerUser.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Producer producer = 2;
 * @return {?proto.themis.Producer}
 */
proto.themis.ProducerUser.prototype.getProducer = function() {
  return /** @type{?proto.themis.Producer} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Producer, 2));
};


/**
 * @param {?proto.themis.Producer|undefined} value
 * @return {!proto.themis.ProducerUser} returns this
*/
proto.themis.ProducerUser.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ProducerUser} returns this
 */
proto.themis.ProducerUser.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ProducerUser.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.BrandUser.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.BrandUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.BrandUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.BrandUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && themis_common_pb.UserPermissions.toObject(includeInstance, f),
    brand: (f = msg.getBrand()) && themis_common_pb.Brand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.BrandUser}
 */
proto.themis.BrandUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.BrandUser;
  return proto.themis.BrandUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.BrandUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.BrandUser}
 */
proto.themis.BrandUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.UserPermissions;
      reader.readMessage(value,themis_common_pb.UserPermissions.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new themis_common_pb.Brand;
      reader.readMessage(value,themis_common_pb.Brand.deserializeBinaryFromReader);
      msg.setBrand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.BrandUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.BrandUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.BrandUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.BrandUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.UserPermissions.serializeBinaryToWriter
    );
  }
  f = message.getBrand();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Brand.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserPermissions user = 1;
 * @return {?proto.themis.UserPermissions}
 */
proto.themis.BrandUser.prototype.getUser = function() {
  return /** @type{?proto.themis.UserPermissions} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.UserPermissions, 1));
};


/**
 * @param {?proto.themis.UserPermissions|undefined} value
 * @return {!proto.themis.BrandUser} returns this
*/
proto.themis.BrandUser.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.BrandUser} returns this
 */
proto.themis.BrandUser.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.BrandUser.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Brand brand = 2;
 * @return {?proto.themis.Brand}
 */
proto.themis.BrandUser.prototype.getBrand = function() {
  return /** @type{?proto.themis.Brand} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Brand, 2));
};


/**
 * @param {?proto.themis.Brand|undefined} value
 * @return {!proto.themis.BrandUser} returns this
*/
proto.themis.BrandUser.prototype.setBrand = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.BrandUser} returns this
 */
proto.themis.BrandUser.prototype.clearBrand = function() {
  return this.setBrand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.BrandUser.prototype.hasBrand = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventUser.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && themis_common_pb.UserPermissions.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && themis_common_pb.Event.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventUser}
 */
proto.themis.EventUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventUser;
  return proto.themis.EventUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventUser}
 */
proto.themis.EventUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.UserPermissions;
      reader.readMessage(value,themis_common_pb.UserPermissions.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new themis_common_pb.Event;
      reader.readMessage(value,themis_common_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.UserPermissions.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserPermissions user = 1;
 * @return {?proto.themis.UserPermissions}
 */
proto.themis.EventUser.prototype.getUser = function() {
  return /** @type{?proto.themis.UserPermissions} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.UserPermissions, 1));
};


/**
 * @param {?proto.themis.UserPermissions|undefined} value
 * @return {!proto.themis.EventUser} returns this
*/
proto.themis.EventUser.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventUser} returns this
 */
proto.themis.EventUser.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventUser.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Event event = 2;
 * @return {?proto.themis.Event}
 */
proto.themis.EventUser.prototype.getEvent = function() {
  return /** @type{?proto.themis.Event} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Event, 2));
};


/**
 * @param {?proto.themis.Event|undefined} value
 * @return {!proto.themis.EventUser} returns this
*/
proto.themis.EventUser.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventUser} returns this
 */
proto.themis.EventUser.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventUser.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.UserPermissionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.UserPermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.UserPermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.UserPermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UserPermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersPermissionsList: jspb.Message.toObjectList(msg.getUsersPermissionsList(),
    themis_common_pb.UserPermissions.toObject, includeInstance),
    userPermissions: (f = msg.getUserPermissions()) && themis_common_pb.UserPermissions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.UserPermissionsResponse}
 */
proto.themis.UserPermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.UserPermissionsResponse;
  return proto.themis.UserPermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.UserPermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.UserPermissionsResponse}
 */
proto.themis.UserPermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.UserPermissions;
      reader.readMessage(value,themis_common_pb.UserPermissions.deserializeBinaryFromReader);
      msg.addUsersPermissions(value);
      break;
    case 2:
      var value = new themis_common_pb.UserPermissions;
      reader.readMessage(value,themis_common_pb.UserPermissions.deserializeBinaryFromReader);
      msg.setUserPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.UserPermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.UserPermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.UserPermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UserPermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.UserPermissions.serializeBinaryToWriter
    );
  }
  f = message.getUserPermissions();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.UserPermissions.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserPermissions users_permissions = 1;
 * @return {!Array<!proto.themis.UserPermissions>}
 */
proto.themis.UserPermissionsResponse.prototype.getUsersPermissionsList = function() {
  return /** @type{!Array<!proto.themis.UserPermissions>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.UserPermissions, 1));
};


/**
 * @param {!Array<!proto.themis.UserPermissions>} value
 * @return {!proto.themis.UserPermissionsResponse} returns this
*/
proto.themis.UserPermissionsResponse.prototype.setUsersPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.UserPermissions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.UserPermissions}
 */
proto.themis.UserPermissionsResponse.prototype.addUsersPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.UserPermissions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.UserPermissionsResponse} returns this
 */
proto.themis.UserPermissionsResponse.prototype.clearUsersPermissionsList = function() {
  return this.setUsersPermissionsList([]);
};


/**
 * optional UserPermissions user_permissions = 2;
 * @return {?proto.themis.UserPermissions}
 */
proto.themis.UserPermissionsResponse.prototype.getUserPermissions = function() {
  return /** @type{?proto.themis.UserPermissions} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.UserPermissions, 2));
};


/**
 * @param {?proto.themis.UserPermissions|undefined} value
 * @return {!proto.themis.UserPermissionsResponse} returns this
*/
proto.themis.UserPermissionsResponse.prototype.setUserPermissions = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.UserPermissionsResponse} returns this
 */
proto.themis.UserPermissionsResponse.prototype.clearUserPermissions = function() {
  return this.setUserPermissions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.UserPermissionsResponse.prototype.hasUserPermissions = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.PermissionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.PermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.PermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.PermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    themis_common_pb.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.PermissionsResponse}
 */
proto.themis.PermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.PermissionsResponse;
  return proto.themis.PermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.PermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.PermissionsResponse}
 */
proto.themis.PermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Permission;
      reader.readMessage(value,themis_common_pb.Permission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.PermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.PermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.PermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Permission.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Permission permissions = 1;
 * @return {!Array<!proto.themis.Permission>}
 */
proto.themis.PermissionsResponse.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.themis.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Permission, 1));
};


/**
 * @param {!Array<!proto.themis.Permission>} value
 * @return {!proto.themis.PermissionsResponse} returns this
*/
proto.themis.PermissionsResponse.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Permission}
 */
proto.themis.PermissionsResponse.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.PermissionsResponse} returns this
 */
proto.themis.PermissionsResponse.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.LocationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.LocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.LocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.LocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.LocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    themis_common_pb.Location.toObject, includeInstance),
    location: (f = msg.getLocation()) && themis_common_pb.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.LocationResponse}
 */
proto.themis.LocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.LocationResponse;
  return proto.themis.LocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.LocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.LocationResponse}
 */
proto.themis.LocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Location;
      reader.readMessage(value,themis_common_pb.Location.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 2:
      var value = new themis_common_pb.Location;
      reader.readMessage(value,themis_common_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.LocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.LocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.LocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.LocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Location.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Location.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Location locations = 1;
 * @return {!Array<!proto.themis.Location>}
 */
proto.themis.LocationResponse.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.themis.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Location, 1));
};


/**
 * @param {!Array<!proto.themis.Location>} value
 * @return {!proto.themis.LocationResponse} returns this
*/
proto.themis.LocationResponse.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Location}
 */
proto.themis.LocationResponse.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.LocationResponse} returns this
 */
proto.themis.LocationResponse.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};


/**
 * optional Location location = 2;
 * @return {?proto.themis.Location}
 */
proto.themis.LocationResponse.prototype.getLocation = function() {
  return /** @type{?proto.themis.Location} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Location, 2));
};


/**
 * @param {?proto.themis.Location|undefined} value
 * @return {!proto.themis.LocationResponse} returns this
*/
proto.themis.LocationResponse.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.LocationResponse} returns this
 */
proto.themis.LocationResponse.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.LocationResponse.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DeleteLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DeleteLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DeleteLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DeleteLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DeleteLocationRequest}
 */
proto.themis.DeleteLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DeleteLocationRequest;
  return proto.themis.DeleteLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DeleteLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DeleteLocationRequest}
 */
proto.themis.DeleteLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DeleteLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DeleteLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DeleteLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DeleteLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.DeleteLocationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DeleteLocationRequest} returns this
 */
proto.themis.DeleteLocationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 program_id = 2;
 * @return {number}
 */
proto.themis.DeleteLocationRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DeleteLocationRequest} returns this
 */
proto.themis.DeleteLocationRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.TeamResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.TeamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.TeamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.TeamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    themis_common_pb.Team.toObject, includeInstance),
    team: (f = msg.getTeam()) && themis_common_pb.Team.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.TeamResponse}
 */
proto.themis.TeamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.TeamResponse;
  return proto.themis.TeamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.TeamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.TeamResponse}
 */
proto.themis.TeamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Team;
      reader.readMessage(value,themis_common_pb.Team.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    case 2:
      var value = new themis_common_pb.Team;
      reader.readMessage(value,themis_common_pb.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.TeamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.TeamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.TeamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Team.serializeBinaryToWriter
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Team.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Team teams = 1;
 * @return {!Array<!proto.themis.Team>}
 */
proto.themis.TeamResponse.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.themis.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Team, 1));
};


/**
 * @param {!Array<!proto.themis.Team>} value
 * @return {!proto.themis.TeamResponse} returns this
*/
proto.themis.TeamResponse.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Team}
 */
proto.themis.TeamResponse.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.TeamResponse} returns this
 */
proto.themis.TeamResponse.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};


/**
 * optional Team team = 2;
 * @return {?proto.themis.Team}
 */
proto.themis.TeamResponse.prototype.getTeam = function() {
  return /** @type{?proto.themis.Team} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Team, 2));
};


/**
 * @param {?proto.themis.Team|undefined} value
 * @return {!proto.themis.TeamResponse} returns this
*/
proto.themis.TeamResponse.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.TeamResponse} returns this
 */
proto.themis.TeamResponse.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.TeamResponse.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.AthleteResponse.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AthleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AthleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AthleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    athletesList: jspb.Message.toObjectList(msg.getAthletesList(),
    themis_common_pb.Athlete.toObject, includeInstance),
    athlete: (f = msg.getAthlete()) && themis_common_pb.Athlete.toObject(includeInstance, f),
    guardiansList: jspb.Message.toObjectList(msg.getGuardiansList(),
    themis_common_pb.Guardian.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AthleteResponse}
 */
proto.themis.AthleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AthleteResponse;
  return proto.themis.AthleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AthleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AthleteResponse}
 */
proto.themis.AthleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.addAthletes(value);
      break;
    case 2:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.setAthlete(value);
      break;
    case 3:
      var value = new themis_common_pb.Guardian;
      reader.readMessage(value,themis_common_pb.Guardian.deserializeBinaryFromReader);
      msg.addGuardians(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AthleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AthleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AthleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAthletesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getAthlete();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getGuardiansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      themis_common_pb.Guardian.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Athlete athletes = 1;
 * @return {!Array<!proto.themis.Athlete>}
 */
proto.themis.AthleteResponse.prototype.getAthletesList = function() {
  return /** @type{!Array<!proto.themis.Athlete>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Athlete, 1));
};


/**
 * @param {!Array<!proto.themis.Athlete>} value
 * @return {!proto.themis.AthleteResponse} returns this
*/
proto.themis.AthleteResponse.prototype.setAthletesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Athlete=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Athlete}
 */
proto.themis.AthleteResponse.prototype.addAthletes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Athlete, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.AthleteResponse} returns this
 */
proto.themis.AthleteResponse.prototype.clearAthletesList = function() {
  return this.setAthletesList([]);
};


/**
 * optional Athlete athlete = 2;
 * @return {?proto.themis.Athlete}
 */
proto.themis.AthleteResponse.prototype.getAthlete = function() {
  return /** @type{?proto.themis.Athlete} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Athlete, 2));
};


/**
 * @param {?proto.themis.Athlete|undefined} value
 * @return {!proto.themis.AthleteResponse} returns this
*/
proto.themis.AthleteResponse.prototype.setAthlete = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.AthleteResponse} returns this
 */
proto.themis.AthleteResponse.prototype.clearAthlete = function() {
  return this.setAthlete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.AthleteResponse.prototype.hasAthlete = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Guardian guardians = 3;
 * @return {!Array<!proto.themis.Guardian>}
 */
proto.themis.AthleteResponse.prototype.getGuardiansList = function() {
  return /** @type{!Array<!proto.themis.Guardian>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Guardian, 3));
};


/**
 * @param {!Array<!proto.themis.Guardian>} value
 * @return {!proto.themis.AthleteResponse} returns this
*/
proto.themis.AthleteResponse.prototype.setGuardiansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.Guardian=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Guardian}
 */
proto.themis.AthleteResponse.prototype.addGuardians = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.Guardian, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.AthleteResponse} returns this
 */
proto.themis.AthleteResponse.prototype.clearGuardiansList = function() {
  return this.setGuardiansList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.AddAthleteListRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AddAthleteListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AddAthleteListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AddAthleteListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddAthleteListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    athletesList: jspb.Message.toObjectList(msg.getAthletesList(),
    themis_common_pb.Athlete.toObject, includeInstance),
    team: (f = msg.getTeam()) && themis_common_pb.Team.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AddAthleteListRequest}
 */
proto.themis.AddAthleteListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AddAthleteListRequest;
  return proto.themis.AddAthleteListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AddAthleteListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AddAthleteListRequest}
 */
proto.themis.AddAthleteListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.addAthletes(value);
      break;
    case 2:
      var value = new themis_common_pb.Team;
      reader.readMessage(value,themis_common_pb.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AddAthleteListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AddAthleteListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AddAthleteListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddAthleteListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAthletesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Team.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Athlete athletes = 1;
 * @return {!Array<!proto.themis.Athlete>}
 */
proto.themis.AddAthleteListRequest.prototype.getAthletesList = function() {
  return /** @type{!Array<!proto.themis.Athlete>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Athlete, 1));
};


/**
 * @param {!Array<!proto.themis.Athlete>} value
 * @return {!proto.themis.AddAthleteListRequest} returns this
*/
proto.themis.AddAthleteListRequest.prototype.setAthletesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Athlete=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Athlete}
 */
proto.themis.AddAthleteListRequest.prototype.addAthletes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Athlete, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.AddAthleteListRequest} returns this
 */
proto.themis.AddAthleteListRequest.prototype.clearAthletesList = function() {
  return this.setAthletesList([]);
};


/**
 * optional Team team = 2;
 * @return {?proto.themis.Team}
 */
proto.themis.AddAthleteListRequest.prototype.getTeam = function() {
  return /** @type{?proto.themis.Team} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Team, 2));
};


/**
 * @param {?proto.themis.Team|undefined} value
 * @return {!proto.themis.AddAthleteListRequest} returns this
*/
proto.themis.AddAthleteListRequest.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.AddAthleteListRequest} returns this
 */
proto.themis.AddAthleteListRequest.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.AddAthleteListRequest.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AthleteLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AthleteLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AthleteLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthleteLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    athlete: (f = msg.getAthlete()) && themis_common_pb.Athlete.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && themis_common_pb.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AthleteLocationRequest}
 */
proto.themis.AthleteLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AthleteLocationRequest;
  return proto.themis.AthleteLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AthleteLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AthleteLocationRequest}
 */
proto.themis.AthleteLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.setAthlete(value);
      break;
    case 2:
      var value = new themis_common_pb.Location;
      reader.readMessage(value,themis_common_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AthleteLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AthleteLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AthleteLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthleteLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAthlete();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional Athlete athlete = 1;
 * @return {?proto.themis.Athlete}
 */
proto.themis.AthleteLocationRequest.prototype.getAthlete = function() {
  return /** @type{?proto.themis.Athlete} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Athlete, 1));
};


/**
 * @param {?proto.themis.Athlete|undefined} value
 * @return {!proto.themis.AthleteLocationRequest} returns this
*/
proto.themis.AthleteLocationRequest.prototype.setAthlete = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.AthleteLocationRequest} returns this
 */
proto.themis.AthleteLocationRequest.prototype.clearAthlete = function() {
  return this.setAthlete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.AthleteLocationRequest.prototype.hasAthlete = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Location location = 2;
 * @return {?proto.themis.Location}
 */
proto.themis.AthleteLocationRequest.prototype.getLocation = function() {
  return /** @type{?proto.themis.Location} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Location, 2));
};


/**
 * @param {?proto.themis.Location|undefined} value
 * @return {!proto.themis.AthleteLocationRequest} returns this
*/
proto.themis.AthleteLocationRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.AthleteLocationRequest} returns this
 */
proto.themis.AthleteLocationRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.AthleteLocationRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AthleteTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AthleteTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AthleteTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthleteTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    athlete: (f = msg.getAthlete()) && themis_common_pb.Athlete.toObject(includeInstance, f),
    team: (f = msg.getTeam()) && themis_common_pb.Team.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AthleteTeamRequest}
 */
proto.themis.AthleteTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AthleteTeamRequest;
  return proto.themis.AthleteTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AthleteTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AthleteTeamRequest}
 */
proto.themis.AthleteTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.setAthlete(value);
      break;
    case 2:
      var value = new themis_common_pb.Team;
      reader.readMessage(value,themis_common_pb.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AthleteTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AthleteTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AthleteTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthleteTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAthlete();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Team.serializeBinaryToWriter
    );
  }
};


/**
 * optional Athlete athlete = 1;
 * @return {?proto.themis.Athlete}
 */
proto.themis.AthleteTeamRequest.prototype.getAthlete = function() {
  return /** @type{?proto.themis.Athlete} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Athlete, 1));
};


/**
 * @param {?proto.themis.Athlete|undefined} value
 * @return {!proto.themis.AthleteTeamRequest} returns this
*/
proto.themis.AthleteTeamRequest.prototype.setAthlete = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.AthleteTeamRequest} returns this
 */
proto.themis.AthleteTeamRequest.prototype.clearAthlete = function() {
  return this.setAthlete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.AthleteTeamRequest.prototype.hasAthlete = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Team team = 2;
 * @return {?proto.themis.Team}
 */
proto.themis.AthleteTeamRequest.prototype.getTeam = function() {
  return /** @type{?proto.themis.Team} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Team, 2));
};


/**
 * @param {?proto.themis.Team|undefined} value
 * @return {!proto.themis.AthleteTeamRequest} returns this
*/
proto.themis.AthleteTeamRequest.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.AthleteTeamRequest} returns this
 */
proto.themis.AthleteTeamRequest.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.AthleteTeamRequest.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.AthletesTeamRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AthletesTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AthletesTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AthletesTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthletesTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    athletesList: jspb.Message.toObjectList(msg.getAthletesList(),
    themis_common_pb.Athlete.toObject, includeInstance),
    team: (f = msg.getTeam()) && themis_common_pb.Team.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AthletesTeamRequest}
 */
proto.themis.AthletesTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AthletesTeamRequest;
  return proto.themis.AthletesTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AthletesTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AthletesTeamRequest}
 */
proto.themis.AthletesTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.addAthletes(value);
      break;
    case 2:
      var value = new themis_common_pb.Team;
      reader.readMessage(value,themis_common_pb.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AthletesTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AthletesTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AthletesTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthletesTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAthletesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Team.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Athlete athletes = 1;
 * @return {!Array<!proto.themis.Athlete>}
 */
proto.themis.AthletesTeamRequest.prototype.getAthletesList = function() {
  return /** @type{!Array<!proto.themis.Athlete>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Athlete, 1));
};


/**
 * @param {!Array<!proto.themis.Athlete>} value
 * @return {!proto.themis.AthletesTeamRequest} returns this
*/
proto.themis.AthletesTeamRequest.prototype.setAthletesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Athlete=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Athlete}
 */
proto.themis.AthletesTeamRequest.prototype.addAthletes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Athlete, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.AthletesTeamRequest} returns this
 */
proto.themis.AthletesTeamRequest.prototype.clearAthletesList = function() {
  return this.setAthletesList([]);
};


/**
 * optional Team team = 2;
 * @return {?proto.themis.Team}
 */
proto.themis.AthletesTeamRequest.prototype.getTeam = function() {
  return /** @type{?proto.themis.Team} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Team, 2));
};


/**
 * @param {?proto.themis.Team|undefined} value
 * @return {!proto.themis.AthletesTeamRequest} returns this
*/
proto.themis.AthletesTeamRequest.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.AthletesTeamRequest} returns this
 */
proto.themis.AthletesTeamRequest.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.AthletesTeamRequest.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GuardianAthleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GuardianAthleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GuardianAthleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GuardianAthleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    guardian: (f = msg.getGuardian()) && themis_common_pb.Guardian.toObject(includeInstance, f),
    athlete: (f = msg.getAthlete()) && themis_common_pb.Athlete.toObject(includeInstance, f),
    connectString: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GuardianAthleteRequest}
 */
proto.themis.GuardianAthleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GuardianAthleteRequest;
  return proto.themis.GuardianAthleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GuardianAthleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GuardianAthleteRequest}
 */
proto.themis.GuardianAthleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Guardian;
      reader.readMessage(value,themis_common_pb.Guardian.deserializeBinaryFromReader);
      msg.setGuardian(value);
      break;
    case 2:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.setAthlete(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GuardianAthleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GuardianAthleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GuardianAthleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GuardianAthleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGuardian();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Guardian.serializeBinaryToWriter
    );
  }
  f = message.getAthlete();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getConnectString();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Guardian guardian = 1;
 * @return {?proto.themis.Guardian}
 */
proto.themis.GuardianAthleteRequest.prototype.getGuardian = function() {
  return /** @type{?proto.themis.Guardian} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Guardian, 1));
};


/**
 * @param {?proto.themis.Guardian|undefined} value
 * @return {!proto.themis.GuardianAthleteRequest} returns this
*/
proto.themis.GuardianAthleteRequest.prototype.setGuardian = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.GuardianAthleteRequest} returns this
 */
proto.themis.GuardianAthleteRequest.prototype.clearGuardian = function() {
  return this.setGuardian(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GuardianAthleteRequest.prototype.hasGuardian = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Athlete athlete = 2;
 * @return {?proto.themis.Athlete}
 */
proto.themis.GuardianAthleteRequest.prototype.getAthlete = function() {
  return /** @type{?proto.themis.Athlete} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Athlete, 2));
};


/**
 * @param {?proto.themis.Athlete|undefined} value
 * @return {!proto.themis.GuardianAthleteRequest} returns this
*/
proto.themis.GuardianAthleteRequest.prototype.setAthlete = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.GuardianAthleteRequest} returns this
 */
proto.themis.GuardianAthleteRequest.prototype.clearAthlete = function() {
  return this.setAthlete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GuardianAthleteRequest.prototype.hasAthlete = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string connect_string = 3;
 * @return {string}
 */
proto.themis.GuardianAthleteRequest.prototype.getConnectString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GuardianAthleteRequest} returns this
 */
proto.themis.GuardianAthleteRequest.prototype.setConnectString = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.GuardianResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GuardianResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GuardianResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GuardianResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GuardianResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    guardiansList: jspb.Message.toObjectList(msg.getGuardiansList(),
    themis_common_pb.Guardian.toObject, includeInstance),
    guardian: (f = msg.getGuardian()) && themis_common_pb.Guardian.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GuardianResponse}
 */
proto.themis.GuardianResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GuardianResponse;
  return proto.themis.GuardianResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GuardianResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GuardianResponse}
 */
proto.themis.GuardianResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Guardian;
      reader.readMessage(value,themis_common_pb.Guardian.deserializeBinaryFromReader);
      msg.addGuardians(value);
      break;
    case 2:
      var value = new themis_common_pb.Guardian;
      reader.readMessage(value,themis_common_pb.Guardian.deserializeBinaryFromReader);
      msg.setGuardian(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GuardianResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GuardianResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GuardianResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GuardianResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGuardiansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Guardian.serializeBinaryToWriter
    );
  }
  f = message.getGuardian();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Guardian.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Guardian guardians = 1;
 * @return {!Array<!proto.themis.Guardian>}
 */
proto.themis.GuardianResponse.prototype.getGuardiansList = function() {
  return /** @type{!Array<!proto.themis.Guardian>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Guardian, 1));
};


/**
 * @param {!Array<!proto.themis.Guardian>} value
 * @return {!proto.themis.GuardianResponse} returns this
*/
proto.themis.GuardianResponse.prototype.setGuardiansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Guardian=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Guardian}
 */
proto.themis.GuardianResponse.prototype.addGuardians = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Guardian, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GuardianResponse} returns this
 */
proto.themis.GuardianResponse.prototype.clearGuardiansList = function() {
  return this.setGuardiansList([]);
};


/**
 * optional Guardian guardian = 2;
 * @return {?proto.themis.Guardian}
 */
proto.themis.GuardianResponse.prototype.getGuardian = function() {
  return /** @type{?proto.themis.Guardian} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Guardian, 2));
};


/**
 * @param {?proto.themis.Guardian|undefined} value
 * @return {!proto.themis.GuardianResponse} returns this
*/
proto.themis.GuardianResponse.prototype.setGuardian = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.GuardianResponse} returns this
 */
proto.themis.GuardianResponse.prototype.clearGuardian = function() {
  return this.setGuardian(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GuardianResponse.prototype.hasGuardian = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CoachLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CoachLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CoachLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CoachLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    coach: (f = msg.getCoach()) && themis_common_pb.Coach.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && themis_common_pb.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CoachLocationRequest}
 */
proto.themis.CoachLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CoachLocationRequest;
  return proto.themis.CoachLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CoachLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CoachLocationRequest}
 */
proto.themis.CoachLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Coach;
      reader.readMessage(value,themis_common_pb.Coach.deserializeBinaryFromReader);
      msg.setCoach(value);
      break;
    case 2:
      var value = new themis_common_pb.Location;
      reader.readMessage(value,themis_common_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CoachLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CoachLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CoachLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CoachLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoach();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Coach.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional Coach coach = 1;
 * @return {?proto.themis.Coach}
 */
proto.themis.CoachLocationRequest.prototype.getCoach = function() {
  return /** @type{?proto.themis.Coach} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Coach, 1));
};


/**
 * @param {?proto.themis.Coach|undefined} value
 * @return {!proto.themis.CoachLocationRequest} returns this
*/
proto.themis.CoachLocationRequest.prototype.setCoach = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.CoachLocationRequest} returns this
 */
proto.themis.CoachLocationRequest.prototype.clearCoach = function() {
  return this.setCoach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.CoachLocationRequest.prototype.hasCoach = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Location location = 2;
 * @return {?proto.themis.Location}
 */
proto.themis.CoachLocationRequest.prototype.getLocation = function() {
  return /** @type{?proto.themis.Location} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Location, 2));
};


/**
 * @param {?proto.themis.Location|undefined} value
 * @return {!proto.themis.CoachLocationRequest} returns this
*/
proto.themis.CoachLocationRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.CoachLocationRequest} returns this
 */
proto.themis.CoachLocationRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.CoachLocationRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CoachTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CoachTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CoachTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CoachTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    coach: (f = msg.getCoach()) && themis_common_pb.Coach.toObject(includeInstance, f),
    team: (f = msg.getTeam()) && themis_common_pb.Team.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CoachTeamRequest}
 */
proto.themis.CoachTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CoachTeamRequest;
  return proto.themis.CoachTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CoachTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CoachTeamRequest}
 */
proto.themis.CoachTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Coach;
      reader.readMessage(value,themis_common_pb.Coach.deserializeBinaryFromReader);
      msg.setCoach(value);
      break;
    case 2:
      var value = new themis_common_pb.Team;
      reader.readMessage(value,themis_common_pb.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CoachTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CoachTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CoachTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CoachTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoach();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Coach.serializeBinaryToWriter
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Team.serializeBinaryToWriter
    );
  }
};


/**
 * optional Coach coach = 1;
 * @return {?proto.themis.Coach}
 */
proto.themis.CoachTeamRequest.prototype.getCoach = function() {
  return /** @type{?proto.themis.Coach} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Coach, 1));
};


/**
 * @param {?proto.themis.Coach|undefined} value
 * @return {!proto.themis.CoachTeamRequest} returns this
*/
proto.themis.CoachTeamRequest.prototype.setCoach = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.CoachTeamRequest} returns this
 */
proto.themis.CoachTeamRequest.prototype.clearCoach = function() {
  return this.setCoach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.CoachTeamRequest.prototype.hasCoach = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Team team = 2;
 * @return {?proto.themis.Team}
 */
proto.themis.CoachTeamRequest.prototype.getTeam = function() {
  return /** @type{?proto.themis.Team} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Team, 2));
};


/**
 * @param {?proto.themis.Team|undefined} value
 * @return {!proto.themis.CoachTeamRequest} returns this
*/
proto.themis.CoachTeamRequest.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.CoachTeamRequest} returns this
 */
proto.themis.CoachTeamRequest.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.CoachTeamRequest.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.CoachResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CoachResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CoachResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CoachResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CoachResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    coachesList: jspb.Message.toObjectList(msg.getCoachesList(),
    themis_common_pb.Coach.toObject, includeInstance),
    coach: (f = msg.getCoach()) && themis_common_pb.Coach.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CoachResponse}
 */
proto.themis.CoachResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CoachResponse;
  return proto.themis.CoachResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CoachResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CoachResponse}
 */
proto.themis.CoachResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Coach;
      reader.readMessage(value,themis_common_pb.Coach.deserializeBinaryFromReader);
      msg.addCoaches(value);
      break;
    case 2:
      var value = new themis_common_pb.Coach;
      reader.readMessage(value,themis_common_pb.Coach.deserializeBinaryFromReader);
      msg.setCoach(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CoachResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CoachResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CoachResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CoachResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoachesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Coach.serializeBinaryToWriter
    );
  }
  f = message.getCoach();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Coach.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Coach coaches = 1;
 * @return {!Array<!proto.themis.Coach>}
 */
proto.themis.CoachResponse.prototype.getCoachesList = function() {
  return /** @type{!Array<!proto.themis.Coach>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Coach, 1));
};


/**
 * @param {!Array<!proto.themis.Coach>} value
 * @return {!proto.themis.CoachResponse} returns this
*/
proto.themis.CoachResponse.prototype.setCoachesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Coach=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Coach}
 */
proto.themis.CoachResponse.prototype.addCoaches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Coach, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.CoachResponse} returns this
 */
proto.themis.CoachResponse.prototype.clearCoachesList = function() {
  return this.setCoachesList([]);
};


/**
 * optional Coach coach = 2;
 * @return {?proto.themis.Coach}
 */
proto.themis.CoachResponse.prototype.getCoach = function() {
  return /** @type{?proto.themis.Coach} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Coach, 2));
};


/**
 * @param {?proto.themis.Coach|undefined} value
 * @return {!proto.themis.CoachResponse} returns this
*/
proto.themis.CoachResponse.prototype.setCoach = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.CoachResponse} returns this
 */
proto.themis.CoachResponse.prototype.clearCoach = function() {
  return this.setCoach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.CoachResponse.prototype.hasCoach = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.PersonnelLocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.PersonnelLocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.PersonnelLocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PersonnelLocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personnel: (f = msg.getPersonnel()) && themis_common_pb.Personnel.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && themis_common_pb.Location.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.PersonnelLocationRequest}
 */
proto.themis.PersonnelLocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.PersonnelLocationRequest;
  return proto.themis.PersonnelLocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.PersonnelLocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.PersonnelLocationRequest}
 */
proto.themis.PersonnelLocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Personnel;
      reader.readMessage(value,themis_common_pb.Personnel.deserializeBinaryFromReader);
      msg.setPersonnel(value);
      break;
    case 2:
      var value = new themis_common_pb.Location;
      reader.readMessage(value,themis_common_pb.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.PersonnelLocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.PersonnelLocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.PersonnelLocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PersonnelLocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonnel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Personnel.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Location.serializeBinaryToWriter
    );
  }
};


/**
 * optional Personnel personnel = 1;
 * @return {?proto.themis.Personnel}
 */
proto.themis.PersonnelLocationRequest.prototype.getPersonnel = function() {
  return /** @type{?proto.themis.Personnel} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Personnel, 1));
};


/**
 * @param {?proto.themis.Personnel|undefined} value
 * @return {!proto.themis.PersonnelLocationRequest} returns this
*/
proto.themis.PersonnelLocationRequest.prototype.setPersonnel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.PersonnelLocationRequest} returns this
 */
proto.themis.PersonnelLocationRequest.prototype.clearPersonnel = function() {
  return this.setPersonnel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.PersonnelLocationRequest.prototype.hasPersonnel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Location location = 2;
 * @return {?proto.themis.Location}
 */
proto.themis.PersonnelLocationRequest.prototype.getLocation = function() {
  return /** @type{?proto.themis.Location} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Location, 2));
};


/**
 * @param {?proto.themis.Location|undefined} value
 * @return {!proto.themis.PersonnelLocationRequest} returns this
*/
proto.themis.PersonnelLocationRequest.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.PersonnelLocationRequest} returns this
 */
proto.themis.PersonnelLocationRequest.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.PersonnelLocationRequest.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.PersonnelTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.PersonnelTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.PersonnelTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PersonnelTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personnel: (f = msg.getPersonnel()) && themis_common_pb.Personnel.toObject(includeInstance, f),
    team: (f = msg.getTeam()) && themis_common_pb.Team.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.PersonnelTeamRequest}
 */
proto.themis.PersonnelTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.PersonnelTeamRequest;
  return proto.themis.PersonnelTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.PersonnelTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.PersonnelTeamRequest}
 */
proto.themis.PersonnelTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Personnel;
      reader.readMessage(value,themis_common_pb.Personnel.deserializeBinaryFromReader);
      msg.setPersonnel(value);
      break;
    case 2:
      var value = new themis_common_pb.Team;
      reader.readMessage(value,themis_common_pb.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.PersonnelTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.PersonnelTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.PersonnelTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PersonnelTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonnel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Personnel.serializeBinaryToWriter
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Team.serializeBinaryToWriter
    );
  }
};


/**
 * optional Personnel personnel = 1;
 * @return {?proto.themis.Personnel}
 */
proto.themis.PersonnelTeamRequest.prototype.getPersonnel = function() {
  return /** @type{?proto.themis.Personnel} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Personnel, 1));
};


/**
 * @param {?proto.themis.Personnel|undefined} value
 * @return {!proto.themis.PersonnelTeamRequest} returns this
*/
proto.themis.PersonnelTeamRequest.prototype.setPersonnel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.PersonnelTeamRequest} returns this
 */
proto.themis.PersonnelTeamRequest.prototype.clearPersonnel = function() {
  return this.setPersonnel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.PersonnelTeamRequest.prototype.hasPersonnel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Team team = 2;
 * @return {?proto.themis.Team}
 */
proto.themis.PersonnelTeamRequest.prototype.getTeam = function() {
  return /** @type{?proto.themis.Team} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Team, 2));
};


/**
 * @param {?proto.themis.Team|undefined} value
 * @return {!proto.themis.PersonnelTeamRequest} returns this
*/
proto.themis.PersonnelTeamRequest.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.PersonnelTeamRequest} returns this
 */
proto.themis.PersonnelTeamRequest.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.PersonnelTeamRequest.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.PersonnelResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.PersonnelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.PersonnelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.PersonnelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PersonnelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    personnelsList: jspb.Message.toObjectList(msg.getPersonnelsList(),
    themis_common_pb.Personnel.toObject, includeInstance),
    personnel: (f = msg.getPersonnel()) && themis_common_pb.Personnel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.PersonnelResponse}
 */
proto.themis.PersonnelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.PersonnelResponse;
  return proto.themis.PersonnelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.PersonnelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.PersonnelResponse}
 */
proto.themis.PersonnelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Personnel;
      reader.readMessage(value,themis_common_pb.Personnel.deserializeBinaryFromReader);
      msg.addPersonnels(value);
      break;
    case 2:
      var value = new themis_common_pb.Personnel;
      reader.readMessage(value,themis_common_pb.Personnel.deserializeBinaryFromReader);
      msg.setPersonnel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.PersonnelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.PersonnelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.PersonnelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PersonnelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonnelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Personnel.serializeBinaryToWriter
    );
  }
  f = message.getPersonnel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Personnel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Personnel personnels = 1;
 * @return {!Array<!proto.themis.Personnel>}
 */
proto.themis.PersonnelResponse.prototype.getPersonnelsList = function() {
  return /** @type{!Array<!proto.themis.Personnel>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Personnel, 1));
};


/**
 * @param {!Array<!proto.themis.Personnel>} value
 * @return {!proto.themis.PersonnelResponse} returns this
*/
proto.themis.PersonnelResponse.prototype.setPersonnelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Personnel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Personnel}
 */
proto.themis.PersonnelResponse.prototype.addPersonnels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Personnel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.PersonnelResponse} returns this
 */
proto.themis.PersonnelResponse.prototype.clearPersonnelsList = function() {
  return this.setPersonnelsList([]);
};


/**
 * optional Personnel personnel = 2;
 * @return {?proto.themis.Personnel}
 */
proto.themis.PersonnelResponse.prototype.getPersonnel = function() {
  return /** @type{?proto.themis.Personnel} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Personnel, 2));
};


/**
 * @param {?proto.themis.Personnel|undefined} value
 * @return {!proto.themis.PersonnelResponse} returns this
*/
proto.themis.PersonnelResponse.prototype.setPersonnel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.PersonnelResponse} returns this
 */
proto.themis.PersonnelResponse.prototype.clearPersonnel = function() {
  return this.setPersonnel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.PersonnelResponse.prototype.hasPersonnel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ProducerResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProducerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProducerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProducerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    producersList: jspb.Message.toObjectList(msg.getProducersList(),
    themis_common_pb.Producer.toObject, includeInstance),
    producer: (f = msg.getProducer()) && themis_common_pb.Producer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProducerResponse}
 */
proto.themis.ProducerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProducerResponse;
  return proto.themis.ProducerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProducerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProducerResponse}
 */
proto.themis.ProducerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Producer;
      reader.readMessage(value,themis_common_pb.Producer.deserializeBinaryFromReader);
      msg.addProducers(value);
      break;
    case 2:
      var value = new themis_common_pb.Producer;
      reader.readMessage(value,themis_common_pb.Producer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProducerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProducerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProducerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProducersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Producer.serializeBinaryToWriter
    );
  }
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Producer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Producer producers = 1;
 * @return {!Array<!proto.themis.Producer>}
 */
proto.themis.ProducerResponse.prototype.getProducersList = function() {
  return /** @type{!Array<!proto.themis.Producer>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Producer, 1));
};


/**
 * @param {!Array<!proto.themis.Producer>} value
 * @return {!proto.themis.ProducerResponse} returns this
*/
proto.themis.ProducerResponse.prototype.setProducersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Producer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Producer}
 */
proto.themis.ProducerResponse.prototype.addProducers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Producer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ProducerResponse} returns this
 */
proto.themis.ProducerResponse.prototype.clearProducersList = function() {
  return this.setProducersList([]);
};


/**
 * optional Producer producer = 2;
 * @return {?proto.themis.Producer}
 */
proto.themis.ProducerResponse.prototype.getProducer = function() {
  return /** @type{?proto.themis.Producer} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Producer, 2));
};


/**
 * @param {?proto.themis.Producer|undefined} value
 * @return {!proto.themis.ProducerResponse} returns this
*/
proto.themis.ProducerResponse.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ProducerResponse} returns this
 */
proto.themis.ProducerResponse.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ProducerResponse.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.BrandResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.BrandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.BrandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.BrandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.BrandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    brandsList: jspb.Message.toObjectList(msg.getBrandsList(),
    themis_common_pb.Brand.toObject, includeInstance),
    brand: (f = msg.getBrand()) && themis_common_pb.Brand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.BrandResponse}
 */
proto.themis.BrandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.BrandResponse;
  return proto.themis.BrandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.BrandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.BrandResponse}
 */
proto.themis.BrandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Brand;
      reader.readMessage(value,themis_common_pb.Brand.deserializeBinaryFromReader);
      msg.addBrands(value);
      break;
    case 2:
      var value = new themis_common_pb.Brand;
      reader.readMessage(value,themis_common_pb.Brand.deserializeBinaryFromReader);
      msg.setBrand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.BrandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.BrandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.BrandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.BrandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Brand.serializeBinaryToWriter
    );
  }
  f = message.getBrand();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Brand.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Brand brands = 1;
 * @return {!Array<!proto.themis.Brand>}
 */
proto.themis.BrandResponse.prototype.getBrandsList = function() {
  return /** @type{!Array<!proto.themis.Brand>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Brand, 1));
};


/**
 * @param {!Array<!proto.themis.Brand>} value
 * @return {!proto.themis.BrandResponse} returns this
*/
proto.themis.BrandResponse.prototype.setBrandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Brand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Brand}
 */
proto.themis.BrandResponse.prototype.addBrands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Brand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.BrandResponse} returns this
 */
proto.themis.BrandResponse.prototype.clearBrandsList = function() {
  return this.setBrandsList([]);
};


/**
 * optional Brand brand = 2;
 * @return {?proto.themis.Brand}
 */
proto.themis.BrandResponse.prototype.getBrand = function() {
  return /** @type{?proto.themis.Brand} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Brand, 2));
};


/**
 * @param {?proto.themis.Brand|undefined} value
 * @return {!proto.themis.BrandResponse} returns this
*/
proto.themis.BrandResponse.prototype.setBrand = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.BrandResponse} returns this
 */
proto.themis.BrandResponse.prototype.clearBrand = function() {
  return this.setBrand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.BrandResponse.prototype.hasBrand = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    themis_common_pb.Event.toObject, includeInstance),
    event: (f = msg.getEvent()) && themis_common_pb.Event.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventResponse}
 */
proto.themis.EventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventResponse;
  return proto.themis.EventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventResponse}
 */
proto.themis.EventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Event;
      reader.readMessage(value,themis_common_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = new themis_common_pb.Event;
      reader.readMessage(value,themis_common_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event events = 1;
 * @return {!Array<!proto.themis.Event>}
 */
proto.themis.EventResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.themis.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Event, 1));
};


/**
 * @param {!Array<!proto.themis.Event>} value
 * @return {!proto.themis.EventResponse} returns this
*/
proto.themis.EventResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Event}
 */
proto.themis.EventResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventResponse} returns this
 */
proto.themis.EventResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional Event event = 2;
 * @return {?proto.themis.Event}
 */
proto.themis.EventResponse.prototype.getEvent = function() {
  return /** @type{?proto.themis.Event} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Event, 2));
};


/**
 * @param {?proto.themis.Event|undefined} value
 * @return {!proto.themis.EventResponse} returns this
*/
proto.themis.EventResponse.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventResponse} returns this
 */
proto.themis.EventResponse.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventResponse.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CountEventEmailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CountEventEmailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CountEventEmailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CountEventEmailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    canSend: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CountEventEmailsResponse}
 */
proto.themis.CountEventEmailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CountEventEmailsResponse;
  return proto.themis.CountEventEmailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CountEventEmailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CountEventEmailsResponse}
 */
proto.themis.CountEventEmailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCanSend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CountEventEmailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CountEventEmailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CountEventEmailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CountEventEmailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCanSend();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 total = 1;
 * @return {number}
 */
proto.themis.CountEventEmailsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CountEventEmailsResponse} returns this
 */
proto.themis.CountEventEmailsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 can_send = 2;
 * @return {number}
 */
proto.themis.CountEventEmailsResponse.prototype.getCanSend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CountEventEmailsResponse} returns this
 */
proto.themis.CountEventEmailsResponse.prototype.setCanSend = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AddEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AddEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AddEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    producerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, ""),
    city: jspb.Message.getFieldWithDefault(msg, 7, ""),
    address: jspb.Message.getFieldWithDefault(msg, 8, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 11, ""),
    email: jspb.Message.getFieldWithDefault(msg, 12, ""),
    website: jspb.Message.getFieldWithDefault(msg, 13, ""),
    info: jspb.Message.getFieldWithDefault(msg, 14, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 15, ""),
    registrationPrice: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AddEventRequest}
 */
proto.themis.AddEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AddEventRequest;
  return proto.themis.AddEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AddEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AddEventRequest}
 */
proto.themis.AddEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfo(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRegistrationPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AddEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AddEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AddEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getInfo();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getRegistrationPrice();
  if (f !== 0) {
    writer.writeUint64(
      16,
      f
    );
  }
};


/**
 * optional uint32 producer_id = 1;
 * @return {number}
 */
proto.themis.AddEventRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 brand_id = 2;
 * @return {number}
 */
proto.themis.AddEventRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string logo = 4;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string city = 7;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string address = 8;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string address2 = 9;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string postal_code = 10;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string phone = 11;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string email = 12;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string website = 13;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string info = 14;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string currency = 15;
 * @return {string}
 */
proto.themis.AddEventRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional uint64 registration_price = 16;
 * @return {number}
 */
proto.themis.AddEventRequest.prototype.getRegistrationPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddEventRequest} returns this
 */
proto.themis.AddEventRequest.prototype.setRegistrationPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.UpdateEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.UpdateEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.UpdateEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UpdateEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    state: jspb.Message.getFieldWithDefault(msg, 7, ""),
    city: jspb.Message.getFieldWithDefault(msg, 8, ""),
    address: jspb.Message.getFieldWithDefault(msg, 9, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 10, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 12, ""),
    email: jspb.Message.getFieldWithDefault(msg, 13, ""),
    website: jspb.Message.getFieldWithDefault(msg, 14, ""),
    info: jspb.Message.getFieldWithDefault(msg, 15, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 16, ""),
    registrationPrice: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.UpdateEventRequest}
 */
proto.themis.UpdateEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.UpdateEventRequest;
  return proto.themis.UpdateEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.UpdateEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.UpdateEventRequest}
 */
proto.themis.UpdateEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfo(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRegistrationPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.UpdateEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.UpdateEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.UpdateEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UpdateEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getInfo();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getRegistrationPrice();
  if (f !== 0) {
    writer.writeUint64(
      17,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.UpdateEventRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 producer_id = 2;
 * @return {number}
 */
proto.themis.UpdateEventRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 brand_id = 3;
 * @return {number}
 */
proto.themis.UpdateEventRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string logo = 5;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string state = 7;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string city = 8;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string address = 9;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string address2 = 10;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string postal_code = 11;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone = 12;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string email = 13;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string website = 14;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string info = 15;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string currency = 16;
 * @return {string}
 */
proto.themis.UpdateEventRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional uint64 registration_price = 17;
 * @return {number}
 */
proto.themis.UpdateEventRequest.prototype.getRegistrationPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.UpdateEventRequest} returns this
 */
proto.themis.UpdateEventRequest.prototype.setRegistrationPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DeleteEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DeleteEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DeleteEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DeleteEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DeleteEventRequest}
 */
proto.themis.DeleteEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DeleteEventRequest;
  return proto.themis.DeleteEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DeleteEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DeleteEventRequest}
 */
proto.themis.DeleteEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DeleteEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DeleteEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DeleteEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DeleteEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 event_id = 1;
 * @return {number}
 */
proto.themis.DeleteEventRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DeleteEventRequest} returns this
 */
proto.themis.DeleteEventRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 producer_id = 2;
 * @return {number}
 */
proto.themis.DeleteEventRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DeleteEventRequest} returns this
 */
proto.themis.DeleteEventRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 brand_id = 3;
 * @return {number}
 */
proto.themis.DeleteEventRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DeleteEventRequest} returns this
 */
proto.themis.DeleteEventRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.UpdateEventRegistrationCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.UpdateEventRegistrationCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UpdateEventRegistrationCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    discountType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    beginsOn: jspb.Message.getFieldWithDefault(msg, 9, ""),
    expiresOn: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest}
 */
proto.themis.UpdateEventRegistrationCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.UpdateEventRegistrationCodeRequest;
  return proto.themis.UpdateEventRegistrationCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.UpdateEventRegistrationCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest}
 */
proto.themis.UpdateEventRegistrationCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {!proto.themis.UnitType} */ (reader.readEnum());
      msg.setDiscountType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeginsOn(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiresOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.UpdateEventRegistrationCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.UpdateEventRegistrationCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UpdateEventRegistrationCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDiscountType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getBeginsOn();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getExpiresOn();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest} returns this
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 event_id = 2;
 * @return {number}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest} returns this
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 producer_id = 3;
 * @return {number}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest} returns this
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 brand_id = 4;
 * @return {number}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest} returns this
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest} returns this
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional UnitType discount_type = 6;
 * @return {!proto.themis.UnitType}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.getDiscountType = function() {
  return /** @type {!proto.themis.UnitType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.themis.UnitType} value
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest} returns this
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.setDiscountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional uint32 amount = 7;
 * @return {number}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest} returns this
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool status = 8;
 * @return {boolean}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest} returns this
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string begins_on = 9;
 * @return {string}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.getBeginsOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest} returns this
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.setBeginsOn = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string expires_on = 10;
 * @return {string}
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.getExpiresOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdateEventRegistrationCodeRequest} returns this
 */
proto.themis.UpdateEventRegistrationCodeRequest.prototype.setExpiresOn = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DeleteEventRegistrationCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DeleteEventRegistrationCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DeleteEventRegistrationCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DeleteEventRegistrationCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DeleteEventRegistrationCodeRequest}
 */
proto.themis.DeleteEventRegistrationCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DeleteEventRegistrationCodeRequest;
  return proto.themis.DeleteEventRegistrationCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DeleteEventRegistrationCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DeleteEventRegistrationCodeRequest}
 */
proto.themis.DeleteEventRegistrationCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DeleteEventRegistrationCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DeleteEventRegistrationCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DeleteEventRegistrationCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DeleteEventRegistrationCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.DeleteEventRegistrationCodeRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DeleteEventRegistrationCodeRequest} returns this
 */
proto.themis.DeleteEventRegistrationCodeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 event_id = 2;
 * @return {number}
 */
proto.themis.DeleteEventRegistrationCodeRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DeleteEventRegistrationCodeRequest} returns this
 */
proto.themis.DeleteEventRegistrationCodeRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 producer_id = 3;
 * @return {number}
 */
proto.themis.DeleteEventRegistrationCodeRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DeleteEventRegistrationCodeRequest} returns this
 */
proto.themis.DeleteEventRegistrationCodeRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 brand_id = 4;
 * @return {number}
 */
proto.themis.DeleteEventRegistrationCodeRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DeleteEventRegistrationCodeRequest} returns this
 */
proto.themis.DeleteEventRegistrationCodeRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AddEventRegistrationCodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AddEventRegistrationCodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddEventRegistrationCodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    discountType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    beginsOn: jspb.Message.getFieldWithDefault(msg, 9, ""),
    expiresOn: jspb.Message.getFieldWithDefault(msg, 10, ""),
    code: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AddEventRegistrationCodeRequest}
 */
proto.themis.AddEventRegistrationCodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AddEventRegistrationCodeRequest;
  return proto.themis.AddEventRegistrationCodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AddEventRegistrationCodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AddEventRegistrationCodeRequest}
 */
proto.themis.AddEventRegistrationCodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {!proto.themis.UnitType} */ (reader.readEnum());
      msg.setDiscountType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAmount(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeginsOn(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiresOn(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AddEventRegistrationCodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AddEventRegistrationCodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddEventRegistrationCodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDiscountType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getBeginsOn();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getExpiresOn();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional uint32 event_id = 2;
 * @return {number}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddEventRegistrationCodeRequest} returns this
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 producer_id = 3;
 * @return {number}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddEventRegistrationCodeRequest} returns this
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 brand_id = 4;
 * @return {number}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddEventRegistrationCodeRequest} returns this
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRegistrationCodeRequest} returns this
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional UnitType discount_type = 6;
 * @return {!proto.themis.UnitType}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.getDiscountType = function() {
  return /** @type {!proto.themis.UnitType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.themis.UnitType} value
 * @return {!proto.themis.AddEventRegistrationCodeRequest} returns this
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.setDiscountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional uint32 amount = 7;
 * @return {number}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddEventRegistrationCodeRequest} returns this
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool status = 8;
 * @return {boolean}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.AddEventRegistrationCodeRequest} returns this
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string begins_on = 9;
 * @return {string}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.getBeginsOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRegistrationCodeRequest} returns this
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.setBeginsOn = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string expires_on = 10;
 * @return {string}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.getExpiresOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRegistrationCodeRequest} returns this
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.setExpiresOn = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string code = 11;
 * @return {string}
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddEventRegistrationCodeRequest} returns this
 */
proto.themis.AddEventRegistrationCodeRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventRegistrationCodesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventRegistrationCodesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventRegistrationCodesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventRegistrationCodesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationCodesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    registrationCodesList: jspb.Message.toObjectList(msg.getRegistrationCodesList(),
    themis_common_pb.EventRegistrationCode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventRegistrationCodesResponse}
 */
proto.themis.EventRegistrationCodesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventRegistrationCodesResponse;
  return proto.themis.EventRegistrationCodesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventRegistrationCodesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventRegistrationCodesResponse}
 */
proto.themis.EventRegistrationCodesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventRegistrationCode;
      reader.readMessage(value,themis_common_pb.EventRegistrationCode.deserializeBinaryFromReader);
      msg.addRegistrationCodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventRegistrationCodesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventRegistrationCodesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventRegistrationCodesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationCodesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistrationCodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventRegistrationCode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventRegistrationCode registration_codes = 1;
 * @return {!Array<!proto.themis.EventRegistrationCode>}
 */
proto.themis.EventRegistrationCodesResponse.prototype.getRegistrationCodesList = function() {
  return /** @type{!Array<!proto.themis.EventRegistrationCode>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventRegistrationCode, 1));
};


/**
 * @param {!Array<!proto.themis.EventRegistrationCode>} value
 * @return {!proto.themis.EventRegistrationCodesResponse} returns this
*/
proto.themis.EventRegistrationCodesResponse.prototype.setRegistrationCodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventRegistrationCode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventRegistrationCode}
 */
proto.themis.EventRegistrationCodesResponse.prototype.addRegistrationCodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventRegistrationCode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventRegistrationCodesResponse} returns this
 */
proto.themis.EventRegistrationCodesResponse.prototype.clearRegistrationCodesList = function() {
  return this.setRegistrationCodesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventRegistrationPreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventRegistrationPreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventRegistrationPreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationPreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    teamId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventRegistrationPreRequest}
 */
proto.themis.EventRegistrationPreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventRegistrationPreRequest;
  return proto.themis.EventRegistrationPreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventRegistrationPreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventRegistrationPreRequest}
 */
proto.themis.EventRegistrationPreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventRegistrationPreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventRegistrationPreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventRegistrationPreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationPreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTeamId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 program_id = 1;
 * @return {number}
 */
proto.themis.EventRegistrationPreRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationPreRequest} returns this
 */
proto.themis.EventRegistrationPreRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 location_id = 2;
 * @return {number}
 */
proto.themis.EventRegistrationPreRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationPreRequest} returns this
 */
proto.themis.EventRegistrationPreRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 team_id = 3;
 * @return {number}
 */
proto.themis.EventRegistrationPreRequest.prototype.getTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationPreRequest} returns this
 */
proto.themis.EventRegistrationPreRequest.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 producer_id = 4;
 * @return {number}
 */
proto.themis.EventRegistrationPreRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationPreRequest} returns this
 */
proto.themis.EventRegistrationPreRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 brand_id = 5;
 * @return {number}
 */
proto.themis.EventRegistrationPreRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationPreRequest} returns this
 */
proto.themis.EventRegistrationPreRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 event_id = 6;
 * @return {number}
 */
proto.themis.EventRegistrationPreRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationPreRequest} returns this
 */
proto.themis.EventRegistrationPreRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventRegistrationPreResponse.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventRegistrationPreResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventRegistrationPreResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventRegistrationPreResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationPreResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamDivision: (f = msg.getTeamDivision()) && themis_common_pb.Division.toObject(includeInstance, f),
    eventDivisionsList: jspb.Message.toObjectList(msg.getEventDivisionsList(),
    themis_common_pb.Division.toObject, includeInstance),
    locationDivisionsList: jspb.Message.toObjectList(msg.getLocationDivisionsList(),
    themis_common_pb.Athlete.toObject, includeInstance),
    teamAthletesList: jspb.Message.toObjectList(msg.getTeamAthletesList(),
    themis_common_pb.Athlete.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventRegistrationPreResponse}
 */
proto.themis.EventRegistrationPreResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventRegistrationPreResponse;
  return proto.themis.EventRegistrationPreResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventRegistrationPreResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventRegistrationPreResponse}
 */
proto.themis.EventRegistrationPreResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Division;
      reader.readMessage(value,themis_common_pb.Division.deserializeBinaryFromReader);
      msg.setTeamDivision(value);
      break;
    case 2:
      var value = new themis_common_pb.Division;
      reader.readMessage(value,themis_common_pb.Division.deserializeBinaryFromReader);
      msg.addEventDivisions(value);
      break;
    case 3:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.addLocationDivisions(value);
      break;
    case 4:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.addTeamAthletes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventRegistrationPreResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventRegistrationPreResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventRegistrationPreResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationPreResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamDivision();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Division.serializeBinaryToWriter
    );
  }
  f = message.getEventDivisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      themis_common_pb.Division.serializeBinaryToWriter
    );
  }
  f = message.getLocationDivisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getTeamAthletesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
};


/**
 * optional Division team_division = 1;
 * @return {?proto.themis.Division}
 */
proto.themis.EventRegistrationPreResponse.prototype.getTeamDivision = function() {
  return /** @type{?proto.themis.Division} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Division, 1));
};


/**
 * @param {?proto.themis.Division|undefined} value
 * @return {!proto.themis.EventRegistrationPreResponse} returns this
*/
proto.themis.EventRegistrationPreResponse.prototype.setTeamDivision = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventRegistrationPreResponse} returns this
 */
proto.themis.EventRegistrationPreResponse.prototype.clearTeamDivision = function() {
  return this.setTeamDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventRegistrationPreResponse.prototype.hasTeamDivision = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Division event_divisions = 2;
 * @return {!Array<!proto.themis.Division>}
 */
proto.themis.EventRegistrationPreResponse.prototype.getEventDivisionsList = function() {
  return /** @type{!Array<!proto.themis.Division>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Division, 2));
};


/**
 * @param {!Array<!proto.themis.Division>} value
 * @return {!proto.themis.EventRegistrationPreResponse} returns this
*/
proto.themis.EventRegistrationPreResponse.prototype.setEventDivisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.Division=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Division}
 */
proto.themis.EventRegistrationPreResponse.prototype.addEventDivisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.Division, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventRegistrationPreResponse} returns this
 */
proto.themis.EventRegistrationPreResponse.prototype.clearEventDivisionsList = function() {
  return this.setEventDivisionsList([]);
};


/**
 * repeated Athlete location_divisions = 3;
 * @return {!Array<!proto.themis.Athlete>}
 */
proto.themis.EventRegistrationPreResponse.prototype.getLocationDivisionsList = function() {
  return /** @type{!Array<!proto.themis.Athlete>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Athlete, 3));
};


/**
 * @param {!Array<!proto.themis.Athlete>} value
 * @return {!proto.themis.EventRegistrationPreResponse} returns this
*/
proto.themis.EventRegistrationPreResponse.prototype.setLocationDivisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.Athlete=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Athlete}
 */
proto.themis.EventRegistrationPreResponse.prototype.addLocationDivisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.Athlete, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventRegistrationPreResponse} returns this
 */
proto.themis.EventRegistrationPreResponse.prototype.clearLocationDivisionsList = function() {
  return this.setLocationDivisionsList([]);
};


/**
 * repeated Athlete team_athletes = 4;
 * @return {!Array<!proto.themis.Athlete>}
 */
proto.themis.EventRegistrationPreResponse.prototype.getTeamAthletesList = function() {
  return /** @type{!Array<!proto.themis.Athlete>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Athlete, 4));
};


/**
 * @param {!Array<!proto.themis.Athlete>} value
 * @return {!proto.themis.EventRegistrationPreResponse} returns this
*/
proto.themis.EventRegistrationPreResponse.prototype.setTeamAthletesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.Athlete=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Athlete}
 */
proto.themis.EventRegistrationPreResponse.prototype.addTeamAthletes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.Athlete, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventRegistrationPreResponse} returns this
 */
proto.themis.EventRegistrationPreResponse.prototype.clearTeamAthletesList = function() {
  return this.setTeamAthletesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ScrapeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ScrapeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ScrapeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ScrapeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scrapeType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ScrapeRequest}
 */
proto.themis.ScrapeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ScrapeRequest;
  return proto.themis.ScrapeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ScrapeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ScrapeRequest}
 */
proto.themis.ScrapeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {!proto.themis.ScrapeRequestType} */ (reader.readEnum());
      msg.setScrapeType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ScrapeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ScrapeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ScrapeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ScrapeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScrapeType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.themis.ScrapeRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ScrapeRequest} returns this
 */
proto.themis.ScrapeRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.themis.ScrapeRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ScrapeRequest} returns this
 */
proto.themis.ScrapeRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ScrapeRequestType scrape_type = 3;
 * @return {!proto.themis.ScrapeRequestType}
 */
proto.themis.ScrapeRequest.prototype.getScrapeType = function() {
  return /** @type {!proto.themis.ScrapeRequestType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.themis.ScrapeRequestType} value
 * @return {!proto.themis.ScrapeRequest} returns this
 */
proto.themis.ScrapeRequest.prototype.setScrapeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 program_id = 4;
 * @return {number}
 */
proto.themis.ScrapeRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ScrapeRequest} returns this
 */
proto.themis.ScrapeRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ScrapeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ScrapeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ScrapeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ScrapeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    okay: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ScrapeResponse}
 */
proto.themis.ScrapeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ScrapeResponse;
  return proto.themis.ScrapeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ScrapeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ScrapeResponse}
 */
proto.themis.ScrapeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOkay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ScrapeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ScrapeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ScrapeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ScrapeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOkay();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool okay = 1;
 * @return {boolean}
 */
proto.themis.ScrapeResponse.prototype.getOkay = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.ScrapeResponse} returns this
 */
proto.themis.ScrapeResponse.prototype.setOkay = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.UpdatePasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.UpdatePasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.UpdatePasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UpdatePasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.UpdatePasswordRequest}
 */
proto.themis.UpdatePasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.UpdatePasswordRequest;
  return proto.themis.UpdatePasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.UpdatePasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.UpdatePasswordRequest}
 */
proto.themis.UpdatePasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.UpdatePasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.UpdatePasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.UpdatePasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UpdatePasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string password = 1;
 * @return {string}
 */
proto.themis.UpdatePasswordRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UpdatePasswordRequest} returns this
 */
proto.themis.UpdatePasswordRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.UpdatePasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.UpdatePasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.UpdatePasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UpdatePasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    okay: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.UpdatePasswordResponse}
 */
proto.themis.UpdatePasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.UpdatePasswordResponse;
  return proto.themis.UpdatePasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.UpdatePasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.UpdatePasswordResponse}
 */
proto.themis.UpdatePasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOkay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.UpdatePasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.UpdatePasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.UpdatePasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UpdatePasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOkay();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool okay = 1;
 * @return {boolean}
 */
proto.themis.UpdatePasswordResponse.prototype.getOkay = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.UpdatePasswordResponse} returns this
 */
proto.themis.UpdatePasswordResponse.prototype.setOkay = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.AddPaymentRequest.repeatedFields_ = [8,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AddPaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AddPaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AddPaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddPaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    producerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    teamId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    scope: jspb.Message.getFieldWithDefault(msg, 7, 0),
    registrationTransactionsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    paymentType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    storedPaymentType: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    paymentSource: jspb.Message.getFieldWithDefault(msg, 13, ""),
    paymentId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    eventTeamIdsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AddPaymentRequest}
 */
proto.themis.AddPaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AddPaymentRequest;
  return proto.themis.AddPaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AddPaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AddPaymentRequest}
 */
proto.themis.AddPaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {!proto.themis.RegistrationTransactionScope} */ (reader.readEnum());
      msg.setScope(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRegistrationTransactions(values[i]);
      }
      break;
    case 11:
      var value = /** @type {!proto.themis.PaymentType} */ (reader.readEnum());
      msg.setPaymentType(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStoredPaymentType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentSource(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaymentId(value);
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventTeamIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AddPaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AddPaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AddPaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddPaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTeamId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getScope();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getRegistrationTransactionsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      8,
      f
    );
  }
  f = message.getPaymentType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getStoredPaymentType();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getPaymentSource();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPaymentId();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getEventTeamIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      15,
      f
    );
  }
};


/**
 * optional uint32 producer_id = 1;
 * @return {number}
 */
proto.themis.AddPaymentRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 program_id = 2;
 * @return {number}
 */
proto.themis.AddPaymentRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 event_id = 3;
 * @return {number}
 */
proto.themis.AddPaymentRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 team_id = 4;
 * @return {number}
 */
proto.themis.AddPaymentRequest.prototype.getTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 location_id = 9;
 * @return {number}
 */
proto.themis.AddPaymentRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 brand_id = 10;
 * @return {number}
 */
proto.themis.AddPaymentRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.themis.AddPaymentRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint64 amount = 6;
 * @return {number}
 */
proto.themis.AddPaymentRequest.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional RegistrationTransactionScope scope = 7;
 * @return {!proto.themis.RegistrationTransactionScope}
 */
proto.themis.AddPaymentRequest.prototype.getScope = function() {
  return /** @type {!proto.themis.RegistrationTransactionScope} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.themis.RegistrationTransactionScope} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setScope = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated uint32 registration_transactions = 8;
 * @return {!Array<number>}
 */
proto.themis.AddPaymentRequest.prototype.getRegistrationTransactionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setRegistrationTransactionsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.addRegistrationTransactions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.clearRegistrationTransactionsList = function() {
  return this.setRegistrationTransactionsList([]);
};


/**
 * optional PaymentType payment_type = 11;
 * @return {!proto.themis.PaymentType}
 */
proto.themis.AddPaymentRequest.prototype.getPaymentType = function() {
  return /** @type {!proto.themis.PaymentType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.themis.PaymentType} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setPaymentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional bool stored_payment_type = 12;
 * @return {boolean}
 */
proto.themis.AddPaymentRequest.prototype.getStoredPaymentType = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setStoredPaymentType = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string payment_source = 13;
 * @return {string}
 */
proto.themis.AddPaymentRequest.prototype.getPaymentSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setPaymentSource = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint32 payment_id = 14;
 * @return {number}
 */
proto.themis.AddPaymentRequest.prototype.getPaymentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setPaymentId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated uint32 event_team_ids = 15;
 * @return {!Array<number>}
 */
proto.themis.AddPaymentRequest.prototype.getEventTeamIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.setEventTeamIdsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.addEventTeamIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.AddPaymentRequest} returns this
 */
proto.themis.AddPaymentRequest.prototype.clearEventTeamIdsList = function() {
  return this.setEventTeamIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AddPaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AddPaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AddPaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddPaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientSecret: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pk: jspb.Message.getFieldWithDefault(msg, 5, ""),
    paymentType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    producerStripeAccountId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    financialTransactionId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    creditAmount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    depositAmount: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AddPaymentResponse}
 */
proto.themis.AddPaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AddPaymentResponse;
  return proto.themis.AddPaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AddPaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AddPaymentResponse}
 */
proto.themis.AddPaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaymentId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPk(value);
      break;
    case 6:
      var value = /** @type {!proto.themis.PaymentType} */ (reader.readEnum());
      msg.setPaymentType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerStripeAccountId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFinancialTransactionId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreditAmount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDepositAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AddPaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AddPaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AddPaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddPaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPk();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPaymentType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getProducerStripeAccountId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFinancialTransactionId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getCreditAmount();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getDepositAmount();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
};


/**
 * optional string client_secret = 1;
 * @return {string}
 */
proto.themis.AddPaymentResponse.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddPaymentResponse} returns this
 */
proto.themis.AddPaymentResponse.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 payment_id = 2;
 * @return {number}
 */
proto.themis.AddPaymentResponse.prototype.getPaymentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentResponse} returns this
 */
proto.themis.AddPaymentResponse.prototype.setPaymentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 amount = 3;
 * @return {number}
 */
proto.themis.AddPaymentResponse.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentResponse} returns this
 */
proto.themis.AddPaymentResponse.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.themis.AddPaymentResponse.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddPaymentResponse} returns this
 */
proto.themis.AddPaymentResponse.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pk = 5;
 * @return {string}
 */
proto.themis.AddPaymentResponse.prototype.getPk = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddPaymentResponse} returns this
 */
proto.themis.AddPaymentResponse.prototype.setPk = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PaymentType payment_type = 6;
 * @return {!proto.themis.PaymentType}
 */
proto.themis.AddPaymentResponse.prototype.getPaymentType = function() {
  return /** @type {!proto.themis.PaymentType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.themis.PaymentType} value
 * @return {!proto.themis.AddPaymentResponse} returns this
 */
proto.themis.AddPaymentResponse.prototype.setPaymentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string producer_stripe_account_id = 7;
 * @return {string}
 */
proto.themis.AddPaymentResponse.prototype.getProducerStripeAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddPaymentResponse} returns this
 */
proto.themis.AddPaymentResponse.prototype.setProducerStripeAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 financial_transaction_id = 8;
 * @return {number}
 */
proto.themis.AddPaymentResponse.prototype.getFinancialTransactionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentResponse} returns this
 */
proto.themis.AddPaymentResponse.prototype.setFinancialTransactionId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 credit_amount = 9;
 * @return {number}
 */
proto.themis.AddPaymentResponse.prototype.getCreditAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentResponse} returns this
 */
proto.themis.AddPaymentResponse.prototype.setCreditAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 deposit_amount = 10;
 * @return {number}
 */
proto.themis.AddPaymentResponse.prototype.getDepositAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddPaymentResponse} returns this
 */
proto.themis.AddPaymentResponse.prototype.setDepositAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GetCreditDepositByFinancialTransactionIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GetCreditDepositByFinancialTransactionIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GetCreditDepositByFinancialTransactionIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetCreditDepositByFinancialTransactionIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ftId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GetCreditDepositByFinancialTransactionIDRequest}
 */
proto.themis.GetCreditDepositByFinancialTransactionIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GetCreditDepositByFinancialTransactionIDRequest;
  return proto.themis.GetCreditDepositByFinancialTransactionIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GetCreditDepositByFinancialTransactionIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GetCreditDepositByFinancialTransactionIDRequest}
 */
proto.themis.GetCreditDepositByFinancialTransactionIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFtId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GetCreditDepositByFinancialTransactionIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GetCreditDepositByFinancialTransactionIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GetCreditDepositByFinancialTransactionIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetCreditDepositByFinancialTransactionIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFtId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 program_id = 1;
 * @return {number}
 */
proto.themis.GetCreditDepositByFinancialTransactionIDRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetCreditDepositByFinancialTransactionIDRequest} returns this
 */
proto.themis.GetCreditDepositByFinancialTransactionIDRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 ft_id = 2;
 * @return {number}
 */
proto.themis.GetCreditDepositByFinancialTransactionIDRequest.prototype.getFtId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetCreditDepositByFinancialTransactionIDRequest} returns this
 */
proto.themis.GetCreditDepositByFinancialTransactionIDRequest.prototype.setFtId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AddFinancialTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AddFinancialTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AddFinancialTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddFinancialTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AddFinancialTransactionRequest}
 */
proto.themis.AddFinancialTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AddFinancialTransactionRequest;
  return proto.themis.AddFinancialTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AddFinancialTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AddFinancialTransactionRequest}
 */
proto.themis.AddFinancialTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AddFinancialTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AddFinancialTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AddFinancialTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddFinancialTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 program_id = 1;
 * @return {number}
 */
proto.themis.AddFinancialTransactionRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddFinancialTransactionRequest} returns this
 */
proto.themis.AddFinancialTransactionRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AddFinancialTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AddFinancialTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AddFinancialTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddFinancialTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AddFinancialTransactionResponse}
 */
proto.themis.AddFinancialTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AddFinancialTransactionResponse;
  return proto.themis.AddFinancialTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AddFinancialTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AddFinancialTransactionResponse}
 */
proto.themis.AddFinancialTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AddFinancialTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AddFinancialTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AddFinancialTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddFinancialTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.AddFinancialTransactionResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddFinancialTransactionResponse} returns this
 */
proto.themis.AddFinancialTransactionResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ChargePaymentSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ChargePaymentSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ChargePaymentSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ChargePaymentSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    succeeded: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ChargePaymentSourceResponse}
 */
proto.themis.ChargePaymentSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ChargePaymentSourceResponse;
  return proto.themis.ChargePaymentSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ChargePaymentSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ChargePaymentSourceResponse}
 */
proto.themis.ChargePaymentSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSucceeded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ChargePaymentSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ChargePaymentSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ChargePaymentSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ChargePaymentSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSucceeded();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool succeeded = 1;
 * @return {boolean}
 */
proto.themis.ChargePaymentSourceResponse.prototype.getSucceeded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.ChargePaymentSourceResponse} returns this
 */
proto.themis.ChargePaymentSourceResponse.prototype.setSucceeded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.StripePKResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.StripePKResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.StripePKResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.StripePKResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stripePK: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.StripePKResponse}
 */
proto.themis.StripePKResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.StripePKResponse;
  return proto.themis.StripePKResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.StripePKResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.StripePKResponse}
 */
proto.themis.StripePKResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripePK(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.StripePKResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.StripePKResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.StripePKResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.StripePKResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStripePK();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string stripe_p_k = 1;
 * @return {string}
 */
proto.themis.StripePKResponse.prototype.getStripePK = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripePKResponse} returns this
 */
proto.themis.StripePKResponse.prototype.setStripePK = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GetAccountBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GetAccountBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GetAccountBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetAccountBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scope: jspb.Message.getFieldWithDefault(msg, 1, 0),
    paidScope: jspb.Message.getFieldWithDefault(msg, 2, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    programLocationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    teamId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    eventTeamId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GetAccountBalanceRequest}
 */
proto.themis.GetAccountBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GetAccountBalanceRequest;
  return proto.themis.GetAccountBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GetAccountBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GetAccountBalanceRequest}
 */
proto.themis.GetAccountBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.themis.RegistrationTransactionScope} */ (reader.readEnum());
      msg.setScope(value);
      break;
    case 2:
      var value = /** @type {!proto.themis.PaidScope} */ (reader.readEnum());
      msg.setPaidScope(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramLocationId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventTeamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GetAccountBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GetAccountBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GetAccountBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetAccountBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScope();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPaidScope();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getProgramLocationId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTeamId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getEventTeamId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
};


/**
 * optional RegistrationTransactionScope scope = 1;
 * @return {!proto.themis.RegistrationTransactionScope}
 */
proto.themis.GetAccountBalanceRequest.prototype.getScope = function() {
  return /** @type {!proto.themis.RegistrationTransactionScope} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.themis.RegistrationTransactionScope} value
 * @return {!proto.themis.GetAccountBalanceRequest} returns this
 */
proto.themis.GetAccountBalanceRequest.prototype.setScope = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional PaidScope paid_scope = 2;
 * @return {!proto.themis.PaidScope}
 */
proto.themis.GetAccountBalanceRequest.prototype.getPaidScope = function() {
  return /** @type {!proto.themis.PaidScope} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.themis.PaidScope} value
 * @return {!proto.themis.GetAccountBalanceRequest} returns this
 */
proto.themis.GetAccountBalanceRequest.prototype.setPaidScope = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 producer_id = 3;
 * @return {number}
 */
proto.themis.GetAccountBalanceRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceRequest} returns this
 */
proto.themis.GetAccountBalanceRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 program_id = 4;
 * @return {number}
 */
proto.themis.GetAccountBalanceRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceRequest} returns this
 */
proto.themis.GetAccountBalanceRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 program_location_id = 5;
 * @return {number}
 */
proto.themis.GetAccountBalanceRequest.prototype.getProgramLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceRequest} returns this
 */
proto.themis.GetAccountBalanceRequest.prototype.setProgramLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 team_id = 6;
 * @return {number}
 */
proto.themis.GetAccountBalanceRequest.prototype.getTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceRequest} returns this
 */
proto.themis.GetAccountBalanceRequest.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 event_id = 7;
 * @return {number}
 */
proto.themis.GetAccountBalanceRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceRequest} returns this
 */
proto.themis.GetAccountBalanceRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 brand_id = 8;
 * @return {number}
 */
proto.themis.GetAccountBalanceRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceRequest} returns this
 */
proto.themis.GetAccountBalanceRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 location_id = 9;
 * @return {number}
 */
proto.themis.GetAccountBalanceRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceRequest} returns this
 */
proto.themis.GetAccountBalanceRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 event_team_id = 10;
 * @return {number}
 */
proto.themis.GetAccountBalanceRequest.prototype.getEventTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceRequest} returns this
 */
proto.themis.GetAccountBalanceRequest.prototype.setEventTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.GetAccountBalanceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GetAccountBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GetAccountBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GetAccountBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetAccountBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    themis_common_pb.RegistrationTransaction.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    scope: jspb.Message.getFieldWithDefault(msg, 3, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    teamId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    crPayoutTotal: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GetAccountBalanceResponse}
 */
proto.themis.GetAccountBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GetAccountBalanceResponse;
  return proto.themis.GetAccountBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GetAccountBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GetAccountBalanceResponse}
 */
proto.themis.GetAccountBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.RegistrationTransaction;
      reader.readMessage(value,themis_common_pb.RegistrationTransaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {!proto.themis.RegistrationTransactionScope} */ (reader.readEnum());
      msg.setScope(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCrPayoutTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GetAccountBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GetAccountBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GetAccountBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetAccountBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.RegistrationTransaction.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getScope();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTeamId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getCrPayoutTotal();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
};


/**
 * repeated RegistrationTransaction transactions = 1;
 * @return {!Array<!proto.themis.RegistrationTransaction>}
 */
proto.themis.GetAccountBalanceResponse.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.themis.RegistrationTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.RegistrationTransaction, 1));
};


/**
 * @param {!Array<!proto.themis.RegistrationTransaction>} value
 * @return {!proto.themis.GetAccountBalanceResponse} returns this
*/
proto.themis.GetAccountBalanceResponse.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.RegistrationTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.RegistrationTransaction}
 */
proto.themis.GetAccountBalanceResponse.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.RegistrationTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GetAccountBalanceResponse} returns this
 */
proto.themis.GetAccountBalanceResponse.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional uint64 total = 2;
 * @return {number}
 */
proto.themis.GetAccountBalanceResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceResponse} returns this
 */
proto.themis.GetAccountBalanceResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional RegistrationTransactionScope scope = 3;
 * @return {!proto.themis.RegistrationTransactionScope}
 */
proto.themis.GetAccountBalanceResponse.prototype.getScope = function() {
  return /** @type {!proto.themis.RegistrationTransactionScope} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.themis.RegistrationTransactionScope} value
 * @return {!proto.themis.GetAccountBalanceResponse} returns this
 */
proto.themis.GetAccountBalanceResponse.prototype.setScope = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 program_id = 5;
 * @return {number}
 */
proto.themis.GetAccountBalanceResponse.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceResponse} returns this
 */
proto.themis.GetAccountBalanceResponse.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 location_id = 6;
 * @return {number}
 */
proto.themis.GetAccountBalanceResponse.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceResponse} returns this
 */
proto.themis.GetAccountBalanceResponse.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 team_id = 7;
 * @return {number}
 */
proto.themis.GetAccountBalanceResponse.prototype.getTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceResponse} returns this
 */
proto.themis.GetAccountBalanceResponse.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 producer_id = 4;
 * @return {number}
 */
proto.themis.GetAccountBalanceResponse.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceResponse} returns this
 */
proto.themis.GetAccountBalanceResponse.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 brand_id = 10;
 * @return {number}
 */
proto.themis.GetAccountBalanceResponse.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceResponse} returns this
 */
proto.themis.GetAccountBalanceResponse.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 event_id = 8;
 * @return {number}
 */
proto.themis.GetAccountBalanceResponse.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceResponse} returns this
 */
proto.themis.GetAccountBalanceResponse.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 cr_payout_total = 9;
 * @return {number}
 */
proto.themis.GetAccountBalanceResponse.prototype.getCrPayoutTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetAccountBalanceResponse} returns this
 */
proto.themis.GetAccountBalanceResponse.prototype.setCrPayoutTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GetCurrentDepositResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GetCurrentDepositResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GetCurrentDepositResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetCurrentDepositResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deposit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    credit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GetCurrentDepositResponse}
 */
proto.themis.GetCurrentDepositResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GetCurrentDepositResponse;
  return proto.themis.GetCurrentDepositResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GetCurrentDepositResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GetCurrentDepositResponse}
 */
proto.themis.GetCurrentDepositResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeposit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCredit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GetCurrentDepositResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GetCurrentDepositResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GetCurrentDepositResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetCurrentDepositResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCredit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 deposit = 1;
 * @return {number}
 */
proto.themis.GetCurrentDepositResponse.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetCurrentDepositResponse} returns this
 */
proto.themis.GetCurrentDepositResponse.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 credit = 2;
 * @return {number}
 */
proto.themis.GetCurrentDepositResponse.prototype.getCredit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetCurrentDepositResponse} returns this
 */
proto.themis.GetCurrentDepositResponse.prototype.setCredit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GetEventDatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GetEventDatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GetEventDatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetEventDatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GetEventDatesRequest}
 */
proto.themis.GetEventDatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GetEventDatesRequest;
  return proto.themis.GetEventDatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GetEventDatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GetEventDatesRequest}
 */
proto.themis.GetEventDatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GetEventDatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GetEventDatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GetEventDatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetEventDatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 event_id = 1;
 * @return {number}
 */
proto.themis.GetEventDatesRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetEventDatesRequest} returns this
 */
proto.themis.GetEventDatesRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventDatesResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventDatesList: jspb.Message.toObjectList(msg.getEventDatesList(),
    themis_common_pb.EventDate.toObject, includeInstance),
    eventFloorsList: jspb.Message.toObjectList(msg.getEventFloorsList(),
    themis_common_pb.EventFloor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDatesResponse}
 */
proto.themis.EventDatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDatesResponse;
  return proto.themis.EventDatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDatesResponse}
 */
proto.themis.EventDatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventDate;
      reader.readMessage(value,themis_common_pb.EventDate.deserializeBinaryFromReader);
      msg.addEventDates(value);
      break;
    case 2:
      var value = new themis_common_pb.EventFloor;
      reader.readMessage(value,themis_common_pb.EventFloor.deserializeBinaryFromReader);
      msg.addEventFloors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventDate.serializeBinaryToWriter
    );
  }
  f = message.getEventFloorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      themis_common_pb.EventFloor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventDate event_dates = 1;
 * @return {!Array<!proto.themis.EventDate>}
 */
proto.themis.EventDatesResponse.prototype.getEventDatesList = function() {
  return /** @type{!Array<!proto.themis.EventDate>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventDate, 1));
};


/**
 * @param {!Array<!proto.themis.EventDate>} value
 * @return {!proto.themis.EventDatesResponse} returns this
*/
proto.themis.EventDatesResponse.prototype.setEventDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventDate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDate}
 */
proto.themis.EventDatesResponse.prototype.addEventDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventDate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDatesResponse} returns this
 */
proto.themis.EventDatesResponse.prototype.clearEventDatesList = function() {
  return this.setEventDatesList([]);
};


/**
 * repeated EventFloor event_floors = 2;
 * @return {!Array<!proto.themis.EventFloor>}
 */
proto.themis.EventDatesResponse.prototype.getEventFloorsList = function() {
  return /** @type{!Array<!proto.themis.EventFloor>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventFloor, 2));
};


/**
 * @param {!Array<!proto.themis.EventFloor>} value
 * @return {!proto.themis.EventDatesResponse} returns this
*/
proto.themis.EventDatesResponse.prototype.setEventFloorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.EventFloor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventFloor}
 */
proto.themis.EventDatesResponse.prototype.addEventFloors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.EventFloor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDatesResponse} returns this
 */
proto.themis.EventDatesResponse.prototype.clearEventFloorsList = function() {
  return this.setEventFloorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.AllStarTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AllStarTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AllStarTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AllStarTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AllStarTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    allStarTypesList: jspb.Message.toObjectList(msg.getAllStarTypesList(),
    themis_common_pb.AllStarType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AllStarTypesResponse}
 */
proto.themis.AllStarTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AllStarTypesResponse;
  return proto.themis.AllStarTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AllStarTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AllStarTypesResponse}
 */
proto.themis.AllStarTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.AllStarType;
      reader.readMessage(value,themis_common_pb.AllStarType.deserializeBinaryFromReader);
      msg.addAllStarTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AllStarTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AllStarTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AllStarTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AllStarTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllStarTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.AllStarType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AllStarType all_star_types = 1;
 * @return {!Array<!proto.themis.AllStarType>}
 */
proto.themis.AllStarTypesResponse.prototype.getAllStarTypesList = function() {
  return /** @type{!Array<!proto.themis.AllStarType>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.AllStarType, 1));
};


/**
 * @param {!Array<!proto.themis.AllStarType>} value
 * @return {!proto.themis.AllStarTypesResponse} returns this
*/
proto.themis.AllStarTypesResponse.prototype.setAllStarTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.AllStarType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.AllStarType}
 */
proto.themis.AllStarTypesResponse.prototype.addAllStarTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.AllStarType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.AllStarTypesResponse} returns this
 */
proto.themis.AllStarTypesResponse.prototype.clearAllStarTypesList = function() {
  return this.setAllStarTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.TiersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.TiersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.TiersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.TiersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TiersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tiersList: jspb.Message.toObjectList(msg.getTiersList(),
    themis_common_pb.Tier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.TiersResponse}
 */
proto.themis.TiersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.TiersResponse;
  return proto.themis.TiersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.TiersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.TiersResponse}
 */
proto.themis.TiersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Tier;
      reader.readMessage(value,themis_common_pb.Tier.deserializeBinaryFromReader);
      msg.addTiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.TiersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.TiersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.TiersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TiersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Tier.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tier tiers = 1;
 * @return {!Array<!proto.themis.Tier>}
 */
proto.themis.TiersResponse.prototype.getTiersList = function() {
  return /** @type{!Array<!proto.themis.Tier>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Tier, 1));
};


/**
 * @param {!Array<!proto.themis.Tier>} value
 * @return {!proto.themis.TiersResponse} returns this
*/
proto.themis.TiersResponse.prototype.setTiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Tier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Tier}
 */
proto.themis.TiersResponse.prototype.addTiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Tier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.TiersResponse} returns this
 */
proto.themis.TiersResponse.prototype.clearTiersList = function() {
  return this.setTiersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.CategoriesResponse.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CategoriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CategoriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CategoriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CategoriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tiersList: jspb.Message.toObjectList(msg.getTiersList(),
    themis_common_pb.Tier.toObject, includeInstance),
    allStarTypesList: jspb.Message.toObjectList(msg.getAllStarTypesList(),
    themis_common_pb.AllStarType.toObject, includeInstance),
    divisionsList: jspb.Message.toObjectList(msg.getDivisionsList(),
    themis_common_pb.Division.toObject, includeInstance),
    scoringAuthoritiesList: jspb.Message.toObjectList(msg.getScoringAuthoritiesList(),
    themis_common_pb.ScoringAuthority.toObject, includeInstance),
    levelsList: jspb.Message.toObjectList(msg.getLevelsList(),
    themis_common_pb.DivisionLevel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CategoriesResponse}
 */
proto.themis.CategoriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CategoriesResponse;
  return proto.themis.CategoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CategoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CategoriesResponse}
 */
proto.themis.CategoriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Tier;
      reader.readMessage(value,themis_common_pb.Tier.deserializeBinaryFromReader);
      msg.addTiers(value);
      break;
    case 2:
      var value = new themis_common_pb.AllStarType;
      reader.readMessage(value,themis_common_pb.AllStarType.deserializeBinaryFromReader);
      msg.addAllStarTypes(value);
      break;
    case 3:
      var value = new themis_common_pb.Division;
      reader.readMessage(value,themis_common_pb.Division.deserializeBinaryFromReader);
      msg.addDivisions(value);
      break;
    case 4:
      var value = new themis_common_pb.ScoringAuthority;
      reader.readMessage(value,themis_common_pb.ScoringAuthority.deserializeBinaryFromReader);
      msg.addScoringAuthorities(value);
      break;
    case 5:
      var value = new themis_common_pb.DivisionLevel;
      reader.readMessage(value,themis_common_pb.DivisionLevel.deserializeBinaryFromReader);
      msg.addLevels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CategoriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CategoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CategoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CategoriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Tier.serializeBinaryToWriter
    );
  }
  f = message.getAllStarTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      themis_common_pb.AllStarType.serializeBinaryToWriter
    );
  }
  f = message.getDivisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      themis_common_pb.Division.serializeBinaryToWriter
    );
  }
  f = message.getScoringAuthoritiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      themis_common_pb.ScoringAuthority.serializeBinaryToWriter
    );
  }
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      themis_common_pb.DivisionLevel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Tier tiers = 1;
 * @return {!Array<!proto.themis.Tier>}
 */
proto.themis.CategoriesResponse.prototype.getTiersList = function() {
  return /** @type{!Array<!proto.themis.Tier>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Tier, 1));
};


/**
 * @param {!Array<!proto.themis.Tier>} value
 * @return {!proto.themis.CategoriesResponse} returns this
*/
proto.themis.CategoriesResponse.prototype.setTiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Tier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Tier}
 */
proto.themis.CategoriesResponse.prototype.addTiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Tier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.CategoriesResponse} returns this
 */
proto.themis.CategoriesResponse.prototype.clearTiersList = function() {
  return this.setTiersList([]);
};


/**
 * repeated AllStarType all_star_types = 2;
 * @return {!Array<!proto.themis.AllStarType>}
 */
proto.themis.CategoriesResponse.prototype.getAllStarTypesList = function() {
  return /** @type{!Array<!proto.themis.AllStarType>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.AllStarType, 2));
};


/**
 * @param {!Array<!proto.themis.AllStarType>} value
 * @return {!proto.themis.CategoriesResponse} returns this
*/
proto.themis.CategoriesResponse.prototype.setAllStarTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.AllStarType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.AllStarType}
 */
proto.themis.CategoriesResponse.prototype.addAllStarTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.AllStarType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.CategoriesResponse} returns this
 */
proto.themis.CategoriesResponse.prototype.clearAllStarTypesList = function() {
  return this.setAllStarTypesList([]);
};


/**
 * repeated Division divisions = 3;
 * @return {!Array<!proto.themis.Division>}
 */
proto.themis.CategoriesResponse.prototype.getDivisionsList = function() {
  return /** @type{!Array<!proto.themis.Division>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Division, 3));
};


/**
 * @param {!Array<!proto.themis.Division>} value
 * @return {!proto.themis.CategoriesResponse} returns this
*/
proto.themis.CategoriesResponse.prototype.setDivisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.Division=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Division}
 */
proto.themis.CategoriesResponse.prototype.addDivisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.Division, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.CategoriesResponse} returns this
 */
proto.themis.CategoriesResponse.prototype.clearDivisionsList = function() {
  return this.setDivisionsList([]);
};


/**
 * repeated ScoringAuthority scoring_authorities = 4;
 * @return {!Array<!proto.themis.ScoringAuthority>}
 */
proto.themis.CategoriesResponse.prototype.getScoringAuthoritiesList = function() {
  return /** @type{!Array<!proto.themis.ScoringAuthority>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.ScoringAuthority, 4));
};


/**
 * @param {!Array<!proto.themis.ScoringAuthority>} value
 * @return {!proto.themis.CategoriesResponse} returns this
*/
proto.themis.CategoriesResponse.prototype.setScoringAuthoritiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.ScoringAuthority=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.ScoringAuthority}
 */
proto.themis.CategoriesResponse.prototype.addScoringAuthorities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.ScoringAuthority, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.CategoriesResponse} returns this
 */
proto.themis.CategoriesResponse.prototype.clearScoringAuthoritiesList = function() {
  return this.setScoringAuthoritiesList([]);
};


/**
 * repeated DivisionLevel levels = 5;
 * @return {!Array<!proto.themis.DivisionLevel>}
 */
proto.themis.CategoriesResponse.prototype.getLevelsList = function() {
  return /** @type{!Array<!proto.themis.DivisionLevel>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.DivisionLevel, 5));
};


/**
 * @param {!Array<!proto.themis.DivisionLevel>} value
 * @return {!proto.themis.CategoriesResponse} returns this
*/
proto.themis.CategoriesResponse.prototype.setLevelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.themis.DivisionLevel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.DivisionLevel}
 */
proto.themis.CategoriesResponse.prototype.addLevels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.themis.DivisionLevel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.CategoriesResponse} returns this
 */
proto.themis.CategoriesResponse.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.DivisionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DivisionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DivisionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DivisionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    divisionsList: jspb.Message.toObjectList(msg.getDivisionsList(),
    themis_common_pb.Division.toObject, includeInstance),
    division: (f = msg.getDivision()) && themis_common_pb.Division.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DivisionsResponse}
 */
proto.themis.DivisionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DivisionsResponse;
  return proto.themis.DivisionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DivisionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DivisionsResponse}
 */
proto.themis.DivisionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Division;
      reader.readMessage(value,themis_common_pb.Division.deserializeBinaryFromReader);
      msg.addDivisions(value);
      break;
    case 2:
      var value = new themis_common_pb.Division;
      reader.readMessage(value,themis_common_pb.Division.deserializeBinaryFromReader);
      msg.setDivision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DivisionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DivisionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DivisionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDivisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Division.serializeBinaryToWriter
    );
  }
  f = message.getDivision();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Division.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Division divisions = 1;
 * @return {!Array<!proto.themis.Division>}
 */
proto.themis.DivisionsResponse.prototype.getDivisionsList = function() {
  return /** @type{!Array<!proto.themis.Division>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Division, 1));
};


/**
 * @param {!Array<!proto.themis.Division>} value
 * @return {!proto.themis.DivisionsResponse} returns this
*/
proto.themis.DivisionsResponse.prototype.setDivisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Division=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Division}
 */
proto.themis.DivisionsResponse.prototype.addDivisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Division, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.DivisionsResponse} returns this
 */
proto.themis.DivisionsResponse.prototype.clearDivisionsList = function() {
  return this.setDivisionsList([]);
};


/**
 * optional Division division = 2;
 * @return {?proto.themis.Division}
 */
proto.themis.DivisionsResponse.prototype.getDivision = function() {
  return /** @type{?proto.themis.Division} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Division, 2));
};


/**
 * @param {?proto.themis.Division|undefined} value
 * @return {!proto.themis.DivisionsResponse} returns this
*/
proto.themis.DivisionsResponse.prototype.setDivision = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DivisionsResponse} returns this
 */
proto.themis.DivisionsResponse.prototype.clearDivision = function() {
  return this.setDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DivisionsResponse.prototype.hasDivision = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventFloorsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventFloorsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventFloorsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventFloorsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFloorsList: jspb.Message.toObjectList(msg.getEventFloorsList(),
    themis_common_pb.EventFloor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventFloorsResponse}
 */
proto.themis.EventFloorsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventFloorsResponse;
  return proto.themis.EventFloorsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventFloorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventFloorsResponse}
 */
proto.themis.EventFloorsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventFloor;
      reader.readMessage(value,themis_common_pb.EventFloor.deserializeBinaryFromReader);
      msg.addEventFloors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventFloorsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventFloorsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventFloorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFloorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventFloor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventFloor event_floors = 1;
 * @return {!Array<!proto.themis.EventFloor>}
 */
proto.themis.EventFloorsResponse.prototype.getEventFloorsList = function() {
  return /** @type{!Array<!proto.themis.EventFloor>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventFloor, 1));
};


/**
 * @param {!Array<!proto.themis.EventFloor>} value
 * @return {!proto.themis.EventFloorsResponse} returns this
*/
proto.themis.EventFloorsResponse.prototype.setEventFloorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventFloor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventFloor}
 */
proto.themis.EventFloorsResponse.prototype.addEventFloors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventFloor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventFloorsResponse} returns this
 */
proto.themis.EventFloorsResponse.prototype.clearEventFloorsList = function() {
  return this.setEventFloorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.SortFloorsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.SortFloorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.SortFloorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.SortFloorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SortFloorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    eventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.SortFloorsRequest}
 */
proto.themis.SortFloorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.SortFloorsRequest;
  return proto.themis.SortFloorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.SortFloorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.SortFloorsRequest}
 */
proto.themis.SortFloorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.SortFloorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.SortFloorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.SortFloorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SortFloorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * repeated uint32 ids = 1;
 * @return {!Array<number>}
 */
proto.themis.SortFloorsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.themis.SortFloorsRequest} returns this
 */
proto.themis.SortFloorsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.themis.SortFloorsRequest} returns this
 */
proto.themis.SortFloorsRequest.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.SortFloorsRequest} returns this
 */
proto.themis.SortFloorsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional uint32 event_id = 2;
 * @return {number}
 */
proto.themis.SortFloorsRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.SortFloorsRequest} returns this
 */
proto.themis.SortFloorsRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 brand_id = 3;
 * @return {number}
 */
proto.themis.SortFloorsRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.SortFloorsRequest} returns this
 */
proto.themis.SortFloorsRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 producer_id = 4;
 * @return {number}
 */
proto.themis.SortFloorsRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.SortFloorsRequest} returns this
 */
proto.themis.SortFloorsRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventTeamResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventTeamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventTeamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventTeamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    themis_common_pb.EventTeam.toObject, includeInstance),
    team: (f = msg.getTeam()) && themis_common_pb.EventTeam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventTeamResponse}
 */
proto.themis.EventTeamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventTeamResponse;
  return proto.themis.EventTeamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventTeamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventTeamResponse}
 */
proto.themis.EventTeamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventTeam;
      reader.readMessage(value,themis_common_pb.EventTeam.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    case 2:
      var value = new themis_common_pb.EventTeam;
      reader.readMessage(value,themis_common_pb.EventTeam.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventTeamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventTeamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventTeamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventTeam.serializeBinaryToWriter
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.EventTeam.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventTeam teams = 1;
 * @return {!Array<!proto.themis.EventTeam>}
 */
proto.themis.EventTeamResponse.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.themis.EventTeam>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventTeam, 1));
};


/**
 * @param {!Array<!proto.themis.EventTeam>} value
 * @return {!proto.themis.EventTeamResponse} returns this
*/
proto.themis.EventTeamResponse.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventTeam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventTeam}
 */
proto.themis.EventTeamResponse.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventTeam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventTeamResponse} returns this
 */
proto.themis.EventTeamResponse.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};


/**
 * optional EventTeam team = 2;
 * @return {?proto.themis.EventTeam}
 */
proto.themis.EventTeamResponse.prototype.getTeam = function() {
  return /** @type{?proto.themis.EventTeam} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventTeam, 2));
};


/**
 * @param {?proto.themis.EventTeam|undefined} value
 * @return {!proto.themis.EventTeamResponse} returns this
*/
proto.themis.EventTeamResponse.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventTeamResponse} returns this
 */
proto.themis.EventTeamResponse.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventTeamResponse.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GetEventTeamRegistrationTransactionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GetEventTeamRegistrationTransactionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    themis_common_pb.RegistrationTransaction.toObject, includeInstance),
    depositAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    creditAmount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GetEventTeamRegistrationTransactionsResponse}
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GetEventTeamRegistrationTransactionsResponse;
  return proto.themis.GetEventTeamRegistrationTransactionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GetEventTeamRegistrationTransactionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GetEventTeamRegistrationTransactionsResponse}
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.RegistrationTransaction;
      reader.readMessage(value,themis_common_pb.RegistrationTransaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDepositAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreditAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GetEventTeamRegistrationTransactionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GetEventTeamRegistrationTransactionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.RegistrationTransaction.serializeBinaryToWriter
    );
  }
  f = message.getDepositAmount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCreditAmount();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * repeated RegistrationTransaction transactions = 1;
 * @return {!Array<!proto.themis.RegistrationTransaction>}
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.themis.RegistrationTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.RegistrationTransaction, 1));
};


/**
 * @param {!Array<!proto.themis.RegistrationTransaction>} value
 * @return {!proto.themis.GetEventTeamRegistrationTransactionsResponse} returns this
*/
proto.themis.GetEventTeamRegistrationTransactionsResponse.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.RegistrationTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.RegistrationTransaction}
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.RegistrationTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GetEventTeamRegistrationTransactionsResponse} returns this
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional uint32 deposit_amount = 2;
 * @return {number}
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.prototype.getDepositAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetEventTeamRegistrationTransactionsResponse} returns this
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.prototype.setDepositAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 credit_amount = 3;
 * @return {number}
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.prototype.getCreditAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GetEventTeamRegistrationTransactionsResponse} returns this
 */
proto.themis.GetEventTeamRegistrationTransactionsResponse.prototype.setCreditAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AthleteEventTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AthleteEventTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AthleteEventTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthleteEventTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    athlete: (f = msg.getAthlete()) && themis_common_pb.Athlete.toObject(includeInstance, f),
    eventTeam: (f = msg.getEventTeam()) && themis_common_pb.EventTeam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AthleteEventTeamRequest}
 */
proto.themis.AthleteEventTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AthleteEventTeamRequest;
  return proto.themis.AthleteEventTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AthleteEventTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AthleteEventTeamRequest}
 */
proto.themis.AthleteEventTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.setAthlete(value);
      break;
    case 2:
      var value = new themis_common_pb.EventTeam;
      reader.readMessage(value,themis_common_pb.EventTeam.deserializeBinaryFromReader);
      msg.setEventTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AthleteEventTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AthleteEventTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AthleteEventTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthleteEventTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAthlete();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getEventTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.EventTeam.serializeBinaryToWriter
    );
  }
};


/**
 * optional Athlete athlete = 1;
 * @return {?proto.themis.Athlete}
 */
proto.themis.AthleteEventTeamRequest.prototype.getAthlete = function() {
  return /** @type{?proto.themis.Athlete} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Athlete, 1));
};


/**
 * @param {?proto.themis.Athlete|undefined} value
 * @return {!proto.themis.AthleteEventTeamRequest} returns this
*/
proto.themis.AthleteEventTeamRequest.prototype.setAthlete = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.AthleteEventTeamRequest} returns this
 */
proto.themis.AthleteEventTeamRequest.prototype.clearAthlete = function() {
  return this.setAthlete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.AthleteEventTeamRequest.prototype.hasAthlete = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EventTeam event_team = 2;
 * @return {?proto.themis.EventTeam}
 */
proto.themis.AthleteEventTeamRequest.prototype.getEventTeam = function() {
  return /** @type{?proto.themis.EventTeam} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventTeam, 2));
};


/**
 * @param {?proto.themis.EventTeam|undefined} value
 * @return {!proto.themis.AthleteEventTeamRequest} returns this
*/
proto.themis.AthleteEventTeamRequest.prototype.setEventTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.AthleteEventTeamRequest} returns this
 */
proto.themis.AthleteEventTeamRequest.prototype.clearEventTeam = function() {
  return this.setEventTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.AthleteEventTeamRequest.prototype.hasEventTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.SubstituteAthleteEventTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.SubstituteAthleteEventTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.SubstituteAthleteEventTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SubstituteAthleteEventTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentAthlete: (f = msg.getCurrentAthlete()) && themis_common_pb.Athlete.toObject(includeInstance, f),
    replacementAthlete: (f = msg.getReplacementAthlete()) && themis_common_pb.Athlete.toObject(includeInstance, f),
    eventTeam: (f = msg.getEventTeam()) && themis_common_pb.EventTeam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.SubstituteAthleteEventTeamRequest}
 */
proto.themis.SubstituteAthleteEventTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.SubstituteAthleteEventTeamRequest;
  return proto.themis.SubstituteAthleteEventTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.SubstituteAthleteEventTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.SubstituteAthleteEventTeamRequest}
 */
proto.themis.SubstituteAthleteEventTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.setCurrentAthlete(value);
      break;
    case 2:
      var value = new themis_common_pb.Athlete;
      reader.readMessage(value,themis_common_pb.Athlete.deserializeBinaryFromReader);
      msg.setReplacementAthlete(value);
      break;
    case 3:
      var value = new themis_common_pb.EventTeam;
      reader.readMessage(value,themis_common_pb.EventTeam.deserializeBinaryFromReader);
      msg.setEventTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.SubstituteAthleteEventTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.SubstituteAthleteEventTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.SubstituteAthleteEventTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SubstituteAthleteEventTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentAthlete();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getReplacementAthlete();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getEventTeam();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      themis_common_pb.EventTeam.serializeBinaryToWriter
    );
  }
};


/**
 * optional Athlete current_athlete = 1;
 * @return {?proto.themis.Athlete}
 */
proto.themis.SubstituteAthleteEventTeamRequest.prototype.getCurrentAthlete = function() {
  return /** @type{?proto.themis.Athlete} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Athlete, 1));
};


/**
 * @param {?proto.themis.Athlete|undefined} value
 * @return {!proto.themis.SubstituteAthleteEventTeamRequest} returns this
*/
proto.themis.SubstituteAthleteEventTeamRequest.prototype.setCurrentAthlete = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.SubstituteAthleteEventTeamRequest} returns this
 */
proto.themis.SubstituteAthleteEventTeamRequest.prototype.clearCurrentAthlete = function() {
  return this.setCurrentAthlete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.SubstituteAthleteEventTeamRequest.prototype.hasCurrentAthlete = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Athlete replacement_athlete = 2;
 * @return {?proto.themis.Athlete}
 */
proto.themis.SubstituteAthleteEventTeamRequest.prototype.getReplacementAthlete = function() {
  return /** @type{?proto.themis.Athlete} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Athlete, 2));
};


/**
 * @param {?proto.themis.Athlete|undefined} value
 * @return {!proto.themis.SubstituteAthleteEventTeamRequest} returns this
*/
proto.themis.SubstituteAthleteEventTeamRequest.prototype.setReplacementAthlete = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.SubstituteAthleteEventTeamRequest} returns this
 */
proto.themis.SubstituteAthleteEventTeamRequest.prototype.clearReplacementAthlete = function() {
  return this.setReplacementAthlete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.SubstituteAthleteEventTeamRequest.prototype.hasReplacementAthlete = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventTeam event_team = 3;
 * @return {?proto.themis.EventTeam}
 */
proto.themis.SubstituteAthleteEventTeamRequest.prototype.getEventTeam = function() {
  return /** @type{?proto.themis.EventTeam} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventTeam, 3));
};


/**
 * @param {?proto.themis.EventTeam|undefined} value
 * @return {!proto.themis.SubstituteAthleteEventTeamRequest} returns this
*/
proto.themis.SubstituteAthleteEventTeamRequest.prototype.setEventTeam = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.SubstituteAthleteEventTeamRequest} returns this
 */
proto.themis.SubstituteAthleteEventTeamRequest.prototype.clearEventTeam = function() {
  return this.setEventTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.SubstituteAthleteEventTeamRequest.prototype.hasEventTeam = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventDivisionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    divisionsList: jspb.Message.toObjectList(msg.getDivisionsList(),
    themis_common_pb.EventDivision.toObject, includeInstance),
    division: (f = msg.getDivision()) && themis_common_pb.EventDivision.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionResponse}
 */
proto.themis.EventDivisionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionResponse;
  return proto.themis.EventDivisionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionResponse}
 */
proto.themis.EventDivisionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventDivision;
      reader.readMessage(value,themis_common_pb.EventDivision.deserializeBinaryFromReader);
      msg.addDivisions(value);
      break;
    case 2:
      var value = new themis_common_pb.EventDivision;
      reader.readMessage(value,themis_common_pb.EventDivision.deserializeBinaryFromReader);
      msg.setDivision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDivisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventDivision.serializeBinaryToWriter
    );
  }
  f = message.getDivision();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.EventDivision.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventDivision divisions = 1;
 * @return {!Array<!proto.themis.EventDivision>}
 */
proto.themis.EventDivisionResponse.prototype.getDivisionsList = function() {
  return /** @type{!Array<!proto.themis.EventDivision>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventDivision, 1));
};


/**
 * @param {!Array<!proto.themis.EventDivision>} value
 * @return {!proto.themis.EventDivisionResponse} returns this
*/
proto.themis.EventDivisionResponse.prototype.setDivisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventDivision=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivision}
 */
proto.themis.EventDivisionResponse.prototype.addDivisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventDivision, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivisionResponse} returns this
 */
proto.themis.EventDivisionResponse.prototype.clearDivisionsList = function() {
  return this.setDivisionsList([]);
};


/**
 * optional EventDivision division = 2;
 * @return {?proto.themis.EventDivision}
 */
proto.themis.EventDivisionResponse.prototype.getDivision = function() {
  return /** @type{?proto.themis.EventDivision} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventDivision, 2));
};


/**
 * @param {?proto.themis.EventDivision|undefined} value
 * @return {!proto.themis.EventDivisionResponse} returns this
*/
proto.themis.EventDivisionResponse.prototype.setDivision = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionResponse} returns this
 */
proto.themis.EventDivisionResponse.prototype.clearDivision = function() {
  return this.setDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionResponse.prototype.hasDivision = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventDivisionUsageResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionUsageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionUsageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionUsageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionUsageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usagesList: jspb.Message.toObjectList(msg.getUsagesList(),
    themis_common_pb.EventDivisionUsage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionUsageResponse}
 */
proto.themis.EventDivisionUsageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionUsageResponse;
  return proto.themis.EventDivisionUsageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionUsageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionUsageResponse}
 */
proto.themis.EventDivisionUsageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventDivisionUsage;
      reader.readMessage(value,themis_common_pb.EventDivisionUsage.deserializeBinaryFromReader);
      msg.addUsages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionUsageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionUsageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionUsageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionUsageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventDivisionUsage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventDivisionUsage usages = 1;
 * @return {!Array<!proto.themis.EventDivisionUsage>}
 */
proto.themis.EventDivisionUsageResponse.prototype.getUsagesList = function() {
  return /** @type{!Array<!proto.themis.EventDivisionUsage>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventDivisionUsage, 1));
};


/**
 * @param {!Array<!proto.themis.EventDivisionUsage>} value
 * @return {!proto.themis.EventDivisionUsageResponse} returns this
*/
proto.themis.EventDivisionUsageResponse.prototype.setUsagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventDivisionUsage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivisionUsage}
 */
proto.themis.EventDivisionUsageResponse.prototype.addUsages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventDivisionUsage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivisionUsageResponse} returns this
 */
proto.themis.EventDivisionUsageResponse.prototype.clearUsagesList = function() {
  return this.setUsagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GetSeasonsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GetSeasonsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GetSeasonsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetSeasonsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestedDate: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GetSeasonsRequest}
 */
proto.themis.GetSeasonsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GetSeasonsRequest;
  return proto.themis.GetSeasonsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GetSeasonsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GetSeasonsRequest}
 */
proto.themis.GetSeasonsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestedDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GetSeasonsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GetSeasonsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GetSeasonsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetSeasonsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestedDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string requested_date = 1;
 * @return {string}
 */
proto.themis.GetSeasonsRequest.prototype.getRequestedDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GetSeasonsRequest} returns this
 */
proto.themis.GetSeasonsRequest.prototype.setRequestedDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.SeasonsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.SeasonsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.SeasonsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.SeasonsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SeasonsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    seasonsList: jspb.Message.toObjectList(msg.getSeasonsList(),
    themis_common_pb.Season.toObject, includeInstance),
    season: (f = msg.getSeason()) && themis_common_pb.Season.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.SeasonsResponse}
 */
proto.themis.SeasonsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.SeasonsResponse;
  return proto.themis.SeasonsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.SeasonsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.SeasonsResponse}
 */
proto.themis.SeasonsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Season;
      reader.readMessage(value,themis_common_pb.Season.deserializeBinaryFromReader);
      msg.addSeasons(value);
      break;
    case 2:
      var value = new themis_common_pb.Season;
      reader.readMessage(value,themis_common_pb.Season.deserializeBinaryFromReader);
      msg.setSeason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.SeasonsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.SeasonsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.SeasonsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SeasonsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeasonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Season.serializeBinaryToWriter
    );
  }
  f = message.getSeason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Season.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Season seasons = 1;
 * @return {!Array<!proto.themis.Season>}
 */
proto.themis.SeasonsResponse.prototype.getSeasonsList = function() {
  return /** @type{!Array<!proto.themis.Season>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Season, 1));
};


/**
 * @param {!Array<!proto.themis.Season>} value
 * @return {!proto.themis.SeasonsResponse} returns this
*/
proto.themis.SeasonsResponse.prototype.setSeasonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Season=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Season}
 */
proto.themis.SeasonsResponse.prototype.addSeasons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Season, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.SeasonsResponse} returns this
 */
proto.themis.SeasonsResponse.prototype.clearSeasonsList = function() {
  return this.setSeasonsList([]);
};


/**
 * optional Season season = 2;
 * @return {?proto.themis.Season}
 */
proto.themis.SeasonsResponse.prototype.getSeason = function() {
  return /** @type{?proto.themis.Season} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Season, 2));
};


/**
 * @param {?proto.themis.Season|undefined} value
 * @return {!proto.themis.SeasonsResponse} returns this
*/
proto.themis.SeasonsResponse.prototype.setSeason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.SeasonsResponse} returns this
 */
proto.themis.SeasonsResponse.prototype.clearSeason = function() {
  return this.setSeason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.SeasonsResponse.prototype.hasSeason = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CoachEventTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CoachEventTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CoachEventTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CoachEventTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    coach: (f = msg.getCoach()) && themis_common_pb.Coach.toObject(includeInstance, f),
    eventTeam: (f = msg.getEventTeam()) && themis_common_pb.EventTeam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CoachEventTeamRequest}
 */
proto.themis.CoachEventTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CoachEventTeamRequest;
  return proto.themis.CoachEventTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CoachEventTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CoachEventTeamRequest}
 */
proto.themis.CoachEventTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Coach;
      reader.readMessage(value,themis_common_pb.Coach.deserializeBinaryFromReader);
      msg.setCoach(value);
      break;
    case 2:
      var value = new themis_common_pb.EventTeam;
      reader.readMessage(value,themis_common_pb.EventTeam.deserializeBinaryFromReader);
      msg.setEventTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CoachEventTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CoachEventTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CoachEventTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CoachEventTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoach();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Coach.serializeBinaryToWriter
    );
  }
  f = message.getEventTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.EventTeam.serializeBinaryToWriter
    );
  }
};


/**
 * optional Coach coach = 1;
 * @return {?proto.themis.Coach}
 */
proto.themis.CoachEventTeamRequest.prototype.getCoach = function() {
  return /** @type{?proto.themis.Coach} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Coach, 1));
};


/**
 * @param {?proto.themis.Coach|undefined} value
 * @return {!proto.themis.CoachEventTeamRequest} returns this
*/
proto.themis.CoachEventTeamRequest.prototype.setCoach = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.CoachEventTeamRequest} returns this
 */
proto.themis.CoachEventTeamRequest.prototype.clearCoach = function() {
  return this.setCoach(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.CoachEventTeamRequest.prototype.hasCoach = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EventTeam event_team = 2;
 * @return {?proto.themis.EventTeam}
 */
proto.themis.CoachEventTeamRequest.prototype.getEventTeam = function() {
  return /** @type{?proto.themis.EventTeam} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventTeam, 2));
};


/**
 * @param {?proto.themis.EventTeam|undefined} value
 * @return {!proto.themis.CoachEventTeamRequest} returns this
*/
proto.themis.CoachEventTeamRequest.prototype.setEventTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.CoachEventTeamRequest} returns this
 */
proto.themis.CoachEventTeamRequest.prototype.clearEventTeam = function() {
  return this.setEventTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.CoachEventTeamRequest.prototype.hasEventTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DivisionImportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DivisionImportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DivisionImportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionImportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    season: (f = msg.getSeason()) && themis_common_pb.Season.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DivisionImportRequest}
 */
proto.themis.DivisionImportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DivisionImportRequest;
  return proto.themis.DivisionImportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DivisionImportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DivisionImportRequest}
 */
proto.themis.DivisionImportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new themis_common_pb.Season;
      reader.readMessage(value,themis_common_pb.Season.deserializeBinaryFromReader);
      msg.setSeason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DivisionImportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DivisionImportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DivisionImportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionImportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Season.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.themis.DivisionImportRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DivisionImportRequest} returns this
 */
proto.themis.DivisionImportRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Season season = 2;
 * @return {?proto.themis.Season}
 */
proto.themis.DivisionImportRequest.prototype.getSeason = function() {
  return /** @type{?proto.themis.Season} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Season, 2));
};


/**
 * @param {?proto.themis.Season|undefined} value
 * @return {!proto.themis.DivisionImportRequest} returns this
*/
proto.themis.DivisionImportRequest.prototype.setSeason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DivisionImportRequest} returns this
 */
proto.themis.DivisionImportRequest.prototype.clearSeason = function() {
  return this.setSeason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DivisionImportRequest.prototype.hasSeason = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.PersonnelEventTeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.PersonnelEventTeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.PersonnelEventTeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PersonnelEventTeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personnel: (f = msg.getPersonnel()) && themis_common_pb.Personnel.toObject(includeInstance, f),
    eventTeam: (f = msg.getEventTeam()) && themis_common_pb.EventTeam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.PersonnelEventTeamRequest}
 */
proto.themis.PersonnelEventTeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.PersonnelEventTeamRequest;
  return proto.themis.PersonnelEventTeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.PersonnelEventTeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.PersonnelEventTeamRequest}
 */
proto.themis.PersonnelEventTeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Personnel;
      reader.readMessage(value,themis_common_pb.Personnel.deserializeBinaryFromReader);
      msg.setPersonnel(value);
      break;
    case 2:
      var value = new themis_common_pb.EventTeam;
      reader.readMessage(value,themis_common_pb.EventTeam.deserializeBinaryFromReader);
      msg.setEventTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.PersonnelEventTeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.PersonnelEventTeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.PersonnelEventTeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PersonnelEventTeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonnel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Personnel.serializeBinaryToWriter
    );
  }
  f = message.getEventTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.EventTeam.serializeBinaryToWriter
    );
  }
};


/**
 * optional Personnel personnel = 1;
 * @return {?proto.themis.Personnel}
 */
proto.themis.PersonnelEventTeamRequest.prototype.getPersonnel = function() {
  return /** @type{?proto.themis.Personnel} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Personnel, 1));
};


/**
 * @param {?proto.themis.Personnel|undefined} value
 * @return {!proto.themis.PersonnelEventTeamRequest} returns this
*/
proto.themis.PersonnelEventTeamRequest.prototype.setPersonnel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.PersonnelEventTeamRequest} returns this
 */
proto.themis.PersonnelEventTeamRequest.prototype.clearPersonnel = function() {
  return this.setPersonnel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.PersonnelEventTeamRequest.prototype.hasPersonnel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EventTeam event_team = 2;
 * @return {?proto.themis.EventTeam}
 */
proto.themis.PersonnelEventTeamRequest.prototype.getEventTeam = function() {
  return /** @type{?proto.themis.EventTeam} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventTeam, 2));
};


/**
 * @param {?proto.themis.EventTeam|undefined} value
 * @return {!proto.themis.PersonnelEventTeamRequest} returns this
*/
proto.themis.PersonnelEventTeamRequest.prototype.setEventTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.PersonnelEventTeamRequest} returns this
 */
proto.themis.PersonnelEventTeamRequest.prototype.clearEventTeam = function() {
  return this.setEventTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.PersonnelEventTeamRequest.prototype.hasEventTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.TeamScoringAuthorityResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.TeamScoringAuthorityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.TeamScoringAuthorityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.TeamScoringAuthorityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamScoringAuthorityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamScoringAuthority: (f = msg.getTeamScoringAuthority()) && themis_common_pb.TeamScoringAuthority.toObject(includeInstance, f),
    teamScoringAuthoritiesList: jspb.Message.toObjectList(msg.getTeamScoringAuthoritiesList(),
    themis_common_pb.TeamScoringAuthority.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.TeamScoringAuthorityResponse}
 */
proto.themis.TeamScoringAuthorityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.TeamScoringAuthorityResponse;
  return proto.themis.TeamScoringAuthorityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.TeamScoringAuthorityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.TeamScoringAuthorityResponse}
 */
proto.themis.TeamScoringAuthorityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.TeamScoringAuthority;
      reader.readMessage(value,themis_common_pb.TeamScoringAuthority.deserializeBinaryFromReader);
      msg.setTeamScoringAuthority(value);
      break;
    case 2:
      var value = new themis_common_pb.TeamScoringAuthority;
      reader.readMessage(value,themis_common_pb.TeamScoringAuthority.deserializeBinaryFromReader);
      msg.addTeamScoringAuthorities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.TeamScoringAuthorityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.TeamScoringAuthorityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.TeamScoringAuthorityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamScoringAuthorityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamScoringAuthority();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.TeamScoringAuthority.serializeBinaryToWriter
    );
  }
  f = message.getTeamScoringAuthoritiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      themis_common_pb.TeamScoringAuthority.serializeBinaryToWriter
    );
  }
};


/**
 * optional TeamScoringAuthority team_scoring_authority = 1;
 * @return {?proto.themis.TeamScoringAuthority}
 */
proto.themis.TeamScoringAuthorityResponse.prototype.getTeamScoringAuthority = function() {
  return /** @type{?proto.themis.TeamScoringAuthority} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.TeamScoringAuthority, 1));
};


/**
 * @param {?proto.themis.TeamScoringAuthority|undefined} value
 * @return {!proto.themis.TeamScoringAuthorityResponse} returns this
*/
proto.themis.TeamScoringAuthorityResponse.prototype.setTeamScoringAuthority = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.TeamScoringAuthorityResponse} returns this
 */
proto.themis.TeamScoringAuthorityResponse.prototype.clearTeamScoringAuthority = function() {
  return this.setTeamScoringAuthority(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.TeamScoringAuthorityResponse.prototype.hasTeamScoringAuthority = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TeamScoringAuthority team_scoring_authorities = 2;
 * @return {!Array<!proto.themis.TeamScoringAuthority>}
 */
proto.themis.TeamScoringAuthorityResponse.prototype.getTeamScoringAuthoritiesList = function() {
  return /** @type{!Array<!proto.themis.TeamScoringAuthority>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.TeamScoringAuthority, 2));
};


/**
 * @param {!Array<!proto.themis.TeamScoringAuthority>} value
 * @return {!proto.themis.TeamScoringAuthorityResponse} returns this
*/
proto.themis.TeamScoringAuthorityResponse.prototype.setTeamScoringAuthoritiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.TeamScoringAuthority=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.TeamScoringAuthority}
 */
proto.themis.TeamScoringAuthorityResponse.prototype.addTeamScoringAuthorities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.TeamScoringAuthority, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.TeamScoringAuthorityResponse} returns this
 */
proto.themis.TeamScoringAuthorityResponse.prototype.clearTeamScoringAuthoritiesList = function() {
  return this.setTeamScoringAuthoritiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.JudgeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.JudgeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.JudgeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.JudgeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.JudgeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    judgesList: jspb.Message.toObjectList(msg.getJudgesList(),
    themis_common_pb.Judge.toObject, includeInstance),
    judge: (f = msg.getJudge()) && themis_common_pb.Judge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.JudgeResponse}
 */
proto.themis.JudgeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.JudgeResponse;
  return proto.themis.JudgeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.JudgeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.JudgeResponse}
 */
proto.themis.JudgeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Judge;
      reader.readMessage(value,themis_common_pb.Judge.deserializeBinaryFromReader);
      msg.addJudges(value);
      break;
    case 2:
      var value = new themis_common_pb.Judge;
      reader.readMessage(value,themis_common_pb.Judge.deserializeBinaryFromReader);
      msg.setJudge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.JudgeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.JudgeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.JudgeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.JudgeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJudgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.Judge.serializeBinaryToWriter
    );
  }
  f = message.getJudge();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Judge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Judge judges = 1;
 * @return {!Array<!proto.themis.Judge>}
 */
proto.themis.JudgeResponse.prototype.getJudgesList = function() {
  return /** @type{!Array<!proto.themis.Judge>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.Judge, 1));
};


/**
 * @param {!Array<!proto.themis.Judge>} value
 * @return {!proto.themis.JudgeResponse} returns this
*/
proto.themis.JudgeResponse.prototype.setJudgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Judge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Judge}
 */
proto.themis.JudgeResponse.prototype.addJudges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Judge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.JudgeResponse} returns this
 */
proto.themis.JudgeResponse.prototype.clearJudgesList = function() {
  return this.setJudgesList([]);
};


/**
 * optional Judge judge = 2;
 * @return {?proto.themis.Judge}
 */
proto.themis.JudgeResponse.prototype.getJudge = function() {
  return /** @type{?proto.themis.Judge} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Judge, 2));
};


/**
 * @param {?proto.themis.Judge|undefined} value
 * @return {!proto.themis.JudgeResponse} returns this
*/
proto.themis.JudgeResponse.prototype.setJudge = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.JudgeResponse} returns this
 */
proto.themis.JudgeResponse.prototype.clearJudge = function() {
  return this.setJudge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.JudgeResponse.prototype.hasJudge = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.JudgeBrandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.JudgeBrandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.JudgeBrandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.JudgeBrandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    judge: (f = msg.getJudge()) && themis_common_pb.Judge.toObject(includeInstance, f),
    brand: (f = msg.getBrand()) && themis_common_pb.Brand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.JudgeBrandRequest}
 */
proto.themis.JudgeBrandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.JudgeBrandRequest;
  return proto.themis.JudgeBrandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.JudgeBrandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.JudgeBrandRequest}
 */
proto.themis.JudgeBrandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Judge;
      reader.readMessage(value,themis_common_pb.Judge.deserializeBinaryFromReader);
      msg.setJudge(value);
      break;
    case 2:
      var value = new themis_common_pb.Brand;
      reader.readMessage(value,themis_common_pb.Brand.deserializeBinaryFromReader);
      msg.setBrand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.JudgeBrandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.JudgeBrandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.JudgeBrandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.JudgeBrandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJudge();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Judge.serializeBinaryToWriter
    );
  }
  f = message.getBrand();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Brand.serializeBinaryToWriter
    );
  }
};


/**
 * optional Judge judge = 1;
 * @return {?proto.themis.Judge}
 */
proto.themis.JudgeBrandRequest.prototype.getJudge = function() {
  return /** @type{?proto.themis.Judge} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Judge, 1));
};


/**
 * @param {?proto.themis.Judge|undefined} value
 * @return {!proto.themis.JudgeBrandRequest} returns this
*/
proto.themis.JudgeBrandRequest.prototype.setJudge = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.JudgeBrandRequest} returns this
 */
proto.themis.JudgeBrandRequest.prototype.clearJudge = function() {
  return this.setJudge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.JudgeBrandRequest.prototype.hasJudge = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Brand brand = 2;
 * @return {?proto.themis.Brand}
 */
proto.themis.JudgeBrandRequest.prototype.getBrand = function() {
  return /** @type{?proto.themis.Brand} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Brand, 2));
};


/**
 * @param {?proto.themis.Brand|undefined} value
 * @return {!proto.themis.JudgeBrandRequest} returns this
*/
proto.themis.JudgeBrandRequest.prototype.setBrand = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.JudgeBrandRequest} returns this
 */
proto.themis.JudgeBrandRequest.prototype.clearBrand = function() {
  return this.setBrand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.JudgeBrandRequest.prototype.hasBrand = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.JudgeProducerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.JudgeProducerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.JudgeProducerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.JudgeProducerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    judge: (f = msg.getJudge()) && themis_common_pb.Judge.toObject(includeInstance, f),
    producer: (f = msg.getProducer()) && themis_common_pb.Producer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.JudgeProducerRequest}
 */
proto.themis.JudgeProducerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.JudgeProducerRequest;
  return proto.themis.JudgeProducerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.JudgeProducerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.JudgeProducerRequest}
 */
proto.themis.JudgeProducerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.Judge;
      reader.readMessage(value,themis_common_pb.Judge.deserializeBinaryFromReader);
      msg.setJudge(value);
      break;
    case 2:
      var value = new themis_common_pb.Producer;
      reader.readMessage(value,themis_common_pb.Producer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.JudgeProducerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.JudgeProducerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.JudgeProducerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.JudgeProducerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJudge();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.Judge.serializeBinaryToWriter
    );
  }
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      themis_common_pb.Producer.serializeBinaryToWriter
    );
  }
};


/**
 * optional Judge judge = 1;
 * @return {?proto.themis.Judge}
 */
proto.themis.JudgeProducerRequest.prototype.getJudge = function() {
  return /** @type{?proto.themis.Judge} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Judge, 1));
};


/**
 * @param {?proto.themis.Judge|undefined} value
 * @return {!proto.themis.JudgeProducerRequest} returns this
*/
proto.themis.JudgeProducerRequest.prototype.setJudge = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.JudgeProducerRequest} returns this
 */
proto.themis.JudgeProducerRequest.prototype.clearJudge = function() {
  return this.setJudge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.JudgeProducerRequest.prototype.hasJudge = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Producer producer = 2;
 * @return {?proto.themis.Producer}
 */
proto.themis.JudgeProducerRequest.prototype.getProducer = function() {
  return /** @type{?proto.themis.Producer} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.Producer, 2));
};


/**
 * @param {?proto.themis.Producer|undefined} value
 * @return {!proto.themis.JudgeProducerRequest} returns this
*/
proto.themis.JudgeProducerRequest.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.JudgeProducerRequest} returns this
 */
proto.themis.JudgeProducerRequest.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.JudgeProducerRequest.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.BugReport.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.BugReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.BugReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.BugReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productVersion: jspb.Message.getFieldWithDefault(msg, 3, ""),
    reduxState: jspb.Message.getFieldWithDefault(msg, 4, ""),
    geolocation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    path: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accessToken: jspb.Message.getFieldWithDefault(msg, 7, ""),
    darkState: jspb.Message.getFieldWithDefault(msg, 8, ""),
    programOrProducer: jspb.Message.getFieldWithDefault(msg, 9, ""),
    type: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.BugReport}
 */
proto.themis.BugReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.BugReport;
  return proto.themis.BugReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.BugReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.BugReport}
 */
proto.themis.BugReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReduxState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeolocation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDarkState(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramOrProducer(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.BugReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.BugReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.BugReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.BugReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getProductVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReduxState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGeolocation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDarkState();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProgramOrProducer();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.BugReport.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.BugReport} returns this
 */
proto.themis.BugReport.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 user_id = 2;
 * @return {number}
 */
proto.themis.BugReport.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.BugReport} returns this
 */
proto.themis.BugReport.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string product_version = 3;
 * @return {string}
 */
proto.themis.BugReport.prototype.getProductVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.BugReport} returns this
 */
proto.themis.BugReport.prototype.setProductVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string redux_state = 4;
 * @return {string}
 */
proto.themis.BugReport.prototype.getReduxState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.BugReport} returns this
 */
proto.themis.BugReport.prototype.setReduxState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string geolocation = 5;
 * @return {string}
 */
proto.themis.BugReport.prototype.getGeolocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.BugReport} returns this
 */
proto.themis.BugReport.prototype.setGeolocation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string path = 6;
 * @return {string}
 */
proto.themis.BugReport.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.BugReport} returns this
 */
proto.themis.BugReport.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string access_token = 7;
 * @return {string}
 */
proto.themis.BugReport.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.BugReport} returns this
 */
proto.themis.BugReport.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dark_state = 8;
 * @return {string}
 */
proto.themis.BugReport.prototype.getDarkState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.BugReport} returns this
 */
proto.themis.BugReport.prototype.setDarkState = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string program_or_producer = 9;
 * @return {string}
 */
proto.themis.BugReport.prototype.getProgramOrProducer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.BugReport} returns this
 */
proto.themis.BugReport.prototype.setProgramOrProducer = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string type = 10;
 * @return {string}
 */
proto.themis.BugReport.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.BugReport} returns this
 */
proto.themis.BugReport.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string description = 11;
 * @return {string}
 */
proto.themis.BugReport.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.BugReport} returns this
 */
proto.themis.BugReport.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AddStripeSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AddStripeSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AddStripeSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddStripeSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AddStripeSourceRequest}
 */
proto.themis.AddStripeSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AddStripeSourceRequest;
  return proto.themis.AddStripeSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AddStripeSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AddStripeSourceRequest}
 */
proto.themis.AddStripeSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AddStripeSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AddStripeSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AddStripeSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddStripeSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.themis.AddStripeSourceRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddStripeSourceRequest} returns this
 */
proto.themis.AddStripeSourceRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.VerifyACHSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.VerifyACHSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.VerifyACHSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.VerifyACHSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount1: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount2: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.VerifyACHSourceRequest}
 */
proto.themis.VerifyACHSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.VerifyACHSourceRequest;
  return proto.themis.VerifyACHSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.VerifyACHSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.VerifyACHSourceRequest}
 */
proto.themis.VerifyACHSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount1(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.VerifyACHSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.VerifyACHSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.VerifyACHSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.VerifyACHSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount1();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAmount2();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.themis.VerifyACHSourceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.VerifyACHSourceRequest} returns this
 */
proto.themis.VerifyACHSourceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 amount1 = 2;
 * @return {number}
 */
proto.themis.VerifyACHSourceRequest.prototype.getAmount1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.VerifyACHSourceRequest} returns this
 */
proto.themis.VerifyACHSourceRequest.prototype.setAmount1 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 amount2 = 3;
 * @return {number}
 */
proto.themis.VerifyACHSourceRequest.prototype.getAmount2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.VerifyACHSourceRequest} returns this
 */
proto.themis.VerifyACHSourceRequest.prototype.setAmount2 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    producerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    teamId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    athleteId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    judgeId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    startDate: jspb.Message.getFieldWithDefault(msg, 9, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ReportRequest}
 */
proto.themis.ReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ReportRequest;
  return proto.themis.ReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ReportRequest}
 */
proto.themis.ReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAthleteId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setJudgeId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTeamId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAthleteId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getJudgeId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional uint32 producer_id = 1;
 * @return {number}
 */
proto.themis.ReportRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ReportRequest} returns this
 */
proto.themis.ReportRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 brand_id = 2;
 * @return {number}
 */
proto.themis.ReportRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ReportRequest} returns this
 */
proto.themis.ReportRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 event_id = 3;
 * @return {number}
 */
proto.themis.ReportRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ReportRequest} returns this
 */
proto.themis.ReportRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 program_id = 4;
 * @return {number}
 */
proto.themis.ReportRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ReportRequest} returns this
 */
proto.themis.ReportRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 location_id = 5;
 * @return {number}
 */
proto.themis.ReportRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ReportRequest} returns this
 */
proto.themis.ReportRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 team_id = 6;
 * @return {number}
 */
proto.themis.ReportRequest.prototype.getTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ReportRequest} returns this
 */
proto.themis.ReportRequest.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 athlete_id = 7;
 * @return {number}
 */
proto.themis.ReportRequest.prototype.getAthleteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ReportRequest} returns this
 */
proto.themis.ReportRequest.prototype.setAthleteId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 judge_id = 8;
 * @return {number}
 */
proto.themis.ReportRequest.prototype.getJudgeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ReportRequest} returns this
 */
proto.themis.ReportRequest.prototype.setJudgeId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string start_date = 9;
 * @return {string}
 */
proto.themis.ReportRequest.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ReportRequest} returns this
 */
proto.themis.ReportRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string end_date = 10;
 * @return {string}
 */
proto.themis.ReportRequest.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ReportRequest} returns this
 */
proto.themis.ReportRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.BasicReportResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.BasicReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.BasicReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.BasicReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.BasicReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.themis.BasicReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.BasicReportResponse}
 */
proto.themis.BasicReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.BasicReportResponse;
  return proto.themis.BasicReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.BasicReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.BasicReportResponse}
 */
proto.themis.BasicReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.BasicReport;
      reader.readMessage(value,proto.themis.BasicReport.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.BasicReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.BasicReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.BasicReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.BasicReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.themis.BasicReport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BasicReport report = 1;
 * @return {!Array<!proto.themis.BasicReport>}
 */
proto.themis.BasicReportResponse.prototype.getReportList = function() {
  return /** @type{!Array<!proto.themis.BasicReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.BasicReport, 1));
};


/**
 * @param {!Array<!proto.themis.BasicReport>} value
 * @return {!proto.themis.BasicReportResponse} returns this
*/
proto.themis.BasicReportResponse.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.BasicReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.BasicReport}
 */
proto.themis.BasicReportResponse.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.BasicReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.BasicReportResponse} returns this
 */
proto.themis.BasicReportResponse.prototype.clearReportList = function() {
  return this.setReportList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.BasicReport.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.BasicReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.BasicReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.BasicReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventLive: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    paidTeams: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unpaidTeams: jspb.Message.getFieldWithDefault(msg, 5, 0),
    partlyPaidTeams: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paidAthletes: jspb.Message.getFieldWithDefault(msg, 7, 0),
    unpaidAthletes: jspb.Message.getFieldWithDefault(msg, 8, 0),
    paid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    unpaid: jspb.Message.getFieldWithDefault(msg, 10, 0),
    partlyPaidAthletes: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.BasicReport}
 */
proto.themis.BasicReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.BasicReport;
  return proto.themis.BasicReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.BasicReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.BasicReport}
 */
proto.themis.BasicReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEventLive(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaidTeams(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUnpaidTeams(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPartlyPaidTeams(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaidAthletes(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUnpaidAthletes(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUnpaid(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPartlyPaidAthletes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.BasicReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.BasicReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.BasicReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.BasicReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventLive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPaidTeams();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getUnpaidTeams();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPartlyPaidTeams();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getPaidAthletes();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getUnpaidAthletes();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getPaid();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getUnpaid();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getPartlyPaidAthletes();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.BasicReport.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.BasicReport} returns this
 */
proto.themis.BasicReport.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string event_name = 2;
 * @return {string}
 */
proto.themis.BasicReport.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.BasicReport} returns this
 */
proto.themis.BasicReport.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool event_live = 3;
 * @return {boolean}
 */
proto.themis.BasicReport.prototype.getEventLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.BasicReport} returns this
 */
proto.themis.BasicReport.prototype.setEventLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional uint32 paid_teams = 4;
 * @return {number}
 */
proto.themis.BasicReport.prototype.getPaidTeams = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.BasicReport} returns this
 */
proto.themis.BasicReport.prototype.setPaidTeams = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 unpaid_teams = 5;
 * @return {number}
 */
proto.themis.BasicReport.prototype.getUnpaidTeams = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.BasicReport} returns this
 */
proto.themis.BasicReport.prototype.setUnpaidTeams = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 partly_paid_teams = 6;
 * @return {number}
 */
proto.themis.BasicReport.prototype.getPartlyPaidTeams = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.BasicReport} returns this
 */
proto.themis.BasicReport.prototype.setPartlyPaidTeams = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 paid_athletes = 7;
 * @return {number}
 */
proto.themis.BasicReport.prototype.getPaidAthletes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.BasicReport} returns this
 */
proto.themis.BasicReport.prototype.setPaidAthletes = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 unpaid_athletes = 8;
 * @return {number}
 */
proto.themis.BasicReport.prototype.getUnpaidAthletes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.BasicReport} returns this
 */
proto.themis.BasicReport.prototype.setUnpaidAthletes = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 paid = 9;
 * @return {number}
 */
proto.themis.BasicReport.prototype.getPaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.BasicReport} returns this
 */
proto.themis.BasicReport.prototype.setPaid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 unpaid = 10;
 * @return {number}
 */
proto.themis.BasicReport.prototype.getUnpaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.BasicReport} returns this
 */
proto.themis.BasicReport.prototype.setUnpaid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 partly_paid_athletes = 11;
 * @return {number}
 */
proto.themis.BasicReport.prototype.getPartlyPaidAthletes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.BasicReport} returns this
 */
proto.themis.BasicReport.prototype.setPartlyPaidAthletes = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CreateStripeProducerAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CreateStripeProducerAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CreateStripeProducerAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CreateStripeProducerAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stripeId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CreateStripeProducerAccountResponse}
 */
proto.themis.CreateStripeProducerAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CreateStripeProducerAccountResponse;
  return proto.themis.CreateStripeProducerAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CreateStripeProducerAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CreateStripeProducerAccountResponse}
 */
proto.themis.CreateStripeProducerAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CreateStripeProducerAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CreateStripeProducerAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CreateStripeProducerAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CreateStripeProducerAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStripeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.themis.CreateStripeProducerAccountResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.CreateStripeProducerAccountResponse} returns this
 */
proto.themis.CreateStripeProducerAccountResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string stripe_id = 2;
 * @return {string}
 */
proto.themis.CreateStripeProducerAccountResponse.prototype.getStripeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.CreateStripeProducerAccountResponse} returns this
 */
proto.themis.CreateStripeProducerAccountResponse.prototype.setStripeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.GetRegistrationTransactionsByIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GetRegistrationTransactionsByIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GetRegistrationTransactionsByIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GetRegistrationTransactionsByIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetRegistrationTransactionsByIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    registrationTransactionIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GetRegistrationTransactionsByIdsRequest}
 */
proto.themis.GetRegistrationTransactionsByIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GetRegistrationTransactionsByIdsRequest;
  return proto.themis.GetRegistrationTransactionsByIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GetRegistrationTransactionsByIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GetRegistrationTransactionsByIdsRequest}
 */
proto.themis.GetRegistrationTransactionsByIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRegistrationTransactionIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GetRegistrationTransactionsByIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GetRegistrationTransactionsByIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GetRegistrationTransactionsByIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetRegistrationTransactionsByIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistrationTransactionIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
};


/**
 * repeated uint32 registration_transaction_ids = 1;
 * @return {!Array<number>}
 */
proto.themis.GetRegistrationTransactionsByIdsRequest.prototype.getRegistrationTransactionIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.themis.GetRegistrationTransactionsByIdsRequest} returns this
 */
proto.themis.GetRegistrationTransactionsByIdsRequest.prototype.setRegistrationTransactionIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.themis.GetRegistrationTransactionsByIdsRequest} returns this
 */
proto.themis.GetRegistrationTransactionsByIdsRequest.prototype.addRegistrationTransactionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GetRegistrationTransactionsByIdsRequest} returns this
 */
proto.themis.GetRegistrationTransactionsByIdsRequest.prototype.clearRegistrationTransactionIdsList = function() {
  return this.setRegistrationTransactionIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.GetRegistrationTransactionsByIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GetRegistrationTransactionsByIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GetRegistrationTransactionsByIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GetRegistrationTransactionsByIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetRegistrationTransactionsByIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    registrationTransactionsList: jspb.Message.toObjectList(msg.getRegistrationTransactionsList(),
    themis_common_pb.RegistrationTransaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GetRegistrationTransactionsByIdsResponse}
 */
proto.themis.GetRegistrationTransactionsByIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GetRegistrationTransactionsByIdsResponse;
  return proto.themis.GetRegistrationTransactionsByIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GetRegistrationTransactionsByIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GetRegistrationTransactionsByIdsResponse}
 */
proto.themis.GetRegistrationTransactionsByIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.RegistrationTransaction;
      reader.readMessage(value,themis_common_pb.RegistrationTransaction.deserializeBinaryFromReader);
      msg.addRegistrationTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GetRegistrationTransactionsByIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GetRegistrationTransactionsByIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GetRegistrationTransactionsByIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GetRegistrationTransactionsByIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistrationTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.RegistrationTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RegistrationTransaction registration_transactions = 1;
 * @return {!Array<!proto.themis.RegistrationTransaction>}
 */
proto.themis.GetRegistrationTransactionsByIdsResponse.prototype.getRegistrationTransactionsList = function() {
  return /** @type{!Array<!proto.themis.RegistrationTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.RegistrationTransaction, 1));
};


/**
 * @param {!Array<!proto.themis.RegistrationTransaction>} value
 * @return {!proto.themis.GetRegistrationTransactionsByIdsResponse} returns this
*/
proto.themis.GetRegistrationTransactionsByIdsResponse.prototype.setRegistrationTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.RegistrationTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.RegistrationTransaction}
 */
proto.themis.GetRegistrationTransactionsByIdsResponse.prototype.addRegistrationTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.RegistrationTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GetRegistrationTransactionsByIdsResponse} returns this
 */
proto.themis.GetRegistrationTransactionsByIdsResponse.prototype.clearRegistrationTransactionsList = function() {
  return this.setRegistrationTransactionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFloorDateJudgingPanelShiftId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    judgeId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest}
 */
proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest;
  return proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest}
 */
proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventFloorDateJudgingPanelShiftId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setJudgeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFloorDateJudgingPanelShiftId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getJudgeId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 event_floor_date_judging_panel_shift_id = 1;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.prototype.getEventFloorDateJudgingPanelShiftId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} returns this
 */
proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.prototype.setEventFloorDateJudgingPanelShiftId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 judge_id = 2;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.prototype.getJudgeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} returns this
 */
proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest.prototype.setJudgeId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFloorDateJudgingPanelShiftId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventFloorDateJudgingPanelShiftDivisionId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    eventDivisionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sortOrder: jspb.Message.getFieldWithDefault(msg, 3, 0),
    judgeId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest}
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest;
  return proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest}
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventFloorDateJudgingPanelShiftId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventFloorDateJudgingPanelShiftDivisionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventDivisionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSortOrder(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setJudgeId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFloorDateJudgingPanelShiftId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventFloorDateJudgingPanelShiftDivisionId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getEventDivisionId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSortOrder();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getJudgeId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional uint32 event_floor_date_judging_panel_shift_id = 1;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.getEventFloorDateJudgingPanelShiftId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} returns this
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.setEventFloorDateJudgingPanelShiftId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 event_floor_date_judging_panel_shift_division_id = 6;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.getEventFloorDateJudgingPanelShiftDivisionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} returns this
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.setEventFloorDateJudgingPanelShiftDivisionId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 event_division_id = 2;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.getEventDivisionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} returns this
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.setEventDivisionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 sort_order = 3;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.getSortOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} returns this
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 judge_id = 4;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.getJudgeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} returns this
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.setJudgeId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool status = 5;
 * @return {boolean}
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} returns this
 */
proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DocumentSignatureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DocumentSignatureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DocumentSignatureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentSignatureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    signature: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentSignatureId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventDocumentId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    signCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DocumentSignatureRequest}
 */
proto.themis.DocumentSignatureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DocumentSignatureRequest;
  return proto.themis.DocumentSignatureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DocumentSignatureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DocumentSignatureRequest}
 */
proto.themis.DocumentSignatureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDocumentSignatureId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventDocumentId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DocumentSignatureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DocumentSignatureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DocumentSignatureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentSignatureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentSignatureId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getEventDocumentId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSignCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string signature = 1;
 * @return {string}
 */
proto.themis.DocumentSignatureRequest.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DocumentSignatureRequest} returns this
 */
proto.themis.DocumentSignatureRequest.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 document_signature_id = 2;
 * @return {number}
 */
proto.themis.DocumentSignatureRequest.prototype.getDocumentSignatureId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DocumentSignatureRequest} returns this
 */
proto.themis.DocumentSignatureRequest.prototype.setDocumentSignatureId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 event_document_id = 3;
 * @return {number}
 */
proto.themis.DocumentSignatureRequest.prototype.getEventDocumentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DocumentSignatureRequest} returns this
 */
proto.themis.DocumentSignatureRequest.prototype.setEventDocumentId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sign_code = 4;
 * @return {string}
 */
proto.themis.DocumentSignatureRequest.prototype.getSignCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DocumentSignatureRequest} returns this
 */
proto.themis.DocumentSignatureRequest.prototype.setSignCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.DocumentSignatureResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DocumentSignatureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DocumentSignatureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DocumentSignatureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentSignatureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    signaturesList: jspb.Message.toObjectList(msg.getSignaturesList(),
    themis_common_pb.DocumentSignature.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DocumentSignatureResponse}
 */
proto.themis.DocumentSignatureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DocumentSignatureResponse;
  return proto.themis.DocumentSignatureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DocumentSignatureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DocumentSignatureResponse}
 */
proto.themis.DocumentSignatureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = new themis_common_pb.DocumentSignature;
      reader.readMessage(value,themis_common_pb.DocumentSignature.deserializeBinaryFromReader);
      msg.addSignatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DocumentSignatureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DocumentSignatureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DocumentSignatureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentSignatureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSignaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      themis_common_pb.DocumentSignature.serializeBinaryToWriter
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.themis.DocumentSignatureResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DocumentSignatureResponse} returns this
 */
proto.themis.DocumentSignatureResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DocumentSignature signatures = 2;
 * @return {!Array<!proto.themis.DocumentSignature>}
 */
proto.themis.DocumentSignatureResponse.prototype.getSignaturesList = function() {
  return /** @type{!Array<!proto.themis.DocumentSignature>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.DocumentSignature, 2));
};


/**
 * @param {!Array<!proto.themis.DocumentSignature>} value
 * @return {!proto.themis.DocumentSignatureResponse} returns this
*/
proto.themis.DocumentSignatureResponse.prototype.setSignaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.DocumentSignature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.DocumentSignature}
 */
proto.themis.DocumentSignatureResponse.prototype.addSignatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.DocumentSignature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.DocumentSignatureResponse} returns this
 */
proto.themis.DocumentSignatureResponse.prototype.clearSignaturesList = function() {
  return this.setSignaturesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.DocumentResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventDocument: (f = msg.getEventDocument()) && themis_common_pb.EventDocument.toObject(includeInstance, f),
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    themis_common_pb.EventDocument.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DocumentResponse}
 */
proto.themis.DocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DocumentResponse;
  return proto.themis.DocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DocumentResponse}
 */
proto.themis.DocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventDocument;
      reader.readMessage(value,themis_common_pb.EventDocument.deserializeBinaryFromReader);
      msg.setEventDocument(value);
      break;
    case 2:
      var value = new themis_common_pb.EventDocument;
      reader.readMessage(value,themis_common_pb.EventDocument.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.EventDocument.serializeBinaryToWriter
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      themis_common_pb.EventDocument.serializeBinaryToWriter
    );
  }
};


/**
 * optional EventDocument event_document = 1;
 * @return {?proto.themis.EventDocument}
 */
proto.themis.DocumentResponse.prototype.getEventDocument = function() {
  return /** @type{?proto.themis.EventDocument} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventDocument, 1));
};


/**
 * @param {?proto.themis.EventDocument|undefined} value
 * @return {!proto.themis.DocumentResponse} returns this
*/
proto.themis.DocumentResponse.prototype.setEventDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DocumentResponse} returns this
 */
proto.themis.DocumentResponse.prototype.clearEventDocument = function() {
  return this.setEventDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DocumentResponse.prototype.hasEventDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated EventDocument documents = 2;
 * @return {!Array<!proto.themis.EventDocument>}
 */
proto.themis.DocumentResponse.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.themis.EventDocument>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventDocument, 2));
};


/**
 * @param {!Array<!proto.themis.EventDocument>} value
 * @return {!proto.themis.DocumentResponse} returns this
*/
proto.themis.DocumentResponse.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.EventDocument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDocument}
 */
proto.themis.DocumentResponse.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.EventDocument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.DocumentResponse} returns this
 */
proto.themis.DocumentResponse.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDocumentDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDocumentDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDocumentDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDocumentDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageInfo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    signTs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    signature: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDocumentDetail}
 */
proto.themis.EventDocumentDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDocumentDetail;
  return proto.themis.EventDocumentDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDocumentDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDocumentDetail}
 */
proto.themis.EventDocumentDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSignTs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDocumentDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDocumentDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDocumentDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDocumentDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageInfo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSignTs();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string page_info = 1;
 * @return {string}
 */
proto.themis.EventDocumentDetail.prototype.getPageInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDocumentDetail} returns this
 */
proto.themis.EventDocumentDetail.prototype.setPageInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 sign_ts = 2;
 * @return {number}
 */
proto.themis.EventDocumentDetail.prototype.getSignTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDocumentDetail} returns this
 */
proto.themis.EventDocumentDetail.prototype.setSignTs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string ip_address = 3;
 * @return {string}
 */
proto.themis.EventDocumentDetail.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDocumentDetail} returns this
 */
proto.themis.EventDocumentDetail.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string signature = 4;
 * @return {string}
 */
proto.themis.EventDocumentDetail.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDocumentDetail} returns this
 */
proto.themis.EventDocumentDetail.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventDocumentResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pagesList: jspb.Message.toObjectList(msg.getPagesList(),
    proto.themis.EventDocumentDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDocumentResponse}
 */
proto.themis.EventDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDocumentResponse;
  return proto.themis.EventDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDocumentResponse}
 */
proto.themis.EventDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.themis.EventDocumentDetail;
      reader.readMessage(value,proto.themis.EventDocumentDetail.deserializeBinaryFromReader);
      msg.addPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.themis.EventDocumentDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventDocumentResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDocumentResponse} returns this
 */
proto.themis.EventDocumentResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.EventDocumentResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDocumentResponse} returns this
 */
proto.themis.EventDocumentResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated EventDocumentDetail pages = 3;
 * @return {!Array<!proto.themis.EventDocumentDetail>}
 */
proto.themis.EventDocumentResponse.prototype.getPagesList = function() {
  return /** @type{!Array<!proto.themis.EventDocumentDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventDocumentDetail, 3));
};


/**
 * @param {!Array<!proto.themis.EventDocumentDetail>} value
 * @return {!proto.themis.EventDocumentResponse} returns this
*/
proto.themis.EventDocumentResponse.prototype.setPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.EventDocumentDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDocumentDetail}
 */
proto.themis.EventDocumentResponse.prototype.addPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.EventDocumentDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDocumentResponse} returns this
 */
proto.themis.EventDocumentResponse.prototype.clearPagesList = function() {
  return this.setPagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CreditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CreditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CreditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CreditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    programId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventTeamId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eventDivisionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CreditRequest}
 */
proto.themis.CreditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CreditRequest;
  return proto.themis.CreditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CreditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CreditRequest}
 */
proto.themis.CreditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventTeamId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventDivisionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CreditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CreditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CreditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CreditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getEventTeamId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getEventDivisionId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 program_id = 1;
 * @return {number}
 */
proto.themis.CreditRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CreditRequest} returns this
 */
proto.themis.CreditRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 location_id = 2;
 * @return {number}
 */
proto.themis.CreditRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CreditRequest} returns this
 */
proto.themis.CreditRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 event_team_id = 3;
 * @return {number}
 */
proto.themis.CreditRequest.prototype.getEventTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CreditRequest} returns this
 */
proto.themis.CreditRequest.prototype.setEventTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 event_division_id = 4;
 * @return {number}
 */
proto.themis.CreditRequest.prototype.getEventDivisionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CreditRequest} returns this
 */
proto.themis.CreditRequest.prototype.setEventDivisionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 producer_id = 5;
 * @return {number}
 */
proto.themis.CreditRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CreditRequest} returns this
 */
proto.themis.CreditRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CreditResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CreditResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CreditResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CreditResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    credit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reservation: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CreditResponse}
 */
proto.themis.CreditResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CreditResponse;
  return proto.themis.CreditResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CreditResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CreditResponse}
 */
proto.themis.CreditResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCredit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeposit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CreditResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CreditResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CreditResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CreditResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCredit();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDeposit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getReservation();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 credit = 1;
 * @return {number}
 */
proto.themis.CreditResponse.prototype.getCredit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CreditResponse} returns this
 */
proto.themis.CreditResponse.prototype.setCredit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 deposit = 2;
 * @return {number}
 */
proto.themis.CreditResponse.prototype.getDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CreditResponse} returns this
 */
proto.themis.CreditResponse.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 reservation = 3;
 * @return {number}
 */
proto.themis.CreditResponse.prototype.getReservation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CreditResponse} returns this
 */
proto.themis.CreditResponse.prototype.setReservation = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.SignatureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.SignatureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.SignatureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SignatureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    signCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    signature: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.SignatureRequest}
 */
proto.themis.SignatureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.SignatureRequest;
  return proto.themis.SignatureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.SignatureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.SignatureRequest}
 */
proto.themis.SignatureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.SignatureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.SignatureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.SignatureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SignatureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string sign_code = 1;
 * @return {string}
 */
proto.themis.SignatureRequest.prototype.getSignCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.SignatureRequest} returns this
 */
proto.themis.SignatureRequest.prototype.setSignCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string signature = 2;
 * @return {string}
 */
proto.themis.SignatureRequest.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.SignatureRequest} returns this
 */
proto.themis.SignatureRequest.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ElectSigAgreement.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ElectSigAgreement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ElectSigAgreement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ElectSigAgreement.toObject = function(includeInstance, msg) {
  var f, obj = {
    signCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agree: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ElectSigAgreement}
 */
proto.themis.ElectSigAgreement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ElectSigAgreement;
  return proto.themis.ElectSigAgreement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ElectSigAgreement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ElectSigAgreement}
 */
proto.themis.ElectSigAgreement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignCode(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAgree(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ElectSigAgreement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ElectSigAgreement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ElectSigAgreement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ElectSigAgreement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgree();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string sign_code = 1;
 * @return {string}
 */
proto.themis.ElectSigAgreement.prototype.getSignCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ElectSigAgreement} returns this
 */
proto.themis.ElectSigAgreement.prototype.setSignCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool agree = 3;
 * @return {boolean}
 */
proto.themis.ElectSigAgreement.prototype.getAgree = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.ElectSigAgreement} returns this
 */
proto.themis.ElectSigAgreement.prototype.setAgree = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ElectSigAgreementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ElectSigAgreementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ElectSigAgreementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ElectSigAgreementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    agreed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ElectSigAgreementResponse}
 */
proto.themis.ElectSigAgreementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ElectSigAgreementResponse;
  return proto.themis.ElectSigAgreementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ElectSigAgreementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ElectSigAgreementResponse}
 */
proto.themis.ElectSigAgreementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAgreed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ElectSigAgreementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ElectSigAgreementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ElectSigAgreementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ElectSigAgreementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgreed();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool agreed = 1;
 * @return {boolean}
 */
proto.themis.ElectSigAgreementResponse.prototype.getAgreed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.ElectSigAgreementResponse} returns this
 */
proto.themis.ElectSigAgreementResponse.prototype.setAgreed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.SignatureQueryRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.SignatureQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.SignatureQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.SignatureQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SignatureQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventDocumentIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    athleteId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    guardianId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.SignatureQueryRequest}
 */
proto.themis.SignatureQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.SignatureQueryRequest;
  return proto.themis.SignatureQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.SignatureQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.SignatureQueryRequest}
 */
proto.themis.SignatureQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEventDocumentIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAthleteId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGuardianId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.SignatureQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.SignatureQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.SignatureQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SignatureQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventDocumentIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getAthleteId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getGuardianId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated int32 event_document_ids = 1;
 * @return {!Array<number>}
 */
proto.themis.SignatureQueryRequest.prototype.getEventDocumentIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.themis.SignatureQueryRequest} returns this
 */
proto.themis.SignatureQueryRequest.prototype.setEventDocumentIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.themis.SignatureQueryRequest} returns this
 */
proto.themis.SignatureQueryRequest.prototype.addEventDocumentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.SignatureQueryRequest} returns this
 */
proto.themis.SignatureQueryRequest.prototype.clearEventDocumentIdsList = function() {
  return this.setEventDocumentIdsList([]);
};


/**
 * optional int32 athlete_id = 2;
 * @return {number}
 */
proto.themis.SignatureQueryRequest.prototype.getAthleteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.SignatureQueryRequest} returns this
 */
proto.themis.SignatureQueryRequest.prototype.setAthleteId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 guardian_id = 3;
 * @return {number}
 */
proto.themis.SignatureQueryRequest.prototype.getGuardianId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.SignatureQueryRequest} returns this
 */
proto.themis.SignatureQueryRequest.prototype.setGuardianId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.SignatureQueryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.SignatureQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.SignatureQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.SignatureQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SignatureQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentSignaturesList: jspb.Message.toObjectList(msg.getDocumentSignaturesList(),
    themis_common_pb.DocumentSignature.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.SignatureQueryResponse}
 */
proto.themis.SignatureQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.SignatureQueryResponse;
  return proto.themis.SignatureQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.SignatureQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.SignatureQueryResponse}
 */
proto.themis.SignatureQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.DocumentSignature;
      reader.readMessage(value,themis_common_pb.DocumentSignature.deserializeBinaryFromReader);
      msg.addDocumentSignatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.SignatureQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.SignatureQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.SignatureQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.SignatureQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentSignaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.DocumentSignature.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DocumentSignature document_signatures = 1;
 * @return {!Array<!proto.themis.DocumentSignature>}
 */
proto.themis.SignatureQueryResponse.prototype.getDocumentSignaturesList = function() {
  return /** @type{!Array<!proto.themis.DocumentSignature>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.DocumentSignature, 1));
};


/**
 * @param {!Array<!proto.themis.DocumentSignature>} value
 * @return {!proto.themis.SignatureQueryResponse} returns this
*/
proto.themis.SignatureQueryResponse.prototype.setDocumentSignaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.DocumentSignature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.DocumentSignature}
 */
proto.themis.SignatureQueryResponse.prototype.addDocumentSignatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.DocumentSignature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.SignatureQueryResponse} returns this
 */
proto.themis.SignatureQueryResponse.prototype.clearDocumentSignaturesList = function() {
  return this.setDocumentSignaturesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.AddEventDivisionPricingBulkRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AddEventDivisionPricingBulkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AddEventDivisionPricingBulkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AddEventDivisionPricingBulkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddEventDivisionPricingBulkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventDivisionPricingsList: jspb.Message.toObjectList(msg.getEventDivisionPricingsList(),
    themis_common_pb.EventDivisionPricing.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AddEventDivisionPricingBulkRequest}
 */
proto.themis.AddEventDivisionPricingBulkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AddEventDivisionPricingBulkRequest;
  return proto.themis.AddEventDivisionPricingBulkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AddEventDivisionPricingBulkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AddEventDivisionPricingBulkRequest}
 */
proto.themis.AddEventDivisionPricingBulkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventDivisionPricing;
      reader.readMessage(value,themis_common_pb.EventDivisionPricing.deserializeBinaryFromReader);
      msg.addEventDivisionPricings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AddEventDivisionPricingBulkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AddEventDivisionPricingBulkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AddEventDivisionPricingBulkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddEventDivisionPricingBulkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventDivisionPricingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventDivisionPricing.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventDivisionPricing event_division_pricings = 1;
 * @return {!Array<!proto.themis.EventDivisionPricing>}
 */
proto.themis.AddEventDivisionPricingBulkRequest.prototype.getEventDivisionPricingsList = function() {
  return /** @type{!Array<!proto.themis.EventDivisionPricing>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventDivisionPricing, 1));
};


/**
 * @param {!Array<!proto.themis.EventDivisionPricing>} value
 * @return {!proto.themis.AddEventDivisionPricingBulkRequest} returns this
*/
proto.themis.AddEventDivisionPricingBulkRequest.prototype.setEventDivisionPricingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventDivisionPricing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivisionPricing}
 */
proto.themis.AddEventDivisionPricingBulkRequest.prototype.addEventDivisionPricings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventDivisionPricing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.AddEventDivisionPricingBulkRequest} returns this
 */
proto.themis.AddEventDivisionPricingBulkRequest.prototype.clearEventDivisionPricingsList = function() {
  return this.setEventDivisionPricingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RegistrationActivityLogsByProducerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RegistrationActivityLogsByProducerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RegistrationActivityLogsByProducerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationActivityLogsByProducerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    producerId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RegistrationActivityLogsByProducerRequest}
 */
proto.themis.RegistrationActivityLogsByProducerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RegistrationActivityLogsByProducerRequest;
  return proto.themis.RegistrationActivityLogsByProducerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RegistrationActivityLogsByProducerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RegistrationActivityLogsByProducerRequest}
 */
proto.themis.RegistrationActivityLogsByProducerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RegistrationActivityLogsByProducerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RegistrationActivityLogsByProducerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RegistrationActivityLogsByProducerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationActivityLogsByProducerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 producer_id = 1;
 * @return {number}
 */
proto.themis.RegistrationActivityLogsByProducerRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationActivityLogsByProducerRequest} returns this
 */
proto.themis.RegistrationActivityLogsByProducerRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RegistrationActivityLogsByEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RegistrationActivityLogsByEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RegistrationActivityLogsByEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationActivityLogsByEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RegistrationActivityLogsByEventRequest}
 */
proto.themis.RegistrationActivityLogsByEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RegistrationActivityLogsByEventRequest;
  return proto.themis.RegistrationActivityLogsByEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RegistrationActivityLogsByEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RegistrationActivityLogsByEventRequest}
 */
proto.themis.RegistrationActivityLogsByEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RegistrationActivityLogsByEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RegistrationActivityLogsByEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RegistrationActivityLogsByEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationActivityLogsByEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 event_id = 1;
 * @return {number}
 */
proto.themis.RegistrationActivityLogsByEventRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationActivityLogsByEventRequest} returns this
 */
proto.themis.RegistrationActivityLogsByEventRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.RegistrationActivityLogsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RegistrationActivityLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RegistrationActivityLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RegistrationActivityLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationActivityLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    logsList: jspb.Message.toObjectList(msg.getLogsList(),
    themis_common_pb.RegistrationActivityLogItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RegistrationActivityLogsResponse}
 */
proto.themis.RegistrationActivityLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RegistrationActivityLogsResponse;
  return proto.themis.RegistrationActivityLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RegistrationActivityLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RegistrationActivityLogsResponse}
 */
proto.themis.RegistrationActivityLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.RegistrationActivityLogItem;
      reader.readMessage(value,themis_common_pb.RegistrationActivityLogItem.deserializeBinaryFromReader);
      msg.addLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RegistrationActivityLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RegistrationActivityLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RegistrationActivityLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationActivityLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.RegistrationActivityLogItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RegistrationActivityLogItem logs = 1;
 * @return {!Array<!proto.themis.RegistrationActivityLogItem>}
 */
proto.themis.RegistrationActivityLogsResponse.prototype.getLogsList = function() {
  return /** @type{!Array<!proto.themis.RegistrationActivityLogItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.RegistrationActivityLogItem, 1));
};


/**
 * @param {!Array<!proto.themis.RegistrationActivityLogItem>} value
 * @return {!proto.themis.RegistrationActivityLogsResponse} returns this
*/
proto.themis.RegistrationActivityLogsResponse.prototype.setLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.RegistrationActivityLogItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.RegistrationActivityLogItem}
 */
proto.themis.RegistrationActivityLogsResponse.prototype.addLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.RegistrationActivityLogItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.RegistrationActivityLogsResponse} returns this
 */
proto.themis.RegistrationActivityLogsResponse.prototype.clearLogsList = function() {
  return this.setLogsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventTeamSignStatus.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventTeamSignStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventTeamSignStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventTeamSignStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeamSignStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    athleteStatusList: jspb.Message.toObjectList(msg.getAthleteStatusList(),
    themis_common_pb.AthleteSignStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventTeamSignStatus}
 */
proto.themis.EventTeamSignStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventTeamSignStatus;
  return proto.themis.EventTeamSignStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventTeamSignStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventTeamSignStatus}
 */
proto.themis.EventTeamSignStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.AthleteSignStatus;
      reader.readMessage(value,themis_common_pb.AthleteSignStatus.deserializeBinaryFromReader);
      msg.addAthleteStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventTeamSignStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventTeamSignStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventTeamSignStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeamSignStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAthleteStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.AthleteSignStatus.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AthleteSignStatus athlete_status = 1;
 * @return {!Array<!proto.themis.AthleteSignStatus>}
 */
proto.themis.EventTeamSignStatus.prototype.getAthleteStatusList = function() {
  return /** @type{!Array<!proto.themis.AthleteSignStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.AthleteSignStatus, 1));
};


/**
 * @param {!Array<!proto.themis.AthleteSignStatus>} value
 * @return {!proto.themis.EventTeamSignStatus} returns this
*/
proto.themis.EventTeamSignStatus.prototype.setAthleteStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.AthleteSignStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.AthleteSignStatus}
 */
proto.themis.EventTeamSignStatus.prototype.addAthleteStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.AthleteSignStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventTeamSignStatus} returns this
 */
proto.themis.EventTeamSignStatus.prototype.clearAthleteStatusList = function() {
  return this.setAthleteStatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ResendEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ResendEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ResendEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ResendEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    athleteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    teamId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ResendEmailRequest}
 */
proto.themis.ResendEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ResendEmailRequest;
  return proto.themis.ResendEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ResendEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ResendEmailRequest}
 */
proto.themis.ResendEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAthleteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ResendEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ResendEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ResendEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ResendEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAthleteId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTeamId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 athlete_id = 1;
 * @return {number}
 */
proto.themis.ResendEmailRequest.prototype.getAthleteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ResendEmailRequest} returns this
 */
proto.themis.ResendEmailRequest.prototype.setAthleteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 event_id = 2;
 * @return {number}
 */
proto.themis.ResendEmailRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ResendEmailRequest} returns this
 */
proto.themis.ResendEmailRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 program_id = 3;
 * @return {number}
 */
proto.themis.ResendEmailRequest.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ResendEmailRequest} returns this
 */
proto.themis.ResendEmailRequest.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 location_id = 4;
 * @return {number}
 */
proto.themis.ResendEmailRequest.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ResendEmailRequest} returns this
 */
proto.themis.ResendEmailRequest.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 team_id = 5;
 * @return {number}
 */
proto.themis.ResendEmailRequest.prototype.getTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ResendEmailRequest} returns this
 */
proto.themis.ResendEmailRequest.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CodeRequest}
 */
proto.themis.CodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CodeRequest;
  return proto.themis.CodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CodeRequest}
 */
proto.themis.CodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.themis.CodeRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.CodeRequest} returns this
 */
proto.themis.CodeRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.themis.CodeRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.CodeRequest} returns this
 */
proto.themis.CodeRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.TeamGenerationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.TeamGenerationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.TeamGenerationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamGenerationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    teamCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.TeamGenerationRequest}
 */
proto.themis.TeamGenerationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.TeamGenerationRequest;
  return proto.themis.TeamGenerationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.TeamGenerationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.TeamGenerationRequest}
 */
proto.themis.TeamGenerationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.TeamGenerationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.TeamGenerationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.TeamGenerationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamGenerationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTeamCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 event_id = 1;
 * @return {number}
 */
proto.themis.TeamGenerationRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.TeamGenerationRequest} returns this
 */
proto.themis.TeamGenerationRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 team_count = 2;
 * @return {number}
 */
proto.themis.TeamGenerationRequest.prototype.getTeamCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.TeamGenerationRequest} returns this
 */
proto.themis.TeamGenerationRequest.prototype.setTeamCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.FixScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.FixScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.FixScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.FixScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventDateId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.FixScheduleRequest}
 */
proto.themis.FixScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.FixScheduleRequest;
  return proto.themis.FixScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.FixScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.FixScheduleRequest}
 */
proto.themis.FixScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventDateId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.FixScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.FixScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.FixScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.FixScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventDateId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 event_id = 1;
 * @return {number}
 */
proto.themis.FixScheduleRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.FixScheduleRequest} returns this
 */
proto.themis.FixScheduleRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 event_date_id = 2;
 * @return {number}
 */
proto.themis.FixScheduleRequest.prototype.getEventDateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.FixScheduleRequest} returns this
 */
proto.themis.FixScheduleRequest.prototype.setEventDateId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 producer_id = 3;
 * @return {number}
 */
proto.themis.FixScheduleRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.FixScheduleRequest} returns this
 */
proto.themis.FixScheduleRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 brand_id = 4;
 * @return {number}
 */
proto.themis.FixScheduleRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.FixScheduleRequest} returns this
 */
proto.themis.FixScheduleRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.FixScheduleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.FixScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.FixScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.FixScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.FixScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventFloorDatesList: jspb.Message.toObjectList(msg.getEventFloorDatesList(),
    themis_common_pb.EventFloorDate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.FixScheduleResponse}
 */
proto.themis.FixScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.FixScheduleResponse;
  return proto.themis.FixScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.FixScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.FixScheduleResponse}
 */
proto.themis.FixScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventFloorDate;
      reader.readMessage(value,themis_common_pb.EventFloorDate.deserializeBinaryFromReader);
      msg.addEventFloorDates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.FixScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.FixScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.FixScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.FixScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventFloorDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventFloorDate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventFloorDate event_floor_dates = 1;
 * @return {!Array<!proto.themis.EventFloorDate>}
 */
proto.themis.FixScheduleResponse.prototype.getEventFloorDatesList = function() {
  return /** @type{!Array<!proto.themis.EventFloorDate>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventFloorDate, 1));
};


/**
 * @param {!Array<!proto.themis.EventFloorDate>} value
 * @return {!proto.themis.FixScheduleResponse} returns this
*/
proto.themis.FixScheduleResponse.prototype.setEventFloorDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventFloorDate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventFloorDate}
 */
proto.themis.FixScheduleResponse.prototype.addEventFloorDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventFloorDate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.FixScheduleResponse} returns this
 */
proto.themis.FixScheduleResponse.prototype.clearEventFloorDatesList = function() {
  return this.setEventFloorDatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.LoadMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.LoadMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.LoadMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.LoadMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scoringAuthorityId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.LoadMessage}
 */
proto.themis.LoadMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.LoadMessage;
  return proto.themis.LoadMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.LoadMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.LoadMessage}
 */
proto.themis.LoadMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScoringAuthorityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.LoadMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.LoadMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.LoadMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.LoadMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScoringAuthorityId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string file_name = 1;
 * @return {string}
 */
proto.themis.LoadMessage.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.LoadMessage} returns this
 */
proto.themis.LoadMessage.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 scoring_authority_id = 2;
 * @return {number}
 */
proto.themis.LoadMessage.prototype.getScoringAuthorityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.LoadMessage} returns this
 */
proto.themis.LoadMessage.prototype.setScoringAuthorityId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventDivisionShifts.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionShifts.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionShifts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionShifts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShifts.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventDivisionShiftsList: jspb.Message.toObjectList(msg.getEventDivisionShiftsList(),
    themis_common_pb.EventDivisionShift.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionShifts}
 */
proto.themis.EventDivisionShifts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionShifts;
  return proto.themis.EventDivisionShifts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionShifts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionShifts}
 */
proto.themis.EventDivisionShifts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventDivisionShift;
      reader.readMessage(value,themis_common_pb.EventDivisionShift.deserializeBinaryFromReader);
      msg.addEventDivisionShifts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionShifts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionShifts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionShifts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShifts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventDivisionShiftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventDivisionShift.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventDivisionShift event_division_shifts = 1;
 * @return {!Array<!proto.themis.EventDivisionShift>}
 */
proto.themis.EventDivisionShifts.prototype.getEventDivisionShiftsList = function() {
  return /** @type{!Array<!proto.themis.EventDivisionShift>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventDivisionShift, 1));
};


/**
 * @param {!Array<!proto.themis.EventDivisionShift>} value
 * @return {!proto.themis.EventDivisionShifts} returns this
*/
proto.themis.EventDivisionShifts.prototype.setEventDivisionShiftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventDivisionShift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivisionShift}
 */
proto.themis.EventDivisionShifts.prototype.addEventDivisionShifts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventDivisionShift, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivisionShifts} returns this
 */
proto.themis.EventDivisionShifts.prototype.clearEventDivisionShiftsList = function() {
  return this.setEventDivisionShiftsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventDivisionShiftTeamReturn.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionShiftTeamReturn.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionShiftTeamReturn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionShiftTeamReturn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeamReturn.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventDivisionShiftTeam: (f = msg.getEventDivisionShiftTeam()) && themis_common_pb.EventDivisionShiftTeam.toObject(includeInstance, f),
    notesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionShiftTeamReturn}
 */
proto.themis.EventDivisionShiftTeamReturn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionShiftTeamReturn;
  return proto.themis.EventDivisionShiftTeamReturn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionShiftTeamReturn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionShiftTeamReturn}
 */
proto.themis.EventDivisionShiftTeamReturn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventDivisionShiftTeam;
      reader.readMessage(value,themis_common_pb.EventDivisionShiftTeam.deserializeBinaryFromReader);
      msg.setEventDivisionShiftTeam(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionShiftTeamReturn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionShiftTeamReturn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionShiftTeamReturn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeamReturn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventDivisionShiftTeam();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      themis_common_pb.EventDivisionShiftTeam.serializeBinaryToWriter
    );
  }
  f = message.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional EventDivisionShiftTeam event_division_shift_team = 1;
 * @return {?proto.themis.EventDivisionShiftTeam}
 */
proto.themis.EventDivisionShiftTeamReturn.prototype.getEventDivisionShiftTeam = function() {
  return /** @type{?proto.themis.EventDivisionShiftTeam} */ (
    jspb.Message.getWrapperField(this, themis_common_pb.EventDivisionShiftTeam, 1));
};


/**
 * @param {?proto.themis.EventDivisionShiftTeam|undefined} value
 * @return {!proto.themis.EventDivisionShiftTeamReturn} returns this
*/
proto.themis.EventDivisionShiftTeamReturn.prototype.setEventDivisionShiftTeam = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShiftTeamReturn} returns this
 */
proto.themis.EventDivisionShiftTeamReturn.prototype.clearEventDivisionShiftTeam = function() {
  return this.setEventDivisionShiftTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeamReturn.prototype.hasEventDivisionShiftTeam = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string notes = 2;
 * @return {!Array<string>}
 */
proto.themis.EventDivisionShiftTeamReturn.prototype.getNotesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.themis.EventDivisionShiftTeamReturn} returns this
 */
proto.themis.EventDivisionShiftTeamReturn.prototype.setNotesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivisionShiftTeamReturn} returns this
 */
proto.themis.EventDivisionShiftTeamReturn.prototype.addNotes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivisionShiftTeamReturn} returns this
 */
proto.themis.EventDivisionShiftTeamReturn.prototype.clearNotesList = function() {
  return this.setNotesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventDivisionShiftTeamsReturn.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionShiftTeamsReturn.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionShiftTeamsReturn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionShiftTeamsReturn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeamsReturn.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventDivisionShiftTeamList: jspb.Message.toObjectList(msg.getEventDivisionShiftTeamList(),
    themis_common_pb.EventDivisionShiftTeam.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionShiftTeamsReturn}
 */
proto.themis.EventDivisionShiftTeamsReturn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionShiftTeamsReturn;
  return proto.themis.EventDivisionShiftTeamsReturn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionShiftTeamsReturn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionShiftTeamsReturn}
 */
proto.themis.EventDivisionShiftTeamsReturn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new themis_common_pb.EventDivisionShiftTeam;
      reader.readMessage(value,themis_common_pb.EventDivisionShiftTeam.deserializeBinaryFromReader);
      msg.addEventDivisionShiftTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionShiftTeamsReturn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionShiftTeamsReturn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionShiftTeamsReturn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeamsReturn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventDivisionShiftTeamList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      themis_common_pb.EventDivisionShiftTeam.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventDivisionShiftTeam event_division_shift_team = 1;
 * @return {!Array<!proto.themis.EventDivisionShiftTeam>}
 */
proto.themis.EventDivisionShiftTeamsReturn.prototype.getEventDivisionShiftTeamList = function() {
  return /** @type{!Array<!proto.themis.EventDivisionShiftTeam>} */ (
    jspb.Message.getRepeatedWrapperField(this, themis_common_pb.EventDivisionShiftTeam, 1));
};


/**
 * @param {!Array<!proto.themis.EventDivisionShiftTeam>} value
 * @return {!proto.themis.EventDivisionShiftTeamsReturn} returns this
*/
proto.themis.EventDivisionShiftTeamsReturn.prototype.setEventDivisionShiftTeamList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivisionShiftTeam}
 */
proto.themis.EventDivisionShiftTeamsReturn.prototype.addEventDivisionShiftTeam = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventDivisionShiftTeam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivisionShiftTeamsReturn} returns this
 */
proto.themis.EventDivisionShiftTeamsReturn.prototype.clearEventDivisionShiftTeamList = function() {
  return this.setEventDivisionShiftTeamList([]);
};


/**
 * @enum {number}
 */
proto.themis.ScrapeRequestType = {
  ACCOUNT: 0,
  TEAM: 1,
  COACH: 2,
  ATHLETE: 3,
  PERSONNEL: 4
};

goog.object.extend(exports, proto.themis);
