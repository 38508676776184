import React, { ReactElement, useEffect, useState, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams, useLocation } from "react-router-dom"
import {
  addDivisionsToEvent,
  addDivisionToEvent,
  deleteEvent, doSetEvent,
  getEventDivisions, getEventDivisionUses, getEvents,
  getRubricsByProducerId,
  getSeasons,
  removeDivisionFromEvent, removeDivisionsFromEvent,
  setEventRegistrationLive, updateEventDivision
} from "store/producer/eventActions"
import { IAppState } from "store/store"
import {
  selectCurrentBrand,
  selectCurrentBrands,
  selectCurrentEvent, selectCurrentEvents,
  selectCurrentProducer
} from "store/producer/producerSelectors"
import { selectCurrentPermissionLists, selectPermissionCodes, selectSuperUser, selectUserProducerPermissions } from "store/user/userSelectors"
import EventPage from './Event'
import themis_common from "store/themis_common_pb"
import { emptyDivision } from "store/producer/producerReducer"
import { format } from "date-fns"
import { findEventRegistrationCodeForEvent, getCalculatedDisplayPricing, updateEventTeamDivision } from 'store/program/eventTeamActions'
import { teamDivisionError, validateTeamOnDivision } from 'lib/validators'

interface DiscountCodeWithTeamPrice extends themis_common.EventRegistrationCode.AsObject {
  teamPrice: number
}

const emptyEventTeamDivision: themis_common.EventDivision.AsObject = {
  id: 0,
  division: undefined,
  divisionSplit: undefined,
  event: undefined,
  nonCrossover: false,
  eventTeamsList: [],
  paid: 0,
  remainingAmount: 0,
  remainingDeposit: 0,
  divisionsList: [],
  sortOrder: 0,
  rubricId: 0,
  name: ""
}

const EventContainer: React.FC = (): ReactElement => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const {
    brandId: inBrandId,
    eventId: inEventId
  } = useParams<{ brandId?: string | undefined, eventId?: string | undefined }>()
  const brandId = Number(inBrandId)
  const eventId = Number(inEventId)

  const [currentTab, setCurrentTab] = useState(7)
  const [isEventOpen, setIsEventOpen] = useState(false)
  const [eventSeasons, setEventSeasons] = useState<themis_common.Season.AsObject[]>([])
  const [currentDivisions, setCurrentDivisions] = useState<themis_common.Division.AsObject[]>([])
  const [refreshDivisions, setRefreshDivisions] = useState(0)
  const [eventDivisions, setEventDivisions] = useState<themis_common.EventDivision.AsObject[]>([])
  const [eventDivisionUses, setEventDivisionUses] = useState<themis_common.EventDivisionUsage.AsObject[]>([])

  const [currentTeam, setCurrentTeam] = useState<themis_common.EventTeam.AsObject>()
  const [selectedEventTeamRegCode, setSelectedEventTeamRegCode] = useState<string>("")
  const [selectedEventTeamDivision, setSelectedEventTeamDivision] = useState<themis_common.EventDivision.AsObject>(emptyEventTeamDivision)
  const [foundRegistrationDiscountCode, setFoundRegistrationDiscountCode] = useState<themis_common.EventRegistrationCode.AsObject>(new themis_common.EventRegistrationCode().toObject())
  const [changeDivisionError, setChangeDivisionError] = useState<boolean>(false)
  const [changeDivisionDialogOpen, setChangeDivisionDialogOpen] = useState<boolean>(false)

  const producer = useSelector((state: IAppState) => selectCurrentProducer(state))
  const brand = useSelector((state: IAppState) => selectCurrentBrand(state))
  const brands = useSelector((state: IAppState) => selectCurrentBrands(state))
  const event = useSelector((state: IAppState) => selectCurrentEvent(state))
  const events = useSelector((state: IAppState) => selectCurrentEvents(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const producerPermissions = useSelector((state: IAppState) => selectUserProducerPermissions(state))
  const permissionLists = useSelector((state: IAppState) => selectCurrentPermissionLists(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const hashValues = useMemo(() => ['registrations', 'dates', 'floors', 'add_divisions', 'divisions', 'codes', 'documents', 'users', 'feed', 'report', 'schedule'], [])

  const unusedDivisions = useMemo<themis_common.Division.AsObject[]>(() => {
    const unused: themis_common.Division.AsObject[] = []
    eventSeasons.forEach((season) => {
      const seasonUnused = season.divisionsList.filter(division => !currentDivisions.find(curDiv => curDiv.id === division.id))
      const modifiedDivisions = seasonUnused.map((division) => {
        return { ...division, season: { ...season, divisionsList: [] } }
      })
      unused.push(...modifiedDivisions)
    })
    return unused
  }, [eventSeasons, currentDivisions])

  const refreshEvent = useCallback((eventId: number) => {
    const doRefreshEvent = async () => {
      if (producer?.id && producer.id > 0 && brandId > 0) {
        await getEvents(dispatch, brandId, producer.id) // This should be refactored or called less. It's too heavy
        await doSetEvent(dispatch, eventId)
      }
    }
    doRefreshEvent()
  }, [brandId, dispatch, producer?.id])

  const setRegistrationLive = useCallback((eventId: number, isLive: boolean) => {
    const doRefreshEvent = async () => {
      if (producer) {
        await setEventRegistrationLive(eventId, brandId, producer.id, isLive, dispatch)
        await doSetEvent(dispatch, eventId)
      }
    }
    doRefreshEvent()

  }, [brandId, dispatch, producer])


  useEffect(() => {
    const doGetEventDivisions = async () => {
      refreshEvent(eventId)
      if (producer?.id && eventId && brandId) {
        const foundEventDivisions = await getEventDivisions(eventId, brandId, producer.id)
        setEventDivisions(foundEventDivisions)
        const divisions: themis_common.Division.AsObject[] = foundEventDivisions.map(ed => ed.division || emptyDivision)
        setCurrentDivisions(divisions)

        const foundEventDivUses = await getEventDivisionUses(eventId, brandId, producer.id)
        setEventDivisionUses(foundEventDivUses)
      }
    }
    doGetEventDivisions()
  }, [brandId, eventId, producer?.id, refreshDivisions, refreshEvent])

  useEffect(() => {
    const doGetSeasons = async () => {
      const eventDate = event?.eventDatesList?.length ? event.eventDatesList[0].startDay.split("|")[0] : format(new Date(), 'yyyy-MM-dd')
      const seasonsResponse = await getSeasons(eventDate)

      const makeEventSeasons = seasonsResponse.filter((season) => event?.seasonsList.find((eventSeason) => eventSeason.id === season.id))
      if (makeEventSeasons.length > 0) setEventSeasons(makeEventSeasons)
    }
    if (event?.eventDatesList && event?.seasonsList) {
      doGetSeasons()
    }
  }, [event?.eventDatesList, event?.seasonsList])

  const debounceRefreshDivision = useCallback(() => {
    setRefreshDivisions((currentRefreshDivisions) => currentRefreshDivisions + 1)
  }, [])

  const addDivision = useCallback((divisionId: number) => {
    const doAddDivision = async () => {
      if (event?.id && producer?.id) {
        await addDivisionToEvent(divisionId, event.id, brandId, producer.id)
        debounceRefreshDivision()
      }
    }
    doAddDivision()
  }, [debounceRefreshDivision, event?.id, brandId, producer?.id])

  const editEventDivision = useCallback((eventDivisionId: number, nonCrossover: boolean) => {
    const doEditDivision = async () => {
      if (event?.id && producer?.id) {
        await updateEventDivision(eventDivisionId, event.id, brandId, producer.id, nonCrossover)
        debounceRefreshDivision()
      }
    }
    doEditDivision()
  }, [debounceRefreshDivision, event?.id, brandId, producer?.id])

  const addAllDivisions = useCallback((divisions: themis_common.Division.AsObject[]) => {
    const doAddDivisions = async () => {
      if (event?.id && producer?.id) {
        await addDivisionsToEvent(divisions, event.id, brandId, producer.id)
        debounceRefreshDivision()
      }
    }
    doAddDivisions()
  }, [debounceRefreshDivision, event?.id, brandId, producer?.id])

  const removeAllDivisions = useCallback((divisions: themis_common.Division.AsObject[]) => {
    const doRemoveDivisions = async () => {
      if (event?.id && producer?.id) {
        await removeDivisionsFromEvent(divisions, event.id, brandId, producer.id)
        debounceRefreshDivision()
      }
    }
    doRemoveDivisions()
  }, [debounceRefreshDivision, event?.id, brandId, producer?.id])

  const removeDivision = useCallback((divisionId: number) => {
    const doRemoveDivision = async () => {
      if (event?.id && producer?.id) {
        const eventDivision = eventDivisions.find((ed) => ed.event?.id === event?.id && ed.division?.id === divisionId)
        if (eventDivision?.id) {
          await removeDivisionFromEvent(eventDivision.id, divisionId, event.id, brandId, producer.id)
          debounceRefreshDivision()
        }
      }
    }
    doRemoveDivision()
  }, [event?.id, producer?.id, eventDivisions, brandId, debounceRefreshDivision])

  useEffect(() => {
    const hash = location.hash.toLowerCase().substr(1)
    if (hash === '') {
      history.replace('#' + hashValues[8])
    }
    const tab = hashValues.indexOf(location.hash.toLowerCase().substr(1))
    if (tab >= 0) setCurrentTab(tab)
  }, [location, hashValues, history])

  const handleChangeTab = (event: React.ChangeEvent<any> | null, newValue: number) => {
    if (hashValues[newValue]) history.push('#' + hashValues[newValue])
    setCurrentTab(newValue)
  }

  const handleDelete = async () => {
    await deleteEvent(dispatch, event?.id || 0, brand?.id || 0, producer?.id || 0)
    setIsEventOpen(false)
    history.push(`/Brand/${brandId}`)
  }

  const [rubrics, setRubrics] = useState<themis_common.Rubric.AsObject[]>()

  const getRubrics = useCallback(async() => {
    if (producer?.id) {
      const rubricsResponse = await getRubricsByProducerId(producer.id)
      setRubrics(rubricsResponse)
    }
  }, [producer?.id])

  useEffect(() => {
    getRubrics()
  }, [getRubrics])

  useEffect(() => {
    if (event) {
      if (selectedEventTeamRegCode.length > 0) {
        const findRegCode = async () => {
          const foundRegCode = await findEventRegistrationCodeForEvent(selectedEventTeamRegCode, event.id)
          // Check if same pricing type as discount
          let pricingType = "athlete"
          if (selectedEventTeamDivision.eventDivisionPricing && selectedEventTeamDivision.eventDivisionPricing.teamPriceInt > 0) {
            pricingType = "team"
          }
          if (foundRegCode.discountType === 0) { // Percent Type
            setFoundRegistrationDiscountCode(foundRegCode)
          } else if (foundRegCode.discountType === 1 && foundRegCode.discountPerType === pricingType) { // Dollar type and If discount type matches pricing type
            setFoundRegistrationDiscountCode(foundRegCode)
          }
        }
        findRegCode()
      } else {
        setFoundRegistrationDiscountCode(new themis_common.EventRegistrationCode().toObject())
      }
    }
  }, [selectedEventTeamDivision.eventDivisionPricing, selectedEventTeamRegCode, event])

  useEffect(() => {
    if (!event || selectedEventTeamDivision.id < 1 || !currentTeam?.programId || !currentTeam.locationId || !currentTeam.teamId || currentTeam.athletesList.length === 0) return
    if (event?.registrationCodesList.length) {
      const validEventRegCodes: themis_common.EventRegistrationCode.AsObject[] = []
      event.registrationCodesList.forEach((code) => {
        if (code.beginsOnClockTime?.unixTime && code.expiresOnClockTime?.unixTime) {
          const codeBeginsUnixTime = Math.floor(code.beginsOnClockTime.unixTime / 1000) // in seconds
          const codeExpiresUnixTime = Math.floor(code.expiresOnClockTime.unixTime / 1000)
          const unixTimeNow = Math.floor(Date.now() / 1000) // in seconds

          // Gather all codes that are marked autofill, not expired, status true, and match selected division
          if (code.pb_default && code.status && codeBeginsUnixTime < unixTimeNow && codeExpiresUnixTime > unixTimeNow) {
            // Check if discount code is valid for the division
            if (code.discountAppliesTo === "All") {
              validEventRegCodes.push(code)
            } else {
              code.eventDivisionsList.forEach((ed) => {
                if (ed.division?.id === selectedEventTeamDivision.division?.id) {
                  validEventRegCodes.push(code)
                }
              })
            }
          }
        }
      })

      // Generate array of valid registration codes with appended team prices
      if (validEventRegCodes.length > 0) {
        // Get team price for all valid discount codes
        const getCalculatedPricesPromises = validEventRegCodes.map((code): Promise<DiscountCodeWithTeamPrice> => {
          const getPricingBE = async (): Promise<DiscountCodeWithTeamPrice> => {
            const eventTeamDisplayPricing = await getCalculatedDisplayPricing(dispatch, currentTeam.programId, currentTeam.locationId, currentTeam.teamId, event.id, event, selectedEventTeamDivision, currentTeam.athletesList, code.id)
            const codeWithTeamPrice: DiscountCodeWithTeamPrice = {
              ...code,
              teamPrice: eventTeamDisplayPricing.teamPrice
            }
            return codeWithTeamPrice
          }
          return getPricingBE()
        })
        // Wait for all the backend calls to finish
        Promise.all(getCalculatedPricesPromises).then((codesWithTeamPrice) => {
          // Find code that gives lowest team price and set it
          const maxDiscountCode = codesWithTeamPrice.reduce((min, code) => min.teamPrice < code.teamPrice ? min : code)
          setSelectedEventTeamRegCode(maxDiscountCode.code)
        })
      } else { // Else if no valid event reg codes, clear discount code
        setSelectedEventTeamRegCode("")
      }
    }
  }, [currentTeam, dispatch, event, selectedEventTeamDivision, selectedEventTeamDivision.id])

  // Check on change division submit if there'd be any errors with the new division
  const teamErrors = useMemo<teamDivisionError[] | undefined>(() => {
    if (currentTeam) {
      let errors: teamDivisionError[] = []
      if (selectedEventTeamDivision?.division?.divisionRestriction) {
        errors = validateTeamOnDivision(currentTeam?.athletesList, selectedEventTeamDivision?.division?.divisionRestriction)
      }
      return errors
    }
  }, [currentTeam, selectedEventTeamDivision])

  const submitChangeDivision = useCallback(() => {
    if (event) {
      const isProducerUpdate = true
      const changeEventTeamDivision = async () => {
        // Wait for unregister to finish before registering again with new division, new code, and isProducerUpdate flag
        if (currentTeam) {
          await updateEventTeamDivision(currentTeam.id, event.id, currentTeam.programId, currentTeam.locationId, currentTeam.teamId, selectedEventTeamDivision, foundRegistrationDiscountCode, currentTeam.athletesList, isProducerUpdate, currentTeam.name, currentTeam.programName)
          setChangeDivisionError(false)
          setChangeDivisionDialogOpen(false)
          setRefreshDivisions((currentRefreshDivisions) => currentRefreshDivisions + 1)
          setCurrentTeam(undefined)
        }
      }
      changeEventTeamDivision()
    }
  }, [currentTeam, event, selectedEventTeamDivision, foundRegistrationDiscountCode])

  return (permissionLists && producer && brand && event && permissionCodes?.denyAccess.length ?
    <EventPage
      superUser={superUser}
      producerPermissions={producerPermissions}
      permissionLists={permissionLists}
      brandId={brandId}
      eventId={eventId}
      producer={producer}
      brand={brand}
      brands={brands}
      event={event}
      events={events}
      currentTab={currentTab}
      eventDivisions={eventDivisions}
      eventDivisionUses={eventDivisionUses}
      setEventDivisionUses={setEventDivisionUses}
      handleChangeTab={handleChangeTab}
      handleDelete={handleDelete}
      isEventOpen={isEventOpen}
      setIsEventOpen={setIsEventOpen}
      eventSeasons={eventSeasons} // Currently unused
      currentDivisions={currentDivisions}
      unusedDivisions={unusedDivisions}
      addDivision={addDivision}
      editEventDivision={editEventDivision}
      removeDivision={removeDivision}
      addAllDivisions={addAllDivisions}
      removeAllDivisions={removeAllDivisions}
      setRefreshDivisions={setRefreshDivisions}
      refreshDivisions={refreshDivisions}
      refreshEvent={refreshEvent}
      setRegistrationLive={setRegistrationLive}
      permissionCodes={permissionCodes}
      dispatch={dispatch}
      currentTeam={currentTeam}
      setCurrentTeam={setCurrentTeam}
      submitChangeDivision={submitChangeDivision}
      setSelectedEventTeamDivision={setSelectedEventTeamDivision}
      setSelectedEventTeamRegCode={setSelectedEventTeamRegCode}
      setFoundRegistrationDiscountCode={setFoundRegistrationDiscountCode}
      setChangeDivisionDialogOpen={setChangeDivisionDialogOpen}
      foundRegistrationDiscountCode={foundRegistrationDiscountCode}
      selectedEventTeamDivision={selectedEventTeamDivision}
      changeDivisionDialogOpen={changeDivisionDialogOpen}
      selectedEventTeamRegCode={selectedEventTeamRegCode}
      changeDivisionError={changeDivisionError}
      setChangeDivisionError={setChangeDivisionError}
      teamErrors={teamErrors}
      rubrics={rubrics}
    /> : <></>
  )

}

export default EventContainer
