import { ProgramPermissions } from "store/user/userReducer"
import { ProducerPermissions } from "store/user/userReducer"
import themis_common from "store/themis_common_pb"

// -----------------------
// Program Permissions
// -----------------------

export const validateProgramPermission = (code: string, permissionCodes: themis_common.PermissionCodes.AsObject, superUser: boolean, permissions: ProgramPermissions, programId: number): boolean => {
  // Check if superUser
  if (superUser) return true
  // Check if ids are 0
  if (programId === 0) return false
  // Check if item exist
  if (!permissions[programId]) return false
  // Check if program has permissions
  return (
    permissions[programId].perms.includes(code) || permissions[programId].perms.includes(permissionCodes.programSuper)
  )
}

export const validateLocationPermission = (code: string, permissionCodes: themis_common.PermissionCodes.AsObject, superUser: boolean, permissions: ProgramPermissions, programId: number, locationId: number): boolean => {
  // Check if superUser
  if (superUser) return true
  // Check if ids are 0
  if (programId === 0 || locationId === 0) return false
  // Check if item exist
  if (!permissions[programId]?.locations[locationId]) return false
  // Check if location has permissions
  // console.log("location perms", permissions[programId].locations[locationId].perms)
  if (permissions[programId].locations[locationId].perms.length > 0) {
    return (
      permissions[programId].locations[locationId].perms.includes(code) ||
      permissions[programId].locations[locationId].perms.includes(permissionCodes.locationSuper)
    ) && !permissions[programId].locations[locationId].perms.includes(permissionCodes.denyAccess)
  }
  // Else check if program has permissions
  // console.log("checking program perms")
  return validateProgramPermission(code, permissionCodes, superUser, permissions, programId)
}

export const validateTeamPermission = (code: string, permissionCodes: themis_common.PermissionCodes.AsObject, superUser: boolean, permissions: ProgramPermissions, programId: number, locationId: number, teamId: number): boolean => {
  // Check if superUser
  if (superUser) return true
  // Check if ids are 0
  if (programId === 0 || locationId === 0 || teamId === 0) return false
  // Check if item exist
  if (!permissions[programId]?.locations[locationId]?.teams[teamId]) return false
  // Else check if team has permissions
  // console.log("team perms", permissions[programId].locations[locationId].teams[teamId])
  if (permissions[programId].locations[locationId].teams[teamId].length > 0) {
    return (
      permissions[programId].locations[locationId].teams[teamId].includes(code) ||
      permissions[programId].locations[locationId].teams[teamId].includes(permissionCodes.teamSuper)
    ) && !permissions[programId].locations[locationId].teams[teamId].includes(permissionCodes.denyAccess)
  }
  // Else check if location has permissions
  // console.log("checking team perms")
  return validateLocationPermission(code, permissionCodes, superUser, permissions, programId, locationId)
}

// -----------------------
// Producer Permissions
// -----------------------

export const validateProducerPermission = (code: string, permissionCodes: themis_common.PermissionCodes.AsObject, superUser: boolean, permissions: ProducerPermissions, producerId: number): boolean => {
  // Check if superUser
  if (superUser) return true
  // Check if ids are 0
  if (producerId === 0) return false
  // Check if item exist
  if (!permissions[producerId]) return false
  // Check if program has permissions
  return (
    permissions[producerId].perms.includes(code) || permissions[producerId].perms.includes(permissionCodes.producerSuper)
  )
}

export const validateBrandPermission = (code: string, permissionCodes: themis_common.PermissionCodes.AsObject, superUser: boolean, permissions: ProducerPermissions, producerId: number, brandId: number): boolean => {
  // Check if superUser
  if (superUser) return true
  // Check if ids are 0
  if (brandId === 0) return false
  // Check if item exist
  if (!permissions[producerId]?.brands[brandId]) return false
  // Check if location has permissions
  if (permissions[producerId].brands[brandId].perms.length > 0) {
    return (
      permissions[producerId].brands[brandId].perms.includes(code) ||
      permissions[producerId].brands[brandId].perms.includes(permissionCodes.brandSuper)
    ) && !permissions[producerId].brands[brandId].perms.includes(permissionCodes.denyAccess)
  }
  // Else check if program has permissions
  return validateProducerPermission(code, permissionCodes, superUser, permissions, producerId)
}

export const validateEventPermission = (code: string, permissionCodes: themis_common.PermissionCodes.AsObject, superUser: boolean, permissions: ProducerPermissions, producerId: number, brandId: number, eventId: number): boolean => {
  // Check if superUser
  if (superUser) return true
  // Check if ids are 0
  if (producerId === 0 || brandId === 0 || eventId === 0) return false
  // Check if item exist
  if (!permissions[producerId]?.brands[brandId]?.events[eventId]) return false
  // Else check if team has permissions
  if (permissions[producerId].brands[brandId].events[eventId].length > 0) {
    return (
      permissions[producerId].brands[brandId].events[eventId].includes(code) ||
      permissions[producerId].brands[brandId].events[eventId].includes(permissionCodes.eventSuper)
    ) && !permissions[producerId].brands[brandId].events[eventId].includes(permissionCodes.denyAccess)
  }
  // Else check if location has permissions
  return validateBrandPermission(code, permissionCodes, superUser, permissions, producerId, brandId)
}
