import { parsePhoneNumber, isValidPhoneNumber, CountryCode } from 'libphonenumber-js'
import { AsFields, FormFields } from "./constants"
import { Athlete, Division, DivisionRestriction, EventDivision } from "store/themis_common_pb"

export const validURL = (str: string): boolean => {
  // TODO:
  // "https://static.wixstatic.com/media/4973e2_246df04d587b472fa0896076f5c7759d~mv2.png/v1/fill/w_599,h_503,al_c,q_85,usm_0.66_1.00_0.01/4973e2_246df04d587b472fa0896076f5c7759d~mv2.webp"
  // This does not pass validURL even though it is valid
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i') // fragment locator
  return pattern.test(str)
}

export const isValidEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const isValidPhone = (number:string, countryCode: CountryCode): boolean => {
  if (!number) return true
  return isValidPhoneNumber(number, countryCode)
}

export const formatPhoneNumber = (number: string, countryCode: CountryCode): string => {
  const phoneNumber = parsePhoneNumber(number, countryCode)
  return phoneNumber.formatNational()
}

export const checkPhone = (object: AsFields, key: string, value: string, country: CountryCode, change: Function, errors?: {[key: string]: string}, setError?: Function): boolean => {
  if (!value || value.trim().length === 0) {
    if (setError) setError({ ...errors, [key]: "" })
    else if (errors && errors[key] === "") errors[key] = ""
    return true
  }
  if (!isValidPhone(value, country)) {
    if (setError) setError({ ...errors, [key]: "Invalid Phone Number" })
    else if (errors) errors[key] = "Invalid Phone Number"
    return false
  } else {
    if (setError) setError({ ...errors, [key]: "" })
    else if (errors) errors[key] = ""
    const formattedPhone = formatPhoneNumber(value, country)
    if (formattedPhone !== value) {
      change({ ...object, [key]: formattedPhone })
    }
  }
  return true
}

export const checkRequired = (name: string, value: string, labels: any[], formFields: {[key: string]: any}, errors?: {[key: string]: string}, setError?: Function) : boolean => {
  if (!formFields[name].required) return true
  const label = labels && labels[0] && labels[0].innerText ? labels[0].innerText : ""
  const slicedLabel = label.slice(-1) === "*" ? label.slice(0, -1) : label
  const fieldLength = value.length
  if (fieldLength === 0) {
    if (setError) {
      setError({ ...errors, [name]: `${slicedLabel}cannot be empty` })
    }
    else if (errors) errors[name] = `${slicedLabel}cannot be empty`
    return false
  } else {
    if (setError) setError({ ...errors, [name]: "" })
    else if (errors) errors[name] = ``
    return true
  }
}

// object: Program.AsObject | Producer.AsObject | Location.AsObject ...etc.
export const checkEmail = (object: {[objKey: string]: string}, key: string, value: string, errors?: {[key: string]: string}, setError?: Function): boolean => {
  if (!value || value.trim().length === 0) {
    if (setError) setError({ ...errors, [key]: "" })
    else if (errors && errors[key] === "") errors[key] = ""
    return true
  }
  if (!isValidEmail(value)) {
    if (setError) setError({ ...errors, [key]: "Invalid Email" })
    else if (errors) errors[key] = "Invalid Email"
    return false
  } else {
    if (setError) setError({ ...errors, [key]: "" })
    else if (errors) errors[key] = ""
  }
  return true
}

export const capitalizeFirstLetter = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1)

export const handleInputChange = (e: any, formFields: FormFields, object: {[key: string]: any}, changeObject: Function, errorText: {[key: string]: string}, setErrorText: Function): void => {
  const { name, value } = e.target
  if ( name === "country") {
    const newState = (value === "US") ? "AL" : ""
    changeObject({ ...object, [name]: value, state: newState })
  } else {
    changeObject({ ...object, [name]: value })
  }
  let isNotError = true
  if (formFields[name].phone) {
    isNotError = isNotError && checkPhone(object, name, value, object.country, changeObject, errorText, setErrorText)
  }

  if (formFields[name].email) {
    isNotError = isNotError && checkEmail(object, name, value, errorText, setErrorText)
  }

  if (isNotError) checkRequired(e.target.name, e.target.value, e.target.labels, formFields, errorText, setErrorText)

}

export const fieldsOkay = (fields: AsFields, formFields: FormFields): boolean => {
  const requiredFieldsOk = Object.keys(formFields).reduce((currentValue: boolean, key: string) => currentValue && (!formFields[key].required || fields[key] > ''), true)
  const phoneFieldsOk = Object.keys(formFields).reduce((currentValue: boolean, key: string) => currentValue && (!formFields[key].phone || ((fields[key] === "" && !formFields[key].required) || isValidPhone(fields[key], fields.country))), true)
  const emailFieldsOk = Object.keys(formFields).reduce((currentValue: boolean, key: string) => currentValue && (!formFields[key].email || ((fields[key] === "" && !formFields[key].required) || isValidEmail(fields[key]))), true)
  return requiredFieldsOk && phoneFieldsOk && emailFieldsOk
}

export const buildErrors = (fields: AsFields, formFields: FormFields, setUpdated: Function): {[key: string]: string} => {
  const errors:{[key: string]: string} = {}
  Object.keys(formFields).forEach((key) => {
    if (formFields[key].required && fields[key] === "") { errors[key] = `${formFields[key].label} cannot be empty` }
  })
  Object.keys(formFields).forEach((key) => {
    if (formFields[key].phone) checkPhone(fields, key, fields[key], fields.country, setUpdated, errors)
  })
  Object.keys(formFields).forEach((key) => {
    if (formFields[key].email) checkEmail(fields, key, fields[key], errors)
  })
  return errors
}

export const eventDivisionSortFunction = (a: EventDivision.AsObject, b: EventDivision.AsObject): number => {
  if (!a?.division?.season?.id || !b?.division?.season?.id) return 0
  if (a.division.season.name > b.division.season.name) return 1
  if (a.division.season.name < b.division.season.name) return -1
  if (a.division.name > b.division.name) return 1
  return -1
}

export const divisionSortFunction = (a: Division.AsObject, b: Division.AsObject): number => {
  if (!a?.season?.id || !b?.season?.id) return 0
  if (a.season.name > b.season.name) return 1
  if (a.season.name < b.season.name) return -1
  if (a.name > b.name) return 1
  return -1
}

export type teamDivisionError = {
  athleteId?: number
  issue: string
}

export const validateTeamOnDivision = (athletesList: Athlete.AsObject[], divisionRestriction: DivisionRestriction.AsObject) : teamDivisionError[] => {
  const retVal: teamDivisionError[] = []
  let athleteCount = 0
  let femaleCount = 0
  let maleCount = 0

  athletesList.forEach(athlete => {
    athleteCount += 1
    if (athlete.person?.gender === 0) maleCount += 1
    if (athlete.person?.gender === 1) femaleCount += 1
    const validAthlete = validateAthleteOnDivision(athlete, divisionRestriction)
    if (validAthlete > "" && !retVal.find(val => val.issue === validAthlete)) retVal.push({ athleteId: athlete.id, issue: validAthlete })
  })

  if (divisionRestriction.minimum > 0 && athleteCount < divisionRestriction.minimum) retVal.push({ issue: `not enough athletes: has ${athleteCount}, minimum ${divisionRestriction.minimum}` })
  if (divisionRestriction.maximum > 0 && athleteCount > divisionRestriction.maximum) retVal.push({ issue: `too many athletes: has ${athleteCount}, maximum ${divisionRestriction.maximum}` })
  if (divisionRestriction.allowMaleMax > 0 && maleCount > divisionRestriction.allowMaleMax) retVal.push({ issue: `too many males: has ${maleCount}, maximum ${divisionRestriction.allowMaleMax}` })
  if (divisionRestriction.allowMaleMin > 0 && maleCount < divisionRestriction.allowMaleMin) retVal.push({ issue: `too few males: has ${maleCount}, minimum ${divisionRestriction.allowMaleMin}` })
  if (divisionRestriction.allowFemaleMax > 0 && femaleCount > divisionRestriction.allowFemaleMax) retVal.push({ issue: `too many females: has ${femaleCount}, maximum ${divisionRestriction.allowFemaleMax}` })
  if (divisionRestriction.allowFemaleMin > 0 && femaleCount < divisionRestriction.allowFemaleMin) retVal.push({ issue: `too few females: has ${femaleCount}, minimum ${divisionRestriction.allowFemaleMin}` })

  return retVal
}

export const validateAthleteOnDivision = (athlete: Athlete.AsObject, divisionRestriction: DivisionRestriction.AsObject) : string => {
  if (!athlete.person) return "error, no athlete"
  if (divisionRestriction.allowedGender === 1 && athlete.person.gender !== 1) return "no males in all female divisions"
  if (!divisionRestriction.notValidateAge) {
    if (athlete.person.birthDate < divisionRestriction.birthStart) return "athlete too old"
    if (athlete.person.birthDate > divisionRestriction.birthEnd ) return "athlete too young"
  }
  return ""
}
