import React, { FunctionComponent, useCallback, useState } from 'react'
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon, ListItemText,
  Paper,
  Typography
} from '@material-ui/core'

import {
  ChevronRight as RightIcon,
  ChevronLeft as LeftIcon,

} from '@material-ui/icons'
import { useStyles } from "lib/theme"
import themis_common from "store/themis_common_pb"
import { divisionSortFunction } from "lib/validators"

interface eventDivisionGridProps {
  currentDivisions: themis_common.Division.AsObject[],
  unusedDivisions: themis_common.Division.AsObject[],
  addDivision: Function,
  addAllDivisions: Function,
  removeAllDivisions: Function,
  removeDivision: Function,
  eventSeasons: themis_common.Season.AsObject[], // Currently unused
  setRefreshDivisions: Function,
  refreshDivisions: number,
  userCanViewEvent: boolean,
  userCanEditEvent: boolean,
}

const EventDivisionGrid: FunctionComponent<eventDivisionGridProps> = ({ currentDivisions, unusedDivisions, addDivision, removeDivision, addAllDivisions, removeAllDivisions, eventSeasons, refreshDivisions, setRefreshDivisions, userCanEditEvent, userCanViewEvent }) => {
  const classes = useStyles()

  const [allLock, setAllLock] = useState(false)

  const buildListItems = useCallback((divisionList: themis_common.Division.AsObject[], direction: string) => {
    if (divisionList && userCanViewEvent) {
      divisionList.sort(divisionSortFunction)
      return divisionList.map(division =>
        <ListItem key={`${direction}-${division.id}`} role="listitem" button onClick={() => {
          if (userCanEditEvent && direction === 'right') addDivision(division.id); else if (userCanEditEvent) removeDivision(division.id)
        }}>
          {direction === 'left' && userCanEditEvent &&
          <ListItemIcon>
            <LeftIcon/>
          </ListItemIcon>}
          <ListItemText id={`division-${division.id}`} primary={division.name} secondary={division.season?.name}/>
          {direction === 'right' && userCanEditEvent &&
          <ListItemIcon>
            <RightIcon/>
          </ListItemIcon>}
        </ListItem>
      )
    } else return []
  }, [addDivision, removeDivision, userCanEditEvent, userCanViewEvent])

  return (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12} >
          <Typography style={{ marginRight: "25px" }} variant="h2">Divisions</Typography>
        </Grid>
        { !userCanEditEvent ? <></> :
          <Grid container item md={6} lg={5} xl={4}>
            <Paper className={classes.paper} style={{ width: "95%" }}>
              <Box p={2}><Typography variant="h3">Available Divisions</Typography></Box>
              <List dense component="div" role="list">
                <ListItem key={`AddAll`} role="listitem" button disabled={allLock} onClick={() => {
                  setAllLock(true)
                  addAllDivisions(unusedDivisions)
                  setTimeout(() => {
                    setAllLock(false)
                  }, 2000)
                }}>
                  <ListItemText id={`Add All`} primary={`Add All Divisions`}/>
                  <ListItemIcon>
                    <RightIcon/>
                  </ListItemIcon>
                </ListItem>
                {buildListItems(unusedDivisions, 'right')}
              </List>
            </Paper>
          </Grid>
        }
        <Grid container item md={6} lg={5} xl={4}>
          <Paper className={classes.paper} style={{ width: "95%" }}>
            <Box p={2}><Typography variant="h3">Selected Divisions</Typography></Box>
            <List dense component="div" role="list">
              {!userCanEditEvent ? <></> :
                <ListItem key={`RemoveAll`} role="listitem" button disabled={allLock} onClick={() => {
                  setAllLock(true)
                  removeAllDivisions(currentDivisions)
                  setTimeout(() => {
                    setAllLock(false)
                  }, 2000)
                }}>
                  <ListItemIcon>
                    <LeftIcon/>
                  </ListItemIcon>
                  <ListItemText id={`Remove All`} primary={`Remove All Divisions`}/>
                </ListItem>
              }
              { buildListItems(currentDivisions, 'left')}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Container>)
}

export default EventDivisionGrid
