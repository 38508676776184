/* eslint-disable */
// source: themis_common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.themis.AddLogoRequest', null, global);
goog.exportSymbol('proto.themis.AllStarType', null, global);
goog.exportSymbol('proto.themis.AllowedGender', null, global);
goog.exportSymbol('proto.themis.Athlete', null, global);
goog.exportSymbol('proto.themis.AthleteSignStatus', null, global);
goog.exportSymbol('proto.themis.Brand', null, global);
goog.exportSymbol('proto.themis.CalculatedAthletePricing', null, global);
goog.exportSymbol('proto.themis.ClockTime', null, global);
goog.exportSymbol('proto.themis.Coach', null, global);
goog.exportSymbol('proto.themis.Division', null, global);
goog.exportSymbol('proto.themis.DivisionLevel', null, global);
goog.exportSymbol('proto.themis.DivisionRestriction', null, global);
goog.exportSymbol('proto.themis.DivisionSplit', null, global);
goog.exportSymbol('proto.themis.DivisionSplitType', null, global);
goog.exportSymbol('proto.themis.DocumentSignature', null, global);
goog.exportSymbol('proto.themis.DocumentSignatureGuardian', null, global);
goog.exportSymbol('proto.themis.DocumentSigningSet', null, global);
goog.exportSymbol('proto.themis.Event', null, global);
goog.exportSymbol('proto.themis.EventDate', null, global);
goog.exportSymbol('proto.themis.EventDivision', null, global);
goog.exportSymbol('proto.themis.EventDivisionPricing', null, global);
goog.exportSymbol('proto.themis.EventDivisionShift', null, global);
goog.exportSymbol('proto.themis.EventDivisionShiftTeam', null, global);
goog.exportSymbol('proto.themis.EventDivisionShiftTeamDeduction', null, global);
goog.exportSymbol('proto.themis.EventDivisionShiftTeamRubricArea', null, global);
goog.exportSymbol('proto.themis.EventDivisionShiftTeamRubricAreaScore', null, global);
goog.exportSymbol('proto.themis.EventDivisionUsage', null, global);
goog.exportSymbol('proto.themis.EventDocument', null, global);
goog.exportSymbol('proto.themis.EventFloor', null, global);
goog.exportSymbol('proto.themis.EventFloorDate', null, global);
goog.exportSymbol('proto.themis.EventFloorDateJudgingPanel', null, global);
goog.exportSymbol('proto.themis.EventFloorDateJudgingPanelResponse', null, global);
goog.exportSymbol('proto.themis.EventFloorDateJudgingPanelShift', null, global);
goog.exportSymbol('proto.themis.EventFloorDateJudgingPanelShiftResponse', null, global);
goog.exportSymbol('proto.themis.EventJudgeShift', null, global);
goog.exportSymbol('proto.themis.EventJudgeShiftRequest', null, global);
goog.exportSymbol('proto.themis.EventJudgeShiftResponse', null, global);
goog.exportSymbol('proto.themis.EventPricing', null, global);
goog.exportSymbol('proto.themis.EventProgramFlat', null, global);
goog.exportSymbol('proto.themis.EventRegistrationByProgramReport', null, global);
goog.exportSymbol('proto.themis.EventRegistrationCode', null, global);
goog.exportSymbol('proto.themis.EventRegistrationReport', null, global);
goog.exportSymbol('proto.themis.EventTeam', null, global);
goog.exportSymbol('proto.themis.EventTeamBalance', null, global);
goog.exportSymbol('proto.themis.EventTeamDisplayPricing', null, global);
goog.exportSymbol('proto.themis.EventTeamFlat', null, global);
goog.exportSymbol('proto.themis.FlatCsv', null, global);
goog.exportSymbol('proto.themis.FormattedDateTime', null, global);
goog.exportSymbol('proto.themis.Gender', null, global);
goog.exportSymbol('proto.themis.GenericEnum', null, global);
goog.exportSymbol('proto.themis.GenericFieldDescriptor', null, global);
goog.exportSymbol('proto.themis.GenericKeyValue', null, global);
goog.exportSymbol('proto.themis.GenericKeyValue.ValueCase', null, global);
goog.exportSymbol('proto.themis.GenericRange', null, global);
goog.exportSymbol('proto.themis.Guardian', null, global);
goog.exportSymbol('proto.themis.GuardianAhtleteValid', null, global);
goog.exportSymbol('proto.themis.Judge', null, global);
goog.exportSymbol('proto.themis.Location', null, global);
goog.exportSymbol('proto.themis.PaidScope', null, global);
goog.exportSymbol('proto.themis.PaymentType', null, global);
goog.exportSymbol('proto.themis.Permission', null, global);
goog.exportSymbol('proto.themis.PermissionCodes', null, global);
goog.exportSymbol('proto.themis.Person', null, global);
goog.exportSymbol('proto.themis.Personnel', null, global);
goog.exportSymbol('proto.themis.Producer', null, global);
goog.exportSymbol('proto.themis.ProducerProgramCreditReport', null, global);
goog.exportSymbol('proto.themis.ProducerUrl', null, global);
goog.exportSymbol('proto.themis.ProducerUrls', null, global);
goog.exportSymbol('proto.themis.Program', null, global);
goog.exportSymbol('proto.themis.ProgramAllowedProducer', null, global);
goog.exportSymbol('proto.themis.ProgramAllowedProducers', null, global);
goog.exportSymbol('proto.themis.ProgramCredit', null, global);
goog.exportSymbol('proto.themis.RegistrationActivityLogItem', null, global);
goog.exportSymbol('proto.themis.RegistrationTransaction', null, global);
goog.exportSymbol('proto.themis.RegistrationTransactionPayment', null, global);
goog.exportSymbol('proto.themis.RegistrationTransactionScope', null, global);
goog.exportSymbol('proto.themis.RowSnapshot', null, global);
goog.exportSymbol('proto.themis.Rubric', null, global);
goog.exportSymbol('proto.themis.RubricArea', null, global);
goog.exportSymbol('proto.themis.RubricAreaItem', null, global);
goog.exportSymbol('proto.themis.RubricDeduction', null, global);
goog.exportSymbol('proto.themis.RubricMultiJudgeStrategy', null, global);
goog.exportSymbol('proto.themis.Rubrics', null, global);
goog.exportSymbol('proto.themis.ScoringAuthority', null, global);
goog.exportSymbol('proto.themis.Season', null, global);
goog.exportSymbol('proto.themis.StripeBankAccount', null, global);
goog.exportSymbol('proto.themis.StripeCreditCard', null, global);
goog.exportSymbol('proto.themis.StripeSources', null, global);
goog.exportSymbol('proto.themis.TableSnapshot', null, global);
goog.exportSymbol('proto.themis.Team', null, global);
goog.exportSymbol('proto.themis.TeamScoringAuthority', null, global);
goog.exportSymbol('proto.themis.Tier', null, global);
goog.exportSymbol('proto.themis.UnitType', null, global);
goog.exportSymbol('proto.themis.UploadCsvRequest', null, global);
goog.exportSymbol('proto.themis.User', null, global);
goog.exportSymbol('proto.themis.UserPermissions', null, global);
goog.exportSymbol('proto.themis.ValueType', null, global);
goog.exportSymbol('proto.themis.field_type', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Producer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Producer.repeatedFields_, null);
};
goog.inherits(proto.themis.Producer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Producer.displayName = 'proto.themis.Producer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProducerUrl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ProducerUrl.repeatedFields_, null);
};
goog.inherits(proto.themis.ProducerUrl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProducerUrl.displayName = 'proto.themis.ProducerUrl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProducerUrls = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ProducerUrls.repeatedFields_, null);
};
goog.inherits(proto.themis.ProducerUrls, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProducerUrls.displayName = 'proto.themis.ProducerUrls';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProgramAllowedProducer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ProgramAllowedProducer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProgramAllowedProducer.displayName = 'proto.themis.ProgramAllowedProducer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProgramAllowedProducers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ProgramAllowedProducers.repeatedFields_, null);
};
goog.inherits(proto.themis.ProgramAllowedProducers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProgramAllowedProducers.displayName = 'proto.themis.ProgramAllowedProducers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Brand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Brand.repeatedFields_, null);
};
goog.inherits(proto.themis.Brand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Brand.displayName = 'proto.themis.Brand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Event.repeatedFields_, null);
};
goog.inherits(proto.themis.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Event.displayName = 'proto.themis.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventPricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventPricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventPricing.displayName = 'proto.themis.EventPricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionPricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventDivisionPricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionPricing.displayName = 'proto.themis.EventDivisionPricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.CalculatedAthletePricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.CalculatedAthletePricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.CalculatedAthletePricing.displayName = 'proto.themis.CalculatedAthletePricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventTeamDisplayPricing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventTeamDisplayPricing.repeatedFields_, null);
};
goog.inherits(proto.themis.EventTeamDisplayPricing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventTeamDisplayPricing.displayName = 'proto.themis.EventTeamDisplayPricing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RegistrationTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.RegistrationTransaction.repeatedFields_, null);
};
goog.inherits(proto.themis.RegistrationTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RegistrationTransaction.displayName = 'proto.themis.RegistrationTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RegistrationTransactionPayment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.RegistrationTransactionPayment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RegistrationTransactionPayment.displayName = 'proto.themis.RegistrationTransactionPayment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDate.displayName = 'proto.themis.EventDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.User.displayName = 'proto.themis.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Person = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.Person, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Person.displayName = 'proto.themis.Person';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Athlete = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Athlete.repeatedFields_, null);
};
goog.inherits(proto.themis.Athlete, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Athlete.displayName = 'proto.themis.Athlete';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Coach = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Coach.repeatedFields_, null);
};
goog.inherits(proto.themis.Coach, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Coach.displayName = 'proto.themis.Coach';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Guardian = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.Guardian, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Guardian.displayName = 'proto.themis.Guardian';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Personnel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Personnel.repeatedFields_, null);
};
goog.inherits(proto.themis.Personnel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Personnel.displayName = 'proto.themis.Personnel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AllStarType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AllStarType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AllStarType.displayName = 'proto.themis.AllStarType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Program = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Program.repeatedFields_, null);
};
goog.inherits(proto.themis.Program, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Program.displayName = 'proto.themis.Program';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Location.repeatedFields_, null);
};
goog.inherits(proto.themis.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Location.displayName = 'proto.themis.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Team = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Team.repeatedFields_, null);
};
goog.inherits(proto.themis.Team, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Team.displayName = 'proto.themis.Team';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventTeam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventTeam.repeatedFields_, null);
};
goog.inherits(proto.themis.EventTeam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventTeam.displayName = 'proto.themis.EventTeam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventTeamBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventTeamBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventTeamBalance.displayName = 'proto.themis.EventTeamBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.TeamScoringAuthority = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.TeamScoringAuthority, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.TeamScoringAuthority.displayName = 'proto.themis.TeamScoringAuthority';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Division = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Division.repeatedFields_, null);
};
goog.inherits(proto.themis.Division, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Division.displayName = 'proto.themis.Division';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DivisionRestriction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.DivisionRestriction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DivisionRestriction.displayName = 'proto.themis.DivisionRestriction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventFloorDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventFloorDate.repeatedFields_, null);
};
goog.inherits(proto.themis.EventFloorDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventFloorDate.displayName = 'proto.themis.EventFloorDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventFloor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventFloor.repeatedFields_, null);
};
goog.inherits(proto.themis.EventFloor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventFloor.displayName = 'proto.themis.EventFloor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventFloorDateJudgingPanel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventFloorDateJudgingPanel.repeatedFields_, null);
};
goog.inherits(proto.themis.EventFloorDateJudgingPanel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventFloorDateJudgingPanel.displayName = 'proto.themis.EventFloorDateJudgingPanel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventFloorDateJudgingPanelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventFloorDateJudgingPanelResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventFloorDateJudgingPanelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventFloorDateJudgingPanelResponse.displayName = 'proto.themis.EventFloorDateJudgingPanelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventFloorDateJudgingPanelShiftResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventFloorDateJudgingPanelShiftResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventFloorDateJudgingPanelShiftResponse.displayName = 'proto.themis.EventFloorDateJudgingPanelShiftResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventFloorDateJudgingPanelShift = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventFloorDateJudgingPanelShift.repeatedFields_, null);
};
goog.inherits(proto.themis.EventFloorDateJudgingPanelShift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventFloorDateJudgingPanelShift.displayName = 'proto.themis.EventFloorDateJudgingPanelShift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventRegistrationCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventRegistrationCode.repeatedFields_, null);
};
goog.inherits(proto.themis.EventRegistrationCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventRegistrationCode.displayName = 'proto.themis.EventRegistrationCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Tier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.Tier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Tier.displayName = 'proto.themis.Tier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DivisionSplit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.DivisionSplit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DivisionSplit.displayName = 'proto.themis.DivisionSplit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivision = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventDivision.repeatedFields_, null);
};
goog.inherits(proto.themis.EventDivision, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivision.displayName = 'proto.themis.EventDivision';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionUsage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventDivisionUsage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionUsage.displayName = 'proto.themis.EventDivisionUsage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ScoringAuthority = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ScoringAuthority.repeatedFields_, null);
};
goog.inherits(proto.themis.ScoringAuthority, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ScoringAuthority.displayName = 'proto.themis.ScoringAuthority';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DivisionLevel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.DivisionLevel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DivisionLevel.displayName = 'proto.themis.DivisionLevel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Season = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Season.repeatedFields_, null);
};
goog.inherits(proto.themis.Season, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Season.displayName = 'proto.themis.Season';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Permission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.Permission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Permission.displayName = 'proto.themis.Permission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.UserPermissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.UserPermissions.repeatedFields_, null);
};
goog.inherits(proto.themis.UserPermissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.UserPermissions.displayName = 'proto.themis.UserPermissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.PermissionCodes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.PermissionCodes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.PermissionCodes.displayName = 'proto.themis.PermissionCodes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Judge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Judge.repeatedFields_, null);
};
goog.inherits(proto.themis.Judge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Judge.displayName = 'proto.themis.Judge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.StripeBankAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.StripeBankAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.StripeBankAccount.displayName = 'proto.themis.StripeBankAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.StripeCreditCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.StripeCreditCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.StripeCreditCard.displayName = 'proto.themis.StripeCreditCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.StripeSources = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.StripeSources.repeatedFields_, null);
};
goog.inherits(proto.themis.StripeSources, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.StripeSources.displayName = 'proto.themis.StripeSources';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ClockTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ClockTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ClockTime.displayName = 'proto.themis.ClockTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.FormattedDateTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.FormattedDateTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.FormattedDateTime.displayName = 'proto.themis.FormattedDateTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AddLogoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AddLogoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AddLogoRequest.displayName = 'proto.themis.AddLogoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DocumentSignature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.DocumentSignature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DocumentSignature.displayName = 'proto.themis.DocumentSignature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDocument.displayName = 'proto.themis.EventDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DocumentSigningSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.DocumentSigningSet.repeatedFields_, null);
};
goog.inherits(proto.themis.DocumentSigningSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DocumentSigningSet.displayName = 'proto.themis.DocumentSigningSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.DocumentSignatureGuardian = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.DocumentSignatureGuardian, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.DocumentSignatureGuardian.displayName = 'proto.themis.DocumentSignatureGuardian';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.UploadCsvRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.UploadCsvRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.UploadCsvRequest.displayName = 'proto.themis.UploadCsvRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GenericKeyValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.themis.GenericKeyValue.oneofGroups_);
};
goog.inherits(proto.themis.GenericKeyValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GenericKeyValue.displayName = 'proto.themis.GenericKeyValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GenericRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.GenericRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GenericRange.displayName = 'proto.themis.GenericRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GenericEnum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.GenericEnum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GenericEnum.displayName = 'proto.themis.GenericEnum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GenericFieldDescriptor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.GenericFieldDescriptor.repeatedFields_, null);
};
goog.inherits(proto.themis.GenericFieldDescriptor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GenericFieldDescriptor.displayName = 'proto.themis.GenericFieldDescriptor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.TableSnapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.TableSnapshot.repeatedFields_, null);
};
goog.inherits(proto.themis.TableSnapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.TableSnapshot.displayName = 'proto.themis.TableSnapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RowSnapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.RowSnapshot.repeatedFields_, null);
};
goog.inherits(proto.themis.RowSnapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RowSnapshot.displayName = 'proto.themis.RowSnapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.FlatCsv = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.FlatCsv, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.FlatCsv.displayName = 'proto.themis.FlatCsv';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.GuardianAhtleteValid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.GuardianAhtleteValid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.GuardianAhtleteValid.displayName = 'proto.themis.GuardianAhtleteValid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RegistrationActivityLogItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.RegistrationActivityLogItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RegistrationActivityLogItem.displayName = 'proto.themis.RegistrationActivityLogItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventTeamFlat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventTeamFlat.repeatedFields_, null);
};
goog.inherits(proto.themis.EventTeamFlat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventTeamFlat.displayName = 'proto.themis.EventTeamFlat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventProgramFlat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventProgramFlat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventProgramFlat.displayName = 'proto.themis.EventProgramFlat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventRegistrationReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventRegistrationReport.repeatedFields_, null);
};
goog.inherits(proto.themis.EventRegistrationReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventRegistrationReport.displayName = 'proto.themis.EventRegistrationReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventRegistrationByProgramReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventRegistrationByProgramReport.repeatedFields_, null);
};
goog.inherits(proto.themis.EventRegistrationByProgramReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventRegistrationByProgramReport.displayName = 'proto.themis.EventRegistrationByProgramReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProducerProgramCreditReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.ProducerProgramCreditReport.repeatedFields_, null);
};
goog.inherits(proto.themis.ProducerProgramCreditReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProducerProgramCreditReport.displayName = 'proto.themis.ProducerProgramCreditReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.AthleteSignStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.AthleteSignStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.AthleteSignStatus.displayName = 'proto.themis.AthleteSignStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventJudgeShiftRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventJudgeShiftRequest.repeatedFields_, null);
};
goog.inherits(proto.themis.EventJudgeShiftRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventJudgeShiftRequest.displayName = 'proto.themis.EventJudgeShiftRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventJudgeShiftResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventJudgeShiftResponse.repeatedFields_, null);
};
goog.inherits(proto.themis.EventJudgeShiftResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventJudgeShiftResponse.displayName = 'proto.themis.EventJudgeShiftResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventJudgeShift = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventJudgeShift.repeatedFields_, null);
};
goog.inherits(proto.themis.EventJudgeShift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventJudgeShift.displayName = 'proto.themis.EventJudgeShift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RubricMultiJudgeStrategy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.RubricMultiJudgeStrategy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RubricMultiJudgeStrategy.displayName = 'proto.themis.RubricMultiJudgeStrategy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Rubric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Rubric.repeatedFields_, null);
};
goog.inherits(proto.themis.Rubric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Rubric.displayName = 'proto.themis.Rubric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RubricDeduction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.RubricDeduction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RubricDeduction.displayName = 'proto.themis.RubricDeduction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.Rubrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.Rubrics.repeatedFields_, null);
};
goog.inherits(proto.themis.Rubrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.Rubrics.displayName = 'proto.themis.Rubrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RubricArea = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.RubricArea.repeatedFields_, null);
};
goog.inherits(proto.themis.RubricArea, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RubricArea.displayName = 'proto.themis.RubricArea';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.RubricAreaItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.RubricAreaItem.repeatedFields_, null);
};
goog.inherits(proto.themis.RubricAreaItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.RubricAreaItem.displayName = 'proto.themis.RubricAreaItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionShift = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventDivisionShift.repeatedFields_, null);
};
goog.inherits(proto.themis.EventDivisionShift, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionShift.displayName = 'proto.themis.EventDivisionShift';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionShiftTeam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventDivisionShiftTeam.repeatedFields_, null);
};
goog.inherits(proto.themis.EventDivisionShiftTeam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionShiftTeam.displayName = 'proto.themis.EventDivisionShiftTeam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionShiftTeamDeduction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventDivisionShiftTeamDeduction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionShiftTeamDeduction.displayName = 'proto.themis.EventDivisionShiftTeamDeduction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionShiftTeamRubricArea = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.themis.EventDivisionShiftTeamRubricArea.repeatedFields_, null);
};
goog.inherits(proto.themis.EventDivisionShiftTeamRubricArea, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionShiftTeamRubricArea.displayName = 'proto.themis.EventDivisionShiftTeamRubricArea';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.EventDivisionShiftTeamRubricAreaScore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.EventDivisionShiftTeamRubricAreaScore.displayName = 'proto.themis.EventDivisionShiftTeamRubricAreaScore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.themis.ProgramCredit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.themis.ProgramCredit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.themis.ProgramCredit.displayName = 'proto.themis.ProgramCredit';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Producer.repeatedFields_ = [13,14,15,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Producer.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Producer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Producer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Producer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    website: jspb.Message.getFieldWithDefault(msg, 6, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    address: jspb.Message.getFieldWithDefault(msg, 9, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 10, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    email: jspb.Message.getFieldWithDefault(msg, 12, ""),
    urlsList: jspb.Message.toObjectList(msg.getUrlsList(),
    proto.themis.ProducerUrl.toObject, includeInstance),
    allowedProgramsList: jspb.Message.toObjectList(msg.getAllowedProgramsList(),
    proto.themis.ProgramAllowedProducer.toObject, includeInstance),
    brandsList: jspb.Message.toObjectList(msg.getBrandsList(),
    proto.themis.Brand.toObject, includeInstance),
    accountEmail: jspb.Message.getFieldWithDefault(msg, 16, ""),
    stripeAccount: jspb.Message.getFieldWithDefault(msg, 17, ""),
    stripeAccountActive: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    judgesList: jspb.Message.toObjectList(msg.getJudgesList(),
    proto.themis.Judge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Producer}
 */
proto.themis.Producer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Producer;
  return proto.themis.Producer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Producer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Producer}
 */
proto.themis.Producer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 13:
      var value = new proto.themis.ProducerUrl;
      reader.readMessage(value,proto.themis.ProducerUrl.deserializeBinaryFromReader);
      msg.addUrls(value);
      break;
    case 14:
      var value = new proto.themis.ProgramAllowedProducer;
      reader.readMessage(value,proto.themis.ProgramAllowedProducer.deserializeBinaryFromReader);
      msg.addAllowedPrograms(value);
      break;
    case 15:
      var value = new proto.themis.Brand;
      reader.readMessage(value,proto.themis.Brand.deserializeBinaryFromReader);
      msg.addBrands(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountEmail(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeAccount(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStripeAccountActive(value);
      break;
    case 19:
      var value = new proto.themis.Judge;
      reader.readMessage(value,proto.themis.Judge.deserializeBinaryFromReader);
      msg.addJudges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Producer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Producer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Producer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Producer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.themis.ProducerUrl.serializeBinaryToWriter
    );
  }
  f = message.getAllowedProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.themis.ProgramAllowedProducer.serializeBinaryToWriter
    );
  }
  f = message.getBrandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.themis.Brand.serializeBinaryToWriter
    );
  }
  f = message.getAccountEmail();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getStripeAccount();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getStripeAccountActive();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getJudgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.themis.Judge.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Producer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Producer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.themis.Producer.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.themis.Producer.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.themis.Producer.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string website = 6;
 * @return {string}
 */
proto.themis.Producer.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string logo = 7;
 * @return {string}
 */
proto.themis.Producer.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone = 8;
 * @return {string}
 */
proto.themis.Producer.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string address = 9;
 * @return {string}
 */
proto.themis.Producer.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string address2 = 10;
 * @return {string}
 */
proto.themis.Producer.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string postal_code = 11;
 * @return {string}
 */
proto.themis.Producer.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string email = 12;
 * @return {string}
 */
proto.themis.Producer.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated ProducerUrl urls = 13;
 * @return {!Array<!proto.themis.ProducerUrl>}
 */
proto.themis.Producer.prototype.getUrlsList = function() {
  return /** @type{!Array<!proto.themis.ProducerUrl>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.ProducerUrl, 13));
};


/**
 * @param {!Array<!proto.themis.ProducerUrl>} value
 * @return {!proto.themis.Producer} returns this
*/
proto.themis.Producer.prototype.setUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.themis.ProducerUrl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.ProducerUrl}
 */
proto.themis.Producer.prototype.addUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.themis.ProducerUrl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.clearUrlsList = function() {
  return this.setUrlsList([]);
};


/**
 * repeated ProgramAllowedProducer allowed_programs = 14;
 * @return {!Array<!proto.themis.ProgramAllowedProducer>}
 */
proto.themis.Producer.prototype.getAllowedProgramsList = function() {
  return /** @type{!Array<!proto.themis.ProgramAllowedProducer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.ProgramAllowedProducer, 14));
};


/**
 * @param {!Array<!proto.themis.ProgramAllowedProducer>} value
 * @return {!proto.themis.Producer} returns this
*/
proto.themis.Producer.prototype.setAllowedProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.themis.ProgramAllowedProducer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.ProgramAllowedProducer}
 */
proto.themis.Producer.prototype.addAllowedPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.themis.ProgramAllowedProducer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.clearAllowedProgramsList = function() {
  return this.setAllowedProgramsList([]);
};


/**
 * repeated Brand brands = 15;
 * @return {!Array<!proto.themis.Brand>}
 */
proto.themis.Producer.prototype.getBrandsList = function() {
  return /** @type{!Array<!proto.themis.Brand>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Brand, 15));
};


/**
 * @param {!Array<!proto.themis.Brand>} value
 * @return {!proto.themis.Producer} returns this
*/
proto.themis.Producer.prototype.setBrandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.themis.Brand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Brand}
 */
proto.themis.Producer.prototype.addBrands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.themis.Brand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.clearBrandsList = function() {
  return this.setBrandsList([]);
};


/**
 * optional string account_email = 16;
 * @return {string}
 */
proto.themis.Producer.prototype.getAccountEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setAccountEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string stripe_account = 17;
 * @return {string}
 */
proto.themis.Producer.prototype.getStripeAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setStripeAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool stripe_account_active = 18;
 * @return {boolean}
 */
proto.themis.Producer.prototype.getStripeAccountActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.setStripeAccountActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * repeated Judge judges = 19;
 * @return {!Array<!proto.themis.Judge>}
 */
proto.themis.Producer.prototype.getJudgesList = function() {
  return /** @type{!Array<!proto.themis.Judge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Judge, 19));
};


/**
 * @param {!Array<!proto.themis.Judge>} value
 * @return {!proto.themis.Producer} returns this
*/
proto.themis.Producer.prototype.setJudgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.themis.Judge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Judge}
 */
proto.themis.Producer.prototype.addJudges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.themis.Judge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Producer} returns this
 */
proto.themis.Producer.prototype.clearJudgesList = function() {
  return this.setJudgesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ProducerUrl.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProducerUrl.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProducerUrl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProducerUrl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerUrl.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    connectUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    producerId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    producer: (f = msg.getProducer()) && proto.themis.Producer.toObject(includeInstance, f),
    brandsList: jspb.Message.toObjectList(msg.getBrandsList(),
    proto.themis.Brand.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProducerUrl}
 */
proto.themis.ProducerUrl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProducerUrl;
  return proto.themis.ProducerUrl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProducerUrl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProducerUrl}
 */
proto.themis.ProducerUrl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 5:
      var value = new proto.themis.Producer;
      reader.readMessage(value,proto.themis.Producer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    case 6:
      var value = new proto.themis.Brand;
      reader.readMessage(value,proto.themis.Brand.deserializeBinaryFromReader);
      msg.addBrands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProducerUrl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProducerUrl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProducerUrl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerUrl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getConnectUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.themis.Producer.serializeBinaryToWriter
    );
  }
  f = message.getBrandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.themis.Brand.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.ProducerUrl.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ProducerUrl} returns this
 */
proto.themis.ProducerUrl.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string connect_url = 2;
 * @return {string}
 */
proto.themis.ProducerUrl.prototype.getConnectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ProducerUrl} returns this
 */
proto.themis.ProducerUrl.prototype.setConnectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_url = 3;
 * @return {string}
 */
proto.themis.ProducerUrl.prototype.getShortUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ProducerUrl} returns this
 */
proto.themis.ProducerUrl.prototype.setShortUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 producer_id = 4;
 * @return {number}
 */
proto.themis.ProducerUrl.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ProducerUrl} returns this
 */
proto.themis.ProducerUrl.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Producer producer = 5;
 * @return {?proto.themis.Producer}
 */
proto.themis.ProducerUrl.prototype.getProducer = function() {
  return /** @type{?proto.themis.Producer} */ (
    jspb.Message.getWrapperField(this, proto.themis.Producer, 5));
};


/**
 * @param {?proto.themis.Producer|undefined} value
 * @return {!proto.themis.ProducerUrl} returns this
*/
proto.themis.ProducerUrl.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ProducerUrl} returns this
 */
proto.themis.ProducerUrl.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ProducerUrl.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Brand brands = 6;
 * @return {!Array<!proto.themis.Brand>}
 */
proto.themis.ProducerUrl.prototype.getBrandsList = function() {
  return /** @type{!Array<!proto.themis.Brand>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Brand, 6));
};


/**
 * @param {!Array<!proto.themis.Brand>} value
 * @return {!proto.themis.ProducerUrl} returns this
*/
proto.themis.ProducerUrl.prototype.setBrandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.themis.Brand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Brand}
 */
proto.themis.ProducerUrl.prototype.addBrands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.themis.Brand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ProducerUrl} returns this
 */
proto.themis.ProducerUrl.prototype.clearBrandsList = function() {
  return this.setBrandsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ProducerUrls.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProducerUrls.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProducerUrls.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProducerUrls} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerUrls.toObject = function(includeInstance, msg) {
  var f, obj = {
    urlsList: jspb.Message.toObjectList(msg.getUrlsList(),
    proto.themis.ProducerUrl.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProducerUrls}
 */
proto.themis.ProducerUrls.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProducerUrls;
  return proto.themis.ProducerUrls.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProducerUrls} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProducerUrls}
 */
proto.themis.ProducerUrls.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.ProducerUrl;
      reader.readMessage(value,proto.themis.ProducerUrl.deserializeBinaryFromReader);
      msg.addUrls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProducerUrls.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProducerUrls.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProducerUrls} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerUrls.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.themis.ProducerUrl.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProducerUrl urls = 1;
 * @return {!Array<!proto.themis.ProducerUrl>}
 */
proto.themis.ProducerUrls.prototype.getUrlsList = function() {
  return /** @type{!Array<!proto.themis.ProducerUrl>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.ProducerUrl, 1));
};


/**
 * @param {!Array<!proto.themis.ProducerUrl>} value
 * @return {!proto.themis.ProducerUrls} returns this
*/
proto.themis.ProducerUrls.prototype.setUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.ProducerUrl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.ProducerUrl}
 */
proto.themis.ProducerUrls.prototype.addUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.ProducerUrl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ProducerUrls} returns this
 */
proto.themis.ProducerUrls.prototype.clearUrlsList = function() {
  return this.setUrlsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProgramAllowedProducer.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProgramAllowedProducer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProgramAllowedProducer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramAllowedProducer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    producerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    producer: (f = msg.getProducer()) && proto.themis.Producer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProgramAllowedProducer}
 */
proto.themis.ProgramAllowedProducer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProgramAllowedProducer;
  return proto.themis.ProgramAllowedProducer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProgramAllowedProducer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProgramAllowedProducer}
 */
proto.themis.ProgramAllowedProducer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerName(value);
      break;
    case 5:
      var value = new proto.themis.Producer;
      reader.readMessage(value,proto.themis.Producer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProgramAllowedProducer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProgramAllowedProducer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProgramAllowedProducer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramAllowedProducer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getProducerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.themis.Producer.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.ProgramAllowedProducer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ProgramAllowedProducer} returns this
 */
proto.themis.ProgramAllowedProducer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 program_id = 2;
 * @return {number}
 */
proto.themis.ProgramAllowedProducer.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ProgramAllowedProducer} returns this
 */
proto.themis.ProgramAllowedProducer.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 producer_id = 3;
 * @return {number}
 */
proto.themis.ProgramAllowedProducer.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ProgramAllowedProducer} returns this
 */
proto.themis.ProgramAllowedProducer.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string producer_name = 4;
 * @return {string}
 */
proto.themis.ProgramAllowedProducer.prototype.getProducerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ProgramAllowedProducer} returns this
 */
proto.themis.ProgramAllowedProducer.prototype.setProducerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Producer producer = 5;
 * @return {?proto.themis.Producer}
 */
proto.themis.ProgramAllowedProducer.prototype.getProducer = function() {
  return /** @type{?proto.themis.Producer} */ (
    jspb.Message.getWrapperField(this, proto.themis.Producer, 5));
};


/**
 * @param {?proto.themis.Producer|undefined} value
 * @return {!proto.themis.ProgramAllowedProducer} returns this
*/
proto.themis.ProgramAllowedProducer.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ProgramAllowedProducer} returns this
 */
proto.themis.ProgramAllowedProducer.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ProgramAllowedProducer.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ProgramAllowedProducers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProgramAllowedProducers.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProgramAllowedProducers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProgramAllowedProducers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramAllowedProducers.toObject = function(includeInstance, msg) {
  var f, obj = {
    programAllowedProducersList: jspb.Message.toObjectList(msg.getProgramAllowedProducersList(),
    proto.themis.ProgramAllowedProducer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProgramAllowedProducers}
 */
proto.themis.ProgramAllowedProducers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProgramAllowedProducers;
  return proto.themis.ProgramAllowedProducers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProgramAllowedProducers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProgramAllowedProducers}
 */
proto.themis.ProgramAllowedProducers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.ProgramAllowedProducer;
      reader.readMessage(value,proto.themis.ProgramAllowedProducer.deserializeBinaryFromReader);
      msg.addProgramAllowedProducers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProgramAllowedProducers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProgramAllowedProducers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProgramAllowedProducers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramAllowedProducers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramAllowedProducersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.themis.ProgramAllowedProducer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProgramAllowedProducer program_allowed_producers = 1;
 * @return {!Array<!proto.themis.ProgramAllowedProducer>}
 */
proto.themis.ProgramAllowedProducers.prototype.getProgramAllowedProducersList = function() {
  return /** @type{!Array<!proto.themis.ProgramAllowedProducer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.ProgramAllowedProducer, 1));
};


/**
 * @param {!Array<!proto.themis.ProgramAllowedProducer>} value
 * @return {!proto.themis.ProgramAllowedProducers} returns this
*/
proto.themis.ProgramAllowedProducers.prototype.setProgramAllowedProducersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.ProgramAllowedProducer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.ProgramAllowedProducer}
 */
proto.themis.ProgramAllowedProducers.prototype.addProgramAllowedProducers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.ProgramAllowedProducer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ProgramAllowedProducers} returns this
 */
proto.themis.ProgramAllowedProducers.prototype.clearProgramAllowedProducersList = function() {
  return this.setProgramAllowedProducersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Brand.repeatedFields_ = [16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Brand.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Brand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Brand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Brand.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    country: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    city: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address: jspb.Message.getFieldWithDefault(msg, 7, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 8, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 10, ""),
    email: jspb.Message.getFieldWithDefault(msg, 11, ""),
    website: jspb.Message.getFieldWithDefault(msg, 12, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 13, ""),
    producerId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    producer: (f = msg.getProducer()) && proto.themis.Producer.toObject(includeInstance, f),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.themis.Event.toObject, includeInstance),
    judgesList: jspb.Message.toObjectList(msg.getJudgesList(),
    proto.themis.Judge.toObject, includeInstance),
    guardianRequired: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Brand}
 */
proto.themis.Brand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Brand;
  return proto.themis.Brand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Brand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Brand}
 */
proto.themis.Brand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 15:
      var value = new proto.themis.Producer;
      reader.readMessage(value,proto.themis.Producer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    case 16:
      var value = new proto.themis.Event;
      reader.readMessage(value,proto.themis.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 17:
      var value = new proto.themis.Judge;
      reader.readMessage(value,proto.themis.Judge.deserializeBinaryFromReader);
      msg.addJudges(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGuardianRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Brand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Brand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Brand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Brand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.themis.Producer.serializeBinaryToWriter
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.themis.Event.serializeBinaryToWriter
    );
  }
  f = message.getJudgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.themis.Judge.serializeBinaryToWriter
    );
  }
  f = message.getGuardianRequired();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Brand.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Brand.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo = 3;
 * @return {string}
 */
proto.themis.Brand.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.themis.Brand.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.themis.Brand.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string city = 6;
 * @return {string}
 */
proto.themis.Brand.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address = 7;
 * @return {string}
 */
proto.themis.Brand.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string address2 = 8;
 * @return {string}
 */
proto.themis.Brand.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string postal_code = 9;
 * @return {string}
 */
proto.themis.Brand.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string phone = 10;
 * @return {string}
 */
proto.themis.Brand.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string email = 11;
 * @return {string}
 */
proto.themis.Brand.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string website = 12;
 * @return {string}
 */
proto.themis.Brand.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string currency = 13;
 * @return {string}
 */
proto.themis.Brand.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint32 producer_id = 14;
 * @return {number}
 */
proto.themis.Brand.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional Producer producer = 15;
 * @return {?proto.themis.Producer}
 */
proto.themis.Brand.prototype.getProducer = function() {
  return /** @type{?proto.themis.Producer} */ (
    jspb.Message.getWrapperField(this, proto.themis.Producer, 15));
};


/**
 * @param {?proto.themis.Producer|undefined} value
 * @return {!proto.themis.Brand} returns this
*/
proto.themis.Brand.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Brand.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated Event events = 16;
 * @return {!Array<!proto.themis.Event>}
 */
proto.themis.Brand.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.themis.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Event, 16));
};


/**
 * @param {!Array<!proto.themis.Event>} value
 * @return {!proto.themis.Brand} returns this
*/
proto.themis.Brand.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.themis.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Event}
 */
proto.themis.Brand.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.themis.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * repeated Judge judges = 17;
 * @return {!Array<!proto.themis.Judge>}
 */
proto.themis.Brand.prototype.getJudgesList = function() {
  return /** @type{!Array<!proto.themis.Judge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Judge, 17));
};


/**
 * @param {!Array<!proto.themis.Judge>} value
 * @return {!proto.themis.Brand} returns this
*/
proto.themis.Brand.prototype.setJudgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.themis.Judge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Judge}
 */
proto.themis.Brand.prototype.addJudges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.themis.Judge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.clearJudgesList = function() {
  return this.setJudgesList([]);
};


/**
 * optional bool guardian_required = 18;
 * @return {boolean}
 */
proto.themis.Brand.prototype.getGuardianRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.Brand} returns this
 */
proto.themis.Brand.prototype.setGuardianRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Event.repeatedFields_ = [14,15,16,24,26,28,32];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    country: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    city: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address: jspb.Message.getFieldWithDefault(msg, 7, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 8, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 10, ""),
    email: jspb.Message.getFieldWithDefault(msg, 11, ""),
    website: jspb.Message.getFieldWithDefault(msg, 12, ""),
    info: jspb.Message.getFieldWithDefault(msg, 13, ""),
    registrationCodesList: jspb.Message.toObjectList(msg.getRegistrationCodesList(),
    proto.themis.EventRegistrationCode.toObject, includeInstance),
    eventDatesList: jspb.Message.toObjectList(msg.getEventDatesList(),
    proto.themis.EventDate.toObject, includeInstance),
    eventFloorsList: jspb.Message.toObjectList(msg.getEventFloorsList(),
    proto.themis.EventFloor.toObject, includeInstance),
    currency: jspb.Message.getFieldWithDefault(msg, 17, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    timeZone: jspb.Message.getFieldWithDefault(msg, 20, ""),
    brand: (f = msg.getBrand()) && proto.themis.Brand.toObject(includeInstance, f),
    brandId: jspb.Message.getFieldWithDefault(msg, 22, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 23, 0),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.themis.EventTeam.toObject, includeInstance),
    registrationPrice: jspb.Message.getFieldWithDefault(msg, 25, 0),
    seasonsList: jspb.Message.toObjectList(msg.getSeasonsList(),
    proto.themis.Season.toObject, includeInstance),
    eventPricing: (f = msg.getEventPricing()) && proto.themis.EventPricing.toObject(includeInstance, f),
    eventDivisionList: jspb.Message.toObjectList(msg.getEventDivisionList(),
    proto.themis.EventDivision.toObject, includeInstance),
    registrationLive: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    firstDay: jspb.Message.getFieldWithDefault(msg, 30, ""),
    venueName: jspb.Message.getFieldWithDefault(msg, 31, ""),
    eventDocumentsList: jspb.Message.toObjectList(msg.getEventDocumentsList(),
    proto.themis.EventDocument.toObject, includeInstance),
    warmupTime: jspb.Message.getFieldWithDefault(msg, 33, 0),
    rotationTime: jspb.Message.getFieldWithDefault(msg, 34, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Event}
 */
proto.themis.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Event;
  return proto.themis.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Event}
 */
proto.themis.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setInfo(value);
      break;
    case 14:
      var value = new proto.themis.EventRegistrationCode;
      reader.readMessage(value,proto.themis.EventRegistrationCode.deserializeBinaryFromReader);
      msg.addRegistrationCodes(value);
      break;
    case 15:
      var value = new proto.themis.EventDate;
      reader.readMessage(value,proto.themis.EventDate.deserializeBinaryFromReader);
      msg.addEventDates(value);
      break;
    case 16:
      var value = new proto.themis.EventFloor;
      reader.readMessage(value,proto.themis.EventFloor.deserializeBinaryFromReader);
      msg.addEventFloors(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    case 21:
      var value = new proto.themis.Brand;
      reader.readMessage(value,proto.themis.Brand.deserializeBinaryFromReader);
      msg.setBrand(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 24:
      var value = new proto.themis.EventTeam;
      reader.readMessage(value,proto.themis.EventTeam.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRegistrationPrice(value);
      break;
    case 26:
      var value = new proto.themis.Season;
      reader.readMessage(value,proto.themis.Season.deserializeBinaryFromReader);
      msg.addSeasons(value);
      break;
    case 27:
      var value = new proto.themis.EventPricing;
      reader.readMessage(value,proto.themis.EventPricing.deserializeBinaryFromReader);
      msg.setEventPricing(value);
      break;
    case 28:
      var value = new proto.themis.EventDivision;
      reader.readMessage(value,proto.themis.EventDivision.deserializeBinaryFromReader);
      msg.addEventDivision(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegistrationLive(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstDay(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setVenueName(value);
      break;
    case 32:
      var value = new proto.themis.EventDocument;
      reader.readMessage(value,proto.themis.EventDocument.deserializeBinaryFromReader);
      msg.addEventDocuments(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWarmupTime(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRotationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getInfo();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRegistrationCodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.themis.EventRegistrationCode.serializeBinaryToWriter
    );
  }
  f = message.getEventDatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.themis.EventDate.serializeBinaryToWriter
    );
  }
  f = message.getEventFloorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.themis.EventFloor.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getBrand();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.themis.Brand.serializeBinaryToWriter
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      22,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      23,
      f
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.themis.EventTeam.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationPrice();
  if (f !== 0) {
    writer.writeUint64(
      25,
      f
    );
  }
  f = message.getSeasonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.themis.Season.serializeBinaryToWriter
    );
  }
  f = message.getEventPricing();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.themis.EventPricing.serializeBinaryToWriter
    );
  }
  f = message.getEventDivisionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      proto.themis.EventDivision.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationLive();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getFirstDay();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getVenueName();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getEventDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      32,
      f,
      proto.themis.EventDocument.serializeBinaryToWriter
    );
  }
  f = message.getWarmupTime();
  if (f !== 0) {
    writer.writeUint32(
      33,
      f
    );
  }
  f = message.getRotationTime();
  if (f !== 0) {
    writer.writeUint32(
      34,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Event.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Event.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string logo = 3;
 * @return {string}
 */
proto.themis.Event.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.themis.Event.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.themis.Event.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string city = 6;
 * @return {string}
 */
proto.themis.Event.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address = 7;
 * @return {string}
 */
proto.themis.Event.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string address2 = 8;
 * @return {string}
 */
proto.themis.Event.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string postal_code = 9;
 * @return {string}
 */
proto.themis.Event.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string phone = 10;
 * @return {string}
 */
proto.themis.Event.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string email = 11;
 * @return {string}
 */
proto.themis.Event.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string website = 12;
 * @return {string}
 */
proto.themis.Event.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string info = 13;
 * @return {string}
 */
proto.themis.Event.prototype.getInfo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setInfo = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated EventRegistrationCode registration_codes = 14;
 * @return {!Array<!proto.themis.EventRegistrationCode>}
 */
proto.themis.Event.prototype.getRegistrationCodesList = function() {
  return /** @type{!Array<!proto.themis.EventRegistrationCode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventRegistrationCode, 14));
};


/**
 * @param {!Array<!proto.themis.EventRegistrationCode>} value
 * @return {!proto.themis.Event} returns this
*/
proto.themis.Event.prototype.setRegistrationCodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.themis.EventRegistrationCode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventRegistrationCode}
 */
proto.themis.Event.prototype.addRegistrationCodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.themis.EventRegistrationCode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.clearRegistrationCodesList = function() {
  return this.setRegistrationCodesList([]);
};


/**
 * repeated EventDate event_dates = 15;
 * @return {!Array<!proto.themis.EventDate>}
 */
proto.themis.Event.prototype.getEventDatesList = function() {
  return /** @type{!Array<!proto.themis.EventDate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventDate, 15));
};


/**
 * @param {!Array<!proto.themis.EventDate>} value
 * @return {!proto.themis.Event} returns this
*/
proto.themis.Event.prototype.setEventDatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.themis.EventDate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDate}
 */
proto.themis.Event.prototype.addEventDates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.themis.EventDate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.clearEventDatesList = function() {
  return this.setEventDatesList([]);
};


/**
 * repeated EventFloor event_floors = 16;
 * @return {!Array<!proto.themis.EventFloor>}
 */
proto.themis.Event.prototype.getEventFloorsList = function() {
  return /** @type{!Array<!proto.themis.EventFloor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventFloor, 16));
};


/**
 * @param {!Array<!proto.themis.EventFloor>} value
 * @return {!proto.themis.Event} returns this
*/
proto.themis.Event.prototype.setEventFloorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.themis.EventFloor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventFloor}
 */
proto.themis.Event.prototype.addEventFloors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.themis.EventFloor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.clearEventFloorsList = function() {
  return this.setEventFloorsList([]);
};


/**
 * optional string currency = 17;
 * @return {string}
 */
proto.themis.Event.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional double lat = 18;
 * @return {number}
 */
proto.themis.Event.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double lng = 19;
 * @return {number}
 */
proto.themis.Event.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional string time_zone = 20;
 * @return {string}
 */
proto.themis.Event.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional Brand brand = 21;
 * @return {?proto.themis.Brand}
 */
proto.themis.Event.prototype.getBrand = function() {
  return /** @type{?proto.themis.Brand} */ (
    jspb.Message.getWrapperField(this, proto.themis.Brand, 21));
};


/**
 * @param {?proto.themis.Brand|undefined} value
 * @return {!proto.themis.Event} returns this
*/
proto.themis.Event.prototype.setBrand = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.clearBrand = function() {
  return this.setBrand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Event.prototype.hasBrand = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional uint32 brand_id = 22;
 * @return {number}
 */
proto.themis.Event.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint32 producer_id = 23;
 * @return {number}
 */
proto.themis.Event.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * repeated EventTeam teams = 24;
 * @return {!Array<!proto.themis.EventTeam>}
 */
proto.themis.Event.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.themis.EventTeam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventTeam, 24));
};


/**
 * @param {!Array<!proto.themis.EventTeam>} value
 * @return {!proto.themis.Event} returns this
*/
proto.themis.Event.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.themis.EventTeam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventTeam}
 */
proto.themis.Event.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.themis.EventTeam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};


/**
 * optional uint64 registration_price = 25;
 * @return {number}
 */
proto.themis.Event.prototype.getRegistrationPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setRegistrationPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * repeated Season seasons = 26;
 * @return {!Array<!proto.themis.Season>}
 */
proto.themis.Event.prototype.getSeasonsList = function() {
  return /** @type{!Array<!proto.themis.Season>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Season, 26));
};


/**
 * @param {!Array<!proto.themis.Season>} value
 * @return {!proto.themis.Event} returns this
*/
proto.themis.Event.prototype.setSeasonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.themis.Season=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Season}
 */
proto.themis.Event.prototype.addSeasons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.themis.Season, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.clearSeasonsList = function() {
  return this.setSeasonsList([]);
};


/**
 * optional EventPricing event_pricing = 27;
 * @return {?proto.themis.EventPricing}
 */
proto.themis.Event.prototype.getEventPricing = function() {
  return /** @type{?proto.themis.EventPricing} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventPricing, 27));
};


/**
 * @param {?proto.themis.EventPricing|undefined} value
 * @return {!proto.themis.Event} returns this
*/
proto.themis.Event.prototype.setEventPricing = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.clearEventPricing = function() {
  return this.setEventPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Event.prototype.hasEventPricing = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * repeated EventDivision event_division = 28;
 * @return {!Array<!proto.themis.EventDivision>}
 */
proto.themis.Event.prototype.getEventDivisionList = function() {
  return /** @type{!Array<!proto.themis.EventDivision>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventDivision, 28));
};


/**
 * @param {!Array<!proto.themis.EventDivision>} value
 * @return {!proto.themis.Event} returns this
*/
proto.themis.Event.prototype.setEventDivisionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.themis.EventDivision=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivision}
 */
proto.themis.Event.prototype.addEventDivision = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.themis.EventDivision, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.clearEventDivisionList = function() {
  return this.setEventDivisionList([]);
};


/**
 * optional bool registration_live = 29;
 * @return {boolean}
 */
proto.themis.Event.prototype.getRegistrationLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setRegistrationLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional string first_day = 30;
 * @return {string}
 */
proto.themis.Event.prototype.getFirstDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setFirstDay = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string venue_name = 31;
 * @return {string}
 */
proto.themis.Event.prototype.getVenueName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setVenueName = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * repeated EventDocument event_documents = 32;
 * @return {!Array<!proto.themis.EventDocument>}
 */
proto.themis.Event.prototype.getEventDocumentsList = function() {
  return /** @type{!Array<!proto.themis.EventDocument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventDocument, 32));
};


/**
 * @param {!Array<!proto.themis.EventDocument>} value
 * @return {!proto.themis.Event} returns this
*/
proto.themis.Event.prototype.setEventDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 32, value);
};


/**
 * @param {!proto.themis.EventDocument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDocument}
 */
proto.themis.Event.prototype.addEventDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 32, opt_value, proto.themis.EventDocument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.clearEventDocumentsList = function() {
  return this.setEventDocumentsList([]);
};


/**
 * optional uint32 warmup_time = 33;
 * @return {number}
 */
proto.themis.Event.prototype.getWarmupTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setWarmupTime = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional uint32 rotation_time = 34;
 * @return {number}
 */
proto.themis.Event.prototype.getRotationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Event} returns this
 */
proto.themis.Event.prototype.setRotationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 34, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventPricing.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventPricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventPricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventPricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    event: (f = msg.getEvent()) && proto.themis.Event.toObject(includeInstance, f),
    athletePriceInt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    athletePriceString: jspb.Message.getFieldWithDefault(msg, 4, ""),
    crossoverPriceInt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    crossoverPriceString: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventPricing}
 */
proto.themis.EventPricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventPricing;
  return proto.themis.EventPricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventPricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventPricing}
 */
proto.themis.EventPricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.Event;
      reader.readMessage(value,proto.themis.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAthletePriceInt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAthletePriceString(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCrossoverPriceInt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrossoverPriceString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventPricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventPricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventPricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventPricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.Event.serializeBinaryToWriter
    );
  }
  f = message.getAthletePriceInt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAthletePriceString();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCrossoverPriceInt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCrossoverPriceString();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventPricing.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventPricing} returns this
 */
proto.themis.EventPricing.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Event event = 2;
 * @return {?proto.themis.Event}
 */
proto.themis.EventPricing.prototype.getEvent = function() {
  return /** @type{?proto.themis.Event} */ (
    jspb.Message.getWrapperField(this, proto.themis.Event, 2));
};


/**
 * @param {?proto.themis.Event|undefined} value
 * @return {!proto.themis.EventPricing} returns this
*/
proto.themis.EventPricing.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventPricing} returns this
 */
proto.themis.EventPricing.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventPricing.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 athlete_price_int = 3;
 * @return {number}
 */
proto.themis.EventPricing.prototype.getAthletePriceInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventPricing} returns this
 */
proto.themis.EventPricing.prototype.setAthletePriceInt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string athlete_price_string = 4;
 * @return {string}
 */
proto.themis.EventPricing.prototype.getAthletePriceString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventPricing} returns this
 */
proto.themis.EventPricing.prototype.setAthletePriceString = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 crossover_price_int = 6;
 * @return {number}
 */
proto.themis.EventPricing.prototype.getCrossoverPriceInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventPricing} returns this
 */
proto.themis.EventPricing.prototype.setCrossoverPriceInt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string crossover_price_string = 7;
 * @return {string}
 */
proto.themis.EventPricing.prototype.getCrossoverPriceString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventPricing} returns this
 */
proto.themis.EventPricing.prototype.setCrossoverPriceString = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionPricing.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionPricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionPricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionPricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventDivision: (f = msg.getEventDivision()) && proto.themis.EventDivision.toObject(includeInstance, f),
    athletePriceInt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    athletePriceString: jspb.Message.getFieldWithDefault(msg, 4, ""),
    teamPriceInt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    teamPriceString: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionPricing}
 */
proto.themis.EventDivisionPricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionPricing;
  return proto.themis.EventDivisionPricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionPricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionPricing}
 */
proto.themis.EventDivisionPricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.EventDivision;
      reader.readMessage(value,proto.themis.EventDivision.deserializeBinaryFromReader);
      msg.setEventDivision(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAthletePriceInt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAthletePriceString(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTeamPriceInt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamPriceString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionPricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionPricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionPricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionPricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventDivision();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.EventDivision.serializeBinaryToWriter
    );
  }
  f = message.getAthletePriceInt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAthletePriceString();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTeamPriceInt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTeamPriceString();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventDivisionPricing.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionPricing} returns this
 */
proto.themis.EventDivisionPricing.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EventDivision event_division = 2;
 * @return {?proto.themis.EventDivision}
 */
proto.themis.EventDivisionPricing.prototype.getEventDivision = function() {
  return /** @type{?proto.themis.EventDivision} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventDivision, 2));
};


/**
 * @param {?proto.themis.EventDivision|undefined} value
 * @return {!proto.themis.EventDivisionPricing} returns this
*/
proto.themis.EventDivisionPricing.prototype.setEventDivision = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionPricing} returns this
 */
proto.themis.EventDivisionPricing.prototype.clearEventDivision = function() {
  return this.setEventDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionPricing.prototype.hasEventDivision = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 athlete_price_int = 3;
 * @return {number}
 */
proto.themis.EventDivisionPricing.prototype.getAthletePriceInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionPricing} returns this
 */
proto.themis.EventDivisionPricing.prototype.setAthletePriceInt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string athlete_price_string = 4;
 * @return {string}
 */
proto.themis.EventDivisionPricing.prototype.getAthletePriceString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDivisionPricing} returns this
 */
proto.themis.EventDivisionPricing.prototype.setAthletePriceString = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 team_price_int = 6;
 * @return {number}
 */
proto.themis.EventDivisionPricing.prototype.getTeamPriceInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionPricing} returns this
 */
proto.themis.EventDivisionPricing.prototype.setTeamPriceInt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string team_price_string = 7;
 * @return {string}
 */
proto.themis.EventDivisionPricing.prototype.getTeamPriceString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDivisionPricing} returns this
 */
proto.themis.EventDivisionPricing.prototype.setTeamPriceString = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.CalculatedAthletePricing.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.CalculatedAthletePricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.CalculatedAthletePricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CalculatedAthletePricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    athleteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    registrationType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    baseAthletePrice: jspb.Message.getFieldWithDefault(msg, 4, 0),
    baseAthleteCrossoverPrice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    athletePrice: jspb.Message.getFieldWithDefault(msg, 6, 0),
    discountAthletePrice: jspb.Message.getFieldWithDefault(msg, 7, 0),
    discountCrossoverPrice: jspb.Message.getFieldWithDefault(msg, 8, 0),
    crPayout: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.CalculatedAthletePricing}
 */
proto.themis.CalculatedAthletePricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.CalculatedAthletePricing;
  return proto.themis.CalculatedAthletePricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.CalculatedAthletePricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.CalculatedAthletePricing}
 */
proto.themis.CalculatedAthletePricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAthleteId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBaseAthletePrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBaseAthleteCrossoverPrice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAthletePrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDiscountAthletePrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDiscountCrossoverPrice(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCrPayout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.CalculatedAthletePricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.CalculatedAthletePricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.CalculatedAthletePricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.CalculatedAthletePricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAthleteId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRegistrationType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBaseAthletePrice();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getBaseAthleteCrossoverPrice();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getAthletePrice();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getDiscountAthletePrice();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getDiscountCrossoverPrice();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getCrPayout();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
};


/**
 * optional uint32 athlete_id = 1;
 * @return {number}
 */
proto.themis.CalculatedAthletePricing.prototype.getAthleteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CalculatedAthletePricing} returns this
 */
proto.themis.CalculatedAthletePricing.prototype.setAthleteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string registration_type = 2;
 * @return {string}
 */
proto.themis.CalculatedAthletePricing.prototype.getRegistrationType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.CalculatedAthletePricing} returns this
 */
proto.themis.CalculatedAthletePricing.prototype.setRegistrationType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.themis.CalculatedAthletePricing.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.CalculatedAthletePricing} returns this
 */
proto.themis.CalculatedAthletePricing.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 base_athlete_price = 4;
 * @return {number}
 */
proto.themis.CalculatedAthletePricing.prototype.getBaseAthletePrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CalculatedAthletePricing} returns this
 */
proto.themis.CalculatedAthletePricing.prototype.setBaseAthletePrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 base_athlete_crossover_price = 5;
 * @return {number}
 */
proto.themis.CalculatedAthletePricing.prototype.getBaseAthleteCrossoverPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CalculatedAthletePricing} returns this
 */
proto.themis.CalculatedAthletePricing.prototype.setBaseAthleteCrossoverPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 athlete_price = 6;
 * @return {number}
 */
proto.themis.CalculatedAthletePricing.prototype.getAthletePrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CalculatedAthletePricing} returns this
 */
proto.themis.CalculatedAthletePricing.prototype.setAthletePrice = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 discount_athlete_price = 7;
 * @return {number}
 */
proto.themis.CalculatedAthletePricing.prototype.getDiscountAthletePrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CalculatedAthletePricing} returns this
 */
proto.themis.CalculatedAthletePricing.prototype.setDiscountAthletePrice = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 discount_crossover_price = 8;
 * @return {number}
 */
proto.themis.CalculatedAthletePricing.prototype.getDiscountCrossoverPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CalculatedAthletePricing} returns this
 */
proto.themis.CalculatedAthletePricing.prototype.setDiscountCrossoverPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 cr_payout = 9;
 * @return {number}
 */
proto.themis.CalculatedAthletePricing.prototype.getCrPayout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.CalculatedAthletePricing} returns this
 */
proto.themis.CalculatedAthletePricing.prototype.setCrPayout = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventTeamDisplayPricing.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventTeamDisplayPricing.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventTeamDisplayPricing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventTeamDisplayPricing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeamDisplayPricing.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamPrice: jspb.Message.getFieldWithDefault(msg, 2, 0),
    athletePricesList: jspb.Message.toObjectList(msg.getAthletePricesList(),
    proto.themis.CalculatedAthletePricing.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventTeamDisplayPricing}
 */
proto.themis.EventTeamDisplayPricing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventTeamDisplayPricing;
  return proto.themis.EventTeamDisplayPricing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventTeamDisplayPricing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventTeamDisplayPricing}
 */
proto.themis.EventTeamDisplayPricing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTeamPrice(value);
      break;
    case 3:
      var value = new proto.themis.CalculatedAthletePricing;
      reader.readMessage(value,proto.themis.CalculatedAthletePricing.deserializeBinaryFromReader);
      msg.addAthletePrices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventTeamDisplayPricing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventTeamDisplayPricing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventTeamDisplayPricing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeamDisplayPricing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamPrice();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAthletePricesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.themis.CalculatedAthletePricing.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 team_price = 2;
 * @return {number}
 */
proto.themis.EventTeamDisplayPricing.prototype.getTeamPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamDisplayPricing} returns this
 */
proto.themis.EventTeamDisplayPricing.prototype.setTeamPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated CalculatedAthletePricing athlete_prices = 3;
 * @return {!Array<!proto.themis.CalculatedAthletePricing>}
 */
proto.themis.EventTeamDisplayPricing.prototype.getAthletePricesList = function() {
  return /** @type{!Array<!proto.themis.CalculatedAthletePricing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.CalculatedAthletePricing, 3));
};


/**
 * @param {!Array<!proto.themis.CalculatedAthletePricing>} value
 * @return {!proto.themis.EventTeamDisplayPricing} returns this
*/
proto.themis.EventTeamDisplayPricing.prototype.setAthletePricesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.CalculatedAthletePricing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.CalculatedAthletePricing}
 */
proto.themis.EventTeamDisplayPricing.prototype.addAthletePrices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.CalculatedAthletePricing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventTeamDisplayPricing} returns this
 */
proto.themis.EventTeamDisplayPricing.prototype.clearAthletePricesList = function() {
  return this.setAthletePricesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.RegistrationTransaction.repeatedFields_ = [31];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RegistrationTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RegistrationTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RegistrationTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    baseAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    discountAmount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    crossoverDiscountAmount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    crPayoutAmount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    teamId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    paid: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    user: (f = msg.getUser()) && proto.themis.User.toObject(includeInstance, f),
    producer: (f = msg.getProducer()) && proto.themis.Producer.toObject(includeInstance, f),
    brand: (f = msg.getBrand()) && proto.themis.Brand.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && proto.themis.Event.toObject(includeInstance, f),
    eventTeam: (f = msg.getEventTeam()) && proto.themis.EventTeam.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto.themis.Location.toObject(includeInstance, f),
    athlete: (f = msg.getAthlete()) && proto.themis.Athlete.toObject(includeInstance, f),
    paymentsList: jspb.Message.toObjectList(msg.getPaymentsList(),
    proto.themis.RegistrationTransactionPayment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RegistrationTransaction}
 */
proto.themis.RegistrationTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RegistrationTransaction;
  return proto.themis.RegistrationTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RegistrationTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RegistrationTransaction}
 */
proto.themis.RegistrationTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBaseAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDiscountAmount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCrossoverDiscountAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCrPayoutAmount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamId(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaid(value);
      break;
    case 21:
      var value = new proto.themis.User;
      reader.readMessage(value,proto.themis.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 25:
      var value = new proto.themis.Producer;
      reader.readMessage(value,proto.themis.Producer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    case 26:
      var value = new proto.themis.Brand;
      reader.readMessage(value,proto.themis.Brand.deserializeBinaryFromReader);
      msg.setBrand(value);
      break;
    case 27:
      var value = new proto.themis.Event;
      reader.readMessage(value,proto.themis.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 28:
      var value = new proto.themis.EventTeam;
      reader.readMessage(value,proto.themis.EventTeam.deserializeBinaryFromReader);
      msg.setEventTeam(value);
      break;
    case 29:
      var value = new proto.themis.Location;
      reader.readMessage(value,proto.themis.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 30:
      var value = new proto.themis.Athlete;
      reader.readMessage(value,proto.themis.Athlete.deserializeBinaryFromReader);
      msg.setAthlete(value);
      break;
    case 31:
      var value = new proto.themis.RegistrationTransactionPayment;
      reader.readMessage(value,proto.themis.RegistrationTransactionPayment.deserializeBinaryFromReader);
      msg.addPayments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RegistrationTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RegistrationTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RegistrationTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBaseAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDiscountAmount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCrossoverDiscountAmount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCrPayoutAmount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getTeamId();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getPaid();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.themis.User.serializeBinaryToWriter
    );
  }
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.themis.Producer.serializeBinaryToWriter
    );
  }
  f = message.getBrand();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.themis.Brand.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.themis.Event.serializeBinaryToWriter
    );
  }
  f = message.getEventTeam();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.themis.EventTeam.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.themis.Location.serializeBinaryToWriter
    );
  }
  f = message.getAthlete();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.themis.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getPaymentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      31,
      f,
      proto.themis.RegistrationTransactionPayment.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.themis.RegistrationTransaction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.themis.RegistrationTransaction.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 base_amount = 4;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getBaseAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setBaseAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 amount = 5;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 discount_amount = 6;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getDiscountAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setDiscountAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 crossover_discount_amount = 7;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getCrossoverDiscountAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setCrossoverDiscountAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 cr_payout_amount = 8;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getCrPayoutAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setCrPayoutAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 producer_id = 10;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 brand_id = 11;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 event_id = 12;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 program_id = 13;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 location_id = 14;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 team_id = 15;
 * @return {number}
 */
proto.themis.RegistrationTransaction.prototype.getTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional bool paid = 20;
 * @return {boolean}
 */
proto.themis.RegistrationTransaction.prototype.getPaid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.setPaid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional User user = 21;
 * @return {?proto.themis.User}
 */
proto.themis.RegistrationTransaction.prototype.getUser = function() {
  return /** @type{?proto.themis.User} */ (
    jspb.Message.getWrapperField(this, proto.themis.User, 21));
};


/**
 * @param {?proto.themis.User|undefined} value
 * @return {!proto.themis.RegistrationTransaction} returns this
*/
proto.themis.RegistrationTransaction.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.RegistrationTransaction.prototype.hasUser = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional Producer producer = 25;
 * @return {?proto.themis.Producer}
 */
proto.themis.RegistrationTransaction.prototype.getProducer = function() {
  return /** @type{?proto.themis.Producer} */ (
    jspb.Message.getWrapperField(this, proto.themis.Producer, 25));
};


/**
 * @param {?proto.themis.Producer|undefined} value
 * @return {!proto.themis.RegistrationTransaction} returns this
*/
proto.themis.RegistrationTransaction.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.RegistrationTransaction.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional Brand brand = 26;
 * @return {?proto.themis.Brand}
 */
proto.themis.RegistrationTransaction.prototype.getBrand = function() {
  return /** @type{?proto.themis.Brand} */ (
    jspb.Message.getWrapperField(this, proto.themis.Brand, 26));
};


/**
 * @param {?proto.themis.Brand|undefined} value
 * @return {!proto.themis.RegistrationTransaction} returns this
*/
proto.themis.RegistrationTransaction.prototype.setBrand = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.clearBrand = function() {
  return this.setBrand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.RegistrationTransaction.prototype.hasBrand = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional Event event = 27;
 * @return {?proto.themis.Event}
 */
proto.themis.RegistrationTransaction.prototype.getEvent = function() {
  return /** @type{?proto.themis.Event} */ (
    jspb.Message.getWrapperField(this, proto.themis.Event, 27));
};


/**
 * @param {?proto.themis.Event|undefined} value
 * @return {!proto.themis.RegistrationTransaction} returns this
*/
proto.themis.RegistrationTransaction.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.RegistrationTransaction.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional EventTeam event_team = 28;
 * @return {?proto.themis.EventTeam}
 */
proto.themis.RegistrationTransaction.prototype.getEventTeam = function() {
  return /** @type{?proto.themis.EventTeam} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventTeam, 28));
};


/**
 * @param {?proto.themis.EventTeam|undefined} value
 * @return {!proto.themis.RegistrationTransaction} returns this
*/
proto.themis.RegistrationTransaction.prototype.setEventTeam = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.clearEventTeam = function() {
  return this.setEventTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.RegistrationTransaction.prototype.hasEventTeam = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional Location location = 29;
 * @return {?proto.themis.Location}
 */
proto.themis.RegistrationTransaction.prototype.getLocation = function() {
  return /** @type{?proto.themis.Location} */ (
    jspb.Message.getWrapperField(this, proto.themis.Location, 29));
};


/**
 * @param {?proto.themis.Location|undefined} value
 * @return {!proto.themis.RegistrationTransaction} returns this
*/
proto.themis.RegistrationTransaction.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.RegistrationTransaction.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional Athlete athlete = 30;
 * @return {?proto.themis.Athlete}
 */
proto.themis.RegistrationTransaction.prototype.getAthlete = function() {
  return /** @type{?proto.themis.Athlete} */ (
    jspb.Message.getWrapperField(this, proto.themis.Athlete, 30));
};


/**
 * @param {?proto.themis.Athlete|undefined} value
 * @return {!proto.themis.RegistrationTransaction} returns this
*/
proto.themis.RegistrationTransaction.prototype.setAthlete = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.clearAthlete = function() {
  return this.setAthlete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.RegistrationTransaction.prototype.hasAthlete = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * repeated RegistrationTransactionPayment payments = 31;
 * @return {!Array<!proto.themis.RegistrationTransactionPayment>}
 */
proto.themis.RegistrationTransaction.prototype.getPaymentsList = function() {
  return /** @type{!Array<!proto.themis.RegistrationTransactionPayment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.RegistrationTransactionPayment, 31));
};


/**
 * @param {!Array<!proto.themis.RegistrationTransactionPayment>} value
 * @return {!proto.themis.RegistrationTransaction} returns this
*/
proto.themis.RegistrationTransaction.prototype.setPaymentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 31, value);
};


/**
 * @param {!proto.themis.RegistrationTransactionPayment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.RegistrationTransactionPayment}
 */
proto.themis.RegistrationTransaction.prototype.addPayments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 31, opt_value, proto.themis.RegistrationTransactionPayment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.RegistrationTransaction} returns this
 */
proto.themis.RegistrationTransaction.prototype.clearPaymentsList = function() {
  return this.setPaymentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RegistrationTransactionPayment.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RegistrationTransactionPayment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RegistrationTransactionPayment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationTransactionPayment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    registrationTransactionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paymentId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paidOrPending: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    cdp: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paymentResult: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RegistrationTransactionPayment}
 */
proto.themis.RegistrationTransactionPayment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RegistrationTransactionPayment;
  return proto.themis.RegistrationTransactionPayment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RegistrationTransactionPayment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RegistrationTransactionPayment}
 */
proto.themis.RegistrationTransactionPayment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegistrationTransactionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaymentId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaidOrPending(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCdp(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaymentResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RegistrationTransactionPayment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RegistrationTransactionPayment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RegistrationTransactionPayment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationTransactionPayment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRegistrationTransactionId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPaymentId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPaidOrPending();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCdp();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaymentResult();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.RegistrationTransactionPayment.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransactionPayment} returns this
 */
proto.themis.RegistrationTransactionPayment.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 registration_transaction_id = 2;
 * @return {number}
 */
proto.themis.RegistrationTransactionPayment.prototype.getRegistrationTransactionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransactionPayment} returns this
 */
proto.themis.RegistrationTransactionPayment.prototype.setRegistrationTransactionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 payment_id = 3;
 * @return {number}
 */
proto.themis.RegistrationTransactionPayment.prototype.getPaymentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransactionPayment} returns this
 */
proto.themis.RegistrationTransactionPayment.prototype.setPaymentId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 amount = 4;
 * @return {number}
 */
proto.themis.RegistrationTransactionPayment.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationTransactionPayment} returns this
 */
proto.themis.RegistrationTransactionPayment.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool paid_or_pending = 5;
 * @return {boolean}
 */
proto.themis.RegistrationTransactionPayment.prototype.getPaidOrPending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.RegistrationTransactionPayment} returns this
 */
proto.themis.RegistrationTransactionPayment.prototype.setPaidOrPending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string CDP = 6;
 * @return {string}
 */
proto.themis.RegistrationTransactionPayment.prototype.getCdp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegistrationTransactionPayment} returns this
 */
proto.themis.RegistrationTransactionPayment.prototype.setCdp = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool payment_result = 7;
 * @return {boolean}
 */
proto.themis.RegistrationTransactionPayment.prototype.getPaymentResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.RegistrationTransactionPayment} returns this
 */
proto.themis.RegistrationTransactionPayment.prototype.setPaymentResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDate.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startDay: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endDay: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eventId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    startClockTime: (f = msg.getStartClockTime()) && proto.themis.ClockTime.toObject(includeInstance, f),
    endClockTime: (f = msg.getEndClockTime()) && proto.themis.ClockTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDate}
 */
proto.themis.EventDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDate;
  return proto.themis.EventDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDate}
 */
proto.themis.EventDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDay(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 7:
      var value = new proto.themis.ClockTime;
      reader.readMessage(value,proto.themis.ClockTime.deserializeBinaryFromReader);
      msg.setStartClockTime(value);
      break;
    case 8:
      var value = new proto.themis.ClockTime;
      reader.readMessage(value,proto.themis.ClockTime.deserializeBinaryFromReader);
      msg.setEndClockTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStartDay();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndDay();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getStartClockTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.themis.ClockTime.serializeBinaryToWriter
    );
  }
  f = message.getEndClockTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.themis.ClockTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventDate.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDate} returns this
 */
proto.themis.EventDate.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string start_day = 2;
 * @return {string}
 */
proto.themis.EventDate.prototype.getStartDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDate} returns this
 */
proto.themis.EventDate.prototype.setStartDay = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_day = 3;
 * @return {string}
 */
proto.themis.EventDate.prototype.getEndDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDate} returns this
 */
proto.themis.EventDate.prototype.setEndDay = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 event_id = 4;
 * @return {number}
 */
proto.themis.EventDate.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDate} returns this
 */
proto.themis.EventDate.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 brand_id = 5;
 * @return {number}
 */
proto.themis.EventDate.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDate} returns this
 */
proto.themis.EventDate.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 producer_id = 6;
 * @return {number}
 */
proto.themis.EventDate.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDate} returns this
 */
proto.themis.EventDate.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional ClockTime start_clock_time = 7;
 * @return {?proto.themis.ClockTime}
 */
proto.themis.EventDate.prototype.getStartClockTime = function() {
  return /** @type{?proto.themis.ClockTime} */ (
    jspb.Message.getWrapperField(this, proto.themis.ClockTime, 7));
};


/**
 * @param {?proto.themis.ClockTime|undefined} value
 * @return {!proto.themis.EventDate} returns this
*/
proto.themis.EventDate.prototype.setStartClockTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDate} returns this
 */
proto.themis.EventDate.prototype.clearStartClockTime = function() {
  return this.setStartClockTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDate.prototype.hasStartClockTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ClockTime end_clock_time = 8;
 * @return {?proto.themis.ClockTime}
 */
proto.themis.EventDate.prototype.getEndClockTime = function() {
  return /** @type{?proto.themis.ClockTime} */ (
    jspb.Message.getWrapperField(this, proto.themis.ClockTime, 8));
};


/**
 * @param {?proto.themis.ClockTime|undefined} value
 * @return {!proto.themis.EventDate} returns this
*/
proto.themis.EventDate.prototype.setEndClockTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDate} returns this
 */
proto.themis.EventDate.prototype.clearEndClockTime = function() {
  return this.setEndClockTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDate.prototype.hasEndClockTime = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.User.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    birthDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    superuser: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    programowner: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    lastlogin: jspb.Message.getFieldWithDefault(msg, 9, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 10, ""),
    agreedToTerms: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.User}
 */
proto.themis.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.User;
  return proto.themis.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.User}
 */
proto.themis.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuperuser(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProgramowner(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastlogin(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAgreedToTerms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBirthDate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSuperuser();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getProgramowner();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getLastlogin();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAgreedToTerms();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.User} returns this
 */
proto.themis.User.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.themis.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.User} returns this
 */
proto.themis.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.themis.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.User} returns this
 */
proto.themis.User.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.themis.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.User} returns this
 */
proto.themis.User.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string birth_date = 5;
 * @return {string}
 */
proto.themis.User.prototype.getBirthDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.User} returns this
 */
proto.themis.User.prototype.setBirthDate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Email = 6;
 * @return {string}
 */
proto.themis.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.User} returns this
 */
proto.themis.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool Superuser = 7;
 * @return {boolean}
 */
proto.themis.User.prototype.getSuperuser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.User} returns this
 */
proto.themis.User.prototype.setSuperuser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool ProgramOwner = 8;
 * @return {boolean}
 */
proto.themis.User.prototype.getProgramowner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.User} returns this
 */
proto.themis.User.prototype.setProgramowner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string LastLogin = 9;
 * @return {string}
 */
proto.themis.User.prototype.getLastlogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.User} returns this
 */
proto.themis.User.prototype.setLastlogin = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string ip_address = 10;
 * @return {string}
 */
proto.themis.User.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.User} returns this
 */
proto.themis.User.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool agreed_to_terms = 11;
 * @return {boolean}
 */
proto.themis.User.prototype.getAgreedToTerms = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.User} returns this
 */
proto.themis.User.prototype.setAgreedToTerms = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Person.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Person.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Person} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Person.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    birthDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nickName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    preferredFirstName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    legalFirstName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    legalMiddleName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    legalLastName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    suffix: jspb.Message.getFieldWithDefault(msg, 10, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 11, 0),
    cellPhone: jspb.Message.getFieldWithDefault(msg, 12, ""),
    homePhone: jspb.Message.getFieldWithDefault(msg, 13, ""),
    address1: jspb.Message.getFieldWithDefault(msg, 14, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 15, ""),
    city: jspb.Message.getFieldWithDefault(msg, 17, ""),
    country: jspb.Message.getFieldWithDefault(msg, 18, ""),
    state: jspb.Message.getFieldWithDefault(msg, 19, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 20, ""),
    hasBirthCertificate: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    user: (f = msg.getUser()) && proto.themis.User.toObject(includeInstance, f),
    usasfProfilePicture: jspb.Message.getFieldWithDefault(msg, 23, ""),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 24, ""),
    usasfPersonId: jspb.Message.getFieldWithDefault(msg, 25, ""),
    title: jspb.Message.getFieldWithDefault(msg, 26, ""),
    isUsasfGuardian: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    isInternal: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    isBirthdayEdited: jspb.Message.getBooleanFieldWithDefault(msg, 29, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Person}
 */
proto.themis.Person.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Person;
  return proto.themis.Person.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Person} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Person}
 */
proto.themis.Person.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreferredFirstName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalFirstName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalMiddleName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalLastName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuffix(value);
      break;
    case 11:
      var value = /** @type {!proto.themis.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCellPhone(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomePhone(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBirthCertificate(value);
      break;
    case 22:
      var value = new proto.themis.User;
      reader.readMessage(value,proto.themis.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsasfProfilePicture(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsasfPersonId(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsUsasfGuardian(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInternal(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBirthdayEdited(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Person.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Person.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Person} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Person.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBirthDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNickName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPreferredFirstName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLegalFirstName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLegalMiddleName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLegalLastName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSuffix();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getCellPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getHomePhone();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getHasBirthCertificate();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.themis.User.serializeBinaryToWriter
    );
  }
  f = message.getUsasfProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getUsasfPersonId();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getIsUsasfGuardian();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getIsInternal();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getIsBirthdayEdited();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Person.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Person.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.themis.Person.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string birth_date = 4;
 * @return {string}
 */
proto.themis.Person.prototype.getBirthDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setBirthDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string nick_name = 5;
 * @return {string}
 */
proto.themis.Person.prototype.getNickName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setNickName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string preferred_first_name = 6;
 * @return {string}
 */
proto.themis.Person.prototype.getPreferredFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setPreferredFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string legal_first_name = 7;
 * @return {string}
 */
proto.themis.Person.prototype.getLegalFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setLegalFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string legal_middle_name = 8;
 * @return {string}
 */
proto.themis.Person.prototype.getLegalMiddleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setLegalMiddleName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string legal_last_name = 9;
 * @return {string}
 */
proto.themis.Person.prototype.getLegalLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setLegalLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string suffix = 10;
 * @return {string}
 */
proto.themis.Person.prototype.getSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setSuffix = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Gender gender = 11;
 * @return {!proto.themis.Gender}
 */
proto.themis.Person.prototype.getGender = function() {
  return /** @type {!proto.themis.Gender} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.themis.Gender} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string cell_phone = 12;
 * @return {string}
 */
proto.themis.Person.prototype.getCellPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setCellPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string home_phone = 13;
 * @return {string}
 */
proto.themis.Person.prototype.getHomePhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setHomePhone = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string address1 = 14;
 * @return {string}
 */
proto.themis.Person.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string address2 = 15;
 * @return {string}
 */
proto.themis.Person.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string city = 17;
 * @return {string}
 */
proto.themis.Person.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string country = 18;
 * @return {string}
 */
proto.themis.Person.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string state = 19;
 * @return {string}
 */
proto.themis.Person.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string postal_code = 20;
 * @return {string}
 */
proto.themis.Person.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool has_birth_certificate = 21;
 * @return {boolean}
 */
proto.themis.Person.prototype.getHasBirthCertificate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setHasBirthCertificate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional User user = 22;
 * @return {?proto.themis.User}
 */
proto.themis.Person.prototype.getUser = function() {
  return /** @type{?proto.themis.User} */ (
    jspb.Message.getWrapperField(this, proto.themis.User, 22));
};


/**
 * @param {?proto.themis.User|undefined} value
 * @return {!proto.themis.Person} returns this
*/
proto.themis.Person.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Person.prototype.hasUser = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string usasf_profile_picture = 23;
 * @return {string}
 */
proto.themis.Person.prototype.getUsasfProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setUsasfProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string last_scrape = 24;
 * @return {string}
 */
proto.themis.Person.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string usasf_person_id = 25;
 * @return {string}
 */
proto.themis.Person.prototype.getUsasfPersonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setUsasfPersonId = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string title = 26;
 * @return {string}
 */
proto.themis.Person.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional bool is_usasf_guardian = 27;
 * @return {boolean}
 */
proto.themis.Person.prototype.getIsUsasfGuardian = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setIsUsasfGuardian = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool is_internal = 28;
 * @return {boolean}
 */
proto.themis.Person.prototype.getIsInternal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setIsInternal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional bool is_birthday_edited = 29;
 * @return {boolean}
 */
proto.themis.Person.prototype.getIsBirthdayEdited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.Person} returns this
 */
proto.themis.Person.prototype.setIsBirthdayEdited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Athlete.repeatedFields_ = [4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Athlete.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Athlete.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Athlete} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Athlete.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    person: (f = msg.getPerson()) && proto.themis.Person.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    athleteTypesList: jspb.Message.toObjectList(msg.getAthleteTypesList(),
    proto.themis.AllStarType.toObject, includeInstance),
    athleteTiersList: jspb.Message.toObjectList(msg.getAthleteTiersList(),
    proto.themis.Tier.toObject, includeInstance),
    athleteGuardiansList: jspb.Message.toObjectList(msg.getAthleteGuardiansList(),
    proto.themis.Guardian.toObject, includeInstance),
    athleteLocationsList: jspb.Message.toObjectList(msg.getAthleteLocationsList(),
    proto.themis.Location.toObject, includeInstance),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 8, ""),
    usasfAthleteId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    validateTeamId: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Athlete}
 */
proto.themis.Athlete.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Athlete;
  return proto.themis.Athlete.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Athlete} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Athlete}
 */
proto.themis.Athlete.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.Person;
      reader.readMessage(value,proto.themis.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new proto.themis.AllStarType;
      reader.readMessage(value,proto.themis.AllStarType.deserializeBinaryFromReader);
      msg.addAthleteTypes(value);
      break;
    case 5:
      var value = new proto.themis.Tier;
      reader.readMessage(value,proto.themis.Tier.deserializeBinaryFromReader);
      msg.addAthleteTiers(value);
      break;
    case 6:
      var value = new proto.themis.Guardian;
      reader.readMessage(value,proto.themis.Guardian.deserializeBinaryFromReader);
      msg.addAthleteGuardians(value);
      break;
    case 7:
      var value = new proto.themis.Location;
      reader.readMessage(value,proto.themis.Location.deserializeBinaryFromReader);
      msg.addAthleteLocations(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsasfAthleteId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValidateTeamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Athlete.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Athlete.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Athlete} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Athlete.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.Person.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAthleteTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.themis.AllStarType.serializeBinaryToWriter
    );
  }
  f = message.getAthleteTiersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.themis.Tier.serializeBinaryToWriter
    );
  }
  f = message.getAthleteGuardiansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.themis.Guardian.serializeBinaryToWriter
    );
  }
  f = message.getAthleteLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.themis.Location.serializeBinaryToWriter
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUsasfAthleteId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getValidateTeamId();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Athlete.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Person person = 2;
 * @return {?proto.themis.Person}
 */
proto.themis.Athlete.prototype.getPerson = function() {
  return /** @type{?proto.themis.Person} */ (
    jspb.Message.getWrapperField(this, proto.themis.Person, 2));
};


/**
 * @param {?proto.themis.Person|undefined} value
 * @return {!proto.themis.Athlete} returns this
*/
proto.themis.Athlete.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Athlete.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.themis.Athlete.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AllStarType athlete_types = 4;
 * @return {!Array<!proto.themis.AllStarType>}
 */
proto.themis.Athlete.prototype.getAthleteTypesList = function() {
  return /** @type{!Array<!proto.themis.AllStarType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.AllStarType, 4));
};


/**
 * @param {!Array<!proto.themis.AllStarType>} value
 * @return {!proto.themis.Athlete} returns this
*/
proto.themis.Athlete.prototype.setAthleteTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.AllStarType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.AllStarType}
 */
proto.themis.Athlete.prototype.addAthleteTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.AllStarType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.clearAthleteTypesList = function() {
  return this.setAthleteTypesList([]);
};


/**
 * repeated Tier athlete_tiers = 5;
 * @return {!Array<!proto.themis.Tier>}
 */
proto.themis.Athlete.prototype.getAthleteTiersList = function() {
  return /** @type{!Array<!proto.themis.Tier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Tier, 5));
};


/**
 * @param {!Array<!proto.themis.Tier>} value
 * @return {!proto.themis.Athlete} returns this
*/
proto.themis.Athlete.prototype.setAthleteTiersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.themis.Tier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Tier}
 */
proto.themis.Athlete.prototype.addAthleteTiers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.themis.Tier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.clearAthleteTiersList = function() {
  return this.setAthleteTiersList([]);
};


/**
 * repeated Guardian athlete_guardians = 6;
 * @return {!Array<!proto.themis.Guardian>}
 */
proto.themis.Athlete.prototype.getAthleteGuardiansList = function() {
  return /** @type{!Array<!proto.themis.Guardian>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Guardian, 6));
};


/**
 * @param {!Array<!proto.themis.Guardian>} value
 * @return {!proto.themis.Athlete} returns this
*/
proto.themis.Athlete.prototype.setAthleteGuardiansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.themis.Guardian=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Guardian}
 */
proto.themis.Athlete.prototype.addAthleteGuardians = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.themis.Guardian, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.clearAthleteGuardiansList = function() {
  return this.setAthleteGuardiansList([]);
};


/**
 * repeated Location athlete_locations = 7;
 * @return {!Array<!proto.themis.Location>}
 */
proto.themis.Athlete.prototype.getAthleteLocationsList = function() {
  return /** @type{!Array<!proto.themis.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Location, 7));
};


/**
 * @param {!Array<!proto.themis.Location>} value
 * @return {!proto.themis.Athlete} returns this
*/
proto.themis.Athlete.prototype.setAthleteLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.themis.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Location}
 */
proto.themis.Athlete.prototype.addAthleteLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.themis.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.clearAthleteLocationsList = function() {
  return this.setAthleteLocationsList([]);
};


/**
 * optional string last_scrape = 8;
 * @return {string}
 */
proto.themis.Athlete.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string usasf_athlete_id = 9;
 * @return {string}
 */
proto.themis.Athlete.prototype.getUsasfAthleteId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.setUsasfAthleteId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint32 program_id = 10;
 * @return {number}
 */
proto.themis.Athlete.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 location_id = 11;
 * @return {number}
 */
proto.themis.Athlete.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 validate_team_id = 12;
 * @return {number}
 */
proto.themis.Athlete.prototype.getValidateTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Athlete} returns this
 */
proto.themis.Athlete.prototype.setValidateTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Coach.repeatedFields_ = [4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Coach.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Coach.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Coach} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Coach.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    person: (f = msg.getPerson()) && proto.themis.Person.toObject(includeInstance, f),
    coachTypesList: jspb.Message.toObjectList(msg.getCoachTypesList(),
    proto.themis.AllStarType.toObject, includeInstance),
    coachLocationsList: jspb.Message.toObjectList(msg.getCoachLocationsList(),
    proto.themis.Location.toObject, includeInstance),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 7, ""),
    usasfCoachId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    validateTeamId: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Coach}
 */
proto.themis.Coach.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Coach;
  return proto.themis.Coach.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Coach} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Coach}
 */
proto.themis.Coach.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.themis.Person;
      reader.readMessage(value,proto.themis.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 4:
      var value = new proto.themis.AllStarType;
      reader.readMessage(value,proto.themis.AllStarType.deserializeBinaryFromReader);
      msg.addCoachTypes(value);
      break;
    case 6:
      var value = new proto.themis.Location;
      reader.readMessage(value,proto.themis.Location.deserializeBinaryFromReader);
      msg.addCoachLocations(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsasfCoachId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValidateTeamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Coach.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Coach.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Coach} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Coach.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.Person.serializeBinaryToWriter
    );
  }
  f = message.getCoachTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.themis.AllStarType.serializeBinaryToWriter
    );
  }
  f = message.getCoachLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.themis.Location.serializeBinaryToWriter
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUsasfCoachId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getValidateTeamId();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Coach.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Coach} returns this
 */
proto.themis.Coach.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Coach.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Coach} returns this
 */
proto.themis.Coach.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Person person = 3;
 * @return {?proto.themis.Person}
 */
proto.themis.Coach.prototype.getPerson = function() {
  return /** @type{?proto.themis.Person} */ (
    jspb.Message.getWrapperField(this, proto.themis.Person, 3));
};


/**
 * @param {?proto.themis.Person|undefined} value
 * @return {!proto.themis.Coach} returns this
*/
proto.themis.Coach.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Coach} returns this
 */
proto.themis.Coach.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Coach.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated AllStarType coach_types = 4;
 * @return {!Array<!proto.themis.AllStarType>}
 */
proto.themis.Coach.prototype.getCoachTypesList = function() {
  return /** @type{!Array<!proto.themis.AllStarType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.AllStarType, 4));
};


/**
 * @param {!Array<!proto.themis.AllStarType>} value
 * @return {!proto.themis.Coach} returns this
*/
proto.themis.Coach.prototype.setCoachTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.AllStarType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.AllStarType}
 */
proto.themis.Coach.prototype.addCoachTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.AllStarType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Coach} returns this
 */
proto.themis.Coach.prototype.clearCoachTypesList = function() {
  return this.setCoachTypesList([]);
};


/**
 * repeated Location coach_locations = 6;
 * @return {!Array<!proto.themis.Location>}
 */
proto.themis.Coach.prototype.getCoachLocationsList = function() {
  return /** @type{!Array<!proto.themis.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Location, 6));
};


/**
 * @param {!Array<!proto.themis.Location>} value
 * @return {!proto.themis.Coach} returns this
*/
proto.themis.Coach.prototype.setCoachLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.themis.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Location}
 */
proto.themis.Coach.prototype.addCoachLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.themis.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Coach} returns this
 */
proto.themis.Coach.prototype.clearCoachLocationsList = function() {
  return this.setCoachLocationsList([]);
};


/**
 * optional string last_scrape = 7;
 * @return {string}
 */
proto.themis.Coach.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Coach} returns this
 */
proto.themis.Coach.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string usasf_coach_id = 8;
 * @return {string}
 */
proto.themis.Coach.prototype.getUsasfCoachId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Coach} returns this
 */
proto.themis.Coach.prototype.setUsasfCoachId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional uint32 program_id = 9;
 * @return {number}
 */
proto.themis.Coach.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Coach} returns this
 */
proto.themis.Coach.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 location_id = 10;
 * @return {number}
 */
proto.themis.Coach.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Coach} returns this
 */
proto.themis.Coach.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 validate_team_id = 11;
 * @return {number}
 */
proto.themis.Coach.prototype.getValidateTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Coach} returns this
 */
proto.themis.Coach.prototype.setValidateTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Guardian.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Guardian.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Guardian} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Guardian.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    person: (f = msg.getPerson()) && proto.themis.Person.toObject(includeInstance, f),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 4, ""),
    signature: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Guardian}
 */
proto.themis.Guardian.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Guardian;
  return proto.themis.Guardian.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Guardian} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Guardian}
 */
proto.themis.Guardian.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.themis.Person;
      reader.readMessage(value,proto.themis.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Guardian.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Guardian.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Guardian} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Guardian.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.Person.serializeBinaryToWriter
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Guardian.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Guardian} returns this
 */
proto.themis.Guardian.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Guardian.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Guardian} returns this
 */
proto.themis.Guardian.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Person person = 3;
 * @return {?proto.themis.Person}
 */
proto.themis.Guardian.prototype.getPerson = function() {
  return /** @type{?proto.themis.Person} */ (
    jspb.Message.getWrapperField(this, proto.themis.Person, 3));
};


/**
 * @param {?proto.themis.Person|undefined} value
 * @return {!proto.themis.Guardian} returns this
*/
proto.themis.Guardian.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Guardian} returns this
 */
proto.themis.Guardian.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Guardian.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string last_scrape = 4;
 * @return {string}
 */
proto.themis.Guardian.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Guardian} returns this
 */
proto.themis.Guardian.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string signature = 5;
 * @return {string}
 */
proto.themis.Guardian.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Guardian} returns this
 */
proto.themis.Guardian.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Personnel.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Personnel.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Personnel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Personnel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Personnel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    person: (f = msg.getPerson()) && proto.themis.Person.toObject(includeInstance, f),
    personnelLocationsList: jspb.Message.toObjectList(msg.getPersonnelLocationsList(),
    proto.themis.Location.toObject, includeInstance),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 5, ""),
    usasfPersonnelId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    validateTeamId: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Personnel}
 */
proto.themis.Personnel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Personnel;
  return proto.themis.Personnel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Personnel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Personnel}
 */
proto.themis.Personnel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.themis.Person;
      reader.readMessage(value,proto.themis.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 4:
      var value = new proto.themis.Location;
      reader.readMessage(value,proto.themis.Location.deserializeBinaryFromReader);
      msg.addPersonnelLocations(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsasfPersonnelId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValidateTeamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Personnel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Personnel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Personnel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Personnel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.Person.serializeBinaryToWriter
    );
  }
  f = message.getPersonnelLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.themis.Location.serializeBinaryToWriter
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUsasfPersonnelId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getValidateTeamId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Personnel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Personnel} returns this
 */
proto.themis.Personnel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Personnel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Personnel} returns this
 */
proto.themis.Personnel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Person person = 3;
 * @return {?proto.themis.Person}
 */
proto.themis.Personnel.prototype.getPerson = function() {
  return /** @type{?proto.themis.Person} */ (
    jspb.Message.getWrapperField(this, proto.themis.Person, 3));
};


/**
 * @param {?proto.themis.Person|undefined} value
 * @return {!proto.themis.Personnel} returns this
*/
proto.themis.Personnel.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Personnel} returns this
 */
proto.themis.Personnel.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Personnel.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Location personnel_locations = 4;
 * @return {!Array<!proto.themis.Location>}
 */
proto.themis.Personnel.prototype.getPersonnelLocationsList = function() {
  return /** @type{!Array<!proto.themis.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Location, 4));
};


/**
 * @param {!Array<!proto.themis.Location>} value
 * @return {!proto.themis.Personnel} returns this
*/
proto.themis.Personnel.prototype.setPersonnelLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Location}
 */
proto.themis.Personnel.prototype.addPersonnelLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Personnel} returns this
 */
proto.themis.Personnel.prototype.clearPersonnelLocationsList = function() {
  return this.setPersonnelLocationsList([]);
};


/**
 * optional string last_scrape = 5;
 * @return {string}
 */
proto.themis.Personnel.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Personnel} returns this
 */
proto.themis.Personnel.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string usasf_personnel_id = 6;
 * @return {string}
 */
proto.themis.Personnel.prototype.getUsasfPersonnelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Personnel} returns this
 */
proto.themis.Personnel.prototype.setUsasfPersonnelId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 program_id = 7;
 * @return {number}
 */
proto.themis.Personnel.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Personnel} returns this
 */
proto.themis.Personnel.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 location_id = 8;
 * @return {number}
 */
proto.themis.Personnel.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Personnel} returns this
 */
proto.themis.Personnel.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 validate_team_id = 9;
 * @return {number}
 */
proto.themis.Personnel.prototype.getValidateTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Personnel} returns this
 */
proto.themis.Personnel.prototype.setValidateTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AllStarType.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AllStarType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AllStarType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AllStarType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 3, ""),
    usasfAllStarTypeId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AllStarType}
 */
proto.themis.AllStarType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AllStarType;
  return proto.themis.AllStarType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AllStarType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AllStarType}
 */
proto.themis.AllStarType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsasfAllStarTypeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AllStarType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AllStarType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AllStarType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AllStarType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsasfAllStarTypeId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.AllStarType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AllStarType} returns this
 */
proto.themis.AllStarType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.AllStarType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AllStarType} returns this
 */
proto.themis.AllStarType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_scrape = 3;
 * @return {string}
 */
proto.themis.AllStarType.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AllStarType} returns this
 */
proto.themis.AllStarType.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 usasf_all_star_type_id = 4;
 * @return {number}
 */
proto.themis.AllStarType.prototype.getUsasfAllStarTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AllStarType} returns this
 */
proto.themis.AllStarType.prototype.setUsasfAllStarTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Program.repeatedFields_ = [22,23,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Program.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Program.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Program} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Program.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    website: jspb.Message.getFieldWithDefault(msg, 6, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 8, ""),
    legalName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dba: jspb.Message.getFieldWithDefault(msg, 10, ""),
    programClassification: jspb.Message.getFieldWithDefault(msg, 11, ""),
    description: jspb.Message.getFieldWithDefault(msg, 12, ""),
    address: jspb.Message.getFieldWithDefault(msg, 13, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 14, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 15, ""),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 16, ""),
    usasfProgramId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    title: jspb.Message.getFieldWithDefault(msg, 18, ""),
    secondaryPhone: jspb.Message.getFieldWithDefault(msg, 19, ""),
    cellPhone: jspb.Message.getFieldWithDefault(msg, 20, ""),
    email: jspb.Message.getFieldWithDefault(msg, 21, ""),
    allStarTypesList: jspb.Message.toObjectList(msg.getAllStarTypesList(),
    proto.themis.AllStarType.toObject, includeInstance),
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.themis.Location.toObject, includeInstance),
    expireDate: jspb.Message.getFieldWithDefault(msg, 24, ""),
    allowedProducersList: jspb.Message.toObjectList(msg.getAllowedProducersList(),
    proto.themis.ProgramAllowedProducer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Program}
 */
proto.themis.Program.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Program;
  return proto.themis.Program.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Program} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Program}
 */
proto.themis.Program.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDba(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramClassification(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsasfProgramId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryPhone(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCellPhone(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 22:
      var value = new proto.themis.AllStarType;
      reader.readMessage(value,proto.themis.AllStarType.deserializeBinaryFromReader);
      msg.addAllStarTypes(value);
      break;
    case 23:
      var value = new proto.themis.Location;
      reader.readMessage(value,proto.themis.Location.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpireDate(value);
      break;
    case 25:
      var value = new proto.themis.ProgramAllowedProducer;
      reader.readMessage(value,proto.themis.ProgramAllowedProducer.deserializeBinaryFromReader);
      msg.addAllowedProducers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Program.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Program.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Program} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Program.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLegalName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDba();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getProgramClassification();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getUsasfProgramId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSecondaryPhone();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCellPhone();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getAllStarTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.themis.AllStarType.serializeBinaryToWriter
    );
  }
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.themis.Location.serializeBinaryToWriter
    );
  }
  f = message.getExpireDate();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAllowedProducersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.themis.ProgramAllowedProducer.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Program.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Program.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.themis.Program.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.themis.Program.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.themis.Program.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string website = 6;
 * @return {string}
 */
proto.themis.Program.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string logo = 7;
 * @return {string}
 */
proto.themis.Program.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone = 8;
 * @return {string}
 */
proto.themis.Program.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string legal_name = 9;
 * @return {string}
 */
proto.themis.Program.prototype.getLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setLegalName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string dba = 10;
 * @return {string}
 */
proto.themis.Program.prototype.getDba = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setDba = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string program_classification = 11;
 * @return {string}
 */
proto.themis.Program.prototype.getProgramClassification = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setProgramClassification = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string description = 12;
 * @return {string}
 */
proto.themis.Program.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string address = 13;
 * @return {string}
 */
proto.themis.Program.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string address2 = 14;
 * @return {string}
 */
proto.themis.Program.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string postal_code = 15;
 * @return {string}
 */
proto.themis.Program.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string last_scrape = 16;
 * @return {string}
 */
proto.themis.Program.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string usasf_program_id = 17;
 * @return {string}
 */
proto.themis.Program.prototype.getUsasfProgramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setUsasfProgramId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string title = 18;
 * @return {string}
 */
proto.themis.Program.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string secondary_phone = 19;
 * @return {string}
 */
proto.themis.Program.prototype.getSecondaryPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setSecondaryPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string cell_phone = 20;
 * @return {string}
 */
proto.themis.Program.prototype.getCellPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setCellPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string email = 21;
 * @return {string}
 */
proto.themis.Program.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated AllStarType all_star_types = 22;
 * @return {!Array<!proto.themis.AllStarType>}
 */
proto.themis.Program.prototype.getAllStarTypesList = function() {
  return /** @type{!Array<!proto.themis.AllStarType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.AllStarType, 22));
};


/**
 * @param {!Array<!proto.themis.AllStarType>} value
 * @return {!proto.themis.Program} returns this
*/
proto.themis.Program.prototype.setAllStarTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.themis.AllStarType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.AllStarType}
 */
proto.themis.Program.prototype.addAllStarTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.themis.AllStarType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.clearAllStarTypesList = function() {
  return this.setAllStarTypesList([]);
};


/**
 * repeated Location locations = 23;
 * @return {!Array<!proto.themis.Location>}
 */
proto.themis.Program.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.themis.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Location, 23));
};


/**
 * @param {!Array<!proto.themis.Location>} value
 * @return {!proto.themis.Program} returns this
*/
proto.themis.Program.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.themis.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Location}
 */
proto.themis.Program.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.themis.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};


/**
 * optional string expire_date = 24;
 * @return {string}
 */
proto.themis.Program.prototype.getExpireDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.setExpireDate = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated ProgramAllowedProducer allowed_producers = 25;
 * @return {!Array<!proto.themis.ProgramAllowedProducer>}
 */
proto.themis.Program.prototype.getAllowedProducersList = function() {
  return /** @type{!Array<!proto.themis.ProgramAllowedProducer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.ProgramAllowedProducer, 25));
};


/**
 * @param {!Array<!proto.themis.ProgramAllowedProducer>} value
 * @return {!proto.themis.Program} returns this
*/
proto.themis.Program.prototype.setAllowedProducersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.themis.ProgramAllowedProducer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.ProgramAllowedProducer}
 */
proto.themis.Program.prototype.addAllowedProducers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.themis.ProgramAllowedProducer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Program} returns this
 */
proto.themis.Program.prototype.clearAllowedProducersList = function() {
  return this.setAllowedProducersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Location.repeatedFields_ = [16,17,18,19,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 7, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 9, ""),
    email: jspb.Message.getFieldWithDefault(msg, 10, ""),
    cellPhone: jspb.Message.getFieldWithDefault(msg, 11, ""),
    secondaryPhone: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 13, ""),
    usasfLocationId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    program: (f = msg.getProgram()) && proto.themis.Program.toObject(includeInstance, f),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.themis.Team.toObject, includeInstance),
    athletesList: jspb.Message.toObjectList(msg.getAthletesList(),
    proto.themis.Athlete.toObject, includeInstance),
    coachesList: jspb.Message.toObjectList(msg.getCoachesList(),
    proto.themis.Coach.toObject, includeInstance),
    personnelList: jspb.Message.toObjectList(msg.getPersonnelList(),
    proto.themis.Personnel.toObject, includeInstance),
    programId: jspb.Message.getFieldWithDefault(msg, 20, 0),
    guardiansList: jspb.Message.toObjectList(msg.getGuardiansList(),
    proto.themis.Guardian.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Location}
 */
proto.themis.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Location;
  return proto.themis.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Location}
 */
proto.themis.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCellPhone(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondaryPhone(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsasfLocationId(value);
      break;
    case 15:
      var value = new proto.themis.Program;
      reader.readMessage(value,proto.themis.Program.deserializeBinaryFromReader);
      msg.setProgram(value);
      break;
    case 16:
      var value = new proto.themis.Team;
      reader.readMessage(value,proto.themis.Team.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    case 17:
      var value = new proto.themis.Athlete;
      reader.readMessage(value,proto.themis.Athlete.deserializeBinaryFromReader);
      msg.addAthletes(value);
      break;
    case 18:
      var value = new proto.themis.Coach;
      reader.readMessage(value,proto.themis.Coach.deserializeBinaryFromReader);
      msg.addCoaches(value);
      break;
    case 19:
      var value = new proto.themis.Personnel;
      reader.readMessage(value,proto.themis.Personnel.deserializeBinaryFromReader);
      msg.addPersonnel(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 21:
      var value = new proto.themis.Guardian;
      reader.readMessage(value,proto.themis.Guardian.deserializeBinaryFromReader);
      msg.addGuardians(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCellPhone();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSecondaryPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUsasfLocationId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getProgram();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.themis.Program.serializeBinaryToWriter
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.themis.Team.serializeBinaryToWriter
    );
  }
  f = message.getAthletesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.themis.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getCoachesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.themis.Coach.serializeBinaryToWriter
    );
  }
  f = message.getPersonnelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.themis.Personnel.serializeBinaryToWriter
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
  f = message.getGuardiansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.themis.Guardian.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Location.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Location.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.themis.Location.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.themis.Location.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.themis.Location.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address = 6;
 * @return {string}
 */
proto.themis.Location.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address2 = 7;
 * @return {string}
 */
proto.themis.Location.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string postal_code = 8;
 * @return {string}
 */
proto.themis.Location.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string phone = 9;
 * @return {string}
 */
proto.themis.Location.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string email = 10;
 * @return {string}
 */
proto.themis.Location.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string cell_phone = 11;
 * @return {string}
 */
proto.themis.Location.prototype.getCellPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setCellPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string secondary_phone = 12;
 * @return {string}
 */
proto.themis.Location.prototype.getSecondaryPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setSecondaryPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string last_scrape = 13;
 * @return {string}
 */
proto.themis.Location.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string usasf_location_id = 14;
 * @return {string}
 */
proto.themis.Location.prototype.getUsasfLocationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setUsasfLocationId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional Program program = 15;
 * @return {?proto.themis.Program}
 */
proto.themis.Location.prototype.getProgram = function() {
  return /** @type{?proto.themis.Program} */ (
    jspb.Message.getWrapperField(this, proto.themis.Program, 15));
};


/**
 * @param {?proto.themis.Program|undefined} value
 * @return {!proto.themis.Location} returns this
*/
proto.themis.Location.prototype.setProgram = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.clearProgram = function() {
  return this.setProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Location.prototype.hasProgram = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated Team teams = 16;
 * @return {!Array<!proto.themis.Team>}
 */
proto.themis.Location.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.themis.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Team, 16));
};


/**
 * @param {!Array<!proto.themis.Team>} value
 * @return {!proto.themis.Location} returns this
*/
proto.themis.Location.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.themis.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Team}
 */
proto.themis.Location.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.themis.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};


/**
 * repeated Athlete athletes = 17;
 * @return {!Array<!proto.themis.Athlete>}
 */
proto.themis.Location.prototype.getAthletesList = function() {
  return /** @type{!Array<!proto.themis.Athlete>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Athlete, 17));
};


/**
 * @param {!Array<!proto.themis.Athlete>} value
 * @return {!proto.themis.Location} returns this
*/
proto.themis.Location.prototype.setAthletesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.themis.Athlete=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Athlete}
 */
proto.themis.Location.prototype.addAthletes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.themis.Athlete, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.clearAthletesList = function() {
  return this.setAthletesList([]);
};


/**
 * repeated Coach coaches = 18;
 * @return {!Array<!proto.themis.Coach>}
 */
proto.themis.Location.prototype.getCoachesList = function() {
  return /** @type{!Array<!proto.themis.Coach>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Coach, 18));
};


/**
 * @param {!Array<!proto.themis.Coach>} value
 * @return {!proto.themis.Location} returns this
*/
proto.themis.Location.prototype.setCoachesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.themis.Coach=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Coach}
 */
proto.themis.Location.prototype.addCoaches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.themis.Coach, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.clearCoachesList = function() {
  return this.setCoachesList([]);
};


/**
 * repeated Personnel personnel = 19;
 * @return {!Array<!proto.themis.Personnel>}
 */
proto.themis.Location.prototype.getPersonnelList = function() {
  return /** @type{!Array<!proto.themis.Personnel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Personnel, 19));
};


/**
 * @param {!Array<!proto.themis.Personnel>} value
 * @return {!proto.themis.Location} returns this
*/
proto.themis.Location.prototype.setPersonnelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.themis.Personnel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Personnel}
 */
proto.themis.Location.prototype.addPersonnel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.themis.Personnel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.clearPersonnelList = function() {
  return this.setPersonnelList([]);
};


/**
 * optional uint32 program_id = 20;
 * @return {number}
 */
proto.themis.Location.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * repeated Guardian guardians = 21;
 * @return {!Array<!proto.themis.Guardian>}
 */
proto.themis.Location.prototype.getGuardiansList = function() {
  return /** @type{!Array<!proto.themis.Guardian>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Guardian, 21));
};


/**
 * @param {!Array<!proto.themis.Guardian>} value
 * @return {!proto.themis.Location} returns this
*/
proto.themis.Location.prototype.setGuardiansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.themis.Guardian=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Guardian}
 */
proto.themis.Location.prototype.addGuardians = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.themis.Guardian, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Location} returns this
 */
proto.themis.Location.prototype.clearGuardiansList = function() {
  return this.setGuardiansList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Team.repeatedFields_ = [6,7,8,16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Team.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Team.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Team} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Team.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    location: (f = msg.getLocation()) && proto.themis.Location.toObject(includeInstance, f),
    program: (f = msg.getProgram()) && proto.themis.Program.toObject(includeInstance, f),
    athletesList: jspb.Message.toObjectList(msg.getAthletesList(),
    proto.themis.Athlete.toObject, includeInstance),
    coachesList: jspb.Message.toObjectList(msg.getCoachesList(),
    proto.themis.Coach.toObject, includeInstance),
    personnelList: jspb.Message.toObjectList(msg.getPersonnelList(),
    proto.themis.Personnel.toObject, includeInstance),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 9, ""),
    usasfTeamId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tier: (f = msg.getTier()) && proto.themis.Tier.toObject(includeInstance, f),
    allStarType: (f = msg.getAllStarType()) && proto.themis.AllStarType.toObject(includeInstance, f),
    programId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    eventTeamsList: jspb.Message.toObjectList(msg.getEventTeamsList(),
    proto.themis.EventTeam.toObject, includeInstance),
    scoringAuthoritiesList: jspb.Message.toObjectList(msg.getScoringAuthoritiesList(),
    proto.themis.TeamScoringAuthority.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Team}
 */
proto.themis.Team.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Team;
  return proto.themis.Team.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Team} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Team}
 */
proto.themis.Team.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.themis.Location;
      reader.readMessage(value,proto.themis.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 5:
      var value = new proto.themis.Program;
      reader.readMessage(value,proto.themis.Program.deserializeBinaryFromReader);
      msg.setProgram(value);
      break;
    case 6:
      var value = new proto.themis.Athlete;
      reader.readMessage(value,proto.themis.Athlete.deserializeBinaryFromReader);
      msg.addAthletes(value);
      break;
    case 7:
      var value = new proto.themis.Coach;
      reader.readMessage(value,proto.themis.Coach.deserializeBinaryFromReader);
      msg.addCoaches(value);
      break;
    case 8:
      var value = new proto.themis.Personnel;
      reader.readMessage(value,proto.themis.Personnel.deserializeBinaryFromReader);
      msg.addPersonnel(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsasfTeamId(value);
      break;
    case 11:
      var value = new proto.themis.Tier;
      reader.readMessage(value,proto.themis.Tier.deserializeBinaryFromReader);
      msg.setTier(value);
      break;
    case 12:
      var value = new proto.themis.AllStarType;
      reader.readMessage(value,proto.themis.AllStarType.deserializeBinaryFromReader);
      msg.setAllStarType(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 16:
      var value = new proto.themis.EventTeam;
      reader.readMessage(value,proto.themis.EventTeam.deserializeBinaryFromReader);
      msg.addEventTeams(value);
      break;
    case 17:
      var value = new proto.themis.TeamScoringAuthority;
      reader.readMessage(value,proto.themis.TeamScoringAuthority.deserializeBinaryFromReader);
      msg.addScoringAuthorities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Team.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Team.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Team} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Team.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.themis.Location.serializeBinaryToWriter
    );
  }
  f = message.getProgram();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.themis.Program.serializeBinaryToWriter
    );
  }
  f = message.getAthletesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.themis.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getCoachesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.themis.Coach.serializeBinaryToWriter
    );
  }
  f = message.getPersonnelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.themis.Personnel.serializeBinaryToWriter
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUsasfTeamId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTier();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.themis.Tier.serializeBinaryToWriter
    );
  }
  f = message.getAllStarType();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.themis.AllStarType.serializeBinaryToWriter
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getEventTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.themis.EventTeam.serializeBinaryToWriter
    );
  }
  f = message.getScoringAuthoritiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.themis.TeamScoringAuthority.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Team.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Team.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.themis.Team.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Location location = 4;
 * @return {?proto.themis.Location}
 */
proto.themis.Team.prototype.getLocation = function() {
  return /** @type{?proto.themis.Location} */ (
    jspb.Message.getWrapperField(this, proto.themis.Location, 4));
};


/**
 * @param {?proto.themis.Location|undefined} value
 * @return {!proto.themis.Team} returns this
*/
proto.themis.Team.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Team.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Program program = 5;
 * @return {?proto.themis.Program}
 */
proto.themis.Team.prototype.getProgram = function() {
  return /** @type{?proto.themis.Program} */ (
    jspb.Message.getWrapperField(this, proto.themis.Program, 5));
};


/**
 * @param {?proto.themis.Program|undefined} value
 * @return {!proto.themis.Team} returns this
*/
proto.themis.Team.prototype.setProgram = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.clearProgram = function() {
  return this.setProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Team.prototype.hasProgram = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Athlete athletes = 6;
 * @return {!Array<!proto.themis.Athlete>}
 */
proto.themis.Team.prototype.getAthletesList = function() {
  return /** @type{!Array<!proto.themis.Athlete>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Athlete, 6));
};


/**
 * @param {!Array<!proto.themis.Athlete>} value
 * @return {!proto.themis.Team} returns this
*/
proto.themis.Team.prototype.setAthletesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.themis.Athlete=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Athlete}
 */
proto.themis.Team.prototype.addAthletes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.themis.Athlete, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.clearAthletesList = function() {
  return this.setAthletesList([]);
};


/**
 * repeated Coach coaches = 7;
 * @return {!Array<!proto.themis.Coach>}
 */
proto.themis.Team.prototype.getCoachesList = function() {
  return /** @type{!Array<!proto.themis.Coach>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Coach, 7));
};


/**
 * @param {!Array<!proto.themis.Coach>} value
 * @return {!proto.themis.Team} returns this
*/
proto.themis.Team.prototype.setCoachesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.themis.Coach=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Coach}
 */
proto.themis.Team.prototype.addCoaches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.themis.Coach, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.clearCoachesList = function() {
  return this.setCoachesList([]);
};


/**
 * repeated Personnel personnel = 8;
 * @return {!Array<!proto.themis.Personnel>}
 */
proto.themis.Team.prototype.getPersonnelList = function() {
  return /** @type{!Array<!proto.themis.Personnel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Personnel, 8));
};


/**
 * @param {!Array<!proto.themis.Personnel>} value
 * @return {!proto.themis.Team} returns this
*/
proto.themis.Team.prototype.setPersonnelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.themis.Personnel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Personnel}
 */
proto.themis.Team.prototype.addPersonnel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.themis.Personnel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.clearPersonnelList = function() {
  return this.setPersonnelList([]);
};


/**
 * optional string last_scrape = 9;
 * @return {string}
 */
proto.themis.Team.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string usasf_team_id = 10;
 * @return {string}
 */
proto.themis.Team.prototype.getUsasfTeamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.setUsasfTeamId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Tier tier = 11;
 * @return {?proto.themis.Tier}
 */
proto.themis.Team.prototype.getTier = function() {
  return /** @type{?proto.themis.Tier} */ (
    jspb.Message.getWrapperField(this, proto.themis.Tier, 11));
};


/**
 * @param {?proto.themis.Tier|undefined} value
 * @return {!proto.themis.Team} returns this
*/
proto.themis.Team.prototype.setTier = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.clearTier = function() {
  return this.setTier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Team.prototype.hasTier = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional AllStarType all_star_type = 12;
 * @return {?proto.themis.AllStarType}
 */
proto.themis.Team.prototype.getAllStarType = function() {
  return /** @type{?proto.themis.AllStarType} */ (
    jspb.Message.getWrapperField(this, proto.themis.AllStarType, 12));
};


/**
 * @param {?proto.themis.AllStarType|undefined} value
 * @return {!proto.themis.Team} returns this
*/
proto.themis.Team.prototype.setAllStarType = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.clearAllStarType = function() {
  return this.setAllStarType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Team.prototype.hasAllStarType = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional uint32 program_id = 14;
 * @return {number}
 */
proto.themis.Team.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 location_id = 15;
 * @return {number}
 */
proto.themis.Team.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated EventTeam event_teams = 16;
 * @return {!Array<!proto.themis.EventTeam>}
 */
proto.themis.Team.prototype.getEventTeamsList = function() {
  return /** @type{!Array<!proto.themis.EventTeam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventTeam, 16));
};


/**
 * @param {!Array<!proto.themis.EventTeam>} value
 * @return {!proto.themis.Team} returns this
*/
proto.themis.Team.prototype.setEventTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.themis.EventTeam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventTeam}
 */
proto.themis.Team.prototype.addEventTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.themis.EventTeam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.clearEventTeamsList = function() {
  return this.setEventTeamsList([]);
};


/**
 * repeated TeamScoringAuthority scoring_authorities = 17;
 * @return {!Array<!proto.themis.TeamScoringAuthority>}
 */
proto.themis.Team.prototype.getScoringAuthoritiesList = function() {
  return /** @type{!Array<!proto.themis.TeamScoringAuthority>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.TeamScoringAuthority, 17));
};


/**
 * @param {!Array<!proto.themis.TeamScoringAuthority>} value
 * @return {!proto.themis.Team} returns this
*/
proto.themis.Team.prototype.setScoringAuthoritiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.themis.TeamScoringAuthority=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.TeamScoringAuthority}
 */
proto.themis.Team.prototype.addScoringAuthorities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.themis.TeamScoringAuthority, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Team} returns this
 */
proto.themis.Team.prototype.clearScoringAuthoritiesList = function() {
  return this.setScoringAuthoritiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventTeam.repeatedFields_ = [10,11,12,24];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventTeam.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventTeam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventTeam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeam.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    teamId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    programName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    event: (f = msg.getEvent()) && proto.themis.Event.toObject(includeInstance, f),
    eventDivision: (f = msg.getEventDivision()) && proto.themis.EventDivision.toObject(includeInstance, f),
    athletesList: jspb.Message.toObjectList(msg.getAthletesList(),
    proto.themis.Athlete.toObject, includeInstance),
    coachesList: jspb.Message.toObjectList(msg.getCoachesList(),
    proto.themis.Coach.toObject, includeInstance),
    personnelList: jspb.Message.toObjectList(msg.getPersonnelList(),
    proto.themis.Personnel.toObject, includeInstance),
    eventRegistrationCode: (f = msg.getEventRegistrationCode()) && proto.themis.EventRegistrationCode.toObject(includeInstance, f),
    totalPaid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    program: (f = msg.getProgram()) && proto.themis.Program.toObject(includeInstance, f),
    location: (f = msg.getLocation()) && proto.themis.Location.toObject(includeInstance, f),
    paid: jspb.Message.getFieldWithDefault(msg, 17, 0),
    remainingAmount: jspb.Message.getFieldWithDefault(msg, 18, 0),
    remainingDeposit: jspb.Message.getFieldWithDefault(msg, 19, 0),
    signed: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    deposit: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    allPaid: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    isProducerUpdate: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    registrationTransactionsList: jspb.Message.toObjectList(msg.getRegistrationTransactionsList(),
    proto.themis.RegistrationTransaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventTeam}
 */
proto.themis.EventTeam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventTeam;
  return proto.themis.EventTeam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventTeam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventTeam}
 */
proto.themis.EventTeam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocationId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 8:
      var value = new proto.themis.Event;
      reader.readMessage(value,proto.themis.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 9:
      var value = new proto.themis.EventDivision;
      reader.readMessage(value,proto.themis.EventDivision.deserializeBinaryFromReader);
      msg.setEventDivision(value);
      break;
    case 10:
      var value = new proto.themis.Athlete;
      reader.readMessage(value,proto.themis.Athlete.deserializeBinaryFromReader);
      msg.addAthletes(value);
      break;
    case 11:
      var value = new proto.themis.Coach;
      reader.readMessage(value,proto.themis.Coach.deserializeBinaryFromReader);
      msg.addCoaches(value);
      break;
    case 12:
      var value = new proto.themis.Personnel;
      reader.readMessage(value,proto.themis.Personnel.deserializeBinaryFromReader);
      msg.addPersonnel(value);
      break;
    case 13:
      var value = new proto.themis.EventRegistrationCode;
      reader.readMessage(value,proto.themis.EventRegistrationCode.deserializeBinaryFromReader);
      msg.setEventRegistrationCode(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPaid(value);
      break;
    case 15:
      var value = new proto.themis.Program;
      reader.readMessage(value,proto.themis.Program.deserializeBinaryFromReader);
      msg.setProgram(value);
      break;
    case 16:
      var value = new proto.themis.Location;
      reader.readMessage(value,proto.themis.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaid(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRemainingAmount(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRemainingDeposit(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSigned(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeposit(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllPaid(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsProducerUpdate(value);
      break;
    case 24:
      var value = new proto.themis.RegistrationTransaction;
      reader.readMessage(value,proto.themis.RegistrationTransaction.deserializeBinaryFromReader);
      msg.addRegistrationTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventTeam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventTeam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventTeam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTeamId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProgramName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.themis.Event.serializeBinaryToWriter
    );
  }
  f = message.getEventDivision();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.themis.EventDivision.serializeBinaryToWriter
    );
  }
  f = message.getAthletesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.themis.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getCoachesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.themis.Coach.serializeBinaryToWriter
    );
  }
  f = message.getPersonnelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.themis.Personnel.serializeBinaryToWriter
    );
  }
  f = message.getEventRegistrationCode();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.themis.EventRegistrationCode.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaid();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getProgram();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.themis.Program.serializeBinaryToWriter
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.themis.Location.serializeBinaryToWriter
    );
  }
  f = message.getPaid();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getRemainingAmount();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getRemainingDeposit();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getSigned();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getDeposit();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getAllPaid();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getIsProducerUpdate();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getRegistrationTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.themis.RegistrationTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventTeam.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 team_id = 2;
 * @return {number}
 */
proto.themis.EventTeam.prototype.getTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 program_id = 3;
 * @return {number}
 */
proto.themis.EventTeam.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 location_id = 4;
 * @return {number}
 */
proto.themis.EventTeam.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.themis.EventTeam.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string program_name = 6;
 * @return {string}
 */
proto.themis.EventTeam.prototype.getProgramName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setProgramName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string location_name = 7;
 * @return {string}
 */
proto.themis.EventTeam.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Event event = 8;
 * @return {?proto.themis.Event}
 */
proto.themis.EventTeam.prototype.getEvent = function() {
  return /** @type{?proto.themis.Event} */ (
    jspb.Message.getWrapperField(this, proto.themis.Event, 8));
};


/**
 * @param {?proto.themis.Event|undefined} value
 * @return {!proto.themis.EventTeam} returns this
*/
proto.themis.EventTeam.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventTeam.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EventDivision event_division = 9;
 * @return {?proto.themis.EventDivision}
 */
proto.themis.EventTeam.prototype.getEventDivision = function() {
  return /** @type{?proto.themis.EventDivision} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventDivision, 9));
};


/**
 * @param {?proto.themis.EventDivision|undefined} value
 * @return {!proto.themis.EventTeam} returns this
*/
proto.themis.EventTeam.prototype.setEventDivision = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.clearEventDivision = function() {
  return this.setEventDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventTeam.prototype.hasEventDivision = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated Athlete athletes = 10;
 * @return {!Array<!proto.themis.Athlete>}
 */
proto.themis.EventTeam.prototype.getAthletesList = function() {
  return /** @type{!Array<!proto.themis.Athlete>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Athlete, 10));
};


/**
 * @param {!Array<!proto.themis.Athlete>} value
 * @return {!proto.themis.EventTeam} returns this
*/
proto.themis.EventTeam.prototype.setAthletesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.themis.Athlete=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Athlete}
 */
proto.themis.EventTeam.prototype.addAthletes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.themis.Athlete, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.clearAthletesList = function() {
  return this.setAthletesList([]);
};


/**
 * repeated Coach coaches = 11;
 * @return {!Array<!proto.themis.Coach>}
 */
proto.themis.EventTeam.prototype.getCoachesList = function() {
  return /** @type{!Array<!proto.themis.Coach>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Coach, 11));
};


/**
 * @param {!Array<!proto.themis.Coach>} value
 * @return {!proto.themis.EventTeam} returns this
*/
proto.themis.EventTeam.prototype.setCoachesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.themis.Coach=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Coach}
 */
proto.themis.EventTeam.prototype.addCoaches = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.themis.Coach, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.clearCoachesList = function() {
  return this.setCoachesList([]);
};


/**
 * repeated Personnel personnel = 12;
 * @return {!Array<!proto.themis.Personnel>}
 */
proto.themis.EventTeam.prototype.getPersonnelList = function() {
  return /** @type{!Array<!proto.themis.Personnel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Personnel, 12));
};


/**
 * @param {!Array<!proto.themis.Personnel>} value
 * @return {!proto.themis.EventTeam} returns this
*/
proto.themis.EventTeam.prototype.setPersonnelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.themis.Personnel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Personnel}
 */
proto.themis.EventTeam.prototype.addPersonnel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.themis.Personnel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.clearPersonnelList = function() {
  return this.setPersonnelList([]);
};


/**
 * optional EventRegistrationCode event_registration_code = 13;
 * @return {?proto.themis.EventRegistrationCode}
 */
proto.themis.EventTeam.prototype.getEventRegistrationCode = function() {
  return /** @type{?proto.themis.EventRegistrationCode} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventRegistrationCode, 13));
};


/**
 * @param {?proto.themis.EventRegistrationCode|undefined} value
 * @return {!proto.themis.EventTeam} returns this
*/
proto.themis.EventTeam.prototype.setEventRegistrationCode = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.clearEventRegistrationCode = function() {
  return this.setEventRegistrationCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventTeam.prototype.hasEventRegistrationCode = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional uint32 total_paid = 14;
 * @return {number}
 */
proto.themis.EventTeam.prototype.getTotalPaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setTotalPaid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional Program program = 15;
 * @return {?proto.themis.Program}
 */
proto.themis.EventTeam.prototype.getProgram = function() {
  return /** @type{?proto.themis.Program} */ (
    jspb.Message.getWrapperField(this, proto.themis.Program, 15));
};


/**
 * @param {?proto.themis.Program|undefined} value
 * @return {!proto.themis.EventTeam} returns this
*/
proto.themis.EventTeam.prototype.setProgram = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.clearProgram = function() {
  return this.setProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventTeam.prototype.hasProgram = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Location location = 16;
 * @return {?proto.themis.Location}
 */
proto.themis.EventTeam.prototype.getLocation = function() {
  return /** @type{?proto.themis.Location} */ (
    jspb.Message.getWrapperField(this, proto.themis.Location, 16));
};


/**
 * @param {?proto.themis.Location|undefined} value
 * @return {!proto.themis.EventTeam} returns this
*/
proto.themis.EventTeam.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventTeam.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional uint32 paid = 17;
 * @return {number}
 */
proto.themis.EventTeam.prototype.getPaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setPaid = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional uint32 remaining_amount = 18;
 * @return {number}
 */
proto.themis.EventTeam.prototype.getRemainingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setRemainingAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional uint32 remaining_deposit = 19;
 * @return {number}
 */
proto.themis.EventTeam.prototype.getRemainingDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setRemainingDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional bool signed = 20;
 * @return {boolean}
 */
proto.themis.EventTeam.prototype.getSigned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setSigned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool deposit = 21;
 * @return {boolean}
 */
proto.themis.EventTeam.prototype.getDeposit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool all_paid = 22;
 * @return {boolean}
 */
proto.themis.EventTeam.prototype.getAllPaid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setAllPaid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool is_producer_update = 23;
 * @return {boolean}
 */
proto.themis.EventTeam.prototype.getIsProducerUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.setIsProducerUpdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * repeated RegistrationTransaction registration_transactions = 24;
 * @return {!Array<!proto.themis.RegistrationTransaction>}
 */
proto.themis.EventTeam.prototype.getRegistrationTransactionsList = function() {
  return /** @type{!Array<!proto.themis.RegistrationTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.RegistrationTransaction, 24));
};


/**
 * @param {!Array<!proto.themis.RegistrationTransaction>} value
 * @return {!proto.themis.EventTeam} returns this
*/
proto.themis.EventTeam.prototype.setRegistrationTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.themis.RegistrationTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.RegistrationTransaction}
 */
proto.themis.EventTeam.prototype.addRegistrationTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.themis.RegistrationTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventTeam} returns this
 */
proto.themis.EventTeam.prototype.clearRegistrationTransactionsList = function() {
  return this.setRegistrationTransactionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventTeamBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventTeamBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventTeamBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeamBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
    paid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    remainingDeposit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    remainingAmount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventTeamBalance}
 */
proto.themis.EventTeamBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventTeamBalance;
  return proto.themis.EventTeamBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventTeamBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventTeamBalance}
 */
proto.themis.EventTeamBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRemainingDeposit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRemainingAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventTeamBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventTeamBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventTeamBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeamBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRemainingDeposit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRemainingAmount();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 paid = 1;
 * @return {number}
 */
proto.themis.EventTeamBalance.prototype.getPaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamBalance} returns this
 */
proto.themis.EventTeamBalance.prototype.setPaid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 remaining_deposit = 2;
 * @return {number}
 */
proto.themis.EventTeamBalance.prototype.getRemainingDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamBalance} returns this
 */
proto.themis.EventTeamBalance.prototype.setRemainingDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 remaining_amount = 3;
 * @return {number}
 */
proto.themis.EventTeamBalance.prototype.getRemainingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamBalance} returns this
 */
proto.themis.EventTeamBalance.prototype.setRemainingAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.TeamScoringAuthority.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.TeamScoringAuthority.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.TeamScoringAuthority} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamScoringAuthority.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    primary: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    team: (f = msg.getTeam()) && proto.themis.Team.toObject(includeInstance, f),
    season: (f = msg.getSeason()) && proto.themis.Season.toObject(includeInstance, f),
    division: (f = msg.getDivision()) && proto.themis.Division.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.TeamScoringAuthority}
 */
proto.themis.TeamScoringAuthority.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.TeamScoringAuthority;
  return proto.themis.TeamScoringAuthority.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.TeamScoringAuthority} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.TeamScoringAuthority}
 */
proto.themis.TeamScoringAuthority.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrimary(value);
      break;
    case 3:
      var value = new proto.themis.Team;
      reader.readMessage(value,proto.themis.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    case 4:
      var value = new proto.themis.Season;
      reader.readMessage(value,proto.themis.Season.deserializeBinaryFromReader);
      msg.setSeason(value);
      break;
    case 5:
      var value = new proto.themis.Division;
      reader.readMessage(value,proto.themis.Division.deserializeBinaryFromReader);
      msg.setDivision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.TeamScoringAuthority.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.TeamScoringAuthority.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.TeamScoringAuthority} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TeamScoringAuthority.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPrimary();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.Team.serializeBinaryToWriter
    );
  }
  f = message.getSeason();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.themis.Season.serializeBinaryToWriter
    );
  }
  f = message.getDivision();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.themis.Division.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.TeamScoringAuthority.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.TeamScoringAuthority} returns this
 */
proto.themis.TeamScoringAuthority.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool primary = 2;
 * @return {boolean}
 */
proto.themis.TeamScoringAuthority.prototype.getPrimary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.TeamScoringAuthority} returns this
 */
proto.themis.TeamScoringAuthority.prototype.setPrimary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional Team team = 3;
 * @return {?proto.themis.Team}
 */
proto.themis.TeamScoringAuthority.prototype.getTeam = function() {
  return /** @type{?proto.themis.Team} */ (
    jspb.Message.getWrapperField(this, proto.themis.Team, 3));
};


/**
 * @param {?proto.themis.Team|undefined} value
 * @return {!proto.themis.TeamScoringAuthority} returns this
*/
proto.themis.TeamScoringAuthority.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.TeamScoringAuthority} returns this
 */
proto.themis.TeamScoringAuthority.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.TeamScoringAuthority.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Season season = 4;
 * @return {?proto.themis.Season}
 */
proto.themis.TeamScoringAuthority.prototype.getSeason = function() {
  return /** @type{?proto.themis.Season} */ (
    jspb.Message.getWrapperField(this, proto.themis.Season, 4));
};


/**
 * @param {?proto.themis.Season|undefined} value
 * @return {!proto.themis.TeamScoringAuthority} returns this
*/
proto.themis.TeamScoringAuthority.prototype.setSeason = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.TeamScoringAuthority} returns this
 */
proto.themis.TeamScoringAuthority.prototype.clearSeason = function() {
  return this.setSeason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.TeamScoringAuthority.prototype.hasSeason = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Division division = 5;
 * @return {?proto.themis.Division}
 */
proto.themis.TeamScoringAuthority.prototype.getDivision = function() {
  return /** @type{?proto.themis.Division} */ (
    jspb.Message.getWrapperField(this, proto.themis.Division, 5));
};


/**
 * @param {?proto.themis.Division|undefined} value
 * @return {!proto.themis.TeamScoringAuthority} returns this
*/
proto.themis.TeamScoringAuthority.prototype.setDivision = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.TeamScoringAuthority} returns this
 */
proto.themis.TeamScoringAuthority.prototype.clearDivision = function() {
  return this.setDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.TeamScoringAuthority.prototype.hasDivision = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Division.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Division.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Division.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Division} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Division.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    allStarType: (f = msg.getAllStarType()) && proto.themis.AllStarType.toObject(includeInstance, f),
    season: (f = msg.getSeason()) && proto.themis.Season.toObject(includeInstance, f),
    tier: (f = msg.getTier()) && proto.themis.Tier.toObject(includeInstance, f),
    level: (f = msg.getLevel()) && proto.themis.DivisionLevel.toObject(includeInstance, f),
    divisionDescriptor: jspb.Message.getFieldWithDefault(msg, 4, ""),
    competitionTypeDescriptor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    levelDescriptor: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ageCategoryDescriptor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    danceCategoryDescriptor: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isDance: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 10, ""),
    usasfDivisionId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    divisionRestriction: (f = msg.getDivisionRestriction()) && proto.themis.DivisionRestriction.toObject(includeInstance, f),
    divisionSplitsList: jspb.Message.toObjectList(msg.getDivisionSplitsList(),
    proto.themis.DivisionSplit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Division}
 */
proto.themis.Division.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Division;
  return proto.themis.Division.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Division} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Division}
 */
proto.themis.Division.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.themis.AllStarType;
      reader.readMessage(value,proto.themis.AllStarType.deserializeBinaryFromReader);
      msg.setAllStarType(value);
      break;
    case 14:
      var value = new proto.themis.Season;
      reader.readMessage(value,proto.themis.Season.deserializeBinaryFromReader);
      msg.setSeason(value);
      break;
    case 15:
      var value = new proto.themis.Tier;
      reader.readMessage(value,proto.themis.Tier.deserializeBinaryFromReader);
      msg.setTier(value);
      break;
    case 16:
      var value = new proto.themis.DivisionLevel;
      reader.readMessage(value,proto.themis.DivisionLevel.deserializeBinaryFromReader);
      msg.setLevel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDivisionDescriptor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionTypeDescriptor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevelDescriptor(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgeCategoryDescriptor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDanceCategoryDescriptor(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsasfDivisionId(value);
      break;
    case 12:
      var value = new proto.themis.DivisionRestriction;
      reader.readMessage(value,proto.themis.DivisionRestriction.deserializeBinaryFromReader);
      msg.setDivisionRestriction(value);
      break;
    case 13:
      var value = new proto.themis.DivisionSplit;
      reader.readMessage(value,proto.themis.DivisionSplit.deserializeBinaryFromReader);
      msg.addDivisionSplits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Division.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Division.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Division} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Division.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAllStarType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.AllStarType.serializeBinaryToWriter
    );
  }
  f = message.getSeason();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.themis.Season.serializeBinaryToWriter
    );
  }
  f = message.getTier();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.themis.Tier.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.themis.DivisionLevel.serializeBinaryToWriter
    );
  }
  f = message.getDivisionDescriptor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCompetitionTypeDescriptor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLevelDescriptor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAgeCategoryDescriptor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDanceCategoryDescriptor();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsDance();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUsasfDivisionId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDivisionRestriction();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.themis.DivisionRestriction.serializeBinaryToWriter
    );
  }
  f = message.getDivisionSplitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.themis.DivisionSplit.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Division.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Division.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AllStarType all_star_type = 3;
 * @return {?proto.themis.AllStarType}
 */
proto.themis.Division.prototype.getAllStarType = function() {
  return /** @type{?proto.themis.AllStarType} */ (
    jspb.Message.getWrapperField(this, proto.themis.AllStarType, 3));
};


/**
 * @param {?proto.themis.AllStarType|undefined} value
 * @return {!proto.themis.Division} returns this
*/
proto.themis.Division.prototype.setAllStarType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.clearAllStarType = function() {
  return this.setAllStarType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Division.prototype.hasAllStarType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Season season = 14;
 * @return {?proto.themis.Season}
 */
proto.themis.Division.prototype.getSeason = function() {
  return /** @type{?proto.themis.Season} */ (
    jspb.Message.getWrapperField(this, proto.themis.Season, 14));
};


/**
 * @param {?proto.themis.Season|undefined} value
 * @return {!proto.themis.Division} returns this
*/
proto.themis.Division.prototype.setSeason = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.clearSeason = function() {
  return this.setSeason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Division.prototype.hasSeason = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Tier tier = 15;
 * @return {?proto.themis.Tier}
 */
proto.themis.Division.prototype.getTier = function() {
  return /** @type{?proto.themis.Tier} */ (
    jspb.Message.getWrapperField(this, proto.themis.Tier, 15));
};


/**
 * @param {?proto.themis.Tier|undefined} value
 * @return {!proto.themis.Division} returns this
*/
proto.themis.Division.prototype.setTier = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.clearTier = function() {
  return this.setTier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Division.prototype.hasTier = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional DivisionLevel level = 16;
 * @return {?proto.themis.DivisionLevel}
 */
proto.themis.Division.prototype.getLevel = function() {
  return /** @type{?proto.themis.DivisionLevel} */ (
    jspb.Message.getWrapperField(this, proto.themis.DivisionLevel, 16));
};


/**
 * @param {?proto.themis.DivisionLevel|undefined} value
 * @return {!proto.themis.Division} returns this
*/
proto.themis.Division.prototype.setLevel = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.clearLevel = function() {
  return this.setLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Division.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string division_descriptor = 4;
 * @return {string}
 */
proto.themis.Division.prototype.getDivisionDescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.setDivisionDescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string competition_type_descriptor = 5;
 * @return {string}
 */
proto.themis.Division.prototype.getCompetitionTypeDescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.setCompetitionTypeDescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string level_descriptor = 6;
 * @return {string}
 */
proto.themis.Division.prototype.getLevelDescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.setLevelDescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string age_category_descriptor = 7;
 * @return {string}
 */
proto.themis.Division.prototype.getAgeCategoryDescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.setAgeCategoryDescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string dance_category_descriptor = 8;
 * @return {string}
 */
proto.themis.Division.prototype.getDanceCategoryDescriptor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.setDanceCategoryDescriptor = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_dance = 9;
 * @return {boolean}
 */
proto.themis.Division.prototype.getIsDance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.setIsDance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string last_scrape = 10;
 * @return {string}
 */
proto.themis.Division.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string usasf_division_id = 11;
 * @return {string}
 */
proto.themis.Division.prototype.getUsasfDivisionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.setUsasfDivisionId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional DivisionRestriction division_restriction = 12;
 * @return {?proto.themis.DivisionRestriction}
 */
proto.themis.Division.prototype.getDivisionRestriction = function() {
  return /** @type{?proto.themis.DivisionRestriction} */ (
    jspb.Message.getWrapperField(this, proto.themis.DivisionRestriction, 12));
};


/**
 * @param {?proto.themis.DivisionRestriction|undefined} value
 * @return {!proto.themis.Division} returns this
*/
proto.themis.Division.prototype.setDivisionRestriction = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.clearDivisionRestriction = function() {
  return this.setDivisionRestriction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Division.prototype.hasDivisionRestriction = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated DivisionSplit division_splits = 13;
 * @return {!Array<!proto.themis.DivisionSplit>}
 */
proto.themis.Division.prototype.getDivisionSplitsList = function() {
  return /** @type{!Array<!proto.themis.DivisionSplit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.DivisionSplit, 13));
};


/**
 * @param {!Array<!proto.themis.DivisionSplit>} value
 * @return {!proto.themis.Division} returns this
*/
proto.themis.Division.prototype.setDivisionSplitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.themis.DivisionSplit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.DivisionSplit}
 */
proto.themis.Division.prototype.addDivisionSplits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.themis.DivisionSplit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Division} returns this
 */
proto.themis.Division.prototype.clearDivisionSplitsList = function() {
  return this.setDivisionSplitsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DivisionRestriction.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DivisionRestriction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DivisionRestriction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionRestriction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    division: (f = msg.getDivision()) && proto.themis.Division.toObject(includeInstance, f),
    allowedGender: jspb.Message.getFieldWithDefault(msg, 3, 0),
    minimum: jspb.Message.getFieldWithDefault(msg, 4, 0),
    maximum: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isFemaleLimited: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isMaleLimited: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    allowMaleMin: jspb.Message.getFieldWithDefault(msg, 8, 0),
    allowMaleMax: jspb.Message.getFieldWithDefault(msg, 9, 0),
    allowFemaleMax: jspb.Message.getFieldWithDefault(msg, 10, 0),
    allowFemaleMin: jspb.Message.getFieldWithDefault(msg, 11, 0),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 12, ""),
    birthStart: jspb.Message.getFieldWithDefault(msg, 13, ""),
    birthEnd: jspb.Message.getFieldWithDefault(msg, 14, ""),
    notValidateAge: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    maxDays: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DivisionRestriction}
 */
proto.themis.DivisionRestriction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DivisionRestriction;
  return proto.themis.DivisionRestriction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DivisionRestriction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DivisionRestriction}
 */
proto.themis.DivisionRestriction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.Division;
      reader.readMessage(value,proto.themis.Division.deserializeBinaryFromReader);
      msg.setDivision(value);
      break;
    case 3:
      var value = /** @type {!proto.themis.AllowedGender} */ (reader.readEnum());
      msg.setAllowedGender(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinimum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaximum(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFemaleLimited(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMaleLimited(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAllowMaleMin(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAllowMaleMax(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAllowFemaleMax(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAllowFemaleMin(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthStart(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthEnd(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotValidateAge(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DivisionRestriction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DivisionRestriction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DivisionRestriction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionRestriction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDivision();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.Division.serializeBinaryToWriter
    );
  }
  f = message.getAllowedGender();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getMinimum();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMaximum();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getIsFemaleLimited();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsMaleLimited();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAllowMaleMin();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getAllowMaleMax();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getAllowFemaleMax();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getAllowFemaleMin();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBirthStart();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBirthEnd();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getNotValidateAge();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getMaxDays();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.DivisionRestriction.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Division division = 2;
 * @return {?proto.themis.Division}
 */
proto.themis.DivisionRestriction.prototype.getDivision = function() {
  return /** @type{?proto.themis.Division} */ (
    jspb.Message.getWrapperField(this, proto.themis.Division, 2));
};


/**
 * @param {?proto.themis.Division|undefined} value
 * @return {!proto.themis.DivisionRestriction} returns this
*/
proto.themis.DivisionRestriction.prototype.setDivision = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.clearDivision = function() {
  return this.setDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DivisionRestriction.prototype.hasDivision = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AllowedGender allowed_gender = 3;
 * @return {!proto.themis.AllowedGender}
 */
proto.themis.DivisionRestriction.prototype.getAllowedGender = function() {
  return /** @type {!proto.themis.AllowedGender} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.themis.AllowedGender} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setAllowedGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 minimum = 4;
 * @return {number}
 */
proto.themis.DivisionRestriction.prototype.getMinimum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setMinimum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 maximum = 5;
 * @return {number}
 */
proto.themis.DivisionRestriction.prototype.getMaximum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setMaximum = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool is_female_limited = 6;
 * @return {boolean}
 */
proto.themis.DivisionRestriction.prototype.getIsFemaleLimited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setIsFemaleLimited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_male_limited = 7;
 * @return {boolean}
 */
proto.themis.DivisionRestriction.prototype.getIsMaleLimited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setIsMaleLimited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional uint32 allow_male_min = 8;
 * @return {number}
 */
proto.themis.DivisionRestriction.prototype.getAllowMaleMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setAllowMaleMin = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 allow_male_max = 9;
 * @return {number}
 */
proto.themis.DivisionRestriction.prototype.getAllowMaleMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setAllowMaleMax = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 allow_female_max = 10;
 * @return {number}
 */
proto.themis.DivisionRestriction.prototype.getAllowFemaleMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setAllowFemaleMax = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 allow_female_min = 11;
 * @return {number}
 */
proto.themis.DivisionRestriction.prototype.getAllowFemaleMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setAllowFemaleMin = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string last_scrape = 12;
 * @return {string}
 */
proto.themis.DivisionRestriction.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string birth_start = 13;
 * @return {string}
 */
proto.themis.DivisionRestriction.prototype.getBirthStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setBirthStart = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string birth_end = 14;
 * @return {string}
 */
proto.themis.DivisionRestriction.prototype.getBirthEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setBirthEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool not_validate_age = 15;
 * @return {boolean}
 */
proto.themis.DivisionRestriction.prototype.getNotValidateAge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setNotValidateAge = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional uint32 max_days = 16;
 * @return {number}
 */
proto.themis.DivisionRestriction.prototype.getMaxDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionRestriction} returns this
 */
proto.themis.DivisionRestriction.prototype.setMaxDays = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventFloorDate.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventFloorDate.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventFloorDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventFloorDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startDayTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endDayTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eventFloorId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    eventDateId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    judgingPanelsList: jspb.Message.toObjectList(msg.getJudgingPanelsList(),
    proto.themis.EventFloorDateJudgingPanel.toObject, includeInstance),
    rotationTime: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventFloorDate}
 */
proto.themis.EventFloorDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventFloorDate;
  return proto.themis.EventFloorDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventFloorDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventFloorDate}
 */
proto.themis.EventFloorDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDayTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDayTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventFloorId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventDateId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 11:
      var value = new proto.themis.EventFloorDateJudgingPanel;
      reader.readMessage(value,proto.themis.EventFloorDateJudgingPanel.deserializeBinaryFromReader);
      msg.addJudgingPanels(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRotationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventFloorDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventFloorDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventFloorDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStartDayTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndDayTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEventFloorId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getEventDateId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getJudgingPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.themis.EventFloorDateJudgingPanel.serializeBinaryToWriter
    );
  }
  f = message.getRotationTime();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventFloorDate.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDate} returns this
 */
proto.themis.EventFloorDate.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string start_day_time = 2;
 * @return {string}
 */
proto.themis.EventFloorDate.prototype.getStartDayTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventFloorDate} returns this
 */
proto.themis.EventFloorDate.prototype.setStartDayTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_day_time = 3;
 * @return {string}
 */
proto.themis.EventFloorDate.prototype.getEndDayTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventFloorDate} returns this
 */
proto.themis.EventFloorDate.prototype.setEndDayTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 event_floor_id = 5;
 * @return {number}
 */
proto.themis.EventFloorDate.prototype.getEventFloorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDate} returns this
 */
proto.themis.EventFloorDate.prototype.setEventFloorId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 event_id = 6;
 * @return {number}
 */
proto.themis.EventFloorDate.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDate} returns this
 */
proto.themis.EventFloorDate.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 brand_id = 7;
 * @return {number}
 */
proto.themis.EventFloorDate.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDate} returns this
 */
proto.themis.EventFloorDate.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 producer_id = 8;
 * @return {number}
 */
proto.themis.EventFloorDate.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDate} returns this
 */
proto.themis.EventFloorDate.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 event_date_id = 9;
 * @return {number}
 */
proto.themis.EventFloorDate.prototype.getEventDateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDate} returns this
 */
proto.themis.EventFloorDate.prototype.setEventDateId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool active = 10;
 * @return {boolean}
 */
proto.themis.EventFloorDate.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventFloorDate} returns this
 */
proto.themis.EventFloorDate.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated EventFloorDateJudgingPanel judging_panels = 11;
 * @return {!Array<!proto.themis.EventFloorDateJudgingPanel>}
 */
proto.themis.EventFloorDate.prototype.getJudgingPanelsList = function() {
  return /** @type{!Array<!proto.themis.EventFloorDateJudgingPanel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventFloorDateJudgingPanel, 11));
};


/**
 * @param {!Array<!proto.themis.EventFloorDateJudgingPanel>} value
 * @return {!proto.themis.EventFloorDate} returns this
*/
proto.themis.EventFloorDate.prototype.setJudgingPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventFloorDateJudgingPanel}
 */
proto.themis.EventFloorDate.prototype.addJudgingPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.themis.EventFloorDateJudgingPanel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventFloorDate} returns this
 */
proto.themis.EventFloorDate.prototype.clearJudgingPanelsList = function() {
  return this.setJudgingPanelsList([]);
};


/**
 * optional uint32 rotation_time = 12;
 * @return {number}
 */
proto.themis.EventFloorDate.prototype.getRotationTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDate} returns this
 */
proto.themis.EventFloorDate.prototype.setRotationTime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventFloor.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventFloor.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventFloor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventFloor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloor.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    daysList: jspb.Message.toObjectList(msg.getDaysList(),
    proto.themis.EventFloorDate.toObject, includeInstance),
    eventId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    event: (f = msg.getEvent()) && proto.themis.Event.toObject(includeInstance, f),
    sortOrder: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventFloor}
 */
proto.themis.EventFloor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventFloor;
  return proto.themis.EventFloor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventFloor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventFloor}
 */
proto.themis.EventFloor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.themis.EventFloorDate;
      reader.readMessage(value,proto.themis.EventFloorDate.deserializeBinaryFromReader);
      msg.addDays(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 7:
      var value = new proto.themis.Event;
      reader.readMessage(value,proto.themis.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSortOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventFloor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventFloor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventFloor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDaysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.themis.EventFloorDate.serializeBinaryToWriter
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.themis.Event.serializeBinaryToWriter
    );
  }
  f = message.getSortOrder();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventFloor.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloor} returns this
 */
proto.themis.EventFloor.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.EventFloor.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventFloor} returns this
 */
proto.themis.EventFloor.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated EventFloorDate days = 3;
 * @return {!Array<!proto.themis.EventFloorDate>}
 */
proto.themis.EventFloor.prototype.getDaysList = function() {
  return /** @type{!Array<!proto.themis.EventFloorDate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventFloorDate, 3));
};


/**
 * @param {!Array<!proto.themis.EventFloorDate>} value
 * @return {!proto.themis.EventFloor} returns this
*/
proto.themis.EventFloor.prototype.setDaysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.EventFloorDate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventFloorDate}
 */
proto.themis.EventFloor.prototype.addDays = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.EventFloorDate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventFloor} returns this
 */
proto.themis.EventFloor.prototype.clearDaysList = function() {
  return this.setDaysList([]);
};


/**
 * optional uint32 event_id = 4;
 * @return {number}
 */
proto.themis.EventFloor.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloor} returns this
 */
proto.themis.EventFloor.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 brand_id = 5;
 * @return {number}
 */
proto.themis.EventFloor.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloor} returns this
 */
proto.themis.EventFloor.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 producer_id = 6;
 * @return {number}
 */
proto.themis.EventFloor.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloor} returns this
 */
proto.themis.EventFloor.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Event event = 7;
 * @return {?proto.themis.Event}
 */
proto.themis.EventFloor.prototype.getEvent = function() {
  return /** @type{?proto.themis.Event} */ (
    jspb.Message.getWrapperField(this, proto.themis.Event, 7));
};


/**
 * @param {?proto.themis.Event|undefined} value
 * @return {!proto.themis.EventFloor} returns this
*/
proto.themis.EventFloor.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventFloor} returns this
 */
proto.themis.EventFloor.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventFloor.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 sort_order = 8;
 * @return {number}
 */
proto.themis.EventFloor.prototype.getSortOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloor} returns this
 */
proto.themis.EventFloor.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventFloorDateJudgingPanel.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventFloorDateJudgingPanel.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventFloorDateJudgingPanel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventFloorDateJudgingPanel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    shiftsList: jspb.Message.toObjectList(msg.getShiftsList(),
    proto.themis.EventFloorDateJudgingPanelShift.toObject, includeInstance),
    eventFloorDateId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventFloorDateJudgingPanel}
 */
proto.themis.EventFloorDateJudgingPanel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventFloorDateJudgingPanel;
  return proto.themis.EventFloorDateJudgingPanel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventFloorDateJudgingPanel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventFloorDateJudgingPanel}
 */
proto.themis.EventFloorDateJudgingPanel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 4:
      var value = new proto.themis.EventFloorDateJudgingPanelShift;
      reader.readMessage(value,proto.themis.EventFloorDateJudgingPanelShift.deserializeBinaryFromReader);
      msg.addShifts(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventFloorDateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventFloorDateJudgingPanel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventFloorDateJudgingPanel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventFloorDateJudgingPanel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getShiftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.themis.EventFloorDateJudgingPanelShift.serializeBinaryToWriter
    );
  }
  f = message.getEventFloorDateId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanel} returns this
 */
proto.themis.EventFloorDateJudgingPanel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.EventFloorDateJudgingPanel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventFloorDateJudgingPanel} returns this
 */
proto.themis.EventFloorDateJudgingPanel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 order = 3;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanel.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanel} returns this
 */
proto.themis.EventFloorDateJudgingPanel.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated EventFloorDateJudgingPanelShift shifts = 4;
 * @return {!Array<!proto.themis.EventFloorDateJudgingPanelShift>}
 */
proto.themis.EventFloorDateJudgingPanel.prototype.getShiftsList = function() {
  return /** @type{!Array<!proto.themis.EventFloorDateJudgingPanelShift>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventFloorDateJudgingPanelShift, 4));
};


/**
 * @param {!Array<!proto.themis.EventFloorDateJudgingPanelShift>} value
 * @return {!proto.themis.EventFloorDateJudgingPanel} returns this
*/
proto.themis.EventFloorDateJudgingPanel.prototype.setShiftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventFloorDateJudgingPanelShift}
 */
proto.themis.EventFloorDateJudgingPanel.prototype.addShifts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.EventFloorDateJudgingPanelShift, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventFloorDateJudgingPanel} returns this
 */
proto.themis.EventFloorDateJudgingPanel.prototype.clearShiftsList = function() {
  return this.setShiftsList([]);
};


/**
 * optional uint32 event_floor_date_id = 5;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanel.prototype.getEventFloorDateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanel} returns this
 */
proto.themis.EventFloorDateJudgingPanel.prototype.setEventFloorDateId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventFloorDateJudgingPanelResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventFloorDateJudgingPanelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventFloorDateJudgingPanelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventFloorDateJudgingPanelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelsList: jspb.Message.toObjectList(msg.getPanelsList(),
    proto.themis.EventFloorDateJudgingPanel.toObject, includeInstance),
    panel: (f = msg.getPanel()) && proto.themis.EventFloorDateJudgingPanel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventFloorDateJudgingPanelResponse}
 */
proto.themis.EventFloorDateJudgingPanelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventFloorDateJudgingPanelResponse;
  return proto.themis.EventFloorDateJudgingPanelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventFloorDateJudgingPanelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventFloorDateJudgingPanelResponse}
 */
proto.themis.EventFloorDateJudgingPanelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.EventFloorDateJudgingPanel;
      reader.readMessage(value,proto.themis.EventFloorDateJudgingPanel.deserializeBinaryFromReader);
      msg.addPanels(value);
      break;
    case 2:
      var value = new proto.themis.EventFloorDateJudgingPanel;
      reader.readMessage(value,proto.themis.EventFloorDateJudgingPanel.deserializeBinaryFromReader);
      msg.setPanel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventFloorDateJudgingPanelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventFloorDateJudgingPanelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventFloorDateJudgingPanelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.themis.EventFloorDateJudgingPanel.serializeBinaryToWriter
    );
  }
  f = message.getPanel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.EventFloorDateJudgingPanel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventFloorDateJudgingPanel panels = 1;
 * @return {!Array<!proto.themis.EventFloorDateJudgingPanel>}
 */
proto.themis.EventFloorDateJudgingPanelResponse.prototype.getPanelsList = function() {
  return /** @type{!Array<!proto.themis.EventFloorDateJudgingPanel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventFloorDateJudgingPanel, 1));
};


/**
 * @param {!Array<!proto.themis.EventFloorDateJudgingPanel>} value
 * @return {!proto.themis.EventFloorDateJudgingPanelResponse} returns this
*/
proto.themis.EventFloorDateJudgingPanelResponse.prototype.setPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventFloorDateJudgingPanel}
 */
proto.themis.EventFloorDateJudgingPanelResponse.prototype.addPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventFloorDateJudgingPanel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventFloorDateJudgingPanelResponse} returns this
 */
proto.themis.EventFloorDateJudgingPanelResponse.prototype.clearPanelsList = function() {
  return this.setPanelsList([]);
};


/**
 * optional EventFloorDateJudgingPanel panel = 2;
 * @return {?proto.themis.EventFloorDateJudgingPanel}
 */
proto.themis.EventFloorDateJudgingPanelResponse.prototype.getPanel = function() {
  return /** @type{?proto.themis.EventFloorDateJudgingPanel} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventFloorDateJudgingPanel, 2));
};


/**
 * @param {?proto.themis.EventFloorDateJudgingPanel|undefined} value
 * @return {!proto.themis.EventFloorDateJudgingPanelResponse} returns this
*/
proto.themis.EventFloorDateJudgingPanelResponse.prototype.setPanel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventFloorDateJudgingPanelResponse} returns this
 */
proto.themis.EventFloorDateJudgingPanelResponse.prototype.clearPanel = function() {
  return this.setPanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventFloorDateJudgingPanelResponse.prototype.hasPanel = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventFloorDateJudgingPanelShiftResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shiftsList: jspb.Message.toObjectList(msg.getShiftsList(),
    proto.themis.EventFloorDateJudgingPanelShift.toObject, includeInstance),
    shift: (f = msg.getShift()) && proto.themis.EventFloorDateJudgingPanelShift.toObject(includeInstance, f),
    panelsList: jspb.Message.toObjectList(msg.getPanelsList(),
    proto.themis.EventFloorDateJudgingPanel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftResponse}
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventFloorDateJudgingPanelShiftResponse;
  return proto.themis.EventFloorDateJudgingPanelShiftResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftResponse}
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.EventFloorDateJudgingPanelShift;
      reader.readMessage(value,proto.themis.EventFloorDateJudgingPanelShift.deserializeBinaryFromReader);
      msg.addShifts(value);
      break;
    case 2:
      var value = new proto.themis.EventFloorDateJudgingPanelShift;
      reader.readMessage(value,proto.themis.EventFloorDateJudgingPanelShift.deserializeBinaryFromReader);
      msg.setShift(value);
      break;
    case 3:
      var value = new proto.themis.EventFloorDateJudgingPanel;
      reader.readMessage(value,proto.themis.EventFloorDateJudgingPanel.deserializeBinaryFromReader);
      msg.addPanels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventFloorDateJudgingPanelShiftResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShiftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.themis.EventFloorDateJudgingPanelShift.serializeBinaryToWriter
    );
  }
  f = message.getShift();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.EventFloorDateJudgingPanelShift.serializeBinaryToWriter
    );
  }
  f = message.getPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.themis.EventFloorDateJudgingPanel.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventFloorDateJudgingPanelShift shifts = 1;
 * @return {!Array<!proto.themis.EventFloorDateJudgingPanelShift>}
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.getShiftsList = function() {
  return /** @type{!Array<!proto.themis.EventFloorDateJudgingPanelShift>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventFloorDateJudgingPanelShift, 1));
};


/**
 * @param {!Array<!proto.themis.EventFloorDateJudgingPanelShift>} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftResponse} returns this
*/
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.setShiftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventFloorDateJudgingPanelShift}
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.addShifts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventFloorDateJudgingPanelShift, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftResponse} returns this
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.clearShiftsList = function() {
  return this.setShiftsList([]);
};


/**
 * optional EventFloorDateJudgingPanelShift shift = 2;
 * @return {?proto.themis.EventFloorDateJudgingPanelShift}
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.getShift = function() {
  return /** @type{?proto.themis.EventFloorDateJudgingPanelShift} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventFloorDateJudgingPanelShift, 2));
};


/**
 * @param {?proto.themis.EventFloorDateJudgingPanelShift|undefined} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftResponse} returns this
*/
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.setShift = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftResponse} returns this
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.clearShift = function() {
  return this.setShift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.hasShift = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated EventFloorDateJudgingPanel panels = 3;
 * @return {!Array<!proto.themis.EventFloorDateJudgingPanel>}
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.getPanelsList = function() {
  return /** @type{!Array<!proto.themis.EventFloorDateJudgingPanel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventFloorDateJudgingPanel, 3));
};


/**
 * @param {!Array<!proto.themis.EventFloorDateJudgingPanel>} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftResponse} returns this
*/
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.setPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventFloorDateJudgingPanel}
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.addPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.EventFloorDateJudgingPanel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventFloorDateJudgingPanelShiftResponse} returns this
 */
proto.themis.EventFloorDateJudgingPanelShiftResponse.prototype.clearPanelsList = function() {
  return this.setPanelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventFloorDateJudgingPanelShift.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventFloorDateJudgingPanelShift.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanelShift.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startTs: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endTs: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eventFloorDateJudgingPanelId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    judgesList: jspb.Message.toObjectList(msg.getJudgesList(),
    proto.themis.EventJudgeShift.toObject, includeInstance),
    eventDivisionsList: jspb.Message.toObjectList(msg.getEventDivisionsList(),
    proto.themis.EventDivision.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventFloorDateJudgingPanelShift}
 */
proto.themis.EventFloorDateJudgingPanelShift.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventFloorDateJudgingPanelShift;
  return proto.themis.EventFloorDateJudgingPanelShift.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventFloorDateJudgingPanelShift}
 */
proto.themis.EventFloorDateJudgingPanelShift.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventFloorDateJudgingPanelId(value);
      break;
    case 5:
      var value = new proto.themis.EventJudgeShift;
      reader.readMessage(value,proto.themis.EventJudgeShift.deserializeBinaryFromReader);
      msg.addJudges(value);
      break;
    case 6:
      var value = new proto.themis.EventDivision;
      reader.readMessage(value,proto.themis.EventDivision.deserializeBinaryFromReader);
      msg.addEventDivisions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventFloorDateJudgingPanelShift.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventFloorDateJudgingPanelShift.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStartTs();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndTs();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEventFloorDateJudgingPanelId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getJudgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.themis.EventJudgeShift.serializeBinaryToWriter
    );
  }
  f = message.getEventDivisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.themis.EventDivision.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShift} returns this
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string start_ts = 2;
 * @return {string}
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.getStartTs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShift} returns this
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.setStartTs = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_ts = 3;
 * @return {string}
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.getEndTs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShift} returns this
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.setEndTs = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 event_floor_date_judging_panel_id = 4;
 * @return {number}
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.getEventFloorDateJudgingPanelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShift} returns this
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.setEventFloorDateJudgingPanelId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated EventJudgeShift judges = 5;
 * @return {!Array<!proto.themis.EventJudgeShift>}
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.getJudgesList = function() {
  return /** @type{!Array<!proto.themis.EventJudgeShift>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventJudgeShift, 5));
};


/**
 * @param {!Array<!proto.themis.EventJudgeShift>} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShift} returns this
*/
proto.themis.EventFloorDateJudgingPanelShift.prototype.setJudgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.themis.EventJudgeShift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventJudgeShift}
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.addJudges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.themis.EventJudgeShift, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventFloorDateJudgingPanelShift} returns this
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.clearJudgesList = function() {
  return this.setJudgesList([]);
};


/**
 * repeated EventDivision event_divisions = 6;
 * @return {!Array<!proto.themis.EventDivision>}
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.getEventDivisionsList = function() {
  return /** @type{!Array<!proto.themis.EventDivision>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventDivision, 6));
};


/**
 * @param {!Array<!proto.themis.EventDivision>} value
 * @return {!proto.themis.EventFloorDateJudgingPanelShift} returns this
*/
proto.themis.EventFloorDateJudgingPanelShift.prototype.setEventDivisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.themis.EventDivision=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivision}
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.addEventDivisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.themis.EventDivision, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventFloorDateJudgingPanelShift} returns this
 */
proto.themis.EventFloorDateJudgingPanelShift.prototype.clearEventDivisionsList = function() {
  return this.setEventDivisionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventRegistrationCode.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventRegistrationCode.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventRegistrationCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventRegistrationCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    discountType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    amountInt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    amountString: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    beginsOn: jspb.Message.getFieldWithDefault(msg, 8, ""),
    expiresOn: jspb.Message.getFieldWithDefault(msg, 9, ""),
    eventId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    discountPerType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    beginsOnClockTime: (f = msg.getBeginsOnClockTime()) && proto.themis.ClockTime.toObject(includeInstance, f),
    expiresOnClockTime: (f = msg.getExpiresOnClockTime()) && proto.themis.ClockTime.toObject(includeInstance, f),
    depositAmount: jspb.Message.getFieldWithDefault(msg, 16, 0),
    depositType: jspb.Message.getFieldWithDefault(msg, 17, ""),
    pb_default: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    crossovers: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    discountAppliesTo: jspb.Message.getFieldWithDefault(msg, 20, ""),
    eventDivisionsList: jspb.Message.toObjectList(msg.getEventDivisionsList(),
    proto.themis.EventDivision.toObject, includeInstance),
    depositAmountString: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventRegistrationCode}
 */
proto.themis.EventRegistrationCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventRegistrationCode;
  return proto.themis.EventRegistrationCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventRegistrationCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventRegistrationCode}
 */
proto.themis.EventRegistrationCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {!proto.themis.UnitType} */ (reader.readEnum());
      msg.setDiscountType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmountInt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountString(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeginsOn(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiresOn(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscountPerType(value);
      break;
    case 14:
      var value = new proto.themis.ClockTime;
      reader.readMessage(value,proto.themis.ClockTime.deserializeBinaryFromReader);
      msg.setBeginsOnClockTime(value);
      break;
    case 15:
      var value = new proto.themis.ClockTime;
      reader.readMessage(value,proto.themis.ClockTime.deserializeBinaryFromReader);
      msg.setExpiresOnClockTime(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDepositAmount(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepositType(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefault(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCrossovers(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscountAppliesTo(value);
      break;
    case 21:
      var value = new proto.themis.EventDivision;
      reader.readMessage(value,proto.themis.EventDivision.deserializeBinaryFromReader);
      msg.addEventDivisions(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepositAmountString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventRegistrationCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventRegistrationCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventRegistrationCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDiscountType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAmountInt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAmountString();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getBeginsOn();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExpiresOn();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getDiscountPerType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBeginsOnClockTime();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.themis.ClockTime.serializeBinaryToWriter
    );
  }
  f = message.getExpiresOnClockTime();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.themis.ClockTime.serializeBinaryToWriter
    );
  }
  f = message.getDepositAmount();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getDepositType();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDefault();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getCrossovers();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getDiscountAppliesTo();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getEventDivisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.themis.EventDivision.serializeBinaryToWriter
    );
  }
  f = message.getDepositAmountString();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventRegistrationCode.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.EventRegistrationCode.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.themis.EventRegistrationCode.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional UnitType discount_type = 4;
 * @return {!proto.themis.UnitType}
 */
proto.themis.EventRegistrationCode.prototype.getDiscountType = function() {
  return /** @type {!proto.themis.UnitType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.themis.UnitType} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setDiscountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 amount_int = 5;
 * @return {number}
 */
proto.themis.EventRegistrationCode.prototype.getAmountInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setAmountInt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string amount_string = 6;
 * @return {string}
 */
proto.themis.EventRegistrationCode.prototype.getAmountString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setAmountString = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool status = 7;
 * @return {boolean}
 */
proto.themis.EventRegistrationCode.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string begins_on = 8;
 * @return {string}
 */
proto.themis.EventRegistrationCode.prototype.getBeginsOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setBeginsOn = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string expires_on = 9;
 * @return {string}
 */
proto.themis.EventRegistrationCode.prototype.getExpiresOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setExpiresOn = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint32 event_id = 10;
 * @return {number}
 */
proto.themis.EventRegistrationCode.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 producer_id = 11;
 * @return {number}
 */
proto.themis.EventRegistrationCode.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 brand_id = 12;
 * @return {number}
 */
proto.themis.EventRegistrationCode.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string discount_per_type = 13;
 * @return {string}
 */
proto.themis.EventRegistrationCode.prototype.getDiscountPerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setDiscountPerType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional ClockTime begins_on_clock_time = 14;
 * @return {?proto.themis.ClockTime}
 */
proto.themis.EventRegistrationCode.prototype.getBeginsOnClockTime = function() {
  return /** @type{?proto.themis.ClockTime} */ (
    jspb.Message.getWrapperField(this, proto.themis.ClockTime, 14));
};


/**
 * @param {?proto.themis.ClockTime|undefined} value
 * @return {!proto.themis.EventRegistrationCode} returns this
*/
proto.themis.EventRegistrationCode.prototype.setBeginsOnClockTime = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.clearBeginsOnClockTime = function() {
  return this.setBeginsOnClockTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventRegistrationCode.prototype.hasBeginsOnClockTime = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ClockTime expires_on_clock_time = 15;
 * @return {?proto.themis.ClockTime}
 */
proto.themis.EventRegistrationCode.prototype.getExpiresOnClockTime = function() {
  return /** @type{?proto.themis.ClockTime} */ (
    jspb.Message.getWrapperField(this, proto.themis.ClockTime, 15));
};


/**
 * @param {?proto.themis.ClockTime|undefined} value
 * @return {!proto.themis.EventRegistrationCode} returns this
*/
proto.themis.EventRegistrationCode.prototype.setExpiresOnClockTime = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.clearExpiresOnClockTime = function() {
  return this.setExpiresOnClockTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventRegistrationCode.prototype.hasExpiresOnClockTime = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional uint32 deposit_amount = 16;
 * @return {number}
 */
proto.themis.EventRegistrationCode.prototype.getDepositAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setDepositAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string deposit_type = 17;
 * @return {string}
 */
proto.themis.EventRegistrationCode.prototype.getDepositType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setDepositType = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool default = 18;
 * @return {boolean}
 */
proto.themis.EventRegistrationCode.prototype.getDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool crossovers = 19;
 * @return {boolean}
 */
proto.themis.EventRegistrationCode.prototype.getCrossovers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setCrossovers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string discount_applies_to = 20;
 * @return {string}
 */
proto.themis.EventRegistrationCode.prototype.getDiscountAppliesTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setDiscountAppliesTo = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated EventDivision event_divisions = 21;
 * @return {!Array<!proto.themis.EventDivision>}
 */
proto.themis.EventRegistrationCode.prototype.getEventDivisionsList = function() {
  return /** @type{!Array<!proto.themis.EventDivision>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventDivision, 21));
};


/**
 * @param {!Array<!proto.themis.EventDivision>} value
 * @return {!proto.themis.EventRegistrationCode} returns this
*/
proto.themis.EventRegistrationCode.prototype.setEventDivisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.themis.EventDivision=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivision}
 */
proto.themis.EventRegistrationCode.prototype.addEventDivisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.themis.EventDivision, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.clearEventDivisionsList = function() {
  return this.setEventDivisionsList([]);
};


/**
 * optional string deposit_amount_string = 22;
 * @return {string}
 */
proto.themis.EventRegistrationCode.prototype.getDepositAmountString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventRegistrationCode} returns this
 */
proto.themis.EventRegistrationCode.prototype.setDepositAmountString = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Tier.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Tier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Tier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Tier.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    allStarType: (f = msg.getAllStarType()) && proto.themis.AllStarType.toObject(includeInstance, f),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 4, ""),
    usasfTierId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Tier}
 */
proto.themis.Tier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Tier;
  return proto.themis.Tier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Tier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Tier}
 */
proto.themis.Tier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.themis.AllStarType;
      reader.readMessage(value,proto.themis.AllStarType.deserializeBinaryFromReader);
      msg.setAllStarType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsasfTierId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Tier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Tier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Tier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Tier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAllStarType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.AllStarType.serializeBinaryToWriter
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUsasfTierId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Tier.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Tier} returns this
 */
proto.themis.Tier.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Tier.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Tier} returns this
 */
proto.themis.Tier.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AllStarType all_star_type = 3;
 * @return {?proto.themis.AllStarType}
 */
proto.themis.Tier.prototype.getAllStarType = function() {
  return /** @type{?proto.themis.AllStarType} */ (
    jspb.Message.getWrapperField(this, proto.themis.AllStarType, 3));
};


/**
 * @param {?proto.themis.AllStarType|undefined} value
 * @return {!proto.themis.Tier} returns this
*/
proto.themis.Tier.prototype.setAllStarType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Tier} returns this
 */
proto.themis.Tier.prototype.clearAllStarType = function() {
  return this.setAllStarType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Tier.prototype.hasAllStarType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string last_scrape = 4;
 * @return {string}
 */
proto.themis.Tier.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Tier} returns this
 */
proto.themis.Tier.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 usasf_tier_id = 5;
 * @return {number}
 */
proto.themis.Tier.prototype.getUsasfTierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Tier} returns this
 */
proto.themis.Tier.prototype.setUsasfTierId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DivisionSplit.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DivisionSplit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DivisionSplit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionSplit.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    division: (f = msg.getDivision()) && proto.themis.Division.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    minTeams: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minAthletes: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxAthletes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    splitType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DivisionSplit}
 */
proto.themis.DivisionSplit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DivisionSplit;
  return proto.themis.DivisionSplit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DivisionSplit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DivisionSplit}
 */
proto.themis.DivisionSplit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.Division;
      reader.readMessage(value,proto.themis.Division.deserializeBinaryFromReader);
      msg.setDivision(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinTeams(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinAthletes(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxAthletes(value);
      break;
    case 7:
      var value = /** @type {!proto.themis.DivisionSplitType} */ (reader.readEnum());
      msg.setSplitType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DivisionSplit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DivisionSplit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DivisionSplit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionSplit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDivision();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.Division.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMinTeams();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getMinAthletes();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMaxAthletes();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getSplitType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.DivisionSplit.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionSplit} returns this
 */
proto.themis.DivisionSplit.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Division division = 2;
 * @return {?proto.themis.Division}
 */
proto.themis.DivisionSplit.prototype.getDivision = function() {
  return /** @type{?proto.themis.Division} */ (
    jspb.Message.getWrapperField(this, proto.themis.Division, 2));
};


/**
 * @param {?proto.themis.Division|undefined} value
 * @return {!proto.themis.DivisionSplit} returns this
*/
proto.themis.DivisionSplit.prototype.setDivision = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DivisionSplit} returns this
 */
proto.themis.DivisionSplit.prototype.clearDivision = function() {
  return this.setDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DivisionSplit.prototype.hasDivision = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.themis.DivisionSplit.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DivisionSplit} returns this
 */
proto.themis.DivisionSplit.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 min_teams = 4;
 * @return {number}
 */
proto.themis.DivisionSplit.prototype.getMinTeams = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionSplit} returns this
 */
proto.themis.DivisionSplit.prototype.setMinTeams = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 min_athletes = 5;
 * @return {number}
 */
proto.themis.DivisionSplit.prototype.getMinAthletes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionSplit} returns this
 */
proto.themis.DivisionSplit.prototype.setMinAthletes = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 max_athletes = 6;
 * @return {number}
 */
proto.themis.DivisionSplit.prototype.getMaxAthletes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionSplit} returns this
 */
proto.themis.DivisionSplit.prototype.setMaxAthletes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional DivisionSplitType split_type = 7;
 * @return {!proto.themis.DivisionSplitType}
 */
proto.themis.DivisionSplit.prototype.getSplitType = function() {
  return /** @type {!proto.themis.DivisionSplitType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.themis.DivisionSplitType} value
 * @return {!proto.themis.DivisionSplit} returns this
 */
proto.themis.DivisionSplit.prototype.setSplitType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional uint32 producer_id = 8;
 * @return {number}
 */
proto.themis.DivisionSplit.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionSplit} returns this
 */
proto.themis.DivisionSplit.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 brand_id = 9;
 * @return {number}
 */
proto.themis.DivisionSplit.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionSplit} returns this
 */
proto.themis.DivisionSplit.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 event_id = 10;
 * @return {number}
 */
proto.themis.DivisionSplit.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionSplit} returns this
 */
proto.themis.DivisionSplit.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventDivision.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivision.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivision.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivision} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivision.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    division: (f = msg.getDivision()) && proto.themis.Division.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && proto.themis.Event.toObject(includeInstance, f),
    divisionSplit: (f = msg.getDivisionSplit()) && proto.themis.DivisionSplit.toObject(includeInstance, f),
    divisionSplit2: (f = msg.getDivisionSplit2()) && proto.themis.DivisionSplit.toObject(includeInstance, f),
    eventDivisionPricing: (f = msg.getEventDivisionPricing()) && proto.themis.EventDivisionPricing.toObject(includeInstance, f),
    eventTeamsList: jspb.Message.toObjectList(msg.getEventTeamsList(),
    proto.themis.EventTeam.toObject, includeInstance),
    divisionsList: jspb.Message.toObjectList(msg.getDivisionsList(),
    proto.themis.Division.toObject, includeInstance),
    paid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    remainingAmount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    remainingDeposit: jspb.Message.getFieldWithDefault(msg, 11, 0),
    sortOrder: jspb.Message.getFieldWithDefault(msg, 12, 0),
    nonCrossover: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    rubricId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    name: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivision}
 */
proto.themis.EventDivision.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivision;
  return proto.themis.EventDivision.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivision} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivision}
 */
proto.themis.EventDivision.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.Division;
      reader.readMessage(value,proto.themis.Division.deserializeBinaryFromReader);
      msg.setDivision(value);
      break;
    case 3:
      var value = new proto.themis.Event;
      reader.readMessage(value,proto.themis.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 4:
      var value = new proto.themis.DivisionSplit;
      reader.readMessage(value,proto.themis.DivisionSplit.deserializeBinaryFromReader);
      msg.setDivisionSplit(value);
      break;
    case 5:
      var value = new proto.themis.DivisionSplit;
      reader.readMessage(value,proto.themis.DivisionSplit.deserializeBinaryFromReader);
      msg.setDivisionSplit2(value);
      break;
    case 6:
      var value = new proto.themis.EventDivisionPricing;
      reader.readMessage(value,proto.themis.EventDivisionPricing.deserializeBinaryFromReader);
      msg.setEventDivisionPricing(value);
      break;
    case 7:
      var value = new proto.themis.EventTeam;
      reader.readMessage(value,proto.themis.EventTeam.deserializeBinaryFromReader);
      msg.addEventTeams(value);
      break;
    case 8:
      var value = new proto.themis.Division;
      reader.readMessage(value,proto.themis.Division.deserializeBinaryFromReader);
      msg.addDivisions(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRemainingAmount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRemainingDeposit(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSortOrder(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNonCrossover(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRubricId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivision.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivision.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivision} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivision.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDivision();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.Division.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.Event.serializeBinaryToWriter
    );
  }
  f = message.getDivisionSplit();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.themis.DivisionSplit.serializeBinaryToWriter
    );
  }
  f = message.getDivisionSplit2();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.themis.DivisionSplit.serializeBinaryToWriter
    );
  }
  f = message.getEventDivisionPricing();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.themis.EventDivisionPricing.serializeBinaryToWriter
    );
  }
  f = message.getEventTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.themis.EventTeam.serializeBinaryToWriter
    );
  }
  f = message.getDivisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.themis.Division.serializeBinaryToWriter
    );
  }
  f = message.getPaid();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getRemainingAmount();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getRemainingDeposit();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getSortOrder();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getNonCrossover();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getRubricId();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventDivision.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Division division = 2;
 * @return {?proto.themis.Division}
 */
proto.themis.EventDivision.prototype.getDivision = function() {
  return /** @type{?proto.themis.Division} */ (
    jspb.Message.getWrapperField(this, proto.themis.Division, 2));
};


/**
 * @param {?proto.themis.Division|undefined} value
 * @return {!proto.themis.EventDivision} returns this
*/
proto.themis.EventDivision.prototype.setDivision = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.clearDivision = function() {
  return this.setDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivision.prototype.hasDivision = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Event event = 3;
 * @return {?proto.themis.Event}
 */
proto.themis.EventDivision.prototype.getEvent = function() {
  return /** @type{?proto.themis.Event} */ (
    jspb.Message.getWrapperField(this, proto.themis.Event, 3));
};


/**
 * @param {?proto.themis.Event|undefined} value
 * @return {!proto.themis.EventDivision} returns this
*/
proto.themis.EventDivision.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivision.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DivisionSplit division_split = 4;
 * @return {?proto.themis.DivisionSplit}
 */
proto.themis.EventDivision.prototype.getDivisionSplit = function() {
  return /** @type{?proto.themis.DivisionSplit} */ (
    jspb.Message.getWrapperField(this, proto.themis.DivisionSplit, 4));
};


/**
 * @param {?proto.themis.DivisionSplit|undefined} value
 * @return {!proto.themis.EventDivision} returns this
*/
proto.themis.EventDivision.prototype.setDivisionSplit = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.clearDivisionSplit = function() {
  return this.setDivisionSplit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivision.prototype.hasDivisionSplit = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DivisionSplit division_split2 = 5;
 * @return {?proto.themis.DivisionSplit}
 */
proto.themis.EventDivision.prototype.getDivisionSplit2 = function() {
  return /** @type{?proto.themis.DivisionSplit} */ (
    jspb.Message.getWrapperField(this, proto.themis.DivisionSplit, 5));
};


/**
 * @param {?proto.themis.DivisionSplit|undefined} value
 * @return {!proto.themis.EventDivision} returns this
*/
proto.themis.EventDivision.prototype.setDivisionSplit2 = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.clearDivisionSplit2 = function() {
  return this.setDivisionSplit2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivision.prototype.hasDivisionSplit2 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EventDivisionPricing event_division_pricing = 6;
 * @return {?proto.themis.EventDivisionPricing}
 */
proto.themis.EventDivision.prototype.getEventDivisionPricing = function() {
  return /** @type{?proto.themis.EventDivisionPricing} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventDivisionPricing, 6));
};


/**
 * @param {?proto.themis.EventDivisionPricing|undefined} value
 * @return {!proto.themis.EventDivision} returns this
*/
proto.themis.EventDivision.prototype.setEventDivisionPricing = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.clearEventDivisionPricing = function() {
  return this.setEventDivisionPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivision.prototype.hasEventDivisionPricing = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated EventTeam event_teams = 7;
 * @return {!Array<!proto.themis.EventTeam>}
 */
proto.themis.EventDivision.prototype.getEventTeamsList = function() {
  return /** @type{!Array<!proto.themis.EventTeam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventTeam, 7));
};


/**
 * @param {!Array<!proto.themis.EventTeam>} value
 * @return {!proto.themis.EventDivision} returns this
*/
proto.themis.EventDivision.prototype.setEventTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.themis.EventTeam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventTeam}
 */
proto.themis.EventDivision.prototype.addEventTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.themis.EventTeam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.clearEventTeamsList = function() {
  return this.setEventTeamsList([]);
};


/**
 * repeated Division divisions = 8;
 * @return {!Array<!proto.themis.Division>}
 */
proto.themis.EventDivision.prototype.getDivisionsList = function() {
  return /** @type{!Array<!proto.themis.Division>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Division, 8));
};


/**
 * @param {!Array<!proto.themis.Division>} value
 * @return {!proto.themis.EventDivision} returns this
*/
proto.themis.EventDivision.prototype.setDivisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.themis.Division=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Division}
 */
proto.themis.EventDivision.prototype.addDivisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.themis.Division, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.clearDivisionsList = function() {
  return this.setDivisionsList([]);
};


/**
 * optional uint32 paid = 9;
 * @return {number}
 */
proto.themis.EventDivision.prototype.getPaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.setPaid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 remaining_amount = 10;
 * @return {number}
 */
proto.themis.EventDivision.prototype.getRemainingAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.setRemainingAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 remaining_deposit = 11;
 * @return {number}
 */
proto.themis.EventDivision.prototype.getRemainingDeposit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.setRemainingDeposit = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 sort_order = 12;
 * @return {number}
 */
proto.themis.EventDivision.prototype.getSortOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool non_crossover = 13;
 * @return {boolean}
 */
proto.themis.EventDivision.prototype.getNonCrossover = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.setNonCrossover = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional uint32 rubric_id = 14;
 * @return {number}
 */
proto.themis.EventDivision.prototype.getRubricId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.setRubricId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string name = 15;
 * @return {string}
 */
proto.themis.EventDivision.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDivision} returns this
 */
proto.themis.EventDivision.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionUsage.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionUsage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionUsage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionUsage.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventDivisionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionUsage}
 */
proto.themis.EventDivisionUsage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionUsage;
  return proto.themis.EventDivisionUsage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionUsage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionUsage}
 */
proto.themis.EventDivisionUsage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventDivisionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionUsage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionUsage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionUsage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionUsage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventDivisionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 event_division_id = 1;
 * @return {number}
 */
proto.themis.EventDivisionUsage.prototype.getEventDivisionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionUsage} returns this
 */
proto.themis.EventDivisionUsage.prototype.setEventDivisionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 count = 2;
 * @return {number}
 */
proto.themis.EventDivisionUsage.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionUsage} returns this
 */
proto.themis.EventDivisionUsage.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ScoringAuthority.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ScoringAuthority.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ScoringAuthority.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ScoringAuthority} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ScoringAuthority.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    divisionsList: jspb.Message.toObjectList(msg.getDivisionsList(),
    proto.themis.Division.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ScoringAuthority}
 */
proto.themis.ScoringAuthority.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ScoringAuthority;
  return proto.themis.ScoringAuthority.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ScoringAuthority} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ScoringAuthority}
 */
proto.themis.ScoringAuthority.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.themis.Division;
      reader.readMessage(value,proto.themis.Division.deserializeBinaryFromReader);
      msg.addDivisions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ScoringAuthority.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ScoringAuthority.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ScoringAuthority} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ScoringAuthority.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDivisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.themis.Division.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.ScoringAuthority.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ScoringAuthority} returns this
 */
proto.themis.ScoringAuthority.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.ScoringAuthority.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ScoringAuthority} returns this
 */
proto.themis.ScoringAuthority.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Division divisions = 3;
 * @return {!Array<!proto.themis.Division>}
 */
proto.themis.ScoringAuthority.prototype.getDivisionsList = function() {
  return /** @type{!Array<!proto.themis.Division>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Division, 3));
};


/**
 * @param {!Array<!proto.themis.Division>} value
 * @return {!proto.themis.ScoringAuthority} returns this
*/
proto.themis.ScoringAuthority.prototype.setDivisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.Division=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Division}
 */
proto.themis.ScoringAuthority.prototype.addDivisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.Division, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ScoringAuthority} returns this
 */
proto.themis.ScoringAuthority.prototype.clearDivisionsList = function() {
  return this.setDivisionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DivisionLevel.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DivisionLevel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DivisionLevel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionLevel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    usasfLevelId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastScrape: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DivisionLevel}
 */
proto.themis.DivisionLevel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DivisionLevel;
  return proto.themis.DivisionLevel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DivisionLevel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DivisionLevel}
 */
proto.themis.DivisionLevel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsasfLevelId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrape(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DivisionLevel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DivisionLevel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DivisionLevel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DivisionLevel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsasfLevelId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastScrape();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.DivisionLevel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DivisionLevel} returns this
 */
proto.themis.DivisionLevel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.DivisionLevel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DivisionLevel} returns this
 */
proto.themis.DivisionLevel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string usasf_level_id = 3;
 * @return {string}
 */
proto.themis.DivisionLevel.prototype.getUsasfLevelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DivisionLevel} returns this
 */
proto.themis.DivisionLevel.prototype.setUsasfLevelId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_scrape = 4;
 * @return {string}
 */
proto.themis.DivisionLevel.prototype.getLastScrape = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DivisionLevel} returns this
 */
proto.themis.DivisionLevel.prototype.setLastScrape = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Season.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Season.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Season.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Season} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Season.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    country: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scoringAuthority: (f = msg.getScoringAuthority()) && proto.themis.ScoringAuthority.toObject(includeInstance, f),
    divisionsList: jspb.Message.toObjectList(msg.getDivisionsList(),
    proto.themis.Division.toObject, includeInstance),
    isExclusive: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    exclusiveProducerId: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Season}
 */
proto.themis.Season.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Season;
  return proto.themis.Season.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Season} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Season}
 */
proto.themis.Season.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 6:
      var value = new proto.themis.ScoringAuthority;
      reader.readMessage(value,proto.themis.ScoringAuthority.deserializeBinaryFromReader);
      msg.setScoringAuthority(value);
      break;
    case 7:
      var value = new proto.themis.Division;
      reader.readMessage(value,proto.themis.Division.deserializeBinaryFromReader);
      msg.addDivisions(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExclusive(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExclusiveProducerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Season.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Season.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Season} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Season.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScoringAuthority();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.themis.ScoringAuthority.serializeBinaryToWriter
    );
  }
  f = message.getDivisionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.themis.Division.serializeBinaryToWriter
    );
  }
  f = message.getIsExclusive();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getExclusiveProducerId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Season.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Season} returns this
 */
proto.themis.Season.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Season.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Season} returns this
 */
proto.themis.Season.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string start_date = 3;
 * @return {string}
 */
proto.themis.Season.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Season} returns this
 */
proto.themis.Season.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end_date = 4;
 * @return {string}
 */
proto.themis.Season.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Season} returns this
 */
proto.themis.Season.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string country = 5;
 * @return {string}
 */
proto.themis.Season.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Season} returns this
 */
proto.themis.Season.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ScoringAuthority scoring_authority = 6;
 * @return {?proto.themis.ScoringAuthority}
 */
proto.themis.Season.prototype.getScoringAuthority = function() {
  return /** @type{?proto.themis.ScoringAuthority} */ (
    jspb.Message.getWrapperField(this, proto.themis.ScoringAuthority, 6));
};


/**
 * @param {?proto.themis.ScoringAuthority|undefined} value
 * @return {!proto.themis.Season} returns this
*/
proto.themis.Season.prototype.setScoringAuthority = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Season} returns this
 */
proto.themis.Season.prototype.clearScoringAuthority = function() {
  return this.setScoringAuthority(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Season.prototype.hasScoringAuthority = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Division divisions = 7;
 * @return {!Array<!proto.themis.Division>}
 */
proto.themis.Season.prototype.getDivisionsList = function() {
  return /** @type{!Array<!proto.themis.Division>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Division, 7));
};


/**
 * @param {!Array<!proto.themis.Division>} value
 * @return {!proto.themis.Season} returns this
*/
proto.themis.Season.prototype.setDivisionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.themis.Division=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Division}
 */
proto.themis.Season.prototype.addDivisions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.themis.Division, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Season} returns this
 */
proto.themis.Season.prototype.clearDivisionsList = function() {
  return this.setDivisionsList([]);
};


/**
 * optional bool is_exclusive = 8;
 * @return {boolean}
 */
proto.themis.Season.prototype.getIsExclusive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.Season} returns this
 */
proto.themis.Season.prototype.setIsExclusive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional uint32 exclusive_producer_id = 9;
 * @return {number}
 */
proto.themis.Season.prototype.getExclusiveProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Season} returns this
 */
proto.themis.Season.prototype.setExclusiveProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Permission.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Permission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Permission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Permission.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Permission}
 */
proto.themis.Permission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Permission;
  return proto.themis.Permission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Permission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Permission}
 */
proto.themis.Permission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Permission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Permission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Permission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Permission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Permission.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Permission} returns this
 */
proto.themis.Permission.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Permission.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Permission} returns this
 */
proto.themis.Permission.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.themis.Permission.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Permission} returns this
 */
proto.themis.Permission.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.UserPermissions.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.UserPermissions.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.UserPermissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.UserPermissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UserPermissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.themis.User.toObject(includeInstance, f),
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.themis.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.UserPermissions}
 */
proto.themis.UserPermissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.UserPermissions;
  return proto.themis.UserPermissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.UserPermissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.UserPermissions}
 */
proto.themis.UserPermissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.User;
      reader.readMessage(value,proto.themis.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.themis.Permission;
      reader.readMessage(value,proto.themis.Permission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.UserPermissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.UserPermissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.UserPermissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UserPermissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.themis.User.serializeBinaryToWriter
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.themis.Permission.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.themis.User}
 */
proto.themis.UserPermissions.prototype.getUser = function() {
  return /** @type{?proto.themis.User} */ (
    jspb.Message.getWrapperField(this, proto.themis.User, 1));
};


/**
 * @param {?proto.themis.User|undefined} value
 * @return {!proto.themis.UserPermissions} returns this
*/
proto.themis.UserPermissions.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.UserPermissions} returns this
 */
proto.themis.UserPermissions.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.UserPermissions.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Permission permissions = 2;
 * @return {!Array<!proto.themis.Permission>}
 */
proto.themis.UserPermissions.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.themis.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Permission, 2));
};


/**
 * @param {!Array<!proto.themis.Permission>} value
 * @return {!proto.themis.UserPermissions} returns this
*/
proto.themis.UserPermissions.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Permission}
 */
proto.themis.UserPermissions.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.UserPermissions} returns this
 */
proto.themis.UserPermissions.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.PermissionCodes.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.PermissionCodes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.PermissionCodes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PermissionCodes.toObject = function(includeInstance, msg) {
  var f, obj = {
    denyAccess: jspb.Message.getFieldWithDefault(msg, 1, ""),
    financesView: jspb.Message.getFieldWithDefault(msg, 32, ""),
    financesDo: jspb.Message.getFieldWithDefault(msg, 33, ""),
    financesEdit: jspb.Message.getFieldWithDefault(msg, 34, ""),
    programSuper: jspb.Message.getFieldWithDefault(msg, 2, ""),
    programView: jspb.Message.getFieldWithDefault(msg, 3, ""),
    programEdit: jspb.Message.getFieldWithDefault(msg, 4, ""),
    programDelete: jspb.Message.getFieldWithDefault(msg, 5, ""),
    locationSuper: jspb.Message.getFieldWithDefault(msg, 6, ""),
    locationView: jspb.Message.getFieldWithDefault(msg, 7, ""),
    locationAdd: jspb.Message.getFieldWithDefault(msg, 8, ""),
    locationEdit: jspb.Message.getFieldWithDefault(msg, 9, ""),
    locationDelete: jspb.Message.getFieldWithDefault(msg, 10, ""),
    teamSuper: jspb.Message.getFieldWithDefault(msg, 11, ""),
    teamView: jspb.Message.getFieldWithDefault(msg, 12, ""),
    teamAdd: jspb.Message.getFieldWithDefault(msg, 13, ""),
    teamEdit: jspb.Message.getFieldWithDefault(msg, 14, ""),
    teamDelete: jspb.Message.getFieldWithDefault(msg, 15, ""),
    teamRegister: jspb.Message.getFieldWithDefault(msg, 16, ""),
    athleteView: jspb.Message.getFieldWithDefault(msg, 17, ""),
    athleteAdd: jspb.Message.getFieldWithDefault(msg, 18, ""),
    athleteEdit: jspb.Message.getFieldWithDefault(msg, 19, ""),
    athleteDelete: jspb.Message.getFieldWithDefault(msg, 20, ""),
    athleteChange: jspb.Message.getFieldWithDefault(msg, 21, ""),
    coachView: jspb.Message.getFieldWithDefault(msg, 22, ""),
    coachAdd: jspb.Message.getFieldWithDefault(msg, 23, ""),
    coachEdit: jspb.Message.getFieldWithDefault(msg, 24, ""),
    coachDelete: jspb.Message.getFieldWithDefault(msg, 25, ""),
    coachChange: jspb.Message.getFieldWithDefault(msg, 26, ""),
    personnelView: jspb.Message.getFieldWithDefault(msg, 27, ""),
    personnelAdd: jspb.Message.getFieldWithDefault(msg, 28, ""),
    personnelEdit: jspb.Message.getFieldWithDefault(msg, 29, ""),
    personnelDelete: jspb.Message.getFieldWithDefault(msg, 30, ""),
    personnelChange: jspb.Message.getFieldWithDefault(msg, 31, ""),
    programUserView: jspb.Message.getFieldWithDefault(msg, 35, ""),
    programUserAdd: jspb.Message.getFieldWithDefault(msg, 36, ""),
    programUserEdit: jspb.Message.getFieldWithDefault(msg, 37, ""),
    programUserDelete: jspb.Message.getFieldWithDefault(msg, 38, ""),
    locationUserView: jspb.Message.getFieldWithDefault(msg, 39, ""),
    locationUserAdd: jspb.Message.getFieldWithDefault(msg, 40, ""),
    locationUserEdit: jspb.Message.getFieldWithDefault(msg, 41, ""),
    locationUserDelete: jspb.Message.getFieldWithDefault(msg, 42, ""),
    teamUserView: jspb.Message.getFieldWithDefault(msg, 43, ""),
    teamUserAdd: jspb.Message.getFieldWithDefault(msg, 44, ""),
    teamUserEdit: jspb.Message.getFieldWithDefault(msg, 45, ""),
    teamUserDelete: jspb.Message.getFieldWithDefault(msg, 46, ""),
    producerSuper: jspb.Message.getFieldWithDefault(msg, 50, ""),
    producerView: jspb.Message.getFieldWithDefault(msg, 51, ""),
    producerEdit: jspb.Message.getFieldWithDefault(msg, 52, ""),
    producerDelete: jspb.Message.getFieldWithDefault(msg, 53, ""),
    brandSuper: jspb.Message.getFieldWithDefault(msg, 54, ""),
    brandView: jspb.Message.getFieldWithDefault(msg, 55, ""),
    brandAdd: jspb.Message.getFieldWithDefault(msg, 56, ""),
    brandEdit: jspb.Message.getFieldWithDefault(msg, 57, ""),
    brandDelete: jspb.Message.getFieldWithDefault(msg, 58, ""),
    eventSuper: jspb.Message.getFieldWithDefault(msg, 59, ""),
    eventView: jspb.Message.getFieldWithDefault(msg, 60, ""),
    eventAdd: jspb.Message.getFieldWithDefault(msg, 61, ""),
    eventEdit: jspb.Message.getFieldWithDefault(msg, 62, ""),
    eventDelete: jspb.Message.getFieldWithDefault(msg, 63, ""),
    eventJudge: jspb.Message.getFieldWithDefault(msg, 82, ""),
    eventMJudge: jspb.Message.getFieldWithDefault(msg, 83, ""),
    judgeView: jspb.Message.getFieldWithDefault(msg, 64, ""),
    judgeAdd: jspb.Message.getFieldWithDefault(msg, 65, ""),
    judgeEdit: jspb.Message.getFieldWithDefault(msg, 66, ""),
    judgeDelete: jspb.Message.getFieldWithDefault(msg, 67, ""),
    producerUserView: jspb.Message.getFieldWithDefault(msg, 70, ""),
    producerUserAdd: jspb.Message.getFieldWithDefault(msg, 71, ""),
    producerUserEdit: jspb.Message.getFieldWithDefault(msg, 72, ""),
    producerUserDelete: jspb.Message.getFieldWithDefault(msg, 73, ""),
    brandUserView: jspb.Message.getFieldWithDefault(msg, 74, ""),
    brandUserAdd: jspb.Message.getFieldWithDefault(msg, 75, ""),
    brandUserEdit: jspb.Message.getFieldWithDefault(msg, 76, ""),
    brandUserDelete: jspb.Message.getFieldWithDefault(msg, 77, ""),
    eventUserView: jspb.Message.getFieldWithDefault(msg, 78, ""),
    eventUserAdd: jspb.Message.getFieldWithDefault(msg, 79, ""),
    eventUserEdit: jspb.Message.getFieldWithDefault(msg, 80, ""),
    eventUserDelete: jspb.Message.getFieldWithDefault(msg, 81, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.PermissionCodes}
 */
proto.themis.PermissionCodes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.PermissionCodes;
  return proto.themis.PermissionCodes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.PermissionCodes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.PermissionCodes}
 */
proto.themis.PermissionCodes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDenyAccess(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinancesView(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinancesDo(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinancesEdit(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramSuper(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramView(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramEdit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramDelete(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationSuper(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationView(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationAdd(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationEdit(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationDelete(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamSuper(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamView(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamAdd(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamEdit(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamDelete(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamRegister(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAthleteView(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAthleteAdd(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAthleteEdit(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAthleteDelete(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAthleteChange(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoachView(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoachAdd(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoachEdit(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoachDelete(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoachChange(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonnelView(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonnelAdd(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonnelEdit(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonnelDelete(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonnelChange(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramUserView(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramUserAdd(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramUserEdit(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramUserDelete(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationUserView(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationUserAdd(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationUserEdit(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationUserDelete(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamUserView(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamUserAdd(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamUserEdit(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamUserDelete(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerSuper(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerView(value);
      break;
    case 52:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerEdit(value);
      break;
    case 53:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerDelete(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandSuper(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandView(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandAdd(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandEdit(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandDelete(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventSuper(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventView(value);
      break;
    case 61:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventAdd(value);
      break;
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventEdit(value);
      break;
    case 63:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventDelete(value);
      break;
    case 82:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventJudge(value);
      break;
    case 83:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventMJudge(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setJudgeView(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setJudgeAdd(value);
      break;
    case 66:
      var value = /** @type {string} */ (reader.readString());
      msg.setJudgeEdit(value);
      break;
    case 67:
      var value = /** @type {string} */ (reader.readString());
      msg.setJudgeDelete(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerUserView(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerUserAdd(value);
      break;
    case 72:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerUserEdit(value);
      break;
    case 73:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerUserDelete(value);
      break;
    case 74:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandUserView(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandUserAdd(value);
      break;
    case 76:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandUserEdit(value);
      break;
    case 77:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandUserDelete(value);
      break;
    case 78:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventUserView(value);
      break;
    case 79:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventUserAdd(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventUserEdit(value);
      break;
    case 81:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventUserDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.PermissionCodes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.PermissionCodes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.PermissionCodes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.PermissionCodes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDenyAccess();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinancesView();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getFinancesDo();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getFinancesEdit();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getProgramSuper();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProgramView();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProgramEdit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProgramDelete();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLocationSuper();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocationView();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLocationAdd();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLocationEdit();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLocationDelete();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTeamSuper();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTeamView();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTeamAdd();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTeamEdit();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTeamDelete();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTeamRegister();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAthleteView();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAthleteAdd();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAthleteEdit();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAthleteDelete();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getAthleteChange();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getCoachView();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getCoachAdd();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getCoachEdit();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getCoachDelete();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getCoachChange();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getPersonnelView();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getPersonnelAdd();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getPersonnelEdit();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getPersonnelDelete();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getPersonnelChange();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getProgramUserView();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getProgramUserAdd();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getProgramUserEdit();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getProgramUserDelete();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getLocationUserView();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getLocationUserAdd();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getLocationUserEdit();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getLocationUserDelete();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getTeamUserView();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getTeamUserAdd();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getTeamUserEdit();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getTeamUserDelete();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getProducerSuper();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getProducerView();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getProducerEdit();
  if (f.length > 0) {
    writer.writeString(
      52,
      f
    );
  }
  f = message.getProducerDelete();
  if (f.length > 0) {
    writer.writeString(
      53,
      f
    );
  }
  f = message.getBrandSuper();
  if (f.length > 0) {
    writer.writeString(
      54,
      f
    );
  }
  f = message.getBrandView();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getBrandAdd();
  if (f.length > 0) {
    writer.writeString(
      56,
      f
    );
  }
  f = message.getBrandEdit();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getBrandDelete();
  if (f.length > 0) {
    writer.writeString(
      58,
      f
    );
  }
  f = message.getEventSuper();
  if (f.length > 0) {
    writer.writeString(
      59,
      f
    );
  }
  f = message.getEventView();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getEventAdd();
  if (f.length > 0) {
    writer.writeString(
      61,
      f
    );
  }
  f = message.getEventEdit();
  if (f.length > 0) {
    writer.writeString(
      62,
      f
    );
  }
  f = message.getEventDelete();
  if (f.length > 0) {
    writer.writeString(
      63,
      f
    );
  }
  f = message.getEventJudge();
  if (f.length > 0) {
    writer.writeString(
      82,
      f
    );
  }
  f = message.getEventMJudge();
  if (f.length > 0) {
    writer.writeString(
      83,
      f
    );
  }
  f = message.getJudgeView();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
  f = message.getJudgeAdd();
  if (f.length > 0) {
    writer.writeString(
      65,
      f
    );
  }
  f = message.getJudgeEdit();
  if (f.length > 0) {
    writer.writeString(
      66,
      f
    );
  }
  f = message.getJudgeDelete();
  if (f.length > 0) {
    writer.writeString(
      67,
      f
    );
  }
  f = message.getProducerUserView();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getProducerUserAdd();
  if (f.length > 0) {
    writer.writeString(
      71,
      f
    );
  }
  f = message.getProducerUserEdit();
  if (f.length > 0) {
    writer.writeString(
      72,
      f
    );
  }
  f = message.getProducerUserDelete();
  if (f.length > 0) {
    writer.writeString(
      73,
      f
    );
  }
  f = message.getBrandUserView();
  if (f.length > 0) {
    writer.writeString(
      74,
      f
    );
  }
  f = message.getBrandUserAdd();
  if (f.length > 0) {
    writer.writeString(
      75,
      f
    );
  }
  f = message.getBrandUserEdit();
  if (f.length > 0) {
    writer.writeString(
      76,
      f
    );
  }
  f = message.getBrandUserDelete();
  if (f.length > 0) {
    writer.writeString(
      77,
      f
    );
  }
  f = message.getEventUserView();
  if (f.length > 0) {
    writer.writeString(
      78,
      f
    );
  }
  f = message.getEventUserAdd();
  if (f.length > 0) {
    writer.writeString(
      79,
      f
    );
  }
  f = message.getEventUserEdit();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getEventUserDelete();
  if (f.length > 0) {
    writer.writeString(
      81,
      f
    );
  }
};


/**
 * optional string deny_access = 1;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getDenyAccess = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setDenyAccess = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string finances_view = 32;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getFinancesView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setFinancesView = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string finances_do = 33;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getFinancesDo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setFinancesDo = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string finances_edit = 34;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getFinancesEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setFinancesEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string program_super = 2;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProgramSuper = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProgramSuper = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string program_view = 3;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProgramView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProgramView = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string program_edit = 4;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProgramEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProgramEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string program_delete = 5;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProgramDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProgramDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string location_super = 6;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getLocationSuper = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setLocationSuper = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string location_view = 7;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getLocationView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setLocationView = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string location_add = 8;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getLocationAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setLocationAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string location_edit = 9;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getLocationEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setLocationEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string location_delete = 10;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getLocationDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setLocationDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string team_super = 11;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getTeamSuper = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setTeamSuper = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string team_view = 12;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getTeamView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setTeamView = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string team_add = 13;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getTeamAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setTeamAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string team_edit = 14;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getTeamEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setTeamEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string team_delete = 15;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getTeamDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setTeamDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string team_register = 16;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getTeamRegister = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setTeamRegister = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string athlete_view = 17;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getAthleteView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setAthleteView = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string athlete_add = 18;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getAthleteAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setAthleteAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string athlete_edit = 19;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getAthleteEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setAthleteEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string athlete_delete = 20;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getAthleteDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setAthleteDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string athlete_change = 21;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getAthleteChange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setAthleteChange = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string coach_view = 22;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getCoachView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setCoachView = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string coach_add = 23;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getCoachAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setCoachAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string coach_edit = 24;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getCoachEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setCoachEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string coach_delete = 25;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getCoachDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setCoachDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string coach_change = 26;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getCoachChange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setCoachChange = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string personnel_view = 27;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getPersonnelView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setPersonnelView = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string personnel_add = 28;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getPersonnelAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setPersonnelAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string personnel_edit = 29;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getPersonnelEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setPersonnelEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string personnel_delete = 30;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getPersonnelDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setPersonnelDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string personnel_change = 31;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getPersonnelChange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setPersonnelChange = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string program_user_view = 35;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProgramUserView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProgramUserView = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string program_user_add = 36;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProgramUserAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProgramUserAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string program_user_edit = 37;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProgramUserEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProgramUserEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string program_user_delete = 38;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProgramUserDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProgramUserDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string location_user_view = 39;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getLocationUserView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setLocationUserView = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string location_user_add = 40;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getLocationUserAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setLocationUserAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string location_user_edit = 41;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getLocationUserEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setLocationUserEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string location_user_delete = 42;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getLocationUserDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setLocationUserDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string team_user_view = 43;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getTeamUserView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setTeamUserView = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string team_user_add = 44;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getTeamUserAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setTeamUserAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string team_user_edit = 45;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getTeamUserEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setTeamUserEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string team_user_delete = 46;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getTeamUserDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setTeamUserDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string producer_super = 50;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProducerSuper = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProducerSuper = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string producer_view = 51;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProducerView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProducerView = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional string producer_edit = 52;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProducerEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 52, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProducerEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 52, value);
};


/**
 * optional string producer_delete = 53;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProducerDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 53, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProducerDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 53, value);
};


/**
 * optional string brand_super = 54;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getBrandSuper = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setBrandSuper = function(value) {
  return jspb.Message.setProto3StringField(this, 54, value);
};


/**
 * optional string brand_view = 55;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getBrandView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setBrandView = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional string brand_add = 56;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getBrandAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setBrandAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 56, value);
};


/**
 * optional string brand_edit = 57;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getBrandEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setBrandEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional string brand_delete = 58;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getBrandDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setBrandDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 58, value);
};


/**
 * optional string event_super = 59;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getEventSuper = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setEventSuper = function(value) {
  return jspb.Message.setProto3StringField(this, 59, value);
};


/**
 * optional string event_view = 60;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getEventView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setEventView = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional string event_add = 61;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getEventAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 61, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setEventAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 61, value);
};


/**
 * optional string event_edit = 62;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getEventEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 62, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setEventEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 62, value);
};


/**
 * optional string event_delete = 63;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getEventDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 63, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setEventDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 63, value);
};


/**
 * optional string event_judge = 82;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getEventJudge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 82, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setEventJudge = function(value) {
  return jspb.Message.setProto3StringField(this, 82, value);
};


/**
 * optional string event_m_judge = 83;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getEventMJudge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 83, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setEventMJudge = function(value) {
  return jspb.Message.setProto3StringField(this, 83, value);
};


/**
 * optional string judge_view = 64;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getJudgeView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setJudgeView = function(value) {
  return jspb.Message.setProto3StringField(this, 64, value);
};


/**
 * optional string judge_add = 65;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getJudgeAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setJudgeAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 65, value);
};


/**
 * optional string judge_edit = 66;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getJudgeEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 66, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setJudgeEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 66, value);
};


/**
 * optional string judge_delete = 67;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getJudgeDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 67, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setJudgeDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 67, value);
};


/**
 * optional string producer_user_view = 70;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProducerUserView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProducerUserView = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string producer_user_add = 71;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProducerUserAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProducerUserAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 71, value);
};


/**
 * optional string producer_user_edit = 72;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProducerUserEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 72, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProducerUserEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 72, value);
};


/**
 * optional string producer_user_delete = 73;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getProducerUserDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 73, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setProducerUserDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 73, value);
};


/**
 * optional string brand_user_view = 74;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getBrandUserView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 74, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setBrandUserView = function(value) {
  return jspb.Message.setProto3StringField(this, 74, value);
};


/**
 * optional string brand_user_add = 75;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getBrandUserAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 75, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setBrandUserAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 75, value);
};


/**
 * optional string brand_user_edit = 76;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getBrandUserEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 76, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setBrandUserEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 76, value);
};


/**
 * optional string brand_user_delete = 77;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getBrandUserDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 77, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setBrandUserDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 77, value);
};


/**
 * optional string event_user_view = 78;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getEventUserView = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 78, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setEventUserView = function(value) {
  return jspb.Message.setProto3StringField(this, 78, value);
};


/**
 * optional string event_user_add = 79;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getEventUserAdd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 79, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setEventUserAdd = function(value) {
  return jspb.Message.setProto3StringField(this, 79, value);
};


/**
 * optional string event_user_edit = 80;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getEventUserEdit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setEventUserEdit = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * optional string event_user_delete = 81;
 * @return {string}
 */
proto.themis.PermissionCodes.prototype.getEventUserDelete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 81, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.PermissionCodes} returns this
 */
proto.themis.PermissionCodes.prototype.setEventUserDelete = function(value) {
  return jspb.Message.setProto3StringField(this, 81, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Judge.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Judge.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Judge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Judge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Judge.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    person: (f = msg.getPerson()) && proto.themis.Person.toObject(includeInstance, f),
    judgeProducersList: jspb.Message.toObjectList(msg.getJudgeProducersList(),
    proto.themis.Producer.toObject, includeInstance),
    producerId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Judge}
 */
proto.themis.Judge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Judge;
  return proto.themis.Judge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Judge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Judge}
 */
proto.themis.Judge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.themis.Person;
      reader.readMessage(value,proto.themis.Person.deserializeBinaryFromReader);
      msg.setPerson(value);
      break;
    case 4:
      var value = new proto.themis.Producer;
      reader.readMessage(value,proto.themis.Producer.deserializeBinaryFromReader);
      msg.addJudgeProducers(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Judge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Judge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Judge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Judge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPerson();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.Person.serializeBinaryToWriter
    );
  }
  f = message.getJudgeProducersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.themis.Producer.serializeBinaryToWriter
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Judge.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Judge} returns this
 */
proto.themis.Judge.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Judge.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Judge} returns this
 */
proto.themis.Judge.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Person person = 3;
 * @return {?proto.themis.Person}
 */
proto.themis.Judge.prototype.getPerson = function() {
  return /** @type{?proto.themis.Person} */ (
    jspb.Message.getWrapperField(this, proto.themis.Person, 3));
};


/**
 * @param {?proto.themis.Person|undefined} value
 * @return {!proto.themis.Judge} returns this
*/
proto.themis.Judge.prototype.setPerson = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Judge} returns this
 */
proto.themis.Judge.prototype.clearPerson = function() {
  return this.setPerson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Judge.prototype.hasPerson = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Producer judge_producers = 4;
 * @return {!Array<!proto.themis.Producer>}
 */
proto.themis.Judge.prototype.getJudgeProducersList = function() {
  return /** @type{!Array<!proto.themis.Producer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Producer, 4));
};


/**
 * @param {!Array<!proto.themis.Producer>} value
 * @return {!proto.themis.Judge} returns this
*/
proto.themis.Judge.prototype.setJudgeProducersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.Producer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Producer}
 */
proto.themis.Judge.prototype.addJudgeProducers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.Producer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Judge} returns this
 */
proto.themis.Judge.prototype.clearJudgeProducersList = function() {
  return this.setJudgeProducersList([]);
};


/**
 * optional uint32 producer_id = 7;
 * @return {number}
 */
proto.themis.Judge.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Judge} returns this
 */
proto.themis.Judge.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 brand_id = 8;
 * @return {number}
 */
proto.themis.Judge.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Judge} returns this
 */
proto.themis.Judge.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.StripeBankAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.StripeBankAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.StripeBankAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.StripeBankAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    object: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountHolderName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountHolderType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bankName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 7, ""),
    customer: jspb.Message.getFieldWithDefault(msg, 8, ""),
    fingerprint: jspb.Message.getFieldWithDefault(msg, 9, ""),
    last4: jspb.Message.getFieldWithDefault(msg, 10, ""),
    routingNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    status: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.StripeBankAccount}
 */
proto.themis.StripeBankAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.StripeBankAccount;
  return proto.themis.StripeBankAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.StripeBankAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.StripeBankAccount}
 */
proto.themis.StripeBankAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setObject(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountHolderName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountHolderType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFingerprint(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast4(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoutingNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.StripeBankAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.StripeBankAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.StripeBankAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.StripeBankAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObject();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountHolderName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountHolderType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFingerprint();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLast4();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRoutingNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string object = 2;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getObject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setObject = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_holder_name = 3;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getAccountHolderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setAccountHolderName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_holder_type = 4;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getAccountHolderType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setAccountHolderType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bank_name = 5;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string currency = 7;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string customer = 8;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string fingerprint = 9;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getFingerprint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setFingerprint = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string last4 = 10;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getLast4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setLast4 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string routing_number = 11;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getRoutingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setRoutingNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string status = 12;
 * @return {string}
 */
proto.themis.StripeBankAccount.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeBankAccount} returns this
 */
proto.themis.StripeBankAccount.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.StripeCreditCard.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.StripeCreditCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.StripeCreditCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.StripeCreditCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    brand: jspb.Message.getFieldWithDefault(msg, 5, ""),
    country: jspb.Message.getFieldWithDefault(msg, 6, ""),
    customer: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cvcCheck: jspb.Message.getFieldWithDefault(msg, 9, ""),
    expMonth: jspb.Message.getFieldWithDefault(msg, 12, 0),
    expYear: jspb.Message.getFieldWithDefault(msg, 13, 0),
    fingerprint: jspb.Message.getFieldWithDefault(msg, 14, ""),
    last4: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.StripeCreditCard}
 */
proto.themis.StripeCreditCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.StripeCreditCard;
  return proto.themis.StripeCreditCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.StripeCreditCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.StripeCreditCard}
 */
proto.themis.StripeCreditCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrand(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomer(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCvcCheck(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpMonth(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpYear(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFingerprint(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast4(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.StripeCreditCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.StripeCreditCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.StripeCreditCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.StripeCreditCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBrand();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCustomer();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCvcCheck();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getExpMonth();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getExpYear();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getFingerprint();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLast4();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.themis.StripeCreditCard.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeCreditCard} returns this
 */
proto.themis.StripeCreditCard.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string brand = 5;
 * @return {string}
 */
proto.themis.StripeCreditCard.prototype.getBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeCreditCard} returns this
 */
proto.themis.StripeCreditCard.prototype.setBrand = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string country = 6;
 * @return {string}
 */
proto.themis.StripeCreditCard.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeCreditCard} returns this
 */
proto.themis.StripeCreditCard.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string customer = 8;
 * @return {string}
 */
proto.themis.StripeCreditCard.prototype.getCustomer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeCreditCard} returns this
 */
proto.themis.StripeCreditCard.prototype.setCustomer = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string cvc_check = 9;
 * @return {string}
 */
proto.themis.StripeCreditCard.prototype.getCvcCheck = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeCreditCard} returns this
 */
proto.themis.StripeCreditCard.prototype.setCvcCheck = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint32 exp_month = 12;
 * @return {number}
 */
proto.themis.StripeCreditCard.prototype.getExpMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.StripeCreditCard} returns this
 */
proto.themis.StripeCreditCard.prototype.setExpMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 exp_year = 13;
 * @return {number}
 */
proto.themis.StripeCreditCard.prototype.getExpYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.StripeCreditCard} returns this
 */
proto.themis.StripeCreditCard.prototype.setExpYear = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string fingerprint = 14;
 * @return {string}
 */
proto.themis.StripeCreditCard.prototype.getFingerprint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeCreditCard} returns this
 */
proto.themis.StripeCreditCard.prototype.setFingerprint = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string last4 = 15;
 * @return {string}
 */
proto.themis.StripeCreditCard.prototype.getLast4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeCreditCard} returns this
 */
proto.themis.StripeCreditCard.prototype.setLast4 = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.StripeSources.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.StripeSources.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.StripeSources.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.StripeSources} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.StripeSources.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paymentType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bankAccountsList: jspb.Message.toObjectList(msg.getBankAccountsList(),
    proto.themis.StripeBankAccount.toObject, includeInstance),
    creditCardsList: jspb.Message.toObjectList(msg.getCreditCardsList(),
    proto.themis.StripeCreditCard.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.StripeSources}
 */
proto.themis.StripeSources.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.StripeSources;
  return proto.themis.StripeSources.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.StripeSources} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.StripeSources}
 */
proto.themis.StripeSources.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {!proto.themis.PaymentType} */ (reader.readEnum());
      msg.setPaymentType(value);
      break;
    case 5:
      var value = new proto.themis.StripeBankAccount;
      reader.readMessage(value,proto.themis.StripeBankAccount.deserializeBinaryFromReader);
      msg.addBankAccounts(value);
      break;
    case 6:
      var value = new proto.themis.StripeCreditCard;
      reader.readMessage(value,proto.themis.StripeCreditCard.deserializeBinaryFromReader);
      msg.addCreditCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.StripeSources.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.StripeSources.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.StripeSources} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.StripeSources.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPaymentType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBankAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.themis.StripeBankAccount.serializeBinaryToWriter
    );
  }
  f = message.getCreditCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.themis.StripeCreditCard.serializeBinaryToWriter
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.themis.StripeSources.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.StripeSources} returns this
 */
proto.themis.StripeSources.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PaymentType payment_type = 2;
 * @return {!proto.themis.PaymentType}
 */
proto.themis.StripeSources.prototype.getPaymentType = function() {
  return /** @type {!proto.themis.PaymentType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.themis.PaymentType} value
 * @return {!proto.themis.StripeSources} returns this
 */
proto.themis.StripeSources.prototype.setPaymentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated StripeBankAccount bank_accounts = 5;
 * @return {!Array<!proto.themis.StripeBankAccount>}
 */
proto.themis.StripeSources.prototype.getBankAccountsList = function() {
  return /** @type{!Array<!proto.themis.StripeBankAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.StripeBankAccount, 5));
};


/**
 * @param {!Array<!proto.themis.StripeBankAccount>} value
 * @return {!proto.themis.StripeSources} returns this
*/
proto.themis.StripeSources.prototype.setBankAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.themis.StripeBankAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.StripeBankAccount}
 */
proto.themis.StripeSources.prototype.addBankAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.themis.StripeBankAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.StripeSources} returns this
 */
proto.themis.StripeSources.prototype.clearBankAccountsList = function() {
  return this.setBankAccountsList([]);
};


/**
 * repeated StripeCreditCard credit_cards = 6;
 * @return {!Array<!proto.themis.StripeCreditCard>}
 */
proto.themis.StripeSources.prototype.getCreditCardsList = function() {
  return /** @type{!Array<!proto.themis.StripeCreditCard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.StripeCreditCard, 6));
};


/**
 * @param {!Array<!proto.themis.StripeCreditCard>} value
 * @return {!proto.themis.StripeSources} returns this
*/
proto.themis.StripeSources.prototype.setCreditCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.themis.StripeCreditCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.StripeCreditCard}
 */
proto.themis.StripeSources.prototype.addCreditCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.themis.StripeCreditCard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.StripeSources} returns this
 */
proto.themis.StripeSources.prototype.clearCreditCardsList = function() {
  return this.setCreditCardsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ClockTime.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ClockTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ClockTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ClockTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    seconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nanos: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cheerTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unixTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    originTime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    formattedDateTime: (f = msg.getFormattedDateTime()) && proto.themis.FormattedDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ClockTime}
 */
proto.themis.ClockTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ClockTime;
  return proto.themis.ClockTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ClockTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ClockTime}
 */
proto.themis.ClockTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNanos(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCheerTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnixTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginTime(value);
      break;
    case 7:
      var value = new proto.themis.FormattedDateTime;
      reader.readMessage(value,proto.themis.FormattedDateTime.deserializeBinaryFromReader);
      msg.setFormattedDateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ClockTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ClockTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ClockTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ClockTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeconds();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNanos();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCheerTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUnixTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getOriginTime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFormattedDateTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.themis.FormattedDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 seconds = 1;
 * @return {number}
 */
proto.themis.ClockTime.prototype.getSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ClockTime} returns this
 */
proto.themis.ClockTime.prototype.setSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 nanos = 2;
 * @return {number}
 */
proto.themis.ClockTime.prototype.getNanos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ClockTime} returns this
 */
proto.themis.ClockTime.prototype.setNanos = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 offset = 3;
 * @return {number}
 */
proto.themis.ClockTime.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ClockTime} returns this
 */
proto.themis.ClockTime.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cheer_time = 4;
 * @return {number}
 */
proto.themis.ClockTime.prototype.getCheerTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ClockTime} returns this
 */
proto.themis.ClockTime.prototype.setCheerTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 unix_time = 5;
 * @return {number}
 */
proto.themis.ClockTime.prototype.getUnixTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ClockTime} returns this
 */
proto.themis.ClockTime.prototype.setUnixTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string origin_time = 6;
 * @return {string}
 */
proto.themis.ClockTime.prototype.getOriginTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ClockTime} returns this
 */
proto.themis.ClockTime.prototype.setOriginTime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional FormattedDateTime formatted_date_time = 7;
 * @return {?proto.themis.FormattedDateTime}
 */
proto.themis.ClockTime.prototype.getFormattedDateTime = function() {
  return /** @type{?proto.themis.FormattedDateTime} */ (
    jspb.Message.getWrapperField(this, proto.themis.FormattedDateTime, 7));
};


/**
 * @param {?proto.themis.FormattedDateTime|undefined} value
 * @return {!proto.themis.ClockTime} returns this
*/
proto.themis.ClockTime.prototype.setFormattedDateTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.ClockTime} returns this
 */
proto.themis.ClockTime.prototype.clearFormattedDateTime = function() {
  return this.setFormattedDateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.ClockTime.prototype.hasFormattedDateTime = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.FormattedDateTime.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.FormattedDateTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.FormattedDateTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.FormattedDateTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, ""),
    monthLong: jspb.Message.getFieldWithDefault(msg, 2, ""),
    monthShort: jspb.Message.getFieldWithDefault(msg, 3, ""),
    monthLeadingNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    monthNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    weekDay: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dayLeadingNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dayNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    hour24leadingNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    hour24number: jspb.Message.getFieldWithDefault(msg, 10, ""),
    hour12LeadingNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    hour12Number: jspb.Message.getFieldWithDefault(msg, 12, ""),
    amPm: jspb.Message.getFieldWithDefault(msg, 13, ""),
    minutesLeadingNumber: jspb.Message.getFieldWithDefault(msg, 14, ""),
    minutesNumber: jspb.Message.getFieldWithDefault(msg, 15, ""),
    secondsLeadingNumber: jspb.Message.getFieldWithDefault(msg, 16, ""),
    secondsNumber: jspb.Message.getFieldWithDefault(msg, 17, ""),
    timeZone: jspb.Message.getFieldWithDefault(msg, 18, ""),
    timeZoneHrOffset: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.FormattedDateTime}
 */
proto.themis.FormattedDateTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.FormattedDateTime;
  return proto.themis.FormattedDateTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.FormattedDateTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.FormattedDateTime}
 */
proto.themis.FormattedDateTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonthLong(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonthShort(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonthLeadingNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonthNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeekDay(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDayLeadingNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDayNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHour24leadingNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHour24number(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHour12LeadingNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setHour12Number(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmPm(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinutesLeadingNumber(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinutesNumber(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondsLeadingNumber(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondsNumber(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZone(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeZoneHrOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.FormattedDateTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.FormattedDateTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.FormattedDateTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.FormattedDateTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonthLong();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMonthShort();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMonthLeadingNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMonthNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWeekDay();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDayLeadingNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDayNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getHour24leadingNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHour24number();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHour12LeadingNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getHour12Number();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAmPm();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMinutesLeadingNumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMinutesNumber();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSecondsLeadingNumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSecondsNumber();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTimeZone();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTimeZoneHrOffset();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string month_long = 2;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getMonthLong = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setMonthLong = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string month_short = 3;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getMonthShort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setMonthShort = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string month_leading_number = 4;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getMonthLeadingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setMonthLeadingNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string month_number = 5;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getMonthNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setMonthNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string week_day = 6;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getWeekDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setWeekDay = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string day_leading_number = 7;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getDayLeadingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setDayLeadingNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string day_number = 8;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getDayNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setDayNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string hour_24leading_number = 9;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getHour24leadingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setHour24leadingNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string hour_24number = 10;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getHour24number = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setHour24number = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string hour_12_leading_number = 11;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getHour12LeadingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setHour12LeadingNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string hour_12_number = 12;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getHour12Number = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setHour12Number = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string am_pm = 13;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getAmPm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setAmPm = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string minutes_leading_number = 14;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getMinutesLeadingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setMinutesLeadingNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string minutes_number = 15;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getMinutesNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setMinutesNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string seconds_leading_number = 16;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getSecondsLeadingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setSecondsLeadingNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string seconds_number = 17;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getSecondsNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setSecondsNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string time_zone = 18;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getTimeZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setTimeZone = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string time_zone_hr_offset = 19;
 * @return {string}
 */
proto.themis.FormattedDateTime.prototype.getTimeZoneHrOffset = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FormattedDateTime} returns this
 */
proto.themis.FormattedDateTime.prototype.setTimeZoneHrOffset = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AddLogoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AddLogoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AddLogoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddLogoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    image: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AddLogoRequest}
 */
proto.themis.AddLogoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AddLogoRequest;
  return proto.themis.AddLogoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AddLogoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AddLogoRequest}
 */
proto.themis.AddLogoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AddLogoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AddLogoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AddLogoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AddLogoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.AddLogoRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AddLogoRequest} returns this
 */
proto.themis.AddLogoRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string image = 2;
 * @return {string}
 */
proto.themis.AddLogoRequest.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddLogoRequest} returns this
 */
proto.themis.AddLogoRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_name = 3;
 * @return {string}
 */
proto.themis.AddLogoRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.AddLogoRequest} returns this
 */
proto.themis.AddLogoRequest.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DocumentSignature.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DocumentSignature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DocumentSignature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentSignature.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    guardianId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    guardian: (f = msg.getGuardian()) && proto.themis.Guardian.toObject(includeInstance, f),
    eventDocumentId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    eventDocument: (f = msg.getEventDocument()) && proto.themis.EventDocument.toObject(includeInstance, f),
    signature: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    signTime: (f = msg.getSignTime()) && proto.themis.FormattedDateTime.toObject(includeInstance, f),
    signCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DocumentSignature}
 */
proto.themis.DocumentSignature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DocumentSignature;
  return proto.themis.DocumentSignature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DocumentSignature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DocumentSignature}
 */
proto.themis.DocumentSignature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGuardianId(value);
      break;
    case 3:
      var value = new proto.themis.Guardian;
      reader.readMessage(value,proto.themis.Guardian.deserializeBinaryFromReader);
      msg.setGuardian(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventDocumentId(value);
      break;
    case 5:
      var value = new proto.themis.EventDocument;
      reader.readMessage(value,proto.themis.EventDocument.deserializeBinaryFromReader);
      msg.setEventDocument(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignature(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 8:
      var value = new proto.themis.FormattedDateTime;
      reader.readMessage(value,proto.themis.FormattedDateTime.deserializeBinaryFromReader);
      msg.setSignTime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignCode(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DocumentSignature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DocumentSignature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DocumentSignature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentSignature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getGuardianId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getGuardian();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.Guardian.serializeBinaryToWriter
    );
  }
  f = message.getEventDocumentId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getEventDocument();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.themis.EventDocument.serializeBinaryToWriter
    );
  }
  f = message.getSignature();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSignTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.themis.FormattedDateTime.serializeBinaryToWriter
    );
  }
  f = message.getSignCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.DocumentSignature.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DocumentSignature} returns this
 */
proto.themis.DocumentSignature.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 guardian_id = 2;
 * @return {number}
 */
proto.themis.DocumentSignature.prototype.getGuardianId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DocumentSignature} returns this
 */
proto.themis.DocumentSignature.prototype.setGuardianId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Guardian guardian = 3;
 * @return {?proto.themis.Guardian}
 */
proto.themis.DocumentSignature.prototype.getGuardian = function() {
  return /** @type{?proto.themis.Guardian} */ (
    jspb.Message.getWrapperField(this, proto.themis.Guardian, 3));
};


/**
 * @param {?proto.themis.Guardian|undefined} value
 * @return {!proto.themis.DocumentSignature} returns this
*/
proto.themis.DocumentSignature.prototype.setGuardian = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DocumentSignature} returns this
 */
proto.themis.DocumentSignature.prototype.clearGuardian = function() {
  return this.setGuardian(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DocumentSignature.prototype.hasGuardian = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 event_document_id = 4;
 * @return {number}
 */
proto.themis.DocumentSignature.prototype.getEventDocumentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.DocumentSignature} returns this
 */
proto.themis.DocumentSignature.prototype.setEventDocumentId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional EventDocument event_document = 5;
 * @return {?proto.themis.EventDocument}
 */
proto.themis.DocumentSignature.prototype.getEventDocument = function() {
  return /** @type{?proto.themis.EventDocument} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventDocument, 5));
};


/**
 * @param {?proto.themis.EventDocument|undefined} value
 * @return {!proto.themis.DocumentSignature} returns this
*/
proto.themis.DocumentSignature.prototype.setEventDocument = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DocumentSignature} returns this
 */
proto.themis.DocumentSignature.prototype.clearEventDocument = function() {
  return this.setEventDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DocumentSignature.prototype.hasEventDocument = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string signature = 6;
 * @return {string}
 */
proto.themis.DocumentSignature.prototype.getSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DocumentSignature} returns this
 */
proto.themis.DocumentSignature.prototype.setSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ip_address = 7;
 * @return {string}
 */
proto.themis.DocumentSignature.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DocumentSignature} returns this
 */
proto.themis.DocumentSignature.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional FormattedDateTime sign_time = 8;
 * @return {?proto.themis.FormattedDateTime}
 */
proto.themis.DocumentSignature.prototype.getSignTime = function() {
  return /** @type{?proto.themis.FormattedDateTime} */ (
    jspb.Message.getWrapperField(this, proto.themis.FormattedDateTime, 8));
};


/**
 * @param {?proto.themis.FormattedDateTime|undefined} value
 * @return {!proto.themis.DocumentSignature} returns this
*/
proto.themis.DocumentSignature.prototype.setSignTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DocumentSignature} returns this
 */
proto.themis.DocumentSignature.prototype.clearSignTime = function() {
  return this.setSignTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DocumentSignature.prototype.hasSignTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string sign_code = 9;
 * @return {string}
 */
proto.themis.DocumentSignature.prototype.getSignCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DocumentSignature} returns this
 */
proto.themis.DocumentSignature.prototype.setSignCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool status = 10;
 * @return {boolean}
 */
proto.themis.DocumentSignature.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.DocumentSignature} returns this
 */
proto.themis.DocumentSignature.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eventId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    event: (f = msg.getEvent()) && proto.themis.Event.toObject(includeInstance, f),
    brandId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    status: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    signed: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDocument}
 */
proto.themis.EventDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDocument;
  return proto.themis.EventDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDocument}
 */
proto.themis.EventDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    case 5:
      var value = new proto.themis.Event;
      reader.readMessage(value,proto.themis.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSigned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.themis.Event.serializeBinaryToWriter
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSigned();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventDocument.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDocument} returns this
 */
proto.themis.EventDocument.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.EventDocument.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDocument} returns this
 */
proto.themis.EventDocument.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.themis.EventDocument.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDocument} returns this
 */
proto.themis.EventDocument.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 event_id = 4;
 * @return {number}
 */
proto.themis.EventDocument.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDocument} returns this
 */
proto.themis.EventDocument.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Event event = 5;
 * @return {?proto.themis.Event}
 */
proto.themis.EventDocument.prototype.getEvent = function() {
  return /** @type{?proto.themis.Event} */ (
    jspb.Message.getWrapperField(this, proto.themis.Event, 5));
};


/**
 * @param {?proto.themis.Event|undefined} value
 * @return {!proto.themis.EventDocument} returns this
*/
proto.themis.EventDocument.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDocument} returns this
 */
proto.themis.EventDocument.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDocument.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 brand_id = 6;
 * @return {number}
 */
proto.themis.EventDocument.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDocument} returns this
 */
proto.themis.EventDocument.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 producer_id = 7;
 * @return {number}
 */
proto.themis.EventDocument.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDocument} returns this
 */
proto.themis.EventDocument.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool status = 8;
 * @return {boolean}
 */
proto.themis.EventDocument.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventDocument} returns this
 */
proto.themis.EventDocument.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional uint32 signed = 9;
 * @return {number}
 */
proto.themis.EventDocument.prototype.getSigned = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDocument} returns this
 */
proto.themis.EventDocument.prototype.setSigned = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.DocumentSigningSet.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DocumentSigningSet.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DocumentSigningSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DocumentSigningSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentSigningSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    athlete: (f = msg.getAthlete()) && proto.themis.Athlete.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && proto.themis.Event.toObject(includeInstance, f),
    guardiansList: jspb.Message.toObjectList(msg.getGuardiansList(),
    proto.themis.DocumentSignatureGuardian.toObject, includeInstance),
    signTime: (f = msg.getSignTime()) && proto.themis.FormattedDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DocumentSigningSet}
 */
proto.themis.DocumentSigningSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DocumentSigningSet;
  return proto.themis.DocumentSigningSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DocumentSigningSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DocumentSigningSet}
 */
proto.themis.DocumentSigningSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.Athlete;
      reader.readMessage(value,proto.themis.Athlete.deserializeBinaryFromReader);
      msg.setAthlete(value);
      break;
    case 2:
      var value = new proto.themis.Event;
      reader.readMessage(value,proto.themis.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 3:
      var value = new proto.themis.DocumentSignatureGuardian;
      reader.readMessage(value,proto.themis.DocumentSignatureGuardian.deserializeBinaryFromReader);
      msg.addGuardians(value);
      break;
    case 4:
      var value = new proto.themis.FormattedDateTime;
      reader.readMessage(value,proto.themis.FormattedDateTime.deserializeBinaryFromReader);
      msg.setSignTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DocumentSigningSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DocumentSigningSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DocumentSigningSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentSigningSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAthlete();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.themis.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.Event.serializeBinaryToWriter
    );
  }
  f = message.getGuardiansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.themis.DocumentSignatureGuardian.serializeBinaryToWriter
    );
  }
  f = message.getSignTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.themis.FormattedDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional Athlete athlete = 1;
 * @return {?proto.themis.Athlete}
 */
proto.themis.DocumentSigningSet.prototype.getAthlete = function() {
  return /** @type{?proto.themis.Athlete} */ (
    jspb.Message.getWrapperField(this, proto.themis.Athlete, 1));
};


/**
 * @param {?proto.themis.Athlete|undefined} value
 * @return {!proto.themis.DocumentSigningSet} returns this
*/
proto.themis.DocumentSigningSet.prototype.setAthlete = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DocumentSigningSet} returns this
 */
proto.themis.DocumentSigningSet.prototype.clearAthlete = function() {
  return this.setAthlete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DocumentSigningSet.prototype.hasAthlete = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Event event = 2;
 * @return {?proto.themis.Event}
 */
proto.themis.DocumentSigningSet.prototype.getEvent = function() {
  return /** @type{?proto.themis.Event} */ (
    jspb.Message.getWrapperField(this, proto.themis.Event, 2));
};


/**
 * @param {?proto.themis.Event|undefined} value
 * @return {!proto.themis.DocumentSigningSet} returns this
*/
proto.themis.DocumentSigningSet.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DocumentSigningSet} returns this
 */
proto.themis.DocumentSigningSet.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DocumentSigningSet.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated DocumentSignatureGuardian guardians = 3;
 * @return {!Array<!proto.themis.DocumentSignatureGuardian>}
 */
proto.themis.DocumentSigningSet.prototype.getGuardiansList = function() {
  return /** @type{!Array<!proto.themis.DocumentSignatureGuardian>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.DocumentSignatureGuardian, 3));
};


/**
 * @param {!Array<!proto.themis.DocumentSignatureGuardian>} value
 * @return {!proto.themis.DocumentSigningSet} returns this
*/
proto.themis.DocumentSigningSet.prototype.setGuardiansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.DocumentSignatureGuardian=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.DocumentSignatureGuardian}
 */
proto.themis.DocumentSigningSet.prototype.addGuardians = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.DocumentSignatureGuardian, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.DocumentSigningSet} returns this
 */
proto.themis.DocumentSigningSet.prototype.clearGuardiansList = function() {
  return this.setGuardiansList([]);
};


/**
 * optional FormattedDateTime sign_time = 4;
 * @return {?proto.themis.FormattedDateTime}
 */
proto.themis.DocumentSigningSet.prototype.getSignTime = function() {
  return /** @type{?proto.themis.FormattedDateTime} */ (
    jspb.Message.getWrapperField(this, proto.themis.FormattedDateTime, 4));
};


/**
 * @param {?proto.themis.FormattedDateTime|undefined} value
 * @return {!proto.themis.DocumentSigningSet} returns this
*/
proto.themis.DocumentSigningSet.prototype.setSignTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DocumentSigningSet} returns this
 */
proto.themis.DocumentSigningSet.prototype.clearSignTime = function() {
  return this.setSignTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DocumentSigningSet.prototype.hasSignTime = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.DocumentSignatureGuardian.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.DocumentSignatureGuardian.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.DocumentSignatureGuardian} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentSignatureGuardian.toObject = function(includeInstance, msg) {
  var f, obj = {
    guardian: (f = msg.getGuardian()) && proto.themis.Guardian.toObject(includeInstance, f),
    documentSignature: (f = msg.getDocumentSignature()) && proto.themis.DocumentSigningSet.toObject(includeInstance, f),
    signCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    agreeElectroncRecordsSignatures: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.DocumentSignatureGuardian}
 */
proto.themis.DocumentSignatureGuardian.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.DocumentSignatureGuardian;
  return proto.themis.DocumentSignatureGuardian.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.DocumentSignatureGuardian} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.DocumentSignatureGuardian}
 */
proto.themis.DocumentSignatureGuardian.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.Guardian;
      reader.readMessage(value,proto.themis.Guardian.deserializeBinaryFromReader);
      msg.setGuardian(value);
      break;
    case 2:
      var value = new proto.themis.DocumentSigningSet;
      reader.readMessage(value,proto.themis.DocumentSigningSet.deserializeBinaryFromReader);
      msg.setDocumentSignature(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignCode(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAgreeElectroncRecordsSignatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.DocumentSignatureGuardian.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.DocumentSignatureGuardian.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.DocumentSignatureGuardian} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.DocumentSignatureGuardian.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGuardian();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.themis.Guardian.serializeBinaryToWriter
    );
  }
  f = message.getDocumentSignature();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.DocumentSigningSet.serializeBinaryToWriter
    );
  }
  f = message.getSignCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAgreeElectroncRecordsSignatures();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional Guardian guardian = 1;
 * @return {?proto.themis.Guardian}
 */
proto.themis.DocumentSignatureGuardian.prototype.getGuardian = function() {
  return /** @type{?proto.themis.Guardian} */ (
    jspb.Message.getWrapperField(this, proto.themis.Guardian, 1));
};


/**
 * @param {?proto.themis.Guardian|undefined} value
 * @return {!proto.themis.DocumentSignatureGuardian} returns this
*/
proto.themis.DocumentSignatureGuardian.prototype.setGuardian = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DocumentSignatureGuardian} returns this
 */
proto.themis.DocumentSignatureGuardian.prototype.clearGuardian = function() {
  return this.setGuardian(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DocumentSignatureGuardian.prototype.hasGuardian = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DocumentSigningSet document_signature = 2;
 * @return {?proto.themis.DocumentSigningSet}
 */
proto.themis.DocumentSignatureGuardian.prototype.getDocumentSignature = function() {
  return /** @type{?proto.themis.DocumentSigningSet} */ (
    jspb.Message.getWrapperField(this, proto.themis.DocumentSigningSet, 2));
};


/**
 * @param {?proto.themis.DocumentSigningSet|undefined} value
 * @return {!proto.themis.DocumentSignatureGuardian} returns this
*/
proto.themis.DocumentSignatureGuardian.prototype.setDocumentSignature = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.DocumentSignatureGuardian} returns this
 */
proto.themis.DocumentSignatureGuardian.prototype.clearDocumentSignature = function() {
  return this.setDocumentSignature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.DocumentSignatureGuardian.prototype.hasDocumentSignature = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string sign_code = 4;
 * @return {string}
 */
proto.themis.DocumentSignatureGuardian.prototype.getSignCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.DocumentSignatureGuardian} returns this
 */
proto.themis.DocumentSignatureGuardian.prototype.setSignCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool agree_electronc_records_signatures = 5;
 * @return {boolean}
 */
proto.themis.DocumentSignatureGuardian.prototype.getAgreeElectroncRecordsSignatures = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.DocumentSignatureGuardian} returns this
 */
proto.themis.DocumentSignatureGuardian.prototype.setAgreeElectroncRecordsSignatures = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.UploadCsvRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.UploadCsvRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.UploadCsvRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UploadCsvRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    csvFile: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hasHeader: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.UploadCsvRequest}
 */
proto.themis.UploadCsvRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.UploadCsvRequest;
  return proto.themis.UploadCsvRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.UploadCsvRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.UploadCsvRequest}
 */
proto.themis.UploadCsvRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCsvFile(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasHeader(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.UploadCsvRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.UploadCsvRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.UploadCsvRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.UploadCsvRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCsvFile();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHasHeader();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string csv_file = 1;
 * @return {string}
 */
proto.themis.UploadCsvRequest.prototype.getCsvFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UploadCsvRequest} returns this
 */
proto.themis.UploadCsvRequest.prototype.setCsvFile = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.themis.UploadCsvRequest.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.UploadCsvRequest} returns this
 */
proto.themis.UploadCsvRequest.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool has_header = 3;
 * @return {boolean}
 */
proto.themis.UploadCsvRequest.prototype.getHasHeader = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.UploadCsvRequest} returns this
 */
proto.themis.UploadCsvRequest.prototype.setHasHeader = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.themis.GenericKeyValue.oneofGroups_ = [[2,3,4,5,6,7,8,9]];

/**
 * @enum {number}
 */
proto.themis.GenericKeyValue.ValueCase = {
  VALUE_NOT_SET: 0,
  SINT64: 2,
  STRING: 3,
  DOUBLE: 4,
  EMAIL: 5,
  PHONE: 6,
  CLOCK_TIME: 7,
  RANGE: 8,
  ENUM: 9
};

/**
 * @return {proto.themis.GenericKeyValue.ValueCase}
 */
proto.themis.GenericKeyValue.prototype.getValueCase = function() {
  return /** @type {proto.themis.GenericKeyValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.themis.GenericKeyValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GenericKeyValue.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GenericKeyValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GenericKeyValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GenericKeyValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = msg.getKey()) && proto.themis.GenericFieldDescriptor.toObject(includeInstance, f),
    sint64: jspb.Message.getFieldWithDefault(msg, 2, 0),
    string: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pb_double: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    clockTime: (f = msg.getClockTime()) && proto.themis.ClockTime.toObject(includeInstance, f),
    range: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    pb_enum: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    nested: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    table: jspb.Message.getFieldWithDefault(msg, 16, ""),
    parentTable: jspb.Message.getFieldWithDefault(msg, 17, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    id: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GenericKeyValue}
 */
proto.themis.GenericKeyValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GenericKeyValue;
  return proto.themis.GenericKeyValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GenericKeyValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GenericKeyValue}
 */
proto.themis.GenericKeyValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.GenericFieldDescriptor;
      reader.readMessage(value,proto.themis.GenericFieldDescriptor.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSint64(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setString(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDouble(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 7:
      var value = new proto.themis.ClockTime;
      reader.readMessage(value,proto.themis.ClockTime.deserializeBinaryFromReader);
      msg.setClockTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRange(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEnum(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNested(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTable(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentTable(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GenericKeyValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GenericKeyValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GenericKeyValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GenericKeyValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.themis.GenericFieldDescriptor.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint64(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getClockTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.themis.ClockTime.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getNested();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getTable();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getParentTable();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional GenericFieldDescriptor key = 1;
 * @return {?proto.themis.GenericFieldDescriptor}
 */
proto.themis.GenericKeyValue.prototype.getKey = function() {
  return /** @type{?proto.themis.GenericFieldDescriptor} */ (
    jspb.Message.getWrapperField(this, proto.themis.GenericFieldDescriptor, 1));
};


/**
 * @param {?proto.themis.GenericFieldDescriptor|undefined} value
 * @return {!proto.themis.GenericKeyValue} returns this
*/
proto.themis.GenericKeyValue.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GenericKeyValue.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sint64 sint64 = 2;
 * @return {number}
 */
proto.themis.GenericKeyValue.prototype.getSint64 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setSint64 = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.themis.GenericKeyValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.clearSint64 = function() {
  return jspb.Message.setOneofField(this, 2, proto.themis.GenericKeyValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GenericKeyValue.prototype.hasSint64 = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string string = 3;
 * @return {string}
 */
proto.themis.GenericKeyValue.prototype.getString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setString = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.themis.GenericKeyValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.clearString = function() {
  return jspb.Message.setOneofField(this, 3, proto.themis.GenericKeyValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GenericKeyValue.prototype.hasString = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double double = 4;
 * @return {number}
 */
proto.themis.GenericKeyValue.prototype.getDouble = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setDouble = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.themis.GenericKeyValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.clearDouble = function() {
  return jspb.Message.setOneofField(this, 4, proto.themis.GenericKeyValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GenericKeyValue.prototype.hasDouble = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.themis.GenericKeyValue.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setEmail = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.themis.GenericKeyValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.clearEmail = function() {
  return jspb.Message.setOneofField(this, 5, proto.themis.GenericKeyValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GenericKeyValue.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.themis.GenericKeyValue.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setPhone = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.themis.GenericKeyValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.clearPhone = function() {
  return jspb.Message.setOneofField(this, 6, proto.themis.GenericKeyValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GenericKeyValue.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ClockTime clock_time = 7;
 * @return {?proto.themis.ClockTime}
 */
proto.themis.GenericKeyValue.prototype.getClockTime = function() {
  return /** @type{?proto.themis.ClockTime} */ (
    jspb.Message.getWrapperField(this, proto.themis.ClockTime, 7));
};


/**
 * @param {?proto.themis.ClockTime|undefined} value
 * @return {!proto.themis.GenericKeyValue} returns this
*/
proto.themis.GenericKeyValue.prototype.setClockTime = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.themis.GenericKeyValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.clearClockTime = function() {
  return this.setClockTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GenericKeyValue.prototype.hasClockTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double range = 8;
 * @return {number}
 */
proto.themis.GenericKeyValue.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setRange = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.themis.GenericKeyValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.clearRange = function() {
  return jspb.Message.setOneofField(this, 8, proto.themis.GenericKeyValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GenericKeyValue.prototype.hasRange = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional double enum = 9;
 * @return {number}
 */
proto.themis.GenericKeyValue.prototype.getEnum = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setEnum = function(value) {
  return jspb.Message.setOneofField(this, 9, proto.themis.GenericKeyValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.clearEnum = function() {
  return jspb.Message.setOneofField(this, 9, proto.themis.GenericKeyValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GenericKeyValue.prototype.hasEnum = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool nested = 15;
 * @return {boolean}
 */
proto.themis.GenericKeyValue.prototype.getNested = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setNested = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string table = 16;
 * @return {string}
 */
proto.themis.GenericKeyValue.prototype.getTable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setTable = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string parent_table = 17;
 * @return {string}
 */
proto.themis.GenericKeyValue.prototype.getParentTable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setParentTable = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string parent_id = 18;
 * @return {string}
 */
proto.themis.GenericKeyValue.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string id = 19;
 * @return {string}
 */
proto.themis.GenericKeyValue.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericKeyValue} returns this
 */
proto.themis.GenericKeyValue.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GenericRange.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GenericRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GenericRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GenericRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rangeMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    rangeMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GenericRange}
 */
proto.themis.GenericRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GenericRange;
  return proto.themis.GenericRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GenericRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GenericRange}
 */
proto.themis.GenericRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRangeMin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRangeMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GenericRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GenericRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GenericRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GenericRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRangeMin();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getRangeMax();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.themis.GenericRange.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericRange} returns this
 */
proto.themis.GenericRange.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.themis.GenericRange.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericRange} returns this
 */
proto.themis.GenericRange.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double range_min = 3;
 * @return {number}
 */
proto.themis.GenericRange.prototype.getRangeMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GenericRange} returns this
 */
proto.themis.GenericRange.prototype.setRangeMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double range_max = 4;
 * @return {number}
 */
proto.themis.GenericRange.prototype.getRangeMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GenericRange} returns this
 */
proto.themis.GenericRange.prototype.setRangeMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GenericEnum.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GenericEnum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GenericEnum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GenericEnum.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GenericEnum}
 */
proto.themis.GenericEnum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GenericEnum;
  return proto.themis.GenericEnum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GenericEnum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GenericEnum}
 */
proto.themis.GenericEnum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GenericEnum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GenericEnum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GenericEnum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GenericEnum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.themis.GenericEnum.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericEnum} returns this
 */
proto.themis.GenericEnum.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.themis.GenericEnum.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericEnum} returns this
 */
proto.themis.GenericEnum.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double value = 3;
 * @return {number}
 */
proto.themis.GenericEnum.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GenericEnum} returns this
 */
proto.themis.GenericEnum.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.GenericFieldDescriptor.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GenericFieldDescriptor.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GenericFieldDescriptor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GenericFieldDescriptor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GenericFieldDescriptor.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rangesList: jspb.Message.toObjectList(msg.getRangesList(),
    proto.themis.GenericRange.toObject, includeInstance),
    enumsList: jspb.Message.toObjectList(msg.getEnumsList(),
    proto.themis.GenericEnum.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GenericFieldDescriptor}
 */
proto.themis.GenericFieldDescriptor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GenericFieldDescriptor;
  return proto.themis.GenericFieldDescriptor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GenericFieldDescriptor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GenericFieldDescriptor}
 */
proto.themis.GenericFieldDescriptor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.themis.ValueType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.themis.GenericRange;
      reader.readMessage(value,proto.themis.GenericRange.deserializeBinaryFromReader);
      msg.addRanges(value);
      break;
    case 5:
      var value = new proto.themis.GenericEnum;
      reader.readMessage(value,proto.themis.GenericEnum.deserializeBinaryFromReader);
      msg.addEnums(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GenericFieldDescriptor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GenericFieldDescriptor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GenericFieldDescriptor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GenericFieldDescriptor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.themis.GenericRange.serializeBinaryToWriter
    );
  }
  f = message.getEnumsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.themis.GenericEnum.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.themis.GenericFieldDescriptor.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericFieldDescriptor} returns this
 */
proto.themis.GenericFieldDescriptor.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.GenericFieldDescriptor.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.GenericFieldDescriptor} returns this
 */
proto.themis.GenericFieldDescriptor.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ValueType type = 3;
 * @return {!proto.themis.ValueType}
 */
proto.themis.GenericFieldDescriptor.prototype.getType = function() {
  return /** @type {!proto.themis.ValueType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.themis.ValueType} value
 * @return {!proto.themis.GenericFieldDescriptor} returns this
 */
proto.themis.GenericFieldDescriptor.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated GenericRange ranges = 4;
 * @return {!Array<!proto.themis.GenericRange>}
 */
proto.themis.GenericFieldDescriptor.prototype.getRangesList = function() {
  return /** @type{!Array<!proto.themis.GenericRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.GenericRange, 4));
};


/**
 * @param {!Array<!proto.themis.GenericRange>} value
 * @return {!proto.themis.GenericFieldDescriptor} returns this
*/
proto.themis.GenericFieldDescriptor.prototype.setRangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.GenericRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.GenericRange}
 */
proto.themis.GenericFieldDescriptor.prototype.addRanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.GenericRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GenericFieldDescriptor} returns this
 */
proto.themis.GenericFieldDescriptor.prototype.clearRangesList = function() {
  return this.setRangesList([]);
};


/**
 * repeated GenericEnum enums = 5;
 * @return {!Array<!proto.themis.GenericEnum>}
 */
proto.themis.GenericFieldDescriptor.prototype.getEnumsList = function() {
  return /** @type{!Array<!proto.themis.GenericEnum>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.GenericEnum, 5));
};


/**
 * @param {!Array<!proto.themis.GenericEnum>} value
 * @return {!proto.themis.GenericFieldDescriptor} returns this
*/
proto.themis.GenericFieldDescriptor.prototype.setEnumsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.themis.GenericEnum=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.GenericEnum}
 */
proto.themis.GenericFieldDescriptor.prototype.addEnums = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.themis.GenericEnum, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.GenericFieldDescriptor} returns this
 */
proto.themis.GenericFieldDescriptor.prototype.clearEnumsList = function() {
  return this.setEnumsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.TableSnapshot.repeatedFields_ = [3,4,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.TableSnapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.TableSnapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.TableSnapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TableSnapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.themis.GenericFieldDescriptor.toObject, includeInstance),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.themis.RowSnapshot.toObject, includeInstance),
    hasParent: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hasChildren: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    parentId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    parentTable: jspb.Message.getFieldWithDefault(msg, 8, ""),
    childrenTablesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.TableSnapshot}
 */
proto.themis.TableSnapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.TableSnapshot;
  return proto.themis.TableSnapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.TableSnapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.TableSnapshot}
 */
proto.themis.TableSnapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.themis.GenericFieldDescriptor;
      reader.readMessage(value,proto.themis.GenericFieldDescriptor.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 4:
      var value = new proto.themis.RowSnapshot;
      reader.readMessage(value,proto.themis.RowSnapshot.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasParent(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasChildren(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setParentId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentTable(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addChildrenTables(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.TableSnapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.TableSnapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.TableSnapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.TableSnapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.themis.GenericFieldDescriptor.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.themis.RowSnapshot.serializeBinaryToWriter
    );
  }
  f = message.getHasParent();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHasChildren();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getParentTable();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getChildrenTablesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.themis.TableSnapshot.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.TableSnapshot} returns this
 */
proto.themis.TableSnapshot.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.TableSnapshot.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.TableSnapshot} returns this
 */
proto.themis.TableSnapshot.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated GenericFieldDescriptor fields = 3;
 * @return {!Array<!proto.themis.GenericFieldDescriptor>}
 */
proto.themis.TableSnapshot.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.themis.GenericFieldDescriptor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.GenericFieldDescriptor, 3));
};


/**
 * @param {!Array<!proto.themis.GenericFieldDescriptor>} value
 * @return {!proto.themis.TableSnapshot} returns this
*/
proto.themis.TableSnapshot.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.themis.GenericFieldDescriptor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.GenericFieldDescriptor}
 */
proto.themis.TableSnapshot.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.themis.GenericFieldDescriptor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.TableSnapshot} returns this
 */
proto.themis.TableSnapshot.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * repeated RowSnapshot items = 4;
 * @return {!Array<!proto.themis.RowSnapshot>}
 */
proto.themis.TableSnapshot.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.themis.RowSnapshot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.RowSnapshot, 4));
};


/**
 * @param {!Array<!proto.themis.RowSnapshot>} value
 * @return {!proto.themis.TableSnapshot} returns this
*/
proto.themis.TableSnapshot.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.RowSnapshot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.RowSnapshot}
 */
proto.themis.TableSnapshot.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.RowSnapshot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.TableSnapshot} returns this
 */
proto.themis.TableSnapshot.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool has_parent = 5;
 * @return {boolean}
 */
proto.themis.TableSnapshot.prototype.getHasParent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.TableSnapshot} returns this
 */
proto.themis.TableSnapshot.prototype.setHasParent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool has_children = 6;
 * @return {boolean}
 */
proto.themis.TableSnapshot.prototype.getHasChildren = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.TableSnapshot} returns this
 */
proto.themis.TableSnapshot.prototype.setHasChildren = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional uint32 parent_id = 7;
 * @return {number}
 */
proto.themis.TableSnapshot.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.TableSnapshot} returns this
 */
proto.themis.TableSnapshot.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string parent_table = 8;
 * @return {string}
 */
proto.themis.TableSnapshot.prototype.getParentTable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.TableSnapshot} returns this
 */
proto.themis.TableSnapshot.prototype.setParentTable = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string children_tables = 9;
 * @return {!Array<string>}
 */
proto.themis.TableSnapshot.prototype.getChildrenTablesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.themis.TableSnapshot} returns this
 */
proto.themis.TableSnapshot.prototype.setChildrenTablesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.themis.TableSnapshot} returns this
 */
proto.themis.TableSnapshot.prototype.addChildrenTables = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.TableSnapshot} returns this
 */
proto.themis.TableSnapshot.prototype.clearChildrenTablesList = function() {
  return this.setChildrenTablesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.RowSnapshot.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RowSnapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RowSnapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RowSnapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RowSnapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.themis.GenericKeyValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RowSnapshot}
 */
proto.themis.RowSnapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RowSnapshot;
  return proto.themis.RowSnapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RowSnapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RowSnapshot}
 */
proto.themis.RowSnapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.GenericKeyValue;
      reader.readMessage(value,proto.themis.GenericKeyValue.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RowSnapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RowSnapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RowSnapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RowSnapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.themis.GenericKeyValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.themis.RowSnapshot.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RowSnapshot} returns this
 */
proto.themis.RowSnapshot.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated GenericKeyValue fields = 2;
 * @return {!Array<!proto.themis.GenericKeyValue>}
 */
proto.themis.RowSnapshot.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.themis.GenericKeyValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.GenericKeyValue, 2));
};


/**
 * @param {!Array<!proto.themis.GenericKeyValue>} value
 * @return {!proto.themis.RowSnapshot} returns this
*/
proto.themis.RowSnapshot.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.GenericKeyValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.GenericKeyValue}
 */
proto.themis.RowSnapshot.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.GenericKeyValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.RowSnapshot} returns this
 */
proto.themis.RowSnapshot.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.FlatCsv.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.FlatCsv.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.FlatCsv} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.FlatCsv.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && proto.themis.TableSnapshot.toObject(includeInstance, f),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.FlatCsv}
 */
proto.themis.FlatCsv.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.FlatCsv;
  return proto.themis.FlatCsv.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.FlatCsv} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.FlatCsv}
 */
proto.themis.FlatCsv.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.TableSnapshot;
      reader.readMessage(value,proto.themis.TableSnapshot.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.FlatCsv.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.FlatCsv.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.FlatCsv} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.FlatCsv.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.themis.TableSnapshot.serializeBinaryToWriter
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional TableSnapshot data = 1;
 * @return {?proto.themis.TableSnapshot}
 */
proto.themis.FlatCsv.prototype.getData = function() {
  return /** @type{?proto.themis.TableSnapshot} */ (
    jspb.Message.getWrapperField(this, proto.themis.TableSnapshot, 1));
};


/**
 * @param {?proto.themis.TableSnapshot|undefined} value
 * @return {!proto.themis.FlatCsv} returns this
*/
proto.themis.FlatCsv.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.FlatCsv} returns this
 */
proto.themis.FlatCsv.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.FlatCsv.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.themis.FlatCsv.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.FlatCsv} returns this
 */
proto.themis.FlatCsv.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.GuardianAhtleteValid.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.GuardianAhtleteValid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.GuardianAhtleteValid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GuardianAhtleteValid.toObject = function(includeInstance, msg) {
  var f, obj = {
    guardianId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    guardian: (f = msg.getGuardian()) && proto.themis.Guardian.toObject(includeInstance, f),
    athleteId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    athlete: (f = msg.getAthlete()) && proto.themis.Athlete.toObject(includeInstance, f),
    validguardianofathlete: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.GuardianAhtleteValid}
 */
proto.themis.GuardianAhtleteValid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.GuardianAhtleteValid;
  return proto.themis.GuardianAhtleteValid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.GuardianAhtleteValid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.GuardianAhtleteValid}
 */
proto.themis.GuardianAhtleteValid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGuardianId(value);
      break;
    case 2:
      var value = new proto.themis.Guardian;
      reader.readMessage(value,proto.themis.Guardian.deserializeBinaryFromReader);
      msg.setGuardian(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAthleteId(value);
      break;
    case 4:
      var value = new proto.themis.Athlete;
      reader.readMessage(value,proto.themis.Athlete.deserializeBinaryFromReader);
      msg.setAthlete(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValidguardianofathlete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.GuardianAhtleteValid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.GuardianAhtleteValid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.GuardianAhtleteValid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.GuardianAhtleteValid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGuardianId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getGuardian();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.Guardian.serializeBinaryToWriter
    );
  }
  f = message.getAthleteId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAthlete();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.themis.Athlete.serializeBinaryToWriter
    );
  }
  f = message.getValidguardianofathlete();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional uint32 guardian_id = 1;
 * @return {number}
 */
proto.themis.GuardianAhtleteValid.prototype.getGuardianId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GuardianAhtleteValid} returns this
 */
proto.themis.GuardianAhtleteValid.prototype.setGuardianId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Guardian guardian = 2;
 * @return {?proto.themis.Guardian}
 */
proto.themis.GuardianAhtleteValid.prototype.getGuardian = function() {
  return /** @type{?proto.themis.Guardian} */ (
    jspb.Message.getWrapperField(this, proto.themis.Guardian, 2));
};


/**
 * @param {?proto.themis.Guardian|undefined} value
 * @return {!proto.themis.GuardianAhtleteValid} returns this
*/
proto.themis.GuardianAhtleteValid.prototype.setGuardian = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.GuardianAhtleteValid} returns this
 */
proto.themis.GuardianAhtleteValid.prototype.clearGuardian = function() {
  return this.setGuardian(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GuardianAhtleteValid.prototype.hasGuardian = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 athlete_id = 3;
 * @return {number}
 */
proto.themis.GuardianAhtleteValid.prototype.getAthleteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.GuardianAhtleteValid} returns this
 */
proto.themis.GuardianAhtleteValid.prototype.setAthleteId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Athlete athlete = 4;
 * @return {?proto.themis.Athlete}
 */
proto.themis.GuardianAhtleteValid.prototype.getAthlete = function() {
  return /** @type{?proto.themis.Athlete} */ (
    jspb.Message.getWrapperField(this, proto.themis.Athlete, 4));
};


/**
 * @param {?proto.themis.Athlete|undefined} value
 * @return {!proto.themis.GuardianAhtleteValid} returns this
*/
proto.themis.GuardianAhtleteValid.prototype.setAthlete = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.GuardianAhtleteValid} returns this
 */
proto.themis.GuardianAhtleteValid.prototype.clearAthlete = function() {
  return this.setAthlete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.GuardianAhtleteValid.prototype.hasAthlete = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool ValidGuardianOfAthlete = 5;
 * @return {boolean}
 */
proto.themis.GuardianAhtleteValid.prototype.getValidguardianofathlete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.GuardianAhtleteValid} returns this
 */
proto.themis.GuardianAhtleteValid.prototype.setValidguardianofathlete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RegistrationActivityLogItem.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RegistrationActivityLogItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RegistrationActivityLogItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationActivityLogItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isPayment: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isRegistration: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    paymentId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    registrationTransactionPaymentId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    transaction: (f = msg.getTransaction()) && proto.themis.RegistrationTransactionPayment.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && proto.themis.ClockTime.toObject(includeInstance, f),
    html: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RegistrationActivityLogItem}
 */
proto.themis.RegistrationActivityLogItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RegistrationActivityLogItem;
  return proto.themis.RegistrationActivityLogItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RegistrationActivityLogItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RegistrationActivityLogItem}
 */
proto.themis.RegistrationActivityLogItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPayment(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRegistration(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaymentId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegistrationTransactionPaymentId(value);
      break;
    case 5:
      var value = new proto.themis.RegistrationTransactionPayment;
      reader.readMessage(value,proto.themis.RegistrationTransactionPayment.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    case 6:
      var value = new proto.themis.ClockTime;
      reader.readMessage(value,proto.themis.ClockTime.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtml(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RegistrationActivityLogItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RegistrationActivityLogItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RegistrationActivityLogItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RegistrationActivityLogItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsPayment();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsRegistration();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getPaymentId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getRegistrationTransactionPaymentId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.themis.RegistrationTransactionPayment.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.themis.ClockTime.serializeBinaryToWriter
    );
  }
  f = message.getHtml();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 8;
 * @return {string}
 */
proto.themis.RegistrationActivityLogItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegistrationActivityLogItem} returns this
 */
proto.themis.RegistrationActivityLogItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_payment = 1;
 * @return {boolean}
 */
proto.themis.RegistrationActivityLogItem.prototype.getIsPayment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.RegistrationActivityLogItem} returns this
 */
proto.themis.RegistrationActivityLogItem.prototype.setIsPayment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_registration = 2;
 * @return {boolean}
 */
proto.themis.RegistrationActivityLogItem.prototype.getIsRegistration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.RegistrationActivityLogItem} returns this
 */
proto.themis.RegistrationActivityLogItem.prototype.setIsRegistration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional uint32 payment_id = 3;
 * @return {number}
 */
proto.themis.RegistrationActivityLogItem.prototype.getPaymentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationActivityLogItem} returns this
 */
proto.themis.RegistrationActivityLogItem.prototype.setPaymentId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 registration_transaction_payment_id = 4;
 * @return {number}
 */
proto.themis.RegistrationActivityLogItem.prototype.getRegistrationTransactionPaymentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RegistrationActivityLogItem} returns this
 */
proto.themis.RegistrationActivityLogItem.prototype.setRegistrationTransactionPaymentId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional RegistrationTransactionPayment transaction = 5;
 * @return {?proto.themis.RegistrationTransactionPayment}
 */
proto.themis.RegistrationActivityLogItem.prototype.getTransaction = function() {
  return /** @type{?proto.themis.RegistrationTransactionPayment} */ (
    jspb.Message.getWrapperField(this, proto.themis.RegistrationTransactionPayment, 5));
};


/**
 * @param {?proto.themis.RegistrationTransactionPayment|undefined} value
 * @return {!proto.themis.RegistrationActivityLogItem} returns this
*/
proto.themis.RegistrationActivityLogItem.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.RegistrationActivityLogItem} returns this
 */
proto.themis.RegistrationActivityLogItem.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.RegistrationActivityLogItem.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ClockTime updated_at = 6;
 * @return {?proto.themis.ClockTime}
 */
proto.themis.RegistrationActivityLogItem.prototype.getUpdatedAt = function() {
  return /** @type{?proto.themis.ClockTime} */ (
    jspb.Message.getWrapperField(this, proto.themis.ClockTime, 6));
};


/**
 * @param {?proto.themis.ClockTime|undefined} value
 * @return {!proto.themis.RegistrationActivityLogItem} returns this
*/
proto.themis.RegistrationActivityLogItem.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.RegistrationActivityLogItem} returns this
 */
proto.themis.RegistrationActivityLogItem.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.RegistrationActivityLogItem.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string html = 7;
 * @return {string}
 */
proto.themis.RegistrationActivityLogItem.prototype.getHtml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegistrationActivityLogItem} returns this
 */
proto.themis.RegistrationActivityLogItem.prototype.setHtml = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.themis.RegistrationActivityLogItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RegistrationActivityLogItem} returns this
 */
proto.themis.RegistrationActivityLogItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventTeamFlat.repeatedFields_ = [24];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventTeamFlat.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventTeamFlat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventTeamFlat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeamFlat.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    programName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    teamName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tierName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    divisionName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    athletes: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paid: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    billablePaid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    billableDue: jspb.Message.getFieldWithDefault(msg, 9, ""),
    producerNet: jspb.Message.getFieldWithDefault(msg, 10, ""),
    remainingDeposit: jspb.Message.getFieldWithDefault(msg, 11, ""),
    fees: jspb.Message.getFieldWithDefault(msg, 12, ""),
    crossovers: jspb.Message.getFieldWithDefault(msg, 13, 0),
    contactName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 15, ""),
    locationPhone: jspb.Message.getFieldWithDefault(msg, 16, ""),
    registrantPhone: jspb.Message.getFieldWithDefault(msg, 17, ""),
    programId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    billableDueInt: jspb.Message.getFieldWithDefault(msg, 19, 0),
    billablePaidInt: jspb.Message.getFieldWithDefault(msg, 20, 0),
    producerNetInt: jspb.Message.getFieldWithDefault(msg, 21, 0),
    remainingDepositInt: jspb.Message.getFieldWithDefault(msg, 22, 0),
    feesInt: jspb.Message.getFieldWithDefault(msg, 23, 0),
    athleteIdsList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventTeamFlat}
 */
proto.themis.EventTeamFlat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventTeamFlat;
  return proto.themis.EventTeamFlat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventTeamFlat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventTeamFlat}
 */
proto.themis.EventTeamFlat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTierName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDivisionName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAthletes(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillablePaid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillableDue(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerNet(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemainingDeposit(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCrossovers(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationPhone(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrantPhone(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBillableDueInt(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBillablePaidInt(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProducerNetInt(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRemainingDepositInt(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFeesInt(value);
      break;
    case 24:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAthleteIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventTeamFlat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventTeamFlat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventTeamFlat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventTeamFlat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getProgramName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTeamName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTierName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDivisionName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAthletes();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getPaid();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getBillablePaid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBillableDue();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProducerNet();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRemainingDeposit();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCrossovers();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getContactName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLocationPhone();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getRegistrantPhone();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getBillableDueInt();
  if (f !== 0) {
    writer.writeUint64(
      19,
      f
    );
  }
  f = message.getBillablePaidInt();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getProducerNetInt();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getRemainingDepositInt();
  if (f !== 0) {
    writer.writeUint64(
      22,
      f
    );
  }
  f = message.getFeesInt();
  if (f !== 0) {
    writer.writeUint64(
      23,
      f
    );
  }
  f = message.getAthleteIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      24,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventTeamFlat.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string program_name = 2;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getProgramName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setProgramName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string team_name = 3;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getTeamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setTeamName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tier_name = 4;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getTierName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setTierName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string division_name = 5;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getDivisionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setDivisionName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 athletes = 6;
 * @return {number}
 */
proto.themis.EventTeamFlat.prototype.getAthletes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setAthletes = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool paid = 7;
 * @return {boolean}
 */
proto.themis.EventTeamFlat.prototype.getPaid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setPaid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string billable_paid = 8;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getBillablePaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setBillablePaid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string billable_due = 9;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getBillableDue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setBillableDue = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string producer_net = 10;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getProducerNet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setProducerNet = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string remaining_deposit = 11;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getRemainingDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setRemainingDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string fees = 12;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional uint32 crossovers = 13;
 * @return {number}
 */
proto.themis.EventTeamFlat.prototype.getCrossovers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setCrossovers = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string contact_name = 14;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getContactName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setContactName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string contact_email = 15;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string location_phone = 16;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getLocationPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setLocationPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string registrant_phone = 17;
 * @return {string}
 */
proto.themis.EventTeamFlat.prototype.getRegistrantPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setRegistrantPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional uint32 program_id = 18;
 * @return {number}
 */
proto.themis.EventTeamFlat.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional uint64 billable_due_int = 19;
 * @return {number}
 */
proto.themis.EventTeamFlat.prototype.getBillableDueInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setBillableDueInt = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional uint64 billable_paid_int = 20;
 * @return {number}
 */
proto.themis.EventTeamFlat.prototype.getBillablePaidInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setBillablePaidInt = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 producer_net_int = 21;
 * @return {number}
 */
proto.themis.EventTeamFlat.prototype.getProducerNetInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setProducerNetInt = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint64 remaining_deposit_int = 22;
 * @return {number}
 */
proto.themis.EventTeamFlat.prototype.getRemainingDepositInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setRemainingDepositInt = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint64 fees_int = 23;
 * @return {number}
 */
proto.themis.EventTeamFlat.prototype.getFeesInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setFeesInt = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * repeated uint32 athlete_ids = 24;
 * @return {!Array<number>}
 */
proto.themis.EventTeamFlat.prototype.getAthleteIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.setAthleteIdsList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.addAthleteIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventTeamFlat} returns this
 */
proto.themis.EventTeamFlat.prototype.clearAthleteIdsList = function() {
  return this.setAthleteIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventProgramFlat.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventProgramFlat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventProgramFlat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventProgramFlat.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    programName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paid: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    billablePaid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    billableDue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    producerNet: jspb.Message.getFieldWithDefault(msg, 6, ""),
    remainingDeposit: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fees: jspb.Message.getFieldWithDefault(msg, 8, ""),
    crossovers: jspb.Message.getFieldWithDefault(msg, 9, 0),
    contactName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 11, ""),
    programPhone: jspb.Message.getFieldWithDefault(msg, 12, ""),
    uniqueAthletes: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventProgramFlat}
 */
proto.themis.EventProgramFlat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventProgramFlat;
  return proto.themis.EventProgramFlat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventProgramFlat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventProgramFlat}
 */
proto.themis.EventProgramFlat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillablePaid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillableDue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerNet(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemainingDeposit(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCrossovers(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramPhone(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUniqueAthletes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventProgramFlat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventProgramFlat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventProgramFlat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventProgramFlat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getProgramName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaid();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getBillablePaid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBillableDue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProducerNet();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRemainingDeposit();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCrossovers();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getContactName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProgramPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUniqueAthletes();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventProgramFlat.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string program_name = 2;
 * @return {string}
 */
proto.themis.EventProgramFlat.prototype.getProgramName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setProgramName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool paid = 3;
 * @return {boolean}
 */
proto.themis.EventProgramFlat.prototype.getPaid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setPaid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string billable_paid = 4;
 * @return {string}
 */
proto.themis.EventProgramFlat.prototype.getBillablePaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setBillablePaid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string billable_due = 5;
 * @return {string}
 */
proto.themis.EventProgramFlat.prototype.getBillableDue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setBillableDue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string producer_net = 6;
 * @return {string}
 */
proto.themis.EventProgramFlat.prototype.getProducerNet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setProducerNet = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string remaining_deposit = 7;
 * @return {string}
 */
proto.themis.EventProgramFlat.prototype.getRemainingDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setRemainingDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string fees = 8;
 * @return {string}
 */
proto.themis.EventProgramFlat.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional uint32 crossovers = 9;
 * @return {number}
 */
proto.themis.EventProgramFlat.prototype.getCrossovers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setCrossovers = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string contact_name = 10;
 * @return {string}
 */
proto.themis.EventProgramFlat.prototype.getContactName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setContactName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string contact_email = 11;
 * @return {string}
 */
proto.themis.EventProgramFlat.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string program_phone = 12;
 * @return {string}
 */
proto.themis.EventProgramFlat.prototype.getProgramPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setProgramPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional uint32 unique_athletes = 13;
 * @return {number}
 */
proto.themis.EventProgramFlat.prototype.getUniqueAthletes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventProgramFlat} returns this
 */
proto.themis.EventProgramFlat.prototype.setUniqueAthletes = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventRegistrationReport.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventRegistrationReport.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventRegistrationReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventRegistrationReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventTeamsList: jspb.Message.toObjectList(msg.getEventTeamsList(),
    proto.themis.EventTeamFlat.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventRegistrationReport}
 */
proto.themis.EventRegistrationReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventRegistrationReport;
  return proto.themis.EventRegistrationReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventRegistrationReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventRegistrationReport}
 */
proto.themis.EventRegistrationReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.EventTeamFlat;
      reader.readMessage(value,proto.themis.EventTeamFlat.deserializeBinaryFromReader);
      msg.addEventTeams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventRegistrationReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventRegistrationReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventRegistrationReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.themis.EventTeamFlat.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventTeamFlat event_teams = 1;
 * @return {!Array<!proto.themis.EventTeamFlat>}
 */
proto.themis.EventRegistrationReport.prototype.getEventTeamsList = function() {
  return /** @type{!Array<!proto.themis.EventTeamFlat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventTeamFlat, 1));
};


/**
 * @param {!Array<!proto.themis.EventTeamFlat>} value
 * @return {!proto.themis.EventRegistrationReport} returns this
*/
proto.themis.EventRegistrationReport.prototype.setEventTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventTeamFlat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventTeamFlat}
 */
proto.themis.EventRegistrationReport.prototype.addEventTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventTeamFlat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventRegistrationReport} returns this
 */
proto.themis.EventRegistrationReport.prototype.clearEventTeamsList = function() {
  return this.setEventTeamsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventRegistrationByProgramReport.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventRegistrationByProgramReport.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventRegistrationByProgramReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventRegistrationByProgramReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationByProgramReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventProgramsList: jspb.Message.toObjectList(msg.getEventProgramsList(),
    proto.themis.EventProgramFlat.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventRegistrationByProgramReport}
 */
proto.themis.EventRegistrationByProgramReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventRegistrationByProgramReport;
  return proto.themis.EventRegistrationByProgramReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventRegistrationByProgramReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventRegistrationByProgramReport}
 */
proto.themis.EventRegistrationByProgramReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.EventProgramFlat;
      reader.readMessage(value,proto.themis.EventProgramFlat.deserializeBinaryFromReader);
      msg.addEventPrograms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventRegistrationByProgramReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventRegistrationByProgramReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventRegistrationByProgramReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventRegistrationByProgramReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventProgramsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.themis.EventProgramFlat.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventProgramFlat event_programs = 1;
 * @return {!Array<!proto.themis.EventProgramFlat>}
 */
proto.themis.EventRegistrationByProgramReport.prototype.getEventProgramsList = function() {
  return /** @type{!Array<!proto.themis.EventProgramFlat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventProgramFlat, 1));
};


/**
 * @param {!Array<!proto.themis.EventProgramFlat>} value
 * @return {!proto.themis.EventRegistrationByProgramReport} returns this
*/
proto.themis.EventRegistrationByProgramReport.prototype.setEventProgramsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.EventProgramFlat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventProgramFlat}
 */
proto.themis.EventRegistrationByProgramReport.prototype.addEventPrograms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.EventProgramFlat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventRegistrationByProgramReport} returns this
 */
proto.themis.EventRegistrationByProgramReport.prototype.clearEventProgramsList = function() {
  return this.setEventProgramsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.ProducerProgramCreditReport.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProducerProgramCreditReport.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProducerProgramCreditReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProducerProgramCreditReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerProgramCreditReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    programCreditsList: jspb.Message.toObjectList(msg.getProgramCreditsList(),
    proto.themis.ProgramCredit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProducerProgramCreditReport}
 */
proto.themis.ProducerProgramCreditReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProducerProgramCreditReport;
  return proto.themis.ProducerProgramCreditReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProducerProgramCreditReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProducerProgramCreditReport}
 */
proto.themis.ProducerProgramCreditReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.ProgramCredit;
      reader.readMessage(value,proto.themis.ProgramCredit.deserializeBinaryFromReader);
      msg.addProgramCredits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProducerProgramCreditReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProducerProgramCreditReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProducerProgramCreditReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProducerProgramCreditReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgramCreditsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.themis.ProgramCredit.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProgramCredit program_credits = 1;
 * @return {!Array<!proto.themis.ProgramCredit>}
 */
proto.themis.ProducerProgramCreditReport.prototype.getProgramCreditsList = function() {
  return /** @type{!Array<!proto.themis.ProgramCredit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.ProgramCredit, 1));
};


/**
 * @param {!Array<!proto.themis.ProgramCredit>} value
 * @return {!proto.themis.ProducerProgramCreditReport} returns this
*/
proto.themis.ProducerProgramCreditReport.prototype.setProgramCreditsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.ProgramCredit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.ProgramCredit}
 */
proto.themis.ProducerProgramCreditReport.prototype.addProgramCredits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.ProgramCredit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.ProducerProgramCreditReport} returns this
 */
proto.themis.ProducerProgramCreditReport.prototype.clearProgramCreditsList = function() {
  return this.setProgramCreditsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.AthleteSignStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.AthleteSignStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.AthleteSignStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthleteSignStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    athleteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    allSigned: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    canSend: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.AthleteSignStatus}
 */
proto.themis.AthleteSignStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.AthleteSignStatus;
  return proto.themis.AthleteSignStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.AthleteSignStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.AthleteSignStatus}
 */
proto.themis.AthleteSignStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAthleteId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllSigned(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanSend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.AthleteSignStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.AthleteSignStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.AthleteSignStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.AthleteSignStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAthleteId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAllSigned();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCanSend();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint32 athlete_id = 1;
 * @return {number}
 */
proto.themis.AthleteSignStatus.prototype.getAthleteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.AthleteSignStatus} returns this
 */
proto.themis.AthleteSignStatus.prototype.setAthleteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool all_signed = 2;
 * @return {boolean}
 */
proto.themis.AthleteSignStatus.prototype.getAllSigned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.AthleteSignStatus} returns this
 */
proto.themis.AthleteSignStatus.prototype.setAllSigned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool can_send = 3;
 * @return {boolean}
 */
proto.themis.AthleteSignStatus.prototype.getCanSend = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.AthleteSignStatus} returns this
 */
proto.themis.AthleteSignStatus.prototype.setCanSend = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventJudgeShiftRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventJudgeShiftRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventJudgeShiftRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventJudgeShiftRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventJudgeShiftRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    judgesList: jspb.Message.toObjectList(msg.getJudgesList(),
    proto.themis.Judge.toObject, includeInstance),
    eventFloorDateJudgingPanelShiftId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eventFloorDateJudgingPanelId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventJudgeShiftRequest}
 */
proto.themis.EventJudgeShiftRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventJudgeShiftRequest;
  return proto.themis.EventJudgeShiftRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventJudgeShiftRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventJudgeShiftRequest}
 */
proto.themis.EventJudgeShiftRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.Judge;
      reader.readMessage(value,proto.themis.Judge.deserializeBinaryFromReader);
      msg.addJudges(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventFloorDateJudgingPanelShiftId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventFloorDateJudgingPanelId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBrandId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventJudgeShiftRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventJudgeShiftRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventJudgeShiftRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventJudgeShiftRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getJudgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.themis.Judge.serializeBinaryToWriter
    );
  }
  f = message.getEventFloorDateJudgingPanelShiftId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getEventFloorDateJudgingPanelId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getEventId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventJudgeShiftRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventJudgeShiftRequest} returns this
 */
proto.themis.EventJudgeShiftRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Judge judges = 2;
 * @return {!Array<!proto.themis.Judge>}
 */
proto.themis.EventJudgeShiftRequest.prototype.getJudgesList = function() {
  return /** @type{!Array<!proto.themis.Judge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Judge, 2));
};


/**
 * @param {!Array<!proto.themis.Judge>} value
 * @return {!proto.themis.EventJudgeShiftRequest} returns this
*/
proto.themis.EventJudgeShiftRequest.prototype.setJudgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.Judge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Judge}
 */
proto.themis.EventJudgeShiftRequest.prototype.addJudges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.Judge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventJudgeShiftRequest} returns this
 */
proto.themis.EventJudgeShiftRequest.prototype.clearJudgesList = function() {
  return this.setJudgesList([]);
};


/**
 * optional uint32 event_floor_date_judging_panel_shift_id = 3;
 * @return {number}
 */
proto.themis.EventJudgeShiftRequest.prototype.getEventFloorDateJudgingPanelShiftId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventJudgeShiftRequest} returns this
 */
proto.themis.EventJudgeShiftRequest.prototype.setEventFloorDateJudgingPanelShiftId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 event_floor_date_judging_panel_id = 4;
 * @return {number}
 */
proto.themis.EventJudgeShiftRequest.prototype.getEventFloorDateJudgingPanelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventJudgeShiftRequest} returns this
 */
proto.themis.EventJudgeShiftRequest.prototype.setEventFloorDateJudgingPanelId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 producer_id = 5;
 * @return {number}
 */
proto.themis.EventJudgeShiftRequest.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventJudgeShiftRequest} returns this
 */
proto.themis.EventJudgeShiftRequest.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 brand_id = 6;
 * @return {number}
 */
proto.themis.EventJudgeShiftRequest.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventJudgeShiftRequest} returns this
 */
proto.themis.EventJudgeShiftRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 event_id = 7;
 * @return {number}
 */
proto.themis.EventJudgeShiftRequest.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventJudgeShiftRequest} returns this
 */
proto.themis.EventJudgeShiftRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventJudgeShiftResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventJudgeShiftResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventJudgeShiftResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventJudgeShiftResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventJudgeShiftResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    shiftId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventJudgeShiftsList: jspb.Message.toObjectList(msg.getEventJudgeShiftsList(),
    proto.themis.EventJudgeShift.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventJudgeShiftResponse}
 */
proto.themis.EventJudgeShiftResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventJudgeShiftResponse;
  return proto.themis.EventJudgeShiftResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventJudgeShiftResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventJudgeShiftResponse}
 */
proto.themis.EventJudgeShiftResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShiftId(value);
      break;
    case 2:
      var value = new proto.themis.EventJudgeShift;
      reader.readMessage(value,proto.themis.EventJudgeShift.deserializeBinaryFromReader);
      msg.addEventJudgeShifts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventJudgeShiftResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventJudgeShiftResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventJudgeShiftResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventJudgeShiftResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShiftId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventJudgeShiftsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.themis.EventJudgeShift.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 shift_id = 1;
 * @return {number}
 */
proto.themis.EventJudgeShiftResponse.prototype.getShiftId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventJudgeShiftResponse} returns this
 */
proto.themis.EventJudgeShiftResponse.prototype.setShiftId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated EventJudgeShift event_judge_shifts = 2;
 * @return {!Array<!proto.themis.EventJudgeShift>}
 */
proto.themis.EventJudgeShiftResponse.prototype.getEventJudgeShiftsList = function() {
  return /** @type{!Array<!proto.themis.EventJudgeShift>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventJudgeShift, 2));
};


/**
 * @param {!Array<!proto.themis.EventJudgeShift>} value
 * @return {!proto.themis.EventJudgeShiftResponse} returns this
*/
proto.themis.EventJudgeShiftResponse.prototype.setEventJudgeShiftsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.themis.EventJudgeShift=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventJudgeShift}
 */
proto.themis.EventJudgeShiftResponse.prototype.addEventJudgeShifts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.themis.EventJudgeShift, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventJudgeShiftResponse} returns this
 */
proto.themis.EventJudgeShiftResponse.prototype.clearEventJudgeShiftsList = function() {
  return this.setEventJudgeShiftsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventJudgeShift.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventJudgeShift.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventJudgeShift.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventJudgeShift} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventJudgeShift.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    judgeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    judgeName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rubricAreasList: jspb.Message.toObjectList(msg.getRubricAreasList(),
    proto.themis.RubricArea.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventJudgeShift}
 */
proto.themis.EventJudgeShift.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventJudgeShift;
  return proto.themis.EventJudgeShift.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventJudgeShift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventJudgeShift}
 */
proto.themis.EventJudgeShift.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setJudgeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJudgeName(value);
      break;
    case 4:
      var value = new proto.themis.RubricArea;
      reader.readMessage(value,proto.themis.RubricArea.deserializeBinaryFromReader);
      msg.addRubricAreas(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventJudgeShift.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventJudgeShift.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventJudgeShift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventJudgeShift.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getJudgeId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getJudgeName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRubricAreasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.themis.RubricArea.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventJudgeShift.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventJudgeShift} returns this
 */
proto.themis.EventJudgeShift.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 judge_id = 2;
 * @return {number}
 */
proto.themis.EventJudgeShift.prototype.getJudgeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventJudgeShift} returns this
 */
proto.themis.EventJudgeShift.prototype.setJudgeId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string judge_name = 3;
 * @return {string}
 */
proto.themis.EventJudgeShift.prototype.getJudgeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventJudgeShift} returns this
 */
proto.themis.EventJudgeShift.prototype.setJudgeName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated RubricArea rubric_areas = 4;
 * @return {!Array<!proto.themis.RubricArea>}
 */
proto.themis.EventJudgeShift.prototype.getRubricAreasList = function() {
  return /** @type{!Array<!proto.themis.RubricArea>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.RubricArea, 4));
};


/**
 * @param {!Array<!proto.themis.RubricArea>} value
 * @return {!proto.themis.EventJudgeShift} returns this
*/
proto.themis.EventJudgeShift.prototype.setRubricAreasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.themis.RubricArea=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.RubricArea}
 */
proto.themis.EventJudgeShift.prototype.addRubricAreas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.themis.RubricArea, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventJudgeShift} returns this
 */
proto.themis.EventJudgeShift.prototype.clearRubricAreasList = function() {
  return this.setRubricAreasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RubricMultiJudgeStrategy.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RubricMultiJudgeStrategy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RubricMultiJudgeStrategy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RubricMultiJudgeStrategy.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RubricMultiJudgeStrategy}
 */
proto.themis.RubricMultiJudgeStrategy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RubricMultiJudgeStrategy;
  return proto.themis.RubricMultiJudgeStrategy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RubricMultiJudgeStrategy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RubricMultiJudgeStrategy}
 */
proto.themis.RubricMultiJudgeStrategy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RubricMultiJudgeStrategy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RubricMultiJudgeStrategy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RubricMultiJudgeStrategy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RubricMultiJudgeStrategy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.RubricMultiJudgeStrategy.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricMultiJudgeStrategy} returns this
 */
proto.themis.RubricMultiJudgeStrategy.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.RubricMultiJudgeStrategy.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RubricMultiJudgeStrategy} returns this
 */
proto.themis.RubricMultiJudgeStrategy.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Rubric.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Rubric.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Rubric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Rubric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Rubric.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    formula: jspb.Message.getFieldWithDefault(msg, 3, ""),
    multiJudgeStrategy: (f = msg.getMultiJudgeStrategy()) && proto.themis.RubricMultiJudgeStrategy.toObject(includeInstance, f),
    producer: (f = msg.getProducer()) && proto.themis.Producer.toObject(includeInstance, f),
    rubricAreasList: jspb.Message.toObjectList(msg.getRubricAreasList(),
    proto.themis.RubricArea.toObject, includeInstance),
    rubricDeductionsList: jspb.Message.toObjectList(msg.getRubricDeductionsList(),
    proto.themis.RubricDeduction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Rubric}
 */
proto.themis.Rubric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Rubric;
  return proto.themis.Rubric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Rubric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Rubric}
 */
proto.themis.Rubric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormula(value);
      break;
    case 4:
      var value = new proto.themis.RubricMultiJudgeStrategy;
      reader.readMessage(value,proto.themis.RubricMultiJudgeStrategy.deserializeBinaryFromReader);
      msg.setMultiJudgeStrategy(value);
      break;
    case 5:
      var value = new proto.themis.Producer;
      reader.readMessage(value,proto.themis.Producer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    case 6:
      var value = new proto.themis.RubricArea;
      reader.readMessage(value,proto.themis.RubricArea.deserializeBinaryFromReader);
      msg.addRubricAreas(value);
      break;
    case 7:
      var value = new proto.themis.RubricDeduction;
      reader.readMessage(value,proto.themis.RubricDeduction.deserializeBinaryFromReader);
      msg.addRubricDeductions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Rubric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Rubric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Rubric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Rubric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFormula();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMultiJudgeStrategy();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.themis.RubricMultiJudgeStrategy.serializeBinaryToWriter
    );
  }
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.themis.Producer.serializeBinaryToWriter
    );
  }
  f = message.getRubricAreasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.themis.RubricArea.serializeBinaryToWriter
    );
  }
  f = message.getRubricDeductionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.themis.RubricDeduction.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.Rubric.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.Rubric} returns this
 */
proto.themis.Rubric.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.Rubric.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Rubric} returns this
 */
proto.themis.Rubric.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string formula = 3;
 * @return {string}
 */
proto.themis.Rubric.prototype.getFormula = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.Rubric} returns this
 */
proto.themis.Rubric.prototype.setFormula = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional RubricMultiJudgeStrategy multi_judge_strategy = 4;
 * @return {?proto.themis.RubricMultiJudgeStrategy}
 */
proto.themis.Rubric.prototype.getMultiJudgeStrategy = function() {
  return /** @type{?proto.themis.RubricMultiJudgeStrategy} */ (
    jspb.Message.getWrapperField(this, proto.themis.RubricMultiJudgeStrategy, 4));
};


/**
 * @param {?proto.themis.RubricMultiJudgeStrategy|undefined} value
 * @return {!proto.themis.Rubric} returns this
*/
proto.themis.Rubric.prototype.setMultiJudgeStrategy = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Rubric} returns this
 */
proto.themis.Rubric.prototype.clearMultiJudgeStrategy = function() {
  return this.setMultiJudgeStrategy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Rubric.prototype.hasMultiJudgeStrategy = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Producer producer = 5;
 * @return {?proto.themis.Producer}
 */
proto.themis.Rubric.prototype.getProducer = function() {
  return /** @type{?proto.themis.Producer} */ (
    jspb.Message.getWrapperField(this, proto.themis.Producer, 5));
};


/**
 * @param {?proto.themis.Producer|undefined} value
 * @return {!proto.themis.Rubric} returns this
*/
proto.themis.Rubric.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.Rubric} returns this
 */
proto.themis.Rubric.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.Rubric.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated RubricArea rubric_areas = 6;
 * @return {!Array<!proto.themis.RubricArea>}
 */
proto.themis.Rubric.prototype.getRubricAreasList = function() {
  return /** @type{!Array<!proto.themis.RubricArea>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.RubricArea, 6));
};


/**
 * @param {!Array<!proto.themis.RubricArea>} value
 * @return {!proto.themis.Rubric} returns this
*/
proto.themis.Rubric.prototype.setRubricAreasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.themis.RubricArea=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.RubricArea}
 */
proto.themis.Rubric.prototype.addRubricAreas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.themis.RubricArea, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Rubric} returns this
 */
proto.themis.Rubric.prototype.clearRubricAreasList = function() {
  return this.setRubricAreasList([]);
};


/**
 * repeated RubricDeduction rubric_deductions = 7;
 * @return {!Array<!proto.themis.RubricDeduction>}
 */
proto.themis.Rubric.prototype.getRubricDeductionsList = function() {
  return /** @type{!Array<!proto.themis.RubricDeduction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.RubricDeduction, 7));
};


/**
 * @param {!Array<!proto.themis.RubricDeduction>} value
 * @return {!proto.themis.Rubric} returns this
*/
proto.themis.Rubric.prototype.setRubricDeductionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.themis.RubricDeduction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.RubricDeduction}
 */
proto.themis.Rubric.prototype.addRubricDeductions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.themis.RubricDeduction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Rubric} returns this
 */
proto.themis.Rubric.prototype.clearRubricDeductionsList = function() {
  return this.setRubricDeductionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RubricDeduction.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RubricDeduction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RubricDeduction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RubricDeduction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rubricId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RubricDeduction}
 */
proto.themis.RubricDeduction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RubricDeduction;
  return proto.themis.RubricDeduction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RubricDeduction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RubricDeduction}
 */
proto.themis.RubricDeduction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRubricId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RubricDeduction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RubricDeduction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RubricDeduction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RubricDeduction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRubricId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.RubricDeduction.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricDeduction} returns this
 */
proto.themis.RubricDeduction.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.RubricDeduction.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RubricDeduction} returns this
 */
proto.themis.RubricDeduction.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 rubric_id = 3;
 * @return {number}
 */
proto.themis.RubricDeduction.prototype.getRubricId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricDeduction} returns this
 */
proto.themis.RubricDeduction.prototype.setRubricId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.Rubrics.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.Rubrics.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.Rubrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.Rubrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Rubrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.themis.Rubric.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.Rubrics}
 */
proto.themis.Rubrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.Rubrics;
  return proto.themis.Rubrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.Rubrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.Rubrics}
 */
proto.themis.Rubrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.themis.Rubric;
      reader.readMessage(value,proto.themis.Rubric.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.Rubrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.Rubrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.Rubrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.Rubrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.themis.Rubric.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Rubric items = 1;
 * @return {!Array<!proto.themis.Rubric>}
 */
proto.themis.Rubrics.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.themis.Rubric>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.Rubric, 1));
};


/**
 * @param {!Array<!proto.themis.Rubric>} value
 * @return {!proto.themis.Rubrics} returns this
*/
proto.themis.Rubrics.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.themis.Rubric=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.Rubric}
 */
proto.themis.Rubrics.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.themis.Rubric, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.Rubrics} returns this
 */
proto.themis.Rubrics.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.RubricArea.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RubricArea.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RubricArea.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RubricArea} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RubricArea.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    formula: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rubricId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    producerId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    rubricAreaItemsList: jspb.Message.toObjectList(msg.getRubricAreaItemsList(),
    proto.themis.RubricAreaItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RubricArea}
 */
proto.themis.RubricArea.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RubricArea;
  return proto.themis.RubricArea.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RubricArea} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RubricArea}
 */
proto.themis.RubricArea.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormula(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRubricId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 8:
      var value = new proto.themis.RubricAreaItem;
      reader.readMessage(value,proto.themis.RubricAreaItem.deserializeBinaryFromReader);
      msg.addRubricAreaItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RubricArea.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RubricArea.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RubricArea} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RubricArea.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFormula();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRubricId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getRubricAreaItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.themis.RubricAreaItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.RubricArea.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricArea} returns this
 */
proto.themis.RubricArea.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.themis.RubricArea.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RubricArea} returns this
 */
proto.themis.RubricArea.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string formula = 3;
 * @return {string}
 */
proto.themis.RubricArea.prototype.getFormula = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RubricArea} returns this
 */
proto.themis.RubricArea.prototype.setFormula = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 rubric_id = 4;
 * @return {number}
 */
proto.themis.RubricArea.prototype.getRubricId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricArea} returns this
 */
proto.themis.RubricArea.prototype.setRubricId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 order = 5;
 * @return {number}
 */
proto.themis.RubricArea.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricArea} returns this
 */
proto.themis.RubricArea.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 producer_id = 6;
 * @return {number}
 */
proto.themis.RubricArea.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricArea} returns this
 */
proto.themis.RubricArea.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated RubricAreaItem rubric_area_items = 8;
 * @return {!Array<!proto.themis.RubricAreaItem>}
 */
proto.themis.RubricArea.prototype.getRubricAreaItemsList = function() {
  return /** @type{!Array<!proto.themis.RubricAreaItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.RubricAreaItem, 8));
};


/**
 * @param {!Array<!proto.themis.RubricAreaItem>} value
 * @return {!proto.themis.RubricArea} returns this
*/
proto.themis.RubricArea.prototype.setRubricAreaItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.themis.RubricAreaItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.RubricAreaItem}
 */
proto.themis.RubricArea.prototype.addRubricAreaItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.themis.RubricAreaItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.RubricArea} returns this
 */
proto.themis.RubricArea.prototype.clearRubricAreaItemsList = function() {
  return this.setRubricAreaItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.RubricAreaItem.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.RubricAreaItem.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.RubricAreaItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.RubricAreaItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RubricAreaItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rubricAreaId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    helpText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fieldType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    rangeLow: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    rangeHigh: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    valuesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f,
    producerId: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.RubricAreaItem}
 */
proto.themis.RubricAreaItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.RubricAreaItem;
  return proto.themis.RubricAreaItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.RubricAreaItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.RubricAreaItem}
 */
proto.themis.RubricAreaItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRubricAreaId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 7:
      var value = /** @type {!proto.themis.field_type} */ (reader.readEnum());
      msg.setFieldType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRangeLow(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRangeHigh(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addValues(values[i]);
      }
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.RubricAreaItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.RubricAreaItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.RubricAreaItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.RubricAreaItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRubricAreaId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFieldType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getRangeLow();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getRangeHigh();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writePackedFloat(
      10,
      f
    );
  }
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.RubricAreaItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.themis.RubricAreaItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 rubric_area_id = 4;
 * @return {number}
 */
proto.themis.RubricAreaItem.prototype.getRubricAreaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.setRubricAreaId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 order = 5;
 * @return {number}
 */
proto.themis.RubricAreaItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string help_text = 6;
 * @return {string}
 */
proto.themis.RubricAreaItem.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional field_type field_type = 7;
 * @return {!proto.themis.field_type}
 */
proto.themis.RubricAreaItem.prototype.getFieldType = function() {
  return /** @type {!proto.themis.field_type} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.themis.field_type} value
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.setFieldType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional float range_low = 8;
 * @return {number}
 */
proto.themis.RubricAreaItem.prototype.getRangeLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.setRangeLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float range_high = 9;
 * @return {number}
 */
proto.themis.RubricAreaItem.prototype.getRangeHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.setRangeHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated float values = 10;
 * @return {!Array<number>}
 */
proto.themis.RubricAreaItem.prototype.getValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional uint32 producer_id = 11;
 * @return {number}
 */
proto.themis.RubricAreaItem.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.RubricAreaItem} returns this
 */
proto.themis.RubricAreaItem.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventDivisionShift.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionShift.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionShift.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionShift} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShift.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventDivision: (f = msg.getEventDivision()) && proto.themis.EventDivision.toObject(includeInstance, f),
    shift: (f = msg.getShift()) && proto.themis.EventFloorDateJudgingPanelShift.toObject(includeInstance, f),
    sortOrder: jspb.Message.getFieldWithDefault(msg, 4, 0),
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.themis.EventDivisionShiftTeam.toObject, includeInstance),
    approved: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionShift}
 */
proto.themis.EventDivisionShift.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionShift;
  return proto.themis.EventDivisionShift.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionShift} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionShift}
 */
proto.themis.EventDivisionShift.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.EventDivision;
      reader.readMessage(value,proto.themis.EventDivision.deserializeBinaryFromReader);
      msg.setEventDivision(value);
      break;
    case 3:
      var value = new proto.themis.EventFloorDateJudgingPanelShift;
      reader.readMessage(value,proto.themis.EventFloorDateJudgingPanelShift.deserializeBinaryFromReader);
      msg.setShift(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSortOrder(value);
      break;
    case 5:
      var value = new proto.themis.EventDivisionShiftTeam;
      reader.readMessage(value,proto.themis.EventDivisionShiftTeam.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApproved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionShift.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionShift.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionShift} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShift.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventDivision();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.EventDivision.serializeBinaryToWriter
    );
  }
  f = message.getShift();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.EventFloorDateJudgingPanelShift.serializeBinaryToWriter
    );
  }
  f = message.getSortOrder();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.themis.EventDivisionShiftTeam.serializeBinaryToWriter
    );
  }
  f = message.getApproved();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventDivisionShift.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShift} returns this
 */
proto.themis.EventDivisionShift.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EventDivision event_division = 2;
 * @return {?proto.themis.EventDivision}
 */
proto.themis.EventDivisionShift.prototype.getEventDivision = function() {
  return /** @type{?proto.themis.EventDivision} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventDivision, 2));
};


/**
 * @param {?proto.themis.EventDivision|undefined} value
 * @return {!proto.themis.EventDivisionShift} returns this
*/
proto.themis.EventDivisionShift.prototype.setEventDivision = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShift} returns this
 */
proto.themis.EventDivisionShift.prototype.clearEventDivision = function() {
  return this.setEventDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShift.prototype.hasEventDivision = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventFloorDateJudgingPanelShift shift = 3;
 * @return {?proto.themis.EventFloorDateJudgingPanelShift}
 */
proto.themis.EventDivisionShift.prototype.getShift = function() {
  return /** @type{?proto.themis.EventFloorDateJudgingPanelShift} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventFloorDateJudgingPanelShift, 3));
};


/**
 * @param {?proto.themis.EventFloorDateJudgingPanelShift|undefined} value
 * @return {!proto.themis.EventDivisionShift} returns this
*/
proto.themis.EventDivisionShift.prototype.setShift = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShift} returns this
 */
proto.themis.EventDivisionShift.prototype.clearShift = function() {
  return this.setShift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShift.prototype.hasShift = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 sort_order = 4;
 * @return {number}
 */
proto.themis.EventDivisionShift.prototype.getSortOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShift} returns this
 */
proto.themis.EventDivisionShift.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated EventDivisionShiftTeam teams = 5;
 * @return {!Array<!proto.themis.EventDivisionShiftTeam>}
 */
proto.themis.EventDivisionShift.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.themis.EventDivisionShiftTeam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventDivisionShiftTeam, 5));
};


/**
 * @param {!Array<!proto.themis.EventDivisionShiftTeam>} value
 * @return {!proto.themis.EventDivisionShift} returns this
*/
proto.themis.EventDivisionShift.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivisionShiftTeam}
 */
proto.themis.EventDivisionShift.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.themis.EventDivisionShiftTeam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivisionShift} returns this
 */
proto.themis.EventDivisionShift.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};


/**
 * optional bool Approved = 6;
 * @return {boolean}
 */
proto.themis.EventDivisionShift.prototype.getApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventDivisionShift} returns this
 */
proto.themis.EventDivisionShift.prototype.setApproved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventDivisionShiftTeam.repeatedFields_ = [11,12,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionShiftTeam.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionShiftTeam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionShiftTeam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeam.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventDivisionShift: (f = msg.getEventDivisionShift()) && proto.themis.EventDivisionShift.toObject(includeInstance, f),
    eventTeam: (f = msg.getEventTeam()) && proto.themis.EventTeam.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && proto.themis.Event.toObject(includeInstance, f),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    startTs: jspb.Message.getFieldWithDefault(msg, 6, 0),
    startTsNanos: jspb.Message.getFieldWithDefault(msg, 7, 0),
    offsetTs: jspb.Message.getFieldWithDefault(msg, 8, 0),
    rubricId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    score: jspb.Message.getFieldWithDefault(msg, 10, 0),
    cheerReplayVideosList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    eventDivisionShiftTeamRubricAreasList: jspb.Message.toObjectList(msg.getEventDivisionShiftTeamRubricAreasList(),
    proto.themis.EventDivisionShiftTeamRubricArea.toObject, includeInstance),
    eventDivisionShiftTeamDeductionsList: jspb.Message.toObjectList(msg.getEventDivisionShiftTeamDeductionsList(),
    proto.themis.EventDivisionShiftTeamDeduction.toObject, includeInstance),
    complete: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    skipped: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionShiftTeam}
 */
proto.themis.EventDivisionShiftTeam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionShiftTeam;
  return proto.themis.EventDivisionShiftTeam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionShiftTeam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionShiftTeam}
 */
proto.themis.EventDivisionShiftTeam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.EventDivisionShift;
      reader.readMessage(value,proto.themis.EventDivisionShift.deserializeBinaryFromReader);
      msg.setEventDivisionShift(value);
      break;
    case 3:
      var value = new proto.themis.EventTeam;
      reader.readMessage(value,proto.themis.EventTeam.deserializeBinaryFromReader);
      msg.setEventTeam(value);
      break;
    case 4:
      var value = new proto.themis.Event;
      reader.readMessage(value,proto.themis.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartTs(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStartTsNanos(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOffsetTs(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRubricId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScore(value);
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCheerReplayVideos(values[i]);
      }
      break;
    case 12:
      var value = new proto.themis.EventDivisionShiftTeamRubricArea;
      reader.readMessage(value,proto.themis.EventDivisionShiftTeamRubricArea.deserializeBinaryFromReader);
      msg.addEventDivisionShiftTeamRubricAreas(value);
      break;
    case 15:
      var value = new proto.themis.EventDivisionShiftTeamDeduction;
      reader.readMessage(value,proto.themis.EventDivisionShiftTeamDeduction.deserializeBinaryFromReader);
      msg.addEventDivisionShiftTeamDeductions(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setComplete(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipped(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionShiftTeam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionShiftTeam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionShiftTeam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventDivisionShift();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.EventDivisionShift.serializeBinaryToWriter
    );
  }
  f = message.getEventTeam();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.EventTeam.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.themis.Event.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getStartTs();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getStartTsNanos();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getOffsetTs();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getRubricId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getCheerReplayVideosList();
  if (f.length > 0) {
    writer.writePackedUint32(
      11,
      f
    );
  }
  f = message.getEventDivisionShiftTeamRubricAreasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.themis.EventDivisionShiftTeamRubricArea.serializeBinaryToWriter
    );
  }
  f = message.getEventDivisionShiftTeamDeductionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.themis.EventDivisionShiftTeamDeduction.serializeBinaryToWriter
    );
  }
  f = message.getComplete();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSkipped();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeam.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EventDivisionShift event_division_shift = 2;
 * @return {?proto.themis.EventDivisionShift}
 */
proto.themis.EventDivisionShiftTeam.prototype.getEventDivisionShift = function() {
  return /** @type{?proto.themis.EventDivisionShift} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventDivisionShift, 2));
};


/**
 * @param {?proto.themis.EventDivisionShift|undefined} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
*/
proto.themis.EventDivisionShiftTeam.prototype.setEventDivisionShift = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.clearEventDivisionShift = function() {
  return this.setEventDivisionShift(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeam.prototype.hasEventDivisionShift = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EventTeam event_team = 3;
 * @return {?proto.themis.EventTeam}
 */
proto.themis.EventDivisionShiftTeam.prototype.getEventTeam = function() {
  return /** @type{?proto.themis.EventTeam} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventTeam, 3));
};


/**
 * @param {?proto.themis.EventTeam|undefined} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
*/
proto.themis.EventDivisionShiftTeam.prototype.setEventTeam = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.clearEventTeam = function() {
  return this.setEventTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeam.prototype.hasEventTeam = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Event event = 4;
 * @return {?proto.themis.Event}
 */
proto.themis.EventDivisionShiftTeam.prototype.getEvent = function() {
  return /** @type{?proto.themis.Event} */ (
    jspb.Message.getWrapperField(this, proto.themis.Event, 4));
};


/**
 * @param {?proto.themis.Event|undefined} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
*/
proto.themis.EventDivisionShiftTeam.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeam.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 order = 5;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeam.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 start_ts = 6;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeam.prototype.getStartTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.setStartTs = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 start_ts_nanos = 7;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeam.prototype.getStartTsNanos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.setStartTsNanos = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 offset_ts = 8;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeam.prototype.getOffsetTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.setOffsetTs = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 rubric_id = 9;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeam.prototype.getRubricId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.setRubricId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 score = 10;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeam.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated uint32 cheer_replay_videos = 11;
 * @return {!Array<number>}
 */
proto.themis.EventDivisionShiftTeam.prototype.getCheerReplayVideosList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.setCheerReplayVideosList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.addCheerReplayVideos = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.clearCheerReplayVideosList = function() {
  return this.setCheerReplayVideosList([]);
};


/**
 * repeated EventDivisionShiftTeamRubricArea event_division_shift_team_rubric_areas = 12;
 * @return {!Array<!proto.themis.EventDivisionShiftTeamRubricArea>}
 */
proto.themis.EventDivisionShiftTeam.prototype.getEventDivisionShiftTeamRubricAreasList = function() {
  return /** @type{!Array<!proto.themis.EventDivisionShiftTeamRubricArea>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventDivisionShiftTeamRubricArea, 12));
};


/**
 * @param {!Array<!proto.themis.EventDivisionShiftTeamRubricArea>} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
*/
proto.themis.EventDivisionShiftTeam.prototype.setEventDivisionShiftTeamRubricAreasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea}
 */
proto.themis.EventDivisionShiftTeam.prototype.addEventDivisionShiftTeamRubricAreas = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.themis.EventDivisionShiftTeamRubricArea, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.clearEventDivisionShiftTeamRubricAreasList = function() {
  return this.setEventDivisionShiftTeamRubricAreasList([]);
};


/**
 * repeated EventDivisionShiftTeamDeduction event_division_shift_team_deductions = 15;
 * @return {!Array<!proto.themis.EventDivisionShiftTeamDeduction>}
 */
proto.themis.EventDivisionShiftTeam.prototype.getEventDivisionShiftTeamDeductionsList = function() {
  return /** @type{!Array<!proto.themis.EventDivisionShiftTeamDeduction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventDivisionShiftTeamDeduction, 15));
};


/**
 * @param {!Array<!proto.themis.EventDivisionShiftTeamDeduction>} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
*/
proto.themis.EventDivisionShiftTeam.prototype.setEventDivisionShiftTeamDeductionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeamDeduction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivisionShiftTeamDeduction}
 */
proto.themis.EventDivisionShiftTeam.prototype.addEventDivisionShiftTeamDeductions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.themis.EventDivisionShiftTeamDeduction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.clearEventDivisionShiftTeamDeductionsList = function() {
  return this.setEventDivisionShiftTeamDeductionsList([]);
};


/**
 * optional bool complete = 13;
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeam.prototype.getComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.setComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool skipped = 14;
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeam.prototype.getSkipped = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.themis.EventDivisionShiftTeam} returns this
 */
proto.themis.EventDivisionShiftTeam.prototype.setSkipped = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionShiftTeamDeduction.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionShiftTeamDeduction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionShiftTeamDeduction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeamDeduction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventDivisionShiftTeam: (f = msg.getEventDivisionShiftTeam()) && proto.themis.EventDivisionShiftTeam.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    deductionName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionShiftTeamDeduction}
 */
proto.themis.EventDivisionShiftTeamDeduction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionShiftTeamDeduction;
  return proto.themis.EventDivisionShiftTeamDeduction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionShiftTeamDeduction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionShiftTeamDeduction}
 */
proto.themis.EventDivisionShiftTeamDeduction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.EventDivisionShiftTeam;
      reader.readMessage(value,proto.themis.EventDivisionShiftTeam.deserializeBinaryFromReader);
      msg.setEventDivisionShiftTeam(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeductionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionShiftTeamDeduction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionShiftTeamDeduction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionShiftTeamDeduction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeamDeduction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventDivisionShiftTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.EventDivisionShiftTeam.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDeductionName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeamDeduction.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeamDeduction} returns this
 */
proto.themis.EventDivisionShiftTeamDeduction.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EventDivisionShiftTeam event_division_shift_team = 2;
 * @return {?proto.themis.EventDivisionShiftTeam}
 */
proto.themis.EventDivisionShiftTeamDeduction.prototype.getEventDivisionShiftTeam = function() {
  return /** @type{?proto.themis.EventDivisionShiftTeam} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventDivisionShiftTeam, 2));
};


/**
 * @param {?proto.themis.EventDivisionShiftTeam|undefined} value
 * @return {!proto.themis.EventDivisionShiftTeamDeduction} returns this
*/
proto.themis.EventDivisionShiftTeamDeduction.prototype.setEventDivisionShiftTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShiftTeamDeduction} returns this
 */
proto.themis.EventDivisionShiftTeamDeduction.prototype.clearEventDivisionShiftTeam = function() {
  return this.setEventDivisionShiftTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeamDeduction.prototype.hasEventDivisionShiftTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float score = 3;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeamDeduction.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeamDeduction} returns this
 */
proto.themis.EventDivisionShiftTeamDeduction.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string deduction_name = 4;
 * @return {string}
 */
proto.themis.EventDivisionShiftTeamDeduction.prototype.getDeductionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDivisionShiftTeamDeduction} returns this
 */
proto.themis.EventDivisionShiftTeamDeduction.prototype.setDeductionName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.themis.EventDivisionShiftTeamRubricArea.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionShiftTeamRubricArea.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeamRubricArea.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventDivisionShiftTeam: (f = msg.getEventDivisionShiftTeam()) && proto.themis.EventDivisionShiftTeam.toObject(includeInstance, f),
    rubricArea: (f = msg.getRubricArea()) && proto.themis.RubricArea.toObject(includeInstance, f),
    score: jspb.Message.getFieldWithDefault(msg, 4, 0),
    producer: (f = msg.getProducer()) && proto.themis.Producer.toObject(includeInstance, f),
    eventDivisionShiftTeamRubricAreaScoresList: jspb.Message.toObjectList(msg.getEventDivisionShiftTeamRubricAreaScoresList(),
    proto.themis.EventDivisionShiftTeamRubricAreaScore.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea}
 */
proto.themis.EventDivisionShiftTeamRubricArea.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionShiftTeamRubricArea;
  return proto.themis.EventDivisionShiftTeamRubricArea.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea}
 */
proto.themis.EventDivisionShiftTeamRubricArea.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.EventDivisionShiftTeam;
      reader.readMessage(value,proto.themis.EventDivisionShiftTeam.deserializeBinaryFromReader);
      msg.setEventDivisionShiftTeam(value);
      break;
    case 3:
      var value = new proto.themis.RubricArea;
      reader.readMessage(value,proto.themis.RubricArea.deserializeBinaryFromReader);
      msg.setRubricArea(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScore(value);
      break;
    case 5:
      var value = new proto.themis.Producer;
      reader.readMessage(value,proto.themis.Producer.deserializeBinaryFromReader);
      msg.setProducer(value);
      break;
    case 6:
      var value = new proto.themis.EventDivisionShiftTeamRubricAreaScore;
      reader.readMessage(value,proto.themis.EventDivisionShiftTeamRubricAreaScore.deserializeBinaryFromReader);
      msg.addEventDivisionShiftTeamRubricAreaScores(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionShiftTeamRubricArea.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeamRubricArea.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventDivisionShiftTeam();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.EventDivisionShiftTeam.serializeBinaryToWriter
    );
  }
  f = message.getRubricArea();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.themis.RubricArea.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getProducer();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.themis.Producer.serializeBinaryToWriter
    );
  }
  f = message.getEventDivisionShiftTeamRubricAreaScoresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.themis.EventDivisionShiftTeamRubricAreaScore.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea} returns this
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EventDivisionShiftTeam event_division_shift_team = 2;
 * @return {?proto.themis.EventDivisionShiftTeam}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.getEventDivisionShiftTeam = function() {
  return /** @type{?proto.themis.EventDivisionShiftTeam} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventDivisionShiftTeam, 2));
};


/**
 * @param {?proto.themis.EventDivisionShiftTeam|undefined} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea} returns this
*/
proto.themis.EventDivisionShiftTeamRubricArea.prototype.setEventDivisionShiftTeam = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea} returns this
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.clearEventDivisionShiftTeam = function() {
  return this.setEventDivisionShiftTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.hasEventDivisionShiftTeam = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RubricArea rubric_area = 3;
 * @return {?proto.themis.RubricArea}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.getRubricArea = function() {
  return /** @type{?proto.themis.RubricArea} */ (
    jspb.Message.getWrapperField(this, proto.themis.RubricArea, 3));
};


/**
 * @param {?proto.themis.RubricArea|undefined} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea} returns this
*/
proto.themis.EventDivisionShiftTeamRubricArea.prototype.setRubricArea = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea} returns this
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.clearRubricArea = function() {
  return this.setRubricArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.hasRubricArea = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 score = 4;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea} returns this
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Producer producer = 5;
 * @return {?proto.themis.Producer}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.getProducer = function() {
  return /** @type{?proto.themis.Producer} */ (
    jspb.Message.getWrapperField(this, proto.themis.Producer, 5));
};


/**
 * @param {?proto.themis.Producer|undefined} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea} returns this
*/
proto.themis.EventDivisionShiftTeamRubricArea.prototype.setProducer = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea} returns this
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.clearProducer = function() {
  return this.setProducer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.hasProducer = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated EventDivisionShiftTeamRubricAreaScore event_division_shift_team_rubric_area_scores = 6;
 * @return {!Array<!proto.themis.EventDivisionShiftTeamRubricAreaScore>}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.getEventDivisionShiftTeamRubricAreaScoresList = function() {
  return /** @type{!Array<!proto.themis.EventDivisionShiftTeamRubricAreaScore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.themis.EventDivisionShiftTeamRubricAreaScore, 6));
};


/**
 * @param {!Array<!proto.themis.EventDivisionShiftTeamRubricAreaScore>} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea} returns this
*/
proto.themis.EventDivisionShiftTeamRubricArea.prototype.setEventDivisionShiftTeamRubricAreaScoresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore}
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.addEventDivisionShiftTeamRubricAreaScores = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.themis.EventDivisionShiftTeamRubricAreaScore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.themis.EventDivisionShiftTeamRubricArea} returns this
 */
proto.themis.EventDivisionShiftTeamRubricArea.prototype.clearEventDivisionShiftTeamRubricAreaScoresList = function() {
  return this.setEventDivisionShiftTeamRubricAreaScoresList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.EventDivisionShiftTeamRubricAreaScore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventDivisionShiftTeamRubricArea: (f = msg.getEventDivisionShiftTeamRubricArea()) && proto.themis.EventDivisionShiftTeamRubricArea.toObject(includeInstance, f),
    judge: (f = msg.getJudge()) && proto.themis.Judge.toObject(includeInstance, f),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    itemsValues: jspb.Message.getFieldWithDefault(msg, 6, ""),
    event: (f = msg.getEvent()) && proto.themis.Event.toObject(includeInstance, f),
    rubricAreaId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    rubricAreaItemId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.EventDivisionShiftTeamRubricAreaScore;
  return proto.themis.EventDivisionShiftTeamRubricAreaScore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.themis.EventDivisionShiftTeamRubricArea;
      reader.readMessage(value,proto.themis.EventDivisionShiftTeamRubricArea.deserializeBinaryFromReader);
      msg.setEventDivisionShiftTeamRubricArea(value);
      break;
    case 4:
      var value = new proto.themis.Judge;
      reader.readMessage(value,proto.themis.Judge.deserializeBinaryFromReader);
      msg.setJudge(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemsValues(value);
      break;
    case 7:
      var value = new proto.themis.Event;
      reader.readMessage(value,proto.themis.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRubricAreaId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRubricAreaItemId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.EventDivisionShiftTeamRubricAreaScore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEventDivisionShiftTeamRubricArea();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.themis.EventDivisionShiftTeamRubricArea.serializeBinaryToWriter
    );
  }
  f = message.getJudge();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.themis.Judge.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getItemsValues();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.themis.Event.serializeBinaryToWriter
    );
  }
  f = message.getRubricAreaId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getRubricAreaItemId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional EventDivisionShiftTeamRubricArea event_division_shift_team_rubric_area = 2;
 * @return {?proto.themis.EventDivisionShiftTeamRubricArea}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.getEventDivisionShiftTeamRubricArea = function() {
  return /** @type{?proto.themis.EventDivisionShiftTeamRubricArea} */ (
    jspb.Message.getWrapperField(this, proto.themis.EventDivisionShiftTeamRubricArea, 2));
};


/**
 * @param {?proto.themis.EventDivisionShiftTeamRubricArea|undefined} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
*/
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.setEventDivisionShiftTeamRubricArea = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.clearEventDivisionShiftTeamRubricArea = function() {
  return this.setEventDivisionShiftTeamRubricArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.hasEventDivisionShiftTeamRubricArea = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Judge judge = 4;
 * @return {?proto.themis.Judge}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.getJudge = function() {
  return /** @type{?proto.themis.Judge} */ (
    jspb.Message.getWrapperField(this, proto.themis.Judge, 4));
};


/**
 * @param {?proto.themis.Judge|undefined} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
*/
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.setJudge = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.clearJudge = function() {
  return this.setJudge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.hasJudge = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float score = 5;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string items_values = 6;
 * @return {string}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.getItemsValues = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.setItemsValues = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Event event = 7;
 * @return {?proto.themis.Event}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.getEvent = function() {
  return /** @type{?proto.themis.Event} */ (
    jspb.Message.getWrapperField(this, proto.themis.Event, 7));
};


/**
 * @param {?proto.themis.Event|undefined} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
*/
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 rubric_area_id = 10;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.getRubricAreaId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.setRubricAreaId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 rubric_area_item_id = 8;
 * @return {number}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.getRubricAreaItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.setRubricAreaItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string comment = 9;
 * @return {string}
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.EventDivisionShiftTeamRubricAreaScore} returns this
 */
proto.themis.EventDivisionShiftTeamRubricAreaScore.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.themis.ProgramCredit.prototype.toObject = function(opt_includeInstance) {
  return proto.themis.ProgramCredit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.themis.ProgramCredit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramCredit.toObject = function(includeInstance, msg) {
  var f, obj = {
    producerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amountInt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    programName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    producerName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    crPayoutAmount: jspb.Message.getFieldWithDefault(msg, 7, ""),
    crPayoutAmountInt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    programId: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.themis.ProgramCredit}
 */
proto.themis.ProgramCredit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.themis.ProgramCredit;
  return proto.themis.ProgramCredit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.themis.ProgramCredit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.themis.ProgramCredit}
 */
proto.themis.ProgramCredit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProducerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmountInt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgramName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducerName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrPayoutAmount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCrPayoutAmountInt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgramId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.themis.ProgramCredit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.themis.ProgramCredit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.themis.ProgramCredit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.themis.ProgramCredit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProducerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmountInt();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProgramName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProducerName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCrPayoutAmount();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCrPayoutAmountInt();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getProgramId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
};


/**
 * optional uint32 producer_id = 1;
 * @return {number}
 */
proto.themis.ProgramCredit.prototype.getProducerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ProgramCredit} returns this
 */
proto.themis.ProgramCredit.prototype.setProducerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string amount = 2;
 * @return {string}
 */
proto.themis.ProgramCredit.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ProgramCredit} returns this
 */
proto.themis.ProgramCredit.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 amount_int = 3;
 * @return {number}
 */
proto.themis.ProgramCredit.prototype.getAmountInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ProgramCredit} returns this
 */
proto.themis.ProgramCredit.prototype.setAmountInt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.themis.ProgramCredit.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ProgramCredit} returns this
 */
proto.themis.ProgramCredit.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string program_name = 5;
 * @return {string}
 */
proto.themis.ProgramCredit.prototype.getProgramName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ProgramCredit} returns this
 */
proto.themis.ProgramCredit.prototype.setProgramName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string producer_name = 6;
 * @return {string}
 */
proto.themis.ProgramCredit.prototype.getProducerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ProgramCredit} returns this
 */
proto.themis.ProgramCredit.prototype.setProducerName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string cr_payout_amount = 7;
 * @return {string}
 */
proto.themis.ProgramCredit.prototype.getCrPayoutAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.themis.ProgramCredit} returns this
 */
proto.themis.ProgramCredit.prototype.setCrPayoutAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint64 cr_payout_amount_int = 8;
 * @return {number}
 */
proto.themis.ProgramCredit.prototype.getCrPayoutAmountInt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ProgramCredit} returns this
 */
proto.themis.ProgramCredit.prototype.setCrPayoutAmountInt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 program_id = 9;
 * @return {number}
 */
proto.themis.ProgramCredit.prototype.getProgramId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.themis.ProgramCredit} returns this
 */
proto.themis.ProgramCredit.prototype.setProgramId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * @enum {number}
 */
proto.themis.PaymentType = {
  CC: 0,
  ACH: 1,
  GOOGLE_PAY: 2,
  APPLE_PAY: 3,
  MICROSOFT_PAY: 4,
  PRODUCER_COVERED: 5
};

/**
 * @enum {number}
 */
proto.themis.RegistrationTransactionScope = {
  TRANSACTION_ITEMS_SPECIFIC: 0,
  PRODUCER_PROGRAM_ITEMS: 1,
  PRODUCER_TEAM_ITEMS: 2,
  PRODUCER_LOCATION_ITEMS: 3,
  EVENT_PROGRAM_ITEMS: 4,
  EVENT_TEAM_ITEMS: 5,
  EVENT_LOCATION_ITEMS: 6,
  PROGRAM_ITEMS: 7,
  LOCATION_ITEMS: 8,
  TEAM_ITEMS: 9,
  BRAND_PROGRAM_ITEMS: 10,
  BRAND_TEAM_ITEMS: 11,
  BRAND_LOCATION_ITEMS: 12,
  PRODUCER_ITEMS: 13,
  BRAND_ITEMS: 14,
  EVENT_ITEMS: 15,
  PRODUCER_PROGRAM_LOCATION_ITEMS: 16,
  EVENT_PROGRAM_LOCATION_ITEMS: 17,
  BRAND_PROGRAM_LOCATION_ITEMS: 18,
  DEPOSIT: 19
};

/**
 * @enum {number}
 */
proto.themis.PaidScope = {
  PAID: 0,
  UNPAID: 1,
  ANY: 2,
  PENDING: 3
};

/**
 * @enum {number}
 */
proto.themis.Gender = {
  MALE: 0,
  FEMALE: 1,
  OTHER: 2,
  GENDER_UNKNOWN: 3
};

/**
 * @enum {number}
 */
proto.themis.AllowedGender = {
  MALE_ONLY: 0,
  FEMALE_ONLY: 1,
  MALE_FEMALE: 2
};

/**
 * @enum {number}
 */
proto.themis.UnitType = {
  PERCENT: 0,
  DOLLAR: 1
};

/**
 * @enum {number}
 */
proto.themis.DivisionSplitType = {
  SIZE_SPLIT: 0,
  D_SPLIT: 1,
  ALL_GIRL: 2,
  GROUP_SPLIT: 3
};

/**
 * @enum {number}
 */
proto.themis.ValueType = {
  STRING: 0,
  SINT64: 1,
  DOUBLE: 2,
  EMAIL: 3,
  PHONE: 4,
  CLOCK_TIME: 5,
  RANGE: 6
};

/**
 * @enum {number}
 */
proto.themis.field_type = {
  INTEGER: 0,
  REAL: 1,
  STRING: 2
};

goog.object.extend(exports, proto.themis);
