/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Transition, useStyles } from "lib/theme"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {
  Button, Checkbox,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Grid,
  IconButton, InputAdornment, List, ListItem, ListItemIcon, TextField, Tooltip,
  Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import themis_common from 'store/themis_common_pb'
import { IAppState } from "../../../store/store"
import { selectBrandJudges, selectCurrentEventDates, selectCurrentEventFloors } from "../../../store/producer/producerSelectors"
import { format, parse } from "date-fns"
import { Clear as ClearIcon, Add as AddIcon, AccessTime as AccessTimeIcon, Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons"
import { eventDivisionSortFunction } from "../../../lib/validators"
import { KeyboardTimePicker } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import {
  addPanelJudgeShift,
  addEventJudgeShifts,
  getEventJudgeShifts, deleteEventJudgeShift,
  addPanelJudgeShiftDivision,
  copyFromPreviousDay,
  deletePanelJudgeShift,
  deletePanelJudgeShiftDivision,
  editPanelJudgeShift,
  fixSchedule,
  getEventDivisionUses,
  sortPanelJudgeShiftDivision
} from "../../../store/producer/eventActions"
import FloorDateSelect from "../../util/FloorDateSelect"

interface eventScheduleProps {
  producerId: number,
  brandId: number,
  eventId: number,
  eventDivisions: themis_common.EventDivision.AsObject[]
  eventDivisionUses: themis_common.EventDivisionUsage.AsObject[],
  setEventDivisionUses: Function,
  userCanViewEvent: boolean,
  userCanEditEvent: boolean,
  event: themis_common.Event.AsObject,
}

const EventSchedule: React.FC<eventScheduleProps> = ({ producerId, brandId, eventId, eventDivisions, eventDivisionUses, userCanEditEvent, userCanViewEvent, setEventDivisionUses, event }): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const eventDates = useSelector((state: IAppState) => selectCurrentEventDates(state))
  const eventFloors = useSelector((state: IAppState) => selectCurrentEventFloors(state))
  const [currentDate, setCurrentDate] = useState<themis_common.EventDate.AsObject | undefined >(undefined)
  const [currentFloor, setCurrentFloor] = useState<themis_common.EventFloor.AsObject | undefined >(undefined)
  const [currentFloorDate, setCurrentFloorDate] = useState<themis_common.EventFloorDate.AsObject | undefined >(undefined)
  const [currentPanel, setCurrentPanel] = useState<themis_common.EventFloorDateJudgingPanel.AsObject | undefined>(undefined)
  const [currentShift, setCurrentShift] = useState<themis_common.EventFloorDateJudgingPanelShift.AsObject | undefined>(undefined)
  const [currentFilter, setCurrentFilter] = useState("")  // This is the primary filter
  const [filterArray, setFilterArray] = useState<string[]>(Array<string>(eventFloors?.length || 0)) // This is the filter for each separate panel
  const [showAddShift, setShowAddShift] = useState(false)
  const [selectedStartTime, setSelectedStartTime] = useState<MaterialUiPickersDate|undefined>(undefined)
  const [selectedEndTime, setSelectedEndTime] = useState<MaterialUiPickersDate|undefined>(undefined)
  const [onlyTeams, setOnlyTeams] = useState(false)
  const [shiftEdit, setShiftEdit] = useState(false)


  const [eventJudgeShifts, setEventJudgeShifts] = useState<themis_common.EventJudgeShift.AsObject[]>([])
  const [eventJudgeShiftsDialogOpen, setEventJudgeShiftsDialogOpen] = useState<boolean>(false)
  const [judgesToAdd, setJudgesToAdd] = useState<themis_common.Judge.AsObject[]>([])

  const divisionUsesMap = new Map()
  eventDivisionUses.forEach(use => {
    divisionUsesMap.set(use.eventDivisionId, use.count)
  })

  const sortedEventDivisions = useMemo(() => { // They are also filtered here
    return eventDivisions
      .filter(item => { // filter to only divisions with teams
        return onlyTeams ? item.eventTeamsList.length : true
      })
      .filter(item => {
        if (!item.division?.divisionRestriction?.maxDays) return true
        if (!divisionUsesMap.has(item.id) || divisionUsesMap.get(item.id) === undefined) return true
        return item.division.divisionRestriction.maxDays > divisionUsesMap.get(item.id)
      })
      .filter(item => { // filter out any that are already placed on this day on any floor
        return !eventFloors?.find((floor: themis_common.EventFloor.AsObject) => {
          const filteredDays = floor.daysList.filter(floorDay => {
            return floorDay.eventDateId === currentDate?.id
          })
          return filteredDays.find(day => day.judgingPanelsList.find(panel => panel.shiftsList.find(shift => shift.eventDivisionsList.find(div => div.id === item.id))))
        })
      })
      .filter((item) => { // This filters on the user input selection
        const splitFilter = currentFilter.split(" ").filter((item) => item.length > 0).map(item => item.toLowerCase())
        return splitFilter.reduce<boolean>((prev, curr) => prev && (item.division?.tier?.name.toLowerCase().includes(curr) || item.division?.name.toLowerCase().includes(curr) || false), true)
      })
      .sort(eventDivisionSortFunction)
  }, [eventDivisions, onlyTeams, divisionUsesMap, eventFloors, currentDate?.id, currentFilter])

  const onDragEnd = async (result: any) => {

    if (!result.destination) {
      return
    }

    if (result.draggableId === "filteredDivisions" && result.destination.droppableId !== "divisionList" && currentFloorDate) {
      // Filtered Divisions
      setCurrentFilter("")
      const shiftId = result.destination.droppableId.split('-').pop()
      const toIndex = result.destination.index
      let index = 0
      for (const division of sortedEventDivisions) {
        const divisionId = division.id
        const toThisIndex = toIndex + index
        if (shiftId && divisionId) {
          await addPanelJudgeShiftDivision(dispatch, currentFloorDate, +shiftId, +divisionId, toThisIndex)
        }
        index++
      }
    }
    else if (result.destination.droppableId === result.source.droppableId) {
      // sort within a shift
      const divisionId = result.draggableId.split('-').pop()
      const shiftId = +result.destination.droppableId.split('-')[1]
      const fromIndex = result.source.index
      let toIndex = result.destination.index + 1
      if (fromIndex >= toIndex && toIndex !== 0) {
        toIndex -= 1
      }
      if (shiftId && divisionId && currentFloorDate) {
        await sortPanelJudgeShiftDivision(dispatch, currentFloorDate, +shiftId, +divisionId, +toIndex)
      }
    }
    else if (result.source.droppableId === "divisionList" && result.destination.droppableId !== "divisionList" && currentFloorDate) {
      // Drag from available to shift
      const divisionId = result.draggableId.split('-').pop()
      const shiftId = result.destination.droppableId.split('-').pop()
      const toIndex = result.destination.index
      if (shiftId && divisionId) {
        await addPanelJudgeShiftDivision(dispatch, currentFloorDate, +shiftId, +divisionId, toIndex)
      }
    }
    else if (result.source.droppableId !== "divisionList" && result.destination.droppableId === "divisionList" && currentFloorDate) {
      // Drag from shift to available
      const divisionId = result.draggableId.split('-').pop()
      const shiftId = result.source.droppableId.split('-').pop()
      if (shiftId && divisionId) {
        await deletePanelJudgeShiftDivision(dispatch, currentFloorDate, +shiftId, +divisionId)
      }
    }
    else if (result.source.droppableId !== "divisionList" && result.destination.droppableId !== "divisionList" && currentFloorDate) {
      // Drag from shift to shift
      const divisionId = result.draggableId.split('-').pop()
      const fromShiftId = result.source.droppableId.split('-').pop()
      const toShiftId = result.destination.droppableId.split('-').pop()
      const toIndex = result.destination.index
      if (fromShiftId && toShiftId && divisionId) {
        await deletePanelJudgeShiftDivision(dispatch, currentFloorDate, +fromShiftId, +divisionId)
        await addPanelJudgeShiftDivision(dispatch, currentFloorDate, +toShiftId, +divisionId, toIndex)
      }
    }
    const foundEventDivUses = await getEventDivisionUses(eventId, brandId, producerId)
    setEventDivisionUses(foundEventDivUses)

  }

  const handleEndShiftChange = (e: MaterialUiPickersDate) => {
    setSelectedEndTime(e)
  }

  const handleStartShiftChange = (e: MaterialUiPickersDate) => {
    setSelectedStartTime(e)
  }

  const deleteShift = useCallback((shiftId: number, panelId: number) => {
    if (currentFloorDate) deletePanelJudgeShift(dispatch, currentFloorDate, shiftId, panelId)
  }, [dispatch, currentFloorDate])

  const addPanelShift = useCallback(() => {
    if (!selectedStartTime || !selectedEndTime || !currentDate?.startClockTime?.formattedDateTime || !currentFloorDate || !currentPanel ) return
    const datePart = `${currentDate.startClockTime.formattedDateTime.year}-${currentDate.startClockTime.formattedDateTime?.monthLeadingNumber}-${currentDate.startClockTime.formattedDateTime?.dayLeadingNumber}`

    const startTime = datePart + "|" + format(selectedStartTime, "HH:mm")
    const endTime = datePart + "|" + format(selectedEndTime, "HH:mm")
    setSelectedStartTime(selectedEndTime)

    addPanelJudgeShift(dispatch, currentFloorDate, currentPanel, startTime, endTime)

    setShowAddShift(false)
  }, [selectedStartTime, selectedEndTime, currentDate?.startClockTime?.formattedDateTime, currentFloorDate, currentPanel, dispatch])

  const editPanelShift = useCallback(() => {
    if (!selectedStartTime || !selectedEndTime || !currentDate?.startClockTime?.formattedDateTime || !currentFloorDate || !currentPanel || !currentShift ) return
    const datePart = `${currentDate.startClockTime.formattedDateTime.year}-${currentDate.startClockTime.formattedDateTime?.monthLeadingNumber}-${currentDate.startClockTime.formattedDateTime?.dayLeadingNumber}`

    const startTime = datePart + "|" + format(selectedStartTime, "HH:mm")
    const endTime = datePart + "|" + format(selectedEndTime, "HH:mm")

    editPanelJudgeShift(dispatch, currentFloorDate, currentPanel, currentShift, startTime, endTime)

    setShowAddShift(false)
  }, [selectedStartTime, selectedEndTime, currentDate?.startClockTime?.formattedDateTime, currentFloorDate, currentPanel, dispatch])

  const handleCopyPreviousDay = useCallback((prevDateId: number) => {
    if (currentFloorDate) copyFromPreviousDay(dispatch, currentFloorDate.id, prevDateId)
  }, [currentFloorDate, dispatch])


  useEffect(() => {
    if (!currentDate && eventDates?.length) {
      setCurrentDate(eventDates[0])
    }
    if (eventFloors?.length) {
      if (currentFloor) setCurrentFloor(currentFloor)
      else setCurrentFloor(eventFloors.find(floor => floor.daysList.find(day => day.eventDateId === currentDate?.id)))
    }

    setCurrentFloor(floor => {
      if (!floor && eventFloors) {
        return eventFloors[0]
      }
      if (floor && eventFloors) {
        return eventFloors.find(thisFloor => thisFloor.id === floor.id)
      }
      return floor
    })
  }, [eventDates, currentDate, eventFloors])

  useEffect(() => {
    if (!currentFloor || !currentDate) {
      setCurrentFloorDate(undefined)
    } else {
      const foundValue = currentFloor.daysList.find(day => day.eventDateId === currentDate.id)
      setCurrentFloorDate(foundValue)
    }
  }, [currentFloor?.id, currentDate?.id, eventFloors]) // Do not remove eventFloors from this list

  const filteredEventDates = useMemo<themis_common.EventDate.AsObject[]>(() => {
    if (!eventDates?.length || !currentFloor?.daysList?.length) return []
    const filteredDates = eventDates.filter(eventDate => currentFloor.daysList.find(day => eventDate.id === day.eventDateId && day.active))

    return filteredDates ?? []
  }, [eventDates, currentFloor?.daysList])

  const filteredEventFloors = useMemo<themis_common.EventFloor.AsObject[]>(() => {
    const filteredFloors = eventFloors?.filter((floor) => floor.daysList.find(day => day.eventDateId === currentDate?.id && day.active))

    if (!filteredFloors?.find(eventFloor => eventFloor.id === currentFloor?.id)) {
      if (filteredFloors?.length) {
        setCurrentFloor(filteredFloors[0])
      }
    }

    return filteredFloors ?? []
  }, [currentDate?.id, currentFloor?.id, eventFloors])

  const eventFloorDayPanels = useMemo(() => {
    return currentFloor?.daysList
      .find(day => day.eventDateId === currentDate?.id && day.active)?.judgingPanelsList
      .map((panel: themis_common.EventFloorDateJudgingPanel.AsObject) => { return { ...panel, shiftsList: panel.shiftsList
        .map(shift => { return { ...shift, eventDivisionsList: shift.eventDivisionsList.sort((a, b) => {
          if (a.sortOrder < b.sortOrder) return -1
          return 1
        })
        }})
        .sort((a, b) => { if (a.startTs < b.startTs ) return -1; return 1} )
      } } ) || []
  }, [currentDate, currentFloor])  // Do not remove doRefresh from this list.

  const setCallScheduler = useCallback(() => {
    console.log("The button calls the schedule function", eventId, currentDate?.id, producerId, brandId)
    if (currentDate?.id && currentDate.id > 0) {
      fixSchedule(eventId, currentDate.id, producerId, brandId)
    }

  }, [])

  const addFloorDatePanelShiftDialog = (
    <Dialog
      open={showAddShift}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => { setShowAddShift(false) }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{selectedStartTime ? "Edit" : "Add" } Shift</DialogTitle>
      <DialogContent>
        <form className={classes.dateForm} noValidate>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.gridForm}>
              <KeyboardTimePicker
                placeholder="08:00 AM"
                variant="inline"
                margin="normal"
                autoOk={true}
                id={`start_time`}
                label="Start Time"
                value={selectedStartTime}
                required={true}
                inputVariant="outlined"
                onChange={handleStartShiftChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<AccessTimeIcon/>}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridForm}>
              <KeyboardTimePicker
                placeholder="05:00 PM"
                variant="inline"
                margin="normal"
                autoOk={true}
                id={`end_time`}
                label="End Time"
                value={selectedEndTime}
                required={true}
                inputVariant="outlined"
                onChange={handleEndShiftChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<AccessTimeIcon/>}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={ () => { setShowAddShift(false) } } color="primary">
          Cancel
        </Button>
        <Button onClick={() => { shiftEdit ? editPanelShift() : addPanelShift() }} color="primary">
          {shiftEdit ? "Edit" : "Add" } Panel Shift
        </Button>
      </DialogActions>
    </Dialog>
  )

  const brandJudges = useSelector((state: IAppState) => selectBrandJudges(state))

  const availableJudges = useMemo(() => {
    return brandJudges.filter((bJudge: themis_common.Judge.AsObject) => !eventJudgeShifts.find((eJShift) => bJudge.id === eJShift.judgeId))
  }, [eventJudgeShifts])

  const handleAddEventJudgeShifts = useCallback(async() => {
    const judges: { id: number, name: string }[] = []
    judgesToAdd.forEach((judge) => {
      judges.push({ id: judge.id, name: judge.name })
    })

    if (currentShift && currentPanel) {
      const judgeShifts = await addEventJudgeShifts(judges, currentPanel.id, currentShift.id, producerId, brandId, eventId)
      setEventJudgeShifts(judgeShifts)
    }
    setJudgesToAdd([])
  }, [judgesToAdd, currentShift, currentPanel, eventJudgeShifts, addEventJudgeShifts])

  const handleGetEventJudgeShifts = useCallback(async(panelShiftId: number) => {
    const judgeShifts = await getEventJudgeShifts(panelShiftId, producerId, brandId, eventId)
    setEventJudgeShifts(judgeShifts)
  }, [getEventJudgeShifts, eventJudgeShifts])

  const handleDeleteEventJudgeShift = useCallback(async(eventJudgeShiftId: number, panelShiftId: number) => {
    const judgeShifts = await deleteEventJudgeShift(eventJudgeShiftId, panelShiftId, producerId, brandId, eventId)
    setEventJudgeShifts(judgeShifts)
  }, [deleteEventJudgeShift, eventJudgeShifts])


  const eventJudgeShiftsDialog = useMemo(() => {
    return (
      <Dialog
        open={eventJudgeShiftsDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {setEventJudgeShiftsDialogOpen(false); setJudgesToAdd([])}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
        style={{ overflowY: "scroll" , height: "600px"}}
      >
        <DialogTitle id="alert-dialog-slide-title">Panel Shift Judges</DialogTitle>
        <DialogContent>
          <Grid container>
            <>
              <Grid container item xs={12} sm={12} md={6}>
                <Autocomplete
                  value={judgesToAdd}
                  multiple
                  id="add-multiple-judges"
                  limitTags={1}
                  options={availableJudges}
                  disableCloseOnSelect
                  getOptionLabel={(judge: themis_common.Judge.AsObject) => (judge.name || 'No Name Found') + ' ' + (judge.person?.nickName ? ' (' + judge.person?.nickName + ')' : '')}
                  style={{ width: 300 }}
                  renderOption={(judge, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        checked={selected}
                        size="small"
                      />
                      {judge.person?.name}
                    </React.Fragment>
                  )}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Select Judges to Add" />
                  )}
                  onChange={(e, v) => {
                    if (setJudgesToAdd) setJudgesToAdd(v)
                  }}
                />
                <Button variant="outlined" size="small" onClick={() => { handleAddEventJudgeShifts() }} color="primary" style={{ marginLeft: "5px", maxHeight:"42px" }}>
                  Add
                </Button>
                {eventJudgeShifts.map((judgeShift) => {
                  return (
                    <Grid item xs={12} key={judgeShift.judgeId}>
                      <List dense={true}>
                        <ListItem>
                          <ListItemIcon>
                            <IconButton size="small" onClick={ () => { if (currentShift) {handleDeleteEventJudgeShift(judgeShift.id, currentShift.id)} }}><DeleteIcon /></IconButton>
                          </ListItemIcon>
                          {judgeShift.judgeName}
                        </ListItem>
                      </List>
                    </Grid>
                  )
                })
                }
              </Grid>
              <Grid item xs={12} sm={12} md ={6}>
                <Typography>Rubric Areas</Typography>
              </Grid>
            </>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => { setEventJudgeShiftsDialogOpen(false); setEventJudgeShifts([]); setJudgesToAdd([])} } color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    )
  }, [eventJudgeShiftsDialogOpen, judgesToAdd, brandJudges, eventJudgeShifts])

  const dragDropStuff = useMemo(() => {
    return <DragDropContext onDragEnd={onDragEnd}>
      <Grid item container xs={2} alignContent='flex-start'>
        <Typography variant="h2" align='center' style={{ width: '100%'}}>Available Divisions</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={"Filter Divisions"}
          id={`Filter Divisions`}
          value={currentFilter}
          name={"FilterDivisions"}
          onChange={(e) => {
            setCurrentFilter(e.target.value)
          }}
          InputProps={{
            endAdornment: (<InputAdornment position="end"><ClearIcon style={{ cursor: "pointer" }} onClick={() => { setCurrentFilter("") }} /> </InputAdornment>)
          }}
        />
        <Droppable droppableId="divisionList">
          {(provided, snapshot) => (
            <Grid container item xs={12} ref={provided.innerRef} {...provided.droppableProps} style={{ border: '1px solid white', padding: 10 }} >
              {
                sortedEventDivisions.length > 0 && currentFilter.length > 0 ?
                  <Draggable key={"filteredDivisions"} draggableId={"filteredDivisions"} index={-1}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={classes.dndItem}>
                        {"[All Filtered Divisions]"}
                      </div>
                    )}
                  </Draggable>
                  : []
              }
              { sortedEventDivisions.map((div, i) =>
                <Draggable key={"eventDivision-" + div.id} draggableId={"eventDivision-Unused-" + div.id} index={i}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={classes.dndItem}>
                      {`${div.division?.tier?.name} - ${div.name ? div.name : div.division?.name} (${div.eventTeamsList.length})`}
                    </div>
                  )}
                </Draggable>
              )}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </Grid>
      <Grid item container xs={10} spacing={1}>
        { eventFloorDayPanels.map((panel, i) => <Grid key={`panel-${i}`} item container xs={6} sm={4} lg={3} xl={2} alignContent='flex-start'>
          <Grid container>
            <Grid item xs={2} />
            <Grid item xs={8} >
              <Typography variant="h2" align='center' style={{ width: '100%'}}>{panel.name}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Add Shift"><IconButton onClick={() => { setSelectedStartTime(undefined); setShiftEdit(false); setCurrentPanel(panel); setShowAddShift(true) }}><AddIcon fontSize="small" /></IconButton></Tooltip>
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={"Filter Divisions"}
            id={`Filter_Divisions_${panel.id}`}
            value={filterArray[i]}
            name={`Filter_Divisions_${panel.id}`}
            onChange={(e) => {
              setCurrentFilter(e.target.value)
            }}
            InputProps={{
              endAdornment: (<InputAdornment position="end"><ClearIcon style={{ cursor: "pointer" }} onClick={() => { setCurrentFilter("") }} /> </InputAdornment>)
            }}
          />
          <Grid container spacing={1}>
            { panel.shiftsList.map((shift: themis_common.EventFloorDateJudgingPanelShift.AsObject) => {
              const baseStartTime = parse(shift.startTs.split('|')[1], "HH:mm", new Date())
              const startTime = format(baseStartTime, "hh:mm a")
              const baseEndTime = parse(shift.endTs.split('|')[1], "HH:mm", new Date())
              const endTime = format(baseEndTime, "hh:mm a")
              return <Grid key={`shift-${shift.id}`} item xs={12}>
                <Typography>{startTime} - {endTime}
                  <IconButton onClick={()=>{ deleteShift(shift.id, panel.id) }}><DeleteIcon/></IconButton>
                  <IconButton onClick={()=>{ setSelectedStartTime(baseStartTime); setSelectedEndTime(baseEndTime); setCurrentPanel(panel); setShiftEdit(true); setCurrentShift(shift); setShowAddShift(true) }}><EditIcon/></IconButton>
                  <Button size="small" variant="outlined" onClick={() => {setEventJudgeShiftsDialogOpen(true); setCurrentPanel(panel); setCurrentShift(shift); handleGetEventJudgeShifts(shift.id)}}><EditIcon fontSize="small" />&nbsp;Judges</Button>
                </Typography>
                <Droppable droppableId={`panelShift-${shift.id}`} key={`panelShift-${shift.id}`}>
                  {(provided, snapshot) => (
                    <Grid container item xs={12} ref={provided.innerRef} {...provided.droppableProps}
                      style={{ border: '1px solid white', padding: 10 }}>
                      { shift.eventDivisionsList
                        .filter(item => { // filter to only divisions with teams
                          return onlyTeams ? item.eventTeamsList.length : true
                        })
                        .map((div: themis_common.EventDivision.AsObject, d: number) => (
                          <Draggable key={"eventDivision-" + div.id} draggableId={`eventDivision-${shift.id}-${div.id}`} index={d}>
                            {(provided, snapshot) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={classes.dndItem}>
                                {`${div.division?.tier?.name} - ${div.name ? div.name : div.division?.name} (${div.eventTeamsList.length})`}
                              </div>
                            )}
                          </Draggable> )
                        )}
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              </Grid>
            }
            )}
          </Grid>
        </Grid>
        )}

        {
        // button for scheduling
        }
        <Grid>
          <Button onClick={() => setCallScheduler()} variant="outlined">
            Check Day&apos;s Schedule
          </Button>
        </Grid>

      </Grid>
    </DragDropContext>

  }, [classes.dndItem, currentFilter, deleteShift, eventFloorDayPanels, filterArray, onDragEnd, onlyTeams, sortedEventDivisions])

  return !userCanViewEvent || !currentFloorDate || !eventFloorDayPanels  ? <></> : (
    <Grid container spacing={1}>
      <Grid item xs={12} md={2} lg={2} xl={1}>
        <Typography variant="h2">
          Schedule
        </Typography>
      </Grid>
      <FloorDateSelect
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        filteredEventDates={filteredEventDates}
        currentFloor={currentFloor}
        setCurrentFloor={setCurrentFloor}
        filteredEventFloors={filteredEventFloors}
        onlyTeams={onlyTeams}
        setOnlyTeams={setOnlyTeams}
        handleCopyPreviousDay={handleCopyPreviousDay}
        event={event}
        userCanEditEvent={userCanEditEvent}
        currentFloorDate={currentFloorDate}
        setCurrentFloorDate={setCurrentFloorDate}

      />

      <Grid container item xs={12} spacing={1}>
        {dragDropStuff}
      </Grid>
      { addFloorDatePanelShiftDialog }
      { eventJudgeShiftsDialog }
    </Grid>)
}

export default EventSchedule
