import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Transition, useStyles } from "lib/theme"
import { addHours, format, parse } from "date-fns"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Typography, useTheme, TextField
} from '@material-ui/core'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { Edit as EditIcon, CheckCircleOutline as CheckedIcon, RadioButtonUnchecked as UncheckedIcon, AccessTime as AccessTimeIcon, Add as AddIcon, Delete as DelIcon } from '@material-ui/icons'
import { IAppState } from "store/store"
import { selectCurrentEvent, selectCurrentEventFloors } from "store/producer/producerSelectors"
import { addPanel, delPanel, editFloorDate, formatEventDates } from "store/producer/eventActions"
import themis_common from "store/themis_common_pb"

interface eventFloorDatesProps {
  producerId: number,
  brandId: number,
  eventId: number,
  userCanViewEvent: boolean,
  userCanEditEvent: boolean,
  eventFloorId: number,
  floor: themis_common.EventFloor.AsObject,
  setEventFloors: Function
}

const EventFloorDates: React.FC<eventFloorDatesProps> = ({ producerId, brandId, eventId, userCanViewEvent, userCanEditEvent, eventFloorId, floor, setEventFloors }): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const [selectedStartTime, setSelectedStartTime] = useState<Date | null>(null)
  const [selectedEndTime, setSelectedEndTime] = useState<Date | null>(null)
  const [activeDay, setActiveDay] = useState<themis_common.EventFloorDate.AsObject>()
  const [openPanelDialog, setOpenPanelDialog] = useState(false)
  const [panelName, setPanelName] = useState("Panel")
  const [defaultDate, setDefaultDate] = useState("")
  const [defaultStart, setDefaultStart] = useState("")
  const [defaultEnd, setDefaultEnd] = useState("")

  const changeDayActive = useCallback((day: themis_common.EventFloorDate.AsObject): void => {
    const doChangeActiveDay = async () => {
      // Change the day active status
      const newDay = { ...day }
      newDay.active = !newDay.active
      const floors = await editFloorDate(newDay, producerId, brandId, eventId, dispatch)
      setEventFloors(floors)
    }
    doChangeActiveDay()
  }, [brandId, eventId, producerId, setEventFloors])

  const addEventFloorDateIcon = useMemo(() => {
    if (userCanEditEvent) {
      return (
        <IconButton className={classes.clickable} aria-label="add" onClick={_e => { setAddDateDialogOpen(true) }}>
          <AddIcon fontSize="inherit" />
        </IconButton>
      )
    } else {
      return []
    }
  }, [classes.clickable, userCanEditEvent])

  const handleStartChange = (date: Date | null) => {
    if (date) {
      setSelectedStartTime(date)
      const stringDate = date?.toString()
      const slicedDate = stringDate?.slice(16, 21)
      setActiveDay(currentDay => {
        if (!currentDay) return {
          id: 0,
          producerId: 0,
          brandId: 0,
          eventId: 0,
          startDayTime: "",
          endDayTime: "",
          eventFloorId: 0,
          eventDateId: 0,
          active: false,
          judgingPanelsList: [],
          rotationTime: 0,
        }
        const newActiveDay = { ...currentDay }
        const startDay = currentDay.startDayTime.split('|')[0]
        newActiveDay.startDayTime = startDay + '|' + slicedDate
        return newActiveDay
      })
    }
  }

  const handleEndChange = (date: Date | null) => {
    if (date) {
      setSelectedEndTime(date)
      const stringDate = date?.toString()
      const slicedDate = stringDate?.slice(16, 21)
      setActiveDay(currentDay => {
        if (!currentDay) return {
          id: 0,
          producerId: 0,
          brandId: 0,
          eventId: 0,
          startDayTime: "",
          endDayTime: "",
          eventFloorId: 0,
          eventDateId: 0,
          active: false,
          judgingPanelsList: [],
          rotationTime: 0,
        }
        const newActiveDay = { ...currentDay }
        const endDay = currentDay.endDayTime.split('|')[0]
        newActiveDay.endDayTime = endDay + '|' + slicedDate
        return newActiveDay
      })
    }
  }

  const saveEventFloorDate = useCallback(() => {
    const doSaveEFD = async () => {
      if (!activeDay) return
      const floors = await editFloorDate(activeDay, producerId, brandId, eventId, dispatch)
      setEventFloors(floors)
    }
    doSaveEFD()
  }, [activeDay, brandId, dispatch, eventId, producerId, setEventFloors])

  const eventFloors = useSelector((state: IAppState) => selectCurrentEventFloors(state))
  const event = useSelector((state: IAppState) => selectCurrentEvent(state))
  if (eventFloors?.length) {
    const setFloor = eventFloors.find((floor) => { return Number(floor.id || "0") === eventFloorId })
    if (setFloor) floor = setFloor
  }

  const days = floor.daysList

  useEffect(() => {
    if (event && event.eventDatesList.length > 0) {
      const datesList = formatEventDates(event.eventDatesList)
      if (datesList && datesList[0]) {
        setDefaultDate(datesList[0].start.substr(0, 10) || "")
        setDefaultStart(datesList[0].start.substr(11) || "")
        setDefaultEnd(datesList[0].end.substr(11) || "")
      }
    }
  }, [event, eventFloorId, floor])

  const handleEditFloorDate = useCallback((day) => {
    setActiveDay(day)
    const start = parse(day.startDayTime, "yyyy-MM-dd|HH:mm", new Date())
    const end = parse(day.endDayTime, "yyyy-MM-dd|HH:mm", new Date())
    setSelectedStartTime(start)
    setSelectedEndTime(end)
    setAddDateDialogOpen(true)
  }, [])

  const handleAddPanel = useCallback((day) => {
    setActiveDay(day)
    setOpenPanelDialog(true)
  }, [])

  const handleDelPanel = useCallback((panelId: number, day: themis_common.EventFloorDate.AsObject) => {
    delPanel(dispatch, day, panelId)
  }, [dispatch])

  const addFloorDatePanel = useCallback(() => {
    if (activeDay) {
      const thePanelName = panelName
      addPanel(dispatch, activeDay, thePanelName)
      setPanelName("Panel")
      setOpenPanelDialog(false)
    }
  }, [activeDay, dispatch, panelName])

  // Setting default Material UI picker date objects
  useEffect(() => {
    setSelectedStartTime(addHours(new Date(defaultDate + " " + defaultStart + ":00 GMT-0800 (Pacific Standard Time)"), -1))
    setSelectedEndTime(addHours(new Date(defaultDate + " " + defaultEnd + ":00 GMT-0800 (Pacific Standard Time)"), -1))
  }, [defaultDate, defaultStart, defaultEnd])

  const dateList = useMemo(() => {
    if (floor.daysList && floor.daysList.length > 0) {
      return floor.daysList.map(day => {
        const start = parse(day.startDayTime, "yyyy-MM-dd|HH:mm", new Date())
        const end = parse(day.endDayTime, "yyyy-MM-dd|HH:mm", new Date())
        return (
          <Grid item container xs={12} key={day.id} style={{ marginLeft: 5, paddingLeft: 5, marginTop: 15, borderLeft: '1px solid ' + theme.palette.text.primary}} >
            <Grid item container xs={12} sm={6} md={3} xl={2} alignContent="flex-start">
              <Grid item xs={12}>
                <Typography style={{ color: day.active ? theme.palette.text.primary : theme.palette.text.disabled }}>
                  {format(start, "MMM d")}
                  { userCanEditEvent ? <IconButton onClick={()=>{ handleEditFloorDate(day)}} >
                    <EditIcon style={{ color: day.active ? theme.palette.text.primary : theme.palette.text.disabled }} />
                  </IconButton> : [] }
                  <IconButton onClick={() => { if (userCanEditEvent) changeDayActive(day)}}>{ day.active ? <CheckedIcon /> : <UncheckedIcon style={{ color: theme.palette.text.disabled }} />  }</IconButton>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ color: day.active ? theme.palette.text.primary : theme.palette.text.disabled }}>Floor Opens {format(start, "h:mm aaaa")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ color: day.active ? theme.palette.text.primary : theme.palette.text.disabled }}>Floor Closes {format(end, "h:mm aaaa")}</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={6} md={9} xl={10}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Panels
                  <IconButton onClick={()=>{ handleAddPanel(day)}} >
                    <AddIcon />
                  </IconButton>
                </Typography>
              </Grid>
              <Grid container spacing={1} item xs={12}>
                { day.judgingPanelsList.map(panel =>
                  <Grid key={"Panel--" + panel.id} item container xs={12} sm={12} md={6} lg={3} xl={2} spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h3">{panel.name} <IconButton><DelIcon onClick={() => {
                        handleDelPanel(panel.id, day)
                      }} /></IconButton></Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>)
      })
    }
  },[floor.daysList, theme.palette.text.primary, theme.palette.text.disabled, userCanEditEvent, handleEditFloorDate, changeDayActive, handleAddPanel, handleDelPanel])


  const [addDateDialogOpen, setAddDateDialogOpen] = useState(false)

  const handleCloseAddDateDialog = () => {
    setAddDateDialogOpen(false)
  }

  const editFloorDateDialog = (
    <Dialog
      open={addDateDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseAddDateDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Edit Floor Date</DialogTitle>
      <DialogContent>
        <form className={classes.dateForm} noValidate>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.gridForm}>
              <KeyboardTimePicker
                placeholder="08:00 AM"
                variant="inline"
                margin="normal"
                autoOk={true}
                id={`start_time_${floor?.id}`}
                label="Start Time"
                value={selectedStartTime}
                required={true}
                inputVariant="outlined"
                onChange={handleStartChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<AccessTimeIcon />}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridForm}>
              <KeyboardTimePicker
                placeholder="05:00 PM"
                variant="inline"
                margin="normal"
                autoOk={true}
                id={`end_time_${floor?.id}`}
                label="End Time"
                value={selectedEndTime}
                required={true}
                inputVariant="outlined"
                onChange={handleEndChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<AccessTimeIcon />}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setAddDateDialogOpen(false) }} color="primary">
          Cancel
        </Button>
        <Button onClick={() => { saveEventFloorDate() }} color="primary">
          Edit Floor Date
        </Button>
      </DialogActions>
    </Dialog>
  )

  const addPanelDialog = (
    <Dialog
      open={openPanelDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => { setOpenPanelDialog(false) }}
      aria-labelledby="alert-dialog-slide-add-panel"
      aria-describedby="alert-dialog-slide-description-add-panel"
    >
      <DialogTitle id="alert-dialog-slide-add-panel">Add Floor Date Panel for {floor.name} on {floor.daysList.find(day => day.id === activeDay?.id)?.startDayTime.substr(0, 10) }</DialogTitle>
      <DialogContent>
        <form className={classes.dateForm} noValidate>
          <Grid container>
            <Grid item xs={12} className={classes.gridForm}>
              <TextField
                id="panelName"
                label="Panel Name"
                type="text"
                defaultValue={ panelName }
                focused
                required
                fullWidth
                onChange={e => {
                  setPanelName(e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setOpenPanelDialog(false) }} color="primary">
          Cancel
        </Button>
        <Button onClick={() => { addFloorDatePanel() }} color="primary">
          Add Panel
        </Button>
      </DialogActions>
    </Dialog>
  )

  return !userCanViewEvent ? <></> : (
    <>
      <Typography variant="h4">
        Dates
      </Typography>
      <Grid item container xs={12}>
        {dateList}
        {editFloorDateDialog}
        {addPanelDialog}
      </Grid>
    </>)
}

export default EventFloorDates
