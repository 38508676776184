import React, { ReactElement, ReactNode, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "lib/theme"
import { Link as RouterLink, Link } from "react-router-dom"

import { IconButton, Typography } from '@material-ui/core'
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { IAppState } from "store/store"
import { selectCurrentLocationTeams } from "store/program/programSelectors"
import { deleteTeam } from "store/program/teamActions"
import themis_common from "store/themis_common_pb"

import YesNoDialog from "components/util/YesNoModal"
import { selectPermissionCodes, selectSuperUser, selectUserProgramPermissions } from 'store/user/userSelectors'
import { validateLocationPermission, validateTeamPermission } from 'lib/permissions'

interface locationTeamsProps { programId: number, locationId: number, setTeamTabEnabled: (value: boolean | ((prevVar: boolean) => boolean)) => void }

// Rename to "TeamsGrid"?
const LocationTeams: React.FC<locationTeamsProps> = (props: locationTeamsProps): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const programId = props.programId
  const locationId = props.locationId

  const locationTeams = useSelector((state: IAppState) => selectCurrentLocationTeams(state))
  const programPermissions = useSelector((state: IAppState) => selectUserProgramPermissions(state))
  const superUser = useSelector((state: IAppState) => selectSuperUser(state))
  const permissionCodes = useSelector((state: IAppState) => selectPermissionCodes(state))

  const [teamToDelete, setTeamToDelete] = useState<themis_common.Team.AsObject | null>(null)

  const addTeamIcon = useMemo(() => {
    if (validateLocationPermission(permissionCodes.teamAdd, permissionCodes, superUser, programPermissions, programId, locationId)) {
      return (
        <IconButton className={classes.clickable} aria-label="add" component={RouterLink} to={`/Location/${locationId}/AddTeam`}>
          <AddIcon fontSize="small"/>
        </IconButton>
      )
    } return []
  }, [classes.clickable, locationId, programPermissions, programId, superUser, permissionCodes])

  const teamList = useMemo(() => {
    if (!locationTeams) return
    const handleSetDeleteTeam = async (team: themis_common.Team.AsObject) => {
      setTeamToDelete(team)
      setIsOpen(true)
    }

    const outList: ReactNode[] = []
    locationTeams.forEach((team: themis_common.Team.AsObject) => {
      if (validateTeamPermission(permissionCodes.teamView, permissionCodes, superUser, programPermissions, programId, locationId, team.id)) {
        props.setTeamTabEnabled(true)
        const primarySA = team.scoringAuthoritiesList.find((teamSA) => teamSA.primary)
        outList.push(<div key={team.id}>
          <Link className={classes.listItem} to={`/Location/${locationId}/Team/${team.id}`}>{team.name} ({primarySA?.season?.scoringAuthority?.name} / {primarySA?.division?.divisionDescriptor})</Link>
          { validateTeamPermission(permissionCodes.teamEdit, permissionCodes, superUser, programPermissions, programId, locationId, team.id) ?
            (<IconButton className={classes.clickable} aria-label="edit" component={RouterLink} to={`/Location/${locationId}/EditTeam/${team.id}`}>
              <EditIcon fontSize="small" />
            </IconButton>)
            :
            (<IconButton style={{ visibility: "hidden" }}>
              <EditIcon fontSize="small" />
            </IconButton>)
          }
          {
            validateTeamPermission(permissionCodes.teamDelete, permissionCodes, superUser, programPermissions, programId, locationId, team.id) ?
              (<IconButton className={classes.clickable} aria-label="delete" onClick={_e => { handleSetDeleteTeam(team) }}>
                <DeleteIcon fontSize="small" />
              </IconButton>)
              :
              (<IconButton style={{ visibility: "hidden" }}>
                <DeleteIcon fontSize="small" />
              </IconButton>)
          }
        </div>)
      }
    })

    return outList
  },[locationTeams, locationId, classes, programPermissions, programId, superUser, permissionCodes])

  async function handleDeleteTeam(teamId: number) {
    await deleteTeam(dispatch, teamId, locationId, programId)
  }

  function handleDelete() {
    if (!teamToDelete?.id) return
    handleDeleteTeam(teamToDelete.id)
    setIsOpen(false)
  }

  const [isOpen, setIsOpen] = useState(false)

  return ( permissionCodes?.denyAccess.length ?
    <>
      <YesNoDialog
        title={`Delete Team ${teamToDelete?.name}?`}
        question={`Deleting a team cannot be undone. Are you sure you want to delete ${teamToDelete?.name}?`}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        buttonActions={[
          { name: "No", color: "primary", callback: () => setIsOpen(false) },
          { name: "Yes", color: "primary", callback: handleDelete },
        ]}
      />
      <Typography variant="h2">
        Teams
        {addTeamIcon}
      </Typography>
      {teamList}
    </> : <></> )
}

export default LocationTeams
