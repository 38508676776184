import { emptyProducer } from "./producerReducer"
import { AnyDispatchType, RESET_PRODUCER, SET_PRODUCER, SET_PRODUCER_LINKS } from "lib/constants"
import { createAction } from 'redux-actions'

import { client } from "store/themisClient"
import themis_api from 'store/themis_api_pb'
import themis_common from 'store/themis_common_pb'

import { refreshUser } from "store/user/userActions"
import { doSetBrand } from "./brandActions"
import { doSetEvent } from "./eventActions"
import { buildProducer } from "./producerBuilders"
import { ObjectUserPermissions } from "store/program/programReducer"

const setProducer = createAction<themis_common.Producer.AsObject>(SET_PRODUCER)
const setProducerLinks = createAction<Array<themis_common.ProducerUrl.AsObject>>(SET_PRODUCER_LINKS)
const resetProducer = createAction<void>(RESET_PRODUCER)

export const resetProducerState = async (dispatch: AnyDispatchType): Promise<void> => {
  dispatch(resetProducer())
}

export const doSetProducer = async (dispatch: AnyDispatchType, producerId: number): Promise<void> => {
  let producer: themis_common.Producer.AsObject
  if (producerId > 0) {
    const request = new themis_common.Producer()
    request.setId(producerId)
    let producerMessage: themis_api.ProducerResponse.AsObject = { producersList: [], producer: emptyProducer }
    try {
      producerMessage = (await client.getProducer(request, {})).toObject()
    } catch (e) {
      // console.log(e)
    }
    if (producerMessage.producer?.id && producerMessage.producer.id > 0) {
      producer = producerMessage.producer
    } else {
      producer = emptyProducer
      producer.id = producerId
    }
  } else {
    producer = emptyProducer
  }

  dispatch(setProducer(producer))
}

export const connectProgramToProducerLink = async (programId: number): Promise<void> => {
  const producerLinks = await getAllProducerLinks()
  const connectProducerMatch: themis_common.ProducerUrl.AsObject | undefined = producerLinks.find((producer => producer.connectUrl === localStorage.getItem('producerConnectLink')?.substring(9)))
  const programIds: number[] = []
  programIds.push(programId)

  if (connectProducerMatch?.producer) {
    await connectProducerLink(connectProducerMatch.connectUrl, programId, programIds, connectProducerMatch.producer?.name)
  }
}

export const connectProducerLink = async (link: string, programId: number, programIds: number[], producerName: string): Promise<themis_api.ConnectProducerLinkResponse.AsObject> => {
  const request = new themis_api.ConnectProducerLinkRequest()
  request.setLink(link)
  request.setProducerName(producerName)
  request.setProgramId(programId)
  request.setProgramIdsList(programIds)
  const response: themis_api.ConnectProducerLinkResponse.AsObject = (await client.connectProducerLink(request, {})).toObject()
  return response
}

export const addProducer = async (dispatch: AnyDispatchType, producerDetails: themis_common.Producer.AsObject): Promise<number> => {
  const producerRequest = buildProducer(producerDetails)
  const response: themis_api.ProducerResponse.AsObject = (await client.addProducer(producerRequest, {})).toObject()
  await refreshUser(dispatch)
  return response.producer?.id || 0
}

export const addProducerLogo = async(dispatch: AnyDispatchType, producerId: number, image: string, fileName: string): Promise<themis_api.ProducerResponse.AsObject> => {
  const addLogoRequest = new themis_common.AddLogoRequest()
  addLogoRequest.setId(producerId)
  addLogoRequest.setImage(image)
  addLogoRequest.setFileName(fileName)
  const response: themis_api.ProducerResponse.AsObject = (await client.addProducerLogo(addLogoRequest, {})).toObject()
  await doSetProducer(dispatch, producerId)
  return response
}

export const updateProducer = async (dispatch: AnyDispatchType, producerDetails: themis_common.Producer.AsObject): Promise<void> => {
  const producerRequest = buildProducer(producerDetails)
  await client.updateProducer(producerRequest, {})
  await doSetProducer(dispatch, producerDetails.id)
  await refreshUser(dispatch)
}

export const deleteProducer = async (dispatch: AnyDispatchType, producerId: number): Promise<void> => {
  const producerRequest = new themis_common.Producer()
  producerRequest.setId(producerId)
  await client.deleteProducer(producerRequest, {})
  await refreshUser(dispatch)
}

export const addProducerLink = async (dispatch: AnyDispatchType, producerId: number, link: string): Promise<void> => {
  const request = new themis_api.AddProducerLinkRequest()
  request.setLink(link)
  const producer = new themis_common.Producer()
  producer.setId(producerId)
  request.setProducer(producer)
  const response: themis_common.ProducerUrls.AsObject = (await client.addProducerLink(request, {})).toObject()
  dispatch(setProducerLinks(response.urlsList))
}

export async function getProducerUsers(producerId: number): Promise<ObjectUserPermissions[]> {
  const producer = new themis_common.Producer()
  producer.setId(producerId)
  const response = (await client.getProducerUsers(producer, {})).toObject()
  return response["usersPermissionsList"]
}

export const clearProducer = async (dispatch: AnyDispatchType):Promise<void> => {
  doSetProducer(dispatch, -1)
  doSetBrand(dispatch, -1, -1)
  doSetEvent(dispatch, -1)
}

export async function getAllProducerLinks(): Promise<themis_common.ProducerUrl.AsObject[]> {
  const emptyMessage = new themis_api.EmptyMessage()
  const response: themis_common.ProducerUrls.AsObject = (await client.getAllProducerLinks(emptyMessage, {})).toObject()
  return response.urlsList
}

export const createStripeProducerAccount = async (producerId: number): Promise<themis_api.CreateStripeProducerAccountResponse.AsObject> => {
  const producer = new themis_common.Producer()
  producer.setId(producerId)
  const response: themis_api.CreateStripeProducerAccountResponse.AsObject = (await client.createStripeProducerAccount(producer, {})).toObject()
  return response
}

export const getProducerStripeOnBoardingUrl = async (producerId: number): Promise<themis_api.CreateStripeProducerAccountResponse.AsObject> => {
  const producer = new themis_common.Producer()
  producer.setId(producerId)
  const response: themis_api.CreateStripeProducerAccountResponse.AsObject = (await client.getProducerStripeOnBoardingUrl(producer, {})).toObject()
  return response
}

export const getProducerDocuments = async (producerId: number): Promise<themis_common.EventDocument.AsObject[]> => {
  const producerDocument = new themis_common.EventDocument()
  producerDocument.setProducerId(producerId)
  const response: themis_api.DocumentResponse.AsObject = (await client.getProducerDocuments(producerDocument, {})).toObject()
  return response.documentsList
}

export const saveProducerDocument = async (document: themis_common.EventDocument.AsObject): Promise<themis_common.EventDocument.AsObject | undefined> => {
  const producerDocument = new themis_common.EventDocument()
  producerDocument.setId(document.id)
  producerDocument.setProducerId(document.producerId)
  producerDocument.setName(document.name)
  producerDocument.setText(document.text)
  producerDocument.setStatus(document.status)

  const response: themis_api.DocumentResponse.AsObject = (await client.editProducerDocument(producerDocument, {})).toObject()
  return response?.eventDocument
}

export const addProducerDocument = async (document: themis_common.EventDocument.AsObject): Promise<themis_common.EventDocument.AsObject | undefined> => {
  const producerDocument = new themis_common.EventDocument()
  producerDocument.setProducerId(document.producerId)
  producerDocument.setName(document.name)
  producerDocument.setText(document.text)
  producerDocument.setStatus(document.status)

  const response: themis_api.DocumentResponse.AsObject = (await client.addProducerDocument(producerDocument, {})).toObject()
  return response?.eventDocument
}

export const addDocumentToAllFutureEvents = async (document: themis_common.EventDocument.AsObject): Promise<boolean | undefined> => {
  const producerDocument = new themis_common.EventDocument()
  producerDocument.setProducerId(document.producerId)
  producerDocument.setName(document.name)
  producerDocument.setText(document.text)
  producerDocument.setStatus(document.status)

  const response: themis_api.StatusResponse.AsObject = (await client.addDocumentToAllFutureEvents(producerDocument, {})).toObject()
  return response?.status
}

export const deleteProducerDocument = async (document: themis_common.EventDocument.AsObject): Promise<themis_common.EventDocument.AsObject | undefined> => {
  const producerDocument = new themis_common.EventDocument()
  producerDocument.setId(document.id)
  producerDocument.setProducerId(document.producerId)

  const response: themis_api.DocumentResponse.AsObject = (await client.deleteProducerDocument(producerDocument, {})).toObject()
  return response?.eventDocument
}

export const removeJudge = async (dispatch: AnyDispatchType, producerId: number, judgeId: number): Promise<void> => {
  const judge = new themis_common.Judge()
  judge.setId(judgeId)

  const producer = new themis_common.Producer()
  producer.setId(producerId)

  const request = new themis_api.JudgeProducerRequest()
  request.setJudge(judge)
  request.setProducer(producer)

  const res = await client.removeJudge(request, {})
  const producerResponse = res.toObject()
  const resultProducer = producerResponse.producer
  dispatch(setProducer(resultProducer))
}

export async function getRegistrationActivityLogByProducer(producerId: number): Promise<themis_common.RegistrationActivityLogItem.AsObject[]> {
  const request = new themis_api.RegistrationActivityLogsByProducerRequest()
  request.setProducerId(producerId)
  const response: themis_api.RegistrationActivityLogsResponse.AsObject = (await client.registrationActivityLogByProducer(request, {})).toObject()
  return response.logsList
}
