/* eslint max-lines: "off" */
import React, { FunctionComponent, ReactElement, useMemo } from 'react'
import { Box, Button, Container, Grid, List, ListItem, ListItemIcon, Paper, Typography } from '@material-ui/core'
import themis_common from 'store/themis_common_pb'
import { Remove as RemoveIcon } from "@material-ui/icons"
import HelpIcon from "../../util/HelpIcon"
import { countries } from "../../../lib/constants"
import { makeMoney } from "../../../lib/functions"
import { EventTeamPayments } from "../../../store/program/programReducer"
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

type eventRegistrationDetailProps = {
  event: themis_common.Event.AsObject,
  eventTeam: themis_common.EventTeam.AsObject
  eventTeamBalance: themis_common.EventTeamBalance.AsObject | undefined,
  eventTeamTransactions: EventTeamPayments,
  payBalance: (amountSelectedToPay: number, eventTeamId: number, deposit: boolean) => void,
  calculatedAthletePrices: themis_common.CalculatedAthletePricing.AsObject[]
  dateComparison: boolean
}

const EventRegistrationDetail: FunctionComponent<eventRegistrationDetailProps> = ({
  event,
  eventTeam,
  eventTeamBalance,
  payBalance,
  eventTeamTransactions,
  calculatedAthletePrices,
  dateComparison
}): ReactElement => {
  const countryName = useMemo<string>(() => {
    const country = countries.find(country => country.code === event.country)
    return country?.name || ""
  }, [event.country])

  const outstanding = useMemo(() => {
    return eventTeamTransactions.total - (eventTeamTransactions.deposit + eventTeamTransactions.credit + eventTeamTransactions.payment)
  }, [eventTeamTransactions])

  return (<>
    <Typography style={{ marginRight: "25px" }} variant="h2">
      Event Details
    </Typography>
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid item xs={12} sm={8}>
        </Grid>
        <Container component={Paper} style={{ padding: "16px" }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="h2">Location</Typography>
              </Grid>
              <Grid item xs={9}>
                <Grid container>
                  <Grid item xs={2}>Venue Name:</Grid><Grid item xs={10}>{event.venueName}</Grid>
                  <Grid item xs={2}>Address:</Grid><Grid item xs={10}>{event.address}</Grid>
                </Grid>
                {event.address2.length > 0 ? <Grid container><Grid item xs={2}>&nbsp;</Grid><Grid xs={10}>{event.address2}</Grid></Grid> : <></>}
                <Grid container><Grid item xs={2}>&nbsp;</Grid><Grid item xs={10}>{event.city}, {event.state} {event.postalCode}</Grid></Grid>
                <Grid container><Grid item xs={2}>&nbsp;</Grid><Grid item xs={10}>{countryName}</Grid></Grid>
                <Grid container><Grid item xs={2}>Time Zone:</Grid><Grid item xs={10}>{event.timeZone}</Grid></Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box display="flex">
                  <Typography variant="h2">Dates</Typography>
                  <HelpIcon
                    markdownText={`### Event Dates\nAll event times are in the timezone of the event location`}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <List>
                  {event.eventDatesList?.map(date => {
                    const startFormattedTime = date.startClockTime?.formattedDateTime
                    const startDateTime = `${startFormattedTime?.weekDay} ${startFormattedTime?.monthLong} ${startFormattedTime?.dayNumber} ${startFormattedTime?.year}, ${startFormattedTime?.hour12Number}:${startFormattedTime?.minutesLeadingNumber} ${startFormattedTime?.amPm}`

                    const endFormattedTime = date.endClockTime?.formattedDateTime
                    let endDateTime: string
                    // If same day, else
                    if (startFormattedTime?.dayNumber === endFormattedTime?.dayNumber) {
                      endDateTime = `${endFormattedTime?.hour12Number}:${endFormattedTime?.minutesLeadingNumber} ${endFormattedTime?.amPm}`
                    } else {
                      endDateTime = `${endFormattedTime?.weekDay} ${endFormattedTime?.monthLong} ${endFormattedTime?.dayNumber}, ${endFormattedTime?.hour12Number}:${endFormattedTime?.minutesLeadingNumber} ${endFormattedTime?.amPm}`
                    }

                    return <ListItem key={date.startDay}><ListItemIcon><RemoveIcon /></ListItemIcon>{startDateTime} - {endDateTime}</ListItem>
                  })}
                </List>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Box display="flex">
                  <Typography variant="h2">Registration Breakdown</Typography>
                  <HelpIcon
                    markdownText={`### The calculation for your team's registration pricing`}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <List>
                  {eventTeam.id > 0
                    ? eventTeamTransactions.transactions.map(athlete => <ListItem key={athlete.id}><FiberManualRecordIcon fontSize={"inherit"} color={"action"} />&nbsp;{makeMoney(athlete.amount, event.currency)} - {athlete.paymentsList.length > 0 || athlete.amount === 0 ? athlete.description : athlete.description.replace("Payment for ", "Amount due for ")}</ListItem>)
                    : calculatedAthletePrices.map(athlete => <ListItem key={athlete.athleteId}><FiberManualRecordIcon fontSize={"inherit"} color={"action"} />&nbsp;{makeMoney(athlete.athletePrice, event.currency)} - {athlete.description.replace("Payment for ", "")}</ListItem>)
                  }
                </List>

              </Grid>
            </Grid>
            {dateComparison && eventTeam.id && eventTeamBalance ?
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h2">Registration Payments</Typography>
                </Grid>
                <Grid item container xs={12}>
                  {eventTeamTransactions.total > 0 ?
                    <Grid container spacing={2}>
                      <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant="h3">
                          Total Registration:
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h3" style={{ textAlign: "left" }}>
                          {makeMoney(eventTeamTransactions.total, event.currency)}
                        </Typography>
                      </Grid>
                    </Grid>
                    : <></>}
                  {eventTeamTransactions.deposit > 0 ?
                    <Grid container spacing={2}>
                      <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant="h3">
                          - Deposit:
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h3" style={{ textAlign: "left" }}>
                          {makeMoney(eventTeamTransactions.deposit, event.currency)}
                        </Typography>
                      </Grid>
                    </Grid>
                    : <></>}
                  {eventTeamTransactions.credit > 0 ?
                    <Grid container spacing={2}>
                      <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant="h3">
                          - Applied From Credit:
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h3" style={{ textAlign: "left" }}>
                          {makeMoney(eventTeamTransactions.credit, event.currency)}
                        </Typography>
                      </Grid>
                    </Grid>
                    : <></>}
                  {eventTeamTransactions.payment > 0 ?
                    <Grid container spacing={2}>
                      <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant="h3">
                          - Payment:
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h3" style={{ textAlign: "left" }}>
                          {makeMoney(eventTeamTransactions.payment, event.currency)}
                        </Typography>
                      </Grid>
                    </Grid>
                    : <></>}
                  <Grid container item xs={8} spacing={2}>
                    <hr style={{ width: "100%" }} />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                      <Typography variant="h3">
                        Payment Outstanding:
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h3" style={{ textAlign: "left" }}>
                        {makeMoney(outstanding, event.currency)}
                      </Typography>
                    </Grid>
                  </Grid>
                  {eventTeamTransactions.availableCredit > 0 && outstanding > 0 ?
                    <Grid container spacing={2}>
                      <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Typography variant="h3">
                          Available Credit:
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h3" style={{ textAlign: "left" }}>
                          {makeMoney(eventTeamTransactions.availableCredit, event.currency)}
                        </Typography>
                      </Grid>
                    </Grid>
                    : <></>}
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container xs={6} style={{ justifyContent: "center" }}>
                    {eventTeamBalance.remainingDeposit > 0 ?
                      <Button variant="outlined"
                        onClick={_ => {
                          payBalance(eventTeamBalance.remainingDeposit, eventTeam.id, true)
                        }}
                      >Pay Outstanding
                        Deposit {makeMoney(eventTeamBalance.remainingDeposit, event.currency)}</Button>
                      : <></>
                    }
                  </Grid>
                  <Grid item container xs={6} style={{ justifyContent: "center" }} >
                    {eventTeamBalance.remainingAmount > 0 ?
                      <Button variant="outlined"
                        onClick={_ => {
                          payBalance(eventTeamBalance.remainingAmount, eventTeam.id, false)
                        }}
                      >Pay Outstanding
                        Balance {makeMoney(eventTeamBalance.remainingAmount, event.currency)}</Button>
                      : <></>
                    }
                  </Grid>
                </Grid>
                {eventTeamBalance.remainingAmount === 0 ?
                  <Grid item xs={8} style={{ textAlign: "center" }}>
                    <Typography variant="h3">Paid in Full</Typography>
                  </Grid>
                  : <></>}
              </Grid> : <></>
            }
          </Grid>
        </Container>
      </Grid>
    </Container>
  </>)
}

export default EventRegistrationDetail
