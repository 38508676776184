import React, {
  CSSProperties,
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useStyles, Transition } from "lib/theme"
import { Grid, Typography, List, ListItem, TextField, Switch, CardContent, Card, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Checkbox, FormControlLabel } from '@material-ui/core'
import Autocomplete from "@material-ui/lab/Autocomplete"
import themis_common from "store/themis_common_pb"
import themis_api from "store/themis_api_pb"
import { textGender } from "../../../lib/functions"
import { checkForCrossovers } from "../../../store/program/eventTeamActions"
import { SwapHorizontalCircle as Crossover } from "@material-ui/icons"
import { makeMoney } from "../../../lib/functions"

import { getEventTeamOutstanding } from "store/program/eventTeamActions"
import { getRegistrationTransactionsByEventTeam, addPayment } from "../../../store/program/paymentActions"
import YesNoDialog from 'components/util/YesNoModal'
import { AnyDispatchType } from "lib/constants"
import { teamDivisionError } from 'lib/validators'
interface eventRegistrationsProps {
  eventDivisions: themis_common.EventDivision.AsObject[],
  event: themis_common.Event.AsObject,
  userCanViewEvent: boolean,
  userCanEditEvent: boolean
  currentTeam: themis_common.EventTeam.AsObject | undefined
  setCurrentTeam: React.Dispatch<React.SetStateAction<themis_common.EventTeam.AsObject | undefined>>
  submitChangeDivision: () => void
  setSelectedEventTeamDivision: React.Dispatch<React.SetStateAction<themis_common.EventDivision.AsObject>>
  setSelectedEventTeamRegCode: React.Dispatch<React.SetStateAction<string>>
  setFoundRegistrationDiscountCode: React.Dispatch<React.SetStateAction<themis_common.EventRegistrationCode.AsObject>>
  setChangeDivisionDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  foundRegistrationDiscountCode: themis_common.EventRegistrationCode.AsObject
  selectedEventTeamDivision: themis_common.EventDivision.AsObject
  changeDivisionDialogOpen: boolean
  selectedEventTeamRegCode: string
  changeDivisionError: boolean
  setChangeDivisionError: React.Dispatch<React.SetStateAction<boolean>>
  userCanEditFinances: boolean,
  userCanViewFinances: boolean,
  brandId: number,
  producerId: number,
  dispatch: AnyDispatchType
  setRefreshDivisions: React.Dispatch<React.SetStateAction<number>>
  teamErrors: teamDivisionError[] | undefined
}

const EventRegistrations: React.FC<eventRegistrationsProps> = ({
  eventDivisions, userCanViewEvent, userCanEditEvent, event, userCanEditFinances, userCanViewFinances, brandId, producerId,
  currentTeam, submitChangeDivision, setCurrentTeam, setSelectedEventTeamDivision, setSelectedEventTeamRegCode,
  setFoundRegistrationDiscountCode, setChangeDivisionDialogOpen, foundRegistrationDiscountCode, selectedEventTeamDivision, setRefreshDivisions,
  changeDivisionDialogOpen, selectedEventTeamRegCode, changeDivisionError, setChangeDivisionError, dispatch, teamErrors
}): ReactElement => {

  const classes = useStyles()
  const inputRef: MutableRefObject<any> = useRef()

  const [currentEventDivision, setCurrentEventDivision] = useState<themis_common.EventDivision.AsObject | undefined>()
  const [offsetHeight, setOffsetHeight] = useState(300)
  const [currentDivisionSearch, setCurrentDivisionSearch] = useState<string>("")
  const [filteredEventDivisions, setFilteredEventDivisions] = useState<themis_common.EventDivision.AsObject[]>([])
  const [currentTeamSearch, setCurrentTeamSearch] = useState<string>("")
  const [filteredTeams, setFilteredTeams] = useState<themis_common.EventTeam.AsObject[]>([])
  const [currentCrossovers, setCurrentCrossovers] = useState<themis_common.Athlete.AsObject[]>([])
  const [divisionSwitchChecked, setDivisionSwitchChecked] = useState<boolean>(false)
  const [eventTeamBalance, setEventTeamBalance] = useState<themis_common.EventTeamBalance.AsObject>()
  const registeredDivisions = useMemo(() => { return eventDivisions.filter(eventDivision => eventDivision.eventTeamsList.length > 0)}, [eventDivisions])
  const [isPayFullOpen, setIsPayFullOpen] = useState(false)
  const [acceptPayFull, setAcceptPayFull] = useState(false)
  const [teamTransactions, setTeamTransactions] = useState<themis_common.RegistrationTransaction.AsObject[]>([])

  useEffect(() => {
    setFilteredTeams(currentEventDivision?.eventTeamsList || [])
  }, [currentEventDivision])

  // Refreshes divisions/teams
  useEffect(() => {
    setCurrentEventDivision(undefined)
  }, [eventDivisions])

  const payFull = (async () => {
    const transactions = teamTransactions.map(tx => tx.id)
    const addPaymentRequest: themis_api.AddPaymentRequest.AsObject = {
      ...(new themis_api.AddPaymentRequest().toObject()),
      amount: eventTeamBalance?.remainingAmount ?? 0,
      currency: currentEventDivision?.event?.currency ?? "USD",
      paymentType: 5, // 5 = Producer Pays
      paymentSource: "", // Empty string for newCredit Card
      storedPaymentType: false, // Using existing source
      programId: currentTeam?.programId ?? 0,
      producerId: producerId,
      scope: 0, // 0 = TRANSACTION_ITEMS_SPECIFIC, 19 = DEPOSIT
      registrationTransactionsList: transactions, // Should probably get this from registrationTransactions array
      eventTeamIdsList: [currentTeam?.id ?? 0],
      brandId: brandId,
      eventId: event?.id ?? 0,
      teamId: currentTeam?.teamId ?? 0,
      locationId: currentTeam?.locationId ?? 0
    }
    const addPaymentDataResponse = await addPayment(addPaymentRequest)

    setIsPayFullOpen(false)
    getEventTeamBalance()
    setRefreshDivisions((currentRefreshDivisions) => currentRefreshDivisions + 1)
  })

  const getEventTeamBalance = (() => {
    setTeamTransactions([])
    const eventTeamBalance = new themis_common.EventTeamBalance()
    setEventTeamBalance(eventTeamBalance.toObject())
    const getOutstanding = (async () => {
      const eventTeamBalance = await getEventTeamOutstanding(dispatch, currentTeam?.programId ?? 0, currentTeam?.locationId ?? 0, currentTeam?.teamId ?? 0, currentTeam?.id ?? 0)
      setEventTeamBalance(eventTeamBalance)
      if ((currentTeam?.id ?? 0) !== 0) {
        const eventTransactions = await getRegistrationTransactionsByEventTeam(currentTeam?.id ?? 0)
        setTeamTransactions(eventTransactions)
      }

    })
    if (currentTeam?.id ?? 0 != 0) {
      getOutstanding()
    } else {
      const eventTeamBalance = new themis_common.EventTeamBalance()
      setEventTeamBalance(eventTeamBalance.toObject())
    }
  })

  useEffect(() => {
    getEventTeamBalance()
  }, [currentTeam])

  useEffect(() => {
    setAcceptPayFull(false)
  }, [isPayFullOpen])


  const payInFullModal = useMemo(() => {
    return (
      <>
        <Typography variant="body2">
          {`Would you like to mark ${currentTeam?.programName} - ${currentTeam?.name} (${currentEventDivision?.division?.tier?.name} - ${currentEventDivision?.division?.name}) as paid in full (${makeMoney(eventTeamBalance?.remainingAmount ?? 0, currentEventDivision?.event?.currency ?? "USD")})?
        As the Event Producer you will be charged ${makeMoney(((currentTeam?.athletesList?.length ?? 0) * 100) * 1.015 ?? 0, currentEventDivision?.event?.currency ?? "USD")} in Themis Fees (Registration) which will be deducted from your Stripe account.
        This action cannot be reversed.`}</Typography>
        <>
          <FormControlLabel
            className={classes.fullWidth}
            onClick={() => {
              setAcceptPayFull(!acceptPayFull)
            }}
            control={
              <Checkbox
                checked={acceptPayFull}
                name="addNewProgram"
                color="primary"
              />
            }
            label="I accept responsibility for these charges"
          />
          <Button variant="outlined"
            disabled={!acceptPayFull}
            onClick={ _ => {
              payFull()
            }}
          >Proceed</Button>
        </>
      </>
    )
  }, [currentTeam, isPayFullOpen, eventTeamBalance, acceptPayFull])

  useEffect(() => {
    if (!divisionSwitchChecked) {
      setFilteredEventDivisions(registeredDivisions)
    } else {
      setFilteredEventDivisions(eventDivisions)
    }
  }, [divisionSwitchChecked, eventDivisions, registeredDivisions])

  useEffect(() => {
    setOffsetHeight((inputRef.current?.getBoundingClientRect()).top)
  }, [inputRef.current])

  const matchWindowHeight: CSSProperties = useMemo(() => {
    return { height: `calc(100vh - (${offsetHeight}px + 24px))` }
  }, [offsetHeight])

  const totalTeams = eventDivisions === undefined ? 0 : eventDivisions.reduce((total: number, division) => {
    return total + division.eventTeamsList.length
  }, 0)

  const hasMultipleSeasons = useMemo(() => {
    const firstSeasonId = eventDivisions[0]?.division?.season?.id
    let retVal = false
    eventDivisions.forEach((ed) => {
      if (ed.division?.season?.id !== firstSeasonId && !retVal) retVal = true
    })
    return retVal
  }, [eventDivisions])

  const filterDivisionList = useCallback((value: string) => {
    if ((!value || value.length === 0) && !divisionSwitchChecked) {
      setFilteredEventDivisions(registeredDivisions)
    } else if ((!value || value.length === 0) && !divisionSwitchChecked) {
      setFilteredEventDivisions(eventDivisions)
    } else {
      if (!divisionSwitchChecked) {
        const newRegisteredDivisions = registeredDivisions.filter((ed) => {
          return ed.division?.name.toLowerCase().includes(value.toLowerCase()) || ed.division?.season?.name.toLowerCase().includes(value.toLowerCase()) || ed.division?.tier?.name.toLowerCase().includes(value.toLowerCase())
        })
        setFilteredEventDivisions(newRegisteredDivisions)
      } else {
        const newEventDivisions = eventDivisions.filter((ed) => {
          return ed.division?.name.toLowerCase().includes(value.toLowerCase()) || ed.division?.season?.name.toLowerCase().includes(value.toLowerCase()) || ed.division?.tier?.name.toLowerCase().includes(value.toLowerCase())
        })
        setFilteredEventDivisions(newEventDivisions)
      }
    }
  }, [eventDivisions, divisionSwitchChecked, registeredDivisions])

  const filterTeamList = useCallback((value: string) => {
    if (value.length === 0) {
      setFilteredTeams(currentEventDivision?.eventTeamsList || [])
    } else {
      const newEventTeams = currentEventDivision?.eventTeamsList.filter((team) => {
        return team.name.toLowerCase().includes(value.toLowerCase()) || team.locationName.toLowerCase().includes(value.toLowerCase())
      })
      setFilteredTeams(newEventTeams || [])
    }
  }, [currentEventDivision?.eventTeamsList])

  useEffect(() => {
    filterTeamList(currentTeamSearch)
  }, [currentEventDivision?.eventTeamsList, currentTeamSearch, filterTeamList])

  useEffect(() => {
    filterDivisionList(currentDivisionSearch)
  }, [currentDivisionSearch, eventDivisions, filterDivisionList])

  useEffect(() => {
    if (currentTeam?.athletesList && currentTeam?.locationId && currentTeam?.id && currentTeam?.teamId && currentEventDivision?.event && currentEventDivision.event?.id > 0) {
      const getCrossovers = async () => {
        const crossovers = await checkForCrossovers(currentTeam.athletesList, currentTeam.id, currentEventDivision.event?.id || 0, currentTeam.programId, currentTeam.locationId, currentTeam.teamId)
        setCurrentCrossovers(crossovers)
      }
      getCrossovers()
    }
  }, [currentEventDivision?.event, currentEventDivision?.event?.id, currentTeam, currentTeam?.athletesList])

  useEffect(() => {
    if (currentTeam) {
      if (currentTeam.id > 0 && currentTeam.eventRegistrationCode && currentTeam.eventRegistrationCode?.id > 0) {
        setSelectedEventTeamRegCode(currentTeam.eventRegistrationCode?.code)
        setFoundRegistrationDiscountCode(currentTeam.eventRegistrationCode)
      }
    }
  }, [currentTeam, setFoundRegistrationDiscountCode, setSelectedEventTeamRegCode])

  const handleChangeEventTeamDivision = () => {
    setChangeDivisionDialogOpen(true)
    if (currentTeam?.eventDivision) setSelectedEventTeamDivision(currentTeam.eventDivision)
    if (currentTeam?.eventRegistrationCode) setSelectedEventTeamRegCode(currentTeam.eventRegistrationCode.code)
  }


  const sortedEventDivisions = useMemo(() => {
    return event.eventDivisionList.sort((a, b) => {
      if (!a.division?.name || !b.division?.name) return 0
      if (a.division.name > b.division.name) return 1
      if (a.division.name < b.division.name) return -1
      return 0
    })
  }, [event.eventDivisionList])



  const formatClockTimeForDiscountCode = (endClockTime?: themis_common.ClockTime.AsObject) => {
    const e = endClockTime?.formattedDateTime
    if (e) {
      return `${e.monthLong} ${e.dayNumber} ${e.year}`
    }
    return ""
  }

  const eventRegistrationCodeHelperText = useMemo(() => {
    if (currentTeam) {
      if (foundRegistrationDiscountCode.id <= 0) return ""
      if (foundRegistrationDiscountCode.discountAppliesTo === "DivisionList" && !foundRegistrationDiscountCode.eventDivisionsList.find(eventDivision => {
        return eventDivision.id === selectedEventTeamDivision.id
      } )) return ""
      const discountValue = foundRegistrationDiscountCode.depositType === 'athlete' ? currentTeam.athletesList.length * foundRegistrationDiscountCode.depositAmount : foundRegistrationDiscountCode.depositAmount
      const discountInfo = foundRegistrationDiscountCode.depositAmount > 0 ? `Deposit of ${makeMoney(discountValue, event.currency)} required by` : "Valid until"

      if (foundRegistrationDiscountCode.discountType === 0) {
        // Percent Type
        return `${foundRegistrationDiscountCode.amountString}% discount - ${discountInfo} ${formatClockTimeForDiscountCode(foundRegistrationDiscountCode.expiresOnClockTime)}`
      }
      if (foundRegistrationDiscountCode.discountType === 1) {
        // Dollar Type
        // TODO: ${currencySymbol}
        return `USD $${foundRegistrationDiscountCode.amountString} discount per ${foundRegistrationDiscountCode.discountPerType} - ${discountInfo} ${formatClockTimeForDiscountCode(foundRegistrationDiscountCode.expiresOnClockTime)}`
      }
      return ""
    }
  }, [foundRegistrationDiscountCode, currentTeam, event, selectedEventTeamDivision])

  useEffect(() => {
    if (teamErrors) {
      if (teamErrors.length >= 1) {
        setChangeDivisionError(true)
      } else {
        setChangeDivisionError(false)
      }
    }
  }, [setChangeDivisionError, teamErrors])

  const handleChangeDivisionDialogClose = () => {
    setChangeDivisionDialogOpen(false)
    setChangeDivisionError(false)
  }

  const changeDivisionDialog = (
    <Dialog
      open={changeDivisionDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title">Change Event Team Division</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth={true}
              id="Pick Division"
              size="small"
              options={sortedEventDivisions}
              getOptionSelected={(option, value) => option.id === value.id}
              value={selectedEventTeamDivision}
              getOptionLabel={(eventDivision: themis_common.EventDivision.AsObject) => eventDivision.division?.tier?.name + " - " + eventDivision.division?.name}
              renderInput={(params) => <TextField {...params}
                inputProps={{ ...params.inputProps, autoComplete: 'off', style: { padding: "12px" } }} // disable autocomplete and autofill
                label="Select Division"
                variant="outlined"
                margin="normal"
                fullWidth
              />}
              onChange={(e, v) => {
                v && setSelectedEventTeamDivision(v)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required={false}
              label={"Discount Code"}
              helperText={eventRegistrationCodeHelperText}
              fullWidth
              id={`${"eventRegistrationCode"}-${1}`}
              value={selectedEventTeamRegCode}
              name={"eventRegistrationCode"}
              onChange={(e) => { setSelectedEventTeamRegCode(e.target.value) }}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                // Limit to 10, uppercase, replace space with _
                e.target.value = e.target.value.slice(0, 10).toUpperCase().split(' ').join('_')
              }}

            />
          </Grid>
        </Grid>
      </DialogContent>
      {changeDivisionError ? <Box pl={4}><Typography className={classes.warning}  display="inline" variant="body2">The selected division is not compatible with current team athletes. Click "Change" if you'd still like to go ahead with this change.</Typography></Box> : <></>}
      <DialogActions>
        <Button onClick={() => handleChangeDivisionDialogClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => submitChangeDivision()} color="primary">
          Change
        </Button>
      </DialogActions>
    </Dialog>
  )

  return !userCanViewEvent ? <></> : (
    // TODO: Use event producer CRPayoutAmount from Database
    <>
      <YesNoDialog title={`Mark Team Registration as Paid In Full`} question={payInFullModal}
        isOpen={isPayFullOpen} onClose={() => setIsPayFullOpen(false)} buttonActions={[{ name: "Cancel", color: "primary", callback: () => setIsPayFullOpen(false) }]} />
      <Typography variant="h2">
        Registrations - Total Teams: {totalTeams}
      </Typography>
      <Grid container item xs={12}>
        <span>Show only registered divisions</span>
        <Switch onClick={() => setDivisionSwitchChecked(divisionSwitchChecked => !divisionSwitchChecked)} />
        <span>Show all divisions</span>
      </Grid>
      <Grid container ref={inputRef} style={{ ...matchWindowHeight }}>
        <Grid container item xs={3} style={{ height: "100%" }} className={classes.userPadding}>
          {/*List of divisions*/}
          <Grid container item xs={6}>
            <Typography variant="h2">Divisions</Typography>
          </Grid>
          <Grid container item xs={6}>
            <TextField margin="dense" variant="outlined" label="Search" onChange={(event) => {
              setCurrentDivisionSearch(event.target.value)
              filterDivisionList(event.target.value)
            }} />
          </Grid>
          <Grid container item xs={12} style={{ height: "Calc(100% - 32px)", overflowY: "auto" }}>
            {!filteredEventDivisions || filteredEventDivisions.length === 0 ? <></> :
              <List>
                {filteredEventDivisions.map((eventDivision) => {
                  const teams: number = eventDivision.eventTeamsList.reduce((prev, _) => prev + 1, 0)

                  const tier = eventDivision.division?.tier?.name
                  let title = teams > 0 ? `${tier} - ${eventDivision.division?.name} (${teams} registered) ${ userCanViewFinances ? `Paid: ${makeMoney(eventDivision?.paid ?? 0, currentEventDivision?.event?.currency ?? "USD")}/Remaining: ${makeMoney(eventDivision?.remainingAmount ?? 0, currentEventDivision?.event?.currency ?? "USD")}` : ''}` : `${tier} - ${eventDivision.division?.name}`
                  if (hasMultipleSeasons) {
                    title = `${eventDivision.division?.season?.name} - ${title}`
                  }
                  const selected: CSSProperties = (currentEventDivision?.division?.id === eventDivision.division?.id) ? { fontWeight: "bold" } : {}
                  return <ListItem key={eventDivision?.id} className={classes.listItem100Clickable} style={selected} onClick={(_) => {
                    setCurrentEventDivision(eventDivision)
                    setCurrentTeam(undefined)
                  }
                  }>{title}</ListItem>
                })}
              </List>
            }
          </Grid>
        </Grid>
        <Grid container item xs={3} className={classes.userGridRight} style={{ height: "100%" }}>
          {/*List of teams in selected division*/}
          <Grid container item xs={6}>
            <Typography variant="h2">Teams</Typography>
          </Grid>
          <Grid container item xs={6}>
            <TextField margin="dense" variant="outlined" label="Search" onChange={(event) => {
              setCurrentTeamSearch(event.target.value)
              filterTeamList(event.target.value)
            }} />
          </Grid>
          <Grid container item xs={12} style={{ height: "Calc(100% - 32px)", overflowY: "auto" }}>
            <List>
              { filteredTeams.map((team) => {
                const selected: CSSProperties = (currentTeam?.id === team.id) ? { fontWeight: "bold" } : { }
                return <ListItem key={team.id} className={classes.listItem100Clickable} style={selected} onClick={(_) => {
                  setCurrentTeam(team)
                }
                }>{ `${team.locationName} - ${team.name} ${ userCanViewFinances ? `Paid: ${makeMoney(team?.paid ?? 0, currentEventDivision?.event?.currency ?? "USD")}/Remaining: ${makeMoney(team?.remainingAmount ?? 0, currentEventDivision?.event?.currency ?? "USD")}` : ''}`}</ListItem>
              })}
            </List>
          </Grid>
        </Grid>
        <Grid container item xs={6} className={classes.userGridRight} style={{ height: "100%", overflowY: "auto" }}>
          {/*Details of selected event team*/}
          <Grid item xs={12}>
            <Typography variant="h2">{currentTeam ? `${currentTeam?.locationName} - ${currentTeam?.name}` : "Select Team"}</Typography>
          </Grid>
          {currentTeam ?
            <>
              <Grid container item xs={12}>
                { (eventTeamBalance?.remainingAmount ?? 0 > 0) && userCanEditFinances ?
                  <Grid item xs={8}>
                    <Button variant="outlined"
                      onClick={ _ => {
                        setIsPayFullOpen(true)
                      }}
                    >Mark Remaining Balance {makeMoney(eventTeamBalance?.remainingAmount ?? 0, currentEventDivision?.event?.currency ?? "USD")} as Paid</Button>
                  </Grid>
                  : <></>
                }
                { userCanEditFinances ?
                  <Grid item xs={4}>
                    <Button
                      type="submit"
                      variant="outlined"
                      onClick={handleChangeEventTeamDivision}
                    >Change Division</Button>
                  </Grid>
                  : <></>
                }
              </Grid>
              <Card className={classes.cardSpacing} raised>
                <CardContent>
                  {userCanViewFinances ?
                    <>
                      <Typography variant="caption">Account Balance</Typography>
                      <table>
                        <tr>
                          <td>{makeMoney(eventTeamBalance?.paid ?? 0, currentEventDivision?.event?.currency ?? "USD")}</td>
                          <td>&nbsp;Paid</td>
                        </tr>
                        <tr>
                          <td>{makeMoney(eventTeamBalance?.remainingAmount ?? 0, currentEventDivision?.event?.currency ?? "USD")}</td>
                          <td>&nbsp;Amount Due</td>
                        </tr>
                        <tr>
                          <td>{makeMoney(eventTeamBalance?.remainingDeposit ?? 0, currentEventDivision?.event?.currency ?? "USD")}</td>
                          <td>&nbsp;Deposit Due</td>
                        </tr>
                      </table>
                    </>: <></> }
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="caption">Program Contact</Typography>
                      { currentTeam?.program?.name ? <Typography variant="body2">Name: {currentTeam.program.name}</Typography> : []}
                      { currentTeam?.program?.email ? <Typography variant="body2">Email: {currentTeam.program.email}</Typography> : []}
                      { currentTeam?.program?.phone ? <Typography variant="body2">Phone: {currentTeam.program.phone}</Typography> : []}
                      { currentTeam?.program?.secondaryPhone ? <Typography variant="body2">Second Phone: {currentTeam.program.secondaryPhone}</Typography> : []}
                      { currentTeam?.program?.cellPhone ? <Typography variant="body2">Cell Phone: {currentTeam.program.cellPhone}</Typography> : []}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">Location Contact</Typography>
                      { currentTeam?.location?.name ? <Typography variant="body2">Name: {currentTeam.location.name}</Typography> : []}
                      { currentTeam?.location?.email ? <Typography variant="body2">Email: {currentTeam.location.email}</Typography> : []}
                      { currentTeam?.location?.phone ? <Typography variant="body2">Phone: {currentTeam.location.phone}</Typography> : []}
                      { currentTeam?.location?.secondaryPhone ? <Typography variant="body2">Second Phone: {currentTeam.location.secondaryPhone}</Typography> : []}
                      { currentTeam?.location?.cellPhone ? <Typography variant="body2">Cell Phone: {currentTeam.location.cellPhone}</Typography> : []}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </> : <></>
          }

          <Grid container item xs={12} style={{ height: "Calc(100% - 32px)", overflowY: "auto" }}>
            <Grid container key={0}>
              <Grid item xs={4}>Name</Grid>
              <Grid item xs={4}>Gender</Grid>
              <Grid item xs={4}>Birth Date</Grid>
            </Grid>
            { currentTeam?.athletesList.map((athlete) => {
              const isCrossover = currentCrossovers.reduce<boolean>((current, co) => {
                return current || co.id === athlete.id
              }, false)
              return <Grid container key={athlete.id}>
                <Grid item xs={4}>{athlete.name} { isCrossover ? <Crossover fontSize="small"/>: [] }</Grid>
                <Grid item xs={4}>{textGender(athlete.person?.gender === undefined ?  3 : athlete.person?.gender)}</Grid>
                <Grid item xs={4}>{athlete.person?.birthDate}</Grid>
              </Grid>
            })}
          </Grid>
        </Grid>
      </Grid>
      {changeDivisionDialog}
    </>)
}

export default EventRegistrations
