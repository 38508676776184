/* eslint max-lines: "off" */
import { MenuItem } from "@material-ui/core"
import React, { Dispatch, ReactElement } from "react"

export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'

// athleteActions
export const SET_ATHLETES = 'SET_ATHLETES'

// guardianActions
export const SET_GUARDIANS = 'SET_GUARDIANS'

// coachActions
export const SET_COACHES = 'SET_COACHES'

// personnelActions
export const SET_PERSONNEL = 'SET_PERSONNEL'

// judgeActions
export const SET_JUDGES = 'SET_JUDGES'

// brandActions
export const SET_BRANDS = 'SET_BRANDS'
export const SET_BRAND = 'SET_BRAND'

// producerActions
export const RESET_PRODUCER = 'RESET_PRODUCER'
export const SET_PRODUCER = 'SET_PRODUCER'
export const SET_PRODUCER_LINKS = 'SET_PRODUCER_LINKS'

// eventActions
export const SET_EVENTS = 'SET_EVENTS'
export const SET_EVENT = 'SET_EVENT'
export const SET_EVENT_DATES = 'SET_EVENT_DATE'
export const SET_EVENT_REG_CODES = 'SET_EVENT_REG_CODES'
export const SET_EVENT_FLOORS = 'SET_EVENT_FLOORS'
export const SET_EVENT_FLOOR_PANELS = 'SET_EVENT_FLOOR_PANELS'
export const SET_EVENT_PRICING = 'SET_EVENT_PRICING'
export const SET_EVENT_DIVISION_PRICING = 'SET_EVENT_DIVISION_PRICING'

// programActions
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_TEAMS = 'SET_TEAMS'
export const SET_TEAM = 'SET_TEAM'
export const SET_TEAM_SA = 'SET_TEAM_SA'

//userActions
export const SET_TRY_LOGIN = 'SET_TRY_LOGIN'
export const SET_LOGGED_IN = 'SET_LOGGED_IN'
export const SET_LOGGED_OUT = 'SET_LOGGED_OUT'
export const RESET_PROGRAM = 'RESET_PROGRAM'
export const SET_PROGRAM = 'SET_PROGRAM'
export const SET_REGISTER_PROGRAM = 'SET_REGISTER_PROGRAM'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const SET_PERMISSION_CODES = 'SET_PERMISSION_CODES'

// Registration Payment Actions
export const SET_SELECTED_REGISTRATIONS_FOR_PAYMENT = 'SET_SELECTED_REGISTRATIONS_FOR_PAYMENT'

//AddLogo Types (Producer, Program, Brand)
export const ADD_LOGO_PRODUCER = 'ADD_LOGO_PRODUCER'
export const ADD_LOGO_PROGRAM = 'ADD_LOGO_PROGRAM'
export const ADD_LOGO_BRAND = 'ADD_LOGO_BRAND'
export const ADD_LOGO_EVENT = 'ADD_LOGO_EVENT'

// Event logo for event registration
export const SET_EVENT_REGISTRATION_LOGO = 'SET_EVENT_REGISTRATION_LOGO'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyDispatchType = Dispatch<any>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyChangeEventType = React.ChangeEvent<any>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ClassesType = Record<string, any>

// @ts-ignore
export type OnClickType = MouseEvent<HTMLLIElement> | MouseEvent<HTMLAnchorElement>

// "?<!" is not supported in Safari
// export const regexpNumber = /(^(?<!.)\d+((\.\d{0,2})|)$)|(^$)/
export const regexpNumber = /(^\d+((\.\d{0,2})|)$)|(^$)/ // TODO: Currently this only allows 2 decimal places for USD
export const regexpOneSymbolOrNumber = /[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]|.*[0-9].*/
export const regExpOneLetter = /[[a-zA-Z]/

// Checks for yyyy-mm-dd format
export const regExpYearMonthDayFormat = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/

export interface Gender {
  name: string;
  value: number;
}

export const emptyRoute = {
  isDivider: false,
  isLogo: false,
  isSub: false,
  key: "",
  label: "",
  route: "",
  value: ""
}

export const passwordStrengthOptions: any = [
  { id: 0, value: "Too weak", minDiversity: 0, minLength: 0 },
  { id: 1, value: "Weak", minDiversity: 2, minLength: 6 },
  { id: 2, value: "Medium", minDiversity: 3, minLength: 8 },
  { id: 3, value: "Strong", minDiversity: 4, minLength: 10 }
]

export const bugIssueTypes: { name: string }[] = [
  { name: "Something Not Working" },
  { name: "Billing Issue" },
  { name: "Missing Feature" },
  { name: "Other" },
]

export const genderOptions = [{ name: 'Male', value: 0, allowForAthlete: true }, { name: 'Female', value: 1, allowForAthlete: true }, { name: 'Other', value: 2, allowForAthlete: false }, { name: 'Unknown', value: 3, allowForAthlete: false }]

export interface Countries {
  name: string;
  code: string;
  pseudoCode?: string;
}

export interface FormFields {
  [key: string]: {label: string, required: boolean, phone: boolean, email: boolean}
}

export const phoneFields = {
  cellPhone: true,
  homePhone: true,
  phone: true
}

export const emailFields = {
  email: true
}

export interface AsFields {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export const coachPersonFields: FormFields = {
  legalFirstName: { label: "First Name", required: true, phone: false, email: false },
  legalMiddleName: { label: "Middle Name", required: false, phone: false, email: false },
  legalLastName: { label: "Last Name", required: true, phone: false, email: false },
  suffix: { label: "Suffix (Jr, Sr, etc.)", required: false, phone: false, email: false },
  nickName: { label: "Nick Name", required: false, phone: false, email: false },
  title: { label: "Title", required: false, phone: false, email: false },
  cellPhone: { label: "Cell Phone", required: false, phone: true, email: false },
  homePhone: { label: "Home Phone", required: false, phone: true, email: false },
  email: { label: "Email", required: true, phone: false, email: true },
  birthDate: { label: "Birth Date", required: true, phone: false, email: false },
  gender: { label: "Gender", required: false, phone: false, email: false },
  address1: { label: "Address", required: false, phone: false, email: false },
  address2: { label: "Address 2", required: false, phone: false, email: false },
  city: { label: "City", required: false, phone: false, email: false },
  state: { label: "State or Provence", required: false, phone: false, email: false },
  postalCode: { label: "Postal Code", required: false, phone: false, email: false },
  country: { label: "Country", required: false, phone: false, email: false },
}

export const personnelPersonFields: FormFields = {
  legalFirstName: { label: "First Name", required: true, phone: false, email: false },
  legalMiddleName: { label: "Middle Name", required: false, phone: false, email: false },
  legalLastName: { label: "Last Name", required: true, phone: false, email: false },
  suffix: { label: "Suffix (Jr, Sr, etc.)", required: false, phone: false, email: false },
  nickName: { label: "Nick Name", required: false, phone: false, email: false },
  title: { label: "Title", required: false, phone: false, email: false },
  cellPhone: { label: "Cell Phone", required: false, phone: true, email: false },
  homePhone: { label: "Home Phone", required: false, phone: true, email: false },
  email: { label: "Email", required: true, phone: false, email: true },
  birthDate: { label: "Birth Date", required: true, phone: false, email: false },
  gender: { label: "Gender", required: false, phone: false, email: false },
  address1: { label: "Address", required: false, phone: false, email: false },
  address2: { label: "Address 2", required: false, phone: false, email: false },
  city: { label: "City", required: false, phone: false, email: false },
  state: { label: "State or Provence", required: false, phone: false, email: false },
  postalCode: { label: "Postal Code", required: false, phone: false, email: false },
  country: { label: "Country", required: false, phone: false, email: false },
}

export const judgePersonFields: FormFields = {
  legalFirstName: { label: "First Name", required: true, phone: false, email: false },
  legalMiddleName: { label: "Middle Name", required: false, phone: false, email: false },
  legalLastName: { label: "Last Name", required: true, phone: false, email: false },
  suffix: { label: "Suffix (Jr, Sr, etc.)", required: false, phone: false, email: false },
  nickName: { label: "Nick Name", required: false, phone: false, email: false },
  title: { label: "Title", required: false, phone: false, email: false },
  cellPhone: { label: "Cell Phone", required: true, phone: true, email: false },
  homePhone: { label: "Home Phone", required: false, phone: true, email: false },
  email: { label: "Email", required: true, phone: false, email: true },
  birthDate: { label: "Birth Date", required: false, phone: false, email: false },
  gender: { label: "Gender", required: false, phone: false, email: false },
  address1: { label: "Address", required: false, phone: false, email: false },
  address2: { label: "Address 2", required: false, phone: false, email: false },
  city: { label: "City", required: true, phone: false, email: false },
  state: { label: "State or Provence", required: true, phone: false, email: false },
  postalCode: { label: "Postal Code", required: true, phone: false, email: false },
  country: { label: "Country", required: true, phone: false, email: false },
}

export const countries: Countries[] = [
  { name: 'United States', code: 'US' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'Australia', code: 'AU' },
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Cote D\'Ivoire', code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'England', code: 'E1', pseudoCode: 'GB' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'Id' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People\'S Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Ireland', code: 'I1', pseudoCode: 'GB' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Scotland', code: 'S1', pseudoCode: 'GB' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wales', code: 'W1', pseudoCode: 'GB' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
]

export const countryItems: ReactElement[] = []
for (const country of countries) {
  countryItems.push(<MenuItem key={ "Country-" + country.name} value={country.code}>{country.name}</MenuItem>)
}

export interface UsStates {
  name: string;
  abbreviation: string;
}

export const usStates: UsStates[] = [
  {
    name: "Alabama",
    abbreviation: "AL"
  },
  {
    name: "Alaska",
    abbreviation: "AK"
  },
  {
    name: "Arizona",
    abbreviation: "AZ"
  },
  {
    name: "Arkansas",
    abbreviation: "AR"
  },
  {
    name: "California",
    abbreviation: "CA"
  },
  {
    name: "Colorado",
    abbreviation: "CO"
  },
  {
    name: "Connecticut",
    abbreviation: "CT"
  },
  {
    name: "Delaware",
    abbreviation: "DE"
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC"
  },
  {
    name: "Florida",
    abbreviation: "FL"
  },
  {
    name: "Georgia",
    abbreviation: "GA"
  },
  {
    name: "Hawaii",
    abbreviation: "HI"
  },
  {
    name: "Idaho",
    abbreviation: "ID"
  },
  {
    name: "Illinois",
    abbreviation: "IL"
  },
  {
    name: "Indiana",
    abbreviation: "IN"
  },
  {
    name: "Iowa",
    abbreviation: "IA"
  },
  {
    name: "Kansas",
    abbreviation: "KS"
  },
  {
    name: "Kentucky",
    abbreviation: "KY"
  },
  {
    name: "Louisiana",
    abbreviation: "LA"
  },
  {
    name: "Maine",
    abbreviation: "ME"
  },
  {
    name: "Maryland",
    abbreviation: "MD"
  },
  {
    name: "Massachusetts",
    abbreviation: "MA"
  },
  {
    name: "Michigan",
    abbreviation: "MI"
  },
  {
    name: "Minnesota",
    abbreviation: "MN"
  },
  {
    name: "Mississippi",
    abbreviation: "MS"
  },
  {
    name: "Missouri",
    abbreviation: "MO"
  },
  {
    name: "Montana",
    abbreviation: "MT"
  },
  {
    name: "Nebraska",
    abbreviation: "NE"
  },
  {
    name: "Nevada",
    abbreviation: "NV"
  },
  {
    name: "New Hampshire",
    abbreviation: "NH"
  },
  {
    name: "New Jersey",
    abbreviation: "NJ"
  },
  {
    name: "New Mexico",
    abbreviation: "NM"
  },
  {
    name: "New York",
    abbreviation: "NY"
  },
  {
    name: "North Carolina",
    abbreviation: "NC"
  },
  {
    name: "North Dakota",
    abbreviation: "ND"
  },
  {
    name: "Ohio",
    abbreviation: "OH"
  },
  {
    name: "Oklahoma",
    abbreviation: "OK"
  },
  {
    name: "Oregon",
    abbreviation: "OR"
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA"
  },
  {
    name: "Rhode Island",
    abbreviation: "RI"
  },
  {
    name: "South Carolina",
    abbreviation: "SC"
  },
  {
    name: "South Dakota",
    abbreviation: "SD"
  },
  {
    name: "Tennessee",
    abbreviation: "TN"
  },
  {
    name: "Texas",
    abbreviation: "TX"
  },
  {
    name: "Utah",
    abbreviation: "UT"
  },
  {
    name: "Vermont",
    abbreviation: "VT"
  },
  {
    name: "Virginia",
    abbreviation: "VA"
  },
  {
    name: "Washington",
    abbreviation: "WA"
  },
  {
    name: "West Virginia",
    abbreviation: "WV"
  },
  {
    name: "Wisconsin",
    abbreviation: "WI"
  },
  {
    name: "Wyoming",
    abbreviation: "WY"
  }
]

export const stateItems: ReactElement[] = []
for (const state of usStates) {
  stateItems.push(<MenuItem key={ "USStates-" + state.abbreviation} value={state.abbreviation}>{state.name}</MenuItem>)
}

export const placeCurrency = (country: string): string => {
  if (!countryTranslation[country]?.currency) {
    return "USD"
  } else {
    return countryTranslation[country].currency
  }
}

export const countryTranslation: {[k: string]:{name: string, native: string, phone: string, continent: string, capital: string, currency: string, languages: string[]}} = {
  "AD": {
    "name": "Andorra",
    "native": "Andorra",
    "phone": "376",
    "continent": "EU",
    "capital": "Andorra la Vella",
    "currency": "EUR",
    "languages": [
      "ca"
    ]
  },
  "AE": {
    "name": "United Arab Emirates",
    "native": "دولة الإمارات العربية المتحدة",
    "phone": "971",
    "continent": "AS",
    "capital": "Abu Dhabi",
    "currency": "AED",
    "languages": [
      "ar"
    ]
  },
  "AF": {
    "name": "Afghanistan",
    "native": "افغانستان",
    "phone": "93",
    "continent": "AS",
    "capital": "Kabul",
    "currency": "AFN",
    "languages": [
      "ps",
      "uz",
      "tk"
    ]
  },
  "AG": {
    "name": "Antigua and Barbuda",
    "native": "Antigua and Barbuda",
    "phone": "1268",
    "continent": "NA",
    "capital": "Saint John's",
    "currency": "XCD",
    "languages": [
      "en"
    ]
  },
  "AI": {
    "name": "Anguilla",
    "native": "Anguilla",
    "phone": "1264",
    "continent": "NA",
    "capital": "The Valley",
    "currency": "XCD",
    "languages": [
      "en"
    ]
  },
  "AL": {
    "name": "Albania",
    "native": "Shqipëria",
    "phone": "355",
    "continent": "EU",
    "capital": "Tirana",
    "currency": "ALL",
    "languages": [
      "sq"
    ]
  },
  "AM": {
    "name": "Armenia",
    "native": "Հայաստան",
    "phone": "374",
    "continent": "AS",
    "capital": "Yerevan",
    "currency": "AMD",
    "languages": [
      "hy",
      "ru"
    ]
  },
  "AO": {
    "name": "Angola",
    "native": "Angola",
    "phone": "244",
    "continent": "AF",
    "capital": "Luanda",
    "currency": "AOA",
    "languages": [
      "pt"
    ]
  },
  "AQ": {
    "name": "Antarctica",
    "native": "Antarctica",
    "phone": "672",
    "continent": "AN",
    "capital": "",
    "currency": "",
    "languages": []
  },
  "AR": {
    "name": "Argentina",
    "native": "Argentina",
    "phone": "54",
    "continent": "SA",
    "capital": "Buenos Aires",
    "currency": "ARS",
    "languages": [
      "es",
      "gn"
    ]
  },
  "AS": {
    "name": "American Samoa",
    "native": "American Samoa",
    "phone": "1684",
    "continent": "OC",
    "capital": "Pago Pago",
    "currency": "USD",
    "languages": [
      "en",
      "sm"
    ]
  },
  "AT": {
    "name": "Austria",
    "native": "Österreich",
    "phone": "43",
    "continent": "EU",
    "capital": "Vienna",
    "currency": "EUR",
    "languages": [
      "de"
    ]
  },
  "AU": {
    "name": "Australia",
    "native": "Australia",
    "phone": "61",
    "continent": "OC",
    "capital": "Canberra",
    "currency": "AUD",
    "languages": [
      "en"
    ]
  },
  "AW": {
    "name": "Aruba",
    "native": "Aruba",
    "phone": "297",
    "continent": "NA",
    "capital": "Oranjestad",
    "currency": "AWG",
    "languages": [
      "nl",
      "pa"
    ]
  },
  "AX": {
    "name": "Åland",
    "native": "Åland",
    "phone": "358",
    "continent": "EU",
    "capital": "Mariehamn",
    "currency": "EUR",
    "languages": [
      "sv"
    ]
  },
  "AZ": {
    "name": "Azerbaijan",
    "native": "Azərbaycan",
    "phone": "994",
    "continent": "AS",
    "capital": "Baku",
    "currency": "AZN",
    "languages": [
      "az"
    ]
  },
  "BA": {
    "name": "Bosnia and Herzegovina",
    "native": "Bosna i Hercegovina",
    "phone": "387",
    "continent": "EU",
    "capital": "Sarajevo",
    "currency": "BAM",
    "languages": [
      "bs",
      "hr",
      "sr"
    ]
  },
  "BB": {
    "name": "Barbados",
    "native": "Barbados",
    "phone": "1246",
    "continent": "NA",
    "capital": "Bridgetown",
    "currency": "BBD",
    "languages": [
      "en"
    ]
  },
  "BD": {
    "name": "Bangladesh",
    "native": "Bangladesh",
    "phone": "880",
    "continent": "AS",
    "capital": "Dhaka",
    "currency": "BDT",
    "languages": [
      "bn"
    ]
  },
  "BE": {
    "name": "Belgium",
    "native": "België",
    "phone": "32",
    "continent": "EU",
    "capital": "Brussels",
    "currency": "EUR",
    "languages": [
      "nl",
      "fr",
      "de"
    ]
  },
  "BF": {
    "name": "Burkina Faso",
    "native": "Burkina Faso",
    "phone": "226",
    "continent": "AF",
    "capital": "Ouagadougou",
    "currency": "XOF",
    "languages": [
      "fr",
      "ff"
    ]
  },
  "BG": {
    "name": "Bulgaria",
    "native": "България",
    "phone": "359",
    "continent": "EU",
    "capital": "Sofia",
    "currency": "BGN",
    "languages": [
      "bg"
    ]
  },
  "BH": {
    "name": "Bahrain",
    "native": "‏البحرين",
    "phone": "973",
    "continent": "AS",
    "capital": "Manama",
    "currency": "BHD",
    "languages": [
      "ar"
    ]
  },
  "BI": {
    "name": "Burundi",
    "native": "Burundi",
    "phone": "257",
    "continent": "AF",
    "capital": "Bujumbura",
    "currency": "BIF",
    "languages": [
      "fr",
      "rn"
    ]
  },
  "BJ": {
    "name": "Benin",
    "native": "Bénin",
    "phone": "229",
    "continent": "AF",
    "capital": "Porto-Novo",
    "currency": "XOF",
    "languages": [
      "fr"
    ]
  },
  "BL": {
    "name": "Saint Barthélemy",
    "native": "Saint-Barthélemy",
    "phone": "590",
    "continent": "NA",
    "capital": "Gustavia",
    "currency": "EUR",
    "languages": [
      "fr"
    ]
  },
  "BM": {
    "name": "Bermuda",
    "native": "Bermuda",
    "phone": "1441",
    "continent": "NA",
    "capital": "Hamilton",
    "currency": "BMD",
    "languages": [
      "en"
    ]
  },
  "BN": {
    "name": "Brunei",
    "native": "Negara Brunei Darussalam",
    "phone": "673",
    "continent": "AS",
    "capital": "Bandar Seri Begawan",
    "currency": "BND",
    "languages": [
      "ms"
    ]
  },
  "BO": {
    "name": "Bolivia",
    "native": "Bolivia",
    "phone": "591",
    "continent": "SA",
    "capital": "Sucre",
    "currency": "BOB",
    "languages": [
      "es",
      "ay",
      "qu"
    ]
  },
  "BQ": {
    "name": "Bonaire",
    "native": "Bonaire",
    "phone": "5997",
    "continent": "NA",
    "capital": "Kralendijk",
    "currency": "USD",
    "languages": [
      "nl"
    ]
  },
  "BR": {
    "name": "Brazil",
    "native": "Brasil",
    "phone": "55",
    "continent": "SA",
    "capital": "Brasília",
    "currency": "BRL",
    "languages": [
      "pt"
    ]
  },
  "BS": {
    "name": "Bahamas",
    "native": "Bahamas",
    "phone": "1242",
    "continent": "NA",
    "capital": "Nassau",
    "currency": "BSD",
    "languages": [
      "en"
    ]
  },
  "BT": {
    "name": "Bhutan",
    "native": "ʼbrug-yul",
    "phone": "975",
    "continent": "AS",
    "capital": "Thimphu",
    "currency": "BTN",
    "languages": [
      "dz"
    ]
  },
  "BV": {
    "name": "Bouvet Island",
    "native": "Bouvetøya",
    "phone": "47",
    "continent": "AN",
    "capital": "",
    "currency": "NOK",
    "languages": [
      "no",
      "nb",
      "nn"
    ]
  },
  "BW": {
    "name": "Botswana",
    "native": "Botswana",
    "phone": "267",
    "continent": "AF",
    "capital": "Gaborone",
    "currency": "BWP",
    "languages": [
      "en",
      "tn"
    ]
  },
  "BY": {
    "name": "Belarus",
    "native": "Белару́сь",
    "phone": "375",
    "continent": "EU",
    "capital": "Minsk",
    "currency": "BYN",
    "languages": [
      "be",
      "ru"
    ]
  },
  "BZ": {
    "name": "Belize",
    "native": "Belize",
    "phone": "501",
    "continent": "NA",
    "capital": "Belmopan",
    "currency": "BZD",
    "languages": [
      "en",
      "es"
    ]
  },
  "CA": {
    "name": "Canada",
    "native": "Canada",
    "phone": "1",
    "continent": "NA",
    "capital": "Ottawa",
    "currency": "CAD",
    "languages": [
      "en",
      "fr"
    ]
  },
  "CC": {
    "name": "Cocos [Keeling] Islands",
    "native": "Cocos (Keeling) Islands",
    "phone": "61",
    "continent": "AS",
    "capital": "West Island",
    "currency": "AUD",
    "languages": [
      "en"
    ]
  },
  "CD": {
    "name": "Democratic Republic of the Congo",
    "native": "République démocratique du Congo",
    "phone": "243",
    "continent": "AF",
    "capital": "Kinshasa",
    "currency": "CDF",
    "languages": [
      "fr",
      "ln",
      "kg",
      "sw",
      "lu"
    ]
  },
  "CF": {
    "name": "Central African Republic",
    "native": "Ködörösêse tî Bêafrîka",
    "phone": "236",
    "continent": "AF",
    "capital": "Bangui",
    "currency": "XAF",
    "languages": [
      "fr",
      "sg"
    ]
  },
  "CG": {
    "name": "Republic of the Congo",
    "native": "République du Congo",
    "phone": "242",
    "continent": "AF",
    "capital": "Brazzaville",
    "currency": "XAF",
    "languages": [
      "fr",
      "ln"
    ]
  },
  "CH": {
    "name": "Switzerland",
    "native": "Schweiz",
    "phone": "41",
    "continent": "EU",
    "capital": "Bern",
    "currency": "CHF",
    "languages": [
      "de",
      "fr",
      "it"
    ]
  },
  "CI": {
    "name": "Ivory Coast",
    "native": "Côte d'Ivoire",
    "phone": "225",
    "continent": "AF",
    "capital": "Yamoussoukro",
    "currency": "XOF",
    "languages": [
      "fr"
    ]
  },
  "CK": {
    "name": "Cook Islands",
    "native": "Cook Islands",
    "phone": "682",
    "continent": "OC",
    "capital": "Avarua",
    "currency": "NZD",
    "languages": [
      "en"
    ]
  },
  "CL": {
    "name": "Chile",
    "native": "Chile",
    "phone": "56",
    "continent": "SA",
    "capital": "Santiago",
    "currency": "CLP",
    "languages": [
      "es"
    ]
  },
  "CM": {
    "name": "Cameroon",
    "native": "Cameroon",
    "phone": "237",
    "continent": "AF",
    "capital": "Yaoundé",
    "currency": "XAF",
    "languages": [
      "en",
      "fr"
    ]
  },
  "CN": {
    "name": "China",
    "native": "中国",
    "phone": "86",
    "continent": "AS",
    "capital": "Beijing",
    "currency": "CNY",
    "languages": [
      "zh"
    ]
  },
  "CO": {
    "name": "Colombia",
    "native": "Colombia",
    "phone": "57",
    "continent": "SA",
    "capital": "Bogotá",
    "currency": "COP",
    "languages": [
      "es"
    ]
  },
  "CR": {
    "name": "Costa Rica",
    "native": "Costa Rica",
    "phone": "506",
    "continent": "NA",
    "capital": "San José",
    "currency": "CRC",
    "languages": [
      "es"
    ]
  },
  "CU": {
    "name": "Cuba",
    "native": "Cuba",
    "phone": "53",
    "continent": "NA",
    "capital": "Havana",
    "currency": "CUP",
    "languages": [
      "es"
    ]
  },
  "CV": {
    "name": "Cape Verde",
    "native": "Cabo Verde",
    "phone": "238",
    "continent": "AF",
    "capital": "Praia",
    "currency": "CVE",
    "languages": [
      "pt"
    ]
  },
  "CW": {
    "name": "Curacao",
    "native": "Curaçao",
    "phone": "5999",
    "continent": "NA",
    "capital": "Willemstad",
    "currency": "ANG",
    "languages": [
      "nl",
      "pa",
      "en"
    ]
  },
  "CX": {
    "name": "Christmas Island",
    "native": "Christmas Island",
    "phone": "61",
    "continent": "AS",
    "capital": "Flying Fish Cove",
    "currency": "AUD",
    "languages": [
      "en"
    ]
  },
  "CY": {
    "name": "Cyprus",
    "native": "Κύπρος",
    "phone": "357",
    "continent": "EU",
    "capital": "Nicosia",
    "currency": "EUR",
    "languages": [
      "el",
      "tr",
      "hy"
    ]
  },
  "CZ": {
    "name": "Czech Republic",
    "native": "Česká republika",
    "phone": "420",
    "continent": "EU",
    "capital": "Prague",
    "currency": "CZK",
    "languages": [
      "cs",
      "sk"
    ]
  },
  "DE": {
    "name": "Germany",
    "native": "Deutschland",
    "phone": "49",
    "continent": "EU",
    "capital": "Berlin",
    "currency": "EUR",
    "languages": [
      "de"
    ]
  },
  "DJ": {
    "name": "Djibouti",
    "native": "Djibouti",
    "phone": "253",
    "continent": "AF",
    "capital": "Djibouti",
    "currency": "DJF",
    "languages": [
      "fr",
      "ar"
    ]
  },
  "DK": {
    "name": "Denmark",
    "native": "Danmark",
    "phone": "45",
    "continent": "EU",
    "capital": "Copenhagen",
    "currency": "DKK",
    "languages": [
      "da"
    ]
  },
  "DM": {
    "name": "Dominica",
    "native": "Dominica",
    "phone": "1767",
    "continent": "NA",
    "capital": "Roseau",
    "currency": "XCD",
    "languages": [
      "en"
    ]
  },
  "DO": {
    "name": "Dominican Republic",
    "native": "República Dominicana",
    "phone": "1809,1829,1849",
    "continent": "NA",
    "capital": "Santo Domingo",
    "currency": "DOP",
    "languages": [
      "es"
    ]
  },
  "DZ": {
    "name": "Algeria",
    "native": "الجزائر",
    "phone": "213",
    "continent": "AF",
    "capital": "Algiers",
    "currency": "DZD",
    "languages": [
      "ar"
    ]
  },
  "EC": {
    "name": "Ecuador",
    "native": "Ecuador",
    "phone": "593",
    "continent": "SA",
    "capital": "Quito",
    "currency": "USD",
    "languages": [
      "es"
    ]
  },
  "EE": {
    "name": "Estonia",
    "native": "Eesti",
    "phone": "372",
    "continent": "EU",
    "capital": "Tallinn",
    "currency": "EUR",
    "languages": [
      "et"
    ]
  },
  "EG": {
    "name": "Egypt",
    "native": "مصر‎",
    "phone": "20",
    "continent": "AF",
    "capital": "Cairo",
    "currency": "EGP",
    "languages": [
      "ar"
    ]
  },
  "EH": {
    "name": "Western Sahara",
    "native": "الصحراء الغربية",
    "phone": "212",
    "continent": "AF",
    "capital": "El Aaiún",
    "currency": "MAD",
    "languages": [
      "es"
    ]
  },
  "ER": {
    "name": "Eritrea",
    "native": "ኤርትራ",
    "phone": "291",
    "continent": "AF",
    "capital": "Asmara",
    "currency": "ERN",
    "languages": [
      "ti",
      "ar",
      "en"
    ]
  },
  "ES": {
    "name": "Spain",
    "native": "España",
    "phone": "34",
    "continent": "EU",
    "capital": "Madrid",
    "currency": "EUR",
    "languages": [
      "es",
      "eu",
      "ca",
      "gl",
      "oc"
    ]
  },
  "ET": {
    "name": "Ethiopia",
    "native": "ኢትዮጵያ",
    "phone": "251",
    "continent": "AF",
    "capital": "Addis Ababa",
    "currency": "ETB",
    "languages": [
      "am"
    ]
  },
  "FI": {
    "name": "Finland",
    "native": "Suomi",
    "phone": "358",
    "continent": "EU",
    "capital": "Helsinki",
    "currency": "EUR",
    "languages": [
      "fi",
      "sv"
    ]
  },
  "FJ": {
    "name": "Fiji",
    "native": "Fiji",
    "phone": "679",
    "continent": "OC",
    "capital": "Suva",
    "currency": "FJD",
    "languages": [
      "en",
      "fj",
      "hi",
      "ur"
    ]
  },
  "FK": {
    "name": "Falkland Islands",
    "native": "Falkland Islands",
    "phone": "500",
    "continent": "SA",
    "capital": "Stanley",
    "currency": "FKP",
    "languages": [
      "en"
    ]
  },
  "FM": {
    "name": "Micronesia",
    "native": "Micronesia",
    "phone": "691",
    "continent": "OC",
    "capital": "Palikir",
    "currency": "USD",
    "languages": [
      "en"
    ]
  },
  "FO": {
    "name": "Faroe Islands",
    "native": "Føroyar",
    "phone": "298",
    "continent": "EU",
    "capital": "Tórshavn",
    "currency": "DKK",
    "languages": [
      "fo"
    ]
  },
  "FR": {
    "name": "France",
    "native": "France",
    "phone": "33",
    "continent": "EU",
    "capital": "Paris",
    "currency": "EUR",
    "languages": [
      "fr"
    ]
  },
  "GA": {
    "name": "Gabon",
    "native": "Gabon",
    "phone": "241",
    "continent": "AF",
    "capital": "Libreville",
    "currency": "XAF",
    "languages": [
      "fr"
    ]
  },
  "GB": {
    "name": "United Kingdom",
    "native": "United Kingdom",
    "phone": "44",
    "continent": "EU",
    "capital": "London",
    "currency": "GBP",
    "languages": [
      "en"
    ]
  },
  "GD": {
    "name": "Grenada",
    "native": "Grenada",
    "phone": "1473",
    "continent": "NA",
    "capital": "St. George's",
    "currency": "XCD",
    "languages": [
      "en"
    ]
  },
  "GE": {
    "name": "Georgia",
    "native": "საქართველო",
    "phone": "995",
    "continent": "AS",
    "capital": "Tbilisi",
    "currency": "GEL",
    "languages": [
      "ka"
    ]
  },
  "GF": {
    "name": "French Guiana",
    "native": "Guyane française",
    "phone": "594",
    "continent": "SA",
    "capital": "Cayenne",
    "currency": "EUR",
    "languages": [
      "fr"
    ]
  },
  "GG": {
    "name": "Guernsey",
    "native": "Guernsey",
    "phone": "44",
    "continent": "EU",
    "capital": "St. Peter Port",
    "currency": "GBP",
    "languages": [
      "en",
      "fr"
    ]
  },
  "GH": {
    "name": "Ghana",
    "native": "Ghana",
    "phone": "233",
    "continent": "AF",
    "capital": "Accra",
    "currency": "GHS",
    "languages": [
      "en"
    ]
  },
  "GI": {
    "name": "Gibraltar",
    "native": "Gibraltar",
    "phone": "350",
    "continent": "EU",
    "capital": "Gibraltar",
    "currency": "GIP",
    "languages": [
      "en"
    ]
  },
  "GL": {
    "name": "Greenland",
    "native": "Kalaallit Nunaat",
    "phone": "299",
    "continent": "NA",
    "capital": "Nuuk",
    "currency": "DKK",
    "languages": [
      "kl"
    ]
  },
  "GM": {
    "name": "Gambia",
    "native": "Gambia",
    "phone": "220",
    "continent": "AF",
    "capital": "Banjul",
    "currency": "GMD",
    "languages": [
      "en"
    ]
  },
  "GN": {
    "name": "Guinea",
    "native": "Guinée",
    "phone": "224",
    "continent": "AF",
    "capital": "Conakry",
    "currency": "GNF",
    "languages": [
      "fr",
      "ff"
    ]
  },
  "GP": {
    "name": "Guadeloupe",
    "native": "Guadeloupe",
    "phone": "590",
    "continent": "NA",
    "capital": "Basse-Terre",
    "currency": "EUR",
    "languages": [
      "fr"
    ]
  },
  "GQ": {
    "name": "Equatorial Guinea",
    "native": "Guinea Ecuatorial",
    "phone": "240",
    "continent": "AF",
    "capital": "Malabo",
    "currency": "XAF",
    "languages": [
      "es",
      "fr"
    ]
  },
  "GR": {
    "name": "Greece",
    "native": "Ελλάδα",
    "phone": "30",
    "continent": "EU",
    "capital": "Athens",
    "currency": "EUR",
    "languages": [
      "el"
    ]
  },
  "GS": {
    "name": "South Georgia and the South Sandwich Islands",
    "native": "South Georgia",
    "phone": "500",
    "continent": "AN",
    "capital": "King Edward Point",
    "currency": "GBP",
    "languages": [
      "en"
    ]
  },
  "GT": {
    "name": "Guatemala",
    "native": "Guatemala",
    "phone": "502",
    "continent": "NA",
    "capital": "Guatemala City",
    "currency": "GTQ",
    "languages": [
      "es"
    ]
  },
  "GU": {
    "name": "Guam",
    "native": "Guam",
    "phone": "1671",
    "continent": "OC",
    "capital": "Hagåtña",
    "currency": "USD",
    "languages": [
      "en",
      "ch",
      "es"
    ]
  },
  "GW": {
    "name": "Guinea-Bissau",
    "native": "Guiné-Bissau",
    "phone": "245",
    "continent": "AF",
    "capital": "Bissau",
    "currency": "XOF",
    "languages": [
      "pt"
    ]
  },
  "GY": {
    "name": "Guyana",
    "native": "Guyana",
    "phone": "592",
    "continent": "SA",
    "capital": "Georgetown",
    "currency": "GYD",
    "languages": [
      "en"
    ]
  },
  "HK": {
    "name": "Hong Kong",
    "native": "香港",
    "phone": "852",
    "continent": "AS",
    "capital": "City of Victoria",
    "currency": "HKD",
    "languages": [
      "zh",
      "en"
    ]
  },
  "HM": {
    "name": "Heard Island and McDonald Islands",
    "native": "Heard Island and McDonald Islands",
    "phone": "61",
    "continent": "AN",
    "capital": "",
    "currency": "AUD",
    "languages": [
      "en"
    ]
  },
  "HN": {
    "name": "Honduras",
    "native": "Honduras",
    "phone": "504",
    "continent": "NA",
    "capital": "Tegucigalpa",
    "currency": "HNL",
    "languages": [
      "es"
    ]
  },
  "HR": {
    "name": "Croatia",
    "native": "Hrvatska",
    "phone": "385",
    "continent": "EU",
    "capital": "Zagreb",
    "currency": "HRK",
    "languages": [
      "hr"
    ]
  },
  "HT": {
    "name": "Haiti",
    "native": "Haïti",
    "phone": "509",
    "continent": "NA",
    "capital": "Port-au-Prince",
    "currency": "HTG",
    "languages": [
      "fr",
      "ht"
    ]
  },
  "HU": {
    "name": "Hungary",
    "native": "Magyarország",
    "phone": "36",
    "continent": "EU",
    "capital": "Budapest",
    "currency": "HUF",
    "languages": [
      "hu"
    ]
  },
  "ID": {
    "name": "Indonesia",
    "native": "Indonesia",
    "phone": "62",
    "continent": "AS",
    "capital": "Jakarta",
    "currency": "IDR",
    "languages": [
      "id"
    ]
  },
  "IE": {
    "name": "Ireland",
    "native": "Éire",
    "phone": "353",
    "continent": "EU",
    "capital": "Dublin",
    "currency": "EUR",
    "languages": [
      "ga",
      "en"
    ]
  },
  "IL": {
    "name": "Israel",
    "native": "יִשְׂרָאֵל",
    "phone": "972",
    "continent": "AS",
    "capital": "Jerusalem",
    "currency": "ILS",
    "languages": [
      "he",
      "ar"
    ]
  },
  "IM": {
    "name": "Isle of Man",
    "native": "Isle of Man",
    "phone": "44",
    "continent": "EU",
    "capital": "Douglas",
    "currency": "GBP",
    "languages": [
      "en",
      "gv"
    ]
  },
  "IN": {
    "name": "India",
    "native": "भारत",
    "phone": "91",
    "continent": "AS",
    "capital": "New Delhi",
    "currency": "INR",
    "languages": [
      "hi",
      "en"
    ]
  },
  "IO": {
    "name": "British Indian Ocean Territory",
    "native": "British Indian Ocean Territory",
    "phone": "246",
    "continent": "AS",
    "capital": "Diego Garcia",
    "currency": "USD",
    "languages": [
      "en"
    ]
  },
  "IQ": {
    "name": "Iraq",
    "native": "العراق",
    "phone": "964",
    "continent": "AS",
    "capital": "Baghdad",
    "currency": "IQD",
    "languages": [
      "ar",
      "ku"
    ]
  },
  "IR": {
    "name": "Iran",
    "native": "ایران",
    "phone": "98",
    "continent": "AS",
    "capital": "Tehran",
    "currency": "IRR",
    "languages": [
      "fa"
    ]
  },
  "IS": {
    "name": "Iceland",
    "native": "Ísland",
    "phone": "354",
    "continent": "EU",
    "capital": "Reykjavik",
    "currency": "ISK",
    "languages": [
      "is"
    ]
  },
  "IT": {
    "name": "Italy",
    "native": "Italia",
    "phone": "39",
    "continent": "EU",
    "capital": "Rome",
    "currency": "EUR",
    "languages": [
      "it"
    ]
  },
  "JE": {
    "name": "Jersey",
    "native": "Jersey",
    "phone": "44",
    "continent": "EU",
    "capital": "Saint Helier",
    "currency": "GBP",
    "languages": [
      "en",
      "fr"
    ]
  },
  "JM": {
    "name": "Jamaica",
    "native": "Jamaica",
    "phone": "1876",
    "continent": "NA",
    "capital": "Kingston",
    "currency": "JMD",
    "languages": [
      "en"
    ]
  },
  "JO": {
    "name": "Jordan",
    "native": "الأردن",
    "phone": "962",
    "continent": "AS",
    "capital": "Amman",
    "currency": "JOD",
    "languages": [
      "ar"
    ]
  },
  "JP": {
    "name": "Japan",
    "native": "日本",
    "phone": "81",
    "continent": "AS",
    "capital": "Tokyo",
    "currency": "JPY",
    "languages": [
      "ja"
    ]
  },
  "KE": {
    "name": "Kenya",
    "native": "Kenya",
    "phone": "254",
    "continent": "AF",
    "capital": "Nairobi",
    "currency": "KES",
    "languages": [
      "en",
      "sw"
    ]
  },
  "KG": {
    "name": "Kyrgyzstan",
    "native": "Кыргызстан",
    "phone": "996",
    "continent": "AS",
    "capital": "Bishkek",
    "currency": "KGS",
    "languages": [
      "ky",
      "ru"
    ]
  },
  "KH": {
    "name": "Cambodia",
    "native": "Kâmpŭchéa",
    "phone": "855",
    "continent": "AS",
    "capital": "Phnom Penh",
    "currency": "KHR",
    "languages": [
      "km"
    ]
  },
  "KI": {
    "name": "Kiribati",
    "native": "Kiribati",
    "phone": "686",
    "continent": "OC",
    "capital": "South Tarawa",
    "currency": "AUD",
    "languages": [
      "en"
    ]
  },
  "KM": {
    "name": "Comoros",
    "native": "Komori",
    "phone": "269",
    "continent": "AF",
    "capital": "Moroni",
    "currency": "KMF",
    "languages": [
      "ar",
      "fr"
    ]
  },
  "KN": {
    "name": "Saint Kitts and Nevis",
    "native": "Saint Kitts and Nevis",
    "phone": "1869",
    "continent": "NA",
    "capital": "Basseterre",
    "currency": "XCD",
    "languages": [
      "en"
    ]
  },
  "KP": {
    "name": "North Korea",
    "native": "북한",
    "phone": "850",
    "continent": "AS",
    "capital": "Pyongyang",
    "currency": "KPW",
    "languages": [
      "ko"
    ]
  },
  "KR": {
    "name": "South Korea",
    "native": "대한민국",
    "phone": "82",
    "continent": "AS",
    "capital": "Seoul",
    "currency": "KRW",
    "languages": [
      "ko"
    ]
  },
  "KW": {
    "name": "Kuwait",
    "native": "الكويت",
    "phone": "965",
    "continent": "AS",
    "capital": "Kuwait City",
    "currency": "KWD",
    "languages": [
      "ar"
    ]
  },
  "KY": {
    "name": "Cayman Islands",
    "native": "Cayman Islands",
    "phone": "1345",
    "continent": "NA",
    "capital": "George Town",
    "currency": "KYD",
    "languages": [
      "en"
    ]
  },
  "KZ": {
    "name": "Kazakhstan",
    "native": "Қазақстан",
    "phone": "76,77",
    "continent": "AS",
    "capital": "Astana",
    "currency": "KZT",
    "languages": [
      "kk",
      "ru"
    ]
  },
  "LA": {
    "name": "Laos",
    "native": "ສປປລາວ",
    "phone": "856",
    "continent": "AS",
    "capital": "Vientiane",
    "currency": "LAK",
    "languages": [
      "lo"
    ]
  },
  "LB": {
    "name": "Lebanon",
    "native": "لبنان",
    "phone": "961",
    "continent": "AS",
    "capital": "Beirut",
    "currency": "LBP",
    "languages": [
      "ar",
      "fr"
    ]
  },
  "LC": {
    "name": "Saint Lucia",
    "native": "Saint Lucia",
    "phone": "1758",
    "continent": "NA",
    "capital": "Castries",
    "currency": "XCD",
    "languages": [
      "en"
    ]
  },
  "LI": {
    "name": "Liechtenstein",
    "native": "Liechtenstein",
    "phone": "423",
    "continent": "EU",
    "capital": "Vaduz",
    "currency": "CHF",
    "languages": [
      "de"
    ]
  },
  "LK": {
    "name": "Sri Lanka",
    "native": "śrī laṃkāva",
    "phone": "94",
    "continent": "AS",
    "capital": "Colombo",
    "currency": "LKR",
    "languages": [
      "si",
      "ta"
    ]
  },
  "LR": {
    "name": "Liberia",
    "native": "Liberia",
    "phone": "231",
    "continent": "AF",
    "capital": "Monrovia",
    "currency": "LRD",
    "languages": [
      "en"
    ]
  },
  "LS": {
    "name": "Lesotho",
    "native": "Lesotho",
    "phone": "266",
    "continent": "AF",
    "capital": "Maseru",
    "currency": "LSL",
    "languages": [
      "en",
      "st"
    ]
  },
  "LT": {
    "name": "Lithuania",
    "native": "Lietuva",
    "phone": "370",
    "continent": "EU",
    "capital": "Vilnius",
    "currency": "EUR",
    "languages": [
      "lt"
    ]
  },
  "LU": {
    "name": "Luxembourg",
    "native": "Luxembourg",
    "phone": "352",
    "continent": "EU",
    "capital": "Luxembourg",
    "currency": "EUR",
    "languages": [
      "fr",
      "de",
      "lb"
    ]
  },
  "LV": {
    "name": "Latvia",
    "native": "Latvija",
    "phone": "371",
    "continent": "EU",
    "capital": "Riga",
    "currency": "EUR",
    "languages": [
      "lv"
    ]
  },
  "LY": {
    "name": "Libya",
    "native": "‏ليبيا",
    "phone": "218",
    "continent": "AF",
    "capital": "Tripoli",
    "currency": "LYD",
    "languages": [
      "ar"
    ]
  },
  "MA": {
    "name": "Morocco",
    "native": "المغرب",
    "phone": "212",
    "continent": "AF",
    "capital": "Rabat",
    "currency": "MAD",
    "languages": [
      "ar"
    ]
  },
  "MC": {
    "name": "Monaco",
    "native": "Monaco",
    "phone": "377",
    "continent": "EU",
    "capital": "Monaco",
    "currency": "EUR",
    "languages": [
      "fr"
    ]
  },
  "MD": {
    "name": "Moldova",
    "native": "Moldova",
    "phone": "373",
    "continent": "EU",
    "capital": "Chișinău",
    "currency": "MDL",
    "languages": [
      "ro"
    ]
  },
  "ME": {
    "name": "Montenegro",
    "native": "Црна Гора",
    "phone": "382",
    "continent": "EU",
    "capital": "Podgorica",
    "currency": "EUR",
    "languages": [
      "sr",
      "bs",
      "sq",
      "hr"
    ]
  },
  "MF": {
    "name": "Saint Martin",
    "native": "Saint-Martin",
    "phone": "590",
    "continent": "NA",
    "capital": "Marigot",
    "currency": "EUR",
    "languages": [
      "en",
      "fr",
      "nl"
    ]
  },
  "MG": {
    "name": "Madagascar",
    "native": "Madagasikara",
    "phone": "261",
    "continent": "AF",
    "capital": "Antananarivo",
    "currency": "MGA",
    "languages": [
      "fr",
      "mg"
    ]
  },
  "MH": {
    "name": "Marshall Islands",
    "native": "M̧ajeļ",
    "phone": "692",
    "continent": "OC",
    "capital": "Majuro",
    "currency": "USD",
    "languages": [
      "en",
      "mh"
    ]
  },
  "MK": {
    "name": "North Macedonia",
    "native": "Северна Македонија",
    "phone": "389",
    "continent": "EU",
    "capital": "Skopje",
    "currency": "MKD",
    "languages": [
      "mk"
    ]
  },
  "ML": {
    "name": "Mali",
    "native": "Mali",
    "phone": "223",
    "continent": "AF",
    "capital": "Bamako",
    "currency": "XOF",
    "languages": [
      "fr"
    ]
  },
  "MM": {
    "name": "Myanmar [Burma]",
    "native": "မြန်မာ",
    "phone": "95",
    "continent": "AS",
    "capital": "Naypyidaw",
    "currency": "MMK",
    "languages": [
      "my"
    ]
  },
  "MN": {
    "name": "Mongolia",
    "native": "Монгол улс",
    "phone": "976",
    "continent": "AS",
    "capital": "Ulan Bator",
    "currency": "MNT",
    "languages": [
      "mn"
    ]
  },
  "MO": {
    "name": "Macao",
    "native": "澳門",
    "phone": "853",
    "continent": "AS",
    "capital": "",
    "currency": "MOP",
    "languages": [
      "zh",
      "pt"
    ]
  },
  "MP": {
    "name": "Northern Mariana Islands",
    "native": "Northern Mariana Islands",
    "phone": "1670",
    "continent": "OC",
    "capital": "Saipan",
    "currency": "USD",
    "languages": [
      "en",
      "ch"
    ]
  },
  "MQ": {
    "name": "Martinique",
    "native": "Martinique",
    "phone": "596",
    "continent": "NA",
    "capital": "Fort-de-France",
    "currency": "EUR",
    "languages": [
      "fr"
    ]
  },
  "MR": {
    "name": "Mauritania",
    "native": "موريتانيا",
    "phone": "222",
    "continent": "AF",
    "capital": "Nouakchott",
    "currency": "MRU",
    "languages": [
      "ar"
    ]
  },
  "MS": {
    "name": "Montserrat",
    "native": "Montserrat",
    "phone": "1664",
    "continent": "NA",
    "capital": "Plymouth",
    "currency": "XCD",
    "languages": [
      "en"
    ]
  },
  "MT": {
    "name": "Malta",
    "native": "Malta",
    "phone": "356",
    "continent": "EU",
    "capital": "Valletta",
    "currency": "EUR",
    "languages": [
      "mt",
      "en"
    ]
  },
  "MU": {
    "name": "Mauritius",
    "native": "Maurice",
    "phone": "230",
    "continent": "AF",
    "capital": "Port Louis",
    "currency": "MUR",
    "languages": [
      "en"
    ]
  },
  "MV": {
    "name": "Maldives",
    "native": "Maldives",
    "phone": "960",
    "continent": "AS",
    "capital": "Malé",
    "currency": "MVR",
    "languages": [
      "dv"
    ]
  },
  "MW": {
    "name": "Malawi",
    "native": "Malawi",
    "phone": "265",
    "continent": "AF",
    "capital": "Lilongwe",
    "currency": "MWK",
    "languages": [
      "en",
      "ny"
    ]
  },
  "MX": {
    "name": "Mexico",
    "native": "México",
    "phone": "52",
    "continent": "NA",
    "capital": "Mexico City",
    "currency": "MXN",
    "languages": [
      "es"
    ]
  },
  "MY": {
    "name": "Malaysia",
    "native": "Malaysia",
    "phone": "60",
    "continent": "AS",
    "capital": "Kuala Lumpur",
    "currency": "MYR",
    "languages": [
      "ms"
    ]
  },
  "MZ": {
    "name": "Mozambique",
    "native": "Moçambique",
    "phone": "258",
    "continent": "AF",
    "capital": "Maputo",
    "currency": "MZN",
    "languages": [
      "pt"
    ]
  },
  "NA": {
    "name": "Namibia",
    "native": "Namibia",
    "phone": "264",
    "continent": "AF",
    "capital": "Windhoek",
    "currency": "NAD",
    "languages": [
      "en",
      "af"
    ]
  },
  "NC": {
    "name": "New Caledonia",
    "native": "Nouvelle-Calédonie",
    "phone": "687",
    "continent": "OC",
    "capital": "Nouméa",
    "currency": "XPF",
    "languages": [
      "fr"
    ]
  },
  "NE": {
    "name": "Niger",
    "native": "Niger",
    "phone": "227",
    "continent": "AF",
    "capital": "Niamey",
    "currency": "XOF",
    "languages": [
      "fr"
    ]
  },
  "NF": {
    "name": "Norfolk Island",
    "native": "Norfolk Island",
    "phone": "672",
    "continent": "OC",
    "capital": "Kingston",
    "currency": "AUD",
    "languages": [
      "en"
    ]
  },
  "NG": {
    "name": "Nigeria",
    "native": "Nigeria",
    "phone": "234",
    "continent": "AF",
    "capital": "Abuja",
    "currency": "NGN",
    "languages": [
      "en"
    ]
  },
  "NI": {
    "name": "Nicaragua",
    "native": "Nicaragua",
    "phone": "505",
    "continent": "NA",
    "capital": "Managua",
    "currency": "NIO",
    "languages": [
      "es"
    ]
  },
  "NL": {
    "name": "Netherlands",
    "native": "Nederland",
    "phone": "31",
    "continent": "EU",
    "capital": "Amsterdam",
    "currency": "EUR",
    "languages": [
      "nl"
    ]
  },
  "NO": {
    "name": "Norway",
    "native": "Norge",
    "phone": "47",
    "continent": "EU",
    "capital": "Oslo",
    "currency": "NOK",
    "languages": [
      "no",
      "nb",
      "nn"
    ]
  },
  "NP": {
    "name": "Nepal",
    "native": "नपल",
    "phone": "977",
    "continent": "AS",
    "capital": "Kathmandu",
    "currency": "NPR",
    "languages": [
      "ne"
    ]
  },
  "NR": {
    "name": "Nauru",
    "native": "Nauru",
    "phone": "674",
    "continent": "OC",
    "capital": "Yaren",
    "currency": "AUD",
    "languages": [
      "en",
      "na"
    ]
  },
  "NU": {
    "name": "Niue",
    "native": "Niuē",
    "phone": "683",
    "continent": "OC",
    "capital": "Alofi",
    "currency": "NZD",
    "languages": [
      "en"
    ]
  },
  "NZ": {
    "name": "New Zealand",
    "native": "New Zealand",
    "phone": "64",
    "continent": "OC",
    "capital": "Wellington",
    "currency": "NZD",
    "languages": [
      "en",
      "mi"
    ]
  },
  "OM": {
    "name": "Oman",
    "native": "عمان",
    "phone": "968",
    "continent": "AS",
    "capital": "Muscat",
    "currency": "OMR",
    "languages": [
      "ar"
    ]
  },
  "PA": {
    "name": "Panama",
    "native": "Panamá",
    "phone": "507",
    "continent": "NA",
    "capital": "Panama City",
    "currency": "PAB",
    "languages": [
      "es"
    ]
  },
  "PE": {
    "name": "Peru",
    "native": "Perú",
    "phone": "51",
    "continent": "SA",
    "capital": "Lima",
    "currency": "PEN",
    "languages": [
      "es"
    ]
  },
  "PF": {
    "name": "French Polynesia",
    "native": "Polynésie française",
    "phone": "689",
    "continent": "OC",
    "capital": "Papeetē",
    "currency": "XPF",
    "languages": [
      "fr"
    ]
  },
  "PG": {
    "name": "Papua New Guinea",
    "native": "Papua Niugini",
    "phone": "675",
    "continent": "OC",
    "capital": "Port Moresby",
    "currency": "PGK",
    "languages": [
      "en"
    ]
  },
  "PH": {
    "name": "Philippines",
    "native": "Pilipinas",
    "phone": "63",
    "continent": "AS",
    "capital": "Manila",
    "currency": "PHP",
    "languages": [
      "en"
    ]
  },
  "PK": {
    "name": "Pakistan",
    "native": "Pakistan",
    "phone": "92",
    "continent": "AS",
    "capital": "Islamabad",
    "currency": "PKR",
    "languages": [
      "en",
      "ur"
    ]
  },
  "PL": {
    "name": "Poland",
    "native": "Polska",
    "phone": "48",
    "continent": "EU",
    "capital": "Warsaw",
    "currency": "PLN",
    "languages": [
      "pl"
    ]
  },
  "PM": {
    "name": "Saint Pierre and Miquelon",
    "native": "Saint-Pierre-et-Miquelon",
    "phone": "508",
    "continent": "NA",
    "capital": "Saint-Pierre",
    "currency": "EUR",
    "languages": [
      "fr"
    ]
  },
  "PN": {
    "name": "Pitcairn Islands",
    "native": "Pitcairn Islands",
    "phone": "64",
    "continent": "OC",
    "capital": "Adamstown",
    "currency": "NZD",
    "languages": [
      "en"
    ]
  },
  "PR": {
    "name": "Puerto Rico",
    "native": "Puerto Rico",
    "phone": "1787,1939",
    "continent": "NA",
    "capital": "San Juan",
    "currency": "USD",
    "languages": [
      "es",
      "en"
    ]
  },
  "PS": {
    "name": "Palestine",
    "native": "فلسطين",
    "phone": "970",
    "continent": "AS",
    "capital": "Ramallah",
    "currency": "ILS",
    "languages": [
      "ar"
    ]
  },
  "PT": {
    "name": "Portugal",
    "native": "Portugal",
    "phone": "351",
    "continent": "EU",
    "capital": "Lisbon",
    "currency": "EUR",
    "languages": [
      "pt"
    ]
  },
  "PW": {
    "name": "Palau",
    "native": "Palau",
    "phone": "680",
    "continent": "OC",
    "capital": "Ngerulmud",
    "currency": "USD",
    "languages": [
      "en"
    ]
  },
  "PY": {
    "name": "Paraguay",
    "native": "Paraguay",
    "phone": "595",
    "continent": "SA",
    "capital": "Asunción",
    "currency": "PYG",
    "languages": [
      "es",
      "gn"
    ]
  },
  "QA": {
    "name": "Qatar",
    "native": "قطر",
    "phone": "974",
    "continent": "AS",
    "capital": "Doha",
    "currency": "QAR",
    "languages": [
      "ar"
    ]
  },
  "RE": {
    "name": "Réunion",
    "native": "La Réunion",
    "phone": "262",
    "continent": "AF",
    "capital": "Saint-Denis",
    "currency": "EUR",
    "languages": [
      "fr"
    ]
  },
  "RO": {
    "name": "Romania",
    "native": "România",
    "phone": "40",
    "continent": "EU",
    "capital": "Bucharest",
    "currency": "RON",
    "languages": [
      "ro"
    ]
  },
  "RS": {
    "name": "Serbia",
    "native": "Србија",
    "phone": "381",
    "continent": "EU",
    "capital": "Belgrade",
    "currency": "RSD",
    "languages": [
      "sr"
    ]
  },
  "RU": {
    "name": "Russia",
    "native": "Россия",
    "phone": "7",
    "continent": "EU",
    "capital": "Moscow",
    "currency": "RUB",
    "languages": [
      "ru"
    ]
  },
  "RW": {
    "name": "Rwanda",
    "native": "Rwanda",
    "phone": "250",
    "continent": "AF",
    "capital": "Kigali",
    "currency": "RWF",
    "languages": [
      "rw",
      "en",
      "fr"
    ]
  },
  "SA": {
    "name": "Saudi Arabia",
    "native": "العربية السعودية",
    "phone": "966",
    "continent": "AS",
    "capital": "Riyadh",
    "currency": "SAR",
    "languages": [
      "ar"
    ]
  },
  "SB": {
    "name": "Solomon Islands",
    "native": "Solomon Islands",
    "phone": "677",
    "continent": "OC",
    "capital": "Honiara",
    "currency": "SBD",
    "languages": [
      "en"
    ]
  },
  "SC": {
    "name": "Seychelles",
    "native": "Seychelles",
    "phone": "248",
    "continent": "AF",
    "capital": "Victoria",
    "currency": "SCR",
    "languages": [
      "fr",
      "en"
    ]
  },
  "SD": {
    "name": "Sudan",
    "native": "السودان",
    "phone": "249",
    "continent": "AF",
    "capital": "Khartoum",
    "currency": "SDG",
    "languages": [
      "ar",
      "en"
    ]
  },
  "SE": {
    "name": "Sweden",
    "native": "Sverige",
    "phone": "46",
    "continent": "EU",
    "capital": "Stockholm",
    "currency": "SEK",
    "languages": [
      "sv"
    ]
  },
  "SG": {
    "name": "Singapore",
    "native": "Singapore",
    "phone": "65",
    "continent": "AS",
    "capital": "Singapore",
    "currency": "SGD",
    "languages": [
      "en",
      "ms",
      "ta",
      "zh"
    ]
  },
  "SH": {
    "name": "Saint Helena",
    "native": "Saint Helena",
    "phone": "290",
    "continent": "AF",
    "capital": "Jamestown",
    "currency": "SHP",
    "languages": [
      "en"
    ]
  },
  "SI": {
    "name": "Slovenia",
    "native": "Slovenija",
    "phone": "386",
    "continent": "EU",
    "capital": "Ljubljana",
    "currency": "EUR",
    "languages": [
      "sl"
    ]
  },
  "SJ": {
    "name": "Svalbard and Jan Mayen",
    "native": "Svalbard og Jan Mayen",
    "phone": "4779",
    "continent": "EU",
    "capital": "Longyearbyen",
    "currency": "NOK",
    "languages": [
      "no"
    ]
  },
  "SK": {
    "name": "Slovakia",
    "native": "Slovensko",
    "phone": "421",
    "continent": "EU",
    "capital": "Bratislava",
    "currency": "EUR",
    "languages": [
      "sk"
    ]
  },
  "SL": {
    "name": "Sierra Leone",
    "native": "Sierra Leone",
    "phone": "232",
    "continent": "AF",
    "capital": "Freetown",
    "currency": "SLL",
    "languages": [
      "en"
    ]
  },
  "SM": {
    "name": "San Marino",
    "native": "San Marino",
    "phone": "378",
    "continent": "EU",
    "capital": "City of San Marino",
    "currency": "EUR",
    "languages": [
      "it"
    ]
  },
  "SN": {
    "name": "Senegal",
    "native": "Sénégal",
    "phone": "221",
    "continent": "AF",
    "capital": "Dakar",
    "currency": "XOF",
    "languages": [
      "fr"
    ]
  },
  "SO": {
    "name": "Somalia",
    "native": "Soomaaliya",
    "phone": "252",
    "continent": "AF",
    "capital": "Mogadishu",
    "currency": "SOS",
    "languages": [
      "so",
      "ar"
    ]
  },
  "SR": {
    "name": "Suriname",
    "native": "Suriname",
    "phone": "597",
    "continent": "SA",
    "capital": "Paramaribo",
    "currency": "SRD",
    "languages": [
      "nl"
    ]
  },
  "SS": {
    "name": "South Sudan",
    "native": "South Sudan",
    "phone": "211",
    "continent": "AF",
    "capital": "Juba",
    "currency": "SSP",
    "languages": [
      "en"
    ]
  },
  "ST": {
    "name": "São Tomé and Príncipe",
    "native": "São Tomé e Príncipe",
    "phone": "239",
    "continent": "AF",
    "capital": "São Tomé",
    "currency": "STN",
    "languages": [
      "pt"
    ]
  },
  "SV": {
    "name": "El Salvador",
    "native": "El Salvador",
    "phone": "503",
    "continent": "NA",
    "capital": "San Salvador",
    "currency": "SVC",
    "languages": [
      "es"
    ]
  },
  "SX": {
    "name": "Sint Maarten",
    "native": "Sint Maarten",
    "phone": "1721",
    "continent": "NA",
    "capital": "Philipsburg",
    "currency": "ANG",
    "languages": [
      "nl",
      "en"
    ]
  },
  "SY": {
    "name": "Syria",
    "native": "سوريا",
    "phone": "963",
    "continent": "AS",
    "capital": "Damascus",
    "currency": "SYP",
    "languages": [
      "ar"
    ]
  },
  "SZ": {
    "name": "Swaziland",
    "native": "Swaziland",
    "phone": "268",
    "continent": "AF",
    "capital": "Lobamba",
    "currency": "SZL",
    "languages": [
      "en",
      "ss"
    ]
  },
  "TC": {
    "name": "Turks and Caicos Islands",
    "native": "Turks and Caicos Islands",
    "phone": "1649",
    "continent": "NA",
    "capital": "Cockburn Town",
    "currency": "USD",
    "languages": [
      "en"
    ]
  },
  "TD": {
    "name": "Chad",
    "native": "Tchad",
    "phone": "235",
    "continent": "AF",
    "capital": "N'Djamena",
    "currency": "XAF",
    "languages": [
      "fr",
      "ar"
    ]
  },
  "TF": {
    "name": "French Southern Territories",
    "native": "Territoire des Terres australes et antarctiques fr",
    "phone": "262",
    "continent": "AN",
    "capital": "Port-aux-Français",
    "currency": "EUR",
    "languages": [
      "fr"
    ]
  },
  "TG": {
    "name": "Togo",
    "native": "Togo",
    "phone": "228",
    "continent": "AF",
    "capital": "Lomé",
    "currency": "XOF",
    "languages": [
      "fr"
    ]
  },
  "TH": {
    "name": "Thailand",
    "native": "ประเทศไทย",
    "phone": "66",
    "continent": "AS",
    "capital": "Bangkok",
    "currency": "THB",
    "languages": [
      "th"
    ]
  },
  "TJ": {
    "name": "Tajikistan",
    "native": "Тоҷикистон",
    "phone": "992",
    "continent": "AS",
    "capital": "Dushanbe",
    "currency": "TJS",
    "languages": [
      "tg",
      "ru"
    ]
  },
  "TK": {
    "name": "Tokelau",
    "native": "Tokelau",
    "phone": "690",
    "continent": "OC",
    "capital": "Fakaofo",
    "currency": "NZD",
    "languages": [
      "en"
    ]
  },
  "TL": {
    "name": "East Timor",
    "native": "Timor-Leste",
    "phone": "670",
    "continent": "OC",
    "capital": "Dili",
    "currency": "USD",
    "languages": [
      "pt"
    ]
  },
  "TM": {
    "name": "Turkmenistan",
    "native": "Türkmenistan",
    "phone": "993",
    "continent": "AS",
    "capital": "Ashgabat",
    "currency": "TMT",
    "languages": [
      "tk",
      "ru"
    ]
  },
  "TN": {
    "name": "Tunisia",
    "native": "تونس",
    "phone": "216",
    "continent": "AF",
    "capital": "Tunis",
    "currency": "TND",
    "languages": [
      "ar"
    ]
  },
  "TO": {
    "name": "Tonga",
    "native": "Tonga",
    "phone": "676",
    "continent": "OC",
    "capital": "Nuku'alofa",
    "currency": "TOP",
    "languages": [
      "en",
      "to"
    ]
  },
  "TR": {
    "name": "Turkey",
    "native": "Türkiye",
    "phone": "90",
    "continent": "AS",
    "capital": "Ankara",
    "currency": "TRY",
    "languages": [
      "tr"
    ]
  },
  "TT": {
    "name": "Trinidad and Tobago",
    "native": "Trinidad and Tobago",
    "phone": "1868",
    "continent": "NA",
    "capital": "Port of Spain",
    "currency": "TTD",
    "languages": [
      "en"
    ]
  },
  "TV": {
    "name": "Tuvalu",
    "native": "Tuvalu",
    "phone": "688",
    "continent": "OC",
    "capital": "Funafuti",
    "currency": "AUD",
    "languages": [
      "en"
    ]
  },
  "TW": {
    "name": "Taiwan",
    "native": "臺灣",
    "phone": "886",
    "continent": "AS",
    "capital": "Taipei",
    "currency": "TWD",
    "languages": [
      "zh"
    ]
  },
  "TZ": {
    "name": "Tanzania",
    "native": "Tanzania",
    "phone": "255",
    "continent": "AF",
    "capital": "Dodoma",
    "currency": "TZS",
    "languages": [
      "sw",
      "en"
    ]
  },
  "UA": {
    "name": "Ukraine",
    "native": "Україна",
    "phone": "380",
    "continent": "EU",
    "capital": "Kyiv",
    "currency": "UAH",
    "languages": [
      "uk"
    ]
  },
  "UG": {
    "name": "Uganda",
    "native": "Uganda",
    "phone": "256",
    "continent": "AF",
    "capital": "Kampala",
    "currency": "UGX",
    "languages": [
      "en",
      "sw"
    ]
  },
  "UM": {
    "name": "U.S. Minor Outlying Islands",
    "native": "United States Minor Outlying Islands",
    "phone": "1",
    "continent": "OC",
    "capital": "",
    "currency": "USD",
    "languages": [
      "en"
    ]
  },
  "US": {
    "name": "United States",
    "native": "United States",
    "phone": "1",
    "continent": "NA",
    "capital": "Washington D.C.",
    "currency": "USD",
    "languages": [
      "en"
    ]
  },
  "UY": {
    "name": "Uruguay",
    "native": "Uruguay",
    "phone": "598",
    "continent": "SA",
    "capital": "Montevideo",
    "currency": "UYU",
    "languages": [
      "es"
    ]
  },
  "UZ": {
    "name": "Uzbekistan",
    "native": "O‘zbekiston",
    "phone": "998",
    "continent": "AS",
    "capital": "Tashkent",
    "currency": "UZS",
    "languages": [
      "uz",
      "ru"
    ]
  },
  "VA": {
    "name": "Vatican City",
    "native": "Vaticano",
    "phone": "379",
    "continent": "EU",
    "capital": "Vatican City",
    "currency": "EUR",
    "languages": [
      "it",
      "la"
    ]
  },
  "VC": {
    "name": "Saint Vincent and the Grenadines",
    "native": "Saint Vincent and the Grenadines",
    "phone": "1784",
    "continent": "NA",
    "capital": "Kingstown",
    "currency": "XCD",
    "languages": [
      "en"
    ]
  },
  "VE": {
    "name": "Venezuela",
    "native": "Venezuela",
    "phone": "58",
    "continent": "SA",
    "capital": "Caracas",
    "currency": "VES",
    "languages": [
      "es"
    ]
  },
  "VG": {
    "name": "British Virgin Islands",
    "native": "British Virgin Islands",
    "phone": "1284",
    "continent": "NA",
    "capital": "Road Town",
    "currency": "USD",
    "languages": [
      "en"
    ]
  },
  "VI": {
    "name": "U.S. Virgin Islands",
    "native": "United States Virgin Islands",
    "phone": "1340",
    "continent": "NA",
    "capital": "Charlotte Amalie",
    "currency": "USD",
    "languages": [
      "en"
    ]
  },
  "VN": {
    "name": "Vietnam",
    "native": "Việt Nam",
    "phone": "84",
    "continent": "AS",
    "capital": "Hanoi",
    "currency": "VND",
    "languages": [
      "vi"
    ]
  },
  "VU": {
    "name": "Vanuatu",
    "native": "Vanuatu",
    "phone": "678",
    "continent": "OC",
    "capital": "Port Vila",
    "currency": "VUV",
    "languages": [
      "bi",
      "en",
      "fr"
    ]
  },
  "WF": {
    "name": "Wallis and Futuna",
    "native": "Wallis et Futuna",
    "phone": "681",
    "continent": "OC",
    "capital": "Mata-Utu",
    "currency": "XPF",
    "languages": [
      "fr"
    ]
  },
  "WS": {
    "name": "Samoa",
    "native": "Samoa",
    "phone": "685",
    "continent": "OC",
    "capital": "Apia",
    "currency": "WST",
    "languages": [
      "sm",
      "en"
    ]
  },
  "XK": {
    "name": "Kosovo",
    "native": "Republika e Kosovës",
    "phone": "377,381,383,386",
    "continent": "EU",
    "capital": "Pristina",
    "currency": "EUR",
    "languages": [
      "sq",
      "sr"
    ]
  },
  "YE": {
    "name": "Yemen",
    "native": "اليَمَن",
    "phone": "967",
    "continent": "AS",
    "capital": "Sana'a",
    "currency": "YER",
    "languages": [
      "ar"
    ]
  },
  "YT": {
    "name": "Mayotte",
    "native": "Mayotte",
    "phone": "262",
    "continent": "AF",
    "capital": "Mamoudzou",
    "currency": "EUR",
    "languages": [
      "fr"
    ]
  },
  "ZA": {
    "name": "South Africa",
    "native": "South Africa",
    "phone": "27",
    "continent": "AF",
    "capital": "Pretoria",
    "currency": "ZAR",
    "languages": [
      "af",
      "en",
      "nr",
      "st",
      "ss",
      "tn",
      "ts",
      "ve",
      "xh",
      "zu"
    ]
  },
  "ZM": {
    "name": "Zambia",
    "native": "Zambia",
    "phone": "260",
    "continent": "AF",
    "capital": "Lusaka",
    "currency": "ZMW",
    "languages": [
      "en"
    ]
  },
  "ZW": {
    "name": "Zimbabwe",
    "native": "Zimbabwe",
    "phone": "263",
    "continent": "AF",
    "capital": "Harare",
    "currency": "USD",
    "languages": [
      "en",
      "sn",
      "nd"
    ]
  }
}

export const countryDateFormats: AsFields = {
  "af-ZA": "yyyy/MM/dd",
  "am-ET": "d/M/yyyy",
  "ar-AE": "dd/MM/yyyy",
  "ar-BH": "dd/MM/yyyy",
  "ar-DZ": "dd-MM-yyyy",
  "ar-EG": "dd/MM/yyyy",
  "ar-IQ": "dd/MM/yyyy",
  "ar-JO": "dd/MM/yyyy",
  "ar-KW": "dd/MM/yyyy",
  "ar-LB": "dd/MM/yyyy",
  "ar-LY": "dd/MM/yyyy",
  "ar-MA": "dd-MM-yyyy",
  "ar-OM": "dd/MM/yyyy",
  "ar-QA": "dd/MM/yyyy",
  "ar-SA": "dd/MM/yy",
  "ar-SY": "dd/MM/yyyy",
  "ar-TN": "dd-MM-yyyy",
  "ar-YE": "dd/MM/yyyy",
  "arn-CL": "dd-MM-yyyy",
  "as-IN": "dd-MM-yyyy",
  "az-Cyrl-AZ": "dd.MM.yyyy",
  "az-Latn-AZ": "dd.MM.yyyy",
  "ba-RU": "dd.MM.yy",
  "be-BY": "dd.MM.yyyy",
  "bg-BG": "dd.M.yyyy",
  "bn-BD": "dd-MM-yy",
  "bn-IN": "dd-MM-yy",
  "bo-CN": "yyyy/M/d",
  "br-FR": "dd/MM/yyyy",
  "bs-Cyrl-BA": "d.M.yyyy",
  "bs-Latn-BA": "d.M.yyyy",
  "ca-ES": "dd/MM/yyyy",
  "co-FR": "dd/MM/yyyy",
  "cs-CZ": "d.M.yyyy",
  "cy-GB": "dd/MM/yyyy",
  "da-DK": "dd-MM-yyyy",
  "de-AT": "dd.MM.yyyy",
  "de-CH": "dd.MM.yyyy",
  "de-DE": "dd.MM.yyyy",
  "de-LI": "dd.MM.yyyy",
  "de-LU": "dd.MM.yyyy",
  "dsb-DE": "d. M. yyyy",
  "dv-MV": "dd/MM/yy",
  "el-GR": "d/M/yyyy",
  "en-029": "MM/dd/yyyy",
  "en-AU": "d/MM/yyyy",
  "en-BZ": "dd/MM/yyyy",
  "en-CA": "dd/MM/yyyy",
  "en-GB": "dd/MM/yyyy",
  "en-IE": "dd/MM/yyyy",
  "en-IN": "dd-MM-yyyy",
  "en-JM": "dd/MM/yyyy",
  "en-MY": "d/M/yyyy",
  "en-NZ": "d/MM/yyyy",
  "en-PH": "M/d/yyyy",
  "en-SG": "d/M/yyyy",
  "en-TT": "dd/MM/yyyy",
  "en-US": "MM/dd/yyyy",
  "en-ZA": "yyyy/MM/dd",
  "en-ZW": "M/d/yyyy",
  "es-AR": "dd/MM/yyyy",
  "es-BO": "dd/MM/yyyy",
  "es-CL": "dd-MM-yyyy",
  "es-CO": "dd/MM/yyyy",
  "es-CR": "dd/MM/yyyy",
  "es-DO": "dd/MM/yyyy",
  "es-EC": "dd/MM/yyyy",
  "es-ES": "dd/MM/yyyy",
  "es-GT": "dd/MM/yyyy",
  "es-HN": "dd/MM/yyyy",
  "es-MX": "dd/MM/yyyy",
  "es-NI": "dd/MM/yyyy",
  "es-PA": "MM/dd/yyyy",
  "es-PE": "dd/MM/yyyy",
  "es-PR": "dd/MM/yyyy",
  "es-PY": "dd/MM/yyyy",
  "es-SV": "dd/MM/yyyy",
  "es-US": "MM/dd/yyyy",
  "es-UY": "dd/MM/yyyy",
  "es-VE": "dd/MM/yyyy",
  "et-EE": "d.MM.yyyy",
  "eu-ES": "yyyy/MM/dd",
  "fa-IR": "MM/dd/yyyy",
  "fi-FI": "d.M.yyyy",
  "fil-PH": "M/d/yyyy",
  "fo-FO": "dd-MM-yyyy",
  "fr-BE": "d/MM/yyyy",
  "fr-CA": "yyyy-MM-dd",
  "fr-CH": "dd.MM.yyyy",
  "fr-FR": "dd/MM/yyyy",
  "fr-LU": "dd/MM/yyyy",
  "fr-MC": "dd/MM/yyyy",
  "fy-NL": "d-M-yyyy",
  "ga-IE": "dd/MM/yyyy",
  "gd-GB": "dd/MM/yyyy",
  "gl-ES": "dd/MM/yy",
  "gsw-FR": "dd/MM/yyyy",
  "gu-IN": "dd-MM-yy",
  "ha-Latn-NG": "d/M/yyyy",
  "he-IL": "dd/MM/yyyy",
  "hi-IN": "dd-MM-yyyy",
  "hr-BA": "d.M.yyyy.",
  "hr-HR": "d.M.yyyy",
  "hsb-DE": "d. M. yyyy",
  "hu-HU": "yyyy. MM. dd.",
  "hy-AM": "dd.MM.yyyy",
  "id-ID": "dd/MM/yyyy",
  "ig-NG": "d/M/yyyy",
  "ii-CN": "yyyy/M/d",
  "is-IS": "d.M.yyyy",
  "it-CH": "dd.MM.yyyy",
  "it-IT": "dd/MM/yyyy",
  "iu-Cans-CA": "d/M/yyyy",
  "iu-Latn-CA": "d/MM/yyyy",
  "ja-JP": "yyyy/MM/dd",
  "ka-GE": "dd.MM.yyyy",
  "kk-KZ": "dd.MM.yyyy",
  "kl-GL": "dd-MM-yyyy",
  "km-KH": "yyyy-MM-dd",
  "kn-IN": "dd-MM-yy",
  "ko-KR": "yyyy-MM-dd",
  "kok-IN": "dd-MM-yyyy",
  "ky-KG": "dd.MM.yy",
  "lb-LU": "dd/MM/yyyy",
  "lo-LA": "dd/MM/yyyy",
  "lt-LT": "yyyy.MM.dd",
  "lv-LV": "yyyy.MM.dd.",
  "mi-NZ": "dd/MM/yyyy",
  "mk-MK": "dd.MM.yyyy",
  "ml-IN": "dd-MM-yy",
  "mn-MN": "yy.MM.dd",
  "mn-Mong-CN": "yyyy/M/d",
  "moh-CA": "M/d/yyyy",
  "mr-IN": "dd-MM-yyyy",
  "ms-BN": "dd/MM/yyyy",
  "ms-MY": "dd/MM/yyyy",
  "mt-MT": "dd/MM/yyyy",
  "nb-NO": "dd.MM.yyyy",
  "ne-NP": "M/d/yyyy",
  "nl-BE": "d/MM/yyyy",
  "nl-NL": "d-M-yyyy",
  "nn-NO": "dd.MM.yyyy",
  "nso-ZA": "yyyy/MM/dd",
  "oc-FR": "dd/MM/yyyy",
  "or-IN": "dd-MM-yy",
  "pa-IN": "dd-MM-yy",
  "pl-PL": "yyyy-MM-dd",
  "prs-AF": "dd/MM/yy",
  "ps-AF": "dd/MM/yy",
  "pt-BR": "d/M/yyyy",
  "pt-PT": "dd-MM-yyyy",
  "qut-GT": "dd/MM/yyyy",
  "quz-BO": "dd/MM/yyyy",
  "quz-EC": "dd/MM/yyyy",
  "quz-PE": "dd/MM/yyyy",
  "rm-CH": "dd/MM/yyyy",
  "ro-RO": "dd.MM.yyyy",
  "ru-RU": "dd.MM.yyyy",
  "rw-RW": "M/d/yyyy",
  "sa-IN": "dd-MM-yyyy",
  "sah-RU": "MM.dd.yyyy",
  "se-FI": "d.M.yyyy",
  "se-NO": "dd.MM.yyyy",
  "se-SE": "yyyy-MM-dd",
  "si-LK": "yyyy-MM-dd",
  "sk-SK": "d. M. yyyy",
  "sl-SI": "d.M.yyyy",
  "sma-NO": "dd.MM.yyyy",
  "sma-SE": "yyyy-MM-dd",
  "smj-NO": "dd.MM.yyyy",
  "smj-SE": "yyyy-MM-dd",
  "smn-FI": "d.M.yyyy",
  "sms-FI": "d.M.yyyy",
  "sq-AL": "yyyy-MM-dd",
  "sr-Cyrl-BA": "d.M.yyyy",
  "sr-Cyrl-CS": "d.M.yyyy",
  "sr-Cyrl-ME": "d.M.yyyy",
  "sr-Cyrl-RS": "d.M.yyyy",
  "sr-Latn-BA": "d.M.yyyy",
  "sr-Latn-CS": "d.M.yyyy",
  "sr-Latn-ME": "d.M.yyyy",
  "sr-Latn-RS": "d.M.yyyy",
  "sv-FI": "d.M.yyyy",
  "sv-SE": "yyyy-MM-dd",
  "sw-KE": "M/d/yyyy",
  "syr-SY": "dd/MM/yyyy",
  "ta-IN": "dd-MM-yyyy",
  "te-IN": "dd-MM-yy",
  "tg-Cyrl-TJ": "dd.MM.yy",
  "th-TH": "d/M/yyyy",
  "tk-TM": "dd.MM.yy",
  "tn-ZA": "yyyy/MM/dd",
  "tr-TR": "dd.MM.yyyy",
  "tt-RU": "dd.MM.yyyy",
  "tzm-Latn-DZ": "dd-MM-yyyy",
  "ug-CN": "yyyy-M-d",
  "uk-UA": "dd.MM.yyyy",
  "ur-PK": "dd/MM/yyyy",
  "uz-Cyrl-UZ": "dd.MM.yyyy",
  "uz-Latn-UZ": "dd/MM yyyy",
  "vi-VN": "dd/MM/yyyy",
  "wo-SN": "dd/MM/yyyy",
  "xh-ZA": "yyyy/MM/dd",
  "yo-NG": "d/M/yyyy",
  "zh-CN": "yyyy/M/d",
  "zh-HK": "d/M/yyyy",
  "zh-MO": "d/M/yyyy",
  "zh-SG": "d/M/yyyy",
  "zh-TW": "yyyy/M/d",
  "zu-ZA": "yyyy/MM/dd",
}

export const currencies = [
  { currency: 'United States Dollar', code: 'USD' },
  { currency: 'Afghan Afghani', code: 'AFN' },
  { currency: 'Albanian Lek', code: 'ALL' },
  { currency: 'Algerian Dinar', code: 'DZD' },
  { currency: 'Angolan Kwanza', code: 'AOA' },
  { currency: 'Argentine Peso', code: 'ARS' },
  { currency: 'Armenian Dram', code: 'AMD' },
  { currency: 'Aruban Florin', code: 'AWG' },
  { currency: 'Australian Dollar', code: 'AUD' },
  { currency: 'Azerbaijani Manat', code: 'AZN' },
  { currency: 'Bahamian Dollar', code: 'BSD' },
  { currency: 'Bahraini Dinar', code: 'BHD' },
  { currency: 'Bangladeshi Taka', code: 'BDT' },
  { currency: 'Barbadian Dollar', code: 'BBD' },
  { currency: 'Belarusian Ruble', code: 'BYN' },
  { currency: 'Belize Dollar', code: 'BZD' },
  { currency: 'Bermudian Dollar', code: 'BMD' },
  { currency: 'Bhutanese Ngultrum', code: 'BTN' },
  { currency: 'Bolivian Boliviano', code: 'BOB' },
  { currency: 'Bosnia & Herzegovina Convertible Mark', code: 'BAM' },
  { currency: 'Botswana Pula', code: 'BWP' },
  { currency: 'Brazilian Real', code: 'BRL' },
  { currency: 'British Pound', code: 'GBP' },
  { currency: 'Brunei Dollar', code: 'BND' },
  { currency: 'Bulgarian Lev', code: 'BGN' },
  { currency: 'Burundian Franc', code: 'BIF' },
  { currency: 'Cambodian Riel', code: 'KHR' },
  { currency: 'Canadian Dollar', code: 'CAD' },
  { currency: 'Cape Verdean Escudo', code: 'CVE' },
  { currency: 'Cayman Islands Dollar', code: 'KYD' },
  { currency: 'Central African Cfa Franc', code: 'XAF' },
  { currency: 'Cfp Franc', code: 'XPF' },
  { currency: 'Chilean Peso', code: 'CLP' },
  { currency: 'Chinese Renminbi Yuan', code: 'CNY' },
  { currency: 'Colombian Peso', code: 'COP' },
  { currency: 'Comorian Franc', code: 'KMF' },
  { currency: 'Congolese Franc', code: 'CDF' },
  { currency: 'Costa Rican Colón', code: 'CRC' },
  { currency: 'Croatian Kuna', code: 'HRK' },
  { currency: 'Cuban Peso', code: 'CUP' },
  { currency: 'Czech Koruna', code: 'CZK' },
  { currency: 'Danish Krone', code: 'DKK' },
  { currency: 'Djiboutian Franc', code: 'DJF' },
  { currency: 'Dominican Peso', code: 'DOP' },
  { currency: 'East Caribbean Dollar', code: 'XCD' },
  { currency: 'Egyptian Pound', code: 'EGP' },
  { currency: 'Eritrean Nakfa', code: 'ERN' },
  { currency: 'Ethiopian Birr', code: 'ETB' },
  { currency: 'Euro', code: 'EUR' },
  { currency: 'Falkland Islands Pound', code: 'FKP' },
  { currency: 'Fijian Dollar', code: 'FJD' },
  { currency: 'Gambian Dalasi', code: 'GMD' },
  { currency: 'Georgian Lari', code: 'GEL' },
  { currency: 'Ghanaian Cedi', code: 'GHS' },
  { currency: 'Gibraltar Pound', code: 'GIP' },
  { currency: 'Guatemalan Quetzal', code: 'GTQ' },
  { currency: 'Guinean Franc', code: 'GNF' },
  { currency: 'Guyanese Dollar', code: 'GYD' },
  { currency: 'Haitian Gourde', code: 'HTG' },
  { currency: 'Honduran Lempira', code: 'HNL' },
  { currency: 'Hong Kong Dollar', code: 'HKD' },
  { currency: 'Hungarian Forint', code: 'HUF' },
  { currency: 'Icelandic Króna', code: 'ISK' },
  { currency: 'Indian Rupee', code: 'INR' },
  { currency: 'Indonesian Rupiah', code: 'IDR' },
  { currency: 'Iranian Rial', code: 'IRR' },
  { currency: 'Iraqi Dinar', code: 'IQD' },
  { currency: 'Israeli New Sheqel', code: 'ILS' },
  { currency: 'Jamaican Dollar', code: 'JMD' },
  { currency: 'Japanese Yen', code: 'JPY' },
  { currency: 'Jordanian Dinar', code: 'JOD' },
  { currency: 'Kazakhstani Tenge', code: 'KZT' },
  { currency: 'Kenyan Shilling', code: 'KES' },
  { currency: 'Kuwaiti Dinar', code: 'KWD' },
  { currency: 'Kyrgyzstani Som', code: 'KGS' },
  { currency: 'Lao Kip', code: 'LAK' },
  { currency: 'Lebanese Pound', code: 'LBP' },
  { currency: 'Lesotho Loti', code: 'LSL' },
  { currency: 'Liberian Dollar', code: 'LRD' },
  { currency: 'Libyan Dinar', code: 'LYD' },
  { currency: 'Macanese Pataca', code: 'MOP' },
  { currency: 'Macedonian Denar', code: 'MKD' },
  { currency: 'Malagasy Ariary', code: 'MGA' },
  { currency: 'Malawian Kwacha', code: 'MWK' },
  { currency: 'Malaysian Ringgit', code: 'MYR' },
  { currency: 'Maldivian Rufiyaa', code: 'MVR' },
  { currency: 'Mauritanian Ouguiya', code: 'MRU' },
  { currency: 'Mauritian Rupee', code: 'MUR' },
  { currency: 'Mexican Peso', code: 'MXN' },
  { currency: 'Moldovan Leu', code: 'MDL' },
  { currency: 'Mongolian Tögrög', code: 'MNT' },
  { currency: 'Moroccan Dirham', code: 'MAD' },
  { currency: 'Mozambican Metical', code: 'MZN' },
  { currency: 'Myanmar Kyat', code: 'MMK' },
  { currency: 'Namibian Dollar', code: 'NAD' },
  { currency: 'Nepalese Rupee', code: 'NPR' },
  { currency: 'Netherlands Antillean Gulden', code: 'ANG' },
  { currency: 'New Taiwan Dollar', code: 'TWD' },
  { currency: 'New Zealand Dollar', code: 'NZD' },
  { currency: 'Nicaraguan Córdoba', code: 'NIO' },
  { currency: 'Nigerian Naira', code: 'NGN' },
  { currency: 'North Korean Won', code: 'KPW' },
  { currency: 'Norwegian Krone', code: 'NOK' },
  { currency: 'Omani Rial', code: 'OMR' },
  { currency: 'Pakistani Rupee', code: 'PKR' },
  { currency: 'Panamanian Balboa', code: 'PAB' },
  { currency: 'Papua New Guinean Kina', code: 'PGK' },
  { currency: 'Paraguayan Guaraní', code: 'PYG' },
  { currency: 'Peruvian Nuevo Sol', code: 'PEN' },
  { currency: 'Philippine Peso', code: 'PHP' },
  { currency: 'Polish Złoty', code: 'PLN' },
  { currency: 'Qatari Riyal', code: 'QAR' },
  { currency: 'Romanian Leu', code: 'RON' },
  { currency: 'Russian Ruble', code: 'RUB' },
  { currency: 'Rwandan Franc', code: 'RWF' },
  { currency: 'São Tomé and Príncipe Dobra', code: 'STN' },
  { currency: 'Saint Helenian Pound', code: 'SHP' },
  { currency: 'Salvadoran Colón', code: 'SVC' },
  { currency: 'Samoan Tala', code: 'WST' },
  { currency: 'Saudi Riyal', code: 'SAR' },
  { currency: 'Serbian Dinar', code: 'RSD' },
  { currency: 'Seychellois Rupee', code: 'SCR' },
  { currency: 'Sierra Leonean Leone', code: 'SLL' },
  { currency: 'Singapore Dollar', code: 'SGD' },
  { currency: 'Solomon Islands Dollar', code: 'SBD' },
  { currency: 'Somali Shilling', code: 'SOS' },
  { currency: 'South African Rand', code: 'ZAR' },
  { currency: 'South Korean Won', code: 'KRW' },
  { currency: 'South Sudanese Pound', code: 'SSP' },
  { currency: 'Sri Lankan Rupee', code: 'LKR' },
  { currency: 'Sudanese Pound', code: 'SDG' },
  { currency: 'Surinamese Dollar', code: 'SRD' },
  { currency: 'Swazi Lilangeni', code: 'SZL' },
  { currency: 'Swedish Krona', code: 'SEK' },
  { currency: 'Swiss Franc', code: 'CHF' },
  { currency: 'Syrian Pound', code: 'SYP' },
  { currency: 'Tajikistani Somoni', code: 'TJS' },
  { currency: 'Tanzanian Shilling', code: 'TZS' },
  { currency: 'Thai Baht', code: 'THB' },
  { currency: 'Tongan Paʻanga', code: 'TOP' },
  { currency: 'Trinidad and Tobago Dollar', code: 'TTD' },
  { currency: 'Tunisian Dinar', code: 'TND' },
  { currency: 'Turkish Lira', code: 'TRY' },
  { currency: 'Turkmenistan Manat', code: 'TMT' },
  { currency: 'Ugandan Shilling', code: 'UGX' },
  { currency: 'Ukrainian Hryvnia', code: 'UAH' },
  { currency: 'United Arab Emirates Dirham', code: 'AED' },
  { currency: 'Uruguayan Peso', code: 'UYU' },
  { currency: 'Uzbekistani Som', code: 'UZS' },
  { currency: 'Vanuatu Vatu', code: 'VUV' },
  { currency: 'Venezuelan Bolívar', code: 'VES' },
  { currency: 'Vietnamese Đồng', code: 'VND' },
  { currency: 'West African Cfa Franc', code: 'XOF' },
  { currency: 'Yemeni Rial', code: 'YER' },
  { currency: 'Zambian Kwacha', code: 'ZMW' },
]

export const currencyItems: ReactElement[] = []
for (const currency of currencies) {
  currencyItems.push(<MenuItem key={ "Currency-" + currency.code} value={currency.code}>{currency.currency}</MenuItem>)
}

export const currencySymbols = [
  { "cc":"AED","symbol":"\u062f.\u0625;","name":"UAE dirham" },
  { "cc":"AFN","symbol":"Afs","name":"Afghan afghani" },
  { "cc":"ALL","symbol":"L","name":"Albanian lek" },
  { "cc":"AMD","symbol":"AMD","name":"Armenian dram" },
  { "cc":"ANG","symbol":"NA\u0192","name":"Netherlands Antillean gulden" },
  { "cc":"AOA","symbol":"Kz","name":"Angolan kwanza" },
  { "cc":"ARS","symbol":"$","name":"Argentine peso" },
  { "cc":"AUD","symbol":"$","name":"Australian dollar" },
  { "cc":"AWG","symbol":"\u0192","name":"Aruban florin" },
  { "cc":"AZN","symbol":"AZN","name":"Azerbaijani manat" },
  { "cc":"BAM","symbol":"KM","name":"Bosnia and Herzegovina konvertibilna marka" },
  { "cc":"BBD","symbol":"Bds$","name":"Barbadian dollar" },
  { "cc":"BDT","symbol":"\u09f3","name":"Bangladeshi taka" },
  { "cc":"BGN","symbol":"BGN","name":"Bulgarian lev" },
  { "cc":"BHD","symbol":".\u062f.\u0628","name":"Bahraini dinar" },
  { "cc":"BIF","symbol":"FBu","name":"Burundi franc" },
  { "cc":"BMD","symbol":"BD$","name":"Bermudian dollar" },
  { "cc":"BND","symbol":"B$","name":"Brunei dollar" },
  { "cc":"BOB","symbol":"Bs.","name":"Bolivian boliviano" },
  { "cc":"BRL","symbol":"R$","name":"Brazilian real" },
  { "cc":"BSD","symbol":"B$","name":"Bahamian dollar" },
  { "cc":"BTN","symbol":"Nu.","name":"Bhutanese ngultrum" },
  { "cc":"BWP","symbol":"P","name":"Botswana pula" },
  { "cc":"BYR","symbol":"Br","name":"Belarusian ruble" },
  { "cc":"BZD","symbol":"BZ$","name":"Belize dollar" },
  { "cc":"CAD","symbol":"$","name":"Canadian dollar" },
  { "cc":"CDF","symbol":"F","name":"Congolese franc" },
  { "cc":"CHF","symbol":"Fr.","name":"Swiss franc" },
  { "cc":"CLP","symbol":"$","name":"Chilean peso" },
  { "cc":"CNY","symbol":"\u00a5","name":"Chinese/Yuan renminbi" },
  { "cc":"COP","symbol":"Col$","name":"Colombian peso" },
  { "cc":"CRC","symbol":"\u20a1","name":"Costa Rican colon" },
  { "cc":"CUC","symbol":"$","name":"Cuban peso" },
  { "cc":"CVE","symbol":"Esc","name":"Cape Verdean escudo" },
  { "cc":"CZK","symbol":"K\u010d","name":"Czech koruna" },
  { "cc":"DJF","symbol":"Fdj","name":"Djiboutian franc" },
  { "cc":"DKK","symbol":"Kr","name":"Danish krone" },
  { "cc":"DOP","symbol":"RD$","name":"Dominican peso" },
  { "cc":"DZD","symbol":"\u062f.\u062c","name":"Algerian dinar" },
  { "cc":"EEK","symbol":"KR","name":"Estonian kroon" },
  { "cc":"EGP","symbol":"\u00a3","name":"Egyptian pound" },
  { "cc":"ERN","symbol":"Nfa","name":"Eritrean nakfa" },
  { "cc":"ETB","symbol":"Br","name":"Ethiopian birr" },
  { "cc":"EUR","symbol":"\u20ac","name":"European Euro" },
  { "cc":"FJD","symbol":"FJ$","name":"Fijian dollar" },
  { "cc":"FKP","symbol":"\u00a3","name":"Falkland Islands pound" },
  { "cc":"GBP","symbol":"\u00a3","name":"British pound" },
  { "cc":"GEL","symbol":"GEL","name":"Georgian lari" },
  { "cc":"GHS","symbol":"GH\u20b5","name":"Ghanaian cedi" },
  { "cc":"GIP","symbol":"\u00a3","name":"Gibraltar pound" },
  { "cc":"GMD","symbol":"D","name":"Gambian dalasi" },
  { "cc":"GNF","symbol":"FG","name":"Guinean franc" },
  { "cc":"GQE","symbol":"CFA","name":"Central African CFA franc" },
  { "cc":"GTQ","symbol":"Q","name":"Guatemalan quetzal" },
  { "cc":"GYD","symbol":"GY$","name":"Guyanese dollar" },
  { "cc":"HKD","symbol":"HK$","name":"Hong Kong dollar" },
  { "cc":"HNL","symbol":"L","name":"Honduran lempira" },
  { "cc":"HRK","symbol":"kn","name":"Croatian kuna" },
  { "cc":"HTG","symbol":"G","name":"Haitian gourde" },
  { "cc":"HUF","symbol":"Ft","name":"Hungarian forint" },
  { "cc":"IDR","symbol":"Rp","name":"Indonesian rupiah" },
  { "cc":"ILS","symbol":"\u20aa","name":"Israeli new sheqel" },
  { "cc":"INR","symbol":"\u20B9","name":"Indian rupee" },
  { "cc":"IQD","symbol":"\u062f.\u0639","name":"Iraqi dinar" },
  { "cc":"IRR","symbol":"IRR","name":"Iranian rial" },
  { "cc":"ISK","symbol":"kr","name":"Icelandic kr\u00f3na" },
  { "cc":"JMD","symbol":"J$","name":"Jamaican dollar" },
  { "cc":"JOD","symbol":"JOD","name":"Jordanian dinar" },
  { "cc":"JPY","symbol":"\u00a5","name":"Japanese yen" },
  { "cc":"KES","symbol":"KSh","name":"Kenyan shilling" },
  { "cc":"KGS","symbol":"\u0441\u043e\u043c","name":"Kyrgyzstani som" },
  { "cc":"KHR","symbol":"\u17db","name":"Cambodian riel" },
  { "cc":"KMF","symbol":"KMF","name":"Comorian franc" },
  { "cc":"KPW","symbol":"W","name":"North Korean won" },
  { "cc":"KRW","symbol":"W","name":"South Korean won" },
  { "cc":"KWD","symbol":"KWD","name":"Kuwaiti dinar" },
  { "cc":"KYD","symbol":"KY$","name":"Cayman Islands dollar" },
  { "cc":"KZT","symbol":"T","name":"Kazakhstani tenge" },
  { "cc":"LAK","symbol":"KN","name":"Lao kip" },
  { "cc":"LBP","symbol":"\u00a3","name":"Lebanese lira" },
  { "cc":"LKR","symbol":"Rs","name":"Sri Lankan rupee" },
  { "cc":"LRD","symbol":"L$","name":"Liberian dollar" },
  { "cc":"LSL","symbol":"M","name":"Lesotho loti" },
  { "cc":"LTL","symbol":"Lt","name":"Lithuanian litas" },
  { "cc":"LVL","symbol":"Ls","name":"Latvian lats" },
  { "cc":"LYD","symbol":"LD","name":"Libyan dinar" },
  { "cc":"MAD","symbol":"MAD","name":"Moroccan dirham" },
  { "cc":"MDL","symbol":"MDL","name":"Moldovan leu" },
  { "cc":"MGA","symbol":"FMG","name":"Malagasy ariary" },
  { "cc":"MKD","symbol":"MKD","name":"Macedonian denar" },
  { "cc":"MMK","symbol":"K","name":"Myanma kyat" },
  { "cc":"MNT","symbol":"\u20ae","name":"Mongolian tugrik" },
  { "cc":"MOP","symbol":"P","name":"Macanese pataca" },
  { "cc":"MRO","symbol":"UM","name":"Mauritanian ouguiya" },
  { "cc":"MUR","symbol":"Rs","name":"Mauritian rupee" },
  { "cc":"MVR","symbol":"Rf","name":"Maldivian rufiyaa" },
  { "cc":"MWK","symbol":"MK","name":"Malawian kwacha" },
  { "cc":"MXN","symbol":"$","name":"Mexican peso" },
  { "cc":"MYR","symbol":"RM","name":"Malaysian ringgit" },
  { "cc":"MZM","symbol":"MTn","name":"Mozambican metical" },
  { "cc":"NAD","symbol":"N$","name":"Namibian dollar" },
  { "cc":"NGN","symbol":"\u20a6","name":"Nigerian naira" },
  { "cc":"NIO","symbol":"C$","name":"Nicaraguan c\u00f3rdoba" },
  { "cc":"NOK","symbol":"kr","name":"Norwegian krone" },
  { "cc":"NPR","symbol":"NRs","name":"Nepalese rupee" },
  { "cc":"NZD","symbol":"NZ$","name":"New Zealand dollar" },
  { "cc":"OMR","symbol":"OMR","name":"Omani rial" },
  { "cc":"PAB","symbol":"B./","name":"Panamanian balboa" },
  { "cc":"PEN","symbol":"S/.","name":"Peruvian nuevo sol" },
  { "cc":"PGK","symbol":"K","name":"Papua New Guinean kina" },
  { "cc":"PHP","symbol":"\u20b1","name":"Philippine peso" },
  { "cc":"PKR","symbol":"Rs.","name":"Pakistani rupee" },
  { "cc":"PLN","symbol":"z\u0142","name":"Polish zloty" },
  { "cc":"PYG","symbol":"\u20b2","name":"Paraguayan guarani" },
  { "cc":"QAR","symbol":"QR","name":"Qatari riyal" },
  { "cc":"RON","symbol":"L","name":"Romanian leu" },
  { "cc":"RSD","symbol":"din.","name":"Serbian dinar" },
  { "cc":"RUB","symbol":"R","name":"Russian ruble" },
  { "cc":"SAR","symbol":"SR","name":"Saudi riyal" },
  { "cc":"SBD","symbol":"SI$","name":"Solomon Islands dollar" },
  { "cc":"SCR","symbol":"SR","name":"Seychellois rupee" },
  { "cc":"SDG","symbol":"SDG","name":"Sudanese pound" },
  { "cc":"SEK","symbol":"kr","name":"Swedish krona" },
  { "cc":"SGD","symbol":"S$","name":"Singapore dollar" },
  { "cc":"SHP","symbol":"\u00a3","name":"Saint Helena pound" },
  { "cc":"SLL","symbol":"Le","name":"Sierra Leonean leone" },
  { "cc":"SOS","symbol":"Sh.","name":"Somali shilling" },
  { "cc":"SRD","symbol":"$","name":"Surinamese dollar" },
  { "cc":"SYP","symbol":"LS","name":"Syrian pound" },
  { "cc":"SZL","symbol":"E","name":"Swazi lilangeni" },
  { "cc":"THB","symbol":"\u0e3f","name":"Thai baht" },
  { "cc":"TJS","symbol":"TJS","name":"Tajikistani somoni" },
  { "cc":"TMT","symbol":"m","name":"Turkmen manat" },
  { "cc":"TND","symbol":"DT","name":"Tunisian dinar" },
  { "cc":"TRY","symbol":"TRY","name":"Turkish new lira" },
  { "cc":"TTD","symbol":"TT$","name":"Trinidad and Tobago dollar" },
  { "cc":"TWD","symbol":"NT$","name":"New Taiwan dollar" },
  { "cc":"TZS","symbol":"TZS","name":"Tanzanian shilling" },
  { "cc":"UAH","symbol":"UAH","name":"Ukrainian hryvnia" },
  { "cc":"UGX","symbol":"USh","name":"Ugandan shilling" },
  { "cc":"USD","symbol":"US$","name":"United States dollar" },
  { "cc":"UYU","symbol":"$U","name":"Uruguayan peso" },
  { "cc":"UZS","symbol":"UZS","name":"Uzbekistani som" },
  { "cc":"VEB","symbol":"Bs","name":"Venezuelan bolivar" },
  { "cc":"VND","symbol":"\u20ab","name":"Vietnamese dong" },
  { "cc":"VUV","symbol":"VT","name":"Vanuatu vatu" },
  { "cc":"WST","symbol":"WS$","name":"Samoan tala" },
  { "cc":"XAF","symbol":"CFA","name":"Central African CFA franc" },
  { "cc":"XCD","symbol":"EC$","name":"East Caribbean dollar" },
  { "cc":"XDR","symbol":"SDR","name":"Special Drawing Rights" },
  { "cc":"XOF","symbol":"CFA","name":"West African CFA franc" },
  { "cc":"XPF","symbol":"F","name":"CFP franc" },
  { "cc":"YER","symbol":"YER","name":"Yemeni rial" },
  { "cc":"ZAR","symbol":"R","name":"South African rand" },
  { "cc":"ZMK","symbol":"ZK","name":"Zambian kwacha" },
  { "cc":"ZWR","symbol":"Z$","name":"Zimbabwean dollar" }
]

export const yetAnotherCurrencyList = {
  "USD": {
    "symbol": "$",
    "name": "US Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "USD",
    "name_plural": "US dollars"
  },
  "CAD": {
    "symbol": "CA$",
    "name": "Canadian Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CAD",
    "name_plural": "Canadian dollars"
  },
  "EUR": {
    "symbol": "€",
    "name": "Euro",
    "symbol_native": "€",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "EUR",
    "name_plural": "euros"
  },
  "AED": {
    "symbol": "AED",
    "name": "United Arab Emirates Dirham",
    "symbol_native": "د.إ.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "AED",
    "name_plural": "UAE dirhams"
  },
  "AFN": {
    "symbol": "Af",
    "name": "Afghan Afghani",
    "symbol_native": "؋",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "AFN",
    "name_plural": "Afghan Afghanis"
  },
  "ALL": {
    "symbol": "ALL",
    "name": "Albanian Lek",
    "symbol_native": "Lek",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ALL",
    "name_plural": "Albanian lekë"
  },
  "AMD": {
    "symbol": "AMD",
    "name": "Armenian Dram",
    "symbol_native": "դր.",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "AMD",
    "name_plural": "Armenian drams"
  },
  "ARS": {
    "symbol": "AR$",
    "name": "Argentine Peso",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ARS",
    "name_plural": "Argentine pesos"
  },
  "AUD": {
    "symbol": "AU$",
    "name": "Australian Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "AUD",
    "name_plural": "Australian dollars"
  },
  "AZN": {
    "symbol": "man.",
    "name": "Azerbaijani Manat",
    "symbol_native": "ман.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "AZN",
    "name_plural": "Azerbaijani manats"
  },
  "BAM": {
    "symbol": "KM",
    "name": "Bosnia-Herzegovina Convertible Mark",
    "symbol_native": "KM",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BAM",
    "name_plural": "Bosnia-Herzegovina convertible marks"
  },
  "BDT": {
    "symbol": "Tk",
    "name": "Bangladeshi Taka",
    "symbol_native": "৳",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BDT",
    "name_plural": "Bangladeshi takas"
  },
  "BGN": {
    "symbol": "BGN",
    "name": "Bulgarian Lev",
    "symbol_native": "лв.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BGN",
    "name_plural": "Bulgarian leva"
  },
  "BHD": {
    "symbol": "BD",
    "name": "Bahraini Dinar",
    "symbol_native": "د.ب.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "BHD",
    "name_plural": "Bahraini dinars"
  },
  "BIF": {
    "symbol": "FBu",
    "name": "Burundian Franc",
    "symbol_native": "FBu",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "BIF",
    "name_plural": "Burundian francs"
  },
  "BND": {
    "symbol": "BN$",
    "name": "Brunei Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BND",
    "name_plural": "Brunei dollars"
  },
  "BOB": {
    "symbol": "Bs",
    "name": "Bolivian Boliviano",
    "symbol_native": "Bs",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BOB",
    "name_plural": "Bolivian bolivianos"
  },
  "BRL": {
    "symbol": "R$",
    "name": "Brazilian Real",
    "symbol_native": "R$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BRL",
    "name_plural": "Brazilian reals"
  },
  "BWP": {
    "symbol": "BWP",
    "name": "Botswanan Pula",
    "symbol_native": "P",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BWP",
    "name_plural": "Botswanan pulas"
  },
  "BYN": {
    "symbol": "Br",
    "name": "Belarusian Ruble",
    "symbol_native": "руб.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BYN",
    "name_plural": "Belarusian rubles"
  },
  "BZD": {
    "symbol": "BZ$",
    "name": "Belize Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BZD",
    "name_plural": "Belize dollars"
  },
  "CDF": {
    "symbol": "CDF",
    "name": "Congolese Franc",
    "symbol_native": "FrCD",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CDF",
    "name_plural": "Congolese francs"
  },
  "CHF": {
    "symbol": "CHF",
    "name": "Swiss Franc",
    "symbol_native": "CHF",
    "decimal_digits": 2,
    "rounding": 0.05,
    "code": "CHF",
    "name_plural": "Swiss francs"
  },
  "CLP": {
    "symbol": "CL$",
    "name": "Chilean Peso",
    "symbol_native": "$",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "CLP",
    "name_plural": "Chilean pesos"
  },
  "CNY": {
    "symbol": "CN¥",
    "name": "Chinese Yuan",
    "symbol_native": "CN¥",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CNY",
    "name_plural": "Chinese yuan"
  },
  "COP": {
    "symbol": "CO$",
    "name": "Colombian Peso",
    "symbol_native": "$",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "COP",
    "name_plural": "Colombian pesos"
  },
  "CRC": {
    "symbol": "₡",
    "name": "Costa Rican Colón",
    "symbol_native": "₡",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "CRC",
    "name_plural": "Costa Rican colóns"
  },
  "CVE": {
    "symbol": "CV$",
    "name": "Cape Verdean Escudo",
    "symbol_native": "CV$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CVE",
    "name_plural": "Cape Verdean escudos"
  },
  "CZK": {
    "symbol": "Kč",
    "name": "Czech Republic Koruna",
    "symbol_native": "Kč",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CZK",
    "name_plural": "Czech Republic korunas"
  },
  "DJF": {
    "symbol": "Fdj",
    "name": "Djiboutian Franc",
    "symbol_native": "Fdj",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "DJF",
    "name_plural": "Djiboutian francs"
  },
  "DKK": {
    "symbol": "Dkr",
    "name": "Danish Krone",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "DKK",
    "name_plural": "Danish kroner"
  },
  "DOP": {
    "symbol": "RD$",
    "name": "Dominican Peso",
    "symbol_native": "RD$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "DOP",
    "name_plural": "Dominican pesos"
  },
  "DZD": {
    "symbol": "DA",
    "name": "Algerian Dinar",
    "symbol_native": "د.ج.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "DZD",
    "name_plural": "Algerian dinars"
  },
  "EEK": {
    "symbol": "Ekr",
    "name": "Estonian Kroon",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "EEK",
    "name_plural": "Estonian kroons"
  },
  "EGP": {
    "symbol": "EGP",
    "name": "Egyptian Pound",
    "symbol_native": "ج.م.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "EGP",
    "name_plural": "Egyptian pounds"
  },
  "ERN": {
    "symbol": "Nfk",
    "name": "Eritrean Nakfa",
    "symbol_native": "Nfk",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ERN",
    "name_plural": "Eritrean nakfas"
  },
  "ETB": {
    "symbol": "Br",
    "name": "Ethiopian Birr",
    "symbol_native": "Br",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ETB",
    "name_plural": "Ethiopian birrs"
  },
  "GBP": {
    "symbol": "£",
    "name": "British Pound Sterling",
    "symbol_native": "£",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GBP",
    "name_plural": "British pounds sterling"
  },
  "GEL": {
    "symbol": "GEL",
    "name": "Georgian Lari",
    "symbol_native": "GEL",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GEL",
    "name_plural": "Georgian laris"
  },
  "GHS": {
    "symbol": "GH₵",
    "name": "Ghanaian Cedi",
    "symbol_native": "GH₵",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GHS",
    "name_plural": "Ghanaian cedis"
  },
  "GNF": {
    "symbol": "FG",
    "name": "Guinean Franc",
    "symbol_native": "FG",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "GNF",
    "name_plural": "Guinean francs"
  },
  "GTQ": {
    "symbol": "GTQ",
    "name": "Guatemalan Quetzal",
    "symbol_native": "Q",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GTQ",
    "name_plural": "Guatemalan quetzals"
  },
  "HKD": {
    "symbol": "HK$",
    "name": "Hong Kong Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "HKD",
    "name_plural": "Hong Kong dollars"
  },
  "HNL": {
    "symbol": "HNL",
    "name": "Honduran Lempira",
    "symbol_native": "L",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "HNL",
    "name_plural": "Honduran lempiras"
  },
  "HRK": {
    "symbol": "kn",
    "name": "Croatian Kuna",
    "symbol_native": "kn",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "HRK",
    "name_plural": "Croatian kunas"
  },
  "HUF": {
    "symbol": "Ft",
    "name": "Hungarian Forint",
    "symbol_native": "Ft",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "HUF",
    "name_plural": "Hungarian forints"
  },
  "IDR": {
    "symbol": "Rp",
    "name": "Indonesian Rupiah",
    "symbol_native": "Rp",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "IDR",
    "name_plural": "Indonesian rupiahs"
  },
  "ILS": {
    "symbol": "₪",
    "name": "Israeli New Sheqel",
    "symbol_native": "₪",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ILS",
    "name_plural": "Israeli new sheqels"
  },
  "INR": {
    "symbol": "Rs",
    "name": "Indian Rupee",
    "symbol_native": "টকা",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "INR",
    "name_plural": "Indian rupees"
  },
  "IQD": {
    "symbol": "IQD",
    "name": "Iraqi Dinar",
    "symbol_native": "د.ع.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "IQD",
    "name_plural": "Iraqi dinars"
  },
  "IRR": {
    "symbol": "IRR",
    "name": "Iranian Rial",
    "symbol_native": "﷼",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "IRR",
    "name_plural": "Iranian rials"
  },
  "ISK": {
    "symbol": "Ikr",
    "name": "Icelandic Króna",
    "symbol_native": "kr",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ISK",
    "name_plural": "Icelandic krónur"
  },
  "JMD": {
    "symbol": "J$",
    "name": "Jamaican Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "JMD",
    "name_plural": "Jamaican dollars"
  },
  "JOD": {
    "symbol": "JD",
    "name": "Jordanian Dinar",
    "symbol_native": "د.أ.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "JOD",
    "name_plural": "Jordanian dinars"
  },
  "JPY": {
    "symbol": "¥",
    "name": "Japanese Yen",
    "symbol_native": "￥",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "JPY",
    "name_plural": "Japanese yen"
  },
  "KES": {
    "symbol": "Ksh",
    "name": "Kenyan Shilling",
    "symbol_native": "Ksh",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "KES",
    "name_plural": "Kenyan shillings"
  },
  "KHR": {
    "symbol": "KHR",
    "name": "Cambodian Riel",
    "symbol_native": "៛",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "KHR",
    "name_plural": "Cambodian riels"
  },
  "KMF": {
    "symbol": "CF",
    "name": "Comorian Franc",
    "symbol_native": "FC",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "KMF",
    "name_plural": "Comorian francs"
  },
  "KRW": {
    "symbol": "₩",
    "name": "South Korean Won",
    "symbol_native": "₩",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "KRW",
    "name_plural": "South Korean won"
  },
  "KWD": {
    "symbol": "KD",
    "name": "Kuwaiti Dinar",
    "symbol_native": "د.ك.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "KWD",
    "name_plural": "Kuwaiti dinars"
  },
  "KZT": {
    "symbol": "KZT",
    "name": "Kazakhstani Tenge",
    "symbol_native": "тңг.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "KZT",
    "name_plural": "Kazakhstani tenges"
  },
  "LBP": {
    "symbol": "LB£",
    "name": "Lebanese Pound",
    "symbol_native": "ل.ل.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "LBP",
    "name_plural": "Lebanese pounds"
  },
  "LKR": {
    "symbol": "SLRs",
    "name": "Sri Lankan Rupee",
    "symbol_native": "SL Re",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "LKR",
    "name_plural": "Sri Lankan rupees"
  },
  "LTL": {
    "symbol": "Lt",
    "name": "Lithuanian Litas",
    "symbol_native": "Lt",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "LTL",
    "name_plural": "Lithuanian litai"
  },
  "LVL": {
    "symbol": "Ls",
    "name": "Latvian Lats",
    "symbol_native": "Ls",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "LVL",
    "name_plural": "Latvian lati"
  },
  "LYD": {
    "symbol": "LD",
    "name": "Libyan Dinar",
    "symbol_native": "د.ل.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "LYD",
    "name_plural": "Libyan dinars"
  },
  "MAD": {
    "symbol": "MAD",
    "name": "Moroccan Dirham",
    "symbol_native": "د.م.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MAD",
    "name_plural": "Moroccan dirhams"
  },
  "MDL": {
    "symbol": "MDL",
    "name": "Moldovan Leu",
    "symbol_native": "MDL",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MDL",
    "name_plural": "Moldovan lei"
  },
  "MGA": {
    "symbol": "MGA",
    "name": "Malagasy Ariary",
    "symbol_native": "MGA",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "MGA",
    "name_plural": "Malagasy Ariaries"
  },
  "MKD": {
    "symbol": "MKD",
    "name": "Macedonian Denar",
    "symbol_native": "MKD",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MKD",
    "name_plural": "Macedonian denari"
  },
  "MMK": {
    "symbol": "MMK",
    "name": "Myanma Kyat",
    "symbol_native": "K",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "MMK",
    "name_plural": "Myanma kyats"
  },
  "MOP": {
    "symbol": "MOP$",
    "name": "Macanese Pataca",
    "symbol_native": "MOP$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MOP",
    "name_plural": "Macanese patacas"
  },
  "MUR": {
    "symbol": "MURs",
    "name": "Mauritian Rupee",
    "symbol_native": "MURs",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "MUR",
    "name_plural": "Mauritian rupees"
  },
  "MXN": {
    "symbol": "MX$",
    "name": "Mexican Peso",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MXN",
    "name_plural": "Mexican pesos"
  },
  "MYR": {
    "symbol": "RM",
    "name": "Malaysian Ringgit",
    "symbol_native": "RM",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MYR",
    "name_plural": "Malaysian ringgits"
  },
  "MZN": {
    "symbol": "MTn",
    "name": "Mozambican Metical",
    "symbol_native": "MTn",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MZN",
    "name_plural": "Mozambican meticals"
  },
  "NAD": {
    "symbol": "N$",
    "name": "Namibian Dollar",
    "symbol_native": "N$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NAD",
    "name_plural": "Namibian dollars"
  },
  "NGN": {
    "symbol": "₦",
    "name": "Nigerian Naira",
    "symbol_native": "₦",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NGN",
    "name_plural": "Nigerian nairas"
  },
  "NIO": {
    "symbol": "C$",
    "name": "Nicaraguan Córdoba",
    "symbol_native": "C$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NIO",
    "name_plural": "Nicaraguan córdobas"
  },
  "NOK": {
    "symbol": "Nkr",
    "name": "Norwegian Krone",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NOK",
    "name_plural": "Norwegian kroner"
  },
  "NPR": {
    "symbol": "NPRs",
    "name": "Nepalese Rupee",
    "symbol_native": "नेरू",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NPR",
    "name_plural": "Nepalese rupees"
  },
  "NZD": {
    "symbol": "NZ$",
    "name": "New Zealand Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NZD",
    "name_plural": "New Zealand dollars"
  },
  "OMR": {
    "symbol": "OMR",
    "name": "Omani Rial",
    "symbol_native": "ر.ع.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "OMR",
    "name_plural": "Omani rials"
  },
  "PAB": {
    "symbol": "B/.",
    "name": "Panamanian Balboa",
    "symbol_native": "B/.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PAB",
    "name_plural": "Panamanian balboas"
  },
  "PEN": {
    "symbol": "S/.",
    "name": "Peruvian Nuevo Sol",
    "symbol_native": "S/.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PEN",
    "name_plural": "Peruvian nuevos soles"
  },
  "PHP": {
    "symbol": "₱",
    "name": "Philippine Peso",
    "symbol_native": "₱",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PHP",
    "name_plural": "Philippine pesos"
  },
  "PKR": {
    "symbol": "PKRs",
    "name": "Pakistani Rupee",
    "symbol_native": "₨",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "PKR",
    "name_plural": "Pakistani rupees"
  },
  "PLN": {
    "symbol": "zł",
    "name": "Polish Zloty",
    "symbol_native": "zł",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PLN",
    "name_plural": "Polish zlotys"
  },
  "PYG": {
    "symbol": "₲",
    "name": "Paraguayan Guarani",
    "symbol_native": "₲",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "PYG",
    "name_plural": "Paraguayan guaranis"
  },
  "QAR": {
    "symbol": "QR",
    "name": "Qatari Rial",
    "symbol_native": "ر.ق.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "QAR",
    "name_plural": "Qatari rials"
  },
  "RON": {
    "symbol": "RON",
    "name": "Romanian Leu",
    "symbol_native": "RON",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "RON",
    "name_plural": "Romanian lei"
  },
  "RSD": {
    "symbol": "din.",
    "name": "Serbian Dinar",
    "symbol_native": "дин.",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "RSD",
    "name_plural": "Serbian dinars"
  },
  "RUB": {
    "symbol": "RUB",
    "name": "Russian Ruble",
    "symbol_native": "₽.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "RUB",
    "name_plural": "Russian rubles"
  },
  "RWF": {
    "symbol": "RWF",
    "name": "Rwandan Franc",
    "symbol_native": "FR",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "RWF",
    "name_plural": "Rwandan francs"
  },
  "SAR": {
    "symbol": "SR",
    "name": "Saudi Riyal",
    "symbol_native": "ر.س.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SAR",
    "name_plural": "Saudi riyals"
  },
  "SDG": {
    "symbol": "SDG",
    "name": "Sudanese Pound",
    "symbol_native": "SDG",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SDG",
    "name_plural": "Sudanese pounds"
  },
  "SEK": {
    "symbol": "Skr",
    "name": "Swedish Krona",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SEK",
    "name_plural": "Swedish kronor"
  },
  "SGD": {
    "symbol": "S$",
    "name": "Singapore Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SGD",
    "name_plural": "Singapore dollars"
  },
  "SOS": {
    "symbol": "Ssh",
    "name": "Somali Shilling",
    "symbol_native": "Ssh",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "SOS",
    "name_plural": "Somali shillings"
  },
  "SYP": {
    "symbol": "SY£",
    "name": "Syrian Pound",
    "symbol_native": "ل.س.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "SYP",
    "name_plural": "Syrian pounds"
  },
  "THB": {
    "symbol": "฿",
    "name": "Thai Baht",
    "symbol_native": "฿",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "THB",
    "name_plural": "Thai baht"
  },
  "TND": {
    "symbol": "DT",
    "name": "Tunisian Dinar",
    "symbol_native": "د.ت.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "TND",
    "name_plural": "Tunisian dinars"
  },
  "TOP": {
    "symbol": "T$",
    "name": "Tongan Paʻanga",
    "symbol_native": "T$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TOP",
    "name_plural": "Tongan paʻanga"
  },
  "TRY": {
    "symbol": "TL",
    "name": "Turkish Lira",
    "symbol_native": "TL",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TRY",
    "name_plural": "Turkish Lira"
  },
  "TTD": {
    "symbol": "TT$",
    "name": "Trinidad and Tobago Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TTD",
    "name_plural": "Trinidad and Tobago dollars"
  },
  "TWD": {
    "symbol": "NT$",
    "name": "New Taiwan Dollar",
    "symbol_native": "NT$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TWD",
    "name_plural": "New Taiwan dollars"
  },
  "TZS": {
    "symbol": "TSh",
    "name": "Tanzanian Shilling",
    "symbol_native": "TSh",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "TZS",
    "name_plural": "Tanzanian shillings"
  },
  "UAH": {
    "symbol": "₴",
    "name": "Ukrainian Hryvnia",
    "symbol_native": "₴",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "UAH",
    "name_plural": "Ukrainian hryvnias"
  },
  "UGX": {
    "symbol": "USh",
    "name": "Ugandan Shilling",
    "symbol_native": "USh",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "UGX",
    "name_plural": "Ugandan shillings"
  },
  "UYU": {
    "symbol": "$U",
    "name": "Uruguayan Peso",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "UYU",
    "name_plural": "Uruguayan pesos"
  },
  "UZS": {
    "symbol": "UZS",
    "name": "Uzbekistan Som",
    "symbol_native": "UZS",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "UZS",
    "name_plural": "Uzbekistan som"
  },
  "VEF": {
    "symbol": "Bs.F.",
    "name": "Venezuelan Bolívar",
    "symbol_native": "Bs.F.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "VEF",
    "name_plural": "Venezuelan bolívars"
  },
  "VND": {
    "symbol": "₫",
    "name": "Vietnamese Dong",
    "symbol_native": "₫",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "VND",
    "name_plural": "Vietnamese dong"
  },
  "XAF": {
    "symbol": "FCFA",
    "name": "CFA Franc BEAC",
    "symbol_native": "FCFA",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "XAF",
    "name_plural": "CFA francs BEAC"
  },
  "XOF": {
    "symbol": "CFA",
    "name": "CFA Franc BCEAO",
    "symbol_native": "CFA",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "XOF",
    "name_plural": "CFA francs BCEAO"
  },
  "YER": {
    "symbol": "YR",
    "name": "Yemeni Rial",
    "symbol_native": "ر.ي.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "YER",
    "name_plural": "Yemeni rials"
  },
  "ZAR": {
    "symbol": "R",
    "name": "South African Rand",
    "symbol_native": "R",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ZAR",
    "name_plural": "South African rand"
  },
  "ZMK": {
    "symbol": "ZK",
    "name": "Zambian Kwacha",
    "symbol_native": "ZK",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ZMK",
    "name_plural": "Zambian kwachas"
  },
  "ZWL": {
    "symbol": "ZWL$",
    "name": "Zimbabwean Dollar",
    "symbol_native": "ZWL$",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ZWL",
    "name_plural": "Zimbabwean Dollar"
  }
}
