import React, { FormEvent, ReactElement, useState, useEffect } from 'react'
import { Button, PropTypes, CircularProgress } from '@material-ui/core'

interface ThemisButtonProps {
  isDisabled: boolean
  title: string
  submitAction: (event: FormEvent) => Promise<void> | Function
  additionalDisabler?: boolean
  style?: Object
  color?: PropTypes.Color | undefined
  variant?: "text" | "outlined" | "contained" | undefined
  size?: "small" | "medium" | "large" | undefined
  spinnerColor?: "primary" | "secondary" | "inherit" | undefined
  spinnerSize?: string | number | undefined
}

const ThemisButton: React.FC<ThemisButtonProps> = ({ 
  isDisabled,
  title,
  submitAction,
  style,
  variant,
  additionalDisabler,
  color,
  size,
  spinnerColor,
  spinnerSize
}): ReactElement => {
  const [message, setMessage] = useState<string>("")

useEffect(() => {
  if (additionalDisabler === undefined) { // i.e. straightforward buttons like add location, add event, unregister
    if (isDisabled) {
      setMessage("Processing")
    } else {
      setMessage(title)
    }
  } else { // i.e. Register button has additional disable bool 'readyToRegister'
    if (isDisabled && !additionalDisabler) {
      setMessage(title)
    } else if (isDisabled) {
      setMessage("Processing")
    }
  }
}, [additionalDisabler, isDisabled, title])

  return <Button
      disabled={isDisabled}
      onClick={submitAction}
      style={style}
      variant={variant}
      color={color}
      size={size}
    >
    {(isDisabled && additionalDisabler) || (isDisabled && additionalDisabler === undefined) ? <CircularProgress size={spinnerSize} color={spinnerColor} /> : <></>}{message}
  </Button>
}

export default ThemisButton
