import React, { ChangeEvent, ReactElement, ReactNode, useMemo, useState, useEffect } from 'react'
import { countries } from "lib/constants"
import { useStyles } from "lib/theme"
import { Link, Link as RouterLink } from "react-router-dom"
import {
  AppBar,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  useTheme
} from '@material-ui/core'
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons'
import { TabPanel } from "lib/pieces"
import ProducerConnectLinks from "./ProducerConnectLinks"
import themis_common from "store/themis_common_pb"
import YesNoDialog from "components/util/YesNoModal"
import UserGrid from "components/user/user_grid/UserGrid"
import { ProducerPermissions } from 'store/user/userReducer'
import { PermissionLists } from 'store/user/userActions'
import { validateBrandPermission, validateProducerPermission } from 'lib/permissions'
import DocumentsContainer from "./documents/DocumentsContainer"
import ReportsContainer from "./reports/ReportsContainer"
import ProducerRegistrationActivity from "./ProducerRegistrationActivity"
import JudgeGrid from './judge/JudgeGrid'


const countryItems: any[] = []
for (const country of countries) {
  countryItems.push(<MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>)
}

interface ProducerProps {
  superUser: boolean
  producerPermissions: ProducerPermissions
  permissionLists: PermissionLists
  producerId: number
  producer: themis_common.Producer.AsObject
  brands: themis_common.Brand.AsObject[]
  currentTab: number
  handleChangeTab: (event: ChangeEvent<{}>, value: number)=>void
  handleDeleteProducer: Function
  handleDeleteBrand: Function
  setDeleteBrand?: (brand: themis_common.Brand.AsObject) => void
  brandToDelete?: themis_common.Brand.AsObject | null
  isProducerOpen: boolean
  isBrandOpen: boolean
  setIsProducerOpen?: (value: boolean | ((prevVar: boolean) => boolean)) => void
  setIsBrandOpen?: (value: boolean | ((prevVar: boolean) => boolean)) => void
  permissionCodes: themis_common.PermissionCodes.AsObject
  handleDeleteJudgeFromProducer: (judgeId: number) => void
}

const Producer: React.FC<ProducerProps> = ({
  superUser, producerPermissions,
  permissionLists, producerId, producer, brands, currentTab,
  handleChangeTab,handleDeleteProducer, handleDeleteBrand, setDeleteBrand,
  brandToDelete, isProducerOpen, isBrandOpen, setIsProducerOpen, setIsBrandOpen,
  permissionCodes, handleDeleteJudgeFromProducer
}): ReactElement => {

  const classes = useStyles()
  const theme = useTheme()
  const [brandsTabEnabled, setBrandsTabEnabled] = useState(false)

  // If producer has "BV", enable brands tab
  useEffect(() => {
    if (validateProducerPermission(permissionCodes.brandView, permissionCodes, superUser, producerPermissions, producer.id)) {
      if (!brandsTabEnabled) setBrandsTabEnabled(true)
    }
  }, [superUser, producerPermissions, producer.id, brandsTabEnabled, permissionCodes])

  // Generate brandsList
  const brandList = useMemo(() => {
    const brandsList: ReactNode[] = []
    brands.forEach((brand: themis_common.Brand.AsObject) => {
      if (validateBrandPermission(permissionCodes.brandView, permissionCodes, superUser, producerPermissions, producerId, brand.id)) {
        if (!brandsTabEnabled) setBrandsTabEnabled(true)
        brandsList.push(<div key={brand.id}>
          <Link className={classes.listItem} to={`/Brand/${brand.id}`}>{brand.name} - {brand.city}, {brand.state}</Link>
          {
            validateBrandPermission(permissionCodes.brandEdit, permissionCodes, superUser, producerPermissions, producerId, brand.id) ?
              (<IconButton className={classes.clickable} aria-label="edit-brand" component={RouterLink} to={`/EditBrand/${brand.id}`}>
                <EditIcon fontSize="small" />
              </IconButton>)
              :
              (<IconButton style={{ visibility: "hidden" }}>
                <EditIcon fontSize="small" />
              </IconButton>)
          }
          {
            validateBrandPermission(permissionCodes.brandDelete, permissionCodes, superUser, producerPermissions, producerId, brand.id) ?
              (<IconButton className={classes.clickable} aria-label="delete-brand" onClick={_e => { if (setDeleteBrand) setDeleteBrand(brand) }}>
                <DeleteIcon fontSize="small" />
              </IconButton>)
              :
              (<IconButton style={{ visibility: "hidden" }}>
                <DeleteIcon fontSize="small" />
              </IconButton>)
          }
        </div>)
      }
    })
    return brandsList
  }, [brands, classes, producerPermissions, producerId, superUser, setDeleteBrand, brandsTabEnabled, permissionCodes])

  const headerContents = () => {
    if (producer && producer.id && producer.name) {
      return `Manage Producer - ${producer.name}`
    } else {
      return "Select Producer to Continue"
    }
  }

  const editProducerIcon = useMemo(() => {
    if (validateProducerPermission(permissionCodes.producerEdit, permissionCodes, superUser, producerPermissions, producerId)) {
      return (
        <IconButton className={classes.clickable} aria-label="edit-producer" component={RouterLink} to={`/EditProducer/${producer?.id}`}>
          <EditIcon fontSize="small" />
        </IconButton>
      )
    } return []
  }, [classes.clickable, producer?.id, producerPermissions, producerId, superUser, permissionCodes])

  const deleteProducerIcon = useMemo(() => {
    if (validateProducerPermission(permissionCodes.producerDelete, permissionCodes, superUser, producerPermissions, producerId)) {
      return (
        <IconButton className={classes.clickable} aria-label="delete-producer" onClick={() => { if (setIsProducerOpen) setIsProducerOpen(true) }}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )
    } return []
  }, [classes.clickable, producerId, producerPermissions, superUser, setIsProducerOpen, permissionCodes])

  const addBrandIcon = useMemo(() => {
    if (validateProducerPermission(permissionCodes.brandAdd, permissionCodes, superUser, producerPermissions, producerId)) {
      return (
        <IconButton className={classes.clickable} aria-label="add-brand" component={RouterLink} to={`/Producer/${producerId}/AddBrand`}>
          <AddIcon fontSize="small"/>
        </IconButton>
      )
    } return []
  }, [classes.clickable, producerId, producerPermissions, superUser, permissionCodes])

  const [isErrorOpen, setIsErrorOpen] = useState(false)

  const userCanViewProducer = useMemo((): boolean => {
    return validateProducerPermission(permissionCodes.producerView, permissionCodes, superUser, producerPermissions, producerId)
  }, [superUser, producerPermissions, producerId, permissionCodes])

  return userCanViewProducer && permissionCodes?.denyAccess.length ? (
    <Container style={{ maxWidth: "none" }}>
      <Grid container>
        <Grid container item xs={12} alignItems="flex-start">
          <Typography variant="h1" className={classes.headerTypo}>{headerContents()}</Typography>
          {editProducerIcon}
          {deleteProducerIcon}
          <AppBar position="static" style={{ backgroundColor: theme.palette.warning.main, color: theme.palette.common.white }}>
            <Tabs value={currentTab} onChange={handleChangeTab} variant="scrollable">
              <Tab label="Brands" id="tab-0" disabled={!brandsTabEnabled} />
              <Tab label="Judges" id="tab-1" aria-label="judges" disabled={!validateProducerPermission(permissionCodes.judgeView, permissionCodes, superUser, producerPermissions, producerId)} />
              <Tab label="Users" id="tab-2" aria-label="users" disabled={!validateProducerPermission(permissionCodes.producerUserView, permissionCodes, superUser, producerPermissions, producerId)} />
              <Tab label="Connect Links" id="tab-3" aria-label="links" disabled={!validateProducerPermission(permissionCodes.producerView, permissionCodes, superUser, producerPermissions, producerId)} />
              <Tab label="Documents" id="tab-4" aria-label="documents" disabled={!validateProducerPermission(permissionCodes.eventView, permissionCodes, superUser, producerPermissions, producerId)} />
              <Tab label="Activity Feed" id="tab-5" aria-label="reports" disabled={!validateProducerPermission(permissionCodes.financesView, permissionCodes, superUser, producerPermissions, producerId)} />
              <Tab label="EventReports" id="tab-6" aria-label="reports" disabled={!validateProducerPermission(permissionCodes.financesView, permissionCodes, superUser, producerPermissions, producerId)} />
            </Tabs>
          </AppBar>
          <TabPanel value={currentTab} index={0}>
            <Typography variant="h2">
              Brands
              {addBrandIcon}
            </Typography>
            {brandList}
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            {producer.judgesList !== undefined &&
            <JudgeGrid
              judges={producer.judgesList}
              producerId={producer.id}
              superUser={superUser}
              producerPermissions={producerPermissions}
              handleDeleteJudge={handleDeleteJudgeFromProducer}
              permissionCodes={permissionCodes}
              currentProducer={producer}
            />
            }
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            {!permissionLists || !producerId ? [] :
              <UserGrid objectId={producerId} type="producer" permissionLists={permissionLists}/>
            }
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <ProducerConnectLinks producerId={producerId} connectLinks={producer?.urlsList || []} />
          </TabPanel>
          <TabPanel index={4} value={currentTab}>
            <DocumentsContainer producer={producer} brands={brands} />
          </TabPanel>
          <TabPanel index={5} value={currentTab}>
            <ProducerRegistrationActivity producerId={producerId} />
          </TabPanel>
          <TabPanel index={6} value={currentTab}>
            <ReportsContainer producerId={producerId} reportId={0} />
          </TabPanel>
        </Grid>
        <YesNoDialog
          title={`Delete ${producer?.name}?`}
          question={`Deleting a producer will delete all brands as well and this can not be undone. Are you sure you want to delete ${producer?.name}?`}
          isOpen={isProducerOpen}
          onClose={() => {if (setIsProducerOpen) setIsProducerOpen(false)}}
          buttonActions={[
            { name: "No", color: "primary", callback: () => {if (setIsProducerOpen) setIsProducerOpen(false)} },
            { name: "Yes", color: "primary", callback: handleDeleteProducer },
          ]}
        />
        <YesNoDialog
          title={`Delete ${brandToDelete?.name}?`}
          question={`Deleting a brand can not be undone. Are you sure you want to delete ${brandToDelete?.name}?`}
          isOpen={isBrandOpen}
          onClose={() => {if (setIsBrandOpen) setIsBrandOpen(false)}}
          buttonActions={[
            { name: "No", color: "primary", callback: () => {if (setIsBrandOpen) setIsBrandOpen(false)} },
            { name: "Yes", color: "primary", callback: handleDeleteBrand },
          ]}
        />
        <YesNoDialog
          title={"Error"}
          question={"There was an error, please contact us if this continues to be a problem."}
          isOpen={isErrorOpen}
          onClose={() => setIsErrorOpen(false)}
          buttonActions={[
            { name: "Okay", color: "primary", callback: () => setIsErrorOpen(false) },
          ]}
        />
      </Grid>
    </Container>
  ) : <></>
}

export default Producer
