/* eslint-disable */
/**
 * @fileoverview gRPC-Web generated client stub for themis
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var themis_common_pb = require('./themis_common_pb.js')
const proto = {};
proto.themis = require('./themis_api_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.themis.ThemisApiClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.themis.ThemisApiPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.UserAuthRequest,
 *   !proto.themis.UserAuthResponse>}
 */
const methodDescriptor_ThemisApi_UserAuth = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UserAuth',
  grpc.web.MethodType.UNARY,
  proto.themis.UserAuthRequest,
  proto.themis.UserAuthResponse,
  /**
   * @param {!proto.themis.UserAuthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserAuthResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.UserAuthRequest,
 *   !proto.themis.UserAuthResponse>}
 */
const methodInfo_ThemisApi_UserAuth = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserAuthResponse,
  /**
   * @param {!proto.themis.UserAuthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserAuthResponse.deserializeBinary
);


/**
 * @param {!proto.themis.UserAuthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserAuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserAuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.userAuth =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UserAuth',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UserAuth,
      callback);
};


/**
 * @param {!proto.themis.UserAuthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserAuthResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.userAuth =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UserAuth',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UserAuth);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.RefreshRequest,
 *   !proto.themis.UserAuthResponse>}
 */
const methodDescriptor_ThemisApi_Refresh = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/Refresh',
  grpc.web.MethodType.UNARY,
  proto.themis.RefreshRequest,
  proto.themis.UserAuthResponse,
  /**
   * @param {!proto.themis.RefreshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserAuthResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.RefreshRequest,
 *   !proto.themis.UserAuthResponse>}
 */
const methodInfo_ThemisApi_Refresh = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserAuthResponse,
  /**
   * @param {!proto.themis.RefreshRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserAuthResponse.deserializeBinary
);


/**
 * @param {!proto.themis.RefreshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserAuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserAuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.refresh =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/Refresh',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_Refresh,
      callback);
};


/**
 * @param {!proto.themis.RefreshRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserAuthResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.refresh =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/Refresh',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_Refresh);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.RegisterRequest,
 *   !proto.themis.RegisterResponse>}
 */
const methodDescriptor_ThemisApi_Register = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/Register',
  grpc.web.MethodType.UNARY,
  proto.themis.RegisterRequest,
  proto.themis.RegisterResponse,
  /**
   * @param {!proto.themis.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.RegisterResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.RegisterRequest,
 *   !proto.themis.RegisterResponse>}
 */
const methodInfo_ThemisApi_Register = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.RegisterResponse,
  /**
   * @param {!proto.themis.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.RegisterResponse.deserializeBinary
);


/**
 * @param {!proto.themis.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.RegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.RegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/Register',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_Register,
      callback);
};


/**
 * @param {!proto.themis.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.RegisterResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/Register',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.UpdatePasswordRequest,
 *   !proto.themis.UpdatePasswordResponse>}
 */
const methodDescriptor_ThemisApi_UpdatePassword = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdatePassword',
  grpc.web.MethodType.UNARY,
  proto.themis.UpdatePasswordRequest,
  proto.themis.UpdatePasswordResponse,
  /**
   * @param {!proto.themis.UpdatePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UpdatePasswordResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.UpdatePasswordRequest,
 *   !proto.themis.UpdatePasswordResponse>}
 */
const methodInfo_ThemisApi_UpdatePassword = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UpdatePasswordResponse,
  /**
   * @param {!proto.themis.UpdatePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UpdatePasswordResponse.deserializeBinary
);


/**
 * @param {!proto.themis.UpdatePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UpdatePasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UpdatePasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updatePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdatePassword',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdatePassword,
      callback);
};


/**
 * @param {!proto.themis.UpdatePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UpdatePasswordResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updatePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdatePassword',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdatePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.GetPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetPermissions = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetPermissions',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  proto.themis.GetPermissionsResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.GetPermissionsResponse>}
 */
const methodInfo_ThemisApi_GetPermissions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GetPermissionsResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GetPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GetPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetPermissions,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GetPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.PermissionCodes>}
 */
const methodDescriptor_ThemisApi_GetPermissionCodes = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetPermissionCodes',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  themis_common_pb.PermissionCodes,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.PermissionCodes.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.PermissionCodes>}
 */
const methodInfo_ThemisApi_GetPermissionCodes = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.PermissionCodes,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.PermissionCodes.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.PermissionCodes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.PermissionCodes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getPermissionCodes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetPermissionCodes',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetPermissionCodes,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.PermissionCodes>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getPermissionCodes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetPermissionCodes',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetPermissionCodes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.UserAuthRequest,
 *   !proto.themis.ShareAuthResponse>}
 */
const methodDescriptor_ThemisApi_ShareAuth = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ShareAuth',
  grpc.web.MethodType.UNARY,
  proto.themis.UserAuthRequest,
  proto.themis.ShareAuthResponse,
  /**
   * @param {!proto.themis.UserAuthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ShareAuthResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.UserAuthRequest,
 *   !proto.themis.ShareAuthResponse>}
 */
const methodInfo_ThemisApi_ShareAuth = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ShareAuthResponse,
  /**
   * @param {!proto.themis.UserAuthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ShareAuthResponse.deserializeBinary
);


/**
 * @param {!proto.themis.UserAuthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ShareAuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ShareAuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.shareAuth =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ShareAuth',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ShareAuth,
      callback);
};


/**
 * @param {!proto.themis.UserAuthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ShareAuthResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.shareAuth =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ShareAuth',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ShareAuth);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AuthSharedRequest,
 *   !proto.themis.UserAuthResponse>}
 */
const methodDescriptor_ThemisApi_UserAuthShared = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UserAuthShared',
  grpc.web.MethodType.UNARY,
  proto.themis.AuthSharedRequest,
  proto.themis.UserAuthResponse,
  /**
   * @param {!proto.themis.AuthSharedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserAuthResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AuthSharedRequest,
 *   !proto.themis.UserAuthResponse>}
 */
const methodInfo_ThemisApi_UserAuthShared = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserAuthResponse,
  /**
   * @param {!proto.themis.AuthSharedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserAuthResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AuthSharedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserAuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserAuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.userAuthShared =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UserAuthShared',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UserAuthShared,
      callback);
};


/**
 * @param {!proto.themis.AuthSharedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserAuthResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.userAuthShared =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UserAuthShared',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UserAuthShared);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.CodeRequest,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_UpdateUserEmailWithCode = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateUserEmailWithCode',
  grpc.web.MethodType.UNARY,
  proto.themis.CodeRequest,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.CodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.CodeRequest,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_UpdateUserEmailWithCode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.CodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.CodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateUserEmailWithCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateUserEmailWithCode',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateUserEmailWithCode,
      callback);
};


/**
 * @param {!proto.themis.CodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateUserEmailWithCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateUserEmailWithCode',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateUserEmailWithCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.User,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_RequestPasswordReset = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RequestPasswordReset',
  grpc.web.MethodType.UNARY,
  themis_common_pb.User,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.User,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_RequestPasswordReset = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.requestPasswordReset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RequestPasswordReset',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RequestPasswordReset,
      callback);
};


/**
 * @param {!proto.themis.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.requestPasswordReset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RequestPasswordReset',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RequestPasswordReset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.CodeRequest,
 *   !proto.themis.StatusResponse>}
 */
const methodDescriptor_ThemisApi_TestPasswordResetCode = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/TestPasswordResetCode',
  grpc.web.MethodType.UNARY,
  proto.themis.CodeRequest,
  proto.themis.StatusResponse,
  /**
   * @param {!proto.themis.CodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.StatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.CodeRequest,
 *   !proto.themis.StatusResponse>}
 */
const methodInfo_ThemisApi_TestPasswordResetCode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.StatusResponse,
  /**
   * @param {!proto.themis.CodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.StatusResponse.deserializeBinary
);


/**
 * @param {!proto.themis.CodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.StatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.StatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.testPasswordResetCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/TestPasswordResetCode',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_TestPasswordResetCode,
      callback);
};


/**
 * @param {!proto.themis.CodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.StatusResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.testPasswordResetCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/TestPasswordResetCode',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_TestPasswordResetCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.CheckEmailRequest,
 *   !proto.themis.CheckEmailResponse>}
 */
const methodDescriptor_ThemisApi_CheckEmail = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/CheckEmail',
  grpc.web.MethodType.UNARY,
  proto.themis.CheckEmailRequest,
  proto.themis.CheckEmailResponse,
  /**
   * @param {!proto.themis.CheckEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CheckEmailResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.CheckEmailRequest,
 *   !proto.themis.CheckEmailResponse>}
 */
const methodInfo_ThemisApi_CheckEmail = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.CheckEmailResponse,
  /**
   * @param {!proto.themis.CheckEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CheckEmailResponse.deserializeBinary
);


/**
 * @param {!proto.themis.CheckEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.CheckEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.CheckEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.checkEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/CheckEmail',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CheckEmail,
      callback);
};


/**
 * @param {!proto.themis.CheckEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.CheckEmailResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.checkEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/CheckEmail',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CheckEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.UserRequest,
 *   !proto.themis.RegisterResponse>}
 */
const methodDescriptor_ThemisApi_AddUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddUser',
  grpc.web.MethodType.UNARY,
  proto.themis.UserRequest,
  proto.themis.RegisterResponse,
  /**
   * @param {!proto.themis.UserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.RegisterResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.UserRequest,
 *   !proto.themis.RegisterResponse>}
 */
const methodInfo_ThemisApi_AddUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.RegisterResponse,
  /**
   * @param {!proto.themis.UserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.RegisterResponse.deserializeBinary
);


/**
 * @param {!proto.themis.UserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.RegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.RegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddUser,
      callback);
};


/**
 * @param {!proto.themis.UserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.RegisterResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Program,
 *   !proto.themis.ProgramResponse>}
 */
const methodDescriptor_ThemisApi_GetProgram = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetProgram',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Program,
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Program,
 *   !proto.themis.ProgramResponse>}
 */
const methodInfo_ThemisApi_GetProgram = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProgramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProgramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getProgram =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProgram,
      callback);
};


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProgramResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getProgram =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProgram);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.ProgramResponse>}
 */
const methodDescriptor_ThemisApi_GetPrograms = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetPrograms',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.ProgramResponse>}
 */
const methodInfo_ThemisApi_GetPrograms = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProgramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProgramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getPrograms =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetPrograms',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetPrograms,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProgramResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getPrograms =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetPrograms',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetPrograms);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Program,
 *   !proto.themis.ProgramResponse>}
 */
const methodDescriptor_ThemisApi_AddProgram = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddProgram',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Program,
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Program,
 *   !proto.themis.ProgramResponse>}
 */
const methodInfo_ThemisApi_AddProgram = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProgramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProgramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addProgram =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProgram,
      callback);
};


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProgramResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addProgram =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProgram);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Program,
 *   !proto.themis.ProgramResponse>}
 */
const methodDescriptor_ThemisApi_UpdateProgram = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateProgram',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Program,
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Program,
 *   !proto.themis.ProgramResponse>}
 */
const methodInfo_ThemisApi_UpdateProgram = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProgramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProgramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateProgram =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateProgram,
      callback);
};


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProgramResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateProgram =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateProgram);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Program,
 *   !proto.themis.ProgramResponse>}
 */
const methodDescriptor_ThemisApi_DeleteProgram = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteProgram',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Program,
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Program,
 *   !proto.themis.ProgramResponse>}
 */
const methodInfo_ThemisApi_DeleteProgram = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProgramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProgramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteProgram =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteProgram,
      callback);
};


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProgramResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteProgram =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteProgram);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddLogoRequest,
 *   !proto.themis.ProgramResponse>}
 */
const methodDescriptor_ThemisApi_AddProgramLogo = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddProgramLogo',
  grpc.web.MethodType.UNARY,
  themis_common_pb.AddLogoRequest,
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.AddLogoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddLogoRequest,
 *   !proto.themis.ProgramResponse>}
 */
const methodInfo_ThemisApi_AddProgramLogo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProgramResponse,
  /**
   * @param {!proto.themis.AddLogoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProgramResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AddLogoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProgramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProgramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addProgramLogo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddProgramLogo',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProgramLogo,
      callback);
};


/**
 * @param {!proto.themis.AddLogoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProgramResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addProgramLogo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddProgramLogo',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProgramLogo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.User,
 *   !proto.themis.User>}
 */
const methodDescriptor_ThemisApi_GetUserById = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetUserById',
  grpc.web.MethodType.UNARY,
  themis_common_pb.User,
  themis_common_pb.User,
  /**
   * @param {!proto.themis.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.User.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.User,
 *   !proto.themis.User>}
 */
const methodInfo_ThemisApi_GetUserById = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.User,
  /**
   * @param {!proto.themis.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.User.deserializeBinary
);


/**
 * @param {!proto.themis.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getUserById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetUserById',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetUserById,
      callback);
};


/**
 * @param {!proto.themis.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.User>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getUserById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetUserById',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetUserById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.User,
 *   !proto.themis.User>}
 */
const methodDescriptor_ThemisApi_EditUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditUser',
  grpc.web.MethodType.UNARY,
  themis_common_pb.User,
  themis_common_pb.User,
  /**
   * @param {!proto.themis.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.User.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.User,
 *   !proto.themis.User>}
 */
const methodInfo_ThemisApi_EditUser = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.User,
  /**
   * @param {!proto.themis.User} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.User.deserializeBinary
);


/**
 * @param {!proto.themis.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.User)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.User>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditUser,
      callback);
};


/**
 * @param {!proto.themis.User} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.User>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Program,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetProgramUsers = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetProgramUsers',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Program,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Program,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_GetProgramUsers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getProgramUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetProgramUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProgramUsers,
      callback);
};


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getProgramUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetProgramUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProgramUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ProgramUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_AddProgramUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddProgramUser',
  grpc.web.MethodType.UNARY,
  proto.themis.ProgramUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProgramUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ProgramUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_AddProgramUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProgramUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.ProgramUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addProgramUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddProgramUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProgramUser,
      callback);
};


/**
 * @param {!proto.themis.ProgramUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addProgramUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddProgramUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProgramUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ProgramUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_EditProgramUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditProgramUser',
  grpc.web.MethodType.UNARY,
  proto.themis.ProgramUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProgramUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ProgramUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_EditProgramUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProgramUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.ProgramUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editProgramUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditProgramUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditProgramUser,
      callback);
};


/**
 * @param {!proto.themis.ProgramUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editProgramUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditProgramUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditProgramUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ProgramUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_DeleteProgramUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteProgramUser',
  grpc.web.MethodType.UNARY,
  proto.themis.ProgramUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProgramUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ProgramUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_DeleteProgramUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProgramUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.ProgramUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteProgramUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteProgramUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteProgramUser,
      callback);
};


/**
 * @param {!proto.themis.ProgramUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteProgramUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteProgramUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteProgramUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Program,
 *   !proto.themis.PermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetProgramPermissions = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetProgramPermissions',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Program,
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Program,
 *   !proto.themis.PermissionsResponse>}
 */
const methodInfo_ThemisApi_GetProgramPermissions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.PermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.PermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getProgramPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetProgramPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProgramPermissions,
      callback);
};


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.PermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getProgramPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetProgramPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProgramPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Location,
 *   !proto.themis.LocationResponse>}
 */
const methodDescriptor_ThemisApi_UpdateLocation = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateLocation',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Location,
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Location,
 *   !proto.themis.LocationResponse>}
 */
const methodInfo_ThemisApi_UpdateLocation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateLocation,
      callback);
};


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Location,
 *   !proto.themis.LocationResponse>}
 */
const methodDescriptor_ThemisApi_AddLocation = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddLocation',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Location,
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Location,
 *   !proto.themis.LocationResponse>}
 */
const methodInfo_ThemisApi_AddLocation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddLocation,
      callback);
};


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Program,
 *   !proto.themis.LocationResponse>}
 */
const methodDescriptor_ThemisApi_GetLocations = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetLocations',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Program,
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Program,
 *   !proto.themis.LocationResponse>}
 */
const methodInfo_ThemisApi_GetLocations = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.Program} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getLocations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetLocations',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetLocations,
      callback);
};


/**
 * @param {!proto.themis.Program} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getLocations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetLocations',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetLocations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Location,
 *   !proto.themis.LocationResponse>}
 */
const methodDescriptor_ThemisApi_DeleteLocation = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteLocation',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Location,
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Location,
 *   !proto.themis.LocationResponse>}
 */
const methodInfo_ThemisApi_DeleteLocation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteLocation,
      callback);
};


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Location,
 *   !proto.themis.LocationResponse>}
 */
const methodDescriptor_ThemisApi_GetLocation = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetLocation',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Location,
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Location,
 *   !proto.themis.LocationResponse>}
 */
const methodInfo_ThemisApi_GetLocation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetLocation,
      callback);
};


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Location,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetLocationUsers = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetLocationUsers',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Location,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Location,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_GetLocationUsers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getLocationUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetLocationUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetLocationUsers,
      callback);
};


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getLocationUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetLocationUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetLocationUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.LocationUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_AddLocationUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddLocationUser',
  grpc.web.MethodType.UNARY,
  proto.themis.LocationUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.LocationUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.LocationUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_AddLocationUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.LocationUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.LocationUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addLocationUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddLocationUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddLocationUser,
      callback);
};


/**
 * @param {!proto.themis.LocationUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addLocationUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddLocationUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddLocationUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.LocationUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_EditLocationUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditLocationUser',
  grpc.web.MethodType.UNARY,
  proto.themis.LocationUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.LocationUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.LocationUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_EditLocationUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.LocationUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.LocationUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editLocationUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditLocationUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditLocationUser,
      callback);
};


/**
 * @param {!proto.themis.LocationUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editLocationUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditLocationUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditLocationUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.LocationUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_DeleteLocationUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteLocationUser',
  grpc.web.MethodType.UNARY,
  proto.themis.LocationUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.LocationUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.LocationUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_DeleteLocationUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.LocationUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.LocationUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteLocationUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteLocationUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteLocationUser,
      callback);
};


/**
 * @param {!proto.themis.LocationUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteLocationUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteLocationUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteLocationUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Location,
 *   !proto.themis.PermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetLocationPermissions = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetLocationPermissions',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Location,
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Location,
 *   !proto.themis.PermissionsResponse>}
 */
const methodInfo_ThemisApi_GetLocationPermissions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.PermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.PermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getLocationPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetLocationPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetLocationPermissions,
      callback);
};


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.PermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getLocationPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetLocationPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetLocationPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Team,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_GetTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Team,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Team,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_GetTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTeam,
      callback);
};


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Team,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_AddTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Team,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Team,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_AddTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddTeam,
      callback);
};


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Team,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_UpdateTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Team,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Team,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_UpdateTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateTeam,
      callback);
};


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Team,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_DeleteTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Team,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Team,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_DeleteTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteTeam,
      callback);
};


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Team,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetTeamUsers = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetTeamUsers',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Team,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Team,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_GetTeamUsers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getTeamUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetTeamUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTeamUsers,
      callback);
};


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getTeamUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetTeamUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTeamUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.TeamUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_AddTeamUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddTeamUser',
  grpc.web.MethodType.UNARY,
  proto.themis.TeamUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.TeamUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.TeamUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_AddTeamUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.TeamUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.TeamUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addTeamUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddTeamUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddTeamUser,
      callback);
};


/**
 * @param {!proto.themis.TeamUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addTeamUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddTeamUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddTeamUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.TeamUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_EditTeamUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditTeamUser',
  grpc.web.MethodType.UNARY,
  proto.themis.TeamUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.TeamUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.TeamUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_EditTeamUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.TeamUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.TeamUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editTeamUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditTeamUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditTeamUser,
      callback);
};


/**
 * @param {!proto.themis.TeamUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editTeamUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditTeamUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditTeamUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.TeamUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_DeleteTeamUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteTeamUser',
  grpc.web.MethodType.UNARY,
  proto.themis.TeamUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.TeamUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.TeamUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_DeleteTeamUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.TeamUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.TeamUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteTeamUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteTeamUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteTeamUser,
      callback);
};


/**
 * @param {!proto.themis.TeamUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteTeamUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteTeamUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteTeamUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Team,
 *   !proto.themis.PermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetTeamPermissions = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetTeamPermissions',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Team,
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Team,
 *   !proto.themis.PermissionsResponse>}
 */
const methodInfo_ThemisApi_GetTeamPermissions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.PermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.PermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getTeamPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetTeamPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTeamPermissions,
      callback);
};


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.PermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getTeamPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetTeamPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTeamPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Athlete,
 *   !proto.themis.AthleteResponse>}
 */
const methodDescriptor_ThemisApi_GetAthlete = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetAthlete',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Athlete,
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.Athlete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Athlete,
 *   !proto.themis.AthleteResponse>}
 */
const methodInfo_ThemisApi_GetAthlete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.Athlete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Athlete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.AthleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.AthleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getAthlete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAthlete,
      callback);
};


/**
 * @param {!proto.themis.Athlete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.AthleteResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getAthlete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAthlete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Athlete,
 *   !proto.themis.AthleteResponse>}
 */
const methodDescriptor_ThemisApi_AddAthlete = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddAthlete',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Athlete,
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.Athlete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Athlete,
 *   !proto.themis.AthleteResponse>}
 */
const methodInfo_ThemisApi_AddAthlete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.Athlete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Athlete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.AthleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.AthleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addAthlete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthlete,
      callback);
};


/**
 * @param {!proto.themis.Athlete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.AthleteResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addAthlete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthlete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Athlete,
 *   !proto.themis.AthleteResponse>}
 */
const methodDescriptor_ThemisApi_UpdateAthlete = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateAthlete',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Athlete,
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.Athlete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Athlete,
 *   !proto.themis.AthleteResponse>}
 */
const methodInfo_ThemisApi_UpdateAthlete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.Athlete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Athlete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.AthleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.AthleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateAthlete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateAthlete,
      callback);
};


/**
 * @param {!proto.themis.Athlete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.AthleteResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateAthlete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateAthlete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddAthleteListRequest,
 *   !proto.themis.AthleteResponse>}
 */
const methodDescriptor_ThemisApi_AddAthleteList = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddAthleteList',
  grpc.web.MethodType.UNARY,
  proto.themis.AddAthleteListRequest,
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.AddAthleteListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddAthleteListRequest,
 *   !proto.themis.AthleteResponse>}
 */
const methodInfo_ThemisApi_AddAthleteList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.AddAthleteListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AddAthleteListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.AthleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.AthleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addAthleteList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddAthleteList',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthleteList,
      callback);
};


/**
 * @param {!proto.themis.AddAthleteListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.AthleteResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addAthleteList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddAthleteList',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthleteList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianResponse>}
 */
const methodDescriptor_ThemisApi_GetGuardian = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetGuardian',
  grpc.web.MethodType.UNARY,
  proto.themis.GuardianAthleteRequest,
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianResponse>}
 */
const methodInfo_ThemisApi_GetGuardian = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GuardianResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GuardianResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getGuardian =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetGuardian',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetGuardian,
      callback);
};


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GuardianResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getGuardian =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetGuardian',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetGuardian);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Location,
 *   !proto.themis.GuardianResponse>}
 */
const methodDescriptor_ThemisApi_GetGuardiansByLocationID = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetGuardiansByLocationID',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Location,
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Location,
 *   !proto.themis.GuardianResponse>}
 */
const methodInfo_ThemisApi_GetGuardiansByLocationID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.Location} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GuardianResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GuardianResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getGuardiansByLocationID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetGuardiansByLocationID',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetGuardiansByLocationID,
      callback);
};


/**
 * @param {!proto.themis.Location} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GuardianResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getGuardiansByLocationID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetGuardiansByLocationID',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetGuardiansByLocationID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianResponse>}
 */
const methodDescriptor_ThemisApi_AddGuardian = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddGuardian',
  grpc.web.MethodType.UNARY,
  proto.themis.GuardianAthleteRequest,
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianResponse>}
 */
const methodInfo_ThemisApi_AddGuardian = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GuardianResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GuardianResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addGuardian =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddGuardian',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddGuardian,
      callback);
};


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GuardianResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addGuardian =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddGuardian',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddGuardian);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianResponse>}
 */
const methodDescriptor_ThemisApi_UpdateGuardian = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateGuardian',
  grpc.web.MethodType.UNARY,
  proto.themis.GuardianAthleteRequest,
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianResponse>}
 */
const methodInfo_ThemisApi_UpdateGuardian = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GuardianResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GuardianResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateGuardian =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateGuardian',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateGuardian,
      callback);
};


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GuardianResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateGuardian =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateGuardian',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateGuardian);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianResponse>}
 */
const methodDescriptor_ThemisApi_AddGuardianToAthlete = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddGuardianToAthlete',
  grpc.web.MethodType.UNARY,
  proto.themis.GuardianAthleteRequest,
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianResponse>}
 */
const methodInfo_ThemisApi_AddGuardianToAthlete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GuardianResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GuardianResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addGuardianToAthlete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddGuardianToAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddGuardianToAthlete,
      callback);
};


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GuardianResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addGuardianToAthlete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddGuardianToAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddGuardianToAthlete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianResponse>}
 */
const methodDescriptor_ThemisApi_RemoveGuardianFromAthlete = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveGuardianFromAthlete',
  grpc.web.MethodType.UNARY,
  proto.themis.GuardianAthleteRequest,
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianResponse>}
 */
const methodInfo_ThemisApi_RemoveGuardianFromAthlete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GuardianResponse,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GuardianResponse.deserializeBinary
);


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GuardianResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GuardianResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeGuardianFromAthlete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveGuardianFromAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveGuardianFromAthlete,
      callback);
};


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GuardianResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeGuardianFromAthlete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveGuardianFromAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveGuardianFromAthlete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AthleteLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodDescriptor_ThemisApi_AddAthleteToLocation = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddAthleteToLocation',
  grpc.web.MethodType.UNARY,
  proto.themis.AthleteLocationRequest,
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.AthleteLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AthleteLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodInfo_ThemisApi_AddAthleteToLocation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.AthleteLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AthleteLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addAthleteToLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddAthleteToLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthleteToLocation,
      callback);
};


/**
 * @param {!proto.themis.AthleteLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addAthleteToLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddAthleteToLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthleteToLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AthleteLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodDescriptor_ThemisApi_RemoveAthleteFromLocation = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveAthleteFromLocation',
  grpc.web.MethodType.UNARY,
  proto.themis.AthleteLocationRequest,
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.AthleteLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AthleteLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodInfo_ThemisApi_RemoveAthleteFromLocation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.AthleteLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AthleteLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeAthleteFromLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveAthleteFromLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveAthleteFromLocation,
      callback);
};


/**
 * @param {!proto.themis.AthleteLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeAthleteFromLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveAthleteFromLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveAthleteFromLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AthleteTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_AddAthleteToTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddAthleteToTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.AthleteTeamRequest,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.AthleteTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AthleteTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_AddAthleteToTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.AthleteTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AthleteTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addAthleteToTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddAthleteToTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthleteToTeam,
      callback);
};


/**
 * @param {!proto.themis.AthleteTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addAthleteToTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddAthleteToTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthleteToTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AthletesTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_AddAthletesToTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddAthletesToTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.AthletesTeamRequest,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.AthletesTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AthletesTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_AddAthletesToTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.AthletesTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AthletesTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addAthletesToTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddAthletesToTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthletesToTeam,
      callback);
};


/**
 * @param {!proto.themis.AthletesTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addAthletesToTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddAthletesToTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthletesToTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Athlete,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_GetAthleteTeams = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetAthleteTeams',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Athlete,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Athlete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Athlete,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_GetAthleteTeams = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Athlete} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Athlete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getAthleteTeams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetAthleteTeams',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAthleteTeams,
      callback);
};


/**
 * @param {!proto.themis.Athlete} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getAthleteTeams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetAthleteTeams',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAthleteTeams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AthleteTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_RemoveAthleteFromTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveAthleteFromTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.AthleteTeamRequest,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.AthleteTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AthleteTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_RemoveAthleteFromTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.AthleteTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AthleteTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeAthleteFromTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveAthleteFromTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveAthleteFromTeam,
      callback);
};


/**
 * @param {!proto.themis.AthleteTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeAthleteFromTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveAthleteFromTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveAthleteFromTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.AllStarTypesResponse>}
 */
const methodDescriptor_ThemisApi_GetAllStarTypes = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetAllStarTypes',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  proto.themis.AllStarTypesResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AllStarTypesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.AllStarTypesResponse>}
 */
const methodInfo_ThemisApi_GetAllStarTypes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.AllStarTypesResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AllStarTypesResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.AllStarTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.AllStarTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getAllStarTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetAllStarTypes',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAllStarTypes,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.AllStarTypesResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getAllStarTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetAllStarTypes',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAllStarTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.DivisionsResponse>}
 */
const methodDescriptor_ThemisApi_GetDivisions = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetDivisions',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.DivisionsResponse>}
 */
const methodInfo_ThemisApi_GetDivisions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DivisionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DivisionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getDivisions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetDivisions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetDivisions,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DivisionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getDivisions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetDivisions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetDivisions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.TiersResponse>}
 */
const methodDescriptor_ThemisApi_GetTiers = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetTiers',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  proto.themis.TiersResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TiersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.TiersResponse>}
 */
const methodInfo_ThemisApi_GetTiers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TiersResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TiersResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TiersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TiersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getTiers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetTiers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTiers,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TiersResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getTiers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetTiers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTiers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.CategoriesResponse>}
 */
const methodDescriptor_ThemisApi_GetCategories = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetCategories',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  proto.themis.CategoriesResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CategoriesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.CategoriesResponse>}
 */
const methodInfo_ThemisApi_GetCategories = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.CategoriesResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CategoriesResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.CategoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.CategoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getCategories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetCategories',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCategories,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.CategoriesResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getCategories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetCategories',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCategories);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Producer,
 *   !proto.themis.ProducerResponse>}
 */
const methodDescriptor_ThemisApi_GetProducer = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetProducer',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Producer,
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Producer,
 *   !proto.themis.ProducerResponse>}
 */
const methodInfo_ThemisApi_GetProducer = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProducerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProducerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getProducer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetProducer',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducer,
      callback);
};


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProducerResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getProducer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetProducer',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.ProducerResponse>}
 */
const methodDescriptor_ThemisApi_GetProducers = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetProducers',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.ProducerResponse>}
 */
const methodInfo_ThemisApi_GetProducers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProducerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProducerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getProducers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetProducers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducers,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProducerResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getProducers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetProducers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Producer,
 *   !proto.themis.ProducerResponse>}
 */
const methodDescriptor_ThemisApi_AddProducer = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddProducer',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Producer,
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Producer,
 *   !proto.themis.ProducerResponse>}
 */
const methodInfo_ThemisApi_AddProducer = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProducerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProducerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addProducer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddProducer',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProducer,
      callback);
};


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProducerResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addProducer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddProducer',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProducer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Producer,
 *   !proto.themis.ProducerResponse>}
 */
const methodDescriptor_ThemisApi_UpdateProducer = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateProducer',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Producer,
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Producer,
 *   !proto.themis.ProducerResponse>}
 */
const methodInfo_ThemisApi_UpdateProducer = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProducerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProducerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateProducer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateProducer',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateProducer,
      callback);
};


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProducerResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateProducer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateProducer',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateProducer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Producer,
 *   !proto.themis.ProducerResponse>}
 */
const methodDescriptor_ThemisApi_DeleteProducer = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteProducer',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Producer,
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Producer,
 *   !proto.themis.ProducerResponse>}
 */
const methodInfo_ThemisApi_DeleteProducer = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProducerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProducerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteProducer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteProducer',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteProducer,
      callback);
};


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProducerResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteProducer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteProducer',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteProducer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddLogoRequest,
 *   !proto.themis.ProducerResponse>}
 */
const methodDescriptor_ThemisApi_AddProducerLogo = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddProducerLogo',
  grpc.web.MethodType.UNARY,
  themis_common_pb.AddLogoRequest,
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.AddLogoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddLogoRequest,
 *   !proto.themis.ProducerResponse>}
 */
const methodInfo_ThemisApi_AddProducerLogo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.AddLogoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AddLogoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProducerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProducerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addProducerLogo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddProducerLogo',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProducerLogo,
      callback);
};


/**
 * @param {!proto.themis.AddLogoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProducerResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addProducerLogo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddProducerLogo',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProducerLogo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Producer,
 *   !proto.themis.CreateStripeProducerAccountResponse>}
 */
const methodDescriptor_ThemisApi_CreateStripeProducerAccount = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/CreateStripeProducerAccount',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Producer,
  proto.themis.CreateStripeProducerAccountResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CreateStripeProducerAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Producer,
 *   !proto.themis.CreateStripeProducerAccountResponse>}
 */
const methodInfo_ThemisApi_CreateStripeProducerAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.CreateStripeProducerAccountResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CreateStripeProducerAccountResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.CreateStripeProducerAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.CreateStripeProducerAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.createStripeProducerAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/CreateStripeProducerAccount',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CreateStripeProducerAccount,
      callback);
};


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.CreateStripeProducerAccountResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.createStripeProducerAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/CreateStripeProducerAccount',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CreateStripeProducerAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Producer,
 *   !proto.themis.CreateStripeProducerAccountResponse>}
 */
const methodDescriptor_ThemisApi_GetProducerStripeOnBoardingUrl = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetProducerStripeOnBoardingUrl',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Producer,
  proto.themis.CreateStripeProducerAccountResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CreateStripeProducerAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Producer,
 *   !proto.themis.CreateStripeProducerAccountResponse>}
 */
const methodInfo_ThemisApi_GetProducerStripeOnBoardingUrl = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.CreateStripeProducerAccountResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CreateStripeProducerAccountResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.CreateStripeProducerAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.CreateStripeProducerAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getProducerStripeOnBoardingUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetProducerStripeOnBoardingUrl',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducerStripeOnBoardingUrl,
      callback);
};


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.CreateStripeProducerAccountResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getProducerStripeOnBoardingUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetProducerStripeOnBoardingUrl',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducerStripeOnBoardingUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Producer,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetProducerUsers = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetProducerUsers',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Producer,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Producer,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_GetProducerUsers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getProducerUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetProducerUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducerUsers,
      callback);
};


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getProducerUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetProducerUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducerUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ProducerUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_AddProducerUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddProducerUser',
  grpc.web.MethodType.UNARY,
  proto.themis.ProducerUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProducerUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ProducerUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_AddProducerUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProducerUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.ProducerUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addProducerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddProducerUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProducerUser,
      callback);
};


/**
 * @param {!proto.themis.ProducerUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addProducerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddProducerUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProducerUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ProducerUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_EditProducerUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditProducerUser',
  grpc.web.MethodType.UNARY,
  proto.themis.ProducerUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProducerUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ProducerUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_EditProducerUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProducerUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.ProducerUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editProducerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditProducerUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditProducerUser,
      callback);
};


/**
 * @param {!proto.themis.ProducerUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editProducerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditProducerUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditProducerUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ProducerUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_DeleteProducerUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteProducerUser',
  grpc.web.MethodType.UNARY,
  proto.themis.ProducerUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProducerUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ProducerUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_DeleteProducerUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.ProducerUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.ProducerUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteProducerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteProducerUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteProducerUser,
      callback);
};


/**
 * @param {!proto.themis.ProducerUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteProducerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteProducerUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteProducerUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Producer,
 *   !proto.themis.PermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetProducerPermissions = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetProducerPermissions',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Producer,
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Producer,
 *   !proto.themis.PermissionsResponse>}
 */
const methodInfo_ThemisApi_GetProducerPermissions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.PermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.PermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getProducerPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetProducerPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducerPermissions,
      callback);
};


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.PermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getProducerPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetProducerPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducerPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddProducerLinkRequest,
 *   !proto.themis.ProducerUrls>}
 */
const methodDescriptor_ThemisApi_AddProducerLink = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddProducerLink',
  grpc.web.MethodType.UNARY,
  proto.themis.AddProducerLinkRequest,
  themis_common_pb.ProducerUrls,
  /**
   * @param {!proto.themis.AddProducerLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.ProducerUrls.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddProducerLinkRequest,
 *   !proto.themis.ProducerUrls>}
 */
const methodInfo_ThemisApi_AddProducerLink = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.ProducerUrls,
  /**
   * @param {!proto.themis.AddProducerLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.ProducerUrls.deserializeBinary
);


/**
 * @param {!proto.themis.AddProducerLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProducerUrls)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProducerUrls>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addProducerLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddProducerLink',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProducerLink,
      callback);
};


/**
 * @param {!proto.themis.AddProducerLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProducerUrls>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addProducerLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddProducerLink',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProducerLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.ProducerUrls>}
 */
const methodDescriptor_ThemisApi_GetAllProducerLinks = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetAllProducerLinks',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  themis_common_pb.ProducerUrls,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.ProducerUrls.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.ProducerUrls>}
 */
const methodInfo_ThemisApi_GetAllProducerLinks = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.ProducerUrls,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.ProducerUrls.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProducerUrls)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProducerUrls>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getAllProducerLinks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetAllProducerLinks',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAllProducerLinks,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProducerUrls>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getAllProducerLinks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetAllProducerLinks',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAllProducerLinks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ConnectProducerLinkRequest,
 *   !proto.themis.ProgramAllowedProducers>}
 */
const methodDescriptor_ThemisApi_ConnectProducerLink = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ConnectProducerLink',
  grpc.web.MethodType.UNARY,
  proto.themis.ConnectProducerLinkRequest,
  themis_common_pb.ProgramAllowedProducers,
  /**
   * @param {!proto.themis.ConnectProducerLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.ProgramAllowedProducers.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ConnectProducerLinkRequest,
 *   !proto.themis.ProgramAllowedProducers>}
 */
const methodInfo_ThemisApi_ConnectProducerLink = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.ProgramAllowedProducers,
  /**
   * @param {!proto.themis.ConnectProducerLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.ProgramAllowedProducers.deserializeBinary
);


/**
 * @param {!proto.themis.ConnectProducerLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProgramAllowedProducers)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProgramAllowedProducers>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.connectProducerLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ConnectProducerLink',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ConnectProducerLink,
      callback);
};


/**
 * @param {!proto.themis.ConnectProducerLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProgramAllowedProducers>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.connectProducerLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ConnectProducerLink',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ConnectProducerLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ConnectProducerLinkRequest,
 *   !proto.themis.ProgramAllowedProducers>}
 */
const methodDescriptor_ThemisApi_ListProducerLink = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ListProducerLink',
  grpc.web.MethodType.UNARY,
  proto.themis.ConnectProducerLinkRequest,
  themis_common_pb.ProgramAllowedProducers,
  /**
   * @param {!proto.themis.ConnectProducerLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.ProgramAllowedProducers.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ConnectProducerLinkRequest,
 *   !proto.themis.ProgramAllowedProducers>}
 */
const methodInfo_ThemisApi_ListProducerLink = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.ProgramAllowedProducers,
  /**
   * @param {!proto.themis.ConnectProducerLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.ProgramAllowedProducers.deserializeBinary
);


/**
 * @param {!proto.themis.ConnectProducerLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProgramAllowedProducers)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProgramAllowedProducers>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.listProducerLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ListProducerLink',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ListProducerLink,
      callback);
};


/**
 * @param {!proto.themis.ConnectProducerLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProgramAllowedProducers>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.listProducerLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ListProducerLink',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ListProducerLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventGridRequest,
 *   !proto.themis.EventResponse>}
 */
const methodDescriptor_ThemisApi_GetApprovedEvents = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetApprovedEvents',
  grpc.web.MethodType.UNARY,
  proto.themis.EventGridRequest,
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.EventGridRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventGridRequest,
 *   !proto.themis.EventResponse>}
 */
const methodInfo_ThemisApi_GetApprovedEvents = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.EventGridRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventGridRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getApprovedEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetApprovedEvents',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetApprovedEvents,
      callback);
};


/**
 * @param {!proto.themis.EventGridRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getApprovedEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetApprovedEvents',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetApprovedEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventGridRequest,
 *   !proto.themis.EventGridResponse>}
 */
const methodDescriptor_ThemisApi_GetEventTeamsForEventGridByProgramID = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventTeamsForEventGridByProgramID',
  grpc.web.MethodType.UNARY,
  proto.themis.EventGridRequest,
  proto.themis.EventGridResponse,
  /**
   * @param {!proto.themis.EventGridRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventGridResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventGridRequest,
 *   !proto.themis.EventGridResponse>}
 */
const methodInfo_ThemisApi_GetEventTeamsForEventGridByProgramID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventGridResponse,
  /**
   * @param {!proto.themis.EventGridRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventGridResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventGridRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventGridResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventGridResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventTeamsForEventGridByProgramID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamsForEventGridByProgramID',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamsForEventGridByProgramID,
      callback);
};


/**
 * @param {!proto.themis.EventGridRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventGridResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventTeamsForEventGridByProgramID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamsForEventGridByProgramID',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamsForEventGridByProgramID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventGridRequest,
 *   !proto.themis.EventGridResponse>}
 */
const methodDescriptor_ThemisApi_GetEventTeamsForEventGridByLocationID = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventTeamsForEventGridByLocationID',
  grpc.web.MethodType.UNARY,
  proto.themis.EventGridRequest,
  proto.themis.EventGridResponse,
  /**
   * @param {!proto.themis.EventGridRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventGridResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventGridRequest,
 *   !proto.themis.EventGridResponse>}
 */
const methodInfo_ThemisApi_GetEventTeamsForEventGridByLocationID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventGridResponse,
  /**
   * @param {!proto.themis.EventGridRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventGridResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventGridRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventGridResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventGridResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventTeamsForEventGridByLocationID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamsForEventGridByLocationID',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamsForEventGridByLocationID,
      callback);
};


/**
 * @param {!proto.themis.EventGridRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventGridResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventTeamsForEventGridByLocationID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamsForEventGridByLocationID',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamsForEventGridByLocationID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventGridRequest,
 *   !proto.themis.EventGridResponse>}
 */
const methodDescriptor_ThemisApi_GetEventTeamsForEventGridByTeamID = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventTeamsForEventGridByTeamID',
  grpc.web.MethodType.UNARY,
  proto.themis.EventGridRequest,
  proto.themis.EventGridResponse,
  /**
   * @param {!proto.themis.EventGridRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventGridResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventGridRequest,
 *   !proto.themis.EventGridResponse>}
 */
const methodInfo_ThemisApi_GetEventTeamsForEventGridByTeamID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventGridResponse,
  /**
   * @param {!proto.themis.EventGridRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventGridResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventGridRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventGridResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventGridResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventTeamsForEventGridByTeamID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamsForEventGridByTeamID',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamsForEventGridByTeamID,
      callback);
};


/**
 * @param {!proto.themis.EventGridRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventGridResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventTeamsForEventGridByTeamID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamsForEventGridByTeamID',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamsForEventGridByTeamID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Coach,
 *   !proto.themis.CoachResponse>}
 */
const methodDescriptor_ThemisApi_GetCoach = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetCoach',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Coach,
  proto.themis.CoachResponse,
  /**
   * @param {!proto.themis.Coach} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CoachResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Coach,
 *   !proto.themis.CoachResponse>}
 */
const methodInfo_ThemisApi_GetCoach = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.CoachResponse,
  /**
   * @param {!proto.themis.Coach} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CoachResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Coach} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.CoachResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.CoachResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getCoach =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetCoach',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCoach,
      callback);
};


/**
 * @param {!proto.themis.Coach} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.CoachResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getCoach =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetCoach',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCoach);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Coach,
 *   !proto.themis.CoachResponse>}
 */
const methodDescriptor_ThemisApi_AddCoach = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddCoach',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Coach,
  proto.themis.CoachResponse,
  /**
   * @param {!proto.themis.Coach} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CoachResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Coach,
 *   !proto.themis.CoachResponse>}
 */
const methodInfo_ThemisApi_AddCoach = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.CoachResponse,
  /**
   * @param {!proto.themis.Coach} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CoachResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Coach} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.CoachResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.CoachResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addCoach =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddCoach',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddCoach,
      callback);
};


/**
 * @param {!proto.themis.Coach} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.CoachResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addCoach =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddCoach',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddCoach);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Coach,
 *   !proto.themis.CoachResponse>}
 */
const methodDescriptor_ThemisApi_UpdateCoach = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateCoach',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Coach,
  proto.themis.CoachResponse,
  /**
   * @param {!proto.themis.Coach} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CoachResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Coach,
 *   !proto.themis.CoachResponse>}
 */
const methodInfo_ThemisApi_UpdateCoach = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.CoachResponse,
  /**
   * @param {!proto.themis.Coach} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CoachResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Coach} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.CoachResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.CoachResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateCoach =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateCoach',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateCoach,
      callback);
};


/**
 * @param {!proto.themis.Coach} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.CoachResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateCoach =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateCoach',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateCoach);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Coach,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_GetCoachTeams = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetCoachTeams',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Coach,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Coach} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Coach,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_GetCoachTeams = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Coach} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Coach} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getCoachTeams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetCoachTeams',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCoachTeams,
      callback);
};


/**
 * @param {!proto.themis.Coach} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getCoachTeams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetCoachTeams',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCoachTeams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.CoachLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodDescriptor_ThemisApi_AddCoachToLocation = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddCoachToLocation',
  grpc.web.MethodType.UNARY,
  proto.themis.CoachLocationRequest,
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.CoachLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.CoachLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodInfo_ThemisApi_AddCoachToLocation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.CoachLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.themis.CoachLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addCoachToLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddCoachToLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddCoachToLocation,
      callback);
};


/**
 * @param {!proto.themis.CoachLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addCoachToLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddCoachToLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddCoachToLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.CoachLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodDescriptor_ThemisApi_RemoveCoachFromLocation = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveCoachFromLocation',
  grpc.web.MethodType.UNARY,
  proto.themis.CoachLocationRequest,
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.CoachLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.CoachLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodInfo_ThemisApi_RemoveCoachFromLocation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.CoachLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.themis.CoachLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeCoachFromLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveCoachFromLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveCoachFromLocation,
      callback);
};


/**
 * @param {!proto.themis.CoachLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeCoachFromLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveCoachFromLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveCoachFromLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.CoachTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_AddCoachToTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddCoachToTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.CoachTeamRequest,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.CoachTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.CoachTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_AddCoachToTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.CoachTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.CoachTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addCoachToTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddCoachToTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddCoachToTeam,
      callback);
};


/**
 * @param {!proto.themis.CoachTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addCoachToTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddCoachToTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddCoachToTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.CoachTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_RemoveCoachFromTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveCoachFromTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.CoachTeamRequest,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.CoachTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.CoachTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_RemoveCoachFromTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.CoachTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.CoachTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeCoachFromTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveCoachFromTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveCoachFromTeam,
      callback);
};


/**
 * @param {!proto.themis.CoachTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeCoachFromTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveCoachFromTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveCoachFromTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Personnel,
 *   !proto.themis.PersonnelResponse>}
 */
const methodDescriptor_ThemisApi_GetPersonnel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetPersonnel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Personnel,
  proto.themis.PersonnelResponse,
  /**
   * @param {!proto.themis.Personnel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PersonnelResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Personnel,
 *   !proto.themis.PersonnelResponse>}
 */
const methodInfo_ThemisApi_GetPersonnel = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.PersonnelResponse,
  /**
   * @param {!proto.themis.Personnel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PersonnelResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Personnel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.PersonnelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.PersonnelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getPersonnel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetPersonnel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetPersonnel,
      callback);
};


/**
 * @param {!proto.themis.Personnel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.PersonnelResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getPersonnel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetPersonnel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetPersonnel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Personnel,
 *   !proto.themis.PersonnelResponse>}
 */
const methodDescriptor_ThemisApi_AddPersonnel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddPersonnel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Personnel,
  proto.themis.PersonnelResponse,
  /**
   * @param {!proto.themis.Personnel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PersonnelResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Personnel,
 *   !proto.themis.PersonnelResponse>}
 */
const methodInfo_ThemisApi_AddPersonnel = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.PersonnelResponse,
  /**
   * @param {!proto.themis.Personnel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PersonnelResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Personnel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.PersonnelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.PersonnelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addPersonnel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddPersonnel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddPersonnel,
      callback);
};


/**
 * @param {!proto.themis.Personnel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.PersonnelResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addPersonnel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddPersonnel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddPersonnel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Personnel,
 *   !proto.themis.PersonnelResponse>}
 */
const methodDescriptor_ThemisApi_UpdatePersonnel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdatePersonnel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Personnel,
  proto.themis.PersonnelResponse,
  /**
   * @param {!proto.themis.Personnel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PersonnelResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Personnel,
 *   !proto.themis.PersonnelResponse>}
 */
const methodInfo_ThemisApi_UpdatePersonnel = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.PersonnelResponse,
  /**
   * @param {!proto.themis.Personnel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PersonnelResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Personnel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.PersonnelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.PersonnelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updatePersonnel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdatePersonnel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdatePersonnel,
      callback);
};


/**
 * @param {!proto.themis.Personnel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.PersonnelResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updatePersonnel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdatePersonnel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdatePersonnel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Personnel,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_GetPersonnelTeams = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetPersonnelTeams',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Personnel,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Personnel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Personnel,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_GetPersonnelTeams = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.Personnel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Personnel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getPersonnelTeams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetPersonnelTeams',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetPersonnelTeams,
      callback);
};


/**
 * @param {!proto.themis.Personnel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getPersonnelTeams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetPersonnelTeams',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetPersonnelTeams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.PersonnelLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodDescriptor_ThemisApi_AddPersonnelToLocation = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddPersonnelToLocation',
  grpc.web.MethodType.UNARY,
  proto.themis.PersonnelLocationRequest,
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.PersonnelLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.PersonnelLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodInfo_ThemisApi_AddPersonnelToLocation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.PersonnelLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.themis.PersonnelLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addPersonnelToLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddPersonnelToLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddPersonnelToLocation,
      callback);
};


/**
 * @param {!proto.themis.PersonnelLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addPersonnelToLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddPersonnelToLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddPersonnelToLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.PersonnelLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodDescriptor_ThemisApi_RemovePersonnelFromLocation = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemovePersonnelFromLocation',
  grpc.web.MethodType.UNARY,
  proto.themis.PersonnelLocationRequest,
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.PersonnelLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.PersonnelLocationRequest,
 *   !proto.themis.LocationResponse>}
 */
const methodInfo_ThemisApi_RemovePersonnelFromLocation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.LocationResponse,
  /**
   * @param {!proto.themis.PersonnelLocationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.LocationResponse.deserializeBinary
);


/**
 * @param {!proto.themis.PersonnelLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.LocationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.LocationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removePersonnelFromLocation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemovePersonnelFromLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemovePersonnelFromLocation,
      callback);
};


/**
 * @param {!proto.themis.PersonnelLocationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.LocationResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removePersonnelFromLocation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemovePersonnelFromLocation',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemovePersonnelFromLocation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.PersonnelTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_AddPersonnelToTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddPersonnelToTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.PersonnelTeamRequest,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.PersonnelTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.PersonnelTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_AddPersonnelToTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.PersonnelTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.PersonnelTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addPersonnelToTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddPersonnelToTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddPersonnelToTeam,
      callback);
};


/**
 * @param {!proto.themis.PersonnelTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addPersonnelToTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddPersonnelToTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddPersonnelToTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.PersonnelTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodDescriptor_ThemisApi_RemovePersonnelFromTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemovePersonnelFromTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.PersonnelTeamRequest,
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.PersonnelTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.PersonnelTeamRequest,
 *   !proto.themis.TeamResponse>}
 */
const methodInfo_ThemisApi_RemovePersonnelFromTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamResponse,
  /**
   * @param {!proto.themis.PersonnelTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.PersonnelTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removePersonnelFromTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemovePersonnelFromTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemovePersonnelFromTeam,
      callback);
};


/**
 * @param {!proto.themis.PersonnelTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removePersonnelFromTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemovePersonnelFromTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemovePersonnelFromTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Brand,
 *   !proto.themis.BrandResponse>}
 */
const methodDescriptor_ThemisApi_UpdateBrand = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateBrand',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Brand,
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Brand,
 *   !proto.themis.BrandResponse>}
 */
const methodInfo_ThemisApi_UpdateBrand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.BrandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.BrandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateBrand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateBrand,
      callback);
};


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.BrandResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateBrand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateBrand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Brand,
 *   !proto.themis.BrandResponse>}
 */
const methodDescriptor_ThemisApi_AddBrand = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddBrand',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Brand,
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Brand,
 *   !proto.themis.BrandResponse>}
 */
const methodInfo_ThemisApi_AddBrand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.BrandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.BrandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addBrand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddBrand,
      callback);
};


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.BrandResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addBrand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddBrand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Producer,
 *   !proto.themis.BrandResponse>}
 */
const methodDescriptor_ThemisApi_GetBrands = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetBrands',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Producer,
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Producer,
 *   !proto.themis.BrandResponse>}
 */
const methodInfo_ThemisApi_GetBrands = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.BrandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.BrandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getBrands =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetBrands',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetBrands,
      callback);
};


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.BrandResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getBrands =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetBrands',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetBrands);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Brand,
 *   !proto.themis.BrandResponse>}
 */
const methodDescriptor_ThemisApi_DeleteBrand = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteBrand',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Brand,
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Brand,
 *   !proto.themis.BrandResponse>}
 */
const methodInfo_ThemisApi_DeleteBrand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.BrandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.BrandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteBrand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteBrand,
      callback);
};


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.BrandResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteBrand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteBrand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Brand,
 *   !proto.themis.BrandResponse>}
 */
const methodDescriptor_ThemisApi_GetBrand = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetBrand',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Brand,
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Brand,
 *   !proto.themis.BrandResponse>}
 */
const methodInfo_ThemisApi_GetBrand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.BrandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.BrandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getBrand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetBrand,
      callback);
};


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.BrandResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getBrand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetBrand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddLogoRequest,
 *   !proto.themis.BrandResponse>}
 */
const methodDescriptor_ThemisApi_AddBrandLogo = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddBrandLogo',
  grpc.web.MethodType.UNARY,
  themis_common_pb.AddLogoRequest,
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.AddLogoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddLogoRequest,
 *   !proto.themis.BrandResponse>}
 */
const methodInfo_ThemisApi_AddBrandLogo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.AddLogoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AddLogoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.BrandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.BrandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addBrandLogo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddBrandLogo',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddBrandLogo,
      callback);
};


/**
 * @param {!proto.themis.AddLogoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.BrandResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addBrandLogo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddBrandLogo',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddBrandLogo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Brand,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetBrandUsers = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetBrandUsers',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Brand,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Brand,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_GetBrandUsers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getBrandUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetBrandUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetBrandUsers,
      callback);
};


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getBrandUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetBrandUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetBrandUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.BrandUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_AddBrandUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddBrandUser',
  grpc.web.MethodType.UNARY,
  proto.themis.BrandUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.BrandUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.BrandUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_AddBrandUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.BrandUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.BrandUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addBrandUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddBrandUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddBrandUser,
      callback);
};


/**
 * @param {!proto.themis.BrandUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addBrandUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddBrandUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddBrandUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.BrandUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_EditBrandUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditBrandUser',
  grpc.web.MethodType.UNARY,
  proto.themis.BrandUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.BrandUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.BrandUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_EditBrandUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.BrandUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.BrandUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editBrandUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditBrandUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditBrandUser,
      callback);
};


/**
 * @param {!proto.themis.BrandUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editBrandUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditBrandUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditBrandUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.BrandUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_DeleteBrandUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteBrandUser',
  grpc.web.MethodType.UNARY,
  proto.themis.BrandUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.BrandUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.BrandUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_DeleteBrandUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.BrandUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.BrandUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteBrandUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteBrandUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteBrandUser,
      callback);
};


/**
 * @param {!proto.themis.BrandUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteBrandUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteBrandUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteBrandUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Brand,
 *   !proto.themis.PermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetBrandPermissions = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetBrandPermissions',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Brand,
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Brand,
 *   !proto.themis.PermissionsResponse>}
 */
const methodInfo_ThemisApi_GetBrandPermissions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.PermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.PermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getBrandPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetBrandPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetBrandPermissions,
      callback);
};


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.PermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getBrandPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetBrandPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetBrandPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodDescriptor_ThemisApi_UpdateEvent = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateEvent',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodInfo_ThemisApi_UpdateEvent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEvent,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodDescriptor_ThemisApi_AddEvent = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEvent',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodInfo_ThemisApi_AddEvent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEvent,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Brand,
 *   !proto.themis.EventResponse>}
 */
const methodDescriptor_ThemisApi_GetEvents = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEvents',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Brand,
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Brand,
 *   !proto.themis.EventResponse>}
 */
const methodInfo_ThemisApi_GetEvents = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Brand} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEvents',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEvents,
      callback);
};


/**
 * @param {!proto.themis.Brand} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEvents',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodDescriptor_ThemisApi_GetEvent = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEvent',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodInfo_ThemisApi_GetEvent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEvent,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodDescriptor_ThemisApi_DeleteEvent = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEvent',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodInfo_ThemisApi_DeleteEvent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEvent,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodDescriptor_ThemisApi_EventRegistrationLive = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EventRegistrationLive',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodInfo_ThemisApi_EventRegistrationLive = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.eventRegistrationLive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EventRegistrationLive',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EventRegistrationLive,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.eventRegistrationLive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EventRegistrationLive',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EventRegistrationLive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodDescriptor_ThemisApi_GetOneEvent = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetOneEvent',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.EventResponse>}
 */
const methodInfo_ThemisApi_GetOneEvent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getOneEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetOneEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetOneEvent,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getOneEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetOneEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetOneEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddLogoRequest,
 *   !proto.themis.EventResponse>}
 */
const methodDescriptor_ThemisApi_AddEventLogo = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventLogo',
  grpc.web.MethodType.UNARY,
  themis_common_pb.AddLogoRequest,
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.AddLogoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddLogoRequest,
 *   !proto.themis.EventResponse>}
 */
const methodInfo_ThemisApi_AddEventLogo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.AddLogoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AddLogoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventLogo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventLogo',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventLogo,
      callback);
};


/**
 * @param {!proto.themis.AddLogoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventLogo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventLogo',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventLogo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_SendEventEmails = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/SendEventEmails',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_SendEventEmails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.sendEventEmails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/SendEventEmails',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SendEventEmails,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.sendEventEmails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/SendEventEmails',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SendEventEmails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.CountEventEmailsResponse>}
 */
const methodDescriptor_ThemisApi_CountEventEmails = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/CountEventEmails',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.CountEventEmailsResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CountEventEmailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.CountEventEmailsResponse>}
 */
const methodInfo_ThemisApi_CountEventEmails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.CountEventEmailsResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CountEventEmailsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.CountEventEmailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.CountEventEmailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.countEventEmails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/CountEventEmails',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CountEventEmails,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.CountEventEmailsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.countEventEmails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/CountEventEmails',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CountEventEmails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetEventUsers = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventUsers',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_GetEventUsers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventUsers,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventUsers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_AddEventUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventUser',
  grpc.web.MethodType.UNARY,
  proto.themis.EventUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.EventUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_AddEventUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.EventUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventUser,
      callback);
};


/**
 * @param {!proto.themis.EventUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_EditEventUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditEventUser',
  grpc.web.MethodType.UNARY,
  proto.themis.EventUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.EventUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_EditEventUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.EventUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editEventUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditEventUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventUser,
      callback);
};


/**
 * @param {!proto.themis.EventUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editEventUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditEventUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodDescriptor_ThemisApi_DeleteEventUser = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventUser',
  grpc.web.MethodType.UNARY,
  proto.themis.EventUser,
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.EventUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventUser,
 *   !proto.themis.UserPermissionsResponse>}
 */
const methodInfo_ThemisApi_DeleteEventUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.UserPermissionsResponse,
  /**
   * @param {!proto.themis.EventUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.UserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.UserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.UserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventUser,
      callback);
};


/**
 * @param {!proto.themis.EventUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.UserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventUser',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.PermissionsResponse>}
 */
const methodDescriptor_ThemisApi_GetEventPermissions = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventPermissions',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.PermissionsResponse>}
 */
const methodInfo_ThemisApi_GetEventPermissions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.PermissionsResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.PermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.PermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.PermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventPermissions,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.PermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventPermissions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDate,
 *   !proto.themis.EventDatesResponse>}
 */
const methodDescriptor_ThemisApi_AddEventDate = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventDate',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDate,
  proto.themis.EventDatesResponse,
  /**
   * @param {!proto.themis.EventDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDatesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDate,
 *   !proto.themis.EventDatesResponse>}
 */
const methodInfo_ThemisApi_AddEventDate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDatesResponse,
  /**
   * @param {!proto.themis.EventDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDatesResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDate',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDate,
      callback);
};


/**
 * @param {!proto.themis.EventDate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDatesResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDate',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDate,
 *   !proto.themis.EventDatesResponse>}
 */
const methodDescriptor_ThemisApi_DeleteEventDate = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventDate',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDate,
  proto.themis.EventDatesResponse,
  /**
   * @param {!proto.themis.EventDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDatesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDate,
 *   !proto.themis.EventDatesResponse>}
 */
const methodInfo_ThemisApi_DeleteEventDate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDatesResponse,
  /**
   * @param {!proto.themis.EventDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDatesResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDate',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDate,
      callback);
};


/**
 * @param {!proto.themis.EventDate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDatesResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDate',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventRegistrationCode,
 *   !proto.themis.EventRegistrationCode>}
 */
const methodDescriptor_ThemisApi_FindEventRegistrationCodeForEvent = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/FindEventRegistrationCodeForEvent',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventRegistrationCode,
  themis_common_pb.EventRegistrationCode,
  /**
   * @param {!proto.themis.EventRegistrationCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventRegistrationCode.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventRegistrationCode,
 *   !proto.themis.EventRegistrationCode>}
 */
const methodInfo_ThemisApi_FindEventRegistrationCodeForEvent = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventRegistrationCode,
  /**
   * @param {!proto.themis.EventRegistrationCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventRegistrationCode.deserializeBinary
);


/**
 * @param {!proto.themis.EventRegistrationCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventRegistrationCode)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventRegistrationCode>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.findEventRegistrationCodeForEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/FindEventRegistrationCodeForEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_FindEventRegistrationCodeForEvent,
      callback);
};


/**
 * @param {!proto.themis.EventRegistrationCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventRegistrationCode>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.findEventRegistrationCodeForEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/FindEventRegistrationCodeForEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_FindEventRegistrationCodeForEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventRegistrationCode,
 *   !proto.themis.EventRegistrationCodesResponse>}
 */
const methodDescriptor_ThemisApi_AddEventRegistrationCode = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventRegistrationCode',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventRegistrationCode,
  proto.themis.EventRegistrationCodesResponse,
  /**
   * @param {!proto.themis.EventRegistrationCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventRegistrationCodesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventRegistrationCode,
 *   !proto.themis.EventRegistrationCodesResponse>}
 */
const methodInfo_ThemisApi_AddEventRegistrationCode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventRegistrationCodesResponse,
  /**
   * @param {!proto.themis.EventRegistrationCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventRegistrationCodesResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventRegistrationCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventRegistrationCodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventRegistrationCodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventRegistrationCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventRegistrationCode',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventRegistrationCode,
      callback);
};


/**
 * @param {!proto.themis.EventRegistrationCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventRegistrationCodesResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventRegistrationCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventRegistrationCode',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventRegistrationCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventRegistrationCode,
 *   !proto.themis.EventRegistrationCodesResponse>}
 */
const methodDescriptor_ThemisApi_UpdateEventRegistrationCode = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateEventRegistrationCode',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventRegistrationCode,
  proto.themis.EventRegistrationCodesResponse,
  /**
   * @param {!proto.themis.EventRegistrationCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventRegistrationCodesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventRegistrationCode,
 *   !proto.themis.EventRegistrationCodesResponse>}
 */
const methodInfo_ThemisApi_UpdateEventRegistrationCode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventRegistrationCodesResponse,
  /**
   * @param {!proto.themis.EventRegistrationCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventRegistrationCodesResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventRegistrationCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventRegistrationCodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventRegistrationCodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateEventRegistrationCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEventRegistrationCode',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEventRegistrationCode,
      callback);
};


/**
 * @param {!proto.themis.EventRegistrationCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventRegistrationCodesResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateEventRegistrationCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEventRegistrationCode',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEventRegistrationCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventRegistrationCode,
 *   !proto.themis.EventRegistrationCodesResponse>}
 */
const methodDescriptor_ThemisApi_DeleteEventRegistrationCode = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventRegistrationCode',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventRegistrationCode,
  proto.themis.EventRegistrationCodesResponse,
  /**
   * @param {!proto.themis.EventRegistrationCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventRegistrationCodesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventRegistrationCode,
 *   !proto.themis.EventRegistrationCodesResponse>}
 */
const methodInfo_ThemisApi_DeleteEventRegistrationCode = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventRegistrationCodesResponse,
  /**
   * @param {!proto.themis.EventRegistrationCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventRegistrationCodesResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventRegistrationCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventRegistrationCodesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventRegistrationCodesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventRegistrationCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventRegistrationCode',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventRegistrationCode,
      callback);
};


/**
 * @param {!proto.themis.EventRegistrationCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventRegistrationCodesResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventRegistrationCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventRegistrationCode',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventRegistrationCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventRegistrationPreRequest,
 *   !proto.themis.EventRegistrationPreResponse>}
 */
const methodDescriptor_ThemisApi_GetEventRegistrationPre = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventRegistrationPre',
  grpc.web.MethodType.UNARY,
  proto.themis.EventRegistrationPreRequest,
  proto.themis.EventRegistrationPreResponse,
  /**
   * @param {!proto.themis.EventRegistrationPreRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventRegistrationPreResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventRegistrationPreRequest,
 *   !proto.themis.EventRegistrationPreResponse>}
 */
const methodInfo_ThemisApi_GetEventRegistrationPre = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventRegistrationPreResponse,
  /**
   * @param {!proto.themis.EventRegistrationPreRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventRegistrationPreResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventRegistrationPreRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventRegistrationPreResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventRegistrationPreResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventRegistrationPre =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventRegistrationPre',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventRegistrationPre,
      callback);
};


/**
 * @param {!proto.themis.EventRegistrationPreRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventRegistrationPreResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventRegistrationPre =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventRegistrationPre',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventRegistrationPre);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloor,
 *   !proto.themis.EventFloorsResponse>}
 */
const methodDescriptor_ThemisApi_AddEventFloor = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventFloor',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloor,
  proto.themis.EventFloorsResponse,
  /**
   * @param {!proto.themis.EventFloor} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventFloorsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloor,
 *   !proto.themis.EventFloorsResponse>}
 */
const methodInfo_ThemisApi_AddEventFloor = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventFloorsResponse,
  /**
   * @param {!proto.themis.EventFloor} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventFloorsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloor} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventFloor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventFloor',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventFloor,
      callback);
};


/**
 * @param {!proto.themis.EventFloor} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventFloor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventFloor',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventFloor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloor,
 *   !proto.themis.EventFloorsResponse>}
 */
const methodDescriptor_ThemisApi_DeleteEventFloor = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventFloor',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloor,
  proto.themis.EventFloorsResponse,
  /**
   * @param {!proto.themis.EventFloor} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventFloorsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloor,
 *   !proto.themis.EventFloorsResponse>}
 */
const methodInfo_ThemisApi_DeleteEventFloor = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventFloorsResponse,
  /**
   * @param {!proto.themis.EventFloor} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventFloorsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloor} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventFloor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventFloor',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventFloor,
      callback);
};


/**
 * @param {!proto.themis.EventFloor} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventFloor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventFloor',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventFloor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.SortFloorsRequest,
 *   !proto.themis.EventFloorsResponse>}
 */
const methodDescriptor_ThemisApi_SortEventFloors = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/SortEventFloors',
  grpc.web.MethodType.UNARY,
  proto.themis.SortFloorsRequest,
  proto.themis.EventFloorsResponse,
  /**
   * @param {!proto.themis.SortFloorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventFloorsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.SortFloorsRequest,
 *   !proto.themis.EventFloorsResponse>}
 */
const methodInfo_ThemisApi_SortEventFloors = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventFloorsResponse,
  /**
   * @param {!proto.themis.SortFloorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventFloorsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.SortFloorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.sortEventFloors =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/SortEventFloors',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SortEventFloors,
      callback);
};


/**
 * @param {!proto.themis.SortFloorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.sortEventFloors =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/SortEventFloors',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SortEventFloors);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanel,
 *   !proto.themis.EventFloorDateJudgingPanelResponse>}
 */
const methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventFloorDateJudgingPanel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDateJudgingPanel,
  themis_common_pb.EventFloorDateJudgingPanelResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanel,
 *   !proto.themis.EventFloorDateJudgingPanelResponse>}
 */
const methodInfo_ThemisApi_AddEventFloorDateJudgingPanel = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventFloorDateJudgingPanel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventFloorDateJudgingPanel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanel,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventFloorDateJudgingPanel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventFloorDateJudgingPanel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanelShift = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventFloorDateJudgingPanelShift',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDateJudgingPanelShift,
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodInfo_ThemisApi_AddEventFloorDateJudgingPanelShift = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventFloorDateJudgingPanelShift =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventFloorDateJudgingPanelShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanelShift,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventFloorDateJudgingPanelShift =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventFloorDateJudgingPanelShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanelShift);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanel,
 *   !proto.themis.EventFloorDateJudgingPanel>}
 */
const methodDescriptor_ThemisApi_GetEventFloorDateJudgingPanel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventFloorDateJudgingPanel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDateJudgingPanel,
  themis_common_pb.EventFloorDateJudgingPanel,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanel.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanel,
 *   !proto.themis.EventFloorDateJudgingPanel>}
 */
const methodInfo_ThemisApi_GetEventFloorDateJudgingPanel = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanel,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanel.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanel)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanel>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventFloorDateJudgingPanel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventFloorDateJudgingPanel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventFloorDateJudgingPanel,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanel>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventFloorDateJudgingPanel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventFloorDateJudgingPanel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventFloorDateJudgingPanel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.EventFloorDateJudgingPanelShift>}
 */
const methodDescriptor_ThemisApi_GetEventFloorDateJudgingPanelShift = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventFloorDateJudgingPanelShift',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDateJudgingPanelShift,
  themis_common_pb.EventFloorDateJudgingPanelShift,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShift.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.EventFloorDateJudgingPanelShift>}
 */
const methodInfo_ThemisApi_GetEventFloorDateJudgingPanelShift = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelShift,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShift.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelShift)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelShift>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventFloorDateJudgingPanelShift =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventFloorDateJudgingPanelShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventFloorDateJudgingPanelShift,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelShift>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventFloorDateJudgingPanelShift =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventFloorDateJudgingPanelShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventFloorDateJudgingPanelShift);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.EventDivisionShifts>}
 */
const methodDescriptor_ThemisApi_GetEventDivisionShiftsByShift = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventDivisionShiftsByShift',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDateJudgingPanelShift,
  proto.themis.EventDivisionShifts,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionShifts.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.EventDivisionShifts>}
 */
const methodInfo_ThemisApi_GetEventDivisionShiftsByShift = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDivisionShifts,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionShifts.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionShifts)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionShifts>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventDivisionShiftsByShift =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionShiftsByShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionShiftsByShift,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionShifts>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventDivisionShiftsByShift =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionShiftsByShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionShiftsByShift);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanel,
 *   !proto.themis.EventFloorDateJudgingPanelResponse>}
 */
const methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventFloorDateJudgingPanel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDateJudgingPanel,
  themis_common_pb.EventFloorDateJudgingPanelResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanel,
 *   !proto.themis.EventFloorDateJudgingPanelResponse>}
 */
const methodInfo_ThemisApi_DeleteEventFloorDateJudgingPanel = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventFloorDateJudgingPanel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventFloorDateJudgingPanel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanel,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventFloorDateJudgingPanel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventFloorDateJudgingPanel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanelShift = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventFloorDateJudgingPanelShift',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDateJudgingPanelShift,
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodInfo_ThemisApi_DeleteEventFloorDateJudgingPanelShift = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventFloorDateJudgingPanelShift =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventFloorDateJudgingPanelShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanelShift,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventFloorDateJudgingPanelShift =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventFloorDateJudgingPanelShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanelShift);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanel,
 *   !proto.themis.EventFloorDateJudgingPanelResponse>}
 */
const methodDescriptor_ThemisApi_UpdateEventFloorDateJudgingPanel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateEventFloorDateJudgingPanel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDateJudgingPanel,
  themis_common_pb.EventFloorDateJudgingPanelResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanel,
 *   !proto.themis.EventFloorDateJudgingPanelResponse>}
 */
const methodInfo_ThemisApi_UpdateEventFloorDateJudgingPanel = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateEventFloorDateJudgingPanel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEventFloorDateJudgingPanel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEventFloorDateJudgingPanel,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateEventFloorDateJudgingPanel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEventFloorDateJudgingPanel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEventFloorDateJudgingPanel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodDescriptor_ThemisApi_UpdateEventFloorDateJudgingPanelShift = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateEventFloorDateJudgingPanelShift',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDateJudgingPanelShift,
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodInfo_ThemisApi_UpdateEventFloorDateJudgingPanelShift = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateEventFloorDateJudgingPanelShift =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEventFloorDateJudgingPanelShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEventFloorDateJudgingPanelShift,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateEventFloorDateJudgingPanelShift =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEventFloorDateJudgingPanelShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEventFloorDateJudgingPanelShift);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanelShiftJudge = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventFloorDateJudgingPanelShiftJudge',
  grpc.web.MethodType.UNARY,
  proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest,
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodInfo_ThemisApi_AddEventFloorDateJudgingPanelShiftJudge = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventFloorDateJudgingPanelShiftJudge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventFloorDateJudgingPanelShiftJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanelShiftJudge,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventFloorDateJudgingPanelShiftJudge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventFloorDateJudgingPanelShiftJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanelShiftJudge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanelShiftJudge = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventFloorDateJudgingPanelShiftJudge',
  grpc.web.MethodType.UNARY,
  proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest,
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodInfo_ThemisApi_DeleteEventFloorDateJudgingPanelShiftJudge = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventFloorDateJudgingPanelShiftJudge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventFloorDateJudgingPanelShiftJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanelShiftJudge,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftJudgeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventFloorDateJudgingPanelShiftJudge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventFloorDateJudgingPanelShiftJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanelShiftJudge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanelShiftDivision = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventFloorDateJudgingPanelShiftDivision',
  grpc.web.MethodType.UNARY,
  proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodInfo_ThemisApi_AddEventFloorDateJudgingPanelShiftDivision = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventFloorDateJudgingPanelShiftDivision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventFloorDateJudgingPanelShiftDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanelShiftDivision,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventFloorDateJudgingPanelShiftDivision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventFloorDateJudgingPanelShiftDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventFloorDateJudgingPanelShiftDivision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanelShiftDivision = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventFloorDateJudgingPanelShiftDivision',
  grpc.web.MethodType.UNARY,
  proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodInfo_ThemisApi_DeleteEventFloorDateJudgingPanelShiftDivision = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventFloorDateJudgingPanelShiftDivision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventFloorDateJudgingPanelShiftDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanelShiftDivision,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventFloorDateJudgingPanelShiftDivision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventFloorDateJudgingPanelShiftDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventFloorDateJudgingPanelShiftDivision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodDescriptor_ThemisApi_ApproveEventFloorDateJudgingPanelShiftDivision = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ApproveEventFloorDateJudgingPanelShiftDivision',
  grpc.web.MethodType.UNARY,
  proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodInfo_ThemisApi_ApproveEventFloorDateJudgingPanelShiftDivision = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.approveEventFloorDateJudgingPanelShiftDivision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ApproveEventFloorDateJudgingPanelShiftDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ApproveEventFloorDateJudgingPanelShiftDivision,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.approveEventFloorDateJudgingPanelShiftDivision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ApproveEventFloorDateJudgingPanelShiftDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ApproveEventFloorDateJudgingPanelShiftDivision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodDescriptor_ThemisApi_SortEventFloorDateJudgingPanelShiftDivision = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/SortEventFloorDateJudgingPanelShiftDivision',
  grpc.web.MethodType.UNARY,
  proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
 *   !proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 */
const methodInfo_ThemisApi_SortEventFloorDateJudgingPanelShiftDivision = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.sortEventFloorDateJudgingPanelShiftDivision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/SortEventFloorDateJudgingPanelShiftDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SortEventFloorDateJudgingPanelShiftDivision,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.sortEventFloorDateJudgingPanelShiftDivision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/SortEventFloorDateJudgingPanelShiftDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SortEventFloorDateJudgingPanelShiftDivision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDate,
 *   !proto.themis.EventFloorDateJudgingPanelResponse>}
 */
const methodDescriptor_ThemisApi_CopyPreviousFloorDate = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/CopyPreviousFloorDate',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDate,
  themis_common_pb.EventFloorDateJudgingPanelResponse,
  /**
   * @param {!proto.themis.EventFloorDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDate,
 *   !proto.themis.EventFloorDateJudgingPanelResponse>}
 */
const methodInfo_ThemisApi_CopyPreviousFloorDate = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventFloorDateJudgingPanelResponse,
  /**
   * @param {!proto.themis.EventFloorDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventFloorDateJudgingPanelResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorDateJudgingPanelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorDateJudgingPanelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.copyPreviousFloorDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/CopyPreviousFloorDate',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CopyPreviousFloorDate,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorDateJudgingPanelResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.copyPreviousFloorDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/CopyPreviousFloorDate',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CopyPreviousFloorDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Division,
 *   !proto.themis.DivisionsResponse>}
 */
const methodDescriptor_ThemisApi_AddDivision = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddDivision',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Division,
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.Division} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Division,
 *   !proto.themis.DivisionsResponse>}
 */
const methodInfo_ThemisApi_AddDivision = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.Division} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Division} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DivisionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DivisionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addDivision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddDivision,
      callback);
};


/**
 * @param {!proto.themis.Division} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DivisionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addDivision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddDivision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Division,
 *   !proto.themis.DivisionsResponse>}
 */
const methodDescriptor_ThemisApi_UpdateDivision = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateDivision',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Division,
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.Division} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Division,
 *   !proto.themis.DivisionsResponse>}
 */
const methodInfo_ThemisApi_UpdateDivision = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.Division} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Division} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DivisionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DivisionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateDivision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateDivision,
      callback);
};


/**
 * @param {!proto.themis.Division} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DivisionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateDivision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateDivision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Division,
 *   !proto.themis.DivisionsResponse>}
 */
const methodDescriptor_ThemisApi_DeleteDivision = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteDivision',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Division,
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.Division} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Division,
 *   !proto.themis.DivisionsResponse>}
 */
const methodInfo_ThemisApi_DeleteDivision = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.Division} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Division} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DivisionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DivisionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteDivision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteDivision,
      callback);
};


/**
 * @param {!proto.themis.Division} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DivisionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteDivision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteDivision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.DivisionLevel,
 *   !proto.themis.DivisionLevel>}
 */
const methodDescriptor_ThemisApi_AddDivisionLevel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddDivisionLevel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.DivisionLevel,
  themis_common_pb.DivisionLevel,
  /**
   * @param {!proto.themis.DivisionLevel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.DivisionLevel.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.DivisionLevel,
 *   !proto.themis.DivisionLevel>}
 */
const methodInfo_ThemisApi_AddDivisionLevel = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.DivisionLevel,
  /**
   * @param {!proto.themis.DivisionLevel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.DivisionLevel.deserializeBinary
);


/**
 * @param {!proto.themis.DivisionLevel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DivisionLevel)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DivisionLevel>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addDivisionLevel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddDivisionLevel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddDivisionLevel,
      callback);
};


/**
 * @param {!proto.themis.DivisionLevel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DivisionLevel>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addDivisionLevel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddDivisionLevel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddDivisionLevel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.DivisionLevel,
 *   !proto.themis.DivisionLevel>}
 */
const methodDescriptor_ThemisApi_UpdateDivisionLevel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateDivisionLevel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.DivisionLevel,
  themis_common_pb.DivisionLevel,
  /**
   * @param {!proto.themis.DivisionLevel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.DivisionLevel.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.DivisionLevel,
 *   !proto.themis.DivisionLevel>}
 */
const methodInfo_ThemisApi_UpdateDivisionLevel = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.DivisionLevel,
  /**
   * @param {!proto.themis.DivisionLevel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.DivisionLevel.deserializeBinary
);


/**
 * @param {!proto.themis.DivisionLevel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DivisionLevel)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DivisionLevel>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateDivisionLevel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateDivisionLevel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateDivisionLevel,
      callback);
};


/**
 * @param {!proto.themis.DivisionLevel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DivisionLevel>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateDivisionLevel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateDivisionLevel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateDivisionLevel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.DivisionLevel,
 *   !proto.themis.DivisionLevel>}
 */
const methodDescriptor_ThemisApi_DeleteDivisionLevel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteDivisionLevel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.DivisionLevel,
  themis_common_pb.DivisionLevel,
  /**
   * @param {!proto.themis.DivisionLevel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.DivisionLevel.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.DivisionLevel,
 *   !proto.themis.DivisionLevel>}
 */
const methodInfo_ThemisApi_DeleteDivisionLevel = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.DivisionLevel,
  /**
   * @param {!proto.themis.DivisionLevel} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.DivisionLevel.deserializeBinary
);


/**
 * @param {!proto.themis.DivisionLevel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DivisionLevel)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DivisionLevel>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteDivisionLevel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteDivisionLevel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteDivisionLevel,
      callback);
};


/**
 * @param {!proto.themis.DivisionLevel} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DivisionLevel>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteDivisionLevel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteDivisionLevel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteDivisionLevel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodDescriptor_ThemisApi_GetEventDivisionsByEvent = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventDivisionsByEvent',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodInfo_ThemisApi_GetEventDivisionsByEvent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventDivisionsByEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionsByEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionsByEvent,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventDivisionsByEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionsByEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionsByEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.EventDivisionUsageResponse>}
 */
const methodDescriptor_ThemisApi_GetEventDivisionUsesByEvent = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventDivisionUsesByEvent',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.EventDivisionUsageResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionUsageResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.EventDivisionUsageResponse>}
 */
const methodInfo_ThemisApi_GetEventDivisionUsesByEvent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDivisionUsageResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionUsageResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionUsageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionUsageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventDivisionUsesByEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionUsesByEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionUsesByEvent,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionUsageResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventDivisionUsesByEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionUsesByEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionUsesByEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodDescriptor_ThemisApi_GetEventDivisionsByEventThin = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventDivisionsByEventThin',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodInfo_ThemisApi_GetEventDivisionsByEventThin = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventDivisionsByEventThin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionsByEventThin',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionsByEventThin,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventDivisionsByEventThin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionsByEventThin',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionsByEventThin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivision,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodDescriptor_ThemisApi_AddEventDivisions = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventDivisions',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivision,
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.EventDivision} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivision,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodInfo_ThemisApi_AddEventDivisions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.EventDivision} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivision} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventDivisions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisions,
      callback);
};


/**
 * @param {!proto.themis.EventDivision} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventDivisions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivision,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodDescriptor_ThemisApi_UpdateEventDivision = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateEventDivision',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivision,
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.EventDivision} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivision,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodInfo_ThemisApi_UpdateEventDivision = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.EventDivision} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivision} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateEventDivision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEventDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEventDivision,
      callback);
};


/**
 * @param {!proto.themis.EventDivision} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateEventDivision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEventDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEventDivision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivision,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodDescriptor_ThemisApi_DeleteEventDivisions = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventDivisions',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivision,
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.EventDivision} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivision,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodInfo_ThemisApi_DeleteEventDivisions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.EventDivision} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivision} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventDivisions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDivisions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDivisions,
      callback);
};


/**
 * @param {!proto.themis.EventDivision} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventDivisions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDivisions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDivisions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.DivisionSplit,
 *   !proto.themis.DivisionsResponse>}
 */
const methodDescriptor_ThemisApi_AddDivisionSplit = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddDivisionSplit',
  grpc.web.MethodType.UNARY,
  themis_common_pb.DivisionSplit,
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.DivisionSplit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.DivisionSplit,
 *   !proto.themis.DivisionsResponse>}
 */
const methodInfo_ThemisApi_AddDivisionSplit = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.DivisionSplit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.DivisionSplit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DivisionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DivisionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addDivisionSplit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddDivisionSplit',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddDivisionSplit,
      callback);
};


/**
 * @param {!proto.themis.DivisionSplit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DivisionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addDivisionSplit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddDivisionSplit',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddDivisionSplit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.DivisionSplit,
 *   !proto.themis.DivisionsResponse>}
 */
const methodDescriptor_ThemisApi_UpdateDivisionSplit = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateDivisionSplit',
  grpc.web.MethodType.UNARY,
  themis_common_pb.DivisionSplit,
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.DivisionSplit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.DivisionSplit,
 *   !proto.themis.DivisionsResponse>}
 */
const methodInfo_ThemisApi_UpdateDivisionSplit = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.DivisionSplit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.DivisionSplit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DivisionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DivisionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateDivisionSplit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateDivisionSplit',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateDivisionSplit,
      callback);
};


/**
 * @param {!proto.themis.DivisionSplit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DivisionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateDivisionSplit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateDivisionSplit',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateDivisionSplit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.DivisionSplit,
 *   !proto.themis.DivisionsResponse>}
 */
const methodDescriptor_ThemisApi_DeleteDivisionSplit = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteDivisionSplit',
  grpc.web.MethodType.UNARY,
  themis_common_pb.DivisionSplit,
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.DivisionSplit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.DivisionSplit,
 *   !proto.themis.DivisionsResponse>}
 */
const methodInfo_ThemisApi_DeleteDivisionSplit = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DivisionsResponse,
  /**
   * @param {!proto.themis.DivisionSplit} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DivisionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.DivisionSplit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DivisionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DivisionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteDivisionSplit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteDivisionSplit',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteDivisionSplit,
      callback);
};


/**
 * @param {!proto.themis.DivisionSplit} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DivisionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteDivisionSplit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteDivisionSplit',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteDivisionSplit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamDisplayPricing>}
 */
const methodDescriptor_ThemisApi_GetCalculatedDisplayPricing = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetCalculatedDisplayPricing',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeamDisplayPricing,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeamDisplayPricing.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamDisplayPricing>}
 */
const methodInfo_ThemisApi_GetCalculatedDisplayPricing = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeamDisplayPricing,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeamDisplayPricing.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamDisplayPricing)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamDisplayPricing>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getCalculatedDisplayPricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetCalculatedDisplayPricing',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCalculatedDisplayPricing,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamDisplayPricing>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getCalculatedDisplayPricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetCalculatedDisplayPricing',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCalculatedDisplayPricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventPricing,
 *   !proto.themis.EventResponse>}
 */
const methodDescriptor_ThemisApi_AddEventPricing = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventPricing',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventPricing,
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.EventPricing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventPricing,
 *   !proto.themis.EventResponse>}
 */
const methodInfo_ThemisApi_AddEventPricing = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventResponse,
  /**
   * @param {!proto.themis.EventPricing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventPricing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventPricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventPricing',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventPricing,
      callback);
};


/**
 * @param {!proto.themis.EventPricing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventPricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventPricing',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventPricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionPricing,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodDescriptor_ThemisApi_AddEventDivisionPricing = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventDivisionPricing',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionPricing,
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.EventDivisionPricing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionPricing,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodInfo_ThemisApi_AddEventDivisionPricing = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.EventDivisionPricing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionPricing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventDivisionPricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionPricing',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionPricing,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionPricing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventDivisionPricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionPricing',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionPricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddEventDivisionPricingBulkRequest,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodDescriptor_ThemisApi_AddEventDivisionPricingBulk = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventDivisionPricingBulk',
  grpc.web.MethodType.UNARY,
  proto.themis.AddEventDivisionPricingBulkRequest,
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.AddEventDivisionPricingBulkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddEventDivisionPricingBulkRequest,
 *   !proto.themis.EventDivisionResponse>}
 */
const methodInfo_ThemisApi_AddEventDivisionPricingBulk = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDivisionResponse,
  /**
   * @param {!proto.themis.AddEventDivisionPricingBulkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AddEventDivisionPricingBulkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventDivisionPricingBulk =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionPricingBulk',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionPricingBulk,
      callback);
};


/**
 * @param {!proto.themis.AddEventDivisionPricingBulkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventDivisionPricingBulk =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionPricingBulk',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionPricingBulk);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.GetAccountBalanceRequest,
 *   !proto.themis.GetAccountBalanceResponse>}
 */
const methodDescriptor_ThemisApi_GetAccountBalance = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetAccountBalance',
  grpc.web.MethodType.UNARY,
  proto.themis.GetAccountBalanceRequest,
  proto.themis.GetAccountBalanceResponse,
  /**
   * @param {!proto.themis.GetAccountBalanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetAccountBalanceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.GetAccountBalanceRequest,
 *   !proto.themis.GetAccountBalanceResponse>}
 */
const methodInfo_ThemisApi_GetAccountBalance = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GetAccountBalanceResponse,
  /**
   * @param {!proto.themis.GetAccountBalanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetAccountBalanceResponse.deserializeBinary
);


/**
 * @param {!proto.themis.GetAccountBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GetAccountBalanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GetAccountBalanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getAccountBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetAccountBalance',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAccountBalance,
      callback);
};


/**
 * @param {!proto.themis.GetAccountBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GetAccountBalanceResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getAccountBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetAccountBalance',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAccountBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.GetCurrentDepositResponse>}
 */
const methodDescriptor_ThemisApi_GetCurrentDeposit = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetCurrentDeposit',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  proto.themis.GetCurrentDepositResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetCurrentDepositResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.GetCurrentDepositResponse>}
 */
const methodInfo_ThemisApi_GetCurrentDeposit = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GetCurrentDepositResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetCurrentDepositResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GetCurrentDepositResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GetCurrentDepositResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getCurrentDeposit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetCurrentDeposit',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCurrentDeposit,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GetCurrentDepositResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getCurrentDeposit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetCurrentDeposit',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCurrentDeposit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.GetCreditDepositByFinancialTransactionIDRequest,
 *   !proto.themis.GetCurrentDepositResponse>}
 */
const methodDescriptor_ThemisApi_GetCreditDepositByFinancialTransactionID = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetCreditDepositByFinancialTransactionID',
  grpc.web.MethodType.UNARY,
  proto.themis.GetCreditDepositByFinancialTransactionIDRequest,
  proto.themis.GetCurrentDepositResponse,
  /**
   * @param {!proto.themis.GetCreditDepositByFinancialTransactionIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetCurrentDepositResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.GetCreditDepositByFinancialTransactionIDRequest,
 *   !proto.themis.GetCurrentDepositResponse>}
 */
const methodInfo_ThemisApi_GetCreditDepositByFinancialTransactionID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GetCurrentDepositResponse,
  /**
   * @param {!proto.themis.GetCreditDepositByFinancialTransactionIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetCurrentDepositResponse.deserializeBinary
);


/**
 * @param {!proto.themis.GetCreditDepositByFinancialTransactionIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GetCurrentDepositResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GetCurrentDepositResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getCreditDepositByFinancialTransactionID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetCreditDepositByFinancialTransactionID',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCreditDepositByFinancialTransactionID,
      callback);
};


/**
 * @param {!proto.themis.GetCreditDepositByFinancialTransactionIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GetCurrentDepositResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getCreditDepositByFinancialTransactionID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetCreditDepositByFinancialTransactionID',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetCreditDepositByFinancialTransactionID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddPaymentRequest,
 *   !proto.themis.AddPaymentResponse>}
 */
const methodDescriptor_ThemisApi_AddPayment = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddPayment',
  grpc.web.MethodType.UNARY,
  proto.themis.AddPaymentRequest,
  proto.themis.AddPaymentResponse,
  /**
   * @param {!proto.themis.AddPaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AddPaymentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddPaymentRequest,
 *   !proto.themis.AddPaymentResponse>}
 */
const methodInfo_ThemisApi_AddPayment = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.AddPaymentResponse,
  /**
   * @param {!proto.themis.AddPaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AddPaymentResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AddPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.AddPaymentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.AddPaymentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addPayment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddPayment',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddPayment,
      callback);
};


/**
 * @param {!proto.themis.AddPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.AddPaymentResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addPayment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddPayment',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddPayment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddPaymentRequest,
 *   !proto.themis.ChargePaymentSourceResponse>}
 */
const methodDescriptor_ThemisApi_ChargePaymentSource = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ChargePaymentSource',
  grpc.web.MethodType.UNARY,
  proto.themis.AddPaymentRequest,
  proto.themis.ChargePaymentSourceResponse,
  /**
   * @param {!proto.themis.AddPaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ChargePaymentSourceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddPaymentRequest,
 *   !proto.themis.ChargePaymentSourceResponse>}
 */
const methodInfo_ThemisApi_ChargePaymentSource = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ChargePaymentSourceResponse,
  /**
   * @param {!proto.themis.AddPaymentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ChargePaymentSourceResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AddPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ChargePaymentSourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ChargePaymentSourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.chargePaymentSource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ChargePaymentSource',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ChargePaymentSource,
      callback);
};


/**
 * @param {!proto.themis.AddPaymentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ChargePaymentSourceResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.chargePaymentSource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ChargePaymentSource',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ChargePaymentSource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddFinancialTransactionRequest,
 *   !proto.themis.AddFinancialTransactionResponse>}
 */
const methodDescriptor_ThemisApi_AddFinancialTransaction = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddFinancialTransaction',
  grpc.web.MethodType.UNARY,
  proto.themis.AddFinancialTransactionRequest,
  proto.themis.AddFinancialTransactionResponse,
  /**
   * @param {!proto.themis.AddFinancialTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AddFinancialTransactionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddFinancialTransactionRequest,
 *   !proto.themis.AddFinancialTransactionResponse>}
 */
const methodInfo_ThemisApi_AddFinancialTransaction = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.AddFinancialTransactionResponse,
  /**
   * @param {!proto.themis.AddFinancialTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AddFinancialTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AddFinancialTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.AddFinancialTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.AddFinancialTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addFinancialTransaction =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddFinancialTransaction',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddFinancialTransaction,
      callback);
};


/**
 * @param {!proto.themis.AddFinancialTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.AddFinancialTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addFinancialTransaction =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddFinancialTransaction',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddFinancialTransaction);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDate,
 *   !proto.themis.EventFloorsResponse>}
 */
const methodDescriptor_ThemisApi_EditEventFloorDate = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditEventFloorDate',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDate,
  proto.themis.EventFloorsResponse,
  /**
   * @param {!proto.themis.EventFloorDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventFloorsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDate,
 *   !proto.themis.EventFloorsResponse>}
 */
const methodInfo_ThemisApi_EditEventFloorDate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventFloorsResponse,
  /**
   * @param {!proto.themis.EventFloorDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventFloorsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventFloorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventFloorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editEventFloorDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditEventFloorDate',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventFloorDate,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDate} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventFloorsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editEventFloorDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditEventFloorDate',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventFloorDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Season,
 *   !proto.themis.SeasonsResponse>}
 */
const methodDescriptor_ThemisApi_AddSeason = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddSeason',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Season,
  proto.themis.SeasonsResponse,
  /**
   * @param {!proto.themis.Season} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.SeasonsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Season,
 *   !proto.themis.SeasonsResponse>}
 */
const methodInfo_ThemisApi_AddSeason = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.SeasonsResponse,
  /**
   * @param {!proto.themis.Season} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.SeasonsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Season} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.SeasonsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.SeasonsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addSeason =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddSeason',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddSeason,
      callback);
};


/**
 * @param {!proto.themis.Season} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.SeasonsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addSeason =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddSeason',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddSeason);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Season,
 *   !proto.themis.SeasonsResponse>}
 */
const methodDescriptor_ThemisApi_UpdateSeason = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateSeason',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Season,
  proto.themis.SeasonsResponse,
  /**
   * @param {!proto.themis.Season} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.SeasonsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Season,
 *   !proto.themis.SeasonsResponse>}
 */
const methodInfo_ThemisApi_UpdateSeason = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.SeasonsResponse,
  /**
   * @param {!proto.themis.Season} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.SeasonsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Season} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.SeasonsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.SeasonsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateSeason =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateSeason',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateSeason,
      callback);
};


/**
 * @param {!proto.themis.Season} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.SeasonsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateSeason =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateSeason',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateSeason);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Season,
 *   !proto.themis.SeasonsResponse>}
 */
const methodDescriptor_ThemisApi_DeleteSeason = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteSeason',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Season,
  proto.themis.SeasonsResponse,
  /**
   * @param {!proto.themis.Season} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.SeasonsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Season,
 *   !proto.themis.SeasonsResponse>}
 */
const methodInfo_ThemisApi_DeleteSeason = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.SeasonsResponse,
  /**
   * @param {!proto.themis.Season} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.SeasonsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Season} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.SeasonsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.SeasonsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteSeason =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteSeason',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteSeason,
      callback);
};


/**
 * @param {!proto.themis.Season} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.SeasonsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteSeason =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteSeason',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteSeason);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.GetSeasonsRequest,
 *   !proto.themis.SeasonsResponse>}
 */
const methodDescriptor_ThemisApi_GetSeasons = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetSeasons',
  grpc.web.MethodType.UNARY,
  proto.themis.GetSeasonsRequest,
  proto.themis.SeasonsResponse,
  /**
   * @param {!proto.themis.GetSeasonsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.SeasonsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.GetSeasonsRequest,
 *   !proto.themis.SeasonsResponse>}
 */
const methodInfo_ThemisApi_GetSeasons = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.SeasonsResponse,
  /**
   * @param {!proto.themis.GetSeasonsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.SeasonsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.GetSeasonsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.SeasonsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.SeasonsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getSeasons =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetSeasons',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetSeasons,
      callback);
};


/**
 * @param {!proto.themis.GetSeasonsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.SeasonsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getSeasons =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetSeasons',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetSeasons);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.TeamScoringAuthority,
 *   !proto.themis.TeamScoringAuthorityResponse>}
 */
const methodDescriptor_ThemisApi_AddTeamScoringAuthority = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddTeamScoringAuthority',
  grpc.web.MethodType.UNARY,
  themis_common_pb.TeamScoringAuthority,
  proto.themis.TeamScoringAuthorityResponse,
  /**
   * @param {!proto.themis.TeamScoringAuthority} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScoringAuthorityResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.TeamScoringAuthority,
 *   !proto.themis.TeamScoringAuthorityResponse>}
 */
const methodInfo_ThemisApi_AddTeamScoringAuthority = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamScoringAuthorityResponse,
  /**
   * @param {!proto.themis.TeamScoringAuthority} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScoringAuthorityResponse.deserializeBinary
);


/**
 * @param {!proto.themis.TeamScoringAuthority} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamScoringAuthorityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamScoringAuthorityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addTeamScoringAuthority =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddTeamScoringAuthority',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddTeamScoringAuthority,
      callback);
};


/**
 * @param {!proto.themis.TeamScoringAuthority} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamScoringAuthorityResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addTeamScoringAuthority =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddTeamScoringAuthority',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddTeamScoringAuthority);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.TeamScoringAuthority,
 *   !proto.themis.TeamScoringAuthorityResponse>}
 */
const methodDescriptor_ThemisApi_DeleteTeamScoringAuthority = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteTeamScoringAuthority',
  grpc.web.MethodType.UNARY,
  themis_common_pb.TeamScoringAuthority,
  proto.themis.TeamScoringAuthorityResponse,
  /**
   * @param {!proto.themis.TeamScoringAuthority} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScoringAuthorityResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.TeamScoringAuthority,
 *   !proto.themis.TeamScoringAuthorityResponse>}
 */
const methodInfo_ThemisApi_DeleteTeamScoringAuthority = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamScoringAuthorityResponse,
  /**
   * @param {!proto.themis.TeamScoringAuthority} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScoringAuthorityResponse.deserializeBinary
);


/**
 * @param {!proto.themis.TeamScoringAuthority} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamScoringAuthorityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamScoringAuthorityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteTeamScoringAuthority =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteTeamScoringAuthority',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteTeamScoringAuthority,
      callback);
};


/**
 * @param {!proto.themis.TeamScoringAuthority} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamScoringAuthorityResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteTeamScoringAuthority =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteTeamScoringAuthority',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteTeamScoringAuthority);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.TeamScoringAuthority,
 *   !proto.themis.TeamScoringAuthorityResponse>}
 */
const methodDescriptor_ThemisApi_SetPrimaryTeamScoringAuthority = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/SetPrimaryTeamScoringAuthority',
  grpc.web.MethodType.UNARY,
  themis_common_pb.TeamScoringAuthority,
  proto.themis.TeamScoringAuthorityResponse,
  /**
   * @param {!proto.themis.TeamScoringAuthority} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScoringAuthorityResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.TeamScoringAuthority,
 *   !proto.themis.TeamScoringAuthorityResponse>}
 */
const methodInfo_ThemisApi_SetPrimaryTeamScoringAuthority = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamScoringAuthorityResponse,
  /**
   * @param {!proto.themis.TeamScoringAuthority} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScoringAuthorityResponse.deserializeBinary
);


/**
 * @param {!proto.themis.TeamScoringAuthority} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamScoringAuthorityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamScoringAuthorityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.setPrimaryTeamScoringAuthority =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/SetPrimaryTeamScoringAuthority',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SetPrimaryTeamScoringAuthority,
      callback);
};


/**
 * @param {!proto.themis.TeamScoringAuthority} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamScoringAuthorityResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.setPrimaryTeamScoringAuthority =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/SetPrimaryTeamScoringAuthority',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SetPrimaryTeamScoringAuthority);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Team,
 *   !proto.themis.TeamScoringAuthorityResponse>}
 */
const methodDescriptor_ThemisApi_GetTeamScoringAuthorities = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetTeamScoringAuthorities',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Team,
  proto.themis.TeamScoringAuthorityResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScoringAuthorityResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Team,
 *   !proto.themis.TeamScoringAuthorityResponse>}
 */
const methodInfo_ThemisApi_GetTeamScoringAuthorities = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamScoringAuthorityResponse,
  /**
   * @param {!proto.themis.Team} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScoringAuthorityResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamScoringAuthorityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamScoringAuthorityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getTeamScoringAuthorities =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetTeamScoringAuthorities',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTeamScoringAuthorities,
      callback);
};


/**
 * @param {!proto.themis.Team} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamScoringAuthorityResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getTeamScoringAuthorities =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetTeamScoringAuthorities',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTeamScoringAuthorities);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodDescriptor_ThemisApi_AddEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodInfo_ThemisApi_AddEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventTeam,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeam>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_RemoveEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveEventTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_RemoveEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveEventTeam,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamResponse>}
 */
const methodDescriptor_ThemisApi_GetEventTeamsByTeamId = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventTeamsByTeamId',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamResponse>}
 */
const methodInfo_ThemisApi_GetEventTeamsByTeamId = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventTeamsByTeamId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamsByTeamId',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamsByTeamId,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventTeamsByTeamId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamsByTeamId',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamsByTeamId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodDescriptor_ThemisApi_GetEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodInfo_ThemisApi_GetEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeam,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeam>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.GetEventTeamRegistrationTransactionsResponse>}
 */
const methodDescriptor_ThemisApi_GetEventTeamRegistrationTransactions = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventTeamRegistrationTransactions',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  proto.themis.GetEventTeamRegistrationTransactionsResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetEventTeamRegistrationTransactionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.GetEventTeamRegistrationTransactionsResponse>}
 */
const methodInfo_ThemisApi_GetEventTeamRegistrationTransactions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GetEventTeamRegistrationTransactionsResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetEventTeamRegistrationTransactionsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GetEventTeamRegistrationTransactionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GetEventTeamRegistrationTransactionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventTeamRegistrationTransactions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamRegistrationTransactions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamRegistrationTransactions,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GetEventTeamRegistrationTransactionsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventTeamRegistrationTransactions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamRegistrationTransactions',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamRegistrationTransactions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodDescriptor_ThemisApi_RemoveEventTeamCoach = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveEventTeamCoach',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodInfo_ThemisApi_RemoveEventTeamCoach = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeEventTeamCoach =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveEventTeamCoach',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveEventTeamCoach,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeam>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeEventTeamCoach =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveEventTeamCoach',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveEventTeamCoach);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodDescriptor_ThemisApi_AddEventTeamCoach = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventTeamCoach',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodInfo_ThemisApi_AddEventTeamCoach = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventTeamCoach =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventTeamCoach',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventTeamCoach,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeam>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventTeamCoach =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventTeamCoach',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventTeamCoach);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodDescriptor_ThemisApi_RemoveEventTeamPersonnel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveEventTeamPersonnel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodInfo_ThemisApi_RemoveEventTeamPersonnel = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeEventTeamPersonnel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveEventTeamPersonnel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveEventTeamPersonnel,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeam>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeEventTeamPersonnel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveEventTeamPersonnel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveEventTeamPersonnel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodDescriptor_ThemisApi_AddEventTeamPersonnel = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventTeamPersonnel',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodInfo_ThemisApi_AddEventTeamPersonnel = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventTeamPersonnel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventTeamPersonnel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventTeamPersonnel,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeam>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventTeamPersonnel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventTeamPersonnel',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventTeamPersonnel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodDescriptor_ThemisApi_RemoveEventTeamAthlete = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveEventTeamAthlete',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodInfo_ThemisApi_RemoveEventTeamAthlete = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeEventTeamAthlete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveEventTeamAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveEventTeamAthlete,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeam>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeEventTeamAthlete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveEventTeamAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveEventTeamAthlete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodDescriptor_ThemisApi_AddEventTeamAthlete = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventTeamAthlete',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodInfo_ThemisApi_AddEventTeamAthlete = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventTeamAthlete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventTeamAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventTeamAthlete,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeam>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventTeamAthlete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventTeamAthlete',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventTeamAthlete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodDescriptor_ThemisApi_CheckForCrossovers = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/CheckForCrossovers',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodInfo_ThemisApi_CheckForCrossovers = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.checkForCrossovers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/CheckForCrossovers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CheckForCrossovers,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeam>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.checkForCrossovers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/CheckForCrossovers',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CheckForCrossovers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamBalance>}
 */
const methodDescriptor_ThemisApi_GetTotalOutstandingForEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetTotalOutstandingForEventTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeamBalance,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeamBalance.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamBalance>}
 */
const methodInfo_ThemisApi_GetTotalOutstandingForEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeamBalance,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeamBalance.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamBalance)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamBalance>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getTotalOutstandingForEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetTotalOutstandingForEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTotalOutstandingForEventTeam,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamBalance>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getTotalOutstandingForEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetTotalOutstandingForEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTotalOutstandingForEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_ValidateEventTeamDiscount = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ValidateEventTeamDiscount',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_ValidateEventTeamDiscount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.validateEventTeamDiscount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ValidateEventTeamDiscount',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ValidateEventTeamDiscount,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.validateEventTeamDiscount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ValidateEventTeamDiscount',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ValidateEventTeamDiscount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodDescriptor_ThemisApi_UpdateEventTeamDivision = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateEventTeamDivision',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeam>}
 */
const methodInfo_ThemisApi_UpdateEventTeamDivision = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventTeam,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventTeam.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateEventTeamDivision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEventTeamDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEventTeamDivision,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeam>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateEventTeamDivision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateEventTeamDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateEventTeamDivision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AthleteEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodDescriptor_ThemisApi_AddAthleteToEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddAthleteToEventTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.AthleteEventTeamRequest,
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.AthleteEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AthleteEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodInfo_ThemisApi_AddAthleteToEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.AthleteEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AthleteEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addAthleteToEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddAthleteToEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthleteToEventTeam,
      callback);
};


/**
 * @param {!proto.themis.AthleteEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addAthleteToEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddAthleteToEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddAthleteToEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AthleteEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodDescriptor_ThemisApi_RemoveAthleteFromEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveAthleteFromEventTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.AthleteEventTeamRequest,
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.AthleteEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AthleteEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodInfo_ThemisApi_RemoveAthleteFromEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.AthleteEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.AthleteEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeAthleteFromEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveAthleteFromEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveAthleteFromEventTeam,
      callback);
};


/**
 * @param {!proto.themis.AthleteEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeAthleteFromEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveAthleteFromEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveAthleteFromEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.SubstituteAthleteEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodDescriptor_ThemisApi_SubstituteAthleteInEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/SubstituteAthleteInEventTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.SubstituteAthleteEventTeamRequest,
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.SubstituteAthleteEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.SubstituteAthleteEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodInfo_ThemisApi_SubstituteAthleteInEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.SubstituteAthleteEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.SubstituteAthleteEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.substituteAthleteInEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/SubstituteAthleteInEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SubstituteAthleteInEventTeam,
      callback);
};


/**
 * @param {!proto.themis.SubstituteAthleteEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.substituteAthleteInEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/SubstituteAthleteInEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SubstituteAthleteInEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.CoachEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodDescriptor_ThemisApi_AddCoachToEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddCoachToEventTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.CoachEventTeamRequest,
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.CoachEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.CoachEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodInfo_ThemisApi_AddCoachToEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.CoachEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.CoachEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addCoachToEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddCoachToEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddCoachToEventTeam,
      callback);
};


/**
 * @param {!proto.themis.CoachEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addCoachToEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddCoachToEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddCoachToEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.CoachEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodDescriptor_ThemisApi_RemoveCoachFromEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveCoachFromEventTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.CoachEventTeamRequest,
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.CoachEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.CoachEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodInfo_ThemisApi_RemoveCoachFromEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.CoachEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.CoachEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeCoachFromEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveCoachFromEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveCoachFromEventTeam,
      callback);
};


/**
 * @param {!proto.themis.CoachEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeCoachFromEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveCoachFromEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveCoachFromEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.PersonnelEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodDescriptor_ThemisApi_AddPersonnelToEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddPersonnelToEventTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.PersonnelEventTeamRequest,
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.PersonnelEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.PersonnelEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodInfo_ThemisApi_AddPersonnelToEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.PersonnelEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.PersonnelEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addPersonnelToEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddPersonnelToEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddPersonnelToEventTeam,
      callback);
};


/**
 * @param {!proto.themis.PersonnelEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addPersonnelToEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddPersonnelToEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddPersonnelToEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.PersonnelEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodDescriptor_ThemisApi_RemovePersonnelFromEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemovePersonnelFromEventTeam',
  grpc.web.MethodType.UNARY,
  proto.themis.PersonnelEventTeamRequest,
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.PersonnelEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.PersonnelEventTeamRequest,
 *   !proto.themis.EventTeamResponse>}
 */
const methodInfo_ThemisApi_RemovePersonnelFromEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.PersonnelEventTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.PersonnelEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removePersonnelFromEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemovePersonnelFromEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemovePersonnelFromEventTeam,
      callback);
};


/**
 * @param {!proto.themis.PersonnelEventTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removePersonnelFromEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemovePersonnelFromEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemovePersonnelFromEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.GetRegistrationTransactionsByIdsResponse>}
 */
const methodDescriptor_ThemisApi_GetRegistrationTransactionsByEventTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetRegistrationTransactionsByEventTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  proto.themis.GetRegistrationTransactionsByIdsResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetRegistrationTransactionsByIdsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.GetRegistrationTransactionsByIdsResponse>}
 */
const methodInfo_ThemisApi_GetRegistrationTransactionsByEventTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GetRegistrationTransactionsByIdsResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetRegistrationTransactionsByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GetRegistrationTransactionsByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GetRegistrationTransactionsByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getRegistrationTransactionsByEventTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetRegistrationTransactionsByEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetRegistrationTransactionsByEventTeam,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GetRegistrationTransactionsByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getRegistrationTransactionsByEventTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetRegistrationTransactionsByEventTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetRegistrationTransactionsByEventTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.GetRegistrationTransactionsByIdsRequest,
 *   !proto.themis.GetRegistrationTransactionsByIdsResponse>}
 */
const methodDescriptor_ThemisApi_GetRegistrationTransactionsByIds = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetRegistrationTransactionsByIds',
  grpc.web.MethodType.UNARY,
  proto.themis.GetRegistrationTransactionsByIdsRequest,
  proto.themis.GetRegistrationTransactionsByIdsResponse,
  /**
   * @param {!proto.themis.GetRegistrationTransactionsByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetRegistrationTransactionsByIdsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.GetRegistrationTransactionsByIdsRequest,
 *   !proto.themis.GetRegistrationTransactionsByIdsResponse>}
 */
const methodInfo_ThemisApi_GetRegistrationTransactionsByIds = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.GetRegistrationTransactionsByIdsResponse,
  /**
   * @param {!proto.themis.GetRegistrationTransactionsByIdsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.GetRegistrationTransactionsByIdsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.GetRegistrationTransactionsByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GetRegistrationTransactionsByIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GetRegistrationTransactionsByIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getRegistrationTransactionsByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetRegistrationTransactionsByIds',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetRegistrationTransactionsByIds,
      callback);
};


/**
 * @param {!proto.themis.GetRegistrationTransactionsByIdsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GetRegistrationTransactionsByIdsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getRegistrationTransactionsByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetRegistrationTransactionsByIds',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetRegistrationTransactionsByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamResponse>}
 */
const methodDescriptor_ThemisApi_GetEventRegistration = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventRegistration',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamResponse>}
 */
const methodInfo_ThemisApi_GetEventRegistration = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventRegistration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventRegistration',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventRegistration,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventRegistration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventRegistration',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventRegistration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamResponse>}
 */
const methodDescriptor_ThemisApi_AddEventRegistration = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventRegistration',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamResponse>}
 */
const methodInfo_ThemisApi_AddEventRegistration = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventTeamResponse,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventRegistration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventRegistration',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventRegistration,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventRegistration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventRegistration',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventRegistration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.CreditRequest,
 *   !proto.themis.CreditResponse>}
 */
const methodDescriptor_ThemisApi_GetAvailableCredit = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetAvailableCredit',
  grpc.web.MethodType.UNARY,
  proto.themis.CreditRequest,
  proto.themis.CreditResponse,
  /**
   * @param {!proto.themis.CreditRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CreditResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.CreditRequest,
 *   !proto.themis.CreditResponse>}
 */
const methodInfo_ThemisApi_GetAvailableCredit = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.CreditResponse,
  /**
   * @param {!proto.themis.CreditRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.CreditResponse.deserializeBinary
);


/**
 * @param {!proto.themis.CreditRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.CreditResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.CreditResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getAvailableCredit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetAvailableCredit',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAvailableCredit,
      callback);
};


/**
 * @param {!proto.themis.CreditRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.CreditResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getAvailableCredit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetAvailableCredit',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAvailableCredit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Judge,
 *   !proto.themis.JudgeResponse>}
 */
const methodDescriptor_ThemisApi_GetJudge = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetJudge',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Judge,
  proto.themis.JudgeResponse,
  /**
   * @param {!proto.themis.Judge} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.JudgeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Judge,
 *   !proto.themis.JudgeResponse>}
 */
const methodInfo_ThemisApi_GetJudge = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.JudgeResponse,
  /**
   * @param {!proto.themis.Judge} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.JudgeResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Judge} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.JudgeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.JudgeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getJudge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetJudge,
      callback);
};


/**
 * @param {!proto.themis.Judge} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.JudgeResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getJudge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetJudge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Judge,
 *   !proto.themis.JudgeResponse>}
 */
const methodDescriptor_ThemisApi_AddJudge = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddJudge',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Judge,
  proto.themis.JudgeResponse,
  /**
   * @param {!proto.themis.Judge} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.JudgeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Judge,
 *   !proto.themis.JudgeResponse>}
 */
const methodInfo_ThemisApi_AddJudge = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.JudgeResponse,
  /**
   * @param {!proto.themis.Judge} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.JudgeResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Judge} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.JudgeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.JudgeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addJudge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddJudge,
      callback);
};


/**
 * @param {!proto.themis.Judge} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.JudgeResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addJudge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddJudge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.JudgeBrandRequest,
 *   !proto.themis.BrandResponse>}
 */
const methodDescriptor_ThemisApi_AddJudgeToBrand = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddJudgeToBrand',
  grpc.web.MethodType.UNARY,
  proto.themis.JudgeBrandRequest,
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.JudgeBrandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.JudgeBrandRequest,
 *   !proto.themis.BrandResponse>}
 */
const methodInfo_ThemisApi_AddJudgeToBrand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.JudgeBrandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @param {!proto.themis.JudgeBrandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.BrandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.BrandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addJudgeToBrand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddJudgeToBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddJudgeToBrand,
      callback);
};


/**
 * @param {!proto.themis.JudgeBrandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.BrandResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addJudgeToBrand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddJudgeToBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddJudgeToBrand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.JudgeProducerRequest,
 *   !proto.themis.ProducerResponse>}
 */
const methodDescriptor_ThemisApi_RemoveJudge = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveJudge',
  grpc.web.MethodType.UNARY,
  proto.themis.JudgeProducerRequest,
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.JudgeProducerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.JudgeProducerRequest,
 *   !proto.themis.ProducerResponse>}
 */
const methodInfo_ThemisApi_RemoveJudge = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ProducerResponse,
  /**
   * @param {!proto.themis.JudgeProducerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ProducerResponse.deserializeBinary
);


/**
 * @param {!proto.themis.JudgeProducerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProducerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProducerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeJudge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveJudge,
      callback);
};


/**
 * @param {!proto.themis.JudgeProducerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProducerResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeJudge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveJudge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.JudgeBrandRequest,
 *   !proto.themis.BrandResponse>}
 */
const methodDescriptor_ThemisApi_RemoveJudgeFromBrand = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RemoveJudgeFromBrand',
  grpc.web.MethodType.UNARY,
  proto.themis.JudgeBrandRequest,
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.JudgeBrandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.JudgeBrandRequest,
 *   !proto.themis.BrandResponse>}
 */
const methodInfo_ThemisApi_RemoveJudgeFromBrand = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.BrandResponse,
  /**
   * @param {!proto.themis.JudgeBrandRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BrandResponse.deserializeBinary
);


/**
 * @param {!proto.themis.JudgeBrandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.BrandResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.BrandResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.removeJudgeFromBrand =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RemoveJudgeFromBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveJudgeFromBrand,
      callback);
};


/**
 * @param {!proto.themis.JudgeBrandRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.BrandResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.removeJudgeFromBrand =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RemoveJudgeFromBrand',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RemoveJudgeFromBrand);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Judge,
 *   !proto.themis.JudgeResponse>}
 */
const methodDescriptor_ThemisApi_UpdateJudge = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UpdateJudge',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Judge,
  proto.themis.JudgeResponse,
  /**
   * @param {!proto.themis.Judge} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.JudgeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Judge,
 *   !proto.themis.JudgeResponse>}
 */
const methodInfo_ThemisApi_UpdateJudge = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.JudgeResponse,
  /**
   * @param {!proto.themis.Judge} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.JudgeResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Judge} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.JudgeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.JudgeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.updateJudge =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UpdateJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateJudge,
      callback);
};


/**
 * @param {!proto.themis.Judge} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.JudgeResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.updateJudge =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UpdateJudge',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UpdateJudge);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ScrapeRequest,
 *   !proto.themis.ScrapeResponse>}
 */
const methodDescriptor_ThemisApi_Scrape = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/Scrape',
  grpc.web.MethodType.UNARY,
  proto.themis.ScrapeRequest,
  proto.themis.ScrapeResponse,
  /**
   * @param {!proto.themis.ScrapeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ScrapeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ScrapeRequest,
 *   !proto.themis.ScrapeResponse>}
 */
const methodInfo_ThemisApi_Scrape = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ScrapeResponse,
  /**
   * @param {!proto.themis.ScrapeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ScrapeResponse.deserializeBinary
);


/**
 * @param {!proto.themis.ScrapeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ScrapeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ScrapeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.scrape =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/Scrape',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_Scrape,
      callback);
};


/**
 * @param {!proto.themis.ScrapeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ScrapeResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.scrape =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/Scrape',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_Scrape);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ScrapeRequest,
 *   !proto.themis.ScrapeResponse>}
 */
const methodDescriptor_ThemisApi_ScrapeProgram = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ScrapeProgram',
  grpc.web.MethodType.UNARY,
  proto.themis.ScrapeRequest,
  proto.themis.ScrapeResponse,
  /**
   * @param {!proto.themis.ScrapeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ScrapeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ScrapeRequest,
 *   !proto.themis.ScrapeResponse>}
 */
const methodInfo_ThemisApi_ScrapeProgram = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ScrapeResponse,
  /**
   * @param {!proto.themis.ScrapeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ScrapeResponse.deserializeBinary
);


/**
 * @param {!proto.themis.ScrapeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ScrapeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ScrapeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.scrapeProgram =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ScrapeProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ScrapeProgram,
      callback);
};


/**
 * @param {!proto.themis.ScrapeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ScrapeResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.scrapeProgram =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ScrapeProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ScrapeProgram);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.DivisionImportRequest,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_ImportDivision = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ImportDivision',
  grpc.web.MethodType.UNARY,
  proto.themis.DivisionImportRequest,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.DivisionImportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.DivisionImportRequest,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_ImportDivision = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.DivisionImportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.DivisionImportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.importDivision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ImportDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ImportDivision,
      callback);
};


/**
 * @param {!proto.themis.DivisionImportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.importDivision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ImportDivision',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ImportDivision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.BugReport,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_AddBugReport = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddBugReport',
  grpc.web.MethodType.UNARY,
  proto.themis.BugReport,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.BugReport} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.BugReport,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_AddBugReport = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.BugReport} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.BugReport} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addBugReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddBugReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddBugReport,
      callback);
};


/**
 * @param {!proto.themis.BugReport} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addBugReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddBugReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddBugReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.StripePKResponse>}
 */
const methodDescriptor_ThemisApi_GetStripePK = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetStripePK',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  proto.themis.StripePKResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.StripePKResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.StripePKResponse>}
 */
const methodInfo_ThemisApi_GetStripePK = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.StripePKResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.StripePKResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.StripePKResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.StripePKResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getStripePK =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetStripePK',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetStripePK,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.StripePKResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getStripePK =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetStripePK',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetStripePK);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.StripeSources>}
 */
const methodDescriptor_ThemisApi_GetUserPaymentSources = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetUserPaymentSources',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  themis_common_pb.StripeSources,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.StripeSources.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.StripeSources>}
 */
const methodInfo_ThemisApi_GetUserPaymentSources = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.StripeSources,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.StripeSources.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.StripeSources)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.StripeSources>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getUserPaymentSources =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetUserPaymentSources',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetUserPaymentSources,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.StripeSources>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getUserPaymentSources =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetUserPaymentSources',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetUserPaymentSources);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddStripeSourceRequest,
 *   !proto.themis.StripeBankAccount>}
 */
const methodDescriptor_ThemisApi_AddACHSource = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddACHSource',
  grpc.web.MethodType.UNARY,
  proto.themis.AddStripeSourceRequest,
  themis_common_pb.StripeBankAccount,
  /**
   * @param {!proto.themis.AddStripeSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.StripeBankAccount.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddStripeSourceRequest,
 *   !proto.themis.StripeBankAccount>}
 */
const methodInfo_ThemisApi_AddACHSource = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.StripeBankAccount,
  /**
   * @param {!proto.themis.AddStripeSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.StripeBankAccount.deserializeBinary
);


/**
 * @param {!proto.themis.AddStripeSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.StripeBankAccount)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.StripeBankAccount>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addACHSource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddACHSource',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddACHSource,
      callback);
};


/**
 * @param {!proto.themis.AddStripeSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.StripeBankAccount>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addACHSource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddACHSource',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddACHSource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.VerifyACHSourceRequest,
 *   !proto.themis.StripeBankAccount>}
 */
const methodDescriptor_ThemisApi_VerifyACHSource = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/VerifyACHSource',
  grpc.web.MethodType.UNARY,
  proto.themis.VerifyACHSourceRequest,
  themis_common_pb.StripeBankAccount,
  /**
   * @param {!proto.themis.VerifyACHSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.StripeBankAccount.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.VerifyACHSourceRequest,
 *   !proto.themis.StripeBankAccount>}
 */
const methodInfo_ThemisApi_VerifyACHSource = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.StripeBankAccount,
  /**
   * @param {!proto.themis.VerifyACHSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.StripeBankAccount.deserializeBinary
);


/**
 * @param {!proto.themis.VerifyACHSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.StripeBankAccount)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.StripeBankAccount>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.verifyACHSource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/VerifyACHSource',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_VerifyACHSource,
      callback);
};


/**
 * @param {!proto.themis.VerifyACHSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.StripeBankAccount>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.verifyACHSource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/VerifyACHSource',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_VerifyACHSource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.AddStripeSourceRequest,
 *   !proto.themis.StripeCreditCard>}
 */
const methodDescriptor_ThemisApi_AddCreditCardSource = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddCreditCardSource',
  grpc.web.MethodType.UNARY,
  proto.themis.AddStripeSourceRequest,
  themis_common_pb.StripeCreditCard,
  /**
   * @param {!proto.themis.AddStripeSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.StripeCreditCard.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.AddStripeSourceRequest,
 *   !proto.themis.StripeCreditCard>}
 */
const methodInfo_ThemisApi_AddCreditCardSource = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.StripeCreditCard,
  /**
   * @param {!proto.themis.AddStripeSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.StripeCreditCard.deserializeBinary
);


/**
 * @param {!proto.themis.AddStripeSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.StripeCreditCard)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.StripeCreditCard>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addCreditCardSource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddCreditCardSource',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddCreditCardSource,
      callback);
};


/**
 * @param {!proto.themis.AddStripeSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.StripeCreditCard>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addCreditCardSource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddCreditCardSource',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddCreditCardSource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.UploadCsvRequest,
 *   !proto.themis.FlatCsv>}
 */
const methodDescriptor_ThemisApi_ImportSpreadsheet = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ImportSpreadsheet',
  grpc.web.MethodType.UNARY,
  themis_common_pb.UploadCsvRequest,
  themis_common_pb.FlatCsv,
  /**
   * @param {!proto.themis.UploadCsvRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.FlatCsv.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.UploadCsvRequest,
 *   !proto.themis.FlatCsv>}
 */
const methodInfo_ThemisApi_ImportSpreadsheet = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.FlatCsv,
  /**
   * @param {!proto.themis.UploadCsvRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.FlatCsv.deserializeBinary
);


/**
 * @param {!proto.themis.UploadCsvRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.FlatCsv)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.FlatCsv>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.importSpreadsheet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ImportSpreadsheet',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ImportSpreadsheet,
      callback);
};


/**
 * @param {!proto.themis.UploadCsvRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.FlatCsv>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.importSpreadsheet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ImportSpreadsheet',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ImportSpreadsheet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.FlatCsv,
 *   !proto.themis.AthleteResponse>}
 */
const methodDescriptor_ThemisApi_ConvertSpreadsheetToAthletes = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ConvertSpreadsheetToAthletes',
  grpc.web.MethodType.UNARY,
  themis_common_pb.FlatCsv,
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.FlatCsv} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.FlatCsv,
 *   !proto.themis.AthleteResponse>}
 */
const methodInfo_ThemisApi_ConvertSpreadsheetToAthletes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.FlatCsv} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @param {!proto.themis.FlatCsv} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.AthleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.AthleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.convertSpreadsheetToAthletes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ConvertSpreadsheetToAthletes',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ConvertSpreadsheetToAthletes,
      callback);
};


/**
 * @param {!proto.themis.FlatCsv} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.AthleteResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.convertSpreadsheetToAthletes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ConvertSpreadsheetToAthletes',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ConvertSpreadsheetToAthletes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.LoadMessage,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_Load = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/Load',
  grpc.web.MethodType.UNARY,
  proto.themis.LoadMessage,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.LoadMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.LoadMessage,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_Load = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.LoadMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.LoadMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.load =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/Load',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_Load,
      callback);
};


/**
 * @param {!proto.themis.LoadMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.load =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/Load',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_Load);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ReportRequest,
 *   !proto.themis.BasicReportResponse>}
 */
const methodDescriptor_ThemisApi_BasicEventsReport = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/BasicEventsReport',
  grpc.web.MethodType.UNARY,
  proto.themis.ReportRequest,
  proto.themis.BasicReportResponse,
  /**
   * @param {!proto.themis.ReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BasicReportResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ReportRequest,
 *   !proto.themis.BasicReportResponse>}
 */
const methodInfo_ThemisApi_BasicEventsReport = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.BasicReportResponse,
  /**
   * @param {!proto.themis.ReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.BasicReportResponse.deserializeBinary
);


/**
 * @param {!proto.themis.ReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.BasicReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.BasicReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.basicEventsReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/BasicEventsReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_BasicEventsReport,
      callback);
};


/**
 * @param {!proto.themis.ReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.BasicReportResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.basicEventsReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/BasicEventsReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_BasicEventsReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.DocumentSignatureRequest,
 *   !proto.themis.DocumentSignatureGuardian>}
 */
const methodDescriptor_ThemisApi_GetSignatureInfo = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetSignatureInfo',
  grpc.web.MethodType.UNARY,
  proto.themis.DocumentSignatureRequest,
  themis_common_pb.DocumentSignatureGuardian,
  /**
   * @param {!proto.themis.DocumentSignatureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.DocumentSignatureGuardian.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.DocumentSignatureRequest,
 *   !proto.themis.DocumentSignatureGuardian>}
 */
const methodInfo_ThemisApi_GetSignatureInfo = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.DocumentSignatureGuardian,
  /**
   * @param {!proto.themis.DocumentSignatureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.DocumentSignatureGuardian.deserializeBinary
);


/**
 * @param {!proto.themis.DocumentSignatureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DocumentSignatureGuardian)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DocumentSignatureGuardian>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getSignatureInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetSignatureInfo',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetSignatureInfo,
      callback);
};


/**
 * @param {!proto.themis.DocumentSignatureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DocumentSignatureGuardian>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getSignatureInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetSignatureInfo',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetSignatureInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.DocumentSignatureRequest,
 *   !proto.themis.DocumentSignatureResponse>}
 */
const methodDescriptor_ThemisApi_SignDocument = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/SignDocument',
  grpc.web.MethodType.UNARY,
  proto.themis.DocumentSignatureRequest,
  proto.themis.DocumentSignatureResponse,
  /**
   * @param {!proto.themis.DocumentSignatureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentSignatureResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.DocumentSignatureRequest,
 *   !proto.themis.DocumentSignatureResponse>}
 */
const methodInfo_ThemisApi_SignDocument = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DocumentSignatureResponse,
  /**
   * @param {!proto.themis.DocumentSignatureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentSignatureResponse.deserializeBinary
);


/**
 * @param {!proto.themis.DocumentSignatureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DocumentSignatureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DocumentSignatureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.signDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/SignDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SignDocument,
      callback);
};


/**
 * @param {!proto.themis.DocumentSignatureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DocumentSignatureResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.signDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/SignDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SignDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodDescriptor_ThemisApi_AddProducerDocument = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddProducerDocument',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDocument,
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodInfo_ThemisApi_AddProducerDocument = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addProducerDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddProducerDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProducerDocument,
      callback);
};


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DocumentResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addProducerDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddProducerDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddProducerDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodDescriptor_ThemisApi_EditProducerDocument = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditProducerDocument',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDocument,
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodInfo_ThemisApi_EditProducerDocument = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editProducerDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditProducerDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditProducerDocument,
      callback);
};


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DocumentResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editProducerDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditProducerDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditProducerDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDocument,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_DeleteProducerDocument = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteProducerDocument',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDocument,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDocument,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_DeleteProducerDocument = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteProducerDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteProducerDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteProducerDocument,
      callback);
};


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteProducerDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteProducerDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteProducerDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodDescriptor_ThemisApi_GetProducerDocuments = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetProducerDocuments',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDocument,
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodInfo_ThemisApi_GetProducerDocuments = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getProducerDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetProducerDocuments',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducerDocuments,
      callback);
};


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DocumentResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getProducerDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetProducerDocuments',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetProducerDocuments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDocument,
 *   !proto.themis.StatusResponse>}
 */
const methodDescriptor_ThemisApi_AddDocumentToAllFutureEvents = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddDocumentToAllFutureEvents',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDocument,
  proto.themis.StatusResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.StatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDocument,
 *   !proto.themis.StatusResponse>}
 */
const methodInfo_ThemisApi_AddDocumentToAllFutureEvents = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.StatusResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.StatusResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.StatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.StatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addDocumentToAllFutureEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddDocumentToAllFutureEvents',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddDocumentToAllFutureEvents,
      callback);
};


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.StatusResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addDocumentToAllFutureEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddDocumentToAllFutureEvents',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddDocumentToAllFutureEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_ResendDocumentToEvent = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ResendDocumentToEvent',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_ResendDocumentToEvent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.resendDocumentToEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ResendDocumentToEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ResendDocumentToEvent,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.resendDocumentToEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ResendDocumentToEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ResendDocumentToEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodDescriptor_ThemisApi_AddEventDocument = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventDocument',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDocument,
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodInfo_ThemisApi_AddEventDocument = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDocument,
      callback);
};


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DocumentResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodDescriptor_ThemisApi_EditEventDocument = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditEventDocument',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDocument,
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodInfo_ThemisApi_EditEventDocument = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editEventDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditEventDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventDocument,
      callback);
};


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DocumentResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editEventDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditEventDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDocument,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_DeleteEventDocument = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventDocument',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDocument,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDocument,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_DeleteEventDocument = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDocument,
      callback);
};


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDocument',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodDescriptor_ThemisApi_GetEventDocuments = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventDocuments',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDocument,
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDocument,
 *   !proto.themis.DocumentResponse>}
 */
const methodInfo_ThemisApi_GetEventDocuments = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.DocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.DocumentResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.DocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.DocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDocuments',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDocuments,
      callback);
};


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.DocumentResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDocuments',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDocuments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDocument,
 *   !proto.themis.EventDocumentResponse>}
 */
const methodDescriptor_ThemisApi_GetSignedEventDocuments = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetSignedEventDocuments',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDocument,
  proto.themis.EventDocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDocumentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDocument,
 *   !proto.themis.EventDocumentResponse>}
 */
const methodInfo_ThemisApi_GetSignedEventDocuments = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDocumentResponse,
  /**
   * @param {!proto.themis.EventDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getSignedEventDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetSignedEventDocuments',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetSignedEventDocuments,
      callback);
};


/**
 * @param {!proto.themis.EventDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getSignedEventDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetSignedEventDocuments',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetSignedEventDocuments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianAhtleteValid>}
 */
const methodDescriptor_ThemisApi_CheckGuardianAthleteValid = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/CheckGuardianAthleteValid',
  grpc.web.MethodType.UNARY,
  proto.themis.GuardianAthleteRequest,
  themis_common_pb.GuardianAhtleteValid,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.GuardianAhtleteValid.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianAhtleteValid>}
 */
const methodInfo_ThemisApi_CheckGuardianAthleteValid = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.GuardianAhtleteValid,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.GuardianAhtleteValid.deserializeBinary
);


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GuardianAhtleteValid)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GuardianAhtleteValid>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.checkGuardianAthleteValid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/CheckGuardianAthleteValid',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CheckGuardianAthleteValid,
      callback);
};


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GuardianAhtleteValid>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.checkGuardianAthleteValid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/CheckGuardianAthleteValid',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CheckGuardianAthleteValid);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianAhtleteValid>}
 */
const methodDescriptor_ThemisApi_SetGuardianAthleteValid = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/SetGuardianAthleteValid',
  grpc.web.MethodType.UNARY,
  proto.themis.GuardianAthleteRequest,
  themis_common_pb.GuardianAhtleteValid,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.GuardianAhtleteValid.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.GuardianAthleteRequest,
 *   !proto.themis.GuardianAhtleteValid>}
 */
const methodInfo_ThemisApi_SetGuardianAthleteValid = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.GuardianAhtleteValid,
  /**
   * @param {!proto.themis.GuardianAthleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.GuardianAhtleteValid.deserializeBinary
);


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.GuardianAhtleteValid)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.GuardianAhtleteValid>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.setGuardianAthleteValid =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/SetGuardianAthleteValid',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SetGuardianAthleteValid,
      callback);
};


/**
 * @param {!proto.themis.GuardianAthleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.GuardianAhtleteValid>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.setGuardianAthleteValid =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/SetGuardianAthleteValid',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SetGuardianAthleteValid);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.SignatureRequest,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_SetGuardianSignature = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/SetGuardianSignature',
  grpc.web.MethodType.UNARY,
  proto.themis.SignatureRequest,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.SignatureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.SignatureRequest,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_SetGuardianSignature = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.SignatureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.SignatureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.setGuardianSignature =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/SetGuardianSignature',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SetGuardianSignature,
      callback);
};


/**
 * @param {!proto.themis.SignatureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.setGuardianSignature =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/SetGuardianSignature',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SetGuardianSignature);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ElectSigAgreement,
 *   !proto.themis.ElectSigAgreementResponse>}
 */
const methodDescriptor_ThemisApi_SaveGuardianElectSignatureAgreement = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/SaveGuardianElectSignatureAgreement',
  grpc.web.MethodType.UNARY,
  proto.themis.ElectSigAgreement,
  proto.themis.ElectSigAgreementResponse,
  /**
   * @param {!proto.themis.ElectSigAgreement} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ElectSigAgreementResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ElectSigAgreement,
 *   !proto.themis.ElectSigAgreementResponse>}
 */
const methodInfo_ThemisApi_SaveGuardianElectSignatureAgreement = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ElectSigAgreementResponse,
  /**
   * @param {!proto.themis.ElectSigAgreement} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ElectSigAgreementResponse.deserializeBinary
);


/**
 * @param {!proto.themis.ElectSigAgreement} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ElectSigAgreementResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ElectSigAgreementResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.saveGuardianElectSignatureAgreement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/SaveGuardianElectSignatureAgreement',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SaveGuardianElectSignatureAgreement,
      callback);
};


/**
 * @param {!proto.themis.ElectSigAgreement} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ElectSigAgreementResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.saveGuardianElectSignatureAgreement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/SaveGuardianElectSignatureAgreement',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SaveGuardianElectSignatureAgreement);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.SignatureQueryRequest,
 *   !proto.themis.SignatureQueryResponse>}
 */
const methodDescriptor_ThemisApi_GetSignatures = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetSignatures',
  grpc.web.MethodType.UNARY,
  proto.themis.SignatureQueryRequest,
  proto.themis.SignatureQueryResponse,
  /**
   * @param {!proto.themis.SignatureQueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.SignatureQueryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.SignatureQueryRequest,
 *   !proto.themis.SignatureQueryResponse>}
 */
const methodInfo_ThemisApi_GetSignatures = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.SignatureQueryResponse,
  /**
   * @param {!proto.themis.SignatureQueryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.SignatureQueryResponse.deserializeBinary
);


/**
 * @param {!proto.themis.SignatureQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.SignatureQueryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.SignatureQueryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getSignatures =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetSignatures',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetSignatures,
      callback);
};


/**
 * @param {!proto.themis.SignatureQueryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.SignatureQueryResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getSignatures =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetSignatures',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetSignatures);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamSignStatus>}
 */
const methodDescriptor_ThemisApi_GetEventTeamSignStatus = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventTeamSignStatus',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventTeam,
  proto.themis.EventTeamSignStatus,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamSignStatus.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventTeam,
 *   !proto.themis.EventTeamSignStatus>}
 */
const methodInfo_ThemisApi_GetEventTeamSignStatus = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventTeamSignStatus,
  /**
   * @param {!proto.themis.EventTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventTeamSignStatus.deserializeBinary
);


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventTeamSignStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventTeamSignStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventTeamSignStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamSignStatus',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamSignStatus,
      callback);
};


/**
 * @param {!proto.themis.EventTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventTeamSignStatus>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventTeamSignStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventTeamSignStatus',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventTeamSignStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ResendEmailRequest,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_ResendEmailToAthleteGuardians = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ResendEmailToAthleteGuardians',
  grpc.web.MethodType.UNARY,
  proto.themis.ResendEmailRequest,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.ResendEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ResendEmailRequest,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_ResendEmailToAthleteGuardians = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.ResendEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.ResendEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.resendEmailToAthleteGuardians =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ResendEmailToAthleteGuardians',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ResendEmailToAthleteGuardians,
      callback);
};


/**
 * @param {!proto.themis.ResendEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.resendEmailToAthleteGuardians =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ResendEmailToAthleteGuardians',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ResendEmailToAthleteGuardians);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.RegistrationActivityLogsByProducerRequest,
 *   !proto.themis.RegistrationActivityLogsResponse>}
 */
const methodDescriptor_ThemisApi_RegistrationActivityLogByProducer = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RegistrationActivityLogByProducer',
  grpc.web.MethodType.UNARY,
  proto.themis.RegistrationActivityLogsByProducerRequest,
  proto.themis.RegistrationActivityLogsResponse,
  /**
   * @param {!proto.themis.RegistrationActivityLogsByProducerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.RegistrationActivityLogsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.RegistrationActivityLogsByProducerRequest,
 *   !proto.themis.RegistrationActivityLogsResponse>}
 */
const methodInfo_ThemisApi_RegistrationActivityLogByProducer = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.RegistrationActivityLogsResponse,
  /**
   * @param {!proto.themis.RegistrationActivityLogsByProducerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.RegistrationActivityLogsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.RegistrationActivityLogsByProducerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.RegistrationActivityLogsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.RegistrationActivityLogsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.registrationActivityLogByProducer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RegistrationActivityLogByProducer',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RegistrationActivityLogByProducer,
      callback);
};


/**
 * @param {!proto.themis.RegistrationActivityLogsByProducerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.RegistrationActivityLogsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.registrationActivityLogByProducer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RegistrationActivityLogByProducer',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RegistrationActivityLogByProducer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.RegistrationActivityLogsByEventRequest,
 *   !proto.themis.RegistrationActivityLogsResponse>}
 */
const methodDescriptor_ThemisApi_RegistrationActivityLogByEvent = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/RegistrationActivityLogByEvent',
  grpc.web.MethodType.UNARY,
  proto.themis.RegistrationActivityLogsByEventRequest,
  proto.themis.RegistrationActivityLogsResponse,
  /**
   * @param {!proto.themis.RegistrationActivityLogsByEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.RegistrationActivityLogsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.RegistrationActivityLogsByEventRequest,
 *   !proto.themis.RegistrationActivityLogsResponse>}
 */
const methodInfo_ThemisApi_RegistrationActivityLogByEvent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.RegistrationActivityLogsResponse,
  /**
   * @param {!proto.themis.RegistrationActivityLogsByEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.RegistrationActivityLogsResponse.deserializeBinary
);


/**
 * @param {!proto.themis.RegistrationActivityLogsByEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.RegistrationActivityLogsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.RegistrationActivityLogsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.registrationActivityLogByEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/RegistrationActivityLogByEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RegistrationActivityLogByEvent,
      callback);
};


/**
 * @param {!proto.themis.RegistrationActivityLogsByEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.RegistrationActivityLogsResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.registrationActivityLogByEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/RegistrationActivityLogByEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_RegistrationActivityLogByEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ReportRequest,
 *   !proto.themis.EventRegistrationReport>}
 */
const methodDescriptor_ThemisApi_DetailEventReport = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DetailEventReport',
  grpc.web.MethodType.UNARY,
  proto.themis.ReportRequest,
  themis_common_pb.EventRegistrationReport,
  /**
   * @param {!proto.themis.ReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventRegistrationReport.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ReportRequest,
 *   !proto.themis.EventRegistrationReport>}
 */
const methodInfo_ThemisApi_DetailEventReport = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventRegistrationReport,
  /**
   * @param {!proto.themis.ReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventRegistrationReport.deserializeBinary
);


/**
 * @param {!proto.themis.ReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventRegistrationReport)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventRegistrationReport>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.detailEventReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DetailEventReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DetailEventReport,
      callback);
};


/**
 * @param {!proto.themis.ReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventRegistrationReport>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.detailEventReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DetailEventReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DetailEventReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ReportRequest,
 *   !proto.themis.EventRegistrationByProgramReport>}
 */
const methodDescriptor_ThemisApi_DetailEventReportByProgram = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DetailEventReportByProgram',
  grpc.web.MethodType.UNARY,
  proto.themis.ReportRequest,
  themis_common_pb.EventRegistrationByProgramReport,
  /**
   * @param {!proto.themis.ReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventRegistrationByProgramReport.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ReportRequest,
 *   !proto.themis.EventRegistrationByProgramReport>}
 */
const methodInfo_ThemisApi_DetailEventReportByProgram = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventRegistrationByProgramReport,
  /**
   * @param {!proto.themis.ReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventRegistrationByProgramReport.deserializeBinary
);


/**
 * @param {!proto.themis.ReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventRegistrationByProgramReport)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventRegistrationByProgramReport>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.detailEventReportByProgram =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DetailEventReportByProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DetailEventReportByProgram,
      callback);
};


/**
 * @param {!proto.themis.ReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventRegistrationByProgramReport>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.detailEventReportByProgram =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DetailEventReportByProgram',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DetailEventReportByProgram);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.ReportRequest,
 *   !proto.themis.ProducerProgramCreditReport>}
 */
const methodDescriptor_ThemisApi_DetailProducerProgramCreditReport = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DetailProducerProgramCreditReport',
  grpc.web.MethodType.UNARY,
  proto.themis.ReportRequest,
  themis_common_pb.ProducerProgramCreditReport,
  /**
   * @param {!proto.themis.ReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.ProducerProgramCreditReport.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.ReportRequest,
 *   !proto.themis.ProducerProgramCreditReport>}
 */
const methodInfo_ThemisApi_DetailProducerProgramCreditReport = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.ProducerProgramCreditReport,
  /**
   * @param {!proto.themis.ReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.ProducerProgramCreditReport.deserializeBinary
);


/**
 * @param {!proto.themis.ReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ProducerProgramCreditReport)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ProducerProgramCreditReport>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.detailProducerProgramCreditReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DetailProducerProgramCreditReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DetailProducerProgramCreditReport,
      callback);
};


/**
 * @param {!proto.themis.ReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ProducerProgramCreditReport>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.detailProducerProgramCreditReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DetailProducerProgramCreditReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DetailProducerProgramCreditReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.StatusResponse>}
 */
const methodDescriptor_ThemisApi_AutoCreateGuardianOver18 = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AutoCreateGuardianOver18',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  proto.themis.StatusResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.StatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.StatusResponse>}
 */
const methodInfo_ThemisApi_AutoCreateGuardianOver18 = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.StatusResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.StatusResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.StatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.StatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.autoCreateGuardianOver18 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AutoCreateGuardianOver18',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AutoCreateGuardianOver18,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.StatusResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.autoCreateGuardianOver18 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AutoCreateGuardianOver18',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AutoCreateGuardianOver18);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.AthleteResponse>}
 */
const methodDescriptor_ThemisApi_FindAthletesWithoutGuardians = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/FindAthletesWithoutGuardians',
  grpc.web.MethodType.UNARY,
  proto.themis.EmptyMessage,
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EmptyMessage,
 *   !proto.themis.AthleteResponse>}
 */
const methodInfo_ThemisApi_FindAthletesWithoutGuardians = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.EmptyMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.AthleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.AthleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.findAthletesWithoutGuardians =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/FindAthletesWithoutGuardians',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_FindAthletesWithoutGuardians,
      callback);
};


/**
 * @param {!proto.themis.EmptyMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.AthleteResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.findAthletesWithoutGuardians =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/FindAthletesWithoutGuardians',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_FindAthletesWithoutGuardians);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Rubric,
 *   !proto.themis.Rubrics>}
 */
const methodDescriptor_ThemisApi_AddRubric = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddRubric',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Rubric,
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.Rubric} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Rubric,
 *   !proto.themis.Rubrics>}
 */
const methodInfo_ThemisApi_AddRubric = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.Rubric} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @param {!proto.themis.Rubric} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Rubrics)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Rubrics>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addRubric =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddRubric',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddRubric,
      callback);
};


/**
 * @param {!proto.themis.Rubric} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Rubrics>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addRubric =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddRubric',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddRubric);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.RubricArea,
 *   !proto.themis.Rubrics>}
 */
const methodDescriptor_ThemisApi_AddRubricArea = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddRubricArea',
  grpc.web.MethodType.UNARY,
  themis_common_pb.RubricArea,
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.RubricArea,
 *   !proto.themis.Rubrics>}
 */
const methodInfo_ThemisApi_AddRubricArea = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @param {!proto.themis.RubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Rubrics)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Rubrics>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addRubricArea =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddRubricArea',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddRubricArea,
      callback);
};


/**
 * @param {!proto.themis.RubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Rubrics>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addRubricArea =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddRubricArea',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddRubricArea);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.RubricAreaItem,
 *   !proto.themis.Rubrics>}
 */
const methodDescriptor_ThemisApi_AddRubricAreaItem = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddRubricAreaItem',
  grpc.web.MethodType.UNARY,
  themis_common_pb.RubricAreaItem,
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricAreaItem} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.RubricAreaItem,
 *   !proto.themis.Rubrics>}
 */
const methodInfo_ThemisApi_AddRubricAreaItem = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricAreaItem} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @param {!proto.themis.RubricAreaItem} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Rubrics)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Rubrics>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addRubricAreaItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddRubricAreaItem',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddRubricAreaItem,
      callback);
};


/**
 * @param {!proto.themis.RubricAreaItem} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Rubrics>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addRubricAreaItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddRubricAreaItem',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddRubricAreaItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Rubric,
 *   !proto.themis.Rubrics>}
 */
const methodDescriptor_ThemisApi_EditRubric = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditRubric',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Rubric,
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.Rubric} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Rubric,
 *   !proto.themis.Rubrics>}
 */
const methodInfo_ThemisApi_EditRubric = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.Rubric} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @param {!proto.themis.Rubric} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Rubrics)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Rubrics>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editRubric =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditRubric',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditRubric,
      callback);
};


/**
 * @param {!proto.themis.Rubric} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Rubrics>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editRubric =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditRubric',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditRubric);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.RubricArea,
 *   !proto.themis.Rubrics>}
 */
const methodDescriptor_ThemisApi_EditRubricArea = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditRubricArea',
  grpc.web.MethodType.UNARY,
  themis_common_pb.RubricArea,
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.RubricArea,
 *   !proto.themis.Rubrics>}
 */
const methodInfo_ThemisApi_EditRubricArea = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @param {!proto.themis.RubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Rubrics)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Rubrics>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editRubricArea =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditRubricArea',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditRubricArea,
      callback);
};


/**
 * @param {!proto.themis.RubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Rubrics>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editRubricArea =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditRubricArea',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditRubricArea);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.RubricAreaItem,
 *   !proto.themis.Rubrics>}
 */
const methodDescriptor_ThemisApi_EditRubricAreaItem = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditRubricAreaItem',
  grpc.web.MethodType.UNARY,
  themis_common_pb.RubricAreaItem,
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricAreaItem} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.RubricAreaItem,
 *   !proto.themis.Rubrics>}
 */
const methodInfo_ThemisApi_EditRubricAreaItem = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricAreaItem} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @param {!proto.themis.RubricAreaItem} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Rubrics)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Rubrics>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editRubricAreaItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditRubricAreaItem',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditRubricAreaItem,
      callback);
};


/**
 * @param {!proto.themis.RubricAreaItem} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Rubrics>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editRubricAreaItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditRubricAreaItem',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditRubricAreaItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Rubric,
 *   !proto.themis.Rubrics>}
 */
const methodDescriptor_ThemisApi_DeleteRubric = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteRubric',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Rubric,
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.Rubric} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Rubric,
 *   !proto.themis.Rubrics>}
 */
const methodInfo_ThemisApi_DeleteRubric = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.Rubric} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @param {!proto.themis.Rubric} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Rubrics)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Rubrics>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteRubric =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteRubric',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteRubric,
      callback);
};


/**
 * @param {!proto.themis.Rubric} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Rubrics>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteRubric =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteRubric',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteRubric);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.RubricArea,
 *   !proto.themis.Rubrics>}
 */
const methodDescriptor_ThemisApi_DeleteRubricArea = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteRubricArea',
  grpc.web.MethodType.UNARY,
  themis_common_pb.RubricArea,
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.RubricArea,
 *   !proto.themis.Rubrics>}
 */
const methodInfo_ThemisApi_DeleteRubricArea = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @param {!proto.themis.RubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Rubrics)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Rubrics>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteRubricArea =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteRubricArea',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteRubricArea,
      callback);
};


/**
 * @param {!proto.themis.RubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Rubrics>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteRubricArea =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteRubricArea',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteRubricArea);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.RubricAreaItem,
 *   !proto.themis.Rubrics>}
 */
const methodDescriptor_ThemisApi_DeleteRubricAreaItem = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteRubricAreaItem',
  grpc.web.MethodType.UNARY,
  themis_common_pb.RubricAreaItem,
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricAreaItem} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.RubricAreaItem,
 *   !proto.themis.Rubrics>}
 */
const methodInfo_ThemisApi_DeleteRubricAreaItem = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.RubricAreaItem} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @param {!proto.themis.RubricAreaItem} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Rubrics)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Rubrics>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteRubricAreaItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteRubricAreaItem',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteRubricAreaItem,
      callback);
};


/**
 * @param {!proto.themis.RubricAreaItem} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Rubrics>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteRubricAreaItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteRubricAreaItem',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteRubricAreaItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Producer,
 *   !proto.themis.Rubrics>}
 */
const methodDescriptor_ThemisApi_GetRubricsByProducerId = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetRubricsByProducerId',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Producer,
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Producer,
 *   !proto.themis.Rubrics>}
 */
const methodInfo_ThemisApi_GetRubricsByProducerId = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Rubrics,
  /**
   * @param {!proto.themis.Producer} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubrics.deserializeBinary
);


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Rubrics)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Rubrics>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getRubricsByProducerId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetRubricsByProducerId',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetRubricsByProducerId,
      callback);
};


/**
 * @param {!proto.themis.Producer} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Rubrics>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getRubricsByProducerId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetRubricsByProducerId',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetRubricsByProducerId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Rubric,
 *   !proto.themis.Rubric>}
 */
const methodDescriptor_ThemisApi_GetRubricById = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetRubricById',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Rubric,
  themis_common_pb.Rubric,
  /**
   * @param {!proto.themis.Rubric} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubric.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Rubric,
 *   !proto.themis.Rubric>}
 */
const methodInfo_ThemisApi_GetRubricById = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Rubric,
  /**
   * @param {!proto.themis.Rubric} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Rubric.deserializeBinary
);


/**
 * @param {!proto.themis.Rubric} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Rubric)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Rubric>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getRubricById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetRubricById',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetRubricById,
      callback);
};


/**
 * @param {!proto.themis.Rubric} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Rubric>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getRubricById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetRubricById',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetRubricById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.Event>}
 */
const methodDescriptor_ThemisApi_AddEventDivisionShiftTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventDivisionShiftTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeam,
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.Event>}
 */
const methodInfo_ThemisApi_AddEventDivisionShiftTeam = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventDivisionShiftTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionShiftTeam,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Event>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventDivisionShiftTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionShiftTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.Event>}
 */
const methodDescriptor_ThemisApi_EditEventDivisionShiftTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditEventDivisionShiftTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeam,
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.Event>}
 */
const methodInfo_ThemisApi_EditEventDivisionShiftTeam = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editEventDivisionShiftTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventDivisionShiftTeam,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Event>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editEventDivisionShiftTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventDivisionShiftTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.Event>}
 */
const methodDescriptor_ThemisApi_DeleteEventDivisionShiftTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventDivisionShiftTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeam,
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.Event>}
 */
const methodInfo_ThemisApi_DeleteEventDivisionShiftTeam = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventDivisionShiftTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDivisionShiftTeam,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Event>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventDivisionShiftTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDivisionShiftTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.Event>}
 */
const methodDescriptor_ThemisApi_CompleteEventDivisionShiftTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/CompleteEventDivisionShiftTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeam,
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.Event>}
 */
const methodInfo_ThemisApi_CompleteEventDivisionShiftTeam = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.completeEventDivisionShiftTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/CompleteEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CompleteEventDivisionShiftTeam,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Event>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.completeEventDivisionShiftTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/CompleteEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_CompleteEventDivisionShiftTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.EventDivisionShiftTeamReturn>}
 */
const methodDescriptor_ThemisApi_GetEventDivisionShiftTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventDivisionShiftTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeam,
  proto.themis.EventDivisionShiftTeamReturn,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionShiftTeamReturn.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.EventDivisionShiftTeamReturn>}
 */
const methodInfo_ThemisApi_GetEventDivisionShiftTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDivisionShiftTeamReturn,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionShiftTeamReturn.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionShiftTeamReturn)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionShiftTeamReturn>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventDivisionShiftTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionShiftTeam,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionShiftTeamReturn>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventDivisionShiftTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionShiftTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShift,
 *   !proto.themis.EventDivisionShiftTeamsReturn>}
 */
const methodDescriptor_ThemisApi_GetEventDivisionShiftTeams = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventDivisionShiftTeams',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShift,
  proto.themis.EventDivisionShiftTeamsReturn,
  /**
   * @param {!proto.themis.EventDivisionShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionShiftTeamsReturn.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShift,
 *   !proto.themis.EventDivisionShiftTeamsReturn>}
 */
const methodInfo_ThemisApi_GetEventDivisionShiftTeams = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EventDivisionShiftTeamsReturn,
  /**
   * @param {!proto.themis.EventDivisionShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EventDivisionShiftTeamsReturn.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionShiftTeamsReturn)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionShiftTeamsReturn>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventDivisionShiftTeams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionShiftTeams',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionShiftTeams,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionShiftTeamsReturn>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventDivisionShiftTeams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionShiftTeams',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionShiftTeams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.EventDivisionShiftTeam>}
 */
const methodDescriptor_ThemisApi_GetEventDivisionShiftTeamScores = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventDivisionShiftTeamScores',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeam,
  themis_common_pb.EventDivisionShiftTeam,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventDivisionShiftTeam.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.EventDivisionShiftTeam>}
 */
const methodInfo_ThemisApi_GetEventDivisionShiftTeamScores = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventDivisionShiftTeam,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventDivisionShiftTeam.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventDivisionShiftTeam)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventDivisionShiftTeam>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventDivisionShiftTeamScores =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionShiftTeamScores',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionShiftTeamScores,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventDivisionShiftTeam>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventDivisionShiftTeamScores =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventDivisionShiftTeamScores',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventDivisionShiftTeamScores);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
 *   !proto.themis.TeamScores>}
 */
const methodDescriptor_ThemisApi_ScoreEventDivisionShift = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ScoreEventDivisionShift',
  grpc.web.MethodType.UNARY,
  proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
  proto.themis.TeamScores,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScores.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest,
 *   !proto.themis.TeamScores>}
 */
const methodInfo_ThemisApi_ScoreEventDivisionShift = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamScores,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScores.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamScores)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamScores>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.scoreEventDivisionShift =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ScoreEventDivisionShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ScoreEventDivisionShift,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShiftDivisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamScores>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.scoreEventDivisionShift =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ScoreEventDivisionShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ScoreEventDivisionShift);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_SkipEventDivisionShiftTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/SkipEventDivisionShiftTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeam,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_SkipEventDivisionShiftTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.skipEventDivisionShiftTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/SkipEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SkipEventDivisionShiftTeam,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.skipEventDivisionShiftTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/SkipEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_SkipEventDivisionShiftTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_UnskipEventDivisionShiftTeam = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/UnskipEventDivisionShiftTeam',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeam,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeam,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_UnskipEventDivisionShiftTeam = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.EventDivisionShiftTeam} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.unskipEventDivisionShiftTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/UnskipEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UnskipEventDivisionShiftTeam,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeam} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.unskipEventDivisionShiftTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/UnskipEventDivisionShiftTeam',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_UnskipEventDivisionShiftTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeamRubricArea,
 *   !proto.themis.Event>}
 */
const methodDescriptor_ThemisApi_AddEventDivisionShiftTeamRA = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventDivisionShiftTeamRA',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeamRubricArea,
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeamRubricArea,
 *   !proto.themis.Event>}
 */
const methodInfo_ThemisApi_AddEventDivisionShiftTeamRA = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventDivisionShiftTeamRA =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionShiftTeamRA',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionShiftTeamRA,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Event>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventDivisionShiftTeamRA =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionShiftTeamRA',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionShiftTeamRA);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeamRubricArea,
 *   !proto.themis.Event>}
 */
const methodDescriptor_ThemisApi_EditEventDivisionShiftTeamRA = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditEventDivisionShiftTeamRA',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeamRubricArea,
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeamRubricArea,
 *   !proto.themis.Event>}
 */
const methodInfo_ThemisApi_EditEventDivisionShiftTeamRA = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editEventDivisionShiftTeamRA =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditEventDivisionShiftTeamRA',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventDivisionShiftTeamRA,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Event>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editEventDivisionShiftTeamRA =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditEventDivisionShiftTeamRA',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventDivisionShiftTeamRA);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeamRubricArea,
 *   !proto.themis.Event>}
 */
const methodDescriptor_ThemisApi_DeleteEventDivisionShiftTeamRA = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventDivisionShiftTeamRA',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeamRubricArea,
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeamRubricArea,
 *   !proto.themis.Event>}
 */
const methodInfo_ThemisApi_DeleteEventDivisionShiftTeamRA = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventDivisionShiftTeamRA =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDivisionShiftTeamRA',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDivisionShiftTeamRA,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Event>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventDivisionShiftTeamRA =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDivisionShiftTeamRA',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDivisionShiftTeamRA);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeamRubricAreaScore,
 *   !proto.themis.Event>}
 */
const methodDescriptor_ThemisApi_AddEventDivisionShiftTeamRAScore = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventDivisionShiftTeamRAScore',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeamRubricAreaScore,
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeamRubricAreaScore,
 *   !proto.themis.Event>}
 */
const methodInfo_ThemisApi_AddEventDivisionShiftTeamRAScore = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventDivisionShiftTeamRAScore =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionShiftTeamRAScore',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionShiftTeamRAScore,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Event>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventDivisionShiftTeamRAScore =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionShiftTeamRAScore',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionShiftTeamRAScore);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeamRubricArea,
 *   !proto.themis.TeamScores>}
 */
const methodDescriptor_ThemisApi_AddEventDivisionShiftTeamRAScores = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventDivisionShiftTeamRAScores',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeamRubricArea,
  proto.themis.TeamScores,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScores.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeamRubricArea,
 *   !proto.themis.TeamScores>}
 */
const methodInfo_ThemisApi_AddEventDivisionShiftTeamRAScores = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamScores,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamScores.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamScores)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamScores>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventDivisionShiftTeamRAScores =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionShiftTeamRAScores',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionShiftTeamRAScores,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricArea} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamScores>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventDivisionShiftTeamRAScores =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventDivisionShiftTeamRAScores',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventDivisionShiftTeamRAScores);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeamRubricAreaScore,
 *   !proto.themis.Event>}
 */
const methodDescriptor_ThemisApi_EditEventDivisionShiftTeamRAScore = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/EditEventDivisionShiftTeamRAScore',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeamRubricAreaScore,
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeamRubricAreaScore,
 *   !proto.themis.Event>}
 */
const methodInfo_ThemisApi_EditEventDivisionShiftTeamRAScore = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.editEventDivisionShiftTeamRAScore =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/EditEventDivisionShiftTeamRAScore',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventDivisionShiftTeamRAScore,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Event>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.editEventDivisionShiftTeamRAScore =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/EditEventDivisionShiftTeamRAScore',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_EditEventDivisionShiftTeamRAScore);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShiftTeamRubricAreaScore,
 *   !proto.themis.Event>}
 */
const methodDescriptor_ThemisApi_DeleteEventDivisionShiftTeamRAScore = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventDivisionShiftTeamRAScore',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShiftTeamRubricAreaScore,
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShiftTeamRubricAreaScore,
 *   !proto.themis.Event>}
 */
const methodInfo_ThemisApi_DeleteEventDivisionShiftTeamRAScore = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventDivisionShiftTeamRAScore =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDivisionShiftTeamRAScore',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDivisionShiftTeamRAScore,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShiftTeamRubricAreaScore} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Event>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventDivisionShiftTeamRAScore =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventDivisionShiftTeamRAScore',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventDivisionShiftTeamRAScore);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventJudgeShiftRequest,
 *   !proto.themis.EventJudgeShiftResponse>}
 */
const methodDescriptor_ThemisApi_AddEventJudgeShifts = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/AddEventJudgeShifts',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventJudgeShiftRequest,
  themis_common_pb.EventJudgeShiftResponse,
  /**
   * @param {!proto.themis.EventJudgeShiftRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventJudgeShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventJudgeShiftRequest,
 *   !proto.themis.EventJudgeShiftResponse>}
 */
const methodInfo_ThemisApi_AddEventJudgeShifts = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventJudgeShiftResponse,
  /**
   * @param {!proto.themis.EventJudgeShiftRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventJudgeShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventJudgeShiftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventJudgeShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventJudgeShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.addEventJudgeShifts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/AddEventJudgeShifts',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventJudgeShifts,
      callback);
};


/**
 * @param {!proto.themis.EventJudgeShiftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventJudgeShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.addEventJudgeShifts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/AddEventJudgeShifts',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_AddEventJudgeShifts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventJudgeShiftRequest,
 *   !proto.themis.EventJudgeShiftResponse>}
 */
const methodDescriptor_ThemisApi_GetEventJudgeShifts = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventJudgeShifts',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventJudgeShiftRequest,
  themis_common_pb.EventJudgeShiftResponse,
  /**
   * @param {!proto.themis.EventJudgeShiftRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventJudgeShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventJudgeShiftRequest,
 *   !proto.themis.EventJudgeShiftResponse>}
 */
const methodInfo_ThemisApi_GetEventJudgeShifts = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventJudgeShiftResponse,
  /**
   * @param {!proto.themis.EventJudgeShiftRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventJudgeShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventJudgeShiftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventJudgeShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventJudgeShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventJudgeShifts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventJudgeShifts',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventJudgeShifts,
      callback);
};


/**
 * @param {!proto.themis.EventJudgeShiftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventJudgeShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventJudgeShifts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventJudgeShifts',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventJudgeShifts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventJudgeShiftRequest,
 *   !proto.themis.EventJudgeShiftResponse>}
 */
const methodDescriptor_ThemisApi_DeleteEventJudgeShift = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/DeleteEventJudgeShift',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventJudgeShiftRequest,
  themis_common_pb.EventJudgeShiftResponse,
  /**
   * @param {!proto.themis.EventJudgeShiftRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventJudgeShiftResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventJudgeShiftRequest,
 *   !proto.themis.EventJudgeShiftResponse>}
 */
const methodInfo_ThemisApi_DeleteEventJudgeShift = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.EventJudgeShiftResponse,
  /**
   * @param {!proto.themis.EventJudgeShiftRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.EventJudgeShiftResponse.deserializeBinary
);


/**
 * @param {!proto.themis.EventJudgeShiftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EventJudgeShiftResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EventJudgeShiftResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.deleteEventJudgeShift =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventJudgeShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventJudgeShift,
      callback);
};


/**
 * @param {!proto.themis.EventJudgeShiftRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EventJudgeShiftResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.deleteEventJudgeShift =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/DeleteEventJudgeShift',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_DeleteEventJudgeShift);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.TeamGenerationRequest,
 *   !proto.themis.EmptyMessage>}
 */
const methodDescriptor_ThemisApi_GenerateTeamsForEvent = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GenerateTeamsForEvent',
  grpc.web.MethodType.UNARY,
  proto.themis.TeamGenerationRequest,
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.TeamGenerationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.TeamGenerationRequest,
 *   !proto.themis.EmptyMessage>}
 */
const methodInfo_ThemisApi_GenerateTeamsForEvent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.EmptyMessage,
  /**
   * @param {!proto.themis.TeamGenerationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.EmptyMessage.deserializeBinary
);


/**
 * @param {!proto.themis.TeamGenerationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.EmptyMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.EmptyMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.generateTeamsForEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GenerateTeamsForEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GenerateTeamsForEvent,
      callback);
};


/**
 * @param {!proto.themis.TeamGenerationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.EmptyMessage>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.generateTeamsForEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GenerateTeamsForEvent',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GenerateTeamsForEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.FixScheduleRequest,
 *   !proto.themis.FixScheduleResponse>}
 */
const methodDescriptor_ThemisApi_FixSchedule = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/FixSchedule',
  grpc.web.MethodType.UNARY,
  proto.themis.FixScheduleRequest,
  proto.themis.FixScheduleResponse,
  /**
   * @param {!proto.themis.FixScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.FixScheduleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.FixScheduleRequest,
 *   !proto.themis.FixScheduleResponse>}
 */
const methodInfo_ThemisApi_FixSchedule = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.FixScheduleResponse,
  /**
   * @param {!proto.themis.FixScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.FixScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.themis.FixScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.FixScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.FixScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.fixSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/FixSchedule',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_FixSchedule,
      callback);
};


/**
 * @param {!proto.themis.FixScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.FixScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.fixSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/FixSchedule',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_FixSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.ShiftScores>}
 */
const methodDescriptor_ThemisApi_ShiftReport = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/ShiftReport',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventFloorDateJudgingPanelShift,
  proto.themis.ShiftScores,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ShiftScores.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventFloorDateJudgingPanelShift,
 *   !proto.themis.ShiftScores>}
 */
const methodInfo_ThemisApi_ShiftReport = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.ShiftScores,
  /**
   * @param {!proto.themis.EventFloorDateJudgingPanelShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.ShiftScores.deserializeBinary
);


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.ShiftScores)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.ShiftScores>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.shiftReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/ShiftReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ShiftReport,
      callback);
};


/**
 * @param {!proto.themis.EventFloorDateJudgingPanelShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.ShiftScores>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.shiftReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/ShiftReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_ShiftReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.EventDivisionShift,
 *   !proto.themis.TeamReport>}
 */
const methodDescriptor_ThemisApi_GetTeamReport = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetTeamReport',
  grpc.web.MethodType.UNARY,
  themis_common_pb.EventDivisionShift,
  proto.themis.TeamReport,
  /**
   * @param {!proto.themis.EventDivisionShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamReport.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.EventDivisionShift,
 *   !proto.themis.TeamReport>}
 */
const methodInfo_ThemisApi_GetTeamReport = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.TeamReport,
  /**
   * @param {!proto.themis.EventDivisionShift} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.TeamReport.deserializeBinary
);


/**
 * @param {!proto.themis.EventDivisionShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.TeamReport)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.TeamReport>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getTeamReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetTeamReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTeamReport,
      callback);
};


/**
 * @param {!proto.themis.EventDivisionShift} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.TeamReport>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getTeamReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetTeamReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetTeamReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.AthleteResponse>}
 */
const methodDescriptor_ThemisApi_GetEventAthletes = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetEventAthletes',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.AthleteResponse>}
 */
const methodInfo_ThemisApi_GetEventAthletes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.themis.AthleteResponse,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.themis.AthleteResponse.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.AthleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.AthleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getEventAthletes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetEventAthletes',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventAthletes,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.AthleteResponse>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getEventAthletes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetEventAthletes',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetEventAthletes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.themis.Event,
 *   !proto.themis.Event>}
 */
const methodDescriptor_ThemisApi_GetAthletePayReport = new grpc.web.MethodDescriptor(
  '/themis.ThemisApi/GetAthletePayReport',
  grpc.web.MethodType.UNARY,
  themis_common_pb.Event,
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.themis.Event,
 *   !proto.themis.Event>}
 */
const methodInfo_ThemisApi_GetAthletePayReport = new grpc.web.AbstractClientBase.MethodInfo(
  themis_common_pb.Event,
  /**
   * @param {!proto.themis.Event} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  themis_common_pb.Event.deserializeBinary
);


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.themis.Event)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.themis.Event>|undefined}
 *     The XHR Node Readable Stream
 */
proto.themis.ThemisApiClient.prototype.getAthletePayReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/themis.ThemisApi/GetAthletePayReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAthletePayReport,
      callback);
};


/**
 * @param {!proto.themis.Event} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.themis.Event>}
 *     Promise that resolves to the response
 */
proto.themis.ThemisApiPromiseClient.prototype.getAthletePayReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/themis.ThemisApi/GetAthletePayReport',
      request,
      metadata || {},
      methodDescriptor_ThemisApi_GetAthletePayReport);
};


module.exports = proto.themis;

