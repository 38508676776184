import React, {
  CSSProperties,
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Box,
  Button, Checkbox,
  Container, FormControlLabel,
  Grid, InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import { useStyles } from "lib/theme"
import themis_common from "store/themis_common_pb"
import { AnyDispatchType, currencySymbols, regexpNumber } from "lib/constants"
import {
  addEventDivisionPricingBulk,
  addEventPricing,
  updateEventDivisionPricing,
  updatePricing
} from "store/producer/eventActions"
import EventPricingDetails from "./EventPricingDetails"
import { eventDivisionSortFunction } from "lib/validators"
import HelpIcon from 'components/util/HelpIcon'
import { Clear as ClearIcon } from '@material-ui/icons'
import { Pagination } from "@material-ui/lab"

const pageSize = 50

interface eventPricingProps {
  event: themis_common.Event.AsObject,
  brandId: number,
  producerId: number,
  refreshEvent: Function,
  editEventDivision: Function,
  userCanViewEvent: boolean,
  userCanEditEvent: boolean,
  dispatch: AnyDispatchType
  rubrics: themis_common.Rubric.AsObject[] | undefined
}

const EventPricing: FunctionComponent<eventPricingProps> = ({ event, brandId, producerId, refreshEvent, editEventDivision, userCanViewEvent, userCanEditEvent, dispatch, rubrics }) => {
  const classes = useStyles()

  const [athletePrice, setAthletePrice] = useState<string>("")
  const [crossoverPrice, setCrossoverPrice] = useState<string>("")
  const [currencySymbol, setCurrencySymbol] = useState<string>("$")
  const [offsetHeight, setOffsetHeight] = useState(300)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentFilter, setCurrentFilter] = useState("")
  const [filterAthletePrice, setFilterAthletePrice] = useState("")
  const [filterTeamPrice, setFilterTeamPrice] = useState("")

  const inputRef: MutableRefObject<any> = useRef()

  useEffect(() => {
    setOffsetHeight((inputRef.current.getBoundingClientRect()).top)
  })

  // This refreshes the event when you first load the page
  useEffect(() => {
    refreshEvent()
  }, [refreshEvent])

  useEffect(() => {
    const symbol = currencySymbols.find(cs => cs.cc === event.currency)
    if (symbol) setCurrencySymbol(symbol.symbol)
  }, [event.currency])

  useEffect(() => {
    if (event.eventPricing?.athletePriceString) setAthletePrice(event.eventPricing.athletePriceString)
    if (event.eventPricing?.crossoverPriceString) setCrossoverPrice(event.eventPricing.crossoverPriceString)
  }, [event.eventPricing?.athletePriceString, event.eventPricing?.crossoverPriceString])

  const sortedEventDivisions = useMemo(() => {
    return event.eventDivisionList.filter((item) => {
      const splitFilter = currentFilter.split(" ").filter((item) => item.length > 0).map(item => item.toLowerCase())
      return splitFilter.reduce<boolean>((prev, curr) => prev && (item.division?.tier?.name.toLowerCase().includes(curr) || item.division?.name.toLowerCase().includes(curr) || false), true)
    }).sort(eventDivisionSortFunction)
  }, [event.eventDivisionList, currentFilter])

  const submitForm = useCallback(( ) => {
    if (!userCanEditEvent) return
    const doSubmitForm = async () => {
      const saveAthletePrice = athletePrice ? athletePrice.split(",").join("") : "0"
      await addEventPricing(event.id, brandId, producerId, saveAthletePrice, crossoverPrice)
      await updatePricing(saveAthletePrice, crossoverPrice, dispatch)
    }
    doSubmitForm()
  }, [dispatch, userCanEditEvent, athletePrice, brandId, crossoverPrice, event.id, producerId])

  const editEventPricing = useMemo(() =>
    <Grid item xs={12} className={classes.eventPricingTop} >
      <Grid container spacing={2}>
        <Grid item sm={5} md={3} lg={2}>
          <Box position="relative">
            <TextField
              variant="outlined"
              // margin="normal"
              label={"Default Athlete Registration"}
              fullWidth
              id={"AthletePrice"}
              value={athletePrice}
              name={"AthletePrice"}
              disabled={!userCanEditEvent}
              type="text"
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              }}
              onChange={(e) => {
                if (regexpNumber.test(e.target.value)) setAthletePrice(e.target.value); else return false
              }}
              autoFocus
            />
            <Box position="absolute" top={15} right={0} zIndex="modal">
              <HelpIcon markdownText={`### Athlete Price\n**Main price per athlete**  \nAthlete Price is used unless the athlete is a crossover or the division the team signed up for has specific pricing`} />
            </Box>
          </Box>
        </Grid>
        <Grid item sm={5} md={3} lg={2}>
          <Box position="relative">
            <TextField
              variant="outlined"
              // margin="normal"
              label={"Crossover Registration"}
              fullWidth
              id={"crossoverPrice"}
              value={crossoverPrice}
              name={"crossoverPrice"}
              disabled={!userCanEditEvent}
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              }}
              onChange={(e) => {
                if (regexpNumber.test(e.target.value)) setCrossoverPrice(e.target.value); else return false
              }}
            />
            <Box position="absolute" top={15} right={0} zIndex="modal">
              <HelpIcon markdownText={`### Crossover Price\nIf the athlete is registered on a second team for this event, crossover price is used.  \n*Crossover Price can be the same price as Athlete Price*`} />
            </Box>
          </Box>
        </Grid>
        <Grid item sm={2}>
          {!userCanEditEvent ? <></> :
            <Button
              type="submit"
              variant="contained"
              className={classes.divisionSubmit}
              onClick={submitForm}
            >Save</Button>
          }
        </Grid>
      </Grid>
    </Grid>
  , [athletePrice, classes.eventPricingTop, classes.submit, crossoverPrice, currencySymbol, submitForm, userCanEditEvent])

  const matchWindowHeight = useMemo<CSSProperties>(() => {
    return {
      height: `calc(100vh - ${offsetHeight}px - 24px)`, // 24px is the Material Tab padding
    }
  }, [offsetHeight])

  const setDivisions = useCallback(() => {
    const setEventDivision = async (eDiv: themis_common.EventDivision.AsObject) => {
      await updateEventDivisionPricing(eDiv.id, filterAthletePrice, filterTeamPrice, dispatch)
    }

    const setEventDivisionPricings = async(pricings: themis_common.EventDivisionPricing[]) => {
      await addEventDivisionPricingBulk(pricings)
      refreshEvent()
    }

    const setEventDivisionRubricAreas = async(rubricArea: string[]) => {
      // await addEventDivisionRubricAreaBulk(rubricArea)
      console.log("setting division")
    }

    const divisionPricings: themis_common.EventDivisionPricing[] = []

    sortedEventDivisions.forEach((eDiv) => {
      const eventDivisionPricing = new themis_common.EventDivisionPricing()
      const eventDivision = new themis_common.EventDivision()
      eventDivision.setId(eDiv.id)
      const currentEvent = new themis_common.Event()
      currentEvent.setId(event.id)
      currentEvent.setProducerId(producerId)
      currentEvent.setBrandId(brandId)
      eventDivision.setEvent(currentEvent)
      //eventDivision.setRubricId()
      eventDivisionPricing.setEventDivision(eventDivision)
      eventDivisionPricing.setAthletePriceString(filterAthletePrice)
      eventDivisionPricing.setTeamPriceString(filterTeamPrice)
      divisionPricings.push(eventDivisionPricing)
    })
    setEventDivisionPricings(divisionPricings)
    //setEventDivisionRubricAreas(rubricArea)
  }, [filterAthletePrice, filterTeamPrice, sortedEventDivisions, event])

  const handleFilterDivision = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCurrentPage(1)
    setCurrentFilter(e.target.value)
  }

  const handleNonCrossover = (eventDivisionId: number, nonCrossover: boolean) => {
    editEventDivision(eventDivisionId, nonCrossover)
  }

  return !userCanViewEvent ? <></> : (
    <Container style={{ maxWidth: "none" }}>
      <Grid container spacing={2} item xs={12} className={classes.eventPricingTop}>
        <Grid item xs={12} >
          <Typography style={{ marginRight: "25px" }} variant="h3">Pricing</Typography>
        </Grid>
        { editEventPricing }
        <Grid item xs={12} >
          <Typography style={{ marginRight: "25px" }} variant="h3">Division Pricing</Typography>
        </Grid>
        <Grid container item xs={12} spacing={2} style={{ borderBottom: "1px solid white" }} >
          <Grid item xs={12} md={3}>
            <TextField
              variant="outlined"
              label={"Filter Divisions"}
              id={`Filter Divisions`}
              value={currentFilter}
              name={"FilterDivisions"}
              onChange={(e) => {
                handleFilterDivision(e)
              }}
              InputProps={{
                endAdornment: (<InputAdornment position="end"><ClearIcon style={{ cursor: "pointer" }} onClick={() => { setCurrentFilter("") }} /> </InputAdornment>)
              }}
            />
          </Grid>
          <Grid container item xs={12} md={9} spacing={2}>
            <Grid item xs={4} md={3}>
              <Box position="relative">
                <TextField
                  variant="outlined"
                  label={"Set Athlete Registration"}
                  fullWidth
                  value={filterAthletePrice}
                  id={"SetAthletePrice"}
                  name={"SetAthletePrice"}
                  disabled={!userCanEditEvent}
                  type="text"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                  }}
                  onChange={(e) => {
                    setFilterAthletePrice(e.target.value)
                    setFilterTeamPrice("")
                  }}
                  autoFocus
                />
                <Box position="absolute" top={15} right={0} zIndex="modal">
                  <HelpIcon markdownText={`### Set Filtered Divisions Athlete Price  \nThis will set the athlete price for all visible divisions`} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} md={3}>
              <Box position="relative">
                <TextField
                  variant="outlined"
                  label={"Set Team Registration"}
                  fullWidth
                  value={filterTeamPrice}
                  id={"setTeamPrice"}
                  name={"setTeamPrice"}
                  disabled={!userCanEditEvent}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                  }}
                  onChange={(e) => {
                    setFilterTeamPrice(e.target.value)
                    setFilterAthletePrice("")
                  }}
                />
                <Box position="absolute" top={15} right={0} zIndex="modal">
                  <HelpIcon markdownText={`### Set Filtered Divisions Team Price\nThis will set the team price for all visible divisions`} />
                </Box>
              </Box>
            </Grid>
            <Grid item sm={4}>
              {!userCanEditEvent ? <></> :
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.divisionSubmit}
                  onClick={setDivisions}
                >Set Selected Divisions</Button>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid ref={inputRef} container item xs={12} style={{ ...matchWindowHeight, overflowY: "auto" }}>
          { !sortedEventDivisions ? [] : sortedEventDivisions.slice((currentPage - 1) * pageSize, (currentPage*pageSize) -1).map((div) => {
            return (
              <Grid item container xs={12} className={classes.userGridLeft} key={`division--${div.id}`}>
                <Grid item container xs={12} md={3} alignItems="center">
                  <Typography>
                    {`${div.division?.tier?.name} - ${div.division?.name} (${div.division?.season?.name})`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <EventPricingDetails eventDivision={div} brandId={brandId} producerId={producerId}
                    userCanViewEvent={userCanViewEvent} userCanEditEvent={userCanEditEvent} refreshEvent={refreshEvent}
                    currency={event.currency} eventId={event.id} dispatch={dispatch} defaultAthletePrice={athletePrice} rubrics={rubrics} />
                </Grid>
                <Grid item container xs={12} md={2} alignItems="center">

                  <FormControlLabel
                    className={classes.fullWidth}
                    control={
                      <Checkbox
                        checked={div.nonCrossover}
                        onChange={(e) => {div.nonCrossover = e.target.checked; handleNonCrossover(div.id, e.target.checked)}}
                        name="nonCrossover"
                        color="primary"
                      />
                    }
                    label={<Typography variant="body2">Exclude Division From Crossover Calculation</Typography>}
                  />



                </Grid>
              </Grid>
            )
          })}
          <Grid container item xs={12} justify="center">
            <Pagination style={{ paddingTop: 20 }} variant="outlined" shape="rounded" count={Math.ceil(sortedEventDivisions.length/pageSize)} page={currentPage} onChange={(e, p) => { setCurrentPage(p)}} />
          </Grid>
        </Grid>
      </Grid>
    </Container>)
}

export default EventPricing
